import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ISubject } from '../models/subject';
import { HttpService } from './http.service';
import { IResponse } from '../models/response';
import { EntityType, ISubjectCalendarData, IDashboard, IDashboardColumn, IStudentTask, EventType, INotifyUpdatePayload, IChangeSubjectClassPayload } from '../models/common';
import { BaseService } from './base.service';
import { exhaustMap, tap, share, delay, map } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from './toast.service';
import { SocketService } from './socket.service';
import { addDashboardColumn$, editDashboardColumn$, removeDashboardColumn$ } from '../helpers/helpers';
import { SubjectTicket } from 'src/app/pages/subject-dashboard/models';
import { ISubjectTicketFilter } from '../components/ticket-filter/models';
import { ExternalLinkService } from './external-link.service';
import { UserSettingsService } from 'src/app/auth/services/user-settings.service';
import {EntityUsers} from '../models/user/user';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class SubjectService extends BaseService<ISubject> {

  joinVideo$: Subject<string> = new Subject();

  private readonly SUBJECT_URL: string = 'v1/subjects';

  protected entityType: EntityType = EntityType.SUBJECT;
  protected GET_ALL: string = '';
  protected GET_ALL_USERS: (id: string) => string = (id: string) => `${this.SUBJECT_URL}/${id}/users`;
  protected GET_ALL_TO_TEACH: string = `${this.SUBJECT_URL}/teach`;
  protected GET_ALL_TO_STUDY: string = `${this.SUBJECT_URL}/study`;
  protected POST_ONE: string = this.SUBJECT_URL;
  protected PUT_ONE: string = this.SUBJECT_URL;
  protected PATCH_ONE: string = this.SUBJECT_URL;
  protected GET_ONE: (id: string) => string = (id: string) => `${this.SUBJECT_URL}/${id}`;
  protected GET_ONE_PUBLIC_DATA: (id: string) => string = (id: string) => `${this.SUBJECT_URL}/public/${id}`;
  protected GET_DASHBOARD_DATA_BY_SUBJECT_INIT = (id: string, userRole: string, limitByColumn: number, filters: string) => `${this.SUBJECT_URL}/dashboard-data/${id}/init?type=${userRole}&limitByColumn=${limitByColumn}&filters=${filters}`;
  protected GET_DASHBOARD_DATA_BY_SUBJECT_BY_PAGE = (id: string, userRole: string, page: number, limit: number, columnId: string, selectedDate: string, filters: string) => `${this.SUBJECT_URL}/dashboard-data/${id}?type=${userRole}&page=${page}&limit=${limit}&columnId=${columnId}&selectedDate=${selectedDate}&filters=${filters}`;
  protected GET_DASHBOARD_DATA_BY_SUBJECT_NEAR_DATE = (id: string, userRole: string, limit: number, columnId: string, selectedDate: string, filters: string) => `${this.SUBJECT_URL}/dashboard-data/${id}/near-selected-date?type=${userRole}&limit=${limit}&columnId=${columnId}&selectedDate=${selectedDate}&filters=${filters}`;
  protected GET_TIMELINE_DATES_BY_COLUMN = (id: string, userRole: string, columnId: string, filters: string) => `${this.SUBJECT_URL}/dashboard-data/${id}/timeline-dates?type=${userRole}&columnId=${columnId}&filters=${filters}`;
  protected GET_TIMELINE_DATES_BY_ALL_COLUMNS = (userRole: string, filters: string) => `${this.SUBJECT_URL}/dashboard-data/timeline-dates-by-all-columns?type=${userRole}&filters=${filters}`;
  protected GET_TIMELINE_DATES_BY_STATE = (userRole: string, state: string, filters: string) => `v2/subjects/dashboard-data/timeline-dates-by-state/${state}?type=${userRole}&filters=${filters}`;
  protected GET_TIMELINE_DATES_ALL_STATES = (userRole: string, filters: string) => `${this.SUBJECT_URL}/dashboard-data/timeline-dates-by-all-states?type=${userRole}&filters=${filters}`;
  protected GET_ALL_BY_CLASS = (id: string) => `${this.SUBJECT_URL}/by-class/${id}`;
  protected GET_ALL_BY_USER = (id: string, schoolId: string, classId: string, type: 'teacher' | 'student') => `${this.SUBJECT_URL}/by-user?userId=${id}&type=${type}&schoolId=${schoolId}&classId=${classId}`;
  protected GET_TEACHER_CALENDAR_DATA = (id: string, from: string, to: string) => `${this.SUBJECT_URL}/teacher-calendar-data/${id}/from/${from}/to/${to}`;
  protected GET_STUDENT_CALENDAR_DATA = (from: string, to: string) => `${this.SUBJECT_URL}/student-calendar-data/from/${from}/to/${to}`;
  protected DELETE_ONE: (id: string) => string = (id: string) => `${this.SUBJECT_URL}/${id}`;
  protected GET_DASHBOARD_BY_SUBJECT = (id: string) => `${this.SUBJECT_URL}/dashboard/${id}`;
  protected POST_JOIN_VIDEO: (id: string) => string = (id: string) => `${this.SUBJECT_URL}/${id}/join/video`;
  protected PATCH_CHANGE_CLASS = `v1/subjects/change-class`;

  constructor(
    protected httpService: HttpService,
    protected toastService: ToastService,
    private socketService: SocketService,
    private externalLinkSevice: ExternalLinkService,
    private userSettingsService: UserSettingsService,
  ) {
    super(httpService, toastService);

    this.joinVideo$
      .pipe(
        exhaustMap(id => this.joinVideoRequest$(id)),
        tap(response => {
          if (response.error && response.message) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
          } else {
            this.externalLinkSevice.open(response.payload.url);
          }
        }),
      )
      .subscribe();

    this.socketService.getCrudMessages$([EntityType.SUBJECT])
      .pipe(
        tap(message => {
          if (message.eventType === EventType.REMOVE) {
            this.userSettingsService.handleSubjectOrLessonRemove(message.entityId);
          }
        }),
      )
      .subscribe(payload => this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId }));
  }

  getAllTeach$(): Observable<IResponse<ISubject[]>> {
    return this.httpService.get$<ISubject[]>(this.GET_ALL_TO_TEACH)
      .pipe(share());
  }

  getAllStudy$(): Observable<IResponse<ISubject[]>> {
    return this.httpService.get$<ISubject[]>(this.GET_ALL_TO_STUDY)
      .pipe(share());
  }

  getDashboardTicketsInit$(
    subjectId: string,
    userRole: string,
    limitByColumn: number,
    filters: ISubjectTicketFilter,
  ): Observable<IResponse<SubjectTicket[]>> {
    return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SUBJECT_INIT(subjectId, userRole, limitByColumn, JSON.stringify(filters)));
  }

  getDashboardTicketsByPage$(
    subjectId: string,
    userRole: string,
    page: number,
    limit: number,
    columnId: string,
    selectedDate: string,
    filters: ISubjectTicketFilter,
  ): Observable<IResponse<SubjectTicket[]>> {
    return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SUBJECT_BY_PAGE(subjectId, userRole, page, limit, columnId, selectedDate, JSON.stringify(filters)));
  }

  getDashboardTicketsNearDate$(
    subjectId: string,
    userRole: string,
    limit: number,
    columnId: string,
    selectedDate: string,
    filters: ISubjectTicketFilter,
  ): Observable<IResponse<SubjectTicket[]>> {
    return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SUBJECT_NEAR_DATE(subjectId, userRole, limit, columnId, selectedDate, JSON.stringify(filters)));
  }

  getTimelineDatesByColumnId$(
    subjectId: string,
    userRole: string,
    columnId: string,
    filters: ISubjectTicketFilter,
  ): Observable<IResponse<string[]>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_COLUMN(subjectId, userRole, columnId as string, JSON.stringify(filters)));
  }

  getTimelineDatesAllColumns$(
    userRole: string,
    filters: ISubjectTicketFilter,
  ): Observable<IResponse<{ [columnId: string]: string[] }>> {
    filters.subject_ids
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_ALL_COLUMNS(userRole as string, JSON.stringify(filters)));
  }

  getTimelineDatesByState$(
    userRole: string,
    state: string,
    filters: ISubjectTicketFilter,
  ): Observable<IResponse<string[]>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_STATE(userRole, state, JSON.stringify(filters)));
  }

  getTimelineDatesByAllStates$(
    userRole: string,
    filters: ISubjectTicketFilter,
  ): Observable<IResponse<{ [columnId: string]: string[] }>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_ALL_STATES(userRole, JSON.stringify(filters)));
  }

  getAllByUserId$(userId: string, schoolId?: string, classId?: string): Observable<IResponse<ISubject[]>> {
    return this.httpService.get$(this.GET_ALL_BY_USER(userId, schoolId, classId, 'teacher'));
  }

  getAllByClassrId$(classId: string): Observable<IResponse<ISubject[]>> {
    return this.httpService.get$(this.GET_ALL_BY_CLASS(classId));
  }

  getTeacherCalendarData$(subjectId: string, fromDate: string, toDate: string): Observable<IResponse<ISubjectCalendarData[]>> {
    return this.httpService.get$(this.GET_TEACHER_CALENDAR_DATA(subjectId, fromDate, toDate));
  }

  getStudentCalendarData$(fromDate: string, toDate: string): Observable<IResponse<IStudentTask[]>> {
    return this.httpService.get$(this.GET_STUDENT_CALENDAR_DATA(fromDate, toDate));
  }

  getDashboardBySubjectId(id: string): Observable<IResponse<IDashboard>> {
    return this.httpService.get$(this.GET_DASHBOARD_BY_SUBJECT(id));
  }

  addDashboardColumn$(column: IDashboardColumn, subjectId: string): Observable<IResponse<IDashboardColumn>> {
    return addDashboardColumn$(column, subjectId, EntityType.SUBJECT, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.ADD_COLUMN, response, { entityId: subjectId })));
  }

  editDashboardColumn$(column: IDashboardColumn, subjectId: string): Observable<IResponse<IDashboardColumn>> {
    return editDashboardColumn$(column, EntityType.SUBJECT, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.EDIT_COLUMN, response, { entityId: subjectId })));
  }

  removeDashboardColumn$(column: IDashboardColumn, subjectId: string): Observable<IResponse<IDashboardColumn>> {
    return removeDashboardColumn$(column.id, EntityType.SUBJECT, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.REMOVE_COLUMN, response, { entityId: subjectId })));
  }

  joinVideoRequest$(subjectId: string): Observable<IResponse<{ url: string }>> {
    return this.httpService.post$(this.POST_JOIN_VIDEO(subjectId), null);
  }

  getPublicData$(subjectId: string): Observable<IResponse<ISubject>> {
    return this.httpService.get$(this.GET_ONE_PUBLIC_DATA(subjectId));
  }

  changeClass$(payload: IChangeSubjectClassPayload): Observable<IResponse<ISubject>> {
    return this.httpService.patch$(this.PATCH_CHANGE_CLASS, payload);
  }

  getAllSubjectUsers$(subjectId: string): Observable<IResponse<EntityUsers>> {
    return this.httpService.get$(this.GET_ALL_USERS(subjectId));
  }

  protected notifyAboutUpdateColumn(eventType: EventType, response: IResponse<ISubject>, payload: INotifyUpdatePayload) {
    if (response.error || !this.socketService.isConnected()) {
      this.notifyAboutUpdate(eventType, response, payload);
    }
  }
}
