import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhiteboardComponent } from './whiteboard.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    WhiteboardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    PipesModule,
  ],
  exports: [
    WhiteboardComponent,
  ],
  entryComponents: [
    WhiteboardComponent,
  ],
})
export class WhiteboardModule { }
