import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { tap, first, switchMap, filter } from 'rxjs/operators';
import { isSuccess, fileToBase64 } from 'src/app/shared/helpers/helpers';
import { DomSanitizer } from '@angular/platform-browser';
import { UserSettingsService } from './user-settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/http.service";
import * as i2 from "@angular/platform-browser";
import * as i3 from "./user-settings.service";
export class UserService {
    constructor(httpService, sanitizer, userSettingsService) {
        this.httpService = httpService;
        this.sanitizer = sanitizer;
        this.userSettingsService = userSettingsService;
        this.PLACEHOLDER_AVATAR_URL = '/assets/img/boy-placeholder.svg';
        this.loggedUser$ = new BehaviorSubject(null);
        this.userAvatar$ = new BehaviorSubject(this.PLACEHOLDER_AVATAR_URL);
        this.GET_USER_API = (id) => 'v2/user-profiles' + (id ? `?id=${id}` : '');
        this.GET_ALL_BY_FILTERS = (userType, entityId, entityType, count, search) => `v1/users?userType=${userType}&entityId=${entityId}&entityType=${entityType}&count=${count}&search=${search}`;
        this.GET_ALL_BY_ENTITIES_FILTERS = (search, entities, page, limit) => `v1/meetings/users?name=${search}&page=${page}&limit=${limit}&filters=${JSON.stringify(entities)}`;
        this.GET_USER_BY_EMAIL = (email) => `v1/user-profiles/by-email/${email}`;
        this.POST_DELETE_ACCOUNT_USER = `v1/user-profiles`;
        this.PATCH_USER = `v1/user-profiles`;
        this.UPLOAD_PHOTO = `v1/user-profiles/upload-photo`;
        this.UPDATE_PASSWORD = `v1/user-profiles/update-password`;
        this.GET_PHOTO = (id) => `v1/user-photo/${id}`;
        this.SET_USER_EMAIL = 'v1/user-profiles/set-email';
        this.POST_USER_ACTIVITY = 'v1/user-activity-log';
    }
    setUser(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (user) {
                this.loggedUser$.next(user);
                this.updateUserAvatar(user);
                yield this.userSettingsService.init$().toPromise();
            }
        });
    }
    getUser$() {
        return this.loggedUser$.asObservable()
            .pipe(filter(user => !!user));
    }
    getUserState$() {
        return this.loggedUser$.asObservable();
    }
    clearUser() {
        this.loggedUser$.next(null);
        this.userAvatar$.next(this.PLACEHOLDER_AVATAR_URL);
    }
    getUserRequest$(userId) {
        return this.httpService.get$(this.GET_USER_API(userId));
    }
    getUsers$(userType, count, search, entityId, entityType) {
        return this.httpService.get$(this.GET_ALL_BY_FILTERS(userType, entityId, entityType, count, search));
    }
    getUsersByEntitiesFilters$(search = null, entities = [], page, limit) {
        return this.httpService.get$(this.GET_ALL_BY_ENTITIES_FILTERS(search, entities, page, limit));
    }
    getByEmail(email) {
        return this.httpService.get$(this.GET_USER_BY_EMAIL(email));
    }
    patchUser(users) {
        return this.httpService.patch$(this.PATCH_USER, users);
    }
    uploadPhoto(file) {
        const body = new FormData();
        body.append('files', file);
        return this.httpService.post$(this.UPLOAD_PHOTO, body)
            .pipe(switchMap(() => this.getPhoto$(this.loggedUser$.value.id)), tap(response => this.setUserAvatar(response)));
    }
    updatePassword$(passwordData) {
        return this.httpService.post$(this.UPDATE_PASSWORD, passwordData);
    }
    deleteAccount$() {
        return this.httpService.delete$(this.POST_DELETE_ACCOUNT_USER);
    }
    postActivity$() {
        return this.httpService.post$(this.POST_USER_ACTIVITY, null);
    }
    getPhoto$(id) {
        return this.httpService.get$(this.GET_PHOTO(id), { responseType: 'blob' });
    }
    updateUserAvatar(user) {
        const data = user ? user : this.loggedUser$.value;
        if (data && data.avatarUrl) {
            this.getPhoto$(data.id)
                .pipe(first())
                .subscribe(response => this.setUserAvatar(response));
        }
    }
    getUserAvatar$() {
        return this.userAvatar$.asObservable();
    }
    setUserEmail$(payload) {
        return this.httpService.post$(this.SET_USER_EMAIL, payload);
    }
    setUserAvatar(response) {
        if (isSuccess(response)) {
            fileToBase64(response.payload)
                .then(data => {
                this.userAvatar$.next(this.sanitizer.bypassSecurityTrustUrl(data));
            });
        }
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.DomSanitizer), i0.ɵɵinject(i3.UserSettingsService)); }, token: UserService, providedIn: "root" });
