import { BaseService } from './base.service';
import { EntityType, EventType } from '../models/common';
import { HttpService } from './http.service';
import { addDashboardColumn$, editDashboardColumn$, removeDashboardColumn$ } from '../helpers/helpers';
import { ToastService } from './toast.service';
import { map, pluck, tap } from 'rxjs/operators';
import { SocketService } from './socket.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./toast.service";
import * as i3 from "./socket.service";
export class MaterialService extends BaseService {
    constructor(httpService, toastService, socketService) {
        super(httpService, toastService);
        this.httpService = httpService;
        this.toastService = toastService;
        this.socketService = socketService;
        this.MATERIALS_URL = 'v1/materials';
        this.entityType = EntityType.MATERIAL;
        this.GET_ALL = this.MATERIALS_URL;
        this.POST_ONE = this.MATERIALS_URL;
        this.PUT_ONE = this.MATERIALS_URL;
        this.PATCH_ONE = this.MATERIALS_URL;
        this.GET_ONE = (id) => `${this.MATERIALS_URL}/${id}`;
        this.GET_MATERIAL_DASHBOARD_DATA_INIT = (id, limitByColumn, filters) => `${this.MATERIALS_URL}/dashboard-data/${id}/init?limitByColumn=${limitByColumn}&filters=${filters}`;
        this.GET_MATERIAL_DASHBOARD_DATA_BY_PAGE = (params) => `${this.MATERIALS_URL}/dashboard-data/${params.materialId}?page=${params.page}&limit=${params.limit}&columnId=${params.columnId}&selectedDate=${params.selectedDate}&filters=${JSON.stringify(params.filters)}`;
        this.GET_DASHBOARD_BY_MATERIAL = (id) => `${this.MATERIALS_URL}/dashboard/${id}`;
        this.DELETE_ONE = (id) => `${this.MATERIALS_URL}/${id}`;
        this.GET_TIMELINE_DATES_BY_COLUMN = (columnId, filters) => `${this.MATERIALS_URL}/dashboard-data/timeline-dates-by-column/${columnId}?filters=${filters}`;
        this.GET_TIMELINE_DATES_BY_ALL_COLUMNS = (materialId, filters) => `${this.MATERIALS_URL}/dashboard-data/all-timeline-dates-by-material-id/${materialId}?filters=${filters}`;
        this.GET_DASHBOARD_DATA_NEAR_DATE = (params) => `${this.MATERIALS_URL}/near-selected-date?limit=${params.limit}&columnId=${params.columnId}&selectedDate=${params.selectedDate}&filters=${JSON.stringify(params.filters)}`;
        this.socketService.getCrudMessages$([EntityType.MATERIAL])
            .subscribe(payload => this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId }));
    }
    getDashboardTicketsInit$(materialId, limitByColumn, filters) {
        return this.httpService.get$(this.GET_MATERIAL_DASHBOARD_DATA_INIT(materialId, limitByColumn, JSON.stringify(filters)));
    }
    getDashboardTicketsByPage$(params) {
        return this.httpService.get$(this.GET_MATERIAL_DASHBOARD_DATA_BY_PAGE(params));
    }
    getDashboardByMaterialId$(id) {
        return this.httpService.get$(this.GET_DASHBOARD_BY_MATERIAL(id));
    }
    addDashboardColumn$(column, materialId) {
        return addDashboardColumn$(column, materialId, EntityType.MATERIAL, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.ADD_COLUMN, response, { entityId: materialId })));
    }
    editDashboardColumn$(column, materialId) {
        return editDashboardColumn$(column, EntityType.MATERIAL, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.EDIT_COLUMN, response, { entityId: materialId })));
    }
    removeDashboardColumn$(column, materialId) {
        return removeDashboardColumn$(column.id, EntityType.MATERIAL, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.REMOVE_COLUMN, response, { entityId: materialId })));
    }
    getTimelineDatesByColumn$(columnId, filters) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_COLUMN(columnId, JSON.stringify(filters)));
    }
    getTimelineDatesByAllColumns$(materialId, filters) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_ALL_COLUMNS(materialId, JSON.stringify(filters)));
    }
    getDashboardTicketsNearDate$(params) {
        return this.httpService.get$(this.GET_DASHBOARD_DATA_NEAR_DATE(params));
    }
    shouldNotifyAboutMaterialTicketUpdate$(materialTicketId) {
        return this.httpService.get$(`${this.GET_ONE(materialTicketId)}/should-notify-about-update`)
            .pipe(pluck('payload'), map((payload) => payload.canNotify));
    }
    notifyAboutUpdateColumn(eventType, response, payload) {
        if (response.error || !this.socketService.isConnected()) {
            super.notifyAboutUpdate(eventType, response, payload);
        }
    }
}
MaterialService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MaterialService_Factory() { return new MaterialService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.SocketService)); }, token: MaterialService, providedIn: "root" });
