import { Socket } from 'ngx-socket-io';
import { UserService } from 'src/app/auth/services/user.service';
import { EnvService } from './env/env.service';
import { Subject } from 'rxjs';
import { tap, share, takeUntil, filter, mergeMap, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SocketMessageType } from '../models/common';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/services/auth.service";
import * as i2 from "../../auth/services/user.service";
import * as i3 from "./env/env.service";
export class SocketService {
    constructor(authService, userService, envService) {
        this.authService = authService;
        this.userService = userService;
        this.envService = envService;
        this.messageEmitter$ = new Subject();
        this.destroy$ = new Subject();
        this.topic = (id) => `/topic/user/${id}`;
    }
    connect() {
        if (this.socket == null) {
            this.socket = new Socket({ url: this.envService.apiConfig.websocketUrl, options: { query: { token: this.authService.jwtToken }, transports: ['websocket'] } });
            this.userService.getUser$()
                .pipe(filter(user => !!user && this.authService.isAuthenticated), mergeMap(user => this.socket.fromEvent(this.topic(user.id))), tap(data => console.log('%c SOCKET MESSAGE: ', 'color: cyan', data)), takeUntil(this.destroy$))
                .subscribe(message => this.messageEmitter$.next(message));
        }
    }
    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket.removeAllListeners();
            this.destroy$.next();
            this.socket = null;
        }
    }
    messages$() {
        return this.messageEmitter$.pipe(share());
    }
    getCrudMessages$(entityTypes) {
        return this.messages$()
            .pipe(filter(() => this.authService.isAuthenticated), filter(message => message.type === SocketMessageType.CRUD &&
            entityTypes.includes(message.payload.entityType)), map(message => message.payload));
    }
    isConnected() {
        if (this.socket && this.socket.ioSocket) {
            return this.socket.ioSocket.connected;
        }
        return false;
    }
}
SocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.EnvService)); }, token: SocketService, providedIn: "root" });
