import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PopoverNotificationComponent } from './popover-notification.component';
import { MeetingAttendeeModule } from '../meeting-attendee/meeting-attendee.module';
import { MeetingJoinModule } from '../meeting-join/meeting-join.module';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';

@NgModule({
  declarations: [
    PopoverNotificationComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    MeetingAttendeeModule,
    StyledScrollbarModule,
    MeetingJoinModule,
  ],
  exports: [
    PopoverNotificationComponent,
  ],
  entryComponents: [
    PopoverNotificationComponent,
  ],
})
export class PopoverNotificationModule { }
