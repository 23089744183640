import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@ionic/angular";
export class PagesGuard {
    constructor(authService, navCtrl) {
        this.authService = authService;
        this.navCtrl = navCtrl;
    }
    canActivate() {
        if (!this.authService.isAuthenticated) {
            this.navCtrl.navigateRoot('auth/login');
        }
        return this.authService.isAuthenticated;
    }
}
PagesGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PagesGuard_Factory() { return new PagesGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.NavController)); }, token: PagesGuard, providedIn: "root" });
