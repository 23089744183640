/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-route-error-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./ticket-route-error-modal.component";
var styles_RouteErrorModalComponent = [i0.styles];
var RenderType_RouteErrorModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RouteErrorModalComponent, data: {} });
export { RenderType_RouteErrorModalComponent as RenderType_RouteErrorModalComponent };
function View_RouteErrorModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_RouteErrorModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-card", [], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "ion-card-content", [], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(3, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "img", [["alt", ""], ["src", "assets/img/warning-ico.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RouteErrorModalComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "ion-button", [["class", "primary"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(9, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null), (_l()(), i1.ɵted(10, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 6, 0, currVal_0); var currVal_1 = "md"; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("common.go-to-home")); _ck(_v, 10, 0, currVal_2); }); }
export function View_RouteErrorModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticket-route-error-modal", [], null, null, null, View_RouteErrorModalComponent_0, RenderType_RouteErrorModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.RouteErrorModalComponent, [i3.ModalController, i3.NavController, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RouteErrorModalComponentNgFactory = i1.ɵccf("app-ticket-route-error-modal", i6.RouteErrorModalComponent, View_RouteErrorModalComponent_Host_0, { errorType: "errorType", routeTitle: "routeTitle" }, {}, []);
export { RouteErrorModalComponentNgFactory as RouteErrorModalComponentNgFactory };
