import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { IResponse } from '../../models/response';
import { exhaustMap, tap, takeUntil } from 'rxjs/operators';
import { HttpService } from '../../services/http.service';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { hasError } from '../../helpers/helpers';
import { ModalController } from '@ionic/angular';

export interface IFeedbackForm {
  message: string;
}

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  isSubmitted: boolean = false;
  submit$: Subject<void> = new Subject();
  hasError = hasError;

  private readonly POST_FEEDBACK: string = 'v1/feedbacks';
  private destroy$: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      message: ['', Validators.required],
    });

    this.submit$
      .pipe(
        exhaustMap(() => this.sendFeedback$(this.formGroup.value)),
        tap(response => this.handleResponse(response)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  sendFeedback$(form: IFeedbackForm): Observable<IResponse<any>> {
    this.isSubmitted = true;
    return this.httpService.post$(this.POST_FEEDBACK, form);
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  private handleResponse(response: IResponse<any>) {
    if (response.error) {
      if (response.message) {
        this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
      }
    } else {
      this.toastService.showToast(this.translateService.instant('feedback.send-success'), TOAST_TYPE.SUCCESS);
      this.modalCtrl.dismiss();
    }
    this.isSubmitted = false;
  }
}
