<ion-app>
	<ion-split-pane [when]="isMobile ? false : 'md'" contentId="main-content" [class.menu-collapsed]="isMenuCollapsed">
		<ion-menu #ionMenu contentId="main-content" type="overlay" [class.no-content]="!isMenuVisible" [disabled]="isMenuDisabled$ | async">
			<app-menu *ngIf="isMenuVisible" (navChange)="onMenuNavChange()"></app-menu>
		</ion-menu>
		<ion-router-outlet id="main-content">
			<template #createMenuContainer></template>
		</ion-router-outlet>
	</ion-split-pane>
	<template #popoverNotificationContainer></template>
	<ion-fab *ngIf="showChatFab" vertical="bottom" horizontal="end" class="chat-fab">
		<ion-badge
			*ngIf="chatFabCounter$ | async as chatFabCounter"
			class="chat-fab-counter"
			[class.small]="chatFabCounter > 99">
			{{ chatFabCounter > 99 ? '99+' : chatFabCounter }}
		</ion-badge>
		<ion-fab-button #chatBtn (click)="openChat($event)" class="chat-fab">
			<ion-icon src="/assets/img/chat-fab-ic.svg"></ion-icon>
		</ion-fab-button>
	</ion-fab>
	<app-file-upload-dialog></app-file-upload-dialog>
	<app-update-notification *ngIf="appUpdateService.showUpdateMessage$() | async"></app-update-notification>
	<app-onboarding-guided-overlay></app-onboarding-guided-overlay>
</ion-app>
<app-notification-sidebar *ngIf="authService.isAuthenticated$ | async"></app-notification-sidebar>
