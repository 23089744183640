import { Component, Input, OnInit } from '@angular/core';
import { ChatMember } from '../../models/chat';

@Component({
  selector: 'app-chat-conversation-item',
  templateUrl: './chat-conversation-item.component.html',
  styleUrls: ['./chat-conversation-item.component.scss'],
})
export class ChatConversationItemComponent implements OnInit {
  @Input() title: string;
  @Input() members: ChatMember[] = [];
  @Input() isChannel: boolean = false;
  @Input() isUnread: boolean = false;
  @Input() noPaddings: boolean = false;
  @Input() lines: string = 'inset';

  constructor() { }

  ngOnInit() {}

  get showTitle(): string {
    return this.title === '' ? 'deactivated' : this.title;
  }

}
