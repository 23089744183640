import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISelectOption } from '../select-input/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InviteService } from '../../services/invite.service';
import { ActiveInvites, IInviteModel, InviteType } from './models';
import { Subject, merge, Observable } from 'rxjs';
import { exhaustMap, concatMap, filter, tap, map, takeUntil, pluck } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { ClipboardService } from '../../services/clipboard.service';
import { EntityType, SocketMessageType } from '../../models/common';
import { SocketService } from '../../services/socket.service';
import { isSuccess } from '../../helpers/helpers';

@Component({
  selector: 'app-invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss'],
})
export class InviteModalComponent implements OnInit, OnDestroy {
  @Input() entityId: string;
  @Input() entityType: EntityType;
  @Input() preselectedUserType: InviteType = InviteType.STUDENT;
  generateEmitter$ = new Subject<void>();
  deactivateEmitter$ = new Subject<void>();

  invite: IInviteModel;
  formGroup: FormGroup;
  selectOptions: ISelectOption[] = [
    { title: 'invitation.for-teacher', value: InviteType.TEACHER },
    { title: 'invitation.for-student', value: InviteType.STUDENT },
  ];
  isSubmitted: boolean = false;
  isLoading: boolean = true;

  private readonly NUMBER_REGEXP = /^\s*(\-|\+)?(\d+|(\d*(\.\d*)))([eE][+-]?\d+)?\s*$/;
  private activeInvites: ActiveInvites;
  private destroy$ = new Subject<void>();

  constructor(
    public clipboardService: ClipboardService,
    private fb: FormBuilder,
    private inviteService: InviteService,
    private toastService: ToastService,
    private socketService: SocketService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.listenForUpdates();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onlyNumbers(event: any) {

    const newValue: string = event.target.value;
    const regExp = new RegExp(this.NUMBER_REGEXP);

    if (!regExp.test(newValue) || newValue === '0' || newValue.length > 7) {
      event.target.value = newValue.slice(0, -1);
    }
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      type: [[this.preselectedUserType], Validators.required],
      valid_hours: [this.invite ? this.invite.valid_hours : null, Validators.required],
      vacant_places: [this.invite ? this.invite.vacant_places : null, Validators.required],
      invite_code: [this.invite ? this.invite.invite_code : null],
      invite_url: [this.invite ? this.invite.invite_url : null],
    });
  }

  private listenForUpdates(): void {
    merge(this.getInitInvites$(), this.getUpdateInvites$(), this.getSocketUpdates$())
    .pipe(
      filter(values => !!values),
      takeUntil(this.destroy$),
    )
    .subscribe(invites => {
      this.activeInvites = new ActiveInvites(invites);
      this.setInvite(this.activeInvites[this.formGroup.controls['type'].value]);
    });

    this.formGroup.controls['type'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.setInvite(this.activeInvites[this.formGroup.controls['type'].value]));
  }

  private setInvite(invite: IInviteModel): void {
    this.invite = invite;
    this.formGroup.controls['valid_hours'].reset(this.invite ? this.invite.valid_hours : null);
    this.formGroup.controls['vacant_places'].reset(this.invite ? this.invite.vacant_places : null);
    this.formGroup.controls['invite_code'].reset(this.invite ? this.invite.invite_code : null);
    this.formGroup.controls['invite_url'].reset(this.invite ? this.invite.invite_url : null);
  }

  private getInitInvites$(): Observable<IInviteModel[]> {
    return this.inviteService.getActive$(this.entityId, this.entityType)
      .pipe(
        tap(response => {
          if (!response.error) {
            this.isLoading = false;
          }

          if (response.error && response.message) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
          }
        }),
        filter(response => !response.error && !!response.payload),
        map(response => response.payload),
      );
  }

  private getUpdateInvites$(): Observable<IInviteModel[]> {
    const generate$ = this.generateEmitter$
      .pipe(
        tap(() => this.isSubmitted = true),
        exhaustMap(() => this.inviteService.generate$(this.entityId, this.entityType, this.formGroup.value)),
      );
    const deactivate$ = this.deactivateEmitter$
      .pipe(
        tap(() => this.isSubmitted = true),
        exhaustMap(() => this.inviteService.deactivate$(this.entityId, this.entityType, this.formGroup.value)),
      );

    return merge(generate$, deactivate$)
      .pipe(
        tap(response => {
          if (response.error && response.message) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
            this.isSubmitted = false;
          }
        }),
        filter(response => !response.error && !!response.payload),
        concatMap(() => this.inviteService.getActive$(this.entityId, this.entityType)),
        tap(response => {
          if (response.error && response.message) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
          } else if (!response.error && response.payload) {
            this.toastService.showToast('Updated!', TOAST_TYPE.SUCCESS);
          }
          this.isSubmitted = false;
        }),
        filter(response => !response.error && !!response.payload),
        map(response => response.payload),
      );
  }

  private getSocketUpdates$(): Observable<IInviteModel[]> {
    return this.socketService.messages$()
      .pipe(
        filter(message => message.type === SocketMessageType.INVITE_CODE_DEACTIVATED),
        concatMap(() => this.inviteService.getActive$(this.entityId, this.entityType)),
        filter(response => isSuccess(response)),
        pluck('payload'),
      );
  }
}
