<ng-container *ngIf="(users$ | async) as users">
  <ng-container *ngIf="users.length">
    <div>
      <ion-label class="title">{{ 'invitation.invited-people' | translate }}</ion-label>
      <ion-content styledScrollbar="shadow-root">
        <ion-list styledScrollbarContent lines="none">
          <ion-item *ngFor="let user of users" lines="none">
            <div class="avatar">
              <img [src]="user.avatarUrl || 'assets/img/boy-placeholder.svg'" slot="start" alt="">
              <ion-icon *ngIf="canShowMeetingInviteStatus && getMeetingInviteIcon(user)" [src]="getMeetingInviteIcon(user)" class="invitee-status"></ion-icon>
            </div>
            <ion-label [ngClass]="{'semi-visible': user.deactivated}">{{ user.firstName }} {{ user.lastName }} <ng-container *ngIf="user.deactivated">( {{'common.deactivated' | translate}} )</ng-container></ion-label>
            <ion-button *ngIf="canRemoveUser(user)" slot="end" mode="md" class="circle-chip-btn remove" (click)="removeInvite(user)">
              <ion-icon class="ico icon-remove"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
        <ion-infinite-scroll (ionInfinite)="loadMoreUsers($event)" [disabled]="isNew || !currentUsers?.length || currentUsers?.length < invitedUsersLimit - 1">
          <ion-infinite-scroll-content color="primary" loadingSpinner="crescent">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
    </div>
  </ng-container>
</ng-container>
