import { fromEvent, merge, timer } from 'rxjs';
import { debounceTime, map, share, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
export const CLIENT_IDLE_TIMEOUT_SECONDS = 5 * 60;
export const MOBILE_WINDOW_LANDSCAPE_WIDTH = 576;
export const TABLET_WINDOW_LANDSCAPE_WIDTH = 767;
export class WindowService {
    constructor() {
        this.resize$ = fromEvent(window, 'resize').pipe(shareReplay(1));
        this.active$ = this.onActive$().pipe(debounceTime(1000));
        this.idle$ = this.onIdle$(CLIENT_IDLE_TIMEOUT_SECONDS).pipe(shareReplay(1));
        this.focus$ = fromEvent(window, 'focus').pipe(shareReplay(1));
    }
    onActive$() {
        const activationEvents = [
            [document, "click"],
            [document, "wheel"],
            [document, "scroll"],
            [document, "mousemove"],
            [document, "keyup"],
            [window, "resize"],
            [window, "scroll"],
            [window, "mousemove"],
            [window, "focus"],
        ];
        const activationEvents$ = activationEvents.map(([source, ev]) => fromEvent(source, ev));
        return merge(...activationEvents$);
    }
    onIdle$(timeoutSeconds) {
        const idleTimeout$ = timer(timeoutSeconds * 1e3);
        const active$ = this.onActive$().pipe(debounceTime(1000), share());
        return active$.pipe(switchMap(() => idleTimeout$.pipe(takeUntil(active$))), map(() => true));
    }
}
WindowService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WindowService_Factory() { return new WindowService(); }, token: WindowService, providedIn: "root" });
