/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-attachments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../files-image-preview/files-image-preview.component.ngfactory";
import * as i3 from "../../files-image-preview/files-image-preview.component";
import * as i4 from "@angular/common";
import * as i5 from "../../styled-scrollbar/styled-scrollbar.directive";
import * as i6 from "../../../services/window.service";
import * as i7 from "../../../services/platform.service";
import * as i8 from "../../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i9 from "@ionic/angular";
import * as i10 from "./message-attachments.component";
import * as i11 from "../../../services/zulip.service";
import * as i12 from "../../../services/toast.service";
import * as i13 from "@ngx-translate/core";
import * as i14 from "../chat-log-controller.service";
import * as i15 from "../../../services/chat-attachments.service";
var styles_MessageAttachmentsComponent = [i0.styles];
var RenderType_MessageAttachmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageAttachmentsComponent, data: {} });
export { RenderType_MessageAttachmentsComponent as RenderType_MessageAttachmentsComponent };
function View_MessageAttachmentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-files-image-preview", [], [[8, "className", 0]], [[null, "download"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("download" === en)) {
        var pd_0 = (_co.downloadImageAttachment(_v.context.ngIf, _v.parent.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.previewImage(_v.context.ngIf, _v.parent.context.$implicit.name) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FilesImagePreviewComponent_0, i2.RenderType_FilesImagePreviewComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilesImagePreviewComponent, [], { src: [0, "src"], previewMode: [1, "previewMode"] }, { download: "download" })], function (_ck, _v) { var currVal_1 = _v.context.ngIf; var currVal_2 = "BIG"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).className; _ck(_v, 0, 0, currVal_0); }); }
function View_MessageAttachmentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MessageAttachmentsComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.asyncDataUrl)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MessageAttachmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "msg-attachments-images"], ["styledScrollbar", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 1, i5.StyledScollbarDirective, [i1.ElementRef, i6.WindowService, i7.PlatformService], { styledScrollbar: [0, "styledScrollbar"], styledScrollbarSelf: [1, "styledScrollbarSelf"] }, null), i1.ɵqud(603979776, 1, { styledScrollbarContent: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageAttachmentsComponent_2)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.images; _ck(_v, 4, 0, currVal_2); }, null); }
function View_MessageAttachmentsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-item", [["class", "msg-attachment-entry"], ["lines", "none"]], null, null, null, i8.View_IonItem_0, i8.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i9.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "a", [["class", "link"]], [[1, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadAttachment($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ("Download " + _v.context.$implicit.name); _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_MessageAttachmentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-list", [["class", "msg-attachments-list"]], null, null, null, i8.View_IonList_0, i8.RenderType_IonList)), i1.ɵdid(1, 49152, null, 0, i9.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MessageAttachmentsComponent_5)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MessageAttachmentsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageAttachmentsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageAttachmentsComponent_4)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.images == null) ? null : _co.images.length) > 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.files == null) ? null : _co.files.length) > 0); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_MessageAttachmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-attachments", [], null, null, null, View_MessageAttachmentsComponent_0, RenderType_MessageAttachmentsComponent)), i1.ɵdid(1, 114688, null, 0, i10.MessageAttachmentsComponent, [i11.ZulipService, i12.ToastService, i13.TranslateService, i14.ChatLogControllerService, i15.ChatAttachmentsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageAttachmentsComponentNgFactory = i1.ɵccf("app-message-attachments", i10.MessageAttachmentsComponent, View_MessageAttachmentsComponent_Host_0, { messageId: "messageId", files: "files", images: "images" }, {}, []);
export { MessageAttachmentsComponentNgFactory as MessageAttachmentsComponentNgFactory };
