import { BehaviorSubject, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import * as i0 from "@angular/core";
import * as i1 from "./platform.service";
export class AppMenuService {
    constructor(platformService) {
        this.platformService = platformService;
        this.collapsed$ = new BehaviorSubject(false);
        this.collapsedChanges$ = this.collapsed$.asObservable().pipe(share());
        this.markAsSelectedEmitter$ = new Subject();
        this.markAsSelectedPage$ = this.markAsSelectedEmitter$.asObservable().pipe(share());
        this.isDisabledEmitter$ = new BehaviorSubject(false);
        this.isDisabled$ = this.isDisabledEmitter$.asObservable().pipe(share());
    }
    setRouterOutlet(routerOutlet) {
        this.routerOutlet = routerOutlet;
    }
    clearRouterOutlet() {
        this.routerOutlet = null;
    }
    toggleCollapsed(collapsed) {
        if (!this.platformService.isMobile) {
            this.collapsed$.next(collapsed);
        }
    }
    isCollapsed() {
        return this.collapsed$.getValue();
    }
    canGoBack() {
        if (this.routerOutlet) {
            return this.routerOutlet.canGoBack();
        }
        return false;
    }
    prevUrl() {
        if (this.routerOutlet) {
            const views = this.routerOutlet.stackCtrl.views.slice();
            return views.length > 1
                ? views[views.length - 2].url
                : views[0].url;
        }
        return '';
    }
    markAsSelectedPage(activePage) {
        this.markAsSelectedEmitter$.next(activePage);
    }
    disableMenu(shouldDisable = true) {
        this.isDisabledEmitter$.next(shouldDisable);
    }
}
AppMenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppMenuService_Factory() { return new AppMenuService(i0.ɵɵinject(i1.PlatformService)); }, token: AppMenuService, providedIn: "root" });
