import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { concat, forkJoin, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { EntityType, UserTypes } from '../../models/common';
import { InviteService } from '../../services/invite.service';
export class ParentChangeConfirmComponent {
    constructor(inviteService, modalCtrl, cdr) {
        this.inviteService = inviteService;
        this.modalCtrl = modalCtrl;
        this.cdr = cdr;
        this.slidesOptions = {
            allowTouchMove: false,
        };
    }
    get isClassEntityChange() {
        return this.entityType === EntityType.SUBJECT;
    }
    ngOnInit() {
        const filterCurrentEntities = [{ entityId: this.entityId, entityType: this.entityType }];
        const filterNewEntities = [{ entityId: this.nextParentEntityId, entityType: this.nextParentEntity }];
        const currentEntityUsers$ = this.inviteService
            .getUsers$('', UserTypes.ALL, filterCurrentEntities, null, null)
            .pipe(first(), map(res => res.payload.filter(user => !user.deactivated)));
        const newEntityUsers$ = this.inviteService
            .getUsers$('', UserTypes.ALL, filterNewEntities, null, null)
            .pipe(first(), map(res => res.payload.filter(user => !user.deactivated)));
        forkJoin(currentEntityUsers$, newEntityUsers$)
            .pipe(first(), map(([users, nextEntityUsers]) => {
            return users
                .filter(user => nextEntityUsers.findIndex(nextUser => nextUser.id === user.id) === -1)
                .map((user) => {
                return {
                    data: user,
                    shouldMove: true,
                };
            });
        }))
            .subscribe(users => {
            this.usersList = users;
            this.cdr.detectChanges();
        });
    }
    ngAfterViewInit() {
        const slideChange$ = this.slidesRef.ionSlideWillChange.pipe(switchMap(() => this.slidesRef.isEnd()));
        this.showBackBtn$ = concat(of(false), slideChange$);
    }
    toggleUserShouldMove(user) {
        user.shouldMove = !user.shouldMove;
    }
    dismiss() {
        this.modalCtrl.dismiss({
            isConfirmed: false,
            payload: false,
        });
    }
    next() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const activeIndex = yield this.slidesRef.getActiveIndex();
            if (activeIndex === 0) {
                this.slidesRef.slideNext();
            }
            else {
                this.confirm();
            }
        });
    }
    back() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.slidesRef.slidePrev();
        });
    }
    confirm() {
        const usersToMove = this.getUsersToMove();
        // Omiting "user_ids_for_migration" in payload
        // for `SubjectService.changeClass$` or `ClassService.changeSchool$`
        // will tell api to move all students/teachers along
        const payload = usersToMove.length === this.usersList.length
            ? {}
            : { user_ids_for_migration: usersToMove.map(entry => entry.data.id) };
        this.modalCtrl.dismiss({
            isConfirmed: true,
            payload,
        });
    }
    getUsersToMove() {
        return this.usersList.filter(user => user.shouldMove);
    }
    getUsersToStay() {
        return this.usersList.filter(user => !user.shouldMove);
    }
}
