<ion-content styledScrollbar="shadow-root">
  <ion-list styledScrollbarContent>
    <ion-item *ngFor="let entry of attachments$ | async; trackBy: trackByLink" class="files-list-item">
      <div slot="start" class="preview">
        <ion-img
          *ngIf="entry.isImage; else fileIconTempl"
          src="assets/img/image-ico.svg"
          (ionImgWillLoad)="handleImagePreload($event, entry)"
          (click)="preview($event, entry)">
        </ion-img>
        <ng-template #fileIconTempl>
          <img src="/assets/img/file-ic.svg" [attr.alt]="entry.name" />
        </ng-template>
      </div>
      <div class="description">
        <a href="#" class="link" (click)="download($event, entry)">{{ entry.name }}</a>
        <ion-text>{{ entry.senderFullName }} <!-- - 234 Kb --></ion-text>
      </div>
    </ion-item>
  </ion-list>

  <ion-infinite-scroll (ionInfinite)="loadMore($event)" threshold="140px">
    <ion-infinite-scroll-content>
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>