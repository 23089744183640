<ion-card [formGroup]="formGroup">
  <div class="loader" [ngClass]="{'submitted': isSubmitted}" *ngIf="isLoading || isSubmitted">
      <ion-spinner name="crescent"></ion-spinner>
  </div>
  <ion-card-content [ngClass]="{'btn-submitted': isSubmitted}">
    <ion-label>{{ 'invitation.invite-people' | translate }}</ion-label>
    <app-select-input [options]="selectOptions" [disable]="isSubmitted" formControlName="type"></app-select-input>
    <ion-item-group>
      <ion-item class="input" lines="none" [disabled]="invite && invite.id">
        <ion-label position="floating">{{ 'invitation.valid-hours' | translate }}</ion-label>
        <ion-input type="number" formControlName="valid_hours" (keyup)="onlyNumbers($event)"></ion-input>
      </ion-item>
      <ion-item class="input" lines="none" [disabled]="invite && invite.id">
        <ion-label position="floating">{{ 'invitation.used-times' | translate }}</ion-label>
        <ion-input type="number" formControlName="vacant_places" (keyup)="onlyNumbers($event)"></ion-input>
      </ion-item>
    </ion-item-group>
    <ion-item *ngIf="invite && invite.id && invite.invite_code" class="input copy" lines="none">
      <ion-input type="text" formControlName="invite_code" readonly>
        <ion-button class="primary" mode="md" slot="end" [disabled]="invite?.vacant_places === 0" (click)="clipboardService.copy(invite.invite_code)">{{ 'invitation.copy-btn' | translate }}</ion-button>
      </ion-input>
    </ion-item>
    <ion-item *ngIf="invite && invite.id && invite.invite_url" class="input copy" lines="none">
      <ion-input type="text" formControlName="invite_url" readonly>
        <ion-button class="primary" mode="md" slot="end" [disabled]="invite?.vacant_places === 0" (click)="clipboardService.copy(invite.invite_url)">{{ 'invitation.copy-btn' | translate }}</ion-button>
      </ion-input>
    </ion-item>
    <ion-item class="submit-btn" lines="none">
      <ion-button *ngIf="!(invite && invite.id)" class="primary" expand="block" type="submit" expand="block" mode="md" [disabled]="!formGroup.valid || isSubmitted" (click)="generateEmitter$.next()">{{ 'invitation.generate-btn' | translate }}</ion-button>
      <ion-button *ngIf="invite && invite.id" class="secondary" expand="block" type="submit" expand="block" mode="md" [disabled]="isSubmitted" (click)="deactivateEmitter$.next()">{{ 'invitation.deactivate-btn' | translate }}</ion-button>
    </ion-item>
  </ion-card-content>
</ion-card>
