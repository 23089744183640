import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FilesImagePreviewComponent } from './files-image-preview.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule,],
  declarations: [FilesImagePreviewComponent],
  exports: [FilesImagePreviewComponent]
})
export class FilesImagePreviewComponentModule {}
