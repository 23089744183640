import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Subject, of } from 'rxjs';
import { map, share, switchMap, take, withLatestFrom, filter, tap } from 'rxjs/operators';
import { UserService } from 'src/app/auth/services/user.service';
import { selectChatStreamInfo, selectAllUsers, selectChatStreamList } from 'src/app/store/selectors/chat.selectors';
import { EntityAdapterService } from './entity-adapter.service';
import { WhiteboardService } from './whiteboard.service';
import { ZulipService } from './zulip.service';
import { ToastService, TOAST_TYPE } from './toast.service';
import { WhiteboardComponent } from '../components/whiteboard/whiteboard.component';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../auth/services/user.service";
import * as i3 from "./whiteboard.service";
import * as i4 from "./entity-adapter.service";
import * as i5 from "./zulip.service";
import * as i6 from "./toast.service";
import * as i7 from "@ngx-translate/core";
export class ChatUiService {
    constructor(store, userService, whiteboardService, entityAdapterService, zulipService, toastService, translateService) {
        this.store = store;
        this.userService = userService;
        this.whiteboardService = whiteboardService;
        this.entityAdapterService = entityAdapterService;
        this.zulipService = zulipService;
        this.toastService = toastService;
        this.translateService = translateService;
        this.events = new Subject();
        this.openChatWithUserEmitter$ = new Subject();
        this.closeChatWithUserEmitter$ = new Subject();
        this.isChatOpenInProgress = false;
        this.events$ = this.events.asObservable().pipe(share());
    }
    publish(event) {
        this.events.next(event);
    }
    openConference(id) {
        this.store.select(selectChatStreamInfo, id).pipe(switchMap(stream => {
            if (stream.meta.type === 'channel') {
                return this.entityAdapterService.getOne$(stream.name, stream.meta.entityType).pipe(map((entity) => ({ stream, ownerId: entity.owner_id, entityId: entity.id })));
            }
            return this.userService.getUser$().pipe(map(user => ({ stream, ownerId: user.id, entityId: null })));
        }), take(1))
            .subscribe(({ stream, ownerId, entityId }) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.whiteboardService.openConference({
                id: entityId ? entityId : stream.name,
                title: stream.meta.type === 'channel' ? stream.title : stream.stream_id + '',
                owner_id: ownerId,
                entityType: entityId ? stream.meta.entityType : "chat-pm"
            }, WhiteboardComponent);
        }));
    }
    openChatWithUser(user) {
        this.openChatWithUserEmitter$.next(user);
    }
    closeChatWithUser() {
        this.closeChatWithUserEmitter$.next();
    }
    get closeChatWithUser$() {
        return this.closeChatWithUserEmitter$.asObservable();
    }
    openChatStream$() {
        return this.openChatWithUserEmitter$
            .asObservable()
            .pipe(filter(() => !this.isChatOpenInProgress), tap(() => this.isChatOpenInProgress = true), switchMap(user => this.getPmStreamId$(user)));
    }
    getPmStreamId$(user) {
        return this.zulipService.currentUser$
            .pipe(withLatestFrom(this.store.select(selectAllUsers)), map(([currUser, users]) => this.getZulipUserIds(currUser, user, users)), filter(([a, b]) => a !== b), tap(userIds => {
            if (!this.isUserIdsValid(userIds)) {
                this.toastService.showToast(this.translateService.instant('connection-errors.something-went-wrong'), TOAST_TYPE.ERROR);
                this.isChatOpenInProgress = false;
            }
        }), filter(userIds => this.isUserIdsValid(userIds)), withLatestFrom(this.store.select(selectChatStreamList)), switchMap(([userIds, streams]) => this.getStreamId$(userIds, streams)
            .pipe(tap(() => this.isChatOpenInProgress = false))));
    }
    getZulipUserIds(currUser, findUser, chatUsers) {
        const chatUser = chatUsers.find(user => user.id === findUser.id);
        const chatUserId = chatUser ? chatUser.zulip_uid : null;
        return [currUser.user_id, chatUserId];
    }
    isUserIdsValid(ids) {
        return ids && ids.length === 2 && !!ids[0] && !!ids[1];
    }
    getStreamId$(userIds, streams) {
        const stream = streams.find(str => str.meta.type === 'pm' &&
            userIds.length === str.subscribers.length &&
            userIds.every(invitee => str.subscribers.includes(invitee)));
        if (stream) {
            return of(stream.stream_id);
        }
        return this.zulipService.createStream$(userIds)
            .pipe(tap(response => {
            if (response.result === 'error') {
                this.toastService.showToast(this.translateService.instant('chat.cannot-create-chat'), TOAST_TYPE.ERROR);
            }
        }), filter(response => response.result === 'success'), map(response => response.stream_id));
    }
}
ChatUiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatUiService_Factory() { return new ChatUiService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.WhiteboardService), i0.ɵɵinject(i4.EntityAdapterService), i0.ɵɵinject(i5.ZulipService), i0.ɵɵinject(i6.ToastService), i0.ɵɵinject(i7.TranslateService)); }, token: ChatUiService, providedIn: "root" });
