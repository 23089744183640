import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export var InvitedUsersListViewEventType;
(function (InvitedUsersListViewEventType) {
    InvitedUsersListViewEventType[InvitedUsersListViewEventType["INIT"] = 0] = "INIT";
    InvitedUsersListViewEventType[InvitedUsersListViewEventType["DESTROY"] = 1] = "DESTROY";
    InvitedUsersListViewEventType[InvitedUsersListViewEventType["ADD"] = 2] = "ADD";
    InvitedUsersListViewEventType[InvitedUsersListViewEventType["REMOVE"] = 3] = "REMOVE";
})(InvitedUsersListViewEventType || (InvitedUsersListViewEventType = {}));
export class InvitedUsersListViewService {
    constructor() {
        this.eventEmitter$ = new Subject();
        this.events$ = this.eventEmitter$.asObservable();
    }
    emitInitEvent(ids) {
        this.eventEmitter$.next({ type: InvitedUsersListViewEventType.INIT, ids });
    }
    emitAddEvent(ids) {
        this.eventEmitter$.next({ type: InvitedUsersListViewEventType.ADD, ids });
    }
    emitRemoveEvent(id) {
        this.eventEmitter$.next({ type: InvitedUsersListViewEventType.REMOVE, ids: [id] });
    }
    emitDestroyEvent() {
        this.eventEmitter$.next({ type: InvitedUsersListViewEventType.DESTROY });
    }
}
InvitedUsersListViewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvitedUsersListViewService_Factory() { return new InvitedUsersListViewService(); }, token: InvitedUsersListViewService, providedIn: "root" });
