import { IBaseEntity, UserRole } from './common';

export enum TicketType {
    TASK = 'Task',
    GENERAL = 'General',
    NOTIFICATION = 'Notification'
}

export enum TeacherTaskState {
    DRAFT = 'draft',
    IN_PROGRESS = 'in_progress',
    TO_CHECK = 'to_check',
    DONE = 'done'
}

export enum StudentTaskState {
    TODO = 'to_do',
    IN_PROGRESS = 'in_progress',
    WAITING_FOR_CHECK = 'waiting_for_check',
    CHECKED_AND_FINISHED = 'checked_and_finished'
}

export interface INotificationFor {
    students?: boolean;
    teachers?: boolean;
}

export const TEACHER_TASK_STATES = [ TeacherTaskState.DRAFT, TeacherTaskState.IN_PROGRESS, TeacherTaskState.TO_CHECK, TeacherTaskState.DONE ];
export const STUDENT_TASK_STATES = [ StudentTaskState.TODO, StudentTaskState.IN_PROGRESS, StudentTaskState.WAITING_FOR_CHECK, StudentTaskState.CHECKED_AND_FINISHED ];

interface Ticket {
    id_code: string;
    title: string;
    description: string;
    cover_url: string;
    state: TeacherTaskState;
    type: TicketType;
    due_date: string;
    is_published: boolean;
    make_visible_at: string;
    notify_at: string;
    school_id: string;
    material_id: string;
    school_class_id: string;
    subject_id: string;
    should_not_verificate: boolean;
    column_id: string;
    owner_id: string;
    role: UserRole;
    notify_completed: boolean;
    notify_any: boolean;
    notify_students: boolean;
    notify_teachers: boolean;
    created_on: string;
    modified_on: string;
    published_date: string;
}

export type ITicket = IBaseEntity & INotificationFor & Partial<Ticket>