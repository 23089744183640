import { Injectable } from '@angular/core';
import { UserService } from 'src/app/auth/services/user.service';
import { filter, first, map } from 'rxjs/operators';
import { SchoolDashboardPermissions, SchoolTicketPermissions } from './school';
import { SubjectDashboardPermissions, SubjectTicketPermissions } from './subject';
import { ClassDashboardPermissions, ClassTicketPermissions } from './schoolClass';
import { MaterialTicketPermissions } from './material';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  public schoolDashboard: SchoolDashboardPermissions;
  public classDashboard: ClassDashboardPermissions;
  public subjectDashboard: SubjectDashboardPermissions;

  public schoolTicket: SchoolTicketPermissions;
  public classTicket: ClassTicketPermissions;
  public subjectTicket: SubjectTicketPermissions;
  public materialTicket: MaterialTicketPermissions;

  constructor(
    userService: UserService,
  ) {
    userService.getUser$()
      .pipe(
        filter(user => !!user),
        map(user => user.id),
      )
      .subscribe(userId => this.initPermissionInstances(userId));
  }

  private initPermissionInstances(userId: string): void {
    this.schoolDashboard = new SchoolDashboardPermissions(userId);
    this.classDashboard = new ClassDashboardPermissions(userId);
    this.subjectDashboard = new SubjectDashboardPermissions(userId);

    this.schoolTicket = new SchoolTicketPermissions(userId);
    this.classTicket = new ClassTicketPermissions(userId);
    this.subjectTicket = new SubjectTicketPermissions(userId);
    this.materialTicket = new MaterialTicketPermissions(userId);
  }
}
