import * as tslib_1 from "tslib";
import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
import * as i0 from "@angular/core";
const { Device } = Plugins;
var Platform;
(function (Platform) {
    Platform["Android"] = "Android";
    Platform["iOS"] = "iOS";
    Platform["MacOS"] = "Mac OS";
    Platform["Windows"] = "Windows";
    Platform["Linux"] = "Linux";
})(Platform || (Platform = {}));
export class PlatformService {
    constructor() {
        this.userAgent = window.navigator.userAgent;
        this.platform = window.navigator.platform;
        this.macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        this.windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        this.iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        this.os = null;
    }
    get isMobile() {
        return [Platform.Android, Platform.iOS].includes(this.os);
    }
    get isiOS() {
        return this.os === Platform.iOS;
    }
    get isNativeiOS() {
        return this.os === Platform.iOS
            ? Capacitor.isNative
            : false;
    }
    get isNativeiPadOS() {
        return [Platform.MacOS].includes(this.os)
            ? Capacitor.isNative
            : false;
    }
    get isAndroid() {
        return this.os === Platform.Android;
    }
    get isFireFox() {
        return window.navigator.userAgent.match(/Firefox\/([0-9]+)\./) !== null;
    }
    get isSafari() {
        return navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0;
    }
    get isDesktop() {
        return [Platform.Windows, Platform.MacOS, Platform.Linux].includes(this.os);
    }
    isWebRtcWork() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isNativeiOS) {
                const info = yield Device.getInfo();
                const osVersion = parseFloat(info.osVersion);
                return osVersion > 14.4;
            }
            return true;
        });
    }
    setOS() {
        if (this.macosPlatforms.indexOf(this.platform) !== -1) {
            this.os = Platform.MacOS;
        }
        else if (this.iosPlatforms.indexOf(this.platform) !== -1) {
            this.os = Platform.iOS;
        }
        else if (this.windowsPlatforms.indexOf(this.platform) !== -1) {
            this.os = Platform.Windows;
        }
        else if (/Android/.test(this.userAgent)) {
            this.os = Platform.Android;
        }
        else if (!this.os && /Linux/.test(this.platform)) {
            this.os = Platform.Linux;
        }
        this.displayConsole();
    }
    displayConsole() {
        const suffix = this.isNativeiOS ? 'Native' : '';
        console.log(`%c [PLATFORM]: ${suffix}`, 'color: green', JSON.stringify(this.os));
    }
}
PlatformService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlatformService_Factory() { return new PlatformService(); }, token: PlatformService, providedIn: "root" });
