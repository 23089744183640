import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

export enum TOAST_TYPE {
  SUCCESS = 'success',
  ERROR = 'danger',
  WARNING = 'warning',
}

export const TOAST_CONFIG = {
  durations: {
    success: 2000,
    error: 5000,
  }
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastController: ToastController,
  ) { }

  public async showToast(message: string, type: TOAST_TYPE, position: 'bottom' | 'top' | 'middle' = 'bottom'): Promise<void> {
    const toast = await this.toastController.create({
      message,
      color: type,
      duration: type === TOAST_TYPE.SUCCESS ? TOAST_CONFIG.durations.success : TOAST_CONFIG.durations.error,
      position,
      cssClass: 'custom-toast',
    });

    return toast.present();
  }
}
