import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppUpdateNotificationComponent } from './app-update-notification.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    AppUpdateNotificationComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  exports: [
    AppUpdateNotificationComponent,
  ],
})
export class AppUpdateNotificationModule { }
