/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../checkbox/checkbox.component.ngfactory";
import * as i3 from "../checkbox/checkbox.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i6 from "@ionic/angular";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "./confirmation-modal.component";
var styles_ConfirmationModalComponent = [i0.styles];
var RenderType_ConfirmationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationModalComponent, data: {} });
export { RenderType_ConfirmationModalComponent as RenderType_ConfirmationModalComponent };
function View_ConfirmationModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "app-checkbox", [["class", "remove-meeting"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.isSelected = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CheckboxComponent_0, i2.RenderType_CheckboxComponent)), i1.ɵdid(2, 4964352, null, 0, i3.CheckboxComponent, [i1.ChangeDetectorRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CheckboxComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵted(7, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_7 = _co.isSelected; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.getToggleText(); _ck(_v, 7, 0, currVal_8); }); }
function View_ConfirmationModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-button", [["class", "secondary"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ion-button", [["class", "primary"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(5, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null), (_l()(), i1.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "md"; _ck(_v, 2, 0, currVal_0); var currVal_2 = "md"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getCancelBtnText(); _ck(_v, 3, 0, currVal_1); var currVal_3 = _co.getConfirmBtnText(); _ck(_v, 6, 0, currVal_3); }); }
function View_ConfirmationModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ion-button", [["class", "primary"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "md"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("common.btn-ok")); _ck(_v, 3, 0, currVal_1); }); }
export function View_ConfirmationModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-card", [], null, null, null, i5.View_IonCard_0, i5.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i6.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "ion-card-content", [], null, null, null, i5.View_IonCardContent_0, i5.RenderType_IonCardContent)), i1.ɵdid(3, 49152, null, 0, i6.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "img", [["alt", "Warning"], ["height", "90"], ["src", "assets/img/warning-ico-sm.png"], ["width", "91"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfirmationModalComponent_1)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfirmationModalComponent_2)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfirmationModalComponent_3)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfirmationModalComponent_4)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showOk", 2]], 0, 0, null, View_ConfirmationModalComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.showCheckbox; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.showConfirm; var currVal_4 = i1.ɵnov(_v, 13); _ck(_v, 12, 0, currVal_3, currVal_4); }, null); }
export function View_ConfirmationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-modal", [], null, null, null, View_ConfirmationModalComponent_0, RenderType_ConfirmationModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.ConfirmationModalComponent, [i6.ModalController, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationModalComponentNgFactory = i1.ɵccf("app-confirmation-modal", i9.ConfirmationModalComponent, View_ConfirmationModalComponent_Host_0, { action: "action", entityName: "entityName" }, {}, []);
export { ConfirmationModalComponentNgFactory as ConfirmationModalComponentNgFactory };
