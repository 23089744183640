import { ISubject } from './subject';
import { ISelectOption } from '../components/select-input/models';
import { UserModel } from './user/user';

export enum MeetingFrequency {
  NONE = 'none',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  EVERY_DAY = 'every_day',
  EVERY_WEEK = 'every_week',
  EVERY_2_WEEKS = 'every_2_weeks',
}

export interface IMeetingType {
  id: string;
  label: string;
  color: string;
  owner_id?: string;
}

export interface IMeeting {
  id: string;
  type_id: string;
  created_on?: string;
  type?: IMeetingType;
  subject_id: string;
  subject?: ISubject;
  start_date: string;
  end_date: string;
  serial_id?: string;
  date_serial_id?: string;
  frequency_dates?: IMeetingFrequency[];
  title?: string;
  owner_id?: string;
  is_invited?: boolean;
  is_available?: boolean;
  status?: MeetingUserStatus;
}

export interface IMeetingUserCount {
  invited: number;
  accepted: number;
}

export interface IMeetingFrequency {
  id?: string;
  /** Every single meeting start date and time */
  start_date: string;
  /** Meeting series end date */
  end_date: string | null;
  /** Single meeting duration */
  meeting_duration: number;
  frequency: MeetingFrequency;
  step: number;
}

export interface INotAvailableMeeting {
  user_id: string;
  start_date: string | Date;
  end_date: string | Date;
  is_available: boolean; // always = false
}

export interface IPastMeeting {
  id: string;
  meetingId: string;
  start_date: string | Date;
  end_date: string | Date;
}

export interface IMeetingCellMark {
  date: string;
  color: string;
}

export type IMeetingUpdate = IMeeting & {
  changeAll: boolean;
};

export enum DateRangeOption {
  Week = 'week',
  Month = 'month',
}

export const getMeetingViewDateRangeOptions = (): ISelectOption[] => {
  return [
    {
      title: 'Week',
      value: DateRangeOption.Week,
    },
    {
      title: 'Month',
      value: DateRangeOption.Month,
    }
  ];
};

export enum MeetingUserStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export function getMeetingUserStatusIcon(status: MeetingUserStatus): string {
  if (status === MeetingUserStatus.PENDING) {
    return 'assets/img/invitee-pending.svg';
  } else if (status === MeetingUserStatus.ACCEPTED) {
    return 'assets/img/invitee-accepted.svg';
  } else if (status === MeetingUserStatus.DECLINED) {
    return 'assets/img/invitee-rejected.svg';
  }
  return '';
}

export class MeetingUserModel extends UserModel {
  status: MeetingUserStatus;
}

export function isReccuringMeeting(meeting: IMeeting): boolean {
  if (!meeting || !meeting.frequency_dates) {
    return false;
  }
  return !(meeting.frequency_dates.length === 1 &&
    meeting.frequency_dates[0].frequency === MeetingFrequency.NONE);
}
