import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { of, Subject } from 'rxjs';
import { delay, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserService } from 'src/app/auth/services/user.service';
import { EntityCrudViewService } from '../../services/entity-crud-view.service';
import { NodeType } from '../tree-menu/models/node_type';
import { MenuController, NavController } from '@ionic/angular';
import { SubjectService } from '../../services/subject.service';
import { ConfirmAction } from '../../models/common';
import { detectChanges, getEntityTypeFromNode } from '../../helpers/helpers';
import { AppMenuService } from '../../services/menu.service';
import { PlatformService } from '../../services/platform.service';
import { InviteModalService } from '../../services/invite-modal.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { ExternalLinkService, ExternalLinksEnum } from '../../services/external-link.service';
export class MenuComponent {
    constructor(auth, userService, entityCrudViewService, navCtlr, subjectService, appMenuService, cdr, navCtrl, zone, platformService, menuCtrl, inviteModalService, confirmationService, externalLinkSevice) {
        this.auth = auth;
        this.userService = userService;
        this.entityCrudViewService = entityCrudViewService;
        this.navCtlr = navCtlr;
        this.subjectService = subjectService;
        this.appMenuService = appMenuService;
        this.cdr = cdr;
        this.navCtrl = navCtrl;
        this.zone = zone;
        this.platformService = platformService;
        this.menuCtrl = menuCtrl;
        this.inviteModalService = inviteModalService;
        this.confirmationService = confirmationService;
        this.externalLinkSevice = externalLinkSevice;
        this.navChange = new EventEmitter();
        this.logout$ = new Subject();
        this.userName$ = this.userService.getUser$()
            .pipe(filter(user => !!user), map(user => `${user.firstName} ${user.lastName}`));
        this.reRenderTree$ = new Subject();
        this.isChildEditingTrigger$ = new Subject();
        this.destroy$ = new Subject();
        this.appMenuService.collapsedChanges$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isCollapsed) => {
            this.isCollapsed = isCollapsed;
            detectChanges(this.cdr);
        });
    }
    ngOnInit() {
        this.logout$
            .pipe(switchMap(() => this.auth.logout$()), tap(() => this.navCtrl.navigateRoot('auth/login')), takeUntil(this.destroy$))
            .subscribe();
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
    get isMobile() {
        return this.platformService.isMobile;
    }
    get isNativeiOS() {
        return this.platformService.isNativeiOS;
    }
    get userAvatar$() {
        return this.userService.getUserAvatar$();
    }
    openPaypal() {
        this.externalLinkSevice.open(ExternalLinksEnum.paypal);
    }
    openTawk() {
        this.externalLinkSevice.open(ExternalLinksEnum.tawk);
    }
    openCreateMenu(entity) {
        if (entity) {
            this.entityCrudViewService.openCreateMenu(entity.payload, getEntityTypeFromNode(entity.type));
        }
        else {
            this.entityCrudViewService.openCreateMenu();
        }
        this.navChange.emit();
    }
    openPage(data) {
        let url;
        switch (data.type) {
            case NodeType.SCHOOL:
                url = `pages/school-dashboard/${data.payload.id}`;
                break;
            case NodeType.CLASS:
                url = `pages/class-dashboard/${data.payload.id}`;
                break;
            case NodeType.SUBJECT_GROUP:
                url = 'pages/subjects';
                break;
            case NodeType.SUBJECT_DASHBOARD:
                url = `pages/subject-dashboard/${data.payload.id}`;
                break;
            case NodeType.SUBJECT_CALENDAR:
                url = `pages/subject-calendar/${data.payload.id}`;
                break;
            case NodeType.LESSON_GROUP:
                url = 'pages/lessons';
                break;
            case NodeType.LESSON_CALENDAR:
                url = 'pages/lessons-calendar';
                break;
            case NodeType.LESSON:
                url = `pages/subject-dashboard/${data.payload.id}`;
                break;
            case NodeType.MATERIAL:
                url = `pages/material/${data.payload.id}`;
                break;
            case NodeType.MEETING:
                url = `pages/meetings`;
                break;
        }
        if (url) {
            this.zone.run(() => this.navCtlr.navigateRoot(url)).then(() => {
                of(true)
                    .pipe(delay(250))
                    .subscribe(() => {
                    this.navChange.emit();
                    if (this.canCloseSidemenu(data)) {
                        this.menuCtrl.close();
                    }
                });
            });
        }
    }
    openNode(node) {
        this.primaryNode = node;
    }
    closeMobileMenu() {
        this.primaryNode = undefined;
        this.reRenderTree$.next();
    }
    toggleEditing() {
        this.isChildEditingTrigger$.next();
    }
    get canEditNodeChild() {
        return this.primaryNode && this.primaryNode.type !== NodeType.LESSON_GROUP;
    }
    openInvite(data) {
        if (data && data.payload) {
            this.showInviteModal(data.payload.id, getEntityTypeFromNode(data.type));
        }
    }
    openVideo(data) {
        this.subjectService.joinVideo$.next(data['data'].id);
    }
    openHomePage() {
        this.navCtlr.navigateRoot('pages/home');
        this.closeMobileMenu();
        this.closeMenu();
        this.appMenuService.toggleCollapsed(false);
    }
    handleTreeMenuCollapse(isCollapsed) {
        this.isCollapsed = isCollapsed;
    }
    closeMenu() {
        this.menuCtrl.close();
    }
    handleLogout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { isConfirmed } = yield this.confirmationService.confirmAction(ConfirmAction.LOGOUT);
            if (isConfirmed) {
                this.logout$.next();
            }
        });
    }
    showInviteModal(entityId, entityType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.inviteModalService.open(entityId, entityType);
        });
    }
    canCloseSidemenu(node) {
        if (this.isMobile) {
            return ![
                NodeType.SCHOOL_GROUP,
                NodeType.CLASS_GROUP,
                NodeType.SUBJECT_GROUP,
                NodeType.LESSON_GROUP,
                NodeType.MATERIAL_GROUP,
            ].includes(node.type);
        }
        return false;
    }
}
