import { Component, OnInit, HostListener, ElementRef, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subject, of } from 'rxjs';
import { trigger, state, style } from '@angular/animations';
import { delay, first, tap } from 'rxjs/operators';
import { INotificationMessage, IBaseNotification } from '../../models/notifications';
import { NotificationSidebarService } from '../../services/notification-sidebar.service';
import { IonInfiniteScroll, IonContent } from '@ionic/angular';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)',
      })),
      state('out', style({
        transform: 'translate3d(110%,0,0)',
      })),
    ]),
  ]
})
export class NotificationSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(IonContent, { static: false }) ionContent: IonContent;
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  isOpened: boolean = false;
  messages$ = this.notificationSidebarService.getNotifications$();
  unreadCount$ = this.notificationSidebarService.getUnreadCount$();
  sidebarState$ = this.notificationSidebarService.getSidebarState$()
    .pipe(
      tap(sidebarState => {
        this.setIsOpen(sidebarState);
        if (sidebarState === 'out' && this.ionContent) {
          this.scrollToTop();
        }
      })
    );

  private destroy$ = new Subject();

  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (!this.eRef.nativeElement.contains(event.target) && this.isOpened) {
      this.toggle();
    }
  }

  constructor(
    private eRef: ElementRef,
    private notificationSidebarService: NotificationSidebarService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.notificationSidebarService.init(this.infiniteScroll);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.notificationSidebarService.destroy();
  }

  toggle(): void {
    this.notificationSidebarService.toggle();
  }

  readMessage(message: INotificationMessage<any>) {
    this.notificationSidebarService.markAsRead(message);
  }

  loadMoreData(ev): void {
    this.notificationSidebarService.loadMoreNotifications();
  }

  markAllAsRead(): void {
    this.notificationSidebarService.markAllAsRead();
  }

  trackByFn(index, item: INotificationMessage<IBaseNotification>) {
    if (!item) {
      return index;
    }
    return JSON.stringify(item);
  }

  private scrollToTop() {
    of(true)
      .pipe(
        delay(1000),
        first(),
      )
      .subscribe(() => this.ionContent.scrollToTop());
  }

  private setIsOpen(sidebarState: 'in' | 'out') {
    of(true)
      .pipe(
        delay(200),
        first(),
      )
      .subscribe(() => this.isOpened = sidebarState === 'in');
  }
}
