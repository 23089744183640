import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export enum InvitedUsersListViewEventType {
  INIT,
  DESTROY,
  ADD,
  REMOVE,
}

export interface IInvitedUsersListViewEvent {
  type: InvitedUsersListViewEventType;
  ids?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class InvitedUsersListViewService {
  events$: Observable<IInvitedUsersListViewEvent>;

  private eventEmitter$ = new Subject<IInvitedUsersListViewEvent>();

  constructor() {
    this.events$ = this.eventEmitter$.asObservable();
  }

  emitInitEvent(ids: string[]): void {
    this.eventEmitter$.next({ type: InvitedUsersListViewEventType.INIT, ids });
  }

  emitAddEvent(ids: string[]): void {
    this.eventEmitter$.next({ type: InvitedUsersListViewEventType.ADD, ids });
  }

  emitRemoveEvent(id: string): void {
    this.eventEmitter$.next({ type: InvitedUsersListViewEventType.REMOVE, ids: [id] });
  }

  emitDestroyEvent(): void {
    this.eventEmitter$.next({ type: InvitedUsersListViewEventType.DESTROY });
  }
}
