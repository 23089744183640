export enum Onboarding {
  Lessons = 'lessons',
  Subjects = 'subjects',
  FindCreatedSubjects = 'find_created_subjects',
  SubjectStudents = 'subject_students',
  SubjectTasks = 'subject_tasks',
  TaskAttachments = 'task_attachments',
  CommunityChat = 'community_chat',
  Materials = 'materials',
  Meetings = 'meetings',
  SchoolsAndClasses = 'schools_and_classes',
}

export enum GuidePopoverPosition {
  Left = 'left',
  LeftAbove = 'left_above',
  LeftBelow = 'left_below',
  Right = 'right',
  RightAbove = 'right_above',
  RightBelow = 'right_below',
}

export const ONBOARDING_YOUTUBE_PLAYLIST_ID = 'PL0hrHvw81XGZhVpz9jYlZb3kKBHWFIPt8'

export interface IOnboarding<
  OnboardingType extends Onboarding = Onboarding,
  Data = Record<string, any>
> {
  type: OnboardingType
  data?: Data
}

export type FindCreatedSubjectsOnboardingPayload = IOnboarding<
  Onboarding.FindCreatedSubjects,
  { title: string }
>

export interface OnboardingLink {
  title: string
  videoId: string
  dur: string
}

export const OnboardingLinks: Record<'en' | 'de', Record<string, OnboardingLink[]>> = {
  en: {
    [Onboarding.Lessons]: [

    ],
    [Onboarding.Subjects]: [
      {
        title: 'Creating a Subject',
        videoId: '9BqUpEDAL9U',
        dur: '0:38'
      },
      {
        title: 'Subject dashboard and calendar',
        videoId: 'BZaYZVyT_C8',
        dur: '1:38'
      }
    ],
    [Onboarding.FindCreatedSubjects]: [

    ],
    [Onboarding.SubjectStudents]: [
      {
        title: 'Inviting Students',
        videoId: 'heKpLuysgI0',
        dur: '0:55'
      }
    ],
    [Onboarding.SubjectTasks]: [
      {
        title: 'Creating a Task',
        videoId: 'faRpiNBrVQY',
        dur: '1:13'
      },
      {
        title: 'Inside the Task',
        videoId: 'hA_bUm-Ip9M',
        dur: '1:13'
      },
    ],
    [Onboarding.TaskAttachments]: [
      {
        title: 'Attachements and editing',
        videoId: 'JqUer6pIAww',
        dur: '1:15'
      }
    ],
    [Onboarding.CommunityChat]: [
      {
        title: 'Whiteboard, Chat & Notifications',
        videoId: 'g-CerF89hg0',
        dur: '1:37'
      }
    ],
    [Onboarding.Materials]: [
      {
        title: 'Materials',
        videoId: 'cgox6SHAhV4',
        dur: '1:56'
      }
    ],
    [Onboarding.Meetings]: [
      {
        title: 'Meetings',
        videoId: 'yYYsIuCs0rU',
        dur: '2:07'
      }
    ],
    [Onboarding.SchoolsAndClasses]: [
      {
        title: 'Class',
        videoId: 'UXUdJoHzjDY',
        dur: '1:14'
      },
      {
        title: 'School',
        videoId: '9Zy-VVQWsb4',
        dur: '1:14'
      },
    ],
  },
  de: {
    [Onboarding.Lessons]: [

    ],
    [Onboarding.Subjects]: [
      {
        title: 'Ein Fach anlegen',
        videoId: '5zAWYKxeLWM',
        dur: '0:38'
      },
      {
        title: 'Fach-Dashboard und Kalendar',
        videoId: 'uQ90S1Prs1Q',
        dur: '1:38'
      }
    ],
    [Onboarding.FindCreatedSubjects]: [

    ],
    [Onboarding.SubjectStudents]: [
      {
        title: 'Schüler einladen',
        videoId: 'yfEp5_8PG-s',
        dur: '0:55'
      }
    ],
    [Onboarding.SubjectTasks]: [
      {
        title: 'Erstellen einer Aufgabe',
        videoId: '1IOAFQKsXz4',
        dur: '1:13'
      },
      {
        title: 'Innerhalb der Aufgabe',
        videoId: 'XtdPin7jFm0',
        dur: '1:13'
      }
    ],
    [Onboarding.TaskAttachments]: [
      {
        title: 'Anhänge & Bearbeitung',
        videoId: 'd4wP38HyHWE',
        dur: '1:15'
      }
    ],
    [Onboarding.CommunityChat]: [
      {
        title: 'Whiteboard, Chat & Benachrichtigungen',
        videoId: 'uUgiy7bu_kI',
        dur: '1:37'
      }
    ],
    [Onboarding.Materials]: [
      {
        title: 'Materialien',
        videoId: '0NUuOR1F8WE',
        dur: '1:56'
      }
    ],
    [Onboarding.Meetings]: [
      {
        title: 'Meetings',
        videoId: 'eU7q-yLPnW4' ,
        dur: '2:07'
      }
    ],
    [Onboarding.SchoolsAndClasses]: [
      {
        title: 'Klasse',
        videoId: 'fRE5PEu4tLA' ,
        dur: '1:14'
      },
      {
        title: 'Schule',
        videoId: 'On8eWJ1DnMc' ,
        dur: '1:14'
      }
    ],
  },
}

export const OnboardingTargets: Record<Onboarding, { target: string, clickTarget: string }> = {
  [Onboarding.Subjects]:{
    target: '[data-app-menu-item-type="CREATE"]',
    clickTarget: '[data-app-menu-item-type="CREATE"] [data-overlay-click-target]',
  },
  [Onboarding.FindCreatedSubjects]:{
    target: '[data-app-menu-item-type="SUBJECT_GROUP"]',
    clickTarget: '[data-app-menu-item-type="SUBJECT_GROUP"] [data-overlay-click-target]',
  },
  [Onboarding.Lessons]:{
    target: '[data-app-menu-item-type="LESSON_GROUP"]',
    clickTarget: '[data-app-menu-item-type="LESSON_GROUP"] [data-overlay-click-target]',
  },
  [Onboarding.SubjectStudents]:{
    target: '[data-app-menu-item-type="SUBJECT"] [data-overlay-click-target="share"]',
    clickTarget: '[data-app-menu-item-type="SUBJECT"] [data-overlay-click-target="share"]',
  },
  [Onboarding.SubjectTasks]:{
    target: 'ion-button.add-btn',
    clickTarget: 'ion-button.add-btn',
  },
  [Onboarding.TaskAttachments]:{
    target: 'quill-editor .attachments-list',
    clickTarget: 'quill-editor .attachments-list > ion-button',
  },
  [Onboarding.CommunityChat]:{
    target: 'ion-fab-button.chat-fab',
    clickTarget: 'ion-fab-button.chat-fab',
  },
  [Onboarding.Materials]:{
    target: '[data-app-menu-item-type="CREATE"]',
    clickTarget: '[data-overlay-click-target]',
  },
  [Onboarding.Meetings]:{
    target: '[data-app-menu-item-type="MEETING"]',
    clickTarget: '[data-overlay-click-target]',
  },
  [Onboarding.SchoolsAndClasses]:{
    target: '[data-app-menu-item-type="CREATE"]',
    clickTarget: '[data-overlay-click-target]',
  },
}