import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Input, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject, of, Subject, merge } from 'rxjs';
import { IonInput } from '@ionic/angular';
import { GradingService } from '../../services/grading-system.service';
import { filter, takeUntil, tap, pluck, map, switchMap, first, withLatestFrom, share } from 'rxjs/operators';
import { IDefaultGrade, IGrade, IRecentGrade } from '../../models/grade';
import { showErrorIfExists, detectChanges } from '../../helpers/helpers';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { EntityType } from '../../models/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';

const cloneDeep = require('lodash.clonedeep');

@Component({
  selector: 'app-grades-crud',
  templateUrl: './grades-crud.component.html',
  styleUrls: ['./grades-crud.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('.1s ease-out',
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('.2s ease-in',
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradesCrudComponent implements OnInit, OnDestroy {
  @Input() entityId: string;
  @Input() entityType: EntityType;
  @Input() disabled: boolean;
  @Input() canEdit: boolean;
  @Input() defaultGrade: IGrade;
  @Output() close = new EventEmitter();
  @Output() updated = new EventEmitter<IGrade>();
  @ViewChild('newGradeInput', { static: false }) newGradeInput: IonInput;

  gradesDefault$: Observable<IDefaultGrade[]>;
  isDefaultGradesLoading: boolean = true;
  gradesRecent$: Observable<IRecentGrade[]>;
  gradeValues$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  isRecentGradesLoading: boolean = true;
  grade: IGrade;
  isGradeLoading: boolean;
  selectedDefaultGrade$: BehaviorSubject<IDefaultGrade> = new BehaviorSubject(null);
  selectedRecentGrade$: BehaviorSubject<string> = new BehaviorSubject(null);
  isNewGradeInputVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isCustomGradeGeneratorVisible$ = new BehaviorSubject<boolean>(false);

  highestGradeControl = new FormControl(false);
  hasPlusMinusControl = new FormControl(false);

  private destroy$ = new Subject();
  private shouldEmmitControlEvent: boolean = false;

  constructor(
    private gradingService: GradingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.gradesDefault$ = this.getDefaultGrades$();
    this.gradesRecent$ = this.getRecentGrades$();
    this.loadGradeSystem();

    this.selectedDefaultGrade$
      .pipe(
        tap(grade => {
          if (this.grade) {
            this.grade.is_percent = grade ? grade.is_percent : this.grade.is_percent;
          }
        }),
        filter(grade => !!grade),
        tap(grade => {
          this.setGrade(grade, true);
          this.setControlValues(this.grade);
          this.gradeValues$.next(this.grade.gradings);
          this.selectedRecentGrade$.next(null);
          this.publishGradingUpdate();
          this.highestGradeControl.setValue(true, { emitEvent: false });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.selectedRecentGrade$
      .pipe(
        filter(value => !!value),
        withLatestFrom(this.gradesRecent$),
        tap(data => {
          const recentGrade: IRecentGrade = data[1].find(g => g.grade.id === data[0]);
          this.setGrade(recentGrade.grade);
          this.setControlValues(this.grade);
          this.gradeValues$.next(this.grade.gradings);
          this.selectedDefaultGrade$.next(null);
          this.publishGradingUpdate();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.gradingService.notifier$
      .pipe(
        filter(event => this.grade && event.response.payload && event.entityId === this.grade.id),
        tap(() => this.toastService.showToast(this.translateService.instant('create-menu.grading-system-updated'), TOAST_TYPE.SUCCESS)),
        map(event => event.response.payload),
        takeUntil(this.destroy$),
      )
      .subscribe(grade => {
        this.grade = grade;
        this.disabled = false;
        detectChanges(this.cdr);
      });

    const highestGradeChanges$ = this.highestGradeControl.valueChanges
      .pipe(
        filter(() => this.shouldEmmitControlEvent),
        tap(value => {
          this.grade.is_highest_grade = value;
          this.sortGrades(value);
          this.publishGradingUpdate();
        }),
      );
    const hasPlusMinusChanges$ = this.hasPlusMinusControl.valueChanges
      .pipe(
        filter(() => this.shouldEmmitControlEvent),
        tap(value => {
          this.grade.has_plus_minus = value;
          this.publishGradingUpdate();
        }),
      );

    merge(highestGradeChanges$, hasPlusMinusChanges$)
      .pipe(
        filter(() => this.grade && !!this.grade.id),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isDisabled(): boolean {
    return this.isGradeLoading || this.disabled;
  }

  addGrade(value: string) {
    if (value && value.length && value.trim().length) {
      const currentValues = this.gradeValues$.getValue().slice();
      currentValues.push(value.trim());
      this.updateGradingValues(currentValues);
      this.publishGradingUpdate();
      this.newGradeInput.value = '';
      this.newGradeInput.setFocus();
    } else {
      this.isNewGradeInputVisible$.next(false);
    }
  }

  updateGrade(ev: any, index: number) {
    const currentValues = this.gradeValues$.getValue().slice();
    if (currentValues[index] !== ev.target.value) {
      currentValues[index] = ev.target.value;
      this.updateGradingValues(currentValues);
      this.publishGradingUpdate();
    }
  }

  reorderGrades(ev: any) {
    const values: string[] = ev.detail.complete(this.gradeValues$.getValue().slice());
    this.updateGradingValues(values);
    this.publishGradingUpdate();
  }


  removeGrade(index: number, removeGradingAddInput = false): void {
    if (removeGradingAddInput) {
      this.isNewGradeInputVisible$.next(false);
      return;
    }
    const currentValues = this.gradeValues$.getValue().slice();
    currentValues.splice(index, 1);
    this.updateGradingValues(currentValues);
    this.publishGradingUpdate();
  }

  showNewGradeInput(): void {
    if (!this.isNewGradeInputVisible$.value) {
      this.isNewGradeInputVisible$.next(true);
      this.newGradeInput.value = '';
      this.newGradeInput.setFocus();
    }
  }

  getDefaultGradeTitle(grade: IDefaultGrade): string {
    return this.getGradeTitle(grade.gradings, grade.is_percent);
  }

  getRecentGradeTitle(recentGrade: IRecentGrade): string {
    const gradeTitle = this.getGradeTitle(recentGrade.grade.gradings, recentGrade.grade.is_percent);
    return `${gradeTitle} (${recentGrade.entity_name})`;
  }

  generateCustom(gradings: string[]): void {
    this.gradeValues$.next(gradings);
    this.selectedDefaultGrade$.next(null);
    this.updateGradingValues(gradings);
    this.grade.is_highest_grade = true;
    this.publishGradingUpdate();
    this.highestGradeControl.setValue(true, { emitEvent: false });
  }

  getGrade(): IGrade {
    return cloneDeep(this.grade);
  }

  private getDefaultGrades$(): Observable<IDefaultGrade[]> {
    return this.gradingService.getDefaultGrades$()
      .pipe(
        tap(response => showErrorIfExists(response, this.toastService)),
        filter(response => !!response.payload),
        tap(() => this.isDefaultGradesLoading = false),
        pluck('payload'),
        share(),
      );
  }

  private getRecentGrades$(): Observable<IRecentGrade[]> {
    return this.gradingService.getRecentGrades$()
      .pipe(
        tap(response => showErrorIfExists(response, this.toastService)),
        filter(response => !!response.payload),
        tap(() => this.isRecentGradesLoading = false),
        pluck('payload'),
        share(),
      );
  }

  private loadGradeSystem(): void {
    const defaultGrade$ = of(this.defaultGrade ? this.defaultGrade : { gradings: [] } as IGrade);
    const loadGrade$ = of(true)
      .pipe(
        tap(() => this.isGradeLoading = true),
        switchMap(() => this.gradingService.getByType$(this.entityType, this.entityId)),
        tap(response => showErrorIfExists(response, this.toastService)),
        filter(response => !!response.payload),
        tap(() => this.isGradeLoading = false),
        map(response => response.payload[this.entityType]
          ? response.payload[this.entityType]
          : { gradings: [] } as IGrade
        ),
      );

    const request$ = this.entityId ? loadGrade$ : defaultGrade$;

    request$
      .pipe(first())
      .subscribe(grade => {
        this.grade = grade;
        this.setControlValues(this.grade);
        this.gradeValues$.next(this.grade.gradings);
      });
  }

  private publishGradingUpdate(): void {
    if (this.grade && this.grade.id && !this.disabled) {
      this.updated.emit(this.grade);
    }
  }

  private getGradeTitle(gradings: string[], hasPercent: boolean): string {
    return `${gradings[0]}-${gradings[gradings.length - 1]}${hasPercent ? '%' : ''}`;
  }

  private setControlValues(grade: IGrade): void {
    if (grade) {
      this.shouldEmmitControlEvent = false;
      this.highestGradeControl.setValue(grade.is_highest_grade, { emitEvent: false });
      this.hasPlusMinusControl.setValue(grade.has_plus_minus, { emitEvent: false });
      this.shouldEmmitControlEvent = true;
    }
  }

  private setGrade(grade: IDefaultGrade | IGrade, isDefault = false) {
    if (isDefault) {
      this.grade = {
        ...this.grade,
        gradings: grade.gradings.slice(),
        is_percent: grade.is_percent,
        is_highest_grade: false,
        has_plus_minus: false,
      };
    } else {
      this.grade = {
        ...this.grade,
        gradings: grade.gradings.slice(),
        is_percent: grade.is_percent,
        is_highest_grade: (grade as IGrade).is_highest_grade,
        has_plus_minus: (grade as IGrade).has_plus_minus,
      };
    }
  }

  private sortGrades(isHighest: boolean): void {
    const currentGrades = this.gradeValues$.value.slice();
    const sotredGrades = currentGrades.sort((prev, curr) => {
      const a = isNaN(+prev) ? prev : +prev;
      const b = isNaN(+curr) ? curr : +curr;
      if (a > b) {
        return isHighest ? 1 : -1;
      }
      if (a < b) {
        return isHighest ? -1 : 1;
      }
      return 0;
    });
    this.gradeValues$.next(sotredGrades);
    this.updateGradingValues(sotredGrades);
  }

  private updateGradingValues(gradings: string[]): void {
    this.gradeValues$.next(gradings);
    this.grade.gradings = gradings.slice();
    this.selectedDefaultGrade$.next(null);
    this.selectedRecentGrade$.next(null);
  }
}
