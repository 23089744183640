<ion-card>
  <ion-card-content>
    <img src="assets/img/warning-ico-sm.png" width="91" height="90" alt="Warning" />
    <h1 *ngIf="title">{{ title }}</h1>
    <p *ngIf="message">{{ message }}</p>
    <ng-container *ngIf="showCheckbox">
      <app-checkbox class="remove-meeting" [(ngModel)]="isSelected">{{ getToggleText() }}</app-checkbox>
    </ng-container>
    <div *ngIf="showConfirm; else showOk" class="buttons">
        <ion-button class="secondary" mode="md" (click)="close(false)">{{ getCancelBtnText() }}</ion-button>
        <ion-button class="primary" mode="md" (click)="close(true)">{{ getConfirmBtnText() }}</ion-button>
    </div>
    <ng-template #showOk>
      <div class="buttons">
          <ion-button class="primary" mode="md" (click)="close(false)">{{ 'common.btn-ok' | translate }}</ion-button>
      </div>
    </ng-template>
  </ion-card-content>
</ion-card>