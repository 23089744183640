<div class="content">
  <ion-card *ngIf="showLoading$ | async" class="loader-card" color="light">
    <ion-card-content>
      <div class="loader">
        <ion-label>Loading</ion-label>
        <ion-spinner name="crescent"></ion-spinner>
      </div>
    </ion-card-content>
  </ion-card>
  <ion-button *ngIf="showLoading$ | async" class="close-button" color="light" mode="md" (click)="onDismiss()">
    <ion-icon slot="icon-only" class="ico icon-plus"></ion-icon>
  </ion-button>
  
  <div class="body">
    <iframe allow="camera; microphone" sandbox="allow-scripts allow-same-origin allow-popups allow-downloads allow-modals" [src]="url | safeUrl" #iframe frameborder="0" (load)="onLoad(iframe)"></iframe>
  </div>
</div>