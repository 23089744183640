import { INotificationMessage, IBaseNotification, NotificationType, IInviteNotification } from 'src/app/shared/models/notifications';
import { TranslateService } from '@ngx-translate/core';

export function getMsgBody(
  message: INotificationMessage<IBaseNotification>,
  translateService: TranslateService,
): string {
    switch (message.type) {
      case NotificationType.TICKET_TASK_PUBLISHED:
      case NotificationType.TICKET_TASK_CHANGED:
      case NotificationType.TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED:
      case NotificationType.TICKET_TASK_STATE_CHANGED:
      case NotificationType.TICKET_TASK_DUE_DATE_CHANGED:
      case NotificationType.ASSIGNED_TASK_STATE_CHANGE:
      case NotificationType.ASSIGNED_TASK_MESSAGE:
      case NotificationType.STUDENT_COMPLETE_TASK:
      case NotificationType.STUDENT_UPDATE_TASK:
      case NotificationType.TICKET_ANNOUNCE_PUBLISHED:
      case NotificationType.MEETING_ATTENDEE_START_SOON:
      case NotificationType.MEETING_ATTENDEE_START_1_HOUR:
      case NotificationType.MEETING_ATTENDEE_START_5_MIN:
      case NotificationType.MEETING_ATTENDEE_START_10_MIN:
      case NotificationType.TICKET_TASK_DUE_DATE:
      case NotificationType.ADD_TICKET_TASK:
        return message.payload.message;
      case NotificationType.ENTITY_INVITE:
        const msg = translateService.instant(`notifications.invited-to-${(message.payload as IInviteNotification).entity_type}`);
        return `${msg} ${(message.payload as IInviteNotification).message}`;
      default:
        return '';
    }
}
