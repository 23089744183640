<div class="container" [ngClass]="isOpen ? 'expanded' : 'collapsed'">
  <ion-item button class="input select-input" [ngStyle]="{'border-color': isOpen ? '#3399FF' : '#DBE0E2'}" (click)="toggleOpen()" lines="none" mode="md" [disabled]="disable">
    <ion-img class="icon" slot="start" *ngIf="showPlaceholderIcon && getPlaceholderIcon() && getPlaceholderIcon().length" [src]="getPlaceholderIcon()" alt=""></ion-img>
    <ion-input [value]="getPlaceholderValue() ? ((getPlaceholderValue() | translate) + getPlaceholderSuffix()) : null" placeholder="{{placeholder | translate}}" readonly></ion-input>
    <ion-icon *ngIf="!isOpen" class="ico icon-arrow-down" slot="end"></ion-icon>
    <ion-icon *ngIf="isOpen" class="ico icon-arrow-up select-expanded" slot="end"></ion-icon>
  </ion-item>
  <ion-list styledScrollbar [styledScrollbarSelf]="true" class="content" lines="none" [ngClass]="isOpen ? 'show' : 'hide'">
    <ion-item *ngFor="let option of options" [ngClass]="{'active': hasOption(option)}" [class.multi-select]="multiSelect" lines="none" (click)="select(option)">
      <div class="checkbox-content">
        <ion-checkbox *ngIf="multiSelect" slot="start" [checked]="hasOption(option)"></ion-checkbox>
        <img src="assets/img/checkbox-mark.svg" alt="">
      </div>
      <ion-img *ngIf="!multiSelect && option.icon" slot="start" [src]="option.icon" alt=""></ion-img>
      <ion-label>{{ option.title | translate }}</ion-label>
    </ion-item>
  </ion-list>
</div>
