import { UserService } from 'src/app/auth/services/user.service';
import { filter, map } from 'rxjs/operators';
import { SchoolDashboardPermissions, SchoolTicketPermissions } from './school';
import { SubjectDashboardPermissions, SubjectTicketPermissions } from './subject';
import { ClassDashboardPermissions, ClassTicketPermissions } from './schoolClass';
import { MaterialTicketPermissions } from './material';
import * as i0 from "@angular/core";
import * as i1 from "../../../auth/services/user.service";
export class PermissionService {
    constructor(userService) {
        userService.getUser$()
            .pipe(filter(user => !!user), map(user => user.id))
            .subscribe(userId => this.initPermissionInstances(userId));
    }
    initPermissionInstances(userId) {
        this.schoolDashboard = new SchoolDashboardPermissions(userId);
        this.classDashboard = new ClassDashboardPermissions(userId);
        this.subjectDashboard = new SubjectDashboardPermissions(userId);
        this.schoolTicket = new SchoolTicketPermissions(userId);
        this.classTicket = new ClassTicketPermissions(userId);
        this.subjectTicket = new SubjectTicketPermissions(userId);
        this.materialTicket = new MaterialTicketPermissions(userId);
    }
}
PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.ɵɵinject(i1.UserService)); }, token: PermissionService, providedIn: "root" });
