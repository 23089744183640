import { isDevMode } from '@angular/core';
import { PROD_API_CONFIG } from 'src/environments/api/prod.config';
import { STAGE_API_CONFIG } from 'src/environments/api/stage.config';
import { environment } from 'src/environments/environment';
import { EnvironmentMode } from 'src/environments/models';
import * as i0 from "@angular/core";
export class EnvService {
    constructor() {
        this.apiConfig = {
            apiEndpoint: environment.apiEndpoint,
            websocketUrl: environment.websocketUrl,
            janusSignalingUrl: environment.janusSignalingUrl,
            groupworldEndpoint: environment.groupworldEndpoint,
            zulipInstanceEndpoint: environment.zulipInstanceEndpoint,
            zulipApiEndpoint: environment.zulipApiEndpoint,
        };
        this.defaultDevEnvironmentMode = EnvironmentMode.Stage;
        this.initEnvs();
    }
    get serverUrl() {
        if (this.apiConfig.groupworldEndpoint) {
            return this.apiConfig.groupworldEndpoint.slice(0, this.apiConfig.groupworldEndpoint.indexOf('/conf'));
        }
        return window.origin;
    }
    initEnvs() {
        let apiConfig;
        if (isDevMode()) {
            apiConfig = this.defaultDevEnvironmentMode === EnvironmentMode.Stage ? STAGE_API_CONFIG : PROD_API_CONFIG;
            this.apiConfig = apiConfig;
        }
        const displayEnv = apiConfig
            ? Object.assign({}, environment, apiConfig) : environment;
        console.log('%c [ENVIRONMENT]: ', 'color: green', displayEnv);
    }
}
EnvService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnvService_Factory() { return new EnvService(); }, token: EnvService, providedIn: "root" });
