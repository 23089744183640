/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-blocked-notice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "./popup-blocked-notice.component";
import * as i7 from "../../services/platform.service";
var styles_PopupBlockedNoticeComponent = [i0.styles];
var RenderType_PopupBlockedNoticeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupBlockedNoticeComponent, data: {} });
export { RenderType_PopupBlockedNoticeComponent as RenderType_PopupBlockedNoticeComponent };
function View_PopupBlockedNoticeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("warnings.popup-blocked")); _ck(_v, 1, 0, currVal_0); }); }
function View_PopupBlockedNoticeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("blocked-popup.more-info")); _ck(_v, 1, 0, currVal_0); }); }
function View_PopupBlockedNoticeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("blocked-popup.title")); _ck(_v, 0, 0, currVal_0); }); }
function View_PopupBlockedNoticeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "support-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("blocked-popup.content")); _ck(_v, 1, 0, currVal_0); }); }
function View_PopupBlockedNoticeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "support-text"], ["href", "https://support.apple.com/it-it/guide/safari/sfri40696/mac"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("blocked-popup.here")); _ck(_v, 1, 0, currVal_0); }); }
export function View_PopupBlockedNoticeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "warning-icon"], ["height", "144"], ["src", "/assets/img/warning-ico.png"], ["width", "144"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupBlockedNoticeComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupBlockedNoticeComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["genericTItle", 2]], null, 0, null, View_PopupBlockedNoticeComponent_3)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupBlockedNoticeComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupBlockedNoticeComponent_5)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["alt", ""], ["class", "popup-image"], ["height", "204"], ["width", "290"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "ion-button", [["class", "dismiss-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(14, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(15, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.platform.isSafari; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.platform.isSafari; var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = !_co.platform.isSafari; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.platform.isSafari; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.popupImage; _ck(_v, 12, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("common.btn-close")); _ck(_v, 15, 0, currVal_6); }); }
export function View_PopupBlockedNoticeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-blocked-notice", [], null, null, null, View_PopupBlockedNoticeComponent_0, RenderType_PopupBlockedNoticeComponent)), i1.ɵdid(1, 49152, null, 0, i6.PopupBlockedNoticeComponent, [i7.PlatformService], null, null)], null, null); }
var PopupBlockedNoticeComponentNgFactory = i1.ɵccf("app-popup-blocked-notice", i6.PopupBlockedNoticeComponent, View_PopupBlockedNoticeComponent_Host_0, { doDismiss: "doDismiss" }, {}, []);
export { PopupBlockedNoticeComponentNgFactory as PopupBlockedNoticeComponentNgFactory };
