import {DashboardPermissionsAbstract, TicketPermissionsAbstract} from './base.abstract.class';
import {ISubject} from '../../models/subject';
import {IDashboardColumn, UserRole} from '../../models/common';
import {isPublished, isPublishedAndDatePast} from '../../components/ticket-item/helpers';
import {ITicket, StudentTaskState, TeacherTaskState, TicketType} from '../../models/ticket';
import {IAssignedTask} from '../../models/assigned-task';

export class SubjectDashboardPermissions extends DashboardPermissionsAbstract<ISubject> {
  private readonly NOT_EDITED_COLUMN_TITLES: string[] = [
    'task', 'tasks', 'general', 'generals', 'announcements', 'announcement', 'notification', 'notifications',
  ];

  canEditEntity(subject: ISubject): boolean {
    return this.isOwner(subject);
  }

  canRemoveEntity(subject: ISubject): boolean {
    return this.isOwnerOrInvitedTeacher(subject);
  }

  canAddColumn(subject: ISubject): boolean {
    return this.isOwnerOrInvitedTeacher(subject);
  }

  canEditOrRemoveColumn(subject: ISubject, column: IDashboardColumn): boolean {
    if (this.NOT_EDITED_COLUMN_TITLES.includes(column.title.toLowerCase())) {
      return false;
    }

    return this.isOwnerOrInvitedTeacher(subject);
  }

  canAddTicketsToColumn(subject: ISubject, column: IDashboardColumn): boolean {
    return this.isOwnerOrInvitedTeacher(subject);
  }

  canCreateInvitation(subject: ISubject): boolean {
    return this.isOwnerOrInvitedTeacher(subject);
  }

  canInviteStudents(subject: ISubject): boolean {
    return this.isOwnerOrInvitedTeacher(subject);
  }

  canRemoveStudentOrTeacher(subject: ISubject): boolean {
    return this.isOwner(subject);
  }
}

export class SubjectTicketPermissions extends TicketPermissionsAbstract {

  private readonly CAN_EDIT_DUE_DATE_STATUS: string[] = [
      'in_progress', 'to_check', 'draft'
  ];

  canEditOrRemoveEntity(entity: ITicket): boolean {
    if (this.isNewTicket(entity)) {
      return true;
    } else if (entity.type === TicketType.TASK && entity.state === TeacherTaskState.DONE) {
      return false;
    }

    return this.isOwnerOrInvitedTeacher(entity);
  }

  canAddAttachments(entity: ITicket): boolean {
    if (this.isNewTicket(entity)) {
      return true;
    } else if (entity.type === TicketType.TASK && entity.state === TeacherTaskState.DONE) {
      return false;
    } else if (entity.type !== TicketType.TASK && this.isStudent(entity)) {
      return false;
    }

    return this.isOwnerOrInvitedTeacher(entity) || this.isStudent(entity);
  }

  canRemoveAttachments(entity: ITicket): boolean {
    return this.canEditOrRemoveEntity(entity);
  }

  canEditDate(entity: ITicket): boolean {
    return !isPublished(entity) && this.canEditOrRemoveEntity(entity);
  }

  canEditState(entity: ITicket, assignedTask?: IAssignedTask): boolean {
    if (this.isStudent(entity)) {
      return assignedTask
       ? assignedTask.state !== StudentTaskState.CHECKED_AND_FINISHED
       : false;
    }

    return this.isOwnerOrInvitedTeacher(entity);
  }

  canEditSubjectOrDate(entity: ITicket): boolean {
    return this.canEditDate(entity);
  }

  canEditSubjectDueDate(entity: ITicket): boolean {
    return entity.role === UserRole.TEACHER && this.CAN_EDIT_DUE_DATE_STATUS.includes(entity.state);
  }
}
