/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../meeting-attendee/meeting-attendee.component.ngfactory";
import * as i3 from "../meeting-attendee/meeting-attendee.component";
import * as i4 from "../../services/meeting-notification.service";
import * as i5 from "../../services/popover-notification.service";
import * as i6 from "../../services/notification-sidebar.service";
import * as i7 from "../../services/meeting.service";
import * as i8 from "../../services/toast.service";
import * as i9 from "../../../auth/services/user.service";
import * as i10 from "@ngx-translate/core";
import * as i11 from "@angular/router";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../meeting-join/meeting-join.component.ngfactory";
import * as i14 from "../meeting-join/meeting-join.component";
import * as i15 from "../../services/whiteboard.service";
import * as i16 from "@angular/common";
import * as i17 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i18 from "@ionic/angular";
import * as i19 from "../styled-scrollbar/styled-scrollbar.directive";
import * as i20 from "../../services/window.service";
import * as i21 from "../../services/platform.service";
import * as i22 from "./popover-notification.component";
var styles_PopoverNotificationComponent = [i0.styles];
var RenderType_PopoverNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverNotificationComponent, data: {} });
export { RenderType_PopoverNotificationComponent as RenderType_PopoverNotificationComponent };
function View_PopoverNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-meeting-attendee", [], null, null, null, i2.View_MeetingAttendeeComponent_0, i2.RenderType_MeetingAttendeeComponent)), i1.ɵdid(2, 114688, null, 0, i3.MeetingAttendeeComponent, [i4.MeetingNotificationService, i5.PopoverNotificationService, i6.NotificationSidebarService, i7.MeetingService, i8.ToastService, i9.UserService, i10.TranslateService, i1.ChangeDetectorRef, i11.Router, i12.DomSanitizer], { meeting: [0, "meeting"], uuid: [1, "uuid"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.payload; var currVal_1 = _v.parent.context.$implicit.uuid; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PopoverNotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-meeting-join", [], null, null, null, i13.View_MeetingJoinComponent_0, i13.RenderType_MeetingJoinComponent)), i1.ɵdid(2, 245760, null, 0, i14.MeetingJoinComponent, [i4.MeetingNotificationService, i15.WhiteboardService], { meeting: [0, "meeting"], uuid: [1, "uuid"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.payload; var currVal_1 = _v.parent.context.$implicit.uuid; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PopoverNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "popover-notification"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopoverNotificationComponent_2)), i1.ɵdid(3, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopoverNotificationComponent_3)), i1.ɵdid(5, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.type === _co.types.MEETING_ATTENDEE); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === _co.types.MEETING_JOIN); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PopoverNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-card", [["mode", "md"]], [[4, "paddingBottom", null]], null, null, i17.View_IonCard_0, i17.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i18.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "ion-card-content", [["styledScrollbar", ""]], null, null, null, i17.View_IonCardContent_0, i17.RenderType_IonCardContent)), i1.ɵdid(3, 49152, null, 0, i18.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(4, 4341760, null, 1, i19.StyledScollbarDirective, [i1.ElementRef, i20.WindowService, i21.PlatformService], { styledScrollbar: [0, "styledScrollbar"], styledScrollbarSelf: [1, "styledScrollbarSelf"] }, null), i1.ɵqud(603979776, 1, { styledScrollbarContent: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PopoverNotificationComponent_1)), i1.ɵdid(7, 278528, null, 0, i16.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "md"; _ck(_v, 1, 0, currVal_1); var currVal_2 = ""; var currVal_3 = true; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.notifications; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isTotalHeight ? "50px" : "0px"); _ck(_v, 0, 0, currVal_0); }); }
export function View_PopoverNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popover-notification", [], null, null, null, View_PopoverNotificationComponent_0, RenderType_PopoverNotificationComponent)), i1.ɵdid(1, 245760, null, 0, i22.PopoverNotificationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopoverNotificationComponentNgFactory = i1.ɵccf("app-popover-notification", i22.PopoverNotificationComponent, View_PopoverNotificationComponent_Host_0, { addNotification$: "addNotification$", removeNotification$: "removeNotification$", onDismiss: "onDismiss" }, {}, []);
export { PopoverNotificationComponentNgFactory as PopoverNotificationComponentNgFactory };
