import { Injectable } from '@angular/core';
import { Observable, of, TimeoutError } from 'rxjs';
import { IResponse } from '../models/response';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { EnvService } from './env/env.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private httpClient: HttpClient,
    private envService: EnvService,
    private errHandlerService: ErrorHandlerService
  ) { }

  get$<T>(endpoint: string, options = {}): Observable<IResponse<T>> {
    return this.httpClient.get<T>(this.getEndpoint(endpoint), options)
      .pipe(
        map(response => ({ payload: response })),
        catchError(error => of(this.getError(error)))
      );
  }

  post$<T>(endpoint: string, data: any, options = {}): Observable<IResponse<T>> {
    return this.httpClient.post<T>(this.getEndpoint(endpoint), data, options)
      .pipe(
        map(response => ({ payload: response })),
        catchError(error => of(this.getError(error)))
      );
  }

  put$<T>(endpoint: string, data: any, options = {}): Observable<IResponse<T>> {
    return this.httpClient.put<T>(this.getEndpoint(endpoint), data, options)
      .pipe(
        map(response => ({ payload: response })),
        catchError(error => of(this.getError(error)))
      );
  }

  patch$<T>(endpoint: string, data: any, options = {}): Observable<IResponse<T>> {
    return this.httpClient.patch<T>(this.getEndpoint(endpoint), data, options)
      .pipe(
        map(response => ({ payload: response })),
        catchError(error => of(this.getError(error)))
      );
  }

  delete$<T>(endpoint: string, options = {}): Observable<IResponse<T>> {
    return this.httpClient.delete<T>(this.getEndpoint(endpoint), options)
      .pipe(
        map(response => ({ payload: response })),
        catchError(error => of(this.getError(error)))
      );
  }

  getEndpoint = (endpoint: string): string => {
    return `${this.envService.apiConfig.apiEndpoint}/${endpoint}`;
  }

  private getError(error: HttpErrorResponse | TimeoutError): IResponse<any> {
    return { error, message: this.errHandlerService.getUserMessage(error) };
  }
}
