import { Injectable } from '@angular/core';
import { ModalOptions } from '@ionic/core'
import { ModalController } from '@ionic/angular';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { ConfirmAction, IConfirmResponse } from '../models/common';
import { Omit } from '../helpers/helpers';

type ModalUIOptions = Omit<ModalOptions, 'component' | 'componentProps'>

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  async confirmAction(action?: ConfirmAction, entityName?: string, modalOptions: ModalUIOptions = {}): Promise<IConfirmResponse> {
    const defaultCssClass = 'confirm-modal';
    const modal = await this.modalCtrl.create({
      component: ConfirmationModalComponent,
      componentProps: {
        action,
        entityName
      },
      backdropDismiss: true,
      ...modalOptions,
      cssClass: 'cssClass' in modalOptions
        ? [defaultCssClass, modalOptions.cssClass].join(' ')
        : defaultCssClass,
    });
    modal.present();

    const { data } = await modal.onWillDismiss();

    return data ? data : { isConfirmed: false };
  }
}
