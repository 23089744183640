import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ChangeDetectorRef, NgZone } from '@angular/core';
import {
    IAssignedTasksCount,
    EntityType,
    ITeacherTask,
    IStudentTask,
    IDashboardColumn,
    ALLOWED_IMAGES_FORMATS,
    IReceivedEvent,
    EventType,
    ALLOWED_VIDEOS_FORMATS,
    ALLOWED_FILES_FORMATS
} from '../models/common';
import { StudentTaskState, ITicket } from '../models/ticket';
import { IAssignedTask } from '../models/assigned-task';
import { IResponse } from '../models/response';
import * as moment from 'moment';
import { NodeType } from '../components/tree-menu/models/node_type';
import { NEVER as OBSERVABLE_NEVER, Observable, Subject } from 'rxjs';
import { HttpService } from '../services/http.service';
import { ToastService, TOAST_TYPE } from '../services/toast.service';
import { MeetingFrequency } from '../models/meeting';
import { TranslateService } from '@ngx-translate/core';
import { first, filter, takeUntil } from 'rxjs/operators';
import {MOBILE_WINDOW_LANDSCAPE_WIDTH, TABLET_WINDOW_LANDSCAPE_WIDTH} from '../services/window.service';
import { DomSanitizer } from '@angular/platform-browser';
import {UserModel} from "../models/user/user";
import { FileType, IFileAttach } from '../models/file-attach';
import { HttpErrorResponse } from '@angular/common/http';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function isHttpErrorResponse<T extends unknown>(response: IResponse<T>): response is HttpErrorResponse {
    return response.error instanceof HttpErrorResponse;
}

const ADD_ONE_DASHBOARD_COLUMN = (id: string, type: EntityType) => `v1/columns?entityId=${id}&entityType=${type}`;
const EDIT_ONE_DASHBOARD_COLUMN = (id: string, type: EntityType) => `v1/columns?columnId=${id}&entityType=${type}`;
const REMOVE_ONE_DASHBOARD_COLUMN = (id: string, type: EntityType) => `v1/columns?columnId=${id}&entityType=${type}`;

export function hasError(control: AbstractControl, error: string = 'required'): boolean {
    return (control.dirty && control.touched) && control.invalid && control.hasError(error);
}

export function detectChanges(cdf: ChangeDetectorRef): void {
    if (!cdf['destroyed']) {
        cdf.detectChanges();
    }
}

export function getAssignedTasksCount(task: ITeacherTask): IAssignedTasksCount {
    const total = task.assignedTasks.length;
    const waitingCount = task.assignedTasks
        .filter(t => t.state === StudentTaskState.WAITING_FOR_CHECK || t.state === StudentTaskState.CHECKED_AND_FINISHED).length;
    const checkedCount = task.assignedTasks
        .filter(t => t.state === StudentTaskState.CHECKED_AND_FINISHED).length;

    return {
        total,
        [StudentTaskState.WAITING_FOR_CHECK]: waitingCount,
        [StudentTaskState.CHECKED_AND_FINISHED]: checkedCount,
        waitingPercent: waitingCount / total,
        checkedPercent: checkedCount / total,
    };
}

export function canShowAssignedTasksScales(task: ITeacherTask): boolean {
    return task && task.ticket && task.ticket.id && !task.ticket.should_not_verificate && task.assignedTasks && !!task.assignedTasks.length;
}

export function getItemForTicketContextMenu(item: ITeacherTask | IStudentTask | ITicket, entityType: EntityType): ITicket | IAssignedTask {
    if (item && entityType) {
        if (entityType === EntityType.TICKET_TASK) {
            return (item as ITeacherTask).ticket;
        } else if (entityType === EntityType.ASSIGNED_TASK) {
            return (item as IStudentTask).assignedTask;
        }
        return item as ITicket;
    }
    return null;
}

export function isSuccess(response: IResponse<any>): boolean {
    return !response.error && !!response.payload;
}

export function getDaysRange(startDate: string, endDate: string): string[] {
    const dates: string[] = [];

    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');
    dates.push(currDate.clone().toISOString());

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().toISOString());
    }
    dates.push(lastDate.clone().toISOString());

    return dates;
  }

export function getDate(date: string): string {
    return moment(date).format('DD.MM.YYYY');
}

export function getDayName(date: string): string {
    return moment(date).format('dddd');
}

export function isTodayDate(date: string): boolean {
    return moment(date).isSame(moment().startOf('day'), 'd');
}

export function getStartDate(dateRange: string[]): string {
    return dateRange ? dateRange[0] : '';
}

export function getEndDate(dateRange: string[]): string {
    return dateRange && dateRange.length > 0 ? dateRange[dateRange.length - 1] : '';
}

export function getEntityTypeFromNode(nodeType: NodeType): EntityType {
    switch (nodeType) {
        case NodeType.SCHOOL:
            return EntityType.SCHOOL;
        case NodeType.CLASS:
            return EntityType.CLASS;
        case NodeType.SUBJECT:
            return EntityType.SUBJECT;
        case NodeType.MATERIAL:
            return EntityType.MATERIAL;
        default:
            return null;
    }
}

export function isImage(name: string): boolean {
    if (!name) {
        return false;
    }

    const ext = /(?:\.([^.]+))?$/.exec(name)[1].toLocaleLowerCase();

    return ALLOWED_IMAGES_FORMATS.includes(ext);
}

export function isVideo(name: string): boolean {
    if (!name) {
        return false;
    }

    const ext = /(?:\.([^.]+))?$/.exec(name)[1].toLocaleLowerCase();

    return ALLOWED_VIDEOS_FORMATS.includes(ext);
}

export function isFile(name: string): boolean {
    if (!name) {
        return false;
    }

    const ext = /(?:\.([^.]+))?$/.exec(name)[1].toLocaleLowerCase();

    return ALLOWED_FILES_FORMATS.includes(ext);
}

export function getFileType(name: string): FileType {
    let fileType: FileType;
    if (isFile(name)) {
        fileType = FileType.DOCUMENT;
    } else if (isImage(name)) {
        fileType = FileType.IMAGE;
    } else if (isVideo(name)) {
        fileType = FileType.VIDEO;
    }
    return fileType;
}

export function getImageSrc(file: IFileAttach, getEndpoint: (url: string) => string, ticketParent?: string): string {
    return getEndpoint(`v1/download-preview/${file.id}${ticketParent ? '?parentType=' + ticketParent : ''}`);
}

export function addDashboardColumn$(
    column: IDashboardColumn,
    entityId: string,
    entityType: EntityType,
    httpService: HttpService,
): Observable<IResponse<IDashboardColumn>> {
    return httpService.post$(ADD_ONE_DASHBOARD_COLUMN(entityId, entityType), { title: column.title });
}

export function editDashboardColumn$(
    column: IDashboardColumn,
    entityType: EntityType,
    httpService: HttpService,
): Observable<IResponse<IDashboardColumn>> {
    return httpService.patch$(EDIT_ONE_DASHBOARD_COLUMN(column.id, entityType), column);
}

export function removeDashboardColumn$(
    columnId: string,
    entityType: EntityType,
    httpService: HttpService,
): Observable<IResponse<IDashboardColumn>> {
    return httpService.delete$(REMOVE_ONE_DASHBOARD_COLUMN(columnId, entityType));
}

export function trackById<T extends { id: string } = any>(index: number, entry) {
    return entry.id;
}

export function isTheSameDay(dayOne: string, dayTwo: string): boolean {
    return moment(dayOne).isSame(dayTwo, 'day');
}

export function getTicketDateForCalendarPages(task: IStudentTask): string {
    return task.ticket.due_date ? task.ticket.due_date : task.ticket.published_date;
}

export function showErrorIfExists(response: IResponse<any>, toastService: ToastService): void {
    if (response.error && response.message) {
        toastService.showToast(response.message, TOAST_TYPE.ERROR);
    }
}

export function createResizeObserver(elem: Element & HTMLElement) {
    if (!('ResizeObserver' in window)) {
        return OBSERVABLE_NEVER;
    }

    return new Observable(observer => {
        const resizeObserver = new (window as any).ResizeObserver(values => observer.next(values));
        resizeObserver.observe(elem);
        return () => {
            resizeObserver.disconnect();
            observer.complete();
        };
    });
}

export function createMutationObserver(elem: Element & HTMLElement, options: MutationObserverInit) {
    if (!('MutationObserver' in window)) {
        return OBSERVABLE_NEVER;
    }

    return new Observable(observer => {
        const mutationObserver = new MutationObserver(values => observer.next(values));
        mutationObserver.observe(elem, options);
        return () => {
            mutationObserver.disconnect();
            observer.complete();
        };
    });
}

export function generateId() {
    const payload = new Uint8Array(7);
    window.crypto.getRandomValues(payload);

    return payload.join('');
}

export function parseHtmlText(value: string) {
    return new DOMParser().parseFromString(value, 'text/html').documentElement.textContent;
}

export function getFrequencyText(frequency: MeetingFrequency, step: number, translateService: TranslateService): string {
    switch (frequency) {
        case MeetingFrequency.NONE: {
          return translateService.instant('meeting.frequency-none');
        }
        case MeetingFrequency.DAY:
        case MeetingFrequency.WEEK:
        case MeetingFrequency.MONTH: {
            const message: string = step > 1
                ? translateService.instant(`meeting.frequency-every_n_${frequency}`, { step })
                : translateService.instant(`meeting.frequency-every_${frequency}`);
            return message;
        }
        default: return '';
    }
}

export function getFileExtension(filename: string): string {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

export function getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
}

export function fileToBase64(file: Blob) {
    return new Promise<string>((resolve, reject) => {
        const fileReader = getFileReader();
        fileReader.onload = () => resolve(fileReader.result as string);
        fileReader.onerror = (err) => reject(err);
        fileReader.readAsDataURL(file);
    })
}

export function validateArrayControlLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): Record<string, any> | null => {
        return Array.isArray(control.value) && control.value.length < minLength
            ? { 'arrayControlLength': { minLength } }
            : null
    }
}

export function createRecord<DataEntry, RecordKey extends keyof DataEntry>(data: DataEntry[], key: RecordKey) {
    return data.reduce((accum, curr) => {
        accum.set(curr[key], curr);
        return accum;
    }, new Map<DataEntry[RecordKey], DataEntry>())
}

/**
 * Run given function after change detection cycle that might occur at the time of sync call
 *
 * Prevents "ExpressionChangedAfterItHasBeenCheckedError" or cases
 * when view(component bindings) deos not reflect model changes.
 */
export function runOnNextTick(zone: NgZone, cb: () => void) {
    zone.onStable.pipe(first()).subscribe(cb);
}

export function entityExists<T>(entity: T | void | null): entity is T {
    return entity != null;
}

export function listenForDashboardColumnUpdates(
    notifier$: Observable<IReceivedEvent<IDashboardColumn>>,
    entityId: string,
    destroy$: Subject<any>,
    updateColumns: (eventType: EventType, column: IDashboardColumn) => void,
): void {
    notifier$
      .pipe(
        filter(event => isSuccess(event.response) && event.entityId === entityId &&
          [EventType.ADD_COLUMN, EventType.EDIT_COLUMN, EventType.REMOVE_COLUMN].includes(event.eventType)
        ),
        takeUntil(destroy$),
      )
      .subscribe(event => {
        updateColumns(event.eventType, event.response.payload as IDashboardColumn);
      });
  }


export const forbiddenNameValidator = (nameRe: RegExp): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const forbidden = nameRe.test(control.value);
        return forbidden ? {forbiddenName: {value: control.value}} : null;
    };
};

export function backgroundAsStyle(value, sanitizer: DomSanitizer) {
    return value ? sanitizer.bypassSecurityTrustStyle(`--background: ${value}`) : null;
}

export const isMobileLandscapeWindow = (): boolean => {
    return window.innerWidth <= MOBILE_WINDOW_LANDSCAPE_WIDTH;
};

export const isTabletLandscapeWindow = (): boolean => {
    return window.innerWidth <= TABLET_WINDOW_LANDSCAPE_WIDTH;
};

export const timeZone = (): number => {
    return (new Date().getTimezoneOffset() / -60) * 60;
}


export const getUserWithAvatar = async (user: UserModel, sanitizer: DomSanitizer) => {
    let avatar = '';

    if (typeof user.avatarUrl === 'string') {
        avatar = user.avatarUrl;
        user.avatarUrl = sanitizer.bypassSecurityTrustUrl(avatar) as string;
    } else if (user.avatarUrl !== null && user.avatarUrl.data) {
        const blob = new Blob([new Uint8Array(user.avatarUrl.data).buffer]);
        avatar = await fileToBase64(blob);
        user.avatarUrl = sanitizer.bypassSecurityTrustUrl(avatar) as string;
    } else if (!user.avatarUrl) {
        user.avatarUrl = sanitizer.bypassSecurityTrustUrl('assets/img/boy-placeholder.svg') as string;
    }

    return user;
};

export const isRealString = (value: unknown): value is string => {
    return typeof value === 'string' && value.trim().length > 0;
}

export interface IKeyboardBinding {
    key: number;
    handler: unknown;
    shiftKey?: boolean;
    metaKey?: boolean;
    ctrlKey?: boolean;
    altKey?: boolean;
}

export interface IKeyboardModule {
    bindings: Record<string, IKeyboardBinding[]>;
}

export function changeKeyboardEnterLogic(keyboardModule: IKeyboardModule): void {
    // '13' is the key of Enter button. 4 is an index of binding Enter + Shift combination.
    const handleEnter = keyboardModule.bindings['13'][4].handler;

    keyboardModule.bindings['13'][4] = {
      key: 13,
      shiftKey: true,
      handler: handleEnter,
    };
}

/** Bumps given date to `limit` date preserving hours and minutes of original date */
export function bumpToArbituaryFutureDate(date: moment.MomentInput, limit = new Date(2030, 0, 1)): string {
    const dateMoment = moment(date)
    return moment(limit)
        .set('hours', dateMoment.get('hours'))
        .set('minutes', dateMoment.get('minutes'))
        .toISOString();
}

type SetStraightTimeParams = { hours: number, minutes: number };

export function setStraightTime(
    date: moment.Moment,
    timeParamsOrHours: number | SetStraightTimeParams = 0,
    minutes: number = 0
): moment.Moment {
    if (typeof timeParamsOrHours === 'object') {
        const timeParams = {
            minutes: 0,
            hours: 0,
            ...timeParamsOrHours,
        }
        return date.clone().set({ ...timeParams, s: 0, ms: 0 });
    }
    return date.clone().set({ hours: timeParamsOrHours, minutes, s: 0, ms: 0 });
}

export function getTextFileBlob(content: string) {
  return new Blob(
    [ content ],
    { type: 'text/plain;charset=utf-8' }
  )
}

export function base64ToArrayBuffer(base64) {
    const binaryStr = window.atob(base64);
    const len = binaryStr.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryStr.charCodeAt(i);
    }
    return bytes.buffer;
  }

  export const URL_REGEXP = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/

  export function isValidURL(url: unknown) {
    return isRealString(url) && (url.startsWith('http') || url.startsWith('https')) && URL_REGEXP.test(url)
  }
