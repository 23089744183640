import * as tslib_1 from "tslib";
import { ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { Subject } from 'rxjs';
import { PopoverNotificationComponent } from '../components/popover-notification/popover-notification.component';
import { tap, delay, filter } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/services/auth.service";
export class PopoverNotificationService {
    constructor(resolver, authService) {
        this.resolver = resolver;
        this.authService = authService;
        this.addNotificationEmitter$ = new Subject();
        this.removeNotificationEmitter$ = new Subject();
        this.addNotification$ = new Subject();
        this.isPopoverCreated = false;
        this.listenAuthState();
        this.listenAddNotification();
    }
    setViewContainer(container) {
        this.popoverViewContainer = container;
    }
    addNotification(notification) {
        this.addNotificationEmitter$.next(notification);
    }
    removeNotification(notificationId) {
        this.removeNotificationEmitter$.next(notificationId);
    }
    removeMeetingAttendeeNotification(meetingId) {
        if (this.popoverComponent) {
            const notification = this.popoverComponent.notifications.find(n => n.payload.id === meetingId);
            if (notification) {
                this.removeNotification(notification.uuid);
            }
        }
    }
    getPopoverNotification(type, payload) {
        return {
            uuid: uuidv4(),
            type,
            payload,
        };
    }
    displayPopover() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.isPopoverCreated) {
                this.isPopoverCreated = true;
                this.popoverViewContainer.clear();
                const factory = this.resolver.resolveComponentFactory(PopoverNotificationComponent);
                const componentRef = this.popoverViewContainer.createComponent(factory);
                componentRef.instance.addNotification$ = this.addNotification$.asObservable();
                componentRef.instance.removeNotification$ = this.removeNotificationEmitter$.asObservable();
                componentRef.instance.onDismiss = () => {
                    componentRef.destroy();
                    this.isPopoverCreated = false;
                };
                this.popoverComponent = componentRef.instance;
            }
        });
    }
    listenAddNotification() {
        this.addNotificationEmitter$
            .pipe(filter(() => this.authService.isAuthenticated), tap(() => this.displayPopover()), delay(100), tap(notification => this.addNotification$.next(notification)))
            .subscribe();
    }
    listenAuthState() {
        this.authService.isAuthenticated$
            .pipe(tap(isAuthenticated => {
            if (!isAuthenticated) {
                this.isPopoverCreated = false;
            }
        }), filter(isAuthenticated => !isAuthenticated && !!this.popoverViewContainer))
            .subscribe(() => this.popoverViewContainer.clear());
    }
}
PopoverNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopoverNotificationService_Factory() { return new PopoverNotificationService(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i1.AuthService)); }, token: PopoverNotificationService, providedIn: "root" });
