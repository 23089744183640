<ng-container *ngIf="!isLoaded">
  <ion-item class="loading ion-margin-top">
    <ion-skeleton-text style="height: 25px;" animated></ion-skeleton-text>
  </ion-item>
</ng-container>
<tree-root [nodes]="nodes" [options]="options" (initialized)="treeUpdated()" (updateData)="treeUpdated()">
  <ng-template #treeNodeWrapperTemplate let-node let-index="index">
    <div class="node-wrapper" [hidden]="isNodeHidden(node.data)" [style.padding-left]="node.getNodePadding()">
      <div class="node-content-wrapper" [class.node-content-wrapper-active]="isActiveNode(node)" [class.node-content-wrapper-focused]="isActiveNode(node)" (click)="node.mouseAction('click', $event)">
        <ion-item [class.sub-item]="isNodeSubItem(node)" [class.mobile-menu-item]="isMobile" *ngIf="!collapsed; else collapsedTreeItem" [attr.data-app-menu-item-type]="node.data.type">
          <img *ngIf="node.data.icon" slot="start" [src]="node.data.icon" (click)="onNodeClicked(node)">
          <ion-button *ngIf="!collapsed && node.data.showVideoButton" class="video" fill="clear" mode="md" (click)="openVideo.emit(node)">
            <ion-icon slot="icon-only" class="ico icon-camera"></ion-icon>
          </ion-button>
          <ion-label class="font-light ion-activatable" [ngClass]="{'font-extra-bold': isActiveNode(node)}" (click)="onNodeClicked(node)" data-overlay-click-target>
            {{ getNodeLabel(node.data.name) }}
            <ion-ripple-effect></ion-ripple-effect>
          </ion-label>
          <ion-button *ngIf="!collapsed && node.data.showButtons && isShareBtnVisible(node.data)" slot="end" fill="clear" mode="md" (click)="onShareBtnClicked(node)" data-overlay-click-target="share">
            <ion-icon slot="icon-only" class="ico icon-share"></ion-icon>
          </ion-button>
          <ion-button *ngIf="!collapsed && node.data.showButtons && node.data.editEnabled || node.data.type === 'CREATE'" slot="end" fill="clear" mode="md" (click)="onEditBtnClicked(node)">
            <ion-icon slot="icon-only" class="ico icon-edit"></ion-icon>
          </ion-button>
          <ion-button  *ngIf="isMobile && canShowCollapseBtn(node.data)" slot="end" fill="clear" mode="md" (click)="onNodeClicked(node, true)">
            <ion-icon slot="icon-only" name="chevron-forward-outline" class="ico icon-arrow-forward"></ion-icon>
          </ion-button>
        </ion-item>
        <ng-template #collapsedTreeItem>
          <ion-item class="tree-item-collapsed" [attr.data-app-menu-item-type]="node.data.type">
            <div class="tree-item-collapsed__content">
              <img *ngIf="node.data.icon" slot="start" [src]="node.data.icon" (click)="onNodeClicked(node)">
              <ion-label class="font-light" [ngClass]="{'font-extra-bold': isActiveNode(node)}" (click)="onNodeClicked(node)" data-overlay-click-target>{{ getNodeLabel(node.data.name) }}</ion-label>
            </div>
          </ion-item>
        </ng-template>
      </div>
    </div>
  </ng-template>
</tree-root>
