import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentFactory, ComponentRef } from '@angular/core';
import { CreateMenuComponent } from '../components/create-menu/create-menu.component';
import { take, filter, mapTo, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Observable, merge } from 'rxjs';
import { SubjectService } from './subject.service';
import { Router } from '@angular/router';
import { EventType, EntityType, IBaseEntity, ICreateMenuPayload } from '../models/common';
import { NavController, ModalController } from '@ionic/angular';
import { MaterialService } from './material.service';
import { SchoolService } from './school.service';
import { ClassService } from './class.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class EntityCrudViewService {

  private createMenuContainer: ViewContainerRef;
  private forceDestroy$: Observable<void>;
  private readonly MIN_WIDTH = 768;

  constructor(
    authService: AuthService,
    private subjectService: SubjectService,
    private materialService: MaterialService,
    private schoolService: SchoolService,
    private classService: ClassService,
    private router: Router,
    private navCtrl: NavController,
    private resolver: ComponentFactoryResolver,
    private modalCtrl: ModalController,
  ) {
    this.forceDestroy$ = authService.isAuthenticated$
      .pipe(filter(isAuthenticated => !isAuthenticated), mapTo(null));

    merge(
      this.subjectService.notifier$,
      this.materialService.notifier$,
      this.schoolService.notifier$,
      this.classService.notifier$,
    )
    .pipe(
      filter(event => event.eventType === EventType.REMOVE),
      filter(event => this.router.url.includes(event.entityId)),
      tap(() => this.navCtrl.navigateRoot('pages')),
    )
    .subscribe();
  }

  setCreateMenuContainer(container: ViewContainerRef): void {
    this.createMenuContainer = container;
  }

  openCreateMenu(entity?: IBaseEntity, entityType?: EntityType, payload?: ICreateMenuPayload): void {
    this.createMenuContainer.clear();
    if (window.innerWidth < this.MIN_WIDTH) {
      return this.displayInModal(entity, entityType, payload);
    }
    return this.displayNearSideMenu(entity, entityType, payload);
  }

  private displayInModal(entity?: IBaseEntity, entityType?: EntityType, payload?: ICreateMenuPayload): void {
    this.modalCtrl.create({
      id: '1',
      component: CreateMenuComponent,
      componentProps: {
        entity,
        entityType,
        payload,
        hasModalStyle: true,
      },
    })
    .then(modal => modal.present());
  }

  private displayNearSideMenu(entity?: IBaseEntity, entityType?: EntityType, payload?: ICreateMenuPayload): void {
    const factory: ComponentFactory<CreateMenuComponent> = this.resolver.resolveComponentFactory(CreateMenuComponent);
    const componentRef: ComponentRef<CreateMenuComponent> = this.createMenuContainer.createComponent(factory);
    componentRef.instance.entity = entity;
    componentRef.instance.entityType = entityType;
    componentRef.instance.payload = payload;
    merge(this.forceDestroy$, componentRef.instance.destroyEmitter)
      .pipe(take(1))
      .subscribe(() => componentRef.destroy());
  }
}
