<ion-card mode="md" (click)="onNotificationClick()">
  <ng-container *ngIf="message.type === notificationTypes.MEETING_ATTENDEE || message.type === notificationTypes.MEETING_ATTENDEE_CHANGED || message.type === notificationTypes.MEETING_ATTENDEE_STATUS_UPDATES; else show">
    <app-meeting-attendee (closeSidebar)="closeSidebar.emit()" [notification]="message"></app-meeting-attendee>
  </ng-container>
  <ng-template #show>
    <div class="content">
      <div class="header">
        <div class="title">
          <ion-text class="created-on font-light">
            {{ formattedDate }}
            <div *ngIf="!message.is_read" class="unread"></div>
          </ion-text>
          <ng-container *ngIf="msgTitle">
            <ion-text class="new-task font-bold">{{ msgTitle }}</ion-text>
          </ng-container>
        </div>
        <div *ngIf="isOpenBtnVisible" class="button">
          <ion-button class="open-btn" fill="clear" mode="md" (click)="open()">
            <img src="assets/img/open-ic.svg" slot="icon-only" alt="">
          </ion-button>
        </div>
      </div>
      <div class="body">
        <ng-container *ngIf="message.type === notificationTypes.ASSIGNED_TASK_STATE_CHANGE || message.type === notificationTypes.TICKET_TASK_STATE_CHANGED">
          <div class="state">
            <img src="assets/img/in-progress-ic.svg" alt="">
            <ion-text>{{ getTranslatedState() | translate }}</ion-text>
          </div>
          <ng-container *ngIf="isAssignedTaskDone">
            <div class="marks">
              <div *ngIf="mark" class="mark">
                <ion-text class="font-regular">{{ 'notifications.mark' | translate }}: </ion-text>
                <span class="font-extra-bold">{{ mark }}</span>
              </div>
              <div *ngIf="subjectMark" class="mark">
                <ion-text class="font-regular">{{ 'notifications.subject-mark' | translate }}: </ion-text>
                <span class="font-extra-bold">{{ subjectMark }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="message.type === notificationTypes.STUDENT_COMPLETE_TASK || message.type === notificationTypes.STUDENT_UPDATE_TASK">
          <div class="user">
            <img src="assets/img/boy-placeholder.svg" alt="">
            <ion-text class="font-semi-bold">{{ studentFullName }}</ion-text>
          </div>
        </ng-container>
        <ng-container *ngIf="isMeetingAttendeeNotification">
          <ion-text class="meeting-label">{{ meetingData?.meeting_type }}</ion-text>
        </ng-container>
        <ion-text class="body-msg font-light">{{ msgBody | truncate:[50] }}</ion-text>
        <ng-container *ngIf="isMeetingAttendeeNotification">
          <div class="user meeting-user">
            <img src="assets/img/boy-placeholder.svg" alt="">
            <ion-text class="font-semi-bold">{{ meetingData?.meeting_owner_fullname }}</ion-text>
          </div>
        </ng-container>
        <ng-container *ngIf="isMeetingAttendeeNotification">
          <div class="meeting-frequency">
            <ion-text>
              {{ meetingData?.meeting_start_date | dateLocale: 'shortTime' }}-{{ meetingData?.meeting_end_date | dateLocale: 'shortTime' }}
            </ion-text>
            <ng-container *ngFor="let frequencyDate of meetingFrequencies">
              <ion-text>
                {{ frequencyDate.start_date | dateLocale: 'shortTime' }}-{{ frequencyDate.end_date | dateLocale: 'shortTime' }} {{ frequencyDate.start_date | dateLocale: 'd.MM.yyyy' }}
              </ion-text>
              <ion-text>
                {{ frequencyDate.start_date | dateLocale: '(EEEE)' }}&nbsp;{{ getFrequencyText(frequencyDate) }}
              </ion-text>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="parentTicketName && parentTicketName.length">
          <ion-badge class="subject" [style]="backgroundAsStyle(parentTicketColor)">{{ parentTicketName }}</ion-badge>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ion-card>
