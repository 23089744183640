import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { CalendarCellDirective } from './calendar-cell.directive';

@NgModule({
  declarations: [ CalendarCellDirective ],
  imports: [ CommonModule ],
  exports: [ CalendarCellDirective ]
})
export class CalendarCellModule {}
