<ion-content styledScrollbar="shadow-root" appRemoveContentSizing scroll-events="true">
  <ion-badge
    *ngIf="displayNewMessageNotice$ | async"
    (click)="handleNewMessageNoticeClick()"
    class="new-message-notice"
    id="new-message-notice">
    {{ 'chat.log_new_message_notice' | translate }}
    <ion-button (click)="handleNewMessageNoticeDismiss()" type="button" class="new-message-notice-btn">
      <ion-icon id="new-message-notice-close" name="checkmark-done-outline" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-badge>
  <ion-item
    *ngIf="!(chatStreamLoading$ | async) && !((messages$ | async)?.length > 0)"
    class="no-messages-placeholder" lines="none">
    <ng-container
      *ngIf="selectedChannel$ | async as selectedChannel"
      [ngSwitch]="selectedChannel?.meta.type">
      <span *ngSwitchCase="'channel'">
        {{ 'chat.no_channel_messages_placeholder' | translate }}&nbsp;<span class="channel-title">#{{ selectedChannel?.title }}</span>
      </span>
      <span *ngSwitchCase="'pm'">
        {{ 'chat.no_pm_messages_placeholder' | translate }}&nbsp;<span class="pm-members-list">{{ selectedChannel?.membersList }}</span>
      </span>
    </ng-container>
  </ion-item>
  <ion-list
    lines="none"
    #chatList
    [class.align-bottom]="resetScrollPosition$ | async"
    [style.bottom.px]="contentOffsetBottom$ | async"
    styledScrollbarContent>
    <ion-item *ngFor="let entry of messages$ | async; trackBy: trackById" #chatListItem [attr.id]="entry.id">
      <div
        class="message-container"
        [renderAttachment]="entry.hasAttachments"
        [renderAttachmentTemplate]="renderAttachmentsTempl"
        [renderAttachmentId]="entry.id">
        <p *ngIf="entry.dateChange" class="date-label">{{ entry.created_at | dateLocale: 'd MMM y' }}</p>
        <div class="message" [class.unread]="!entry.flags?.includes('read') && !isTempLocalMessage(entry)">
          <div class="message-header">
            <div class="start">
              <ion-avatar>
                <img [attr.src]="entry?.sender?.avatarUrl || 'assets/img/boy-placeholder.svg'" />
              </ion-avatar>
              <ion-text class="sender">{{ entry?.sender?.fullName || 'deactivated' }}</ion-text>
              <span class="unread-bullet">&bull;</span>
            </div>
            <ion-text class="date">{{ entry.created_at | dateLocale: 'hh:mm' }}</ion-text>
          </div>
          <div
            class="message-content"
            [innerHTML]="entry.content | unescapeHtml">
          </div>
          <ng-container renderAttachmentsOutlet></ng-container>
        </div>
      </div>
    </ion-item>
  </ion-list>
  <footer slot="fixed" #footerEl>
    <ion-fab
      slot="fixed"
      class="scroll-down-fab">
      <ion-fab-button
        *ngIf="(displayScrollDownButton$ | async) || (chatStreamLoading$ | async)"
        [disabled]="chatStreamLoading$ | async"
        (click)="scrollDown()"
        type="button" mode="ios" size="small">
        <ion-icon [hidden]="(chatStreamLoading$ | async)" name="chevron-down-outline"></ion-icon>
        <ion-spinner [hidden]="!(chatStreamLoading$ | async)"></ion-spinner>
      </ion-fab-button>
    </ion-fab>

    <ion-list
      *ngIf="messageFormGroup.get('attachments').valid"
      class="msg-attachments-list"
      styledScrollbar="default"
      styledScrollbarSelf="true">
      <ng-container *ngFor="let entry of messageFormGroup.get('attachments').value">
        <ion-item class="msg-attachment-entry" lines="none">
          <ion-text>{{ entry.name }}</ion-text>
          <ion-button (click)="removeAttachment(entry)" fill="clear" slot="end" class="remove-attachment-btn">
            <ion-icon name="close-outline" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-item>
      </ng-container>
    </ion-list>
    <form [formGroup]="messageFormGroup" (ngSubmit)="send()">
      <quill-editor
        #quillEditorEl
        formControlName="content"
        id="chat-log-message-editor-box"
        (onEditorCreated)="onQuillEditorCreated($event)"
        (onFocus)="onQuillEditorFocus()"
        (onBlur)="onQuillEditorBlur()"
        [trimOnValidation]="true"
        [required]="true"
        [disabled]="sendInProgress$ | async"
        customToolbarPosition="bottom"
        class="message-box"
        [placeholder]="'chat.message-placeholder' | translate"
        (keyup.enter)="send()">
        <div quill-editor-toolbar>
          <span class="ql-formats">
            <ion-button fill="clear">
              <button type="button" class="ql-bold"></button>
            </ion-button>
            <ion-button fill="clear">
              <button type="button" class="ql-underline"></button>
            </ion-button>
            <ion-button fill="clear">
              <button type="button" class="ql-italic"></button>
            </ion-button>
          </span>
          <div class="attachments-list">
            <ion-button (click)="fileInput.click()" fill="clear">
              <img src="assets/img/add-file.svg" slot="icon-only" alt="">
            </ion-button>
            <ion-button fill="clear">
              <button type="button" class="ql-link"></button>
            </ion-button>
            <ion-button [disabled]="sendInProgress$ | async" type="submit" class="submit-btn">
              <ion-spinner *ngIf="sendInProgress$ | async; else defaultSubmitBtnLabel"></ion-spinner>
              <ng-template #defaultSubmitBtnLabel>
                <span>{{ 'common.btn-send' | translate }}</span>
              </ng-template>
            </ion-button>
          </div>
        </div>
      </quill-editor>
    </form>
  </footer>
</ion-content>

<input type="file" (change)="handleFile($event)" #fileInput multiple hidden />

<ng-template #renderAttachmentsTempl let-ctx>
  <app-message-attachments
    [messageId]="ctx.id"
    [files]="ctx.files"
    [images]="ctx.images"
    [class.has-images]="ctx.images?.length > 0">
  </app-message-attachments>
</ng-template>
