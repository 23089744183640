import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { ToastService, TOAST_TYPE } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
const { Clipboard } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  copy(text: string): void {
    Clipboard.write({
      string: text,
    });
    this.toastService.showToast(this.translateService.instant('common.copied'), TOAST_TYPE.WARNING);
  }
}
