import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { trackById } from '../../helpers/helpers';
import { Onboarding, OnboardingLink } from '../../models/onboarding';
import { OnboardingService } from '../../services/onboarding.service';

interface OnboardingEntry {
  id: string
  title: string
  desc: string
  links: Array<OnboardingLink & { url: string }>
}

@Component({
  selector: 'app-onboarding-guide-menu',
  templateUrl: './onboarding-guide-menu.component.html',
  styleUrls: ['./onboarding-guide-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingGuideMenuComponent implements OnInit, OnDestroy {
  guides: OnboardingEntry[]
  expanded$ = new ReplaySubject<string>()
  trackBy = trackById
  hideHintsControl: FormControl
  destroy$ = new Subject()

  constructor(
    private onboardingService: OnboardingService,
  ) {
    this.guides = Object
      .values(Onboarding)
      .filter(entry => ![Onboarding.FindCreatedSubjects, Onboarding.Lessons].includes(entry))
      .map(entry => {
        return {
          id: entry,
          title: `onboarding.${ entry }_title`,
          desc: `onboarding.${ entry }`,
          links: this.onboardingService.getOnboardingLinks(entry)
        }
      })
    this.expanded$.next(this.guides[0].id)
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit() {
    const displayHints = this.onboardingService.getDisplayHintsSettings()
    this.hideHintsControl = new FormControl(!displayHints)
    this.hideHintsControl.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$)
      )
      .subscribe(value => this.onboardingService.setDisplayHintsSettings(!value))
  }

  expand(onboardingId) {
    this.expanded$.next(onboardingId)
  }

}
