import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, first, shareReplay } from "rxjs/operators";
import { Onboarding, IOnboarding, OnboardingTargets, GuidePopoverPosition } from "../models/onboarding";
import { OverlayHighlightOptions } from "./onboarding.service";

interface ShowOverlayAction<OnboardingType extends Onboarding = Onboarding> {
  options: OverlayHighlightOptions
  onboarding: IOnboarding<OnboardingType>
}

@Injectable({ providedIn: 'root' })
export class OnboardingGuideService {
  private readonly activeGuide$ = new BehaviorSubject<Onboarding>(null)
  private readonly showOverlay$ = new BehaviorSubject<ShowOverlayAction | null>(null)

  constructor() {
    this.showOverlay$
      .pipe(
        filter(options => options == null)
      )
      .subscribe(() => this.activeGuide$.next(null))
  }
  
  onShowOverlay$(): Observable<ShowOverlayAction> {
    return this.showOverlay$.asObservable().pipe(shareReplay(1))
  }

  async showGuide(onboarding: IOnboarding): Promise<IOnboarding | void> {
    const activeGuide = this.activeGuide$.getValue()

    if (activeGuide != null) {
      await this.activeGuide$
        .pipe(
          first(guide => guide == null)
        )
        .toPromise()
    }

    try {
      this.activeGuide$.next(onboarding.type)
      const onboardingTargetsConfig = OnboardingTargets[onboarding.type]
      if (onboardingTargetsConfig != null) {
        const { target, clickTarget } = onboardingTargetsConfig
        return this.showOverlay(onboarding, target, clickTarget)
      }
    } catch(err) {
      console.error(err);
    }
  }

  private showOverlay(
    onboarding: IOnboarding,
    targetSlector: string,
    clickTargetSelector?: string,
  ): Promise<IOnboarding> {
    const guideComplete$ = new Subject<IOnboarding>()
    const focusElem = document.querySelector<HTMLElement>(targetSlector)
    const clickTarget = clickTargetSelector && document.querySelector<HTMLElement>(clickTargetSelector)

    if (focusElem == null) {
      throw new Error(`Can't find an element to highlight(${ targetSlector })`)
    }

    if (clickTargetSelector != null && clickTarget == null) {
      console.warn(new Error(`Can't find click target to delegate to(${ clickTargetSelector })`))
    }

    const focusElemRect = focusElem.getBoundingClientRect()
    const overlayHighlightPaddingX = 5
    const overlayHighlightPaddingY = 10
    const options: OverlayHighlightOptions = {
      top: focusElemRect.top - overlayHighlightPaddingY,
      left: focusElemRect.left - overlayHighlightPaddingX,
      width: focusElemRect.width + overlayHighlightPaddingY,
      height: focusElemRect.height + overlayHighlightPaddingY * 2,
      borderRadius: 5,
      timeout: 2000,
      delegateClick: () => {
        clickTarget && clickTarget.click()
        this.showOverlay$.next(null)
        guideComplete$.next(onboarding)
        guideComplete$.complete()
      },
      onDismiss: () => {
        this.showOverlay$.next(null)
        guideComplete$.next(onboarding)
        guideComplete$.complete()
      }
    }

    this.showOverlay$.next({ onboarding, options })
    return guideComplete$.toPromise()
  }

}