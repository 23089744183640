import { Component, Input, OnInit, EventEmitter, Output, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { IStreamId } from 'src/app/shared/models/chat';
import { selectChatStreamInfo } from 'src/app/store/selectors/chat.selectors';

@Component({
  selector: 'app-chat-info-page',
  templateUrl: './chat-info.page.html',
  styleUrls: ['./chat-info.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatInfoPage implements OnInit, OnChanges {
  @Input() selectedChannelId: IStreamId;
  @Output() back = new EventEmitter<void>();

  public title$: Observable<string>;
  private selectedChannelId$ = new BehaviorSubject<IStreamId>(null);

  constructor(private store: Store) { }

  ngOnInit() {
    const title$ = this.selectedChannelId$.pipe(
      filter(id => id != null),
      distinctUntilChanged(),
      switchMap((selectedChannelId) => this.store.select(selectChatStreamInfo, selectedChannelId)),
      filter(stream => stream != null),
      map(stream => stream.title)
    )
    this.title$ = concat(of(''), title$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedChannelId' in changes) {
      this.selectedChannelId$.next(changes['selectedChannelId'].currentValue);
    }
  }

}
