import { ChangeDetectionStrategy, Component, Input, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IOnboarding, OnboardingLink } from '../../models/onboarding';
import { OnboardingService } from '../../services/onboarding.service';

export interface OnboardingGuideComponentProps {
  onboarding: IOnboarding
  doDismiss?: () => void
}

@Component({
  selector: 'app-onboarding-guide',
  templateUrl: './onboarding-guide.component.html',
  styleUrls: ['./onboarding-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingGuideComponent implements OnInit, OnDestroy, OnboardingGuideComponentProps {
  @Input() onboarding: IOnboarding
  @Input() doDismiss: () => void

  @Output() onDismiss = new EventEmitter<void>()

  title: string = ''
  description: string = ''
  links: Array<OnboardingLink & { url: string }> = []
  hideHintsControl: FormControl

  private destroy$ = new Subject<void>()

  constructor(
    private onboardingService: OnboardingService,
  ) { }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit() {
    this.title = `onboarding.${this.onboarding.type}_title`
    this.description = `onboarding.${this.onboarding.type}`
    this.links = this.onboardingService.getOnboardingLinks(this.onboarding.type)

    const displayHints = this.onboardingService.getDisplayHintsSettings()
    this.hideHintsControl = new FormControl(!displayHints)

    this.hideHintsControl.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$)
      )
      .subscribe((value) =>
        this.onboardingService.setDisplayHintsSettings(!Boolean(value))
      )
  }
  
  dismiss() {
    typeof this.doDismiss === 'function' && this.doDismiss()
    this.onDismiss.next()
  }

}
