import { EntityType, TicketParent } from '../models/common';
import * as moment from 'moment';
import { StudentNotifierService } from './student-notifier.service';
import { MaterialService } from './material.service';
import * as i0 from "@angular/core";
import * as i1 from "./student-notifier.service";
import * as i2 from "./material.service";
export class UpdateNotificationHelperService {
    constructor(studentNotifierService, materialService) {
        this.studentNotifierService = studentNotifierService;
        this.materialService = materialService;
    }
    displayCanUpdateModal(parentType, entity, entityType) {
        if (parentType === TicketParent.MATERIAL) {
            this.showUpdateNotificationModalForMaterialTicket(parentType, entity, entityType);
        }
        else if (entityType === EntityType.TICKET_NOTIFICATION) {
            this.showUpdateNotificationModalForTicketNotification(parentType, entity, entityType);
        }
        else if (entityType === EntityType.TICKET_GENERAL) {
            this.showUpdateNotificationModalForTicketGeneral(parentType, entity, entityType);
        }
        else {
            this.studentNotifierService.notifyAboutTicketChange(entity, entityType, parentType);
        }
    }
    showUpdateNotificationModalForTicketNotification(parentType, entity, entityType) {
        if (moment(new Date()).isAfter(moment(entity.notify_at))) {
            this.studentNotifierService.notifyAboutTicketChange(entity, entityType, parentType);
        }
    }
    showUpdateNotificationModalForTicketGeneral(parentType, entity, entityType) {
        if (moment(new Date()).isAfter(moment(entity.make_visible_at))) {
            this.studentNotifierService.notifyAboutTicketChange(entity, entityType, parentType);
        }
    }
    showUpdateNotificationModalForMaterialTicket(parentType, entity, entityType) {
        this.materialService.shouldNotifyAboutMaterialTicketUpdate$(entity.id)
            .toPromise()
            .then(canNotify => {
            if (canNotify) {
                this.studentNotifierService.notifyAboutTicketChange(entity, entityType, parentType);
            }
        });
    }
}
UpdateNotificationHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateNotificationHelperService_Factory() { return new UpdateNotificationHelperService(i0.ɵɵinject(i1.StudentNotifierService), i0.ɵɵinject(i2.MaterialService)); }, token: UpdateNotificationHelperService, providedIn: "root" });
