export enum NodeType {
    CREATE = 'CREATE',
    SCHOOL_GROUP = 'SCHOOL_GROUP',
    SCHOOL = 'SCHOOL',
    CLASS_GROUP = 'CLASS_GROUP',
    CLASS = 'CLASS',
    SUBJECT_GROUP = 'SUBJECT_GROUP',
    SUBJECT = 'SUBJECT',
    SUBJECT_DASHBOARD = 'SUBJECT_DASHBOARD',
    SUBJECT_CALENDAR = 'SUBJECT_CALENDAR',
    MATERIAL_GROUP = 'MATERIAL_GROUP',
    MATERIAL = 'MATERIAL',
    LESSON_GROUP = 'LESSON_GROUP',
    LESSON = 'LESSON',
    LESSON_CALENDAR = 'LESSON_CALENDAR',
    MEETING = 'MEETING',
}
