import { ZulipService } from './zulip.service';
import { DownloadLocallyService } from './download-locally.service';
import { base64ToArrayBuffer } from '../helpers/helpers';
import * as i0 from "@angular/core";
import * as i1 from "./zulip.service";
import * as i2 from "./download-locally.service";
export class ChatAttachmentsService {
    constructor(zulipService, downloadLocallyService) {
        this.zulipService = zulipService;
        this.downloadLocallyService = downloadLocallyService;
    }
    downloadFileAttachment(entry) {
        return this.zulipService.getFile(entry.link)
            .toPromise()
            .then(res => new Response(res).arrayBuffer())
            .then(res => {
            this.downloadLocallyService.download(entry.name, res);
        });
    }
    downloadImageAttachment(imageDataUrl, name) {
        this.downloadLocallyService.download(name, base64ToArrayBuffer(imageDataUrl.split('base64,')[1]));
    }
    /** Open base64-encoded image in new browser tab */
    previewImage(imageDataUrl, fileName) {
        let windowRef = window.open('', '_blank');
        let image = new Image();
        image.src = imageDataUrl;
        image.alt = fileName;
        windowRef.document.write(image.outerHTML);
        windowRef.document.title = fileName;
        image = windowRef = void (0);
    }
}
ChatAttachmentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatAttachmentsService_Factory() { return new ChatAttachmentsService(i0.ɵɵinject(i1.ZulipService), i0.ɵɵinject(i2.DownloadLocallyService)); }, token: ChatAttachmentsService, providedIn: "root" });
