export enum InviteType {
  STUDENT = 'student',
  TEACHER = 'teacher',
}

export interface IInviteModel {
  id?: string;
  invite_code?: string;
  invite_url?: string;
  valid_hours?: number;
  vacant_places?: number;
  created_on?: string;
  type?: InviteType;
}

export class ActiveInvites {
  public teacher: IInviteModel;
  public student: IInviteModel;

  constructor(data: IInviteModel[]) {
    if (data) {
      this.teacher = data.find(i => i.type === InviteType.TEACHER);
      this.student = data.find(i => i.type === InviteType.STUDENT);
    }
  }
}
