import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatNavPage } from './chat-nav.page';
import { ChatNavComponentModule } from 'src/app/shared/components/chat-nav/chat-nav.module';
import { RemoveContentSizingDirectiveModule } from 'src/app/shared/directives/remove-content-sizing/remove-content-sizing.module';
import { StyledScrollbarModule } from 'src/app/shared/components/styled-scrollbar/styled-scrollbar.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChatNavComponentModule,
    StyledScrollbarModule,
    TranslateModule,
    RemoveContentSizingDirectiveModule
  ],
  declarations: [ChatNavPage],
  exports: [ChatNavPage]
})
export class ChatNavPageModule {}
