import { Injectable } from '@angular/core';
import { EntityType } from '../models/common';
import { PopoverController } from '@ionic/angular';
import { InviteModalComponent } from '../components/invite-modal/invite-modal.component';
import { InviteType } from '../components/invite-modal/models';

@Injectable({
  providedIn: 'root'
})
export class InviteModalService {

  constructor(
    private popoverCtrl: PopoverController,
  ) { }

  async open(entityId: string, entityType: EntityType, inviteType: InviteType = InviteType.STUDENT): Promise<void> {
    const inviteModal = await this.popoverCtrl.create({
      componentProps: {
          entityId,
          entityType,
          preselectedUserType: inviteType
      },
      component: InviteModalComponent,
      backdropDismiss: true,
      cssClass: 'invite-modal',
    });

    return inviteModal.present();
  }
}
