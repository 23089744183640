import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, isDevMode, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonContent, IonItem } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { QuillEditor } from 'ngx-quill';
import produce from 'immer';
import { BehaviorSubject, combineLatest, concat, EMPTY, from, fromEvent, iif, Observable, of, race, ReplaySubject, Subject, timer, zip, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, exhaustMap, filter, first, map, mapTo, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom, delay, startWith } from 'rxjs/operators';
import { selectChatStreamAnchor, selectChatStreamInfo, selectChatStreamLoading, selectChatStreamMembers, selectChatStreamMessages, selectDraftByTempId, selectFirstUnread, selectLoadRemoteMessagesSuccess } from 'src/app/store/selectors/chat.selectors';
import { createMutationObserver, createResizeObserver, generateId, trackById, validateArrayControlLength, changeKeyboardEnterLogic } from '../../helpers/helpers';
import { ChatMember, IChatStream, IMessage, IMessageId, IStreamId, NewMessageStatus } from '../../models/chat';
import * as ChatActions from 'src/app/store/actions/chat'
import { ZulipService } from '../../services/zulip.service';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ChatLogControllerService } from './chat-log-controller.service';
import { WindowService } from '../../services/window.service';

const CONTENT_BOTTOM_OFFSET = '102px';

type MessageVM = IMessage & {
  dateChange?: boolean;
  created_at?: string;
  sender?: {
    fullName: string,
    avatarUrl: string,
  }
}

interface ListItemPosition {
  id: IMessageId
  y: number
}

@Component({
  selector: 'app-chat-log',
  templateUrl: './chat-log.component.html',
  styleUrls: ['./chat-log.component.scss'],
  providers: [ ChatLogControllerService ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatLogComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() selectedChannelId: IStreamId;

  @ViewChild(IonContent, { static: false, read: ElementRef }) contentRef: ElementRef<HTMLElement>;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('footerEl', { static: false, read: ElementRef }) contentFooter: ElementRef;
  @ViewChild('chatList', { static: false, read: ElementRef }) chatList: ElementRef<HTMLElement>;
  @ViewChild('quillEditorEl', { static: false, read: ElementRef }) quillEditorEl: ElementRef;
  @ViewChildren('chatListItem') chatListItems: QueryList<IonItem>;

  messages$: Observable<MessageVM[]>;
  chatStreamLoading$ = new BehaviorSubject<boolean>(false);
  displayNewMessageNotice$ = new BehaviorSubject<boolean>(false);
  resetScrollPosition$ = new BehaviorSubject<boolean>(false);
  contentOffsetBottom$ = new Subject<number>();
  displayScrollDownButton$ = new BehaviorSubject<boolean>(false);
  trackById = trackById;
  messageFormGroup = new FormGroup({
    content: new FormControl(null, [ Validators.required ]),
    attachments: new FormControl([], [ validateArrayControlLength(1) ])
  });
  contentBottomOffset = CONTENT_BOTTOM_OFFSET;
  selectedChannel$: Observable<IChatStream & { membersList?: string }>;
  sendInProgress$ = new Subject<boolean>();

  private selectedChannelId$ = new ReplaySubject<IStreamId>();
  private destroy$ = new Subject();
  private quillEditorClickListenerDestroy$ = new Subject<void>();
  private quillEditor: QuillEditor;

  constructor(
    private store: Store,
    private zulipService: ZulipService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private toastService: ToastService,
    private chatLogController: ChatLogControllerService,
    private windowService: WindowService,
  ) { }

  ngOnInit() {
    // Show loader while messages are loading and rendering
    this.selectedChannelId$.pipe(
      switchMap((selectedChannelId) =>
        this.store.select(selectChatStreamLoading, selectedChannelId)
      ),
      takeUntil(this.destroy$)
    )
    .subscribe((loading) => {
      this.chatStreamLoading$.next(loading);
      this.cdr.detectChanges();
    });

    // Fetch stream members(users) from eStudy api and assign their info to messages
    const members$ = this.selectedChannelId$.pipe(
      switchMap(selectedChannelId => this.store.pipe(selectChatStreamMembers(selectedChannelId)))
    );
    const chatStreamMembers$ = concat(of([]), members$).pipe(
      map(members => members.reduce((accum, entry) => {
        accum[entry.zulip_uid] = entry;
        return accum;
      }, {})),
      shareReplay(1)
    );
    const messages$ = this.selectedChannelId$.pipe(
      switchMap(
        selectedChannelId => this.store.select(selectChatStreamMessages, selectedChannelId)
      )
    );

    this.messages$ = combineLatest([ messages$, chatStreamMembers$ ]).pipe(
      map(([ messages, members ]) => {
        if (messages.length > 0) {
          return this.prepareMessagesVm(messages, members)
        }
        return [];
      }),
      shareReplay(1)
    );

    this.selectedChannelId$.pipe(
      switchMap((streamId) => this.store.select(selectFirstUnread, streamId)),
      map((firstUnread) => (firstUnread != null)),
      takeUntil(this.destroy$),
    )
    .subscribe((shouldDisplay) => {
      this.displayNewMessageNotice$.next(shouldDisplay);
      this.cdr.detectChanges();
    });

    this.selectedChannel$ = this.selectedChannelId$.pipe(
      switchMap(id => combineLatest([
        this.store.select(selectChatStreamInfo, id),
        this.store.pipe(
          selectChatStreamMembers(id),
          withLatestFrom(this.zulipService.currentUser$),
          map(([users, currentUser]) =>
            users.filter(user => user.zulip_uid !== currentUser.user_id)
          )
        )
      ])),
      map(([ channel, members ]) =>
        ({
          ...channel,
          membersList: members.map(({ fullName }) => fullName).join(', ')
        })
      )
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedChannelId' in changes) {
      this.selectedChannelId$.next(changes['selectedChannelId'].currentValue);
    }
  }

  ngOnDestroy() {
    this.quillEditor.blur();
    this.destroy$.next();
    this.destroy$.complete();
    this.chatLogController.complete();
  }

  ngAfterViewInit() {
    const initialScroll$ = new Subject();
    const chatListResize$ = this.chatListRendered$();
    const scrollElem$ = from(this.content.getScrollElement());

    // Init messages lazy-loading when user scrolls to most top of the chat list
    initialScroll$
      .pipe(
        first(),
        switchMap(() =>
          combineLatest([ this.selectedChannelId$, scrollElem$ ])
        ),
        switchMap(([ streamId, scrollElem]) => this.initLazyLoading(streamId, scrollElem)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Scroll to bottom after initial messages set has rendered
    this.selectedChannelId$
      .pipe(
        switchMap(() => combineLatest([ chatListResize$, scrollElem$ ]).pipe(first())),
        switchMap(([, scrollElem]) =>
          zip(
            this.content.ionScrollEnd,
            this.content.scrollByPoint(0, scrollElem.scrollHeight, 32),
          ).pipe(first())
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (isDevMode()) {
          console.log('\tinitialScroll$.next');
        }
        initialScroll$.next()
      });

    // Mark as read when user scroll approaches unread messages
    this.getUnreadMessagesPositions$().pipe(
      withLatestFrom(this.isUserActive$()),
      filter(values => values[1]),
      map(values => values[0]),
      withLatestFrom(scrollElem$),
      switchMap(([unreadMessagesPositions, scrollElem]) => {
        if (unreadMessagesPositions.length === 0) {
          return of([]);
        }
        const forceCheck = new Promise<void>(resolve => {
          if (scrollElem.scrollHeight <= scrollElem.offsetHeight) {
            resolve();
          }
        });
        return race(from(forceCheck), this.content.ionScrollEnd).pipe(
          map(() =>
            unreadMessagesPositions.filter(msg =>
              msg.y >= scrollElem.scrollTop && msg.y < (scrollElem.scrollTop + scrollElem.offsetHeight)
            )
          )
        )
      }),
      filter(messages => messages.length > 0),
      withLatestFrom(this.selectedChannelId$),
      map(([ messages, selectedChannelId ]) => messages.map(
        ({ id }) => ({ id: Number(id), streamId: selectedChannelId })
      )),
      takeUntil(this.destroy$)
    )
    .subscribe((messages) => {
      this.store.dispatch(ChatActions.markMessagesRead({ messages }));
    })

    // If user stays at the bottom of the chat history(latest messages)
    // listen for new messages and keep scroll at this position
    // until user scrolls up
    combineLatest([this.getUnreadMessages$(), scrollElem$, initialScroll$])
      .pipe(
        filter(([ messages, scrollElem ]) =>
          // check if user received new messages and scrollTop is around bottom scroll boundary
          messages.length > 0 && scrollElem.scrollTop + scrollElem.offsetHeight + 48 >= scrollElem.scrollHeight
        ),
        // wait for messages to be rendered
        switchMap(() => chatListResize$.pipe(first())),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.content.scrollToBottom());

    // Display scroll-down button if user scrolled at least 1 view-port above bottom-line
    combineLatest([ scrollElem$, initialScroll$ ])
      .pipe(
        switchMap(([ scrollElem ]) => this.content.ionScrollEnd.pipe(
          map(() =>
            scrollElem.scrollTop <= scrollElem.scrollHeight - scrollElem.offsetHeight * 2
          ),
          distinctUntilChanged(),
        )),
        takeUntil(this.destroy$)
      )
      .subscribe(display => {
        this.displayScrollDownButton$.next(display);
        this.cdr.detectChanges();
      });

    combineLatest([ scrollElem$, initialScroll$ ])
      .pipe(
        switchMap(([ scrollElem ]) => {
          return concat(timer(100), this.content.ionScrollEnd).pipe(
            map(() => {
              const approxMessageHeight = 178;
              const visibilityState = this.getChatListItemsPositions().reduce((accum, item) => {
                accum[item.id] = (
                  // subtracting `approxMessageHeight` to give more room for lazy-loading
                  item.y >= (scrollElem.scrollTop - approxMessageHeight) &&
                  item.y <= scrollElem.scrollTop + scrollElem.offsetHeight
                );
                return accum;
              }, {} as Record<string, boolean>);
              return visibilityState;
            })
          )
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(visibilityState => {
        this.chatLogController.updateVisibilityState(visibilityState);
      });
  }

  handleFile(event: Event) {
    const attachmentsControl = this.messageFormGroup.get('attachments');
    const files: File[] = attachmentsControl.value || [];
    const newFiles = Array.from((event.target as HTMLInputElement).files);

    for (let file of newFiles) {
      if (Number(((file.size / 1014) / 1024).toFixed(4)) > 25) {
        const msg = this.translateService.instant('chat.attachment_size_limit_error');
        this.toastService.showToast(msg, TOAST_TYPE.ERROR, 'bottom');
      } else {
        files.push(file);
      }
    }

    attachmentsControl.setValue(files);
  }

  removeAttachment(entry: File) {
    const attachmentsControl = this.messageFormGroup.get('attachments');
    attachmentsControl.setValue(
      attachmentsControl.value.filter(_entry => _entry.name !== entry.name)
    )
  }

  isTempLocalMessage(entry: IMessage) {
    const isTempMsg = typeof entry.id === 'string' && entry.id.startsWith('temp');
    return entry.local_id != null || isTempMsg;
  }

  handleNewMessageNoticeDismiss() {
    this.displayNewMessageNotice$.next(false);
    this.store.dispatch(ChatActions.markAllMessagesRead({ streamId: this.selectedChannelId }));
  }

  handleNewMessageNoticeClick() {
    this.maybeLoadAllMessages$()
      .pipe(
        switchMap(() => this.chatListRendered$()),
        first(),
      )
      .subscribe(() => {
        const unreadElem = this.chatList.nativeElement.querySelector<HTMLElement>('.unread');
        const unreadItem = unreadElem ? unreadElem.closest('ion-item') : null;

        if (unreadItem) {
          this.content.scrollToPoint(0, unreadItem.offsetTop - unreadItem.offsetHeight, 100);
        }
      })
  }

  send() {
    if (this.messageFormGroup.get('content').invalid && this.messageFormGroup.get('attachments').invalid) {
      return;
    }

    const loadRemoteMessagesSuccess$ = this.selectedChannelId$.pipe(
      switchMap(selectedChannelId =>
        this.store.select(
          selectLoadRemoteMessagesSuccess,
          selectedChannelId
        )
      )
    );

    this.sendInProgress$.next(true);

    const newMessagePayload$ = of({
      streamId: null,
      content: `<i style="display:none" hidden> @**webhookBot** </i>` + (this.messageFormGroup.value.content || ''),
      timestamp: Date.now() / 1e3,
      tempMessageId: 'temp_' + generateId(),
      hasImages: false,
    }).pipe(
      switchMap(msg => {
        const attachments: File[] = this.messageFormGroup.get('attachments').value;

        if (attachments.length > 0) {
          return this.uploadFilesAsAttachments(attachments).pipe(
            map(uploads => {
              const attachmentsLinks = uploads.map(entry => `[](${ entry.uri })`).join('§§');
              return {
                ...msg,
                content: msg.content + `<div style="display:none" hidden attr-data-msg-attachments>${ attachmentsLinks }</div>`,
                hasImages: attachments.some(entry => entry.type.startsWith('image/'))
              }
            })
          );
        }
        return of(msg);
      }),
      withLatestFrom(this.selectedChannelId$),
      map(([payload, streamId]) => ({ ...payload, streamId }))
    );

    newMessagePayload$.subscribe(payload => {
      this.messageFormGroup.reset();
      this.messageFormGroup.setValue({
        content: '',
        attachments: [],
      });
      this.messageFormGroup.updateValueAndValidity();
      this.sendInProgress$.next(false);
      this.store.dispatch(ChatActions.sendMessage(payload));
    });

    const messageSent$ = newMessagePayload$.pipe(
      switchMap(payload =>
        this.store.select(selectDraftByTempId, payload.tempMessageId)
          .pipe(
            first((draft) => draft != null && draft.status === NewMessageStatus.Sent),
            map(draft => ({ ...payload, ...draft }))
          )
      ),
    );
    const messageRendered$ = combineLatest([ messageSent$, this.chatListRendered$() ]).pipe(
      first(([ newMessage ]) =>
        document.getElementById(newMessage.id as string) != null
      ),
      map(([ newMessage ]) => newMessage)
    );

    zip(messageRendered$, loadRemoteMessagesSuccess$)
      .pipe(
        switchMap(([ newMessage ]) => {
          if (!newMessage.hasImages) return of(newMessage);

          const target = document.getElementById(newMessage.id as string);

          if (target == null) {
            return EMPTY;
          }

          if (newMessage.hasImages && target.querySelector('.msg-attachments-images')) {
            return of(newMessage);
          }

          return createMutationObserver(target, { subtree: true, childList: true }).pipe(
            filter(() => target.querySelector('.msg-attachments-images') != null),
            mapTo(newMessage)
          )
        }),
      )
      .subscribe((newMessage) => {
        this.store.dispatch(
          ChatActions.removeDraftMessage({ draftId: newMessage.tempMessageId })
        );
      })

    // Scroll to bottom when user has sent a new message, so it is visible immediately.
    zip(newMessagePayload$, this.chatListRendered$())
      .pipe(first())
      .subscribe(data => this.scrollDown());

    this.quillEditor.focus();
  }

  scrollDown() {
    this.content.scrollToBottom(100);
  }

  onQuillEditorCreated(editor): void {
    this.quillEditor = editor;

    createResizeObserver(this.quillEditor.container)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const {height} = this.contentFooter.nativeElement.getBoundingClientRect();
        this.contentRef.nativeElement.style.setProperty('--app-offset-bottom', height * -1 + 'px');
        this.content.getScrollElement().then((el: HTMLElement) => {
          // scoll to bottom if scrollTop at around the bottom bound
          // so we can maintain scroll position for user
          if (el.scrollTop / el.scrollHeight > 0.8) {
            el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
          }
        });
      });

    this.quillEditor.root.classList.add('styled-scrollbar', 'has-scroll');

    changeKeyboardEnterLogic(this.quillEditor.getModule('keyboard'));
  }

  onQuillEditorFocus(): void {
    fromEvent(document, 'click')
      .pipe(
        first(event => {
          const editorSelector = '#' + this.quillEditorEl.nativeElement.id
          const withinEditor = null != (event.target as HTMLElement).closest(editorSelector)
          const clickOnSend = (event.target as HTMLButtonElement).type === 'submit'
          return !withinEditor && !clickOnSend
        }),
        takeUntil(race(this.quillEditorClickListenerDestroy$, this.destroy$))
      )
      .subscribe(() => {
        this.quillEditor.blur();
      });

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(event => (event.ctrlKey || event.metaKey) && event.key === 'Enter'),
        takeUntil(race(this.quillEditorClickListenerDestroy$, this.destroy$))
      )
      .subscribe(() => this.send())
  }

  onQuillEditorBlur(): void {
    this.quillEditorClickListenerDestroy$.next();
  }

  private getUnreadMessagesPositions$(): Observable<Array<ListItemPosition>> {
    const chatListChangeRendered$ = this.chatListRendered$().pipe(
      filter(chatList => chatList.offsetHeight > 40)
    );

    const unreadMessages$ = this.getUnreadMessages$();

    return combineLatest([ unreadMessages$, chatListChangeRendered$ ]).pipe(
      map(([ unreadMessages ]) =>
        this.getChatListItemsPositions().filter(itemPosition => unreadMessages.includes(itemPosition.id))
      )
    );
  }

  /** Emit chat list element when rendered */
  private chatListRendered$() {
    return concat(of(null), this.chatListItems.changes).pipe(
      switchMap(() => createResizeObserver(this.chatList.nativeElement).pipe(
        mapTo(this.chatList.nativeElement),
        filter(chatList => chatList.offsetHeight > 20)
      ))
    );
  }

  private getUnreadMessages$() {
    return this.messages$.pipe(
      map(messages =>
        messages.reduce<IMessageId[]>((accum, message) => {
          // If action targets current user's message don't include it in the list
          // since it will be handled on chat store effects level
          const isTempMessage = this.isTempLocalMessage(message);
          const isRead = Array.isArray(message.flags) && message.flags.includes('read');
          if (!isTempMessage && !isRead) {
            accum.push(message.id.toString());
          }
          return accum;
        }, [])
      )
    );
  }

  private getChatListItemsPositions(): ListItemPosition[] {
    return this.chatListItems.map<ListItemPosition>(({ el }: IonItem & { el: HTMLElement }) => (
      {
        id: el.id,
        y: el.offsetTop,
      }
    ))
  }

  private initLazyLoading(streamId: IStreamId, scrollElem: HTMLElement) {
    const loadRemoteMessagesSuccess$ = this.store.select(
      selectLoadRemoteMessagesSuccess,
      streamId
    ).pipe(
      filter(isLoadRemoteMessagesSuccess => isLoadRemoteMessagesSuccess)
    )
    const chatStreamLoading$ = this.store.select(
      selectChatStreamLoading,
      streamId
    );
    const chatStreamAnchor$ = this.store.select(
      selectChatStreamAnchor,
      streamId
    );
    const nextAnchor$ = this.messages$.pipe(
      map(messages => messages.length > 0 ? messages[0].id : null)
    );

    return this.content.ionScroll
      .pipe(
        debounceTime(100),
        // Don't activate until top boundary
        filter(e => e.detail.scrollTop < (this.chatList.nativeElement.offsetTop + 50)),
        switchMap(() =>
          combineLatest([
            chatStreamLoading$,
            chatStreamAnchor$,
            nextAnchor$
          ]).pipe(take(1))
        ),
        filter(([ chatStreamLoading, currentAnchor, nextAnchor ]) =>
          !chatStreamLoading && currentAnchor !== nextAnchor
        ),
        switchMap(([,, anchor]) => this.messages$.pipe(
          first(),
          map(messages => ({ anchor, count: messages.length })),
        )),
        tap(({ anchor }) => {
          this.store.dispatch(ChatActions.loadRemoteMessages({
            streamId: streamId,
            anchor
          }));
        }),
        switchMap(({ count: prevMessagesCount }) => {
          const nextMessagesCount$ = this.messages$.pipe(
            map(messages => messages.length)
          );
          return loadRemoteMessagesSuccess$.pipe(
            withLatestFrom(nextMessagesCount$),
            map(([isLoadRemoteMessagesSuccess, nextMessagesCount]) =>
              isLoadRemoteMessagesSuccess && nextMessagesCount !== prevMessagesCount
            )
          )
        }),
        // filter(stateChanged => stateChanged),
        exhaustMap((stateChanged) =>
          iif(
            () => stateChanged,
            this.maintainScrollPositionOnContentResize(scrollElem),
            of(null)
          )
        ),
        tap(() => {
          this.chatStreamLoading$.next(false);
          this.cdr.detectChanges();
        })
      )
  }

  private maintainScrollPositionOnContentResize(scrollElem: HTMLElement): Observable<void> {
    return of(null).pipe(
      map(() => ({
        prevScrollHeight: scrollElem.scrollHeight,
        prevScrollTop: scrollElem.scrollTop,
      })),
      tap(({ prevScrollHeight, prevScrollTop }) => {
        // Pins growing content container to bottom
        this.resetScrollPosition$.next(true);

        // Evaluate scroll-top position and apply as bottom value
        // This will simulate that scroll position stays at the position when lazy-loading was triggered
        const offsetBottom = prevScrollHeight - prevScrollTop - scrollElem.clientHeight;
        this.contentOffsetBottom$.next(-offsetBottom);
      }),
      switchMap(({ prevScrollHeight, prevScrollTop }) => this.chatListItems.changes.pipe(
        first(),
        tap(() => {
          requestAnimationFrame(() => {
            // Restore original scroll position after all the styles applied
            scrollElem.scrollTop = prevScrollTop + scrollElem.scrollHeight - prevScrollHeight;
          });

          // reset "position" and "bottom" styles
          this.resetScrollPosition$.next(false);
          this.contentOffsetBottom$.next(0);
        })
      )),
    )
  }

  private prepareMessagesVm(messages: IMessage[], members: Record<string, ChatMember>): MessageVM[] {
    return produce(messages, (_messages: MessageVM[]) => {
      _messages.sort((a, b) => a.timestamp - b.timestamp);

      const member = members[_messages[0].sender_id];

      if (member) {
        _messages[0].sender = {
          fullName: `${member.firstname} ${member.lastname}`,
          avatarUrl: member.avatar_url
        }
      }
      _messages[0].dateChange = true;
      _messages[0].created_at = new Date(_messages[0].timestamp * 1e3).toISOString();

      for (let i = 1; i < _messages.length; i++) {
        _messages[i].created_at = new Date(_messages[i].timestamp * 1e3).toISOString();

        const prevDate = moment(_messages[i - 1].created_at);
        if (prevDate.diff(new Date(_messages[i].created_at), 'days') !== 0) {
          _messages[i].dateChange = true;
        }

        const member = members[_messages[i].sender_id];
        if (member) {
          _messages[i].sender = {
            fullName: `${member.firstname} ${member.lastname}`,
            avatarUrl: member.avatar_url
          }
        }
      }
    });
  }

  /**
   * Checks if unread messages list includes historical first unread message.
   * If so - loads entire message history into chat store and resolves.
   * Skips and resolves immediately otherwise.
   */
  private maybeLoadAllMessages$() {
    return this.selectedChannelId$.pipe(
      switchMap(id => {
        const unreads$ = this.getUnreadMessages$();
        const firstUnread$ = this.store.select(selectFirstUnread, id);
        return combineLatest([ unreads$, firstUnread$, of(id) ]);
      }),
      first(),
      switchMap(([ unreads, firstUnread, streamId ]) => {
        if (unreads.includes(firstUnread + '')) {
          return of(null);
        }

        this.store.dispatch(ChatActions.loadRemoteMessages({
          anchor: firstUnread,
          streamId: streamId,
          fetchAll: true
        }));

        const chatStreamLoaded$ = this.store.select(selectChatStreamLoading, streamId).pipe(
          filter(loading => !loading)
        );

        return chatStreamLoaded$;
      })
    )
  }

  private uploadFilesAsAttachments(attachments: File[]): Observable<Array<{ uri: string } | null>> {
    const attachmentsUpload$ = attachments.map(entry =>
      this.zulipService.uploadFile(entry).pipe(
        catchError(() => {
          const msg = this.translateService.instant('chat.attachment_upload_error');
          this.toastService.showToast(msg, TOAST_TYPE.ERROR, 'bottom');
          return of(null);
        })
      )
    );
    return zip(...attachmentsUpload$).pipe(
      map(uploads => uploads.filter(entry => entry != null))
    );
  }

  private isUserActive$(): Observable<boolean> {
    return merge(
      this.windowService.active$.pipe(mapTo(true)),
      this.windowService.idle$.pipe(mapTo(false))
    )
    .pipe(
      startWith(true),
      shareReplay(1),
    );
  }

}
