import { ConfirmationService } from './confirmation.service';
import { ConfirmAction } from '../models/common';
import { HttpService } from './http.service';
import { isPublished } from '../components/ticket-item/helpers';
import * as i0 from "@angular/core";
import * as i1 from "./confirmation.service";
import * as i2 from "./http.service";
export class StudentNotifierService {
    constructor(confirmationService, httpService) {
        this.confirmationService = confirmationService;
        this.httpService = httpService;
        this.POST_NOTIFY_STUDENTS = (id, entityType, parentType) => `v2/tickets/send-notification-changed?entityId=${id}&entityType=${entityType}&parentType=${parentType}`;
    }
    notifyAboutTicketChange(entity, entityType, parentType) {
        if (this.canShowConfimationModal(entity, entityType)) {
            this.isNotificationConfirmed()
                .then(isConfirmed => {
                if (isConfirmed) {
                    return this.notifyAboutTicketChangeReq$(entity.id, entityType, parentType).toPromise();
                }
            });
        }
    }
    canShowConfimationModal(entity, entityType) {
        return !!entity.material_id || isPublished(entity);
    }
    isNotificationConfirmed() {
        return this.confirmationService.confirmAction(ConfirmAction.SHOULD_NOTIFY_USERS)
            .then(isConfirmed => isConfirmed.isConfirmed);
    }
    notifyAboutTicketChangeReq$(ticketId, entityType, parentType) {
        return this.httpService.post$(this.POST_NOTIFY_STUDENTS(ticketId, entityType, parentType), {});
    }
}
StudentNotifierService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentNotifierService_Factory() { return new StudentNotifierService(i0.ɵɵinject(i1.ConfirmationService), i0.ɵɵinject(i2.HttpService)); }, token: StudentNotifierService, providedIn: "root" });
