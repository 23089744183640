import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentsInvitedListComponent } from './students-invited-list.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';

@NgModule({
  declarations: [StudentsInvitedListComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    StyledScrollbarModule
  ],
  exports: [StudentsInvitedListComponent],
})
export class StudentsInvitedListModule { }
