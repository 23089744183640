import { HttpService } from './http.service';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { concatMap, filter, map, share, shareReplay, tap } from 'rxjs/operators';
import { SocketMessageType, UserTypes } from '../models/common';
import { SocketService } from './socket.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./socket.service";
import * as i3 from "../../auth/services/auth.service";
export class InviteService {
    constructor(httpService, socketService, authService) {
        this.httpService = httpService;
        this.socketService = socketService;
        this.authService = authService;
        this.notifyEmitter$ = new Subject();
        this.POST_JOIN_ENTITY = 'v1/join';
        this.GET_ALL_ACTIVE = (id, type) => `v1/invite/status?entityId=${id}&entityType=${type}`;
        this.GET_ALL_USERS = (search, userType, filterEntities, page, limit, shouldAddInvitedUser = false, shouldAddSchoolOwner = true, entityWithUserType, shouldHideDeactivated) => {
            let url = `v1/invite/users?name=${search}&userType=${userType}&filters=${JSON.stringify(filterEntities)}&shouldAddInvitedUser=${shouldAddInvitedUser}&shouldAddSchoolOwner=${shouldAddSchoolOwner}&entityWithUserType=${entityWithUserType}&shouldHideDeactivated=${shouldHideDeactivated}`;
            if (Number.isFinite(page)) {
                url += `&page=${page}`;
            }
            if (Number.isFinite(limit)) {
                url += `&limit=${limit}`;
            }
            return url;
        };
        this.POST_INVITE_ALL = (search, userType, entityId, entityType, filterEntities) => `v1/invite/all?search=${search}&userType=${userType}&inviteEntityId=${entityId}&inviteEntityType=${entityType}&filters=${JSON.stringify(filterEntities)}`;
        this.POST_GENERATE = (id, type) => `v1/invite/generate?entityId=${id}&entityType=${type}`;
        this.POST_DEACTIVATE = (id, type) => `v1/invite/deactivate?entityId=${id}&entityType=${type}`;
        this.POST_INVITE_STUDENT = (id, type) => `v1/invite/student?entityId=${id}&entityType=${type}`;
        this.DELETE_INVITE = (id, type, userId) => `v1/invite/delete?entityId=${id}&entityType=${type}&userId=${userId}`;
        this.pendingJoin$ = new BehaviorSubject(false);
        this.onPendingJoin$ = this.pendingJoin$.asObservable().pipe(shareReplay(1));
        this.notifier$ = this.notifyEmitter$.pipe(share());
        this.socketService.messages$()
            .pipe(filter(() => this.authService.isAuthenticated), filter(message => [SocketMessageType.ENTITY_INVITATION, SocketMessageType.USER_ADD_INVITATION].includes(message.type)), map(message => ({ type: message.type, payload: message.payload })))
            .subscribe(invitationMessage => this.notifyEmitter$.next(invitationMessage));
    }
    joinByCode$(code) {
        return this.httpService.post$(this.POST_JOIN_ENTITY, { inviteCode: code })
            .pipe(tap(response => {
            const payload = response.payload
                ? { entity_id: response.payload.entityId, entity_type: response.payload.entityType }
                : null;
            this.notify(response, { type: SocketMessageType.USER_ADD_INVITATION, payload });
        }));
    }
    getActive$(entityId, type) {
        return this.httpService.get$(this.GET_ALL_ACTIVE(entityId, type));
    }
    generate$(entityId, type, invite) {
        return this.httpService.post$(this.POST_GENERATE(entityId, type), {
            valid_hours: +invite.valid_hours,
            vacant_places: +invite.vacant_places,
            type: invite.type[0]
        });
    }
    deactivate$(entityId, type, invite) {
        return this.httpService.post$(this.POST_DEACTIVATE(entityId, type), {
            type: invite.type[0],
        });
    }
    removeInvitation$(entityId, type, userId) {
        return this.httpService.delete$(this.DELETE_INVITE(entityId, type, userId), {});
    }
    removeInvitations$(entityId, type, userIds) {
        return forkJoin(userIds.map(userId => this.removeInvitation$(entityId, type, userId)));
    }
    createInvitations$(entityId, entityType, usersIds) {
        return this.httpService.post$(this.POST_INVITE_STUDENT(entityId, entityType), { users: usersIds });
    }
    getUsers$(search, userType, filterEntities, page, limit, shouldAddInvitedUser = false, shouldAddSchoolOwner = true, entityWithUserType = UserTypes.ALL, shouldHideDeactivated = false) {
        return this.httpService.get$(this.GET_ALL_USERS(search, userType, filterEntities, page, limit, shouldAddInvitedUser, shouldAddSchoolOwner, entityWithUserType, shouldHideDeactivated));
    }
    inviteAll$(search, userType, inviteEntityId, inviteEntityType, filterEntities) {
        return this.httpService.post$(this.POST_INVITE_ALL(search, userType, inviteEntityId, inviteEntityType, filterEntities), {});
    }
    observeStudentJoined$() {
        const pendingJoin$ = this.pendingJoin$.asObservable();
        const joined$ = this.notifier$.pipe(filter(event => event.type === 'USER_ADD_INVITATION'));
        return pendingJoin$.pipe(concatMap(() => joined$), tap(() => this.pendingJoin$.next(false)));
    }
    startPendingJoin() {
        this.pendingJoin$.next(true);
    }
    notify(response, invitationMessage) {
        if (!response.error && invitationMessage) {
            this.notifyEmitter$.next(invitationMessage);
        }
    }
}
InviteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InviteService_Factory() { return new InviteService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.SocketService), i0.ɵɵinject(i3.AuthService)); }, token: InviteService, providedIn: "root" });
