import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpService } from './http.service';
import { IGrade, IDefaultGrade, IRecentGrade } from '../models/grade';
import {EntityType, EventType} from '../models/common';
import { Observable } from 'rxjs';
import { IResponse } from '../models/response';
import { ToastService } from './toast.service';
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GradingService extends BaseService<IGrade> {

  protected entityType: EntityType = EntityType.GRADING_SYSTEM;
  protected GET_ALL: string = 'v1/gradings';
  protected POST_ONE: string = 'v1/gradings';
  protected PUT_ONE: string = 'v1/gradings';
  protected PATCH_ONE: string = 'v1/gradings';
  protected GET_ALL_DEFAULT: string = 'v1/default-gradings';
  protected GET_ALL_RECENT: string = 'v1/recent-gradings';
  protected GET_ONE: (id: string) => string = (id: string) => `v1/gradings/${id}`;
  protected GET_BY_TYPE = (id: string, type: string) => `v1/gradings?entityType=${type}&entityId=${id}`;
  protected DELETE_ONE: (id: string) => string = (id: string) => `v1/gradings/${id}`;

  constructor(
    protected httpService: HttpService,
    protected toastService: ToastService,
  ) {
    super(httpService, toastService);
  }

  getByType$(entityType: EntityType, entityId: string): Observable<IResponse<{ [key: string]: IGrade }>> {
    return this.httpService.get$(this.GET_BY_TYPE(entityId, entityType));
  }

  getDefaultGrades$(): Observable<IResponse<IDefaultGrade[]>> {
    return this.httpService.get$(this.GET_ALL_DEFAULT);
  }

  getRecentGrades$(): Observable<IResponse<IRecentGrade[]>> {
    return this.httpService.get$(this.GET_ALL_RECENT);
  }

  updateGrading$(grading: IGrade, entityType: string, entityId: string): Observable<IResponse<IGrade>> {
    return this.httpService.patch$(this.PATCH_ONE, {grading, entityType, entityId})
      .pipe(tap(response => {
        this.notifyAboutUpdate(EventType.EDIT, response, { entityId });
      }));
  }
}
