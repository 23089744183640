import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { InviteService } from '../../services/invite.service';
import { Subject } from 'rxjs';
import { takeUntil, filter, exhaustMap, tap } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-join-input',
  templateUrl: './join-input.component.html',
  styleUrls: ['./join-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinInputComponent implements OnInit, OnDestroy {
  @Input() collapsed: boolean = false;
  @Input() showLabel: boolean = false;
  @Input() onDismiss: Function = null;
  join$: Subject<string> = new Subject();
  code: string;
  isSubmitted: boolean = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private inviteService: InviteService,
    private cdf: ChangeDetectorRef,
    private toastService: ToastService,
    private popoverCtrl: PopoverController,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.join$
      .pipe(
        filter(code => code && !!code.length),
        tap(() => this.isSubmitted = true),
        exhaustMap(code => this.inviteService.joinByCode$(code)),
        tap(response => {
          this.code = '';
          this.isSubmitted = false;
          if (response.error && response.message) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
          } else {
            const message = this.translateService.instant(`join-input.join-${response.payload.entityType}-success`);
            this.toastService.showToast(message, TOAST_TYPE.SUCCESS);
          }
          if (this.onDismiss instanceof Function) {
            this.onDismiss();
          }
          this.cdf.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  openJoinDialog(event: MouseEvent) {
    (async () => {
      const popover = await this.popoverCtrl.create({
        component: JoinInputComponent,
        componentProps: {
          collapsed: false,
          showLabel: true,
          onDismiss: () => popover.dismiss()
        },
        cssClass: 'join-popover-host',
        showBackdrop: false,
        event
      });
      popover.present()
    })();
  }
}
