import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectInputComponent } from './select-input.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';

@NgModule({
  declarations: [SelectInputComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    StyledScrollbarModule,
  ],
  exports: [SelectInputComponent],
})
export class SelectInputModule { }
