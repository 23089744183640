<div *ngIf="images?.length > 0" class="msg-attachments-images" styledScrollbar [styledScrollbarSelf]="true">
  <ng-container *ngFor="let entry of images">
    <app-files-image-preview
      *ngIf="entry.asyncDataUrl | async as imageUrl"
      [src]="imageUrl"
      [previewMode]="'BIG'"
      (download)="downloadImageAttachment(imageUrl, entry.name)"
      (click)="previewImage(imageUrl, entry.name)">
    </app-files-image-preview>
  </ng-container>
</div>
<ion-list *ngIf="files?.length > 0" class="msg-attachments-list">
  <ion-item *ngFor="let entry of files" class="msg-attachment-entry" lines="none">
    <a class="link" (click)="downloadAttachment($event, entry)" [attr.title]="'Download ' + entry.name">
      {{ entry.name }}
    </a>
  </ion-item>
</ion-list>