<ion-item
  *ngIf="link$ | async as link"
  [class.collapsed]="collapsed"
  lines="none">
  <ion-button
    [hidden]="collapsed"
    fill="clear"
    (click)="startConf(link)"
    class="start-conf-btn">
    {{ 'instant-meet.title' | translate }}
  </ion-button>
  <ion-button
    (click)="copyConfLink(link)"
    class="font-extra-bold copy-btn"
    [class.copy-btn--collapsed]="collapsed"
    slot="end">
    {{ 'instant-meet.copy-btn' | translate }}
  </ion-button>
</ion-item>