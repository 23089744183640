import { Injectable } from "@angular/core";
import { createEffect } from "@ngrx/effects";
import { UserService } from "src/app/auth/services/user.service";
import { WindowService } from "src/app/shared/services/window.service";
import { merge, of, timer } from "rxjs";
import { debounceTime, filter, mapTo, mergeMap, scan, switchMap, takeWhile, tap, withLatestFrom } from "rxjs/operators";

@Injectable()
export class AppEffects {
  constructor(private windowService: WindowService, private userService: UserService) {}

  updateUserActivity$ = createEffect(
    () => {
      return this.userService.getUser$().pipe(
        mergeMap(() => {
          const active$ = this.windowService.active$.pipe(mapTo(true));
          const idle$ = this.windowService.idle$.pipe(mapTo(false));
          const forceActiveCheck$ = this.windowService.focus$.pipe(debounceTime(1000), mapTo(false));
          const isActive = document.hasFocus && document.hasFocus();
          const isActive$ = merge(of(isActive), active$, idle$, forceActiveCheck$).pipe(scan((_, val) => val, true));
          const scheduledPing$ = timer(0, 3 * 60 * 1000).pipe(
            withLatestFrom(isActive$, this.userService.getUserState$()),
            takeWhile(([, , u]) => u !== null),
            filter(([, isActive]) => isActive),
            switchMap(() => this.userService.postActivity$()),
            tap((res) => (res.error ? console.log("Could not send user activity signal:", res) : null))
          );
          return scheduledPing$;
        })
      );
    },
    { dispatch: false }
  );
}
