import { TicketPermissionsAbstract } from './base.abstract.class';
import { ITicket } from '../../models/ticket';

export class MaterialTicketPermissions extends TicketPermissionsAbstract {
  canEditOrRemoveEntity(entity: ITicket): boolean {
    return this.isOwner(entity);
  }
  canAddAttachments(entity: ITicket): boolean {
    if (this.isNewTicket(entity)) {
      return true;
    }
    return this.isOwner(entity);
  }
  canRemoveAttachments(entity: ITicket): boolean {
    return this.isOwner(entity);
  }
  canEditDate(entity: ITicket): boolean {
    return false;
  }
}
