import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatNavComponent } from './chat-nav.component';
import { ChatConversationItemComponentModule } from '../chat-conversation-item/chat-conversation-item.module';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    StyledScrollbarModule,
    TranslateModule,
    ChatConversationItemComponentModule
  ],
  declarations: [ChatNavComponent],
  exports: [ChatNavComponent]
})
export class ChatNavComponentModule {}
