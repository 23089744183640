import { UserModel } from 'src/app/shared/models/user/user';
import { ITicket } from 'src/app/shared/models/ticket';
import { Observable, forkJoin } from 'rxjs';
import { IResponse } from 'src/app/shared/models/response';
import {
    EntityType,
    UserRole,
    SocketMessageType,
    IEntityInvitationMessage,
    IReceivedEvent,
    EventType,
    UserTypes
} from 'src/app/shared/models/common';
import { InviteService, IInvitationMessage } from 'src/app/shared/services/invite.service';
import { map, filter, concatMap } from 'rxjs/operators';
import { SchoolDashboardColumn } from '../school-dashboard/helpers';
import { isSuccess } from 'src/app/shared/helpers/helpers';
import { IKanbanStateEvent, KanbanStateEventAddItem, KanbanStateEventUpdateItem, KanbanStateEventRemoveItem, KanbanStateEventChangeItemColumn, KanbanStateEventSetItemsInColumns } from 'src/app/shared/components/kanban-board/helpers';
import { ToastService } from 'src/app/shared/services/toast.service';

export const SHOULD_CREATE_CLASS_TICKET_NOTIFICATION_KEY = 'shouldCreateClassTicketNotification'

export enum ClassDashboardColumn {
    STUDENTS = 'Students',
    TEACHERS = 'Teachers',
    NOTIFICATIONS = 'Notifications',
}
export enum ClassDashboardTranslateKeyColumn {
    STUDENTS = 'common.students',
    TEACHERS = 'common.teachers',
    NOTIFICATIONS = 'common.notifications',
}

export type ClassDashboardTicket = UserModel | ITicket;

export function getDashboardUsersLimit(): number {
    const userCardHeight = 70;
    const screenHeight = window.innerHeight;
    const limitStudents = Math.floor(screenHeight / userCardHeight);
    return limitStudents < 10 ? 10 : limitStudents;
}

export function getUsersByColumn$(
    columnId: string,
    pageToLoad: number,
    limit: number,
    entityId: string,
    filterEntityType: EntityType,
    inviteService: InviteService,
    shouldAddSchoolOwner?: boolean
): Observable<IResponse<UserModel[]>> {
    const userType = columnId.toLowerCase().slice(0, -1) as 'teacher' | 'student';
    const filters = [{ entityId, entityType: filterEntityType }];
    return inviteService.getUsers$('', userType as UserTypes, filters, pageToLoad, limit, false, shouldAddSchoolOwner)
      .pipe(
        map(response => {
          if (response.payload) {
            return {
              ...response,
              payload: response.payload.map(user => ({ ...user, role: userType.toUpperCase() as UserRole })),
            };
          }
          return response;
        })
      );
  }

export function getUserColumnIdFromRole(userRole: UserRole): string {
  return userRole[0].toUpperCase() + userRole.substring(1).toLowerCase() + 's';
}

export function getInviteUpdates$(
  notifier$: Observable<IInvitationMessage>,
  entityType: EntityType,
  entityId: string,
  loadedPages: { [columnId: string]: { top: number; bottom: number } },
  getColumnId: (role: UserRole) => ClassDashboardColumn | SchoolDashboardColumn,
  getItems$: (columnId: ClassDashboardColumn | SchoolDashboardColumn) => Observable<IResponse<ClassDashboardTicket[]>>,
  toastService: ToastService,
): Observable<IKanbanStateEvent> {
  return notifier$
    .pipe(
      filter(message => message.type === SocketMessageType.ENTITY_INVITATION),
      map(message => message.payload as IEntityInvitationMessage),
      filter(message => message.entity_type === entityType && message.entity_id === entityId),
      concatMap(message => {
        message.for_user_roles
          .forEach(userColumnId => {
            loadedPages[getColumnId(userColumnId)] = {
              top: 1,
              bottom: 1,
            };
          });
        const requests$ = message.for_user_roles.map(userColumnId => getItems$(getColumnId(userColumnId))
          .pipe(map(response => ({ response, columndId: getColumnId(userColumnId) }))));
        return forkJoin(requests$);
      }),
      filter(response => {
        for (const responseData of response) {
          if (!isSuccess(responseData.response)) {
            return false;
          }
        }
        return true;
      }),
      map(response => {
        const data = {};
        for (const responseData of response) {
          data[responseData.columndId] = responseData.response.payload;
        }
        return data;
      }),
      map(data => new KanbanStateEventSetItemsInColumns(data)),
    );
}

export function getTicketsUpdates$(
  notifier$: Observable<IReceivedEvent<ITicket>>,
  parentIdKey: string,
  parentId: string,
  updateColumnDates: (columndId: string) => void,
): Observable<IKanbanStateEvent> {
  return notifier$
      .pipe(
        filter(event => isSuccess(event.response)),
        filter(event => event.eventType === EventType.REMOVE || event.response.payload[parentIdKey] === parentId),
        map(event => {
          if (event.eventType === EventType.ADD) {
            updateColumnDates(event.response.payload.column_id);
            return new KanbanStateEventAddItem(event.response.payload);
          } else if (event.eventType === EventType.EDIT || event.eventType === EventType.PUBLISH_TICKET) {
            return new KanbanStateEventUpdateItem(event.response.payload);
          } else if (event.eventType === EventType.REMOVE) {
            const ticket: ITicket = event.response.payload;
            updateColumnDates(ticket.column_id);
            return new KanbanStateEventRemoveItem(ticket.id, ticket.column_id);
          } else if (event.eventType === EventType.CHANGE_TICKET_COLUMN) {
            updateColumnDates(event.prevColumnId);
            updateColumnDates(event.response.payload.column_id);
            return new KanbanStateEventChangeItemColumn(event.response.payload, event.prevColumnId);
          }
        }),
      );
}
