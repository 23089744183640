import { IResponse } from './response';
import { TeacherTaskState, StudentTaskState, ITicket } from './ticket';
import { IAssignedTask } from './assigned-task';
import { UserModel } from './user/user';
import { NodeType } from '../components/tree-menu/models/node_type';
import { IMeeting, MeetingUserStatus } from './meeting';
import { INotificationMessage } from './notifications';
import { ColumnItem } from '../components/kanban-board/helpers';
import { Observable } from 'rxjs';
import { IFileAttach } from './file-attach';

export type ExtraRequests = (id: string) => Observable<IResponse<any>>;

export enum EventType {
    ADD = 'ADD',
    CONFIRM_MEETING = 'CONFIRM_MEETING',
    REMOVE = 'REMOVE',
    EDIT = 'EDIT',
    CHANGE_STATE = 'CHANGE_STATE',
    FILE_UPLOAD = 'FILE_UPLOAD',
    MATERIAL_ATTACH = 'MATERIAL_ATTACH',
    LINK_ATTACH = 'LINK_ATTACH',
    PUBLISH_TICKET = 'PUBLISH_TICKET',
    CHANGE_TICKET_COLUMN = 'CHANGE_TICKET_COLUMN',
    CHANGE_TICKET_STATE = 'CHANGE_TICKET_STATE',
    CHANGE_TICKET_TYPE = 'CHANGE_TICKET_TYPE',
    ASSIGNED_TASK_STATE_CHANGED = 'ASSIGNED_TASK_STATE_CHANGED',
    ASSIGNED_TASK_UPDATED = 'ASSIGNED_TASK_UPDATED',
    ADD_COLUMN = 'ADD_COLUMN',
    EDIT_COLUMN = 'EDIT_COLUMN',
    REMOVE_COLUMN = 'REMOVE_COLUMN',
    ADD_USER = 'ADD_USER',
    REMOVE_USER = 'REMOVE_USER',
}

export enum EntityType {
    SCHOOL = 'school',
    CLASS = 'schoolClass',
    SUBJECT = 'subject',
    TICKET = 'TICKET',
    TICKET_TASK = 'TICKET_TASK',
    TICKET_GENERAL = 'TICKET_GENERAL',
    TICKET_NOTIFICATION = 'TICKET_NOTIFICATION',
    TICKET_MATERIAL = 'TICKET_MATERIAL',
    ASSIGNED_TASK = 'ASSIGNED_TASK',
    GRADING_SYSTEM = 'GRADING_SYSTEM',
    USER_STUDENT = 'USER_STUDENT',
    USER_TEACHER = 'USER_TEACHER',
    MEETING = 'meeting',
    MATERIAL = 'material',
    FILE_ATTACH = 'FILE_ATTACH',
    MATERIAL_ATTACH = 'MATERIAL_ATTACH',
    LINK_ATTACH = 'LINK_ATTACH',
}

export enum TicketParent {
    SUBJECT = 'SUBJECT',
    CLASS = 'CLASS',
    SCHOOL = 'SCHOOL',
    MATERIAL = 'MATERIAL',
}

export interface IPublishEvent<T> {
    entity: T;
    entityType: EntityType;
    eventType: EventType;
    patchValue?: any;
    newState?: TeacherTaskState | StudentTaskState;
    newColumnId?: string;
    payload?: any;
    extraRequests?: ExtraRequests[];
    timeOffset?: number;
}

export interface IReceivedEvent<T> {
    entityId: string;
    entityType: EntityType;
    eventType: EventType;
    response: IResponse<T>;
    prevState?: string;
    prevColumnId?: string;
}

export interface INotifyUpdatePayload {
    entityId?: string;
    prevState?: string;
    prevColumnId?: string;
}

export enum TicketCalendarItemType {
    TICKET = 'TICKET',
    MEETING = 'MEETING'
}

export interface IAssignedTasksCount {
    total: number;
    [StudentTaskState.WAITING_FOR_CHECK]: number;
    [StudentTaskState.CHECKED_AND_FINISHED]: number;
    waitingPercent: number;
    checkedPercent: number;
}

export interface IStudentTask {
    ticket: ITicket;
    assignedTask: IAssignedTask;
}

export interface ITeacherTask {
    ticket: ITicket;
    assignedTasks: IAssignedTask[];
}

export interface ISubjectCalendarData {
    user: UserModel;
    tasks: IStudentTask[];
    meetings: IMeeting[];
}

export interface IBaseTicketList<T> {
    [key: string]: ColumnItem<T>[];
}

export interface IDashboardColumn {
    id: string;
    title: string;
}

export interface IDashboard {
    columns?: IDashboardColumn[];
    school_id?: string;
    class_id?: string;
    subject_id?: string;
}

export interface IActivePage {
    type: NodeType;
    id?: string;
}

export enum ConfirmAction {
    REMOVE_NOT_EMPTY_COLUMN = 'REMOVE_NOT_EMPTY_COLUMN',
    REMOVE_EMPTY_COLUMN = 'REMOVE_EMPTY_COLUMN',
    REMOVE_TICKET = 'REMOVE_TICKET',
    REMOVE_SCHOOL = 'REMOVE_school',
    REMOVE_CLASS = 'REMOVE_schoolClass',
    REMOVE_SUBJECT = 'REMOVE_subject',
    REMOVE_MATERIAL = 'REMOVE_material',
    REMOVE_MEETING = 'REMOVE_meeting',
    LEAVE_SCHOOL = 'LEAVE_school',
    LEAVE_CLASS = 'LEAVE_schoolClass',
    LEAVE_SUBJECT = 'LEAVE_subject',
    REMOVE_ONE_MEETING = 'REMOVE_ONE_meeting',
    REMOVE_SERIES_MEETING = 'REMOVE_SERIES_meeting',
    REMOVE_ONE_MEETING_TYPE = 'REMOVE_ONE_meeting-type',
    REMOVE_STUDENT_INVITE = 'REMOVE_STUDENT_INVITE',
    REMOVE_STUDENT_INVITE_FOR_MEETING = 'REMOVE_STUDENT_INVITE_FOR_MEETING',
    REMOVE_TEACHER_INVITE = 'REMOVE_TEACHER_INVITE',
    REMOVE_FILE = 'REMOVE_FILE',
    SHOULD_NOTIFY_USERS = 'SHOULD_NOTIFY_USERS',
    SHOULD_EDIT_RECCURING_MEETINGS = 'SHOULD_EDIT_RECCURING_MEETINGS',
    LOGOUT = 'LOGOUT',
    SHOULD_TRANSFER_USERS_TO_PARENT_ENTITY = 'SHOULD_TRANSFER_USERS_TO_PARENT_ENTITY',
    DELETE_ACCOUNT = 'DELETE_ACCOUNT',
    NOTIFY_ABOUT_REMOVED_TICKET = 'NOTIFY_ABOUT_REMOVED_TICKET',
    UNABLE_CHOOSE_PAST_DAY = 'UNABLE_CHOOSE_PAST_DAY',
    FILE_SIZE_EXCEED = 'FILE_SIZE_EXCEED',
    COMMMON = 'COMMON',
    SHARED_TO_ESTUDY_SUBJECT = 'SHARED_TO_ESTUDY_SUBJECT',
    SHARED_TO_ESTUDY_MATERIAL = 'SHARED_TO_ESTUDY_MATERIAL',
    CREATE_INSTANT_MEET_LINK = 'CREATE_INSTANT_MEET_LINK',
    LEAVE_MEETING = 'LEAVE_MEETING',
    LEAVE_MEETING_SERIES = 'LEAVE_MEETING_SERIES',
}

export const ConfirmLeaveActionByEntityType = {
    [EntityType.SCHOOL]: ConfirmAction.LEAVE_SCHOOL,
    [EntityType.CLASS]: ConfirmAction.LEAVE_CLASS,
    [EntityType.SUBJECT]: ConfirmAction.LEAVE_SUBJECT,
} as const

export interface IBaseEntity {
    id?: string;
    is_deleted?: boolean;
    created_on?: string;
    modified_on?: string;
}

export interface IInviteBaseEntity extends IBaseEntity {
    student_invite_id?: string;
    teacher_invite_id?: string;
}

export enum SocketMessageType {
    CHAT_MESSAGE = 'CHAT_MESSAGE',
    CRUD = 'CRUD',
    MEETING_ATTENDEE = 'MEETING_ATTENDEE',
    MEETING_ATTENDEE_STATUS_UPDATES = 'MEETING_ATTENDEE_STATUS_UPDATES',
    MEETING_ATTENDEE_JOIN = 'MEETING_ATTENDEE_JOIN',
    NOTIFICATIONS = 'NOTIFICATIONS',
    ENTITY_INVITATION = 'ENTITY_INVITATION',
    USER_ADD_INVITATION = 'USER_ADD_INVITATION',
    INVITE_CODE_DEACTIVATED = 'INVITE_CODE_DEACTIVATED',
    CONFERENCE_BREAKOUT_ROOM_JOIN = 'CONFERENCE_BREAKOUT_ROOM_JOIN',
    CONFERENCE_BREAKOUT_ROOM_EXIT = 'CONFERENCE_BREAKOUT_ROOM_EXIT',
    CONFERENCE_BREAKOUT_ROOM_REQUEST_RETURN = 'CONFERENCE_BREAKOUT_ROOM_REQUEST_RETURN',
}

type SocketMessagePayload = IChatMessage | ICrudMessage | IMeeting | IEntityInvitationMessage |
    IUserAddInvitationMessage | IMeetingSerialStatusUpdates | INotificationMessage<any>;

export interface ISocketMessage {
    type: SocketMessageType;
    payload: SocketMessagePayload;
}

export enum ChatMessageType {
    TEXT = 'TEXT',
    FILE = 'FILE',
    MATERIAL = 'MATERIAL',
    LINK = 'LINK',
}

export interface IChatMessage {
    id?: string;
    assigned_task_id?: string;
    type?: ChatMessageType;
    added_by?: UserModel;
    added_on?: string;
    payload?: any;
    is_read?: boolean;
}

export interface ICrudMessage {
    entityId: string;
    entityType: EntityType;
    eventType: EventType;
    entity: any;
    prevState?: string;
    prevColumnId?: string;
}

export interface IFilesList {
    documents: IFileAttach[];
    images: IFileAttach[];
    videos: IFileAttach[];
}

export interface IConfirmResponse<TPayload = any> {
    isConfirmed: boolean;
    payload?: TPayload;
}

export interface IMeetingSerialStatusUpdates {
    meeting_serial_id: string;
    meeting_status: MeetingUserStatus;
}

export interface IEntityInvitationMessage {
    entity_id: string;
    entity_type: EntityType;
    event_type: EventType;
    for_user_roles: UserRole[];
}

export interface IUserAddInvitationMessage {
    entity_id: string;
    entity_type: EntityType;
}

export enum UserRole {
    TEACHER = 'TEACHER',
    STUDENT = 'STUDENT',
}

export enum UserTypes {
    TEACHER = 'teacher',
    STUDENT = 'student',
    ALL = 'all'
}

export const ALLOWED_IMAGES_FORMATS: string[] = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
export const ALLOWED_VIDEOS_FORMATS: string[] = ['avi', 'mp4', 'mov', 'wmv', 'mkv'];
export const ALLOWED_FILES_FORMATS: string[] = ['pdf', 'doc', 'docx'];

export interface IChangeTicketType {
    id: string;
    currentType: string;
    newType: string;
}

export interface IChangeParentPayload {
    /** Student ids to move */
    user_ids_for_migration?: string[];
}

export interface IChangeClassSchoolPayload extends IChangeParentPayload {
    id: string;
    school_id: string;
}

export interface IChangeSubjectClassPayload extends IChangeParentPayload {
    id: string;
    class_id: string;
}
export interface IUpcomingTasksStats {
    [key: string]: {
        hits: number;
        total: number;
    }
}
export interface ICreateMenuPayload {
    editReccuringMeeting?: boolean;
    createMeetingForClassId?: string;
}

export enum DefinedQueryParam {
    SHARING_SOURCE = 'source',
    SHARING_TITLE = 'title',
    POST_SHARING = 'post-sharing',
}

export const SHALLOW_TICKET_ID = 'shallow_ticket_id'

export interface IInstantMeetLink {
    url: string
    id: string
    ownerId: string
}