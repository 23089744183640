import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatMembersListComponent } from './chat-members-list.component';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { IsChatUserOnlinePipeModule } from '../../pipes/is-chat-user-online/is-chat-user-online.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    StyledScrollbarModule,
    IsChatUserOnlinePipeModule,
  ],
  declarations: [ChatMembersListComponent],
  exports: [ChatMembersListComponent]
})
export class ChatMembersListModule {}
