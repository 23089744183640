import { Component, OnInit, Input } from '@angular/core';
import { RouteErrorType } from './helpers';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-route-error-modal',
  templateUrl: './ticket-route-error-modal.component.html',
  styleUrls: ['./ticket-route-error-modal.component.scss'],
})
export class RouteErrorModalComponent implements OnInit {
  @Input() errorType: RouteErrorType;
  @Input() routeTitle: string;
  title: string;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.title = this.getTitle();
  }

  close(): void {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateRoot('/');
  }

  private getTitle(): string {
    switch (this.errorType) {
      case RouteErrorType.NO_ACCESS:
          return this.translateService.instant('auth-errors.route-no-access', { routeTitle: this.routeTitle });
      case RouteErrorType.UNEXPTECTED_ERROR:
        return this.translateService.instant('unknown-errors.route-error-unexpected', { routeTitle: this.routeTitle });
    }
  }

}
