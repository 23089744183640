import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InviteService } from '../../services/invite.service';
import { of, Subject} from 'rxjs';
import { concatMap, filter, takeUntil, delay } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { LinkStorageService } from '../../services/link-storage.service';
import { NavController } from '@ionic/angular';
import { EntityType } from '../../models/common';

@Component({
  selector: 'app-invite-loading-modal',
  templateUrl: './invite-loading-modal.component.html',
  styleUrls: ['./invite-loading-modal.component.scss'],
})
export class InviteLoadingModalComponent implements OnInit, OnDestroy {
  @Input() session: { code: string, link: string };
  @Input() doDismiss: Function;

  isLoading: boolean = true;

  private redirectUrls = {
    [EntityType.SUBJECT]: '/pages/subject-dashboard',
    [EntityType.CLASS]: '/pages/class-dashboard',
    [EntityType.SCHOOL]: '/pages/school-dashboard',
  };

  private destroy$ = new Subject<void>();

  constructor(
    private inviteService: InviteService,
    private sessionStorageService: LinkStorageService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private navCtrl: NavController,
  ) { }

  ngOnInit() {
    const invite$ = of(this.session.code);

    invite$
      .pipe(
        filter(code => !!code),
        delay(3000),
        concatMap(code => this.inviteService.joinByCode$(code)),
        takeUntil(this.destroy$)
      )
      .subscribe(response => {
        this.isLoading = false;
        
        if (response.error && response.message) {
          this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
          this.navCtrl.navigateRoot('pages/home');
        } else {
          if (response.payload) {
            const message = this.translateService.instant(`join-input.join-${ response.payload.entityType }-success`);
            this.toastService.showToast(message, TOAST_TYPE.SUCCESS);
  
            const redirectUrl = `${ this.redirectUrls[response.payload.entityType]}/${ response.payload.entityId }`;
            this.navCtrl.navigateForward(redirectUrl);
          }
        }

        this.doDismiss instanceof Function && this.doDismiss();

        this.sessionStorageService.clearAll();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
