import {Injectable} from '@angular/core';
import {EntityType, TicketParent} from '../models/common';
import {ITicket} from '../models/ticket';
import * as moment from 'moment';
import {StudentNotifierService} from './student-notifier.service';
import {MaterialService} from './material.service';

@Injectable({
    providedIn: 'root'
})
export class UpdateNotificationHelperService {

    constructor(
        private studentNotifierService: StudentNotifierService,
        private materialService: MaterialService,
    ) {
    }

    public displayCanUpdateModal(parentType: TicketParent, entity: ITicket, entityType: EntityType): void {
        if (parentType === TicketParent.MATERIAL) {
            this.showUpdateNotificationModalForMaterialTicket(parentType, entity, entityType);
        } else if (entityType === EntityType.TICKET_NOTIFICATION) {
            this.showUpdateNotificationModalForTicketNotification(parentType, entity, entityType);
        } else if (entityType === EntityType.TICKET_GENERAL) {
            this.showUpdateNotificationModalForTicketGeneral(parentType, entity, entityType);
        } else {
            this.studentNotifierService.notifyAboutTicketChange(entity as ITicket, entityType, parentType);
        }
    }

    private showUpdateNotificationModalForTicketNotification(parentType: TicketParent, entity: ITicket, entityType: EntityType): void {
        if (moment(new Date()).isAfter(moment((entity as any).notify_at))) {
            this.studentNotifierService.notifyAboutTicketChange(entity as ITicket, entityType, parentType);
        }
    }

    private showUpdateNotificationModalForTicketGeneral(parentType: TicketParent, entity: ITicket, entityType: EntityType): void {
        if (moment(new Date()).isAfter(moment((entity as any).make_visible_at))) {
            this.studentNotifierService.notifyAboutTicketChange(entity as ITicket, entityType, parentType);
        }
    }

    private showUpdateNotificationModalForMaterialTicket(parentType: TicketParent, entity: ITicket, entityType: EntityType): void {
        this.materialService.shouldNotifyAboutMaterialTicketUpdate$(entity.id)
            .toPromise()
            .then(canNotify => {
                if (canNotify) {
                    this.studentNotifierService.notifyAboutTicketChange(entity as ITicket, entityType, parentType);
                }
            });
    }

}
