import { Component, OnInit } from '@angular/core';
import { FileAttachService } from '../../services/file-attach.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class FileUploadDialogComponent implements OnInit {

  constructor(
    public uploadService: FileAttachService,
  ) { }

  ngOnInit() {}

}
