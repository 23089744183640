import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TimeoutError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private translateService: TranslateService,
  ) { }

  handleError(error: any) {
    console.log(error);
  }

  getUserMessage(error: HttpErrorResponse | TimeoutError): string {
    console.log(error);
    let message: string = this.translateService.instant('unknown-errors.unexpected');

    if (error instanceof HttpErrorResponse) {
      if (error.status <= 0) {
        message = this.translateService.instant('connection-errors.server-unreachable');
      } else if (error.status === 401) {
        message = this.handle401Error(error);
      } else if (error.status === 400) {
        message = this.handle400Error(error);
      } else if (error.status === 403) {
        message = this.handle403Error(error);
      } else if (error.status === 404) {
        message = this.handle404Error(error);
      } else if (error.status === 409) {
        message = this.handle409Error(error);
      } else if (error.status === 422) {
        message = this.handle422Error(error);
      } else if (error.status === 504) {
        message = this.translateService.instant('connection-errors.no-internet', { status: error.status });
      } else {
        message = this.translateService.instant('connection-errors.unexpected', { status: error.status });
      }
    } else if (error instanceof TimeoutError) {
      message = this.translateService.instant('connection-errors.server-unreachable');
    }

    return message;
  }

  private handle400Error(error: HttpErrorResponse): string {
    if (error.error && error.error.error &&  error.error.error.name === 'EMAIL_EXISTS') {
      return this.translateService.instant('auth-errors.email-exist');
    }
    return this.translateService.instant('connection-errors.unexpected', { status: error.status });
  }

  private handle401Error(error: HttpErrorResponse): string {
    if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'TOKEN_EXPIRED') {
      return this.translateService.instant('auth-errors.token-is-expired');
    }
    return this.translateService.instant('auth-errors.credentials-invalid');
  }

  private handle403Error(error: HttpErrorResponse): string {
    if (error.error && error.error.error &&  error.error.error.code === 'NOT_OWNER') {
      return this.translateService.instant('validation-errors.user-is-not-owner');
    } else if (error.error && error.error.error &&  error.error.error.code === 'YOU_ARE_OWNER') {
      return this.translateService.instant('validation-errors.user-is-owner');
    }
    return this.translateService.instant('connection-errors.unexpected', { status: error.status });
  }

  private handle404Error(error: HttpErrorResponse): string {
    if (error.error && error.error.error &&  error.error.error.code === 'USER_NOT_FOUND') {
      return this.translateService.instant('auth-errors.user-not-found');
    } else if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'INVITE_CODE_NOT_FOUND') {
      return this.translateService.instant('invitation.invite-code-invalid');
    } else if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'GRADING_NOT_FOUND') {
      return this.translateService.instant('create-menu.grading-system-not-found');
    }
    return this.translateService.instant('connection-errors.unexpected', { status: error.status });
  }

  private handle409Error(error: HttpErrorResponse): string {
    if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'ALREADY_INVITED') {
      return this.translateService.instant('invitation.already-invited');
    } else if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'YOU_ARE_OWNER') {
      return this.translateService.instant('validation-errors.user-is-owner');
    } else if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'USER_EXISTS') {
      return this.translateService.instant('auth-errors.email-exist');
    } else if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'COLUMN_ALREADY_EXISTS') {
      return this.translateService.instant('column.already-exists');
    }
    return this.translateService.instant('connection-errors.unexpected', { status: error.status });
  }

  private handle422Error(error: HttpErrorResponse): string {
    if (error.error && error.error.error && error.error.error.message && error.error.error.message.includes('lastName')) {
      return this.translateService.instant('validation-errors.fullname-required');
    } else if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'INVITE_CODE_REACHED') {
      return this.translateService.instant('invitation.invite-code-reached');
    } else if (error.error && error.error.error && error.error.error.code && error.error.error.code === 'DUE_DATE_INVALID') {
      return this.translateService.instant('ticket-errors.due-date-invalid');
    }
    return this.translateService.instant('connection-errors.unexpected', { status: error.status });
  }
}
