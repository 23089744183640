import { BaseService } from './base.service';
import { HttpService } from './http.service';
import { EntityType, EventType } from '../models/common';
import { ToastService } from './toast.service';
import { tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./toast.service";
export class GradingService extends BaseService {
    constructor(httpService, toastService) {
        super(httpService, toastService);
        this.httpService = httpService;
        this.toastService = toastService;
        this.entityType = EntityType.GRADING_SYSTEM;
        this.GET_ALL = 'v1/gradings';
        this.POST_ONE = 'v1/gradings';
        this.PUT_ONE = 'v1/gradings';
        this.PATCH_ONE = 'v1/gradings';
        this.GET_ALL_DEFAULT = 'v1/default-gradings';
        this.GET_ALL_RECENT = 'v1/recent-gradings';
        this.GET_ONE = (id) => `v1/gradings/${id}`;
        this.GET_BY_TYPE = (id, type) => `v1/gradings?entityType=${type}&entityId=${id}`;
        this.DELETE_ONE = (id) => `v1/gradings/${id}`;
    }
    getByType$(entityType, entityId) {
        return this.httpService.get$(this.GET_BY_TYPE(entityId, entityType));
    }
    getDefaultGrades$() {
        return this.httpService.get$(this.GET_ALL_DEFAULT);
    }
    getRecentGrades$() {
        return this.httpService.get$(this.GET_ALL_RECENT);
    }
    updateGrading$(grading, entityType, entityId) {
        return this.httpService.patch$(this.PATCH_ONE, { grading, entityType, entityId })
            .pipe(tap(response => {
            this.notifyAboutUpdate(EventType.EDIT, response, { entityId });
        }));
    }
}
GradingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GradingService_Factory() { return new GradingService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService)); }, token: GradingService, providedIn: "root" });
