import * as tslib_1 from "tslib";
import { PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { ToastService, TOAST_TYPE } from './toast.service';
import { PlatformService } from './platform.service';
import { ExternalLinkService } from './external-link.service';
import { BREAKOUT_ROOM_STORE_KEY } from '../components/whiteboard/models';
import { EnvService } from './env/env.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { UserService } from 'src/app/auth/services/user.service';
import { nanoid } from 'nanoid';
import { UserSettingsService } from 'src/app/auth/services/user-settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/services/auth.service";
import * as i2 from "./platform.service";
import * as i3 from "./external-link.service";
import * as i4 from "@ionic/angular";
import * as i5 from "./http.service";
import * as i6 from "./env/env.service";
import * as i7 from "./toast.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@ionic/storage";
import * as i10 from "../../auth/services/user.service";
import * as i11 from "../../auth/services/user-settings.service";
export class WhiteboardService {
    constructor(authService, platformService, externalLinkService, popoverCtrl, httpService, envService, toastService, translateService, storage, userService, userSettingsService) {
        this.authService = authService;
        this.platformService = platformService;
        this.externalLinkService = externalLinkService;
        this.popoverCtrl = popoverCtrl;
        this.httpService = httpService;
        this.envService = envService;
        this.toastService = toastService;
        this.translateService = translateService;
        this.storage = storage;
        this.userService = userService;
        this.userSettingsService = userSettingsService;
        this.isWhiteboardOpened = false;
        this.WHITEBOARD_URL = 'v1/whiteboard';
        this.GET_WHITEBOARD_HTML = (params) => `/whiteboard-loader.html?type=attachment&ticketParent=${params.ticketParent}&entityType=${params.entityType}&entityId=${params.entityId}&attachmentId=${params.attachmentId}&ticketId=${params.openMaterialAttachmentParams.parentTicketId}&assignedTaskId=${params.openMaterialAttachmentParams.assignedTaskId}&saveToChat=${params.saveToChat}`;
        this.POST_UPLOAD_IMAGE = (ticketParent) => `${this.WHITEBOARD_URL}/save-image?ticketParent=${ticketParent}`;
        this.POST_UPLOAD_PDF = (ticketParent) => `${this.WHITEBOARD_URL}/save-pdf?ticketParent=${ticketParent}`;
        this.POST_UPLOAD_DOC = (ticketParent) => `${this.WHITEBOARD_URL}/save-doc?ticketParent=${ticketParent}`;
        this.POST_BREAKOUT_ROOM_JOIN = `v3/whiteboard/breakout-room-join`;
        this.POST_BREAKOUT_ROOM_EXIT = `v3/whiteboard/breakout-room-exit`;
        this.POST_BREAKOUT_ROOM_REQUEST_RETURN = `v3/whiteboard/breakout-room-request-return`;
        this.GET_ONE_BY_ID = (id) => `${this.WHITEBOARD_URL}/${id}`;
        this.PATCH_ONE = this.WHITEBOARD_URL;
        this.POST_ONE = this.WHITEBOARD_URL;
        this.authService.isAuthenticated$.pipe(filter(isAuthenticated => !isAuthenticated), switchMap(() => this.storage.remove(BREAKOUT_ROOM_STORE_KEY)))
            .subscribe({
            error: err => console.error(err)
        });
    }
    checkWhiteboardOpened() {
        return this.isWhiteboardOpened;
    }
    openAttachmentInWhiteboard(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isWhiteboardOpened) {
                return;
            }
            this.isWhiteboardOpened = true;
            const whiteboardFileUrlParams = this.getWhiteboardFileUrlParams(data);
            const popover = yield this.popoverCtrl.create({
                showBackdrop: true,
                backdropDismiss: false,
                cssClass: 'popover-whiteboard',
                component: data.component,
                componentProps: {
                    url: this.getWhiteboardFileUrl(whiteboardFileUrlParams),
                    ticketParent: data.ticketParent,
                    parentTicketIdForMaterialTicket: whiteboardFileUrlParams.openMaterialAttachmentParams.parentTicketId,
                    attachment: data.attachment,
                    saveToChat: data.saveToChat,
                    onDismiss: (err) => {
                        popover.dismiss();
                        this.isWhiteboardOpened = false;
                        if (err) {
                            this.handleWhiteboardError(err);
                        }
                    },
                },
            });
            return popover.present();
        });
    }
    openConference(room, component) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isWhiteboardOpened) {
                return;
            }
            // let params = `id=${encodeURIComponent(room.id)}&entity=${encodeURIComponent(room.entityType)}${room.entityType == "chat-pm" ? `&owner=${encodeURIComponent(room.owner_id)}` : ''}`;
            const prms = new URLSearchParams({
                type: 'whiteboard',
                id: room.id,
                entity: room.entityType,
            });
            const isWebRtcWork = yield this.platformService.isWebRtcWork();
            const url = new URL(`${this.envService.apiConfig.groupworldEndpoint}/whiteboard-loader.html`);
            if (room.entityType == 'chat-pm' || room.entityType == 'instant-meet') {
                prms.set('owner', room.owner_id);
            }
            if (isWebRtcWork) {
                url.search = prms.toString();
                this.isWhiteboardOpened = true;
                const popover = yield this.popoverCtrl.create({
                    showBackdrop: true,
                    backdropDismiss: false,
                    cssClass: 'popover-whiteboard-wide',
                    component: component,
                    componentProps: {
                        // url: `${this.envService.apiConfig.groupworldEndpoint}/whiteboard-loader.html?type=whiteboard&${params}`,
                        url: url.toString(),
                        onDismiss: (err) => {
                            popover.dismiss();
                            this.isWhiteboardOpened = false;
                            if (err) {
                                this.handleWhiteboardError(err);
                            }
                        },
                    },
                });
                return popover.present();
            }
            else {
                // params = params + `&jwt_token=${encodeURIComponent(this.authService.jwtToken)}`;
                // this.externalLinkService.open(`${this.envService.apiConfig.groupworldEndpoint}/whiteboard-loader.html?type=whiteboard&${params}`);
                const url = new URL(`${this.envService.apiConfig.groupworldEndpoint}/whiteboard-loader.html`);
                prms.set('jwt_token', this.authService.jwtToken);
                url.search = prms.toString();
                this.externalLinkService.open(url.toString());
            }
        });
    }
    uploadPdf$(uploadData) {
        const url = this.POST_UPLOAD_PDF(uploadData.ticketParent);
        const formData = this.getFileFormData('pdf', uploadData);
        return this.httpService.post$(url, formData, { responseType: uploadData.shouldDownload ? 'arraybuffer' : 'json' });
    }
    uploadDoc$(uploadData) {
        const url = this.POST_UPLOAD_DOC(uploadData.ticketParent);
        const formData = this.getFileFormData('doc', uploadData);
        return this.httpService.post$(url, formData, { responseType: uploadData.shouldDownload ? 'arraybuffer' : 'json' });
    }
    uploadImage$(uploadData) {
        const url = this.POST_UPLOAD_IMAGE(uploadData.ticketParent);
        const data = {
            filename: uploadData.payload,
            attachmentId: uploadData.attachmentId,
            assignedTaskId: uploadData.assignedTaskId,
            shouldDownload: uploadData.shouldDownload,
            isSameDestination: uploadData.isSameDestination,
            ticketId: uploadData.parentTicketIdForMaterialTicket,
            saveToChat: uploadData.saveToChat,
        };
        return this.httpService.post$(url, data, { responseType: uploadData.shouldDownload ? 'arraybuffer' : 'json' });
    }
    getWhiteboardFileUrl(params) {
        return `${this.envService.apiConfig.groupworldEndpoint}${this.GET_WHITEBOARD_HTML(params)}`;
    }
    sendBreakoutRoomJoin(data) {
        return this.httpService.post$(this.POST_BREAKOUT_ROOM_JOIN, data);
    }
    sendBreakoutRoomExit(data) {
        return this.httpService.post$(this.POST_BREAKOUT_ROOM_EXIT, data);
    }
    sendBreakoutRoomRequestReturn(data) {
        return this.httpService.post$(this.POST_BREAKOUT_ROOM_REQUEST_RETURN, data);
    }
    getOneById$(id) {
        return this.httpService.get$(this.GET_ONE_BY_ID(id));
    }
    create$(payload) {
        return this.httpService.post$(this.POST_ONE, payload);
    }
    update$(payload) {
        return this.httpService.patch$(this.PATCH_ONE, payload);
    }
    createInstantLessonLink() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.userService
                .getUserState$()
                .pipe(first())
                .toPromise();
            const url = new URL(location.origin + '/conf/instant-lesson.html');
            const params = new URLSearchParams();
            const id = 'instant_lesson_' + nanoid(32);
            params.set('id', id);
            params.set('presenter-name', [user.firstName, user.lastName].join(' '));
            url.search = params.toString();
            const currentSettings = this.userSettingsService.getSettings();
            const link = {
                url: url.toString(),
                ownerId: user.id,
                id,
            };
            this.userSettingsService.updateSettings(Object.assign({}, currentSettings, { instantMeetLink: link }));
            return link;
        });
    }
    getInstantMeetLink$() {
        return this.userSettingsService
            .userSettings$()
            .pipe(filter(settings => settings != null && settings.instantMeetLink != null), map(settings => settings.instantMeetLink));
    }
    getFileFormData(fileType, uploadData) {
        const formData = new FormData();
        formData.append(fileType, uploadData.payload);
        formData.append('attachmentId', uploadData.attachmentId);
        formData.append('assignedTaskId', uploadData.assignedTaskId);
        formData.append('shouldDownload', String(uploadData.shouldDownload));
        formData.append('isSameDestination', String(uploadData.isSameDestination));
        formData.append('ticketId', String(uploadData.parentTicketIdForMaterialTicket));
        formData.append('saveToChat', String(uploadData.saveToChat));
        return formData;
    }
    getWhiteboardFileUrlParams(data) {
        return {
            entityId: data.entityId,
            entityType: data.entityType,
            ticketParent: data.ticketParent,
            attachmentId: data.attachment.id,
            saveToChat: data.saveToChat,
            openMaterialAttachmentParams: data.openMaterialAttachmentParams
                ? data.openMaterialAttachmentParams
                : { parentTicketId: null, assignedTaskId: null },
        };
    }
    handleWhiteboardError(err) {
        const msg = this.translateService.instant('connection-errors.something-went-wrong');
        this.toastService.showToast(msg, TOAST_TYPE.ERROR);
    }
}
WhiteboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WhiteboardService_Factory() { return new WhiteboardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.PlatformService), i0.ɵɵinject(i3.ExternalLinkService), i0.ɵɵinject(i4.PopoverController), i0.ɵɵinject(i5.HttpService), i0.ɵɵinject(i6.EnvService), i0.ɵɵinject(i7.ToastService), i0.ɵɵinject(i8.TranslateService), i0.ɵɵinject(i9.Storage), i0.ɵɵinject(i10.UserService), i0.ɵɵinject(i11.UserSettingsService)); }, token: WhiteboardService, providedIn: "root" });
