import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ISubject, ISubjectPost} from 'src/app/shared/models/subject';
import {TranslateService} from '@ngx-translate/core';
import {
    bumpToArbituaryFutureDate,
    detectChanges, getUserWithAvatar,
    hasError,
    isSuccess,
    showErrorIfExists
} from 'src/app/shared/helpers/helpers';
import {ISelectOption} from '../select-input/models';
import {SubjectService} from '../../services/subject.service';
import {combineLatest, forkJoin, from, merge, Observable, of, Subject, zip} from 'rxjs';
import {
    concatMap,
    debounceTime,
    delay,
    distinctUntilChanged,
    filter,
    first,
    map,
    mapTo,
    pluck,
    startWith,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import {TOAST_TYPE, ToastService} from '../../services/toast.service';
import {UserService} from 'src/app/auth/services/user.service';
import {
    ConfirmAction,
    EntityType,
    EventType,
    IBaseEntity,
    IChangeClassSchoolPayload,
    IChangeParentPayload,
    IChangeSubjectClassPayload,
    IConfirmResponse,
    ICreateMenuPayload,
    ICrudMessage,
    IPublishEvent,
    IReceivedEvent,
    UserTypes
} from '../../models/common';
import {ConfirmationService} from '../../services/confirmation.service';
import {IClass} from '../../models/class';
import {ISchool, ISchoolPost} from '../../models/school';
import {SchoolService} from '../../services/school.service';
import {ClassService} from '../../services/class.service';
import {getClassesOps, getCreateTypesOps, getSchoolsOps, getSubjectsOps} from './helpers';
import {UserModel} from '../../models/user/user';
import {IGrade} from '../../models/grade';
import {StudentsInvitedListComponent} from '../students-invited-list/students-invited-list.component';
import {InviteService} from '../../services/invite.service';
import {
    IMeeting,
    IMeetingFrequency,
    IMeetingType,
    IMeetingUpdate,
    isReccuringMeeting,
    MeetingFrequency
} from '../../models/meeting';
import {MeetingService} from '../../services/meeting.service';
import {IonInput, ModalController} from '@ionic/angular';
import * as moment from 'moment';
import {IMaterial} from '../../models/material';
import {MaterialService} from '../../services/material.service';
import {GradesCrudComponent} from '../grades-crud/grades-crud.component';
import {SchoolStoreService} from '../../services/stores/school-store.service';
import {ClassStoreService} from '../../services/stores/class-store.service';
import {SubjectStoreService} from '../../services/stores/subject-store.service';
import {IResponse} from '../../models/response';
import {LoadingService} from '../../services/loading.service';
import {SelectInputComponent} from '../select-input/select-input.component';
import {BaseService} from '../../services/base.service';
import {PermissionService} from '../../services/permission/permission.service';
import {removeMeeting} from 'src/app/pages/meetings/meeting-info/meetings-remove.helper';
import {PlatformService} from '../../services/platform.service';
import {GradingService} from '../../services/grading-system.service';
import {ParentChangeConfirmComponent} from '../parent-change-confirm/parent-change-confirm.component';
import {SocketService} from '../../services/socket.service';
import {DomSanitizer} from '@angular/platform-browser';

export enum CreateMenuType {
  SCHOOL = 'SCHOOL',
  CLASS = 'CLASS',
  CLASS_WITH_SCHOOL = 'CLASS_WITH_SCHOOL',
  SUBJECT = 'SUBJECT',
  SUBJECT_WITH_CLASS = 'SUBJECT_WITH_CLASS',
  MEETING = 'MEETING',
  MATERIAL = 'MATERIAL',
}

const cloneDeep = require('lodash.clonedeep');

export interface InvitedUserModel extends UserModel {
  isNew?: boolean;
}

@Component({
  selector: 'app-create-menu',
  templateUrl: './create-menu.component.html',
  styleUrls: ['./create-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CreateMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() entity: ISchool | IClass | ISubject | IMeeting;
  @Input() entityType: EntityType;
  @Input() hasModalStyle = false;
  @Input() payload?: ICreateMenuPayload;
  @Output() destroyEmitter: EventEmitter<void> = new EventEmitter();

  @ViewChild(StudentsInvitedListComponent, { static: false }) invitedUsersComponent: StudentsInvitedListComponent;
  @ViewChild(GradesCrudComponent, { static: false }) gradesCrudComponent: GradesCrudComponent;
  @ViewChild('subjectSelect', { static: false }) subjectSelect: SelectInputComponent;
  @ViewChild('meetingTypesSelect', { static: false }) meetingTypesSelect: SelectInputComponent;
  @ViewChild('titleInput', { static: false }) titleInput: IonInput;

  isLoading: boolean = true;
  createMenuType: CreateMenuType;
  createForm: FormGroup;
  userFilterForm: FormGroup;
  isSubmitted: boolean = false;
  submitBtnName: string;
  save$: Subject<void> = new Subject();

  createOptions: ISelectOption[] = getCreateTypesOps();
  createMenuTypes = CreateMenuType;

  classes: ISelectOption[] = [];
  schools: ISelectOption[] = [];
  subjects: ISelectOption[] = [];
  classesFilterList: ISelectOption[] = [];
  schoolsFilterList: ISelectOption[] = [];
  subjectsFilterList: ISelectOption[] = [];
  meetingTypes: ISelectOption[] = [];
  searchedStudents: UserModel[] = [];
  meetingFrequencyDates: IMeetingFrequency[] = [];

  hasError = hasError;

  isStudentListVisible: boolean = false;
  isGradingSystemVisible: boolean = false;
  isGradingSystemUpdated: boolean = false;
  isStudentListLoading: boolean = false;
  studentSearchTerm$: Subject<string> = new Subject();
  updatingStudent: UserModel;
  showGradingInput: boolean = false;
  isMeetingClassLoading: boolean = false;
  isMeetingSubjectLoading: boolean = false;
  userFilterCurrentData: { entityId: any; entityType: EntityType; }[];
  searchedUsersPageToLoad = 1;
  searchedUsersLimit = 10;
  currentSearchTerm = '';
  searchedUsersEmptyText = '';
  isNew: boolean;
  isAddAllInProcess = false;
  shouldEditReccuringMeetings = false;
  canEdit: boolean;
  canRemoveUsers: boolean;
  canAddUsers: boolean;
  entityGrade: IGrade;
  private meetingFrequencyHasChanged = false;
  private destroy$: Subject<void> = new Subject();
  private ownerId: string = '';
  private defaultEntityType: EntityType = EntityType.SUBJECT;
  private titleTranslator = {
    [EntityType.SCHOOL]: 'school',
    [EntityType.CLASS]: 'class',
    [EntityType.SUBJECT]: 'subject',
    [EntityType.MATERIAL]: 'material',
    [EntityType.MEETING]: 'meeting',
  };
  private preventShowChangeParentEntityModal: boolean = false;
  private skipUserUpdate = false;

  schoolsList: ISchool[] = [];
  classesList: IClass[] = [];

  isFormUpdated = false;

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private schoolService: SchoolService,
    private classService: ClassService,
    private subjectService: SubjectService,
    private toastService: ToastService,
    private userService: UserService,
    private cdf: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private inviteService: InviteService,
    private meetingService: MeetingService,
    private materialService: MaterialService,
    private schoolStoreService: SchoolStoreService,
    private classStoreService: ClassStoreService,
    private subjectStoreService: SubjectStoreService,
    private modalCtrl: ModalController,
    private loadingService: LoadingService,
    private permissionService: PermissionService,
    private platformService: PlatformService,
    private gradingService: GradingService,
    private socketService: SocketService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.isNew = this.entity && this.entity.id ? false : true;
    this.canEdit = this.isNew ? true : this.canEditEntity(this.entity, this.entityType);
    this.canRemoveUsers  = this.isNew ? true : this.canRemoveUsersToEntity(this.entity, this.entityType);
    this.canAddUsers  = this.isNew ? true : this.canAddUsersToEntity(this.entity, this.entityType);

    if (!this.entityType) {
      this.entityType = this.defaultEntityType;
    }
    this.submitBtnName = this.translateService.instant(`common.btn-${ this.isNew ? 'create' : 'save' }`);
    this.createForm = this.fb.group({
      createType: [[this.entityType], Validators.required],
      name: [this.isNew ? '' : this.entity.title, Validators.required],
      color: [this.isNew ? null : (this.entity as ISubject).color ? (this.entity as ISubject).color : null],
      class: [this.isNew ? null : [(this.entity as ISubject).class_id] ? [(this.entity as ISubject).class_id] : null],
      school: [[this.getPreselectedSchoolId(this.entity)]],
      address: [this.isNew ? null : (this.entity as ISchool).address ? (this.entity as ISchool).address : null],
      phone: [this.isNew ? null : (this.entity as ISchool).phone ? (this.entity as ISchool).phone : null],
      website: [this.isNew ? null : (this.entity as ISchool).website ? (this.entity as ISchool).website : null],
      is_official: [this.isNew ? false : (this.entity as ISchool).is_official ? (this.entity as ISchool).is_official : false],
      subject: [this.isNew ? null : [(this.entity as IMeeting).subject_id] ? [(this.entity as IMeeting).subject_id] : null],
      type: [this.isNew ? null : [(this.entity as IMeeting).type_id] ? [(this.entity as IMeeting).type_id] : null],
    });

    this.userFilterForm = this.fb.group({
      filter_school: [[null]],
      filter_class: [[this.payload ? this.payload.createMeetingForClassId : null]],
      filter_subject: [[]],
    });

    this.socketService.getCrudMessages$([
        EntityType.SCHOOL,
        EntityType.CLASS,
        EntityType.SUBJECT,
    ])
        .pipe(
            takeUntil(this.destroy$),
            tap(event => {
                if (event.eventType === EventType.EDIT) {
                    this.handleUpdateEvent(event);
                } else if (event.eventType === EventType.REMOVE) {
                    this.handleDeleteEvent(event);
                }
            }),
        )
        .subscribe(() => detectChanges(this.cdf));

    merge(
      this.schoolService.notifier$,
      this.classService.notifier$,
      this.subjectService.notifier$,
      this.materialService.notifier$,
      this.meetingService.notifier$,
    )
      .pipe(
        filter(event => this.isNew ? true : event.entityId === this.entity.id),
        concatMap(event => this.getExtraRequests$(event)),
        tap(event => this.handleResponse(event)),
        tap(() => this.skipUserUpdate = false),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.createForm
        .valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.isFormUpdated = true);

    this.save$
      .pipe(
        tap(() => {
          this.createForm.patchValue({ name: this.createForm.value.name.trim() }, { emitEvent: false });
        }),
        filter(() => this.isValid && !this.isSubmitted),
        switchMap(() => this.isNew || !this.shouldChangeParent()
          ? of({ payload: {} } as IResponse<any>)
          : this.changeParent$()
        ),
        filter(response => isSuccess(response)),
        switchMap(() => {
          this.isSubmitted = true;
          this.skipUserUpdate = !this.isNew;
          const invites = this.entity && this.entity.id ? this.getRequestsToUpdateInvites$(this.entity.id) : [];
          return invites.length === 0 || this.isNew ? of(true) : forkJoin(invites);
        }),
        tap(() => {
          if (this.isFormUpdated || this.meetingFrequencyHasChanged || this.isGradingSystemUpdated) {
            const event = {
              [this.shouldPutValues ? 'entity' : 'patchValue' ]: this.getEntity(this.entityType),
              entityType: this.entityType,
              eventType: this.isNew ? EventType.ADD : EventType.EDIT,
            };
            this.getService(this.entityType).publishUpdate(event as unknown as IPublishEvent<any>);
          } else if(this.invitedUsersComponent.isUserListChanged) {
            const message = `${this.getServiceName(this.entityType)}.edited-success`;
            this.toastService.showToast(this.translateService.instant(message), TOAST_TYPE.SUCCESS);
            this.close()
          } else {
            this.isSubmitted = false;
            this.invitedUsersComponent.removeInvitedFlag();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    merge(of([this.entityType]), this.createForm.controls['createType'].valueChanges)
      .pipe(
        concatMap(type => this.prepareView(type[0])),
        takeUntil(this.destroy$),
      )
      .subscribe();

    merge(this.getSchoolValueChanges$(), this.getClassValueChanges$())
      .pipe(
        tap(() => {
          if (!this.hasModalStyle) {
            this.setStudentListVisible();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.initUserFilters();

    this.initOwnerId();
  }

  private handleUpdateEvent(event: ICrudMessage): void {
      if (event.entityType === EntityType.CLASS &&
          this.createForm.value.class && this.createForm.value.class[0] === event.entityId) {
          this.classes = this.classes.map(schoolClass => {
              if (schoolClass.value === event.entityId) {
                  schoolClass.title = event.entity.title;
              }
              return schoolClass;
          });
      } else if (event.entityType === EntityType.SCHOOL &&
          this.createForm.value.school && this.createForm.value.school[0] === event.entityId) {
          this.schools = this.schools.map(school => {
              if (school.value === event.entityId) {
                  school.title = event.entity.title;
              }
              return school;
          });
      }
  }

  private handleDeleteEvent(event: ICrudMessage): void {
    if (event.entityType === EntityType.CLASS &&
        this.createForm.value.class && this.createForm.value.class[0] === event.entityId) {
        this.createForm.patchValue({class: [null]});
        this.classes = this.classes.filter(schoolClass => schoolClass.value !== event.entityId);
    } else if (event.entityType === EntityType.SCHOOL &&
        this.createForm.value.school && this.createForm.value.school[0] === event.entityId) {
        this.createForm.patchValue({school: [null]});
        this.schools = this.schools.filter(school => school.value !== event.entityId);
    }
  }

  get shouldPutValues(): boolean {
    return this.isNew ||
        ![EntityType.SUBJECT, EntityType.CLASS, EntityType.SCHOOL].includes(this.entityType);
  }

  ngAfterViewInit() {
    if (this.invitedUsersComponent && this.entityType !== EntityType.MATERIAL) {
      this.invitedUsersComponent.initUsers();
    }

    this.setFocusOnTitleInput();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get isMobile(): boolean {
    return this.platformService.isMobile;
  }

  get isValid() {
    if (this.entityType === EntityType.MEETING) {
      return (
        this.createForm.dirty ||
        this.meetingFrequencyHasChanged ||
        (this.invitedUsersComponent && this.invitedUsersComponent.isUserListChanged)
      ) && this.createForm.valid && this.meetingFrequencyDates && this.meetingFrequencyDates.length;
    }
    return (
      this.createForm.dirty ||
      this.isGradingSystemUpdated ||
      (this.invitedUsersComponent && this.invitedUsersComponent.isUserListChanged)
    ) && this.createForm.valid;
  }

  get canRemoveEntity(): boolean {
    if (this.isNew) {
      return false;
    }

    switch (this.entityType) {
      case EntityType.SCHOOL:
        return this.permissionService.schoolDashboard.canRemoveEntity(this.entity);
      case EntityType.CLASS:
        return this.permissionService.schoolDashboard.canRemoveEntity(this.entity);
      case EntityType.SUBJECT:
        return this.permissionService.schoolDashboard.canRemoveEntity(this.entity);
      case EntityType.MEETING:
        return this.ownerId === this.entity.owner_id;
      default:
        return true;
    }
  }

  get isAddDateAndTimeBtnVisible(): boolean {
    if (this.createMenuType === CreateMenuType.MEETING) {
      return !this.isNew && !this.shouldEditReccuringMeetings;
    }
    return false;
  }

  get isFrequencySelectVisible(): boolean {
    if (this.createMenuType === CreateMenuType.MEETING) {
      if (this.isNew || !isReccuringMeeting(this.entity as IMeeting)) {
        return true;
      }
      return this.shouldEditReccuringMeetings;
    }
    return false;
  }

  get isControlDisabled(): boolean {
    return this.isLoading || this.isSubmitted;
  }

  get isCreateTypeControlDisabled(): boolean {
    return this.isControlDisabled || (!!this.entity && !!this.entity.id);
  }

  get isColorPickerVisible(): boolean {
    return this.entityType === EntityType.SUBJECT;
  }

  get schoolOfficialStatusControlDisabled(): boolean {
    return this.isControlDisabled || (!!this.entity && !!this.entity.id);
  }

  get isAddAllButtonDisabled(): boolean {
    return this.isControlDisabled || !!this.searchedUsersEmptyText.length;
  }

  get isStudentsInvitedListVisible(): boolean {
    return !this.isLoading && ![EntityType.SCHOOL, EntityType.MATERIAL].includes(this.entityType);
  }

  get isCloseRightBlockBtnVisible(): boolean {
    if (this.hasModalStyle) {
      return true;
    }
    return this.createMenuType !== CreateMenuType.MEETING;
  }

  isValidDateRange(startDate: any, endDate: any) {
    return moment(startDate).isValid() && moment(endDate).isValid;
  }

  canSeeGradingSystem(): boolean {
    return this.createMenuType === CreateMenuType.SUBJECT ||
      this.createMenuType === CreateMenuType.SUBJECT_WITH_CLASS ||
      this.createMenuType === CreateMenuType.SCHOOL;
  }

  canSeeStudentList(): boolean {
    if ([EntityType.SCHOOL, EntityType.MATERIAL].includes(this.entityType)) {
      return false;
    }
    if (this.hasModalStyle) {
      return true;
    }
    return [EntityType.SUBJECT, EntityType.CLASS].includes(this.entityType);
  }

  close() {
    if (this.hasModalStyle) {
      this.modalCtrl.dismiss();
    } else {
      this.destroyEmitter.emit();
    }
  }

  removeEntity(): void {
    if (this.entityType === EntityType.MEETING) {
     return removeMeeting(this.entity as IMeeting, this.meetingService, this.confirmationService);
    }
    const actionText: string = this.entity && this.entity.owner_id === this.ownerId ? 'REMOVE' : 'LEAVE';
    this.confirmationService.confirmAction(`${actionText}_${this.entityType}` as ConfirmAction, this.entity.title)
      .then(res => {
        if (res.isConfirmed) {
          this.getService(this.entityType).publishUpdate({
            entity: this.entity,
            entityType: this.entityType,
            eventType: EventType.REMOVE,
          } as IPublishEvent<IBaseEntity>);
        }
      });
  }

  async addAllStudents(): Promise<void> {
    if (!this.isAddAllInProcess) {
      this.isAddAllInProcess = true;
      await this.loadingService.startLoading();
      this.getAllUsers$()
        .pipe(
          tap(response => {
            showErrorIfExists(response, this.toastService);
            this.isAddAllInProcess = false;
            this.loadingService.finishLoading();
          }),
          filter(response => !response.error),
          pluck('payload'),
          map((users) => users.filter(user => !user.deactivated)),
          first(),
        )
        .subscribe(data => {
          this.invitedUsersComponent.addAll(data);
        });
    }
  }

  markFormControlsAsDirty(controls: string[] = ['name']): void {
    controls.forEach(control => this.createForm.controls[control].markAsDirty());
  }

  setStudentListVisible(): void {
    this.isGradingSystemVisible = false;
    this.isStudentListVisible = true;
  }

  setGradingSystemVisible(): void {
    this.isGradingSystemVisible = true;
    this.isStudentListVisible = false;
    if (!this.isNew) {
      this.entityGrade = null;
    }
  }

  closeRightBlock(): void {
    if (this.gradesCrudComponent) {
      this.entityGrade = this.gradesCrudComponent.getGrade();
    }
    this.isGradingSystemVisible = false;
    this.isStudentListVisible = false;
  }

  onGradeUpdate(grade: IGrade): void {
    this.entityGrade = cloneDeep(grade);
    this.isGradingSystemUpdated = true;
  }

  isStudentInvited(id: string): boolean {
    if (this.invitedUsersComponent) {
      return this.invitedUsersComponent.currentUsers.findIndex(user => user.id === id) !== -1;
    }
    return false;
  }

  inviteUser(user: InvitedUserModel): void {
    user.isNew = true;
    if (this.invitedUsersComponent) {
      this.invitedUsersComponent.addInvite(user);
    }
  }

  getRemoveButtonText(): string {
    const btnText: string = this.entity && this.entity.owner_id === this.ownerId
      ? 'remove-btn'
      : 'leave-btn';
    return this.translateService.instant(`${this.getServiceName(this.entityType)}.${btnText}`);
  }

  getParentId(): string | null {
    if (this.createMenuType === CreateMenuType.SUBJECT_WITH_CLASS && this.hasClasses()) {
      return this.createForm.value.class[0];
    } else if (this.createMenuType === CreateMenuType.CLASS_WITH_SCHOOL && this.hasSchools()) {
      return this.createForm.value.school[0];
    } else if (this.createMenuType === CreateMenuType.MEETING && this.createForm.value.subject) {
      return this.createForm.value.subject ? this.createForm.value.subject[0] : null;
    }
    return null;
  }

  getParentType(): EntityType | null {
    if (this.createMenuType === CreateMenuType.SUBJECT_WITH_CLASS && this.hasClasses()) {
      return EntityType.CLASS;
    } else if (this.createMenuType === CreateMenuType.CLASS_WITH_SCHOOL && this.hasSchools()) {
      return EntityType.SCHOOL;
    } else if (this.createMenuType === CreateMenuType.MEETING) {
      return EntityType.SUBJECT;
    }
    return null;
  }

  getTitle(): string {
    if (this.isNew) {
      return this.translateService.instant('create-menu.create-new-title');
    }
    return this.translateService.instant(`create-menu.edit-${this.titleTranslator[this.entityType]}-title`);
  }

  addNewFrequencyRange(): void {
    this.meetingFrequencyHasChanged = true;
    const currentDateTime = moment().set({ minutes: 0, seconds: 0 }).toISOString()
    this.meetingFrequencyDates.push({
      start_date: currentDateTime,
      end_date: null,
      meeting_duration: 60 * 60,  // seconds value equivalent to 1 hour
      frequency: MeetingFrequency.NONE,
      step: 1,
    });
  }

  changeFrequencyRange(newMeetingFrequency: IMeetingFrequency, index: number): void {
    if (this.isValidDateRange(newMeetingFrequency.start_date, newMeetingFrequency.end_date)) {
      this.meetingFrequencyHasChanged = true;
      const changePayload: IMeetingFrequency = {
        start_date: newMeetingFrequency.start_date,
        end_date: newMeetingFrequency.end_date,
        frequency: newMeetingFrequency.frequency,
        step: newMeetingFrequency.step,
        meeting_duration: newMeetingFrequency.meeting_duration,
      }

      if (!this.isNew && !this.shouldEditReccuringMeetings) {
        this.meetingFrequencyDates = [{
          ...this.meetingFrequencyDates[0],
          ...changePayload,
        }];
        return;
      }
      this.meetingFrequencyDates[index] = {
        ...this.meetingFrequencyDates[index],
        ...changePayload,
      };
    }
  }

  removeFrequencyRange(index: number): void {
    if (this.meetingFrequencyDates && this.meetingFrequencyDates[index]) {
      this.meetingFrequencyHasChanged = true;
      this.meetingFrequencyDates.splice(index, 1);
    }
  }

  meetingFrequencyTrackByFn(index, item: IMeetingFrequency) {
    return index;
  }

  loadMoreUsers(event: any): void {
    this.searchedUsersPageToLoad += 1;
    if (this.invitedUsersComponent) {
      this.invitedUsersComponent.loadMoreUsers();
    }

    this.getUsers$(this.currentSearchTerm, this.userFilterCurrentData)
      .pipe(
        tap(response => showErrorIfExists(response, this.toastService)),
        first(),
      )
      .subscribe(response => {
        if (response.payload && response.payload.length) {
          this.searchedStudents.push(...response.payload);
        } else {
          this.searchedUsersPageToLoad -= 1;
        }
        event.target.complete();
      });
  }

  searchNameChanged(name: string): void {
    this.studentSearchTerm$.next(name);
  }

  isMeetingFilterSelectVisible(values: ISelectOption[]): boolean {
    return !!values.length;
  }

  private handleResponse(event: IReceivedEvent<ISubject>): void {
    this.isSubmitted = false;
    console.log('event', event)
    if (event.response.error) {
      if (event.response.message) {
        this.toastService.showToast(event.response.message, TOAST_TYPE.ERROR);
      }
    } else {
      let message;
      switch (event.eventType) {
        case EventType.ADD:
          message = `${this.getServiceName(this.entityType)}.added-success`;
          break;
        case EventType.EDIT:
          message = `${this.getServiceName(this.entityType)}.edited-success`;
          break;
        case EventType.REMOVE:
          message = `${this.getServiceName(this.entityType)}.removed-success`;
          break;
      }
      if (message) {
        this.toastService.showToast(this.translateService.instant(message), TOAST_TYPE.SUCCESS);
      }
      this.createForm.reset(this.createForm.value, { emitEvent: false });
      this.close();
    }
    detectChanges(this.cdf);
  }

  changeColor(color: string) {
    if (this.entityType === EntityType.SUBJECT && (this.createForm.value.color !== color)) {
      this.createForm.controls['color'].setValue(color);
      if (!this.isNew) {
        this.markFormControlsAsDirty(['color']);
      }
    }
  }

  private getEntity(entityType: EntityType): ISchool | ISchoolPost | IClass | ISubject | IMaterial {
    if (entityType === EntityType.SUBJECT) {
      const newSubject: ISubject = {
        title: this.createForm.value.name,
        color: this.createForm.value.color,
        class_id: this.hasClasses() ? this.createForm.value.class[0] : null,
      };
      return this.isNew
        ? {
          ...newSubject,
          grade: this.getGrade(),
        } as ISubjectPost
        : {...newSubject, id: this.entity.id} as ISubject;
    } else if (entityType === EntityType.CLASS) {
      const newClass: IClass = {
        title: this.createForm.value.name,
        school_id: this.hasSchools() ? this.createForm.value.school[0] : null,
      };
      return this.isNew
        ? {
          ...newClass,
          owner_id: this.ownerId,
        } as IClass
        : {
          id: this.entity.id,
          ...newClass,
        } as IClass;
    } else if (entityType === EntityType.SCHOOL) {
      const newSchool: ISchool = {
        title: this.createForm.value.name,
        address: this.createForm.value.address,
        phone: this.createForm.value.phone,
        website: this.createForm.value.website,
        is_official: this.createForm.value.is_official,
      };
      return this.isNew
        ? {
          ...newSchool,
          grade: this.getGrade(),
        } as ISchoolPost
        : {
          id: this.entity.id,
          ...newSchool,
        } as ISchool;
    } else if (entityType === EntityType.MEETING) {
      const values = {
        title: this.createForm.value.name,
        subject_id: this.createForm.value.subject ? this.createForm.value.subject[0] : null,
        type_id: this.createForm.value.type[0],
        frequency_dates: this.meetingFrequencyDates.map(entry => {
          if (entry.end_date == null && Number.isFinite(entry.meeting_duration)) {
            const derivedEndDateMoment = moment(entry.start_date).add(entry.meeting_duration, 'seconds');
            const end_date = entry.frequency === MeetingFrequency.NONE
              ? derivedEndDateMoment.toISOString()
              : bumpToArbituaryFutureDate(derivedEndDateMoment);

            return { ...entry, end_date }
          }
          return entry;
        }),
      } as IMeeting;

      if (this.isNew) {
        return values;
      } else if (!isReccuringMeeting(this.entity as IMeeting) ||
        isReccuringMeeting(this.entity as IMeeting) && this.shouldEditReccuringMeetings) {
        return {
          ...this.entity,
          ...values,
          changeAll: true,
        } as IMeetingUpdate;
      }

      return {
        ...this.entity,
        ...values,
        frequency_dates: (this.entity as IMeeting).frequency_dates,
        start_date: this.meetingFrequencyDates[0].start_date,
        end_date: this.meetingFrequencyDates[0].end_date,
        changeAll: false,
      } as IMeetingUpdate;
    } else if (entityType === EntityType.MATERIAL) {
      const newMaterial: IMaterial = {
        title: this.createForm.value.name,
      };
      return this.isNew
        ? {
          ...newMaterial,
          owner_id: this.ownerId,
        } as IMaterial
        : {
          ...this.entity,
          ...newMaterial,
        } as IMaterial;
    }
  }

  private initOwnerId(): void {
    this.userService.getUser$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        if (user) {
          this.ownerId = user.id;
        }
      });
  }

  private async prepareView(type: EntityType): Promise<void> {
    this.isLoading = true;
    this.entityType = type;
    this.closeRightBlock();

    if (this.entityType === EntityType.SCHOOL) {
      this.createMenuType = CreateMenuType.SCHOOL;
      this.enableSchoolFieldsValidators();
      this.closeRightBlock();
    } else if (this.entityType === EntityType.CLASS) {
      this.disableSchoolFieldsValidators();
      this.disableMeetingFieldsValidators();
      if (!this.hasModalStyle) {
        this.setStudentListVisible();
      }

      this.getSchools$()
        .pipe(first())
        .subscribe(schools => {
          this.isLoading = false;
          const schoolsCount: number = schools.length;
          this.createMenuType = schoolsCount > 0 ? CreateMenuType.CLASS_WITH_SCHOOL : CreateMenuType.CLASS;
          this.schoolsList = schools;
          this.schools = getSchoolsOps(schools.slice());
          this.schools.unshift({ title: 'common.none-selected', value: null });
        });
    } else if (this.entityType === EntityType.SUBJECT) {
      this.disableSchoolFieldsValidators();
      this.disableMeetingFieldsValidators();
      if (!this.hasModalStyle) {
        this.setStudentListVisible();
      }

      this.getClasses$()
        .pipe(first())
        .subscribe(classes => {
          this.isLoading = false;
          const classesCount: number = classes.length;
          this.createMenuType = classesCount > 0 ? CreateMenuType.SUBJECT_WITH_CLASS : CreateMenuType.SUBJECT;
          this.classesList = classes.slice();
          this.classes = getClassesOps(classes.slice());
          this.classes.unshift({ title: 'common.none-selected', value: null });
        });
    } else if (this.entityType === EntityType.MEETING) {
      if (!this.hasModalStyle) {
        this.setStudentListVisible();
      }
      this.disableSchoolFieldsValidators();
      this.enableMeetingFieldsValidators();
      this.createMenuType = CreateMenuType.MEETING;
      this.shouldEditReccuringMeetings = this.isNew ? false : this.payload ? this.payload.editReccuringMeeting : false;

      if (this.isNew && this.entity && (this.entity as IMeeting).frequency_dates && (this.entity as IMeeting).frequency_dates.length) {
        this.meetingFrequencyDates = (this.entity as IMeeting).frequency_dates.slice();
      } else if (!this.isNew && this.entity && (this.entity as IMeeting).frequency_dates) {
        this.meetingFrequencyDates = this.shouldEditReccuringMeetings || !isReccuringMeeting(this.entity as IMeeting)
          ? (this.entity as IMeeting).frequency_dates.slice()
          : [{
              start_date: (this.entity as IMeeting).start_date,
              end_date: (this.entity as IMeeting).end_date,
              frequency: MeetingFrequency.NONE,
              id: (this.entity as IMeeting).date_serial_id,
            } as IMeetingFrequency];
      }

      zip(
        this.getSubjectsTeach$(),
        this.getMeetingTypes$(),
      )
      .pipe(
        tap(values => {
          this.meetingTypes = values[1]
              .filter(meetingType => !meetingType.owner_id || meetingType.owner_id === this.ownerId)
              .map(entry => ({
            title: entry.label,
            value: entry.id,
          }));

          this.subjects = getSubjectsOps(values[0]);
        }),
        delay(300),
        first(),
      )
      .subscribe(values => {
        this.isLoading = false;
        if (this.isNew) {
          const typeValue = values[1].find(t => t.label === 'Lesson').id;
          this.createForm.patchValue({ type: typeValue ? [typeValue] : null }, { emitEvent: false });
          if (this.meetingTypesSelect) {
            detectChanges(this.meetingTypesSelect.cdr);
          }
        }
      });


    } else if (this.entityType === EntityType.MATERIAL) {
      this.disableSchoolFieldsValidators();
      this.disableMeetingFieldsValidators();
      this.createMenuType = CreateMenuType.MATERIAL;
      this.closeRightBlock();
    }
    this.isLoading = false;
  }

  private enableSchoolFieldsValidators(): void {
    this.createForm.controls.website.setValidators(Validators.required);
    this.createForm.controls.phone.setValidators(Validators.required);
    this.createForm.controls.address.setValidators(Validators.required);
    this.createForm.controls.website.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    this.createForm.controls.phone.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    this.createForm.controls.address.updateValueAndValidity({ emitEvent: false, onlySelf: true });
  }

  private disableSchoolFieldsValidators(): void {
    this.createForm.controls.website.clearValidators();
    this.createForm.controls.phone.clearValidators();
    this.createForm.controls.address.clearValidators();
    this.createForm.controls.website.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    this.createForm.controls.phone.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    this.createForm.controls.address.updateValueAndValidity({ emitEvent: false, onlySelf: true });
  }

  private enableMeetingFieldsValidators(): void {
    this.createForm.controls.type.setValidators(Validators.required);
    this.createForm.controls.type.updateValueAndValidity({ emitEvent: false, onlySelf: true });
  }

  private disableMeetingFieldsValidators(): void {
    this.createForm.controls.type.clearValidators();
    this.createForm.controls.type.updateValueAndValidity({ emitEvent: false, onlySelf: true });
  }

  private getServiceName(entityType: EntityType): string {
    if (entityType === EntityType.SCHOOL) {
      return 'school';
    } else if (entityType === EntityType.CLASS) {
      return 'class';
    } else if (entityType === EntityType.SUBJECT) {
      return 'subject';
    } else if (entityType === EntityType.MEETING) {
      return 'meeting';
    } else if (entityType === EntityType.MATERIAL) {
      return 'material';
    }
  }

  private getService(entityType: EntityType): BaseService<any> {
    if (entityType === EntityType.SCHOOL) {
      return this.schoolService;
    } else if (entityType === EntityType.CLASS) {
      return this.classService;
    } else if (entityType === EntityType.SUBJECT) {
      return this.subjectService;
    } else if (entityType === EntityType.MEETING) {
      return this.meetingService;
    } else if (entityType === EntityType.MATERIAL) {
      return this.materialService;
    }
  }

  private getGrade(): IGrade {
    if (this.gradesCrudComponent) {
      return this.gradesCrudComponent.getGrade();
    }
    return this.entityGrade
      ? this.entityGrade
      : { gradings: [] };
  }

  private getAllSchools$(): Observable<ISchool[]> {
    return this.schoolStoreService.getState$()
      .pipe(
        filter(state => !!state),
        map(state => [...state['teach'], ...state['study']]),
      );
  }

  private getAllClasses$(): Observable<IClass[]> {
    return this.classStoreService.getState$()
      .pipe(
        filter(state => !!state),
        map(state => [...state['teach'], ...state['study']]),
      );
  }

  private getAllSubjects$(): Observable<ISubject[]> {
    return this.subjectStoreService.getState$()
      .pipe(
        filter(state => !!state),
        map(state => ([ ...state.teach, ...state.study ])),
      );
  }

  private initUserFiltersSelects(schools: ISchool[], classes: IClass[], subjects: ISubject[]): void {
    this.schoolsFilterList = getSchoolsOps(schools.slice());
    this.schoolsFilterList.unshift({
      title: 'common.none-selected',
      value: null,
    });
    this.classesFilterList = getClassesOps(classes.slice());
    this.classesFilterList.unshift({
      title: 'common.none-selected',
      value: null,
    });
    this.subjectsFilterList = getSubjectsOps(subjects.slice());
    this.subjectsFilterList.unshift({
      title: 'common.none-selected',
      value: null,
    });
  }

  private getSchools$(): Observable<ISchool[]> {
    return this.schoolStoreService.getState$()
    .pipe(
      filter(state => !!state),
      pluck('teach'),
      map(schools => schools ? schools : []),
    );
  }

  private getClasses$(): Observable<IClass[]> {
    return this.classStoreService.getState$()
      .pipe(
        filter(state => !!state),
        pluck('teach'),
        map(classes => classes ? classes : []),
      );
  }

  private getSubjectsTeach$(): Observable<ISubject[]> {
    return this.subjectStoreService.getState$()
      .pipe(
        filter(state => !!state),
        pluck('teach'),
      );
  }

  private getMeetingTypes$(): Observable<IMeetingType[]> {
    return this.meetingService.getTypeParams$();
  }

  private hasClasses(): boolean {
    return this.createForm.value.class && this.createForm.value.class.length;
  }

  private hasSchools(): boolean {
    return this.createForm.value.school && this.createForm.value.school.length;
  }

  private getUsers$(search: string, data: { entityId: any; entityType: EntityType; }[], loadAll: boolean = false): Observable<IResponse<UserModel[]>> {
    return this.inviteService.getUsers$(
      search,
      this.entityType === EntityType.MEETING ? UserTypes.ALL : UserTypes.STUDENT,
      data,
      loadAll ? 0 : this.searchedUsersPageToLoad,
      loadAll ? 0 : this.searchedUsersLimit,
        false,
        true,
        UserTypes.ALL,
        this.isNew
    )
    .pipe(
      map(response => {
        if (response.payload && response.payload.length) {
          const ownerIndex = response.payload.findIndex(user => user.id === this.ownerId);
          if (ownerIndex !== -1) {
            response.payload.splice(ownerIndex, 1);
            return response;
          }
        }
        return response;
      }),
      switchMap(response => {
          if (response.payload && response.payload.length) {
              return from(Promise.all(response.payload.map(user => getUserWithAvatar(user, this.sanitizer))))
                  .pipe(map(payload => ({payload})));
          } else {
              return of(response);
          }
      }),
    );
  }

  private getAllUsers$(): Observable<IResponse<UserModel[]>> {
    return this.getUsers$(this.currentSearchTerm, this.userFilterCurrentData, true);
  }

  private canEditEntity(entity: IBaseEntity, entityType: EntityType): boolean {
    if (entityType === EntityType.SCHOOL) {
      return this.permissionService.schoolDashboard.canEditEntity(entity);
    } else if (entityType === EntityType.CLASS) {
      return this.permissionService.classDashboard.canEditEntity(entity);
    } else if (entityType === EntityType.SUBJECT) {
      return this.permissionService.subjectDashboard.canEditEntity(entity);
    }
    return true;
  }

  private canRemoveUsersToEntity(entity: IBaseEntity, entityType: EntityType): boolean {
    if (entityType === EntityType.SCHOOL) {
      return this.permissionService.schoolDashboard.canRemoveStudentOrTeacher(entity);
    } else if (entityType === EntityType.CLASS) {
      return this.permissionService.classDashboard.canRemoveStudentOrTeacher(entity);
    } else if (entityType === EntityType.SUBJECT) {
      return this.permissionService.subjectDashboard.canRemoveStudentOrTeacher(entity);
    }
    return true;
  }

  private canAddUsersToEntity(entity: IBaseEntity, entityType: EntityType): boolean {
    if (entityType === EntityType.SCHOOL) {
      return this.permissionService.schoolDashboard.canInviteStudents(entity);
    } else if (entityType === EntityType.CLASS) {
      return this.permissionService.classDashboard.canInviteStudents(entity);
    } else if (entityType === EntityType.SUBJECT) {
      return this.permissionService.subjectDashboard.canInviteStudents(entity);
    }
    return true;
  }

  private setFocusOnTitleInput(): void {
    of(true)
      .pipe(delay(1500))
      .subscribe(() => {
        this.titleInput.setFocus();
      });
  }

  private getSchoolValueChanges$(): Observable<any> {
    return this.createForm.controls['school'].valueChanges
      .pipe(tap(value => this.userFilterForm.controls.filter_school.setValue(value)));
  }

  private getClassValueChanges$(): Observable<any> {
    return this.createForm.controls['class'].valueChanges
      .pipe(tap(value => this.userFilterForm.controls.filter_class.setValue(value)));
  }

  private async getConfirmationOfChangingParent$(): Promise<IConfirmResponse<IChangeParentPayload>> {
    if ((this.entityType === EntityType.SUBJECT && !this.createForm.value.class[0]) ||
      (this.entityType === EntityType.CLASS && !this.createForm.value.school[0])
    ) {
      return { isConfirmed: true, payload: { move_all: false } } as IConfirmResponse<IChangeParentPayload>;
    }

    if (this.invitedUsersComponent && this.invitedUsersComponent.currentUsers && this.invitedUsersComponent.currentUsers.length) {
      return await this.openParentChangeConfirmModal();
    }

    return { isConfirmed: true, payload: { move_all: false } } as IConfirmResponse;
  }

  private async openParentChangeConfirmModal(): Promise<IConfirmResponse<IChangeParentPayload>> {
    const isEqualById = id => (entry: IBaseEntity) => entry.id === id;

    const nextParentEntityId = this.entityType === EntityType.CLASS
      ? this.createForm.value.school[0]
      : this.entityType === EntityType.SUBJECT
        ? this.createForm.value.class[0]
        : null;
    const nextParentEntity = this.entityType === EntityType.CLASS
      ? this.schoolsList.find(isEqualById(nextParentEntityId))
      : this.classesList.find(isEqualById(nextParentEntityId))

    type ParentChangeConfirmComponentProps = Pick<
      ParentChangeConfirmComponent,
      'entityId' | 'entityType' | 'entityChange'
    >

    const modal = await this.modalCtrl.create({
      component: ParentChangeConfirmComponent,
      componentProps: {
        entityId: this.entity.id,
        entityType: this.entityType,
        nextParentEntityId,
        nextParentEntity: this.entityType === EntityType.CLASS ? EntityType.SCHOOL : EntityType.CLASS,
        entityChange: {
          srcTitle: this.entity.title,
          destTitle: nextParentEntity.title,
        }
      } as ParentChangeConfirmComponentProps,
      cssClass: 'parent-change-confirm-modal'
    });

    modal.present();

    const res = await modal.onDidDismiss();
    return res.data;
  }

  private shouldChangeParent(): boolean {
    if ([EntityType.SUBJECT, EntityType.CLASS].includes(this.entityType)) {
      const prevParentId: string = this.entityType === EntityType.SUBJECT
        ? (this.entity as ISubject).class_id
        : (this.entity as IClass).school_id;

      const currParentId: string = this.entityType === EntityType.SUBJECT
        ? this.createForm.value.class[0]
        : this.createForm.value.school[0];

      if (!prevParentId || !currParentId) {
        return false;
      }

      return prevParentId !== currParentId;
    }

    return false;
  }

  private changeParent$(): Observable<IResponse<any>> {
    return from(this.getConfirmationOfChangingParent$())
      .pipe(
        switchMap(res => {
          // Don't proceed since user canceled confirmation
          if (!res.isConfirmed) {
            return of<IResponse<null>>({ payload: null });
          }
          this.isSubmitted = true;
          if (this.entityType === EntityType.SUBJECT) {
            const payload: IChangeSubjectClassPayload = {
              ...res.payload,
              id: this.entity.id,
              class_id: this.createForm.value.class[0],
            }
            return this.subjectService.changeClass$(payload);
          }

          const payload: IChangeClassSchoolPayload = {
            ...res.payload,
            id: this.entity.id,
            school_id: this.createForm.value.school[0],
          }
          return this.classService.changeSchool$(payload);
        }),
      );
  }

  private inviteUpdateRequestList(entityId: string): Observable<any>[] {
    const requests$: Observable<any>[] = [];
    const shouldUpdateInvites = this.shouldUpdateInvites();

    if (shouldUpdateInvites) {
      requests$.push(...this.getRequestsToUpdateInvites$(entityId));
    }

    return requests$;
  }

  private getExtraRequests$(event: IReceivedEvent<any>): Observable<IReceivedEvent<any>> {
    if (isSuccess(event.response)) {
      const requests$: Observable<any>[] = [];
      const shouldUpdateGrading = this.isNew ? false : this.isGradingSystemUpdated;

      requests$.push(...this.inviteUpdateRequestList(event.response.payload.id));

      if (shouldUpdateGrading) {
        requests$.push(this.gradingService.updateGrading$(this.getGrade(), this.entityType, this.entity.id));
      }

      if (requests$.length) {
        return forkJoin(requests$)
          .pipe(mapTo(event));
      }
    }
    return of(event);
  }

  private shouldUpdateInvites(): boolean {
    if ([EntityType.SCHOOL, EntityType.MATERIAL].includes(this.entityType) || !this.invitedUsersComponent || this.skipUserUpdate) {
      return false;
    }

    return this.isNew
      ? this.invitedUsersComponent.currentUsers.length > 0
      : this.invitedUsersComponent.isUserListChanged;
  }

  private getRequestsToUpdateInvites$(entityId: string): Observable<any>[] {
    const requests$: Observable<any>[] = [];

    if (this.isNew) {
      requests$.push(
        this.inviteService.createInvitations$(entityId, this.entityType, this.invitedUsersComponent.currentUsers.map(u => u.id))
      );
    } else {
      if (this.invitedUsersComponent.usersToAdd.length) {
        requests$.push(
          this.inviteService.createInvitations$(this.entity.id, this.entityType, this.invitedUsersComponent.usersToAdd.map(u => u.id))
        );
      }
      if (this.invitedUsersComponent.usersToRemove.length) {
        requests$.push(
          this.inviteService.removeInvitations$(this.entity.id, this.entityType, this.invitedUsersComponent.usersToRemove.map(u => u.id))
        );
      }
    }

    return requests$;
  }

  private initUserFilters(): void {
    zip(
      this.getAllSchools$(),
      this.getAllClasses$(),
      this.getAllSubjects$()
    )
    .pipe(
      tap(([schools, classes, subjects]) => {
        this.initUserFiltersSelects(schools, classes, subjects);
      }),
      delay(300),
      switchMap(values => {
        if (this.shouldInviteAllUsersOfSelectedClass()) {
          this.userFilterCurrentData = [{ entityId: this.payload.createMeetingForClassId, entityType: EntityType.CLASS }];
          return this.addAllStudents()
            .finally(() => values);
        }
        return of(values);
      }),
      first(),
    )
    .subscribe(values => {
      this.isLoading = false;
    });

    const searchUsersName$ = this.studentSearchTerm$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        startWith(''),
        map(value => value.trim()),
      );
    const searchBySchool$ = this.userFilterForm.controls.filter_school.valueChanges
      .pipe(
        map(id => ({ entityId: id[0], entityType: EntityType.SCHOOL })),
        distinctUntilChanged(),
        startWith(null),
      );
    const searchByClass$ = this.userFilterForm.controls.filter_class.valueChanges
      .pipe(
        map(id => ({ entityId: id[0], entityType: EntityType.CLASS })),
        distinctUntilChanged(),
        startWith(null),
      );
    const searchBySubject$ = this.userFilterForm.controls.filter_subject.valueChanges
      .pipe(
        map(id => ({ entityId: id[0], entityType: EntityType.SUBJECT })),
        distinctUntilChanged(),
        startWith(null),
      );

    combineLatest([searchUsersName$, searchBySchool$, searchByClass$, searchBySubject$])
      .pipe(
        map(values => [values[0], [values[1], values[2], values[3]].filter(v => v && v.entityId !== null)]),
        tap(values => {
          this.isStudentListLoading = true;
          this.searchedUsersPageToLoad = 1;
          this.searchedStudents = [];
          this.currentSearchTerm = values[0] as string;
          this.userFilterCurrentData = values[1] as { entityId: any; entityType: EntityType; }[];
        }),
        concatMap(values => this.getUsers$(values[0] as string, values[1] as { entityId: any; entityType: EntityType; }[])),
        tap(response => showErrorIfExists(response, this.toastService)),
        map(response => response.payload ? response.payload : []),
        takeUntil(this.destroy$),
      )
      .subscribe(students => {
        this.isStudentListLoading = false;
        this.searchedStudents = students;
        this.searchedUsersEmptyText = this.searchedStudents.length ? '' : this.translateService.instant('common.list-empty');
      });
  }

  private getPreselectedSchoolId(schoolClass: IClass): string | null {
    if (schoolClass) {
      return schoolClass.school_id;
    }
    return null;
  }

  private shouldInviteAllUsersOfSelectedClass(): boolean {
    return this.payload && !!this.payload.createMeetingForClassId;
  }
}
