import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';
import { TicketParent, EntityType } from '../models/common';
import { Observable } from 'rxjs';
import { IResponse } from '../models/response';
import { HttpService } from './http.service';
import {LinkStorageService} from './link-storage.service';

export interface ITicketRouteParams {
  ticketId: string;
  entityType: EntityType;
  ticketParent?: TicketParent;
  chatId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TicketRouteService {

  public inAppNavigationFlag: boolean = false;
  private readonly COPY_PASTE_LINK_URL: string = 'v1/tickets/copy-paste-link';

  constructor(
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private linkStorageService: LinkStorageService,
  ) {
  }

  openTicket(params: ITicketRouteParams) {
    if (params.entityType === EntityType.ASSIGNED_TASK) {
      return this.openAssignedTask(params.ticketId);
    }

    const taskUrl: string = this.prepareUrl(params.ticketId, params.entityType, params.ticketParent);

    const queryParams = {};
    if (params.entityType === EntityType.TICKET_TASK && params.chatId) {
      queryParams['chatId'] = params.chatId;
    }

    this.navCtrl.navigateForward(taskUrl, { relativeTo: this.route, queryParams });
  }

  retrieveTicketRoute$(ticketRouteParams: ITicketRouteParams): Observable<IResponse<ITicketRouteParams>> {
    return this.httpService.post$(this.COPY_PASTE_LINK_URL, ticketRouteParams);
  }

  prepareUrl(ticketId: string, entityType: EntityType, ticketParent: TicketParent): string {
    return `pages/ticket/${ticketId}/${entityType}/${ticketParent}`;
  }

  private openAssignedTask(assignedTaskId: string) {
    const taskUrl: string = `pages/ticket/${assignedTaskId}/${EntityType.ASSIGNED_TASK}/${TicketParent.SUBJECT}`;
    this.linkStorageService.saveTicketLink(taskUrl);
    this.navCtrl.navigateForward(taskUrl, { relativeTo: this.route });
  }
}
