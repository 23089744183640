import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IMaterial } from '../models/material';
import { EntityType, IDashboard, IDashboardColumn, EventType, INotifyUpdatePayload } from '../models/common';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { IResponse } from '../models/response';
import { ITicket } from '../models/ticket';
import { addDashboardColumn$, editDashboardColumn$, removeDashboardColumn$ } from '../helpers/helpers';
import { ToastService } from './toast.service';
import { ITicketFilter } from '../components/ticket-filter/models';
import {map, pluck, tap} from 'rxjs/operators';
import { SocketService } from './socket.service';

export interface IMaterialTicketsByPageParams {
  materialId: string;
  columnId: string;
  selectedDate: string;
  filters: ITicketFilter;
  page: number;
  limit: number;
}

export interface IMaterialTicketsNearDateParams {
  columnId: string;
  selectedDate: string;
  filters: ITicketFilter;
  limit: number;
}

@Injectable({
  providedIn: 'root'
})
export class MaterialService extends BaseService<IMaterial> {

  private readonly MATERIALS_URL: string = 'v1/materials';

  protected entityType: EntityType = EntityType.MATERIAL;
  protected GET_ALL: string = this.MATERIALS_URL;
  protected POST_ONE: string = this.MATERIALS_URL;
  protected PUT_ONE: string = this.MATERIALS_URL;
  protected PATCH_ONE: string = this.MATERIALS_URL;
  protected GET_ONE: (id: string) => string = (id: string) => `${this.MATERIALS_URL}/${id}`;
  protected GET_MATERIAL_DASHBOARD_DATA_INIT = (id: string, limitByColumn: number, filters: string) => `${this.MATERIALS_URL}/dashboard-data/${id}/init?limitByColumn=${limitByColumn}&filters=${filters}`;
  protected GET_MATERIAL_DASHBOARD_DATA_BY_PAGE = (params: IMaterialTicketsByPageParams) => `${this.MATERIALS_URL}/dashboard-data/${params.materialId}?page=${params.page}&limit=${params.limit}&columnId=${params.columnId}&selectedDate=${params.selectedDate}&filters=${JSON.stringify(params.filters)}`;
  protected GET_DASHBOARD_BY_MATERIAL = (id: string) => `${this.MATERIALS_URL}/dashboard/${id}`;
  protected DELETE_ONE: (id: string) => string = (id: string) => `${this.MATERIALS_URL}/${id}`;
  protected GET_TIMELINE_DATES_BY_COLUMN = (columnId: string, filters: string) => `${this.MATERIALS_URL}/dashboard-data/timeline-dates-by-column/${columnId}?filters=${filters}`;
  protected GET_TIMELINE_DATES_BY_ALL_COLUMNS = (materialId: string, filters: string) => `${this.MATERIALS_URL}/dashboard-data/all-timeline-dates-by-material-id/${materialId}?filters=${filters}`;
  protected GET_DASHBOARD_DATA_NEAR_DATE = (params: IMaterialTicketsNearDateParams) => `${this.MATERIALS_URL}/near-selected-date?limit=${params.limit}&columnId=${params.columnId}&selectedDate=${params.selectedDate}&filters=${JSON.stringify(params.filters)}`;

  constructor(
    protected httpService: HttpService,
    protected toastService: ToastService,
    private socketService: SocketService,
  ) {
    super(httpService, toastService);

    this.socketService.getCrudMessages$([EntityType.MATERIAL])
      .subscribe(payload => this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId }));
  }

  getDashboardTicketsInit$(materialId: string, limitByColumn: number, filters: ITicketFilter): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_MATERIAL_DASHBOARD_DATA_INIT(materialId, limitByColumn, JSON.stringify(filters)));
  }

  getDashboardTicketsByPage$(params: IMaterialTicketsByPageParams): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_MATERIAL_DASHBOARD_DATA_BY_PAGE(params));
  }

  getDashboardByMaterialId$(id: string): Observable<IResponse<IDashboard>> {
    return this.httpService.get$(this.GET_DASHBOARD_BY_MATERIAL(id));
  }

  addDashboardColumn$(column: IDashboardColumn, materialId: string): Observable<IResponse<IDashboardColumn>> {
    return addDashboardColumn$(column, materialId, EntityType.MATERIAL, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.ADD_COLUMN, response, { entityId: materialId })));
  }

  editDashboardColumn$(column: IDashboardColumn, materialId: string): Observable<IResponse<IDashboardColumn>> {
    return editDashboardColumn$(column, EntityType.MATERIAL, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.EDIT_COLUMN, response, { entityId: materialId })));
  }

  removeDashboardColumn$(column: IDashboardColumn, materialId: string): Observable<IResponse<IDashboardColumn>> {
    return removeDashboardColumn$(column.id, EntityType.MATERIAL, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.REMOVE_COLUMN, response, { entityId: materialId })));
  }

  getTimelineDatesByColumn$(
    columnId: string,
    filters: ITicketFilter,
  ): Observable<IResponse<string[]>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_COLUMN(columnId, JSON.stringify(filters)));
  }

  getTimelineDatesByAllColumns$(
    materialId: string,
    filters: ITicketFilter,
  ): Observable<IResponse<{ [columnId: string]: string[] }>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_ALL_COLUMNS(materialId, JSON.stringify(filters)));
  }

  getDashboardTicketsNearDate$(params: IMaterialTicketsNearDateParams): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_DASHBOARD_DATA_NEAR_DATE(params));
  }

  shouldNotifyAboutMaterialTicketUpdate$(materialTicketId: string): Observable<boolean> {
    return this.httpService.get$(`${this.GET_ONE(materialTicketId)}/should-notify-about-update`)
        .pipe(pluck('payload'), map((payload: {canNotify: boolean}) => payload.canNotify));
  }

  protected notifyAboutUpdateColumn(eventType: EventType, response: IResponse<IMaterial>, payload: INotifyUpdatePayload) {
    if (response.error || !this.socketService.isConnected()) {
      super.notifyAboutUpdate(eventType, response, payload);
    }
  }
}
