import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';
import produce from 'immer';
import * as ChatActions from '../actions/chat';
import {
  IMessage,
  IStreamId,
  IMessageId,
  INewMessage,
  NewMessageStatus,
  IChatStream,
  MarkMessageReadEntry,
  ChatMember,
  IChatMessageAttachment
} from 'src/app/shared/models/chat';

export interface IChatStreamState {
  loading: boolean;
  loaded: boolean;
  error: { msg: string; code: string } | null;
  id: number;
  data: IChatStream;
  messages: IMessage[];
  anchor: IMessageId;
  firstUnread: IMessageId | null;
  attachments: IChatMessageAttachment[];
  attachmentsLoaded: boolean;
}

export interface IChatUserState {
  data: ChatMember;
  online: boolean;
  loading: boolean;
  loadingFailure: any;
}

export type IChatStateStreams = Record<IStreamId, IChatStreamState>;
export type IChatStateDrafts = Record<IMessageId, INewMessage>;
export type IChatStateUsers = Record<number, IChatUserState>

export interface State {
  streams: IChatStateStreams;
  drafts: IChatStateDrafts;
  users: IChatStateUsers;
  streamsLoading: boolean;
  streamsLoadingFailure: { msg: string, code: string } | null;
  markMessagesRead: MarkMessageReadEntry[];
}

const initialState: State = {
  streams: {},
  drafts: {},
  users: {},
  streamsLoading: false,
  streamsLoadingFailure: null,
  markMessagesRead: [],
}

const chatReducer = createReducer(
  initialState,
  on(ChatActions.resetChatState, () => initialState),
  on(ChatActions.unloadChatStream, (state, action) =>
    produce(state, _state => {
      for (let streamId of action.streams) {
        delete _state.streams[streamId];
      }
    })
  ),
  on(ChatActions.loadSingleChatStream, (state, action) =>
    produce(state, _state => {
      if (_state.streams[action.streamId] == null) {
        _state.streams[action.streamId] = {
          loading: true,
          loaded: false,
          error: null,
          id: action.streamId,
          data: null,
          messages: [],
          anchor: null,
          firstUnread: null,
          attachments: null,
          attachmentsLoaded: false,
        }
      }
    })
  ),
  on(ChatActions.loadChatStreams, (state, action) =>
    produce(state, _state => {
      _state.streamsLoading = true;
      _state.streamsLoadingFailure = null;

      if (action.streams.length > 0) {
        for (const streamId of action.streams) {
          _state.streams[streamId] = {
            loading: true,
            loaded: false,
            error: null,
            id: streamId,
            data: null,
            messages: [],
            anchor: null,
            firstUnread: null,
            attachments: null,
            attachmentsLoaded: false,
          }
        }
      }
    })
  ),
  on(ChatActions.loadChatStreamsSuccess, (state, action) =>
    produce(state, _state => {
      _state.streamsLoading = false;
      _state.streamsLoadingFailure = null;

      for (const stream of action.streams) {
        _state.streams[stream.stream_id] = {
          loading: false,
          loaded: false,
          error: null,
          id: stream.stream_id,
          data: { ...stream },
          messages: [],
          anchor: null,
          firstUnread: null,
          attachments: null,
          attachmentsLoaded: false,
        }

        if (stream.meta.title != null) {
          _state.streams[stream.stream_id].data.title = stream.meta.title;
        }
      }
    })
  ),
  on(ChatActions.initChatStreams, (state, action) =>
    produce(state, _state => {
      _state.streamsLoading = false;
      _state.streamsLoadingFailure = null;

      for (const stream of action.streams) {
        _state.streams[stream.stream_id] = {
          loading: false,
          loaded: false,
          error: null,
          id: stream.stream_id,
          data: { ...stream },
          messages: [],
          anchor: null,
          firstUnread: null,
          attachments: null,
          attachmentsLoaded: false,
        }

        if (typeof stream.meta === 'object' && stream.meta.title != null) {
          _state.streams[stream.stream_id].data.title = stream.meta.title;
        }
      }
    })
  ),
  on(ChatActions.updateChatStreamData, (state, action) =>
    produce(state, _state => {
      for (let prop in action.data) {
        _state.streams[action.streamId].data[prop] = action.data[prop];
      }
    })
  ),
  on(ChatActions.batchUpdateChatStreamData, (state, action) =>
    produce(state, _state => {
      for (const stream of action.streams) {
        _state.streams[stream.stream_id].data = {
          ...(_state.streams[stream.stream_id].data),
          ...stream,
        }
      }
    })
  ),
  on(ChatActions.addChatStreamSubscriber, (state, action) =>
    produce(state, _state => {
      if (action.streamId in _state.streams) {
        _state.streams[action.streamId].data.subscribers.push(action.uid);
      }
    })
  ),
  on(ChatActions.removeChatStreamSubscriber, (state, action) =>
    produce(state, _state => {
      if (action.streamId in _state.streams) {
        const subIx = _state.streams[action.streamId].data.subscribers.findIndex(
          entry => entry === action.uid
        );
        if (subIx > -1) {
          _state.streams[action.streamId].data.subscribers.splice(subIx, 1);
        }
      }
    })
  ),
  on(ChatActions.setChatStreamFirstUnread, (state, action) =>
    produce(state, _state => {
      if (action.streamId in _state.streams) {
        _state.streams[action.streamId].firstUnread = action.firstUnread;
      }
    })
  ),
  on(ChatActions.loadRemoteMessages, (state, action) => 
    produce(state, (_state) => {
      _state.streams[action.streamId].loading = true;
      _state.streams[action.streamId].error = null;
    })
  ),
  on(ChatActions.loadRemoteMessagesSuccess, (state, action) => 
    produce(state, _state => {
      let messages: IMessage[] = null;
      // Numeric literals with absolute values equal to 2^53 or greater are too large to be represented accurately as integers.
      // This is why it compares strings instead of numbers.
      // The 10e15 value assumes "newest" anchor according to zulip API
      if (action.anchor.toString() === (10e15).toString()) {
        messages = action.data;
      } else {
        const currentStateMessages = state.streams[action.streamId].messages.filter(
          message => message.id !== action.anchor
        );
        messages = [].concat(action.data, currentStateMessages)
          .sort((a, b) => a.id - b.id);
      }

      messages = messages.map(msg => {
        if (msg.content.includes('attr-data-msg-attachments')) {
          return {
            ...msg,
            hasAttachments: true,
          }
        }
        return msg;
      })

      _state.streams[action.streamId] = {
        ...state.streams[action.streamId],
        loading: false,
        loaded: true,
        error: null,
        anchor: action.anchor,
        messages,
      }
    })
  ),
  on(ChatActions.loadRemoteMessagesFailure, (state, action) => 
    produce(state, _state => {
      _state.streams[action.streamId].loading = false;
      _state.streams[action.streamId].error = action.error;  
    })
  ),
  on(ChatActions.setMessage, (state, action) =>
    produce(state, _state => {
      const message = {
        ...action.message,
        hasAttachments: action.message.content.includes('attr-data-msg-attachments')
      }

      if (action.message.local_id) {
        const draftMessageIx = _state.streams[action.streamId].messages.findIndex(({ id }) =>
          id === message.local_id
        );

        if (draftMessageIx > -1) {
          _state.streams[action.streamId].messages[draftMessageIx] = message;
        }
      } else {
        _state.streams[action.streamId].messages.push(message);
      }
    })
  ),
  on(ChatActions.setDraftMessage, (state, action) =>
    produce(state, _state => {
      _state.drafts[action.message.id] = {
        streamId: action.streamId,
        tempId: action.message.id,
        status: NewMessageStatus.Draft,
        id: null
      }
    })
  ),
  on(ChatActions.updateDraftMessage, (state, action) =>
    produce(state, _state => {
      _state.drafts[action.draftId].status = action.status;

      if (action.status === NewMessageStatus.Sent) {
        _state.drafts[action.draftId].id = action.id;
      }
    })
  ),
  on(ChatActions.removeDraftMessage, (state, action) =>
    produce(state, _state => {
      delete _state.drafts[action.draftId];
    })
  ),
  on(ChatActions.addMarkMessagesRead, (state, action) =>
    produce(state, _state => {
      _state.markMessagesRead.push(...action.messages);
    })
  ),
  on(ChatActions.markMessagesReadSuccess, (state, action) =>
    produce(state, _state => {
      const targets = _state.markMessagesRead.filter(({ id }) => action.messages.includes(id));
      
      for (let entry of targets) {
        // FIXME: what if update_message_flags event arrives but target message is not yet present in store?
        const targetMessageIx = _state.streams[entry.streamId].messages.findIndex(
          msg => msg.id === entry.id
        );
        const targetMessage = _state.streams[entry.streamId].messages[targetMessageIx];

        if (targetMessage != null) {
          targetMessage.flags = [].concat((targetMessage.flags || []), ['read']);
          const markMessagesReadEntryIx = _state.markMessagesRead.findIndex(
            entry => entry.id === targetMessage.id
          );
          _state.markMessagesRead.splice(markMessagesReadEntryIx, 1);
        }

      }
    })
  ),
  on(ChatActions.markMessagesReadFailure, (state, action) =>
    produce(state, _state => {
      const failedMessages = action.messages.map(({ id }) => id);
      _state.markMessagesRead = _state.markMessagesRead.filter(
        entry => failedMessages.includes(entry.id)
      );
    })
  ),
  on(ChatActions.loadUserDataSuccess, (state, action) =>
    produce(state, _state => {
      _state.users[action.uid] = {
        online: false,
        loading: false,
        loadingFailure: null,
        data: action.data,
      }
    })
  ),
  on(ChatActions.loadUserDataFailed, (state, action) => {
    console.error(action.error);
    return produce(state, _state => {
      _state.users[action.uid] = {
        online: false,
        loading: false,
        loadingFailure: action.error,
        data: null
      }
    })
  }),
  on(ChatActions.updateUserPresence, (state, action) =>
    produce(state, _state => {
      if (action.uid in _state.users) {
        _state.users[action.uid].online = action.online
      }
    })
  ),
  on(ChatActions.updateUsersPresence, (state, action) =>
    produce(state, _state => {
      for (let { uid, online } of action.payload) {
        if (uid in _state.users) {
          _state.users[uid].online = online
        }
      }
    })
  ),
  on(ChatActions.batchLoadChatUsersSuccess, (state, action) =>
    produce(state, _state => {
      for (let entry of action.payload) {
        _state.users[entry.zulip_uid] = {
          online: false,
          loading: false,
          loadingFailure: null,
          data: entry,
        }
      }
    })
  ),
  on(ChatActions.batchLoadChatUsersFailed, (state, action) => state),
  on(ChatActions.loadChatStreamAttachments, (state, action) => state),
  on(ChatActions.loadChatStreamAttachmentsSuccess, (state, action) =>
    produce(state, _state => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        if (_state.streams[action.streamId].attachments == null) {
          _state.streams[action.streamId].attachments = [...action.payload];
        } else {
          _state.streams[action.streamId].attachments.push(...action.payload);
        }
      }
    })
  ),
  on(ChatActions.loadChatStreamAttachmentsFailure, (state, action) => state),
  on(ChatActions.setChatStreamAttachmentsLoaded, (state, action) =>
    produce(state, _state => {
      _state.streams[action.streamId].attachmentsLoaded = action.loaded;
    })
  ),
)

export const chatFeatureKey = 'chat';

export function reducer(state: State, action: Action) {
  return chatReducer(state, action);
}