import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import {map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { IChatStream, IStreamId } from 'src/app/shared/models/chat';
import { selectChatStreamMembers, selectChatStreams } from 'src/app/store/selectors/chat.selectors';
import { trackById } from '../../helpers/helpers';
import { ZulipService } from '../../services/zulip.service';
import { UNDEFINED_CHANNEL_TITLE } from 'src/app/store/helpers';

type ChatStreamVM = IChatStream & { isUnread: boolean }

@Component({
  selector: 'app-chat-nav',
  templateUrl: './chat-nav.component.html',
  styleUrls: ['./chat-nav.component.scss'],
})
export class ChatNavComponent implements OnInit, AfterViewInit {
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() displayInfoBtn: boolean = true;
  @Output() select = new EventEmitter<string>();
  @Output() create = new EventEmitter<void>();
  @Output() showDetails = new EventEmitter<IStreamId>();
  @Output() openConference = new EventEmitter<IStreamId>();

  trackBy = trackById;
  channels$: Observable<ChatStreamVM[]>;
  conversations$: Observable<ChatStreamVM[]>;

  constructor(
    private elementRef: ElementRef,
    private store: Store,
    private zulipService: ZulipService,
  ) {
    const streams$ = this.store.select(selectChatStreams).pipe(
      map(streams => streams.map((stream): ChatStreamVM =>
        ({ ...stream.data, isUnread: stream.firstUnread != null })
      ))
    )
    this.channels$ = streams$.pipe(
      map(streams =>
        streams
          .filter(stream => stream.meta && stream.meta.type === 'channel' && stream.title !== UNDEFINED_CHANNEL_TITLE)
          .sort(this.sortByLastMessage())
      )
    )
    this.conversations$ = streams$.pipe(
      map(streams =>
        streams
          .filter(stream => stream.meta && stream.meta.type === 'pm')
          .sort(this.sortByLastMessage())
      ),
      withLatestFrom(this.zulipService.currentUser$),
      switchMap(([ streams, currentUser ]) => {
        const streams$ = streams.map(stream => this.store.pipe(
          selectChatStreamMembers(stream.stream_id),
          map(members =>
            ({
              ...stream,
              members: members.filter(user => user.zulip_uid !== currentUser.user_id)
            })
          ),
        ));
        return zip(...streams$);
      })
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.elementRef.nativeElement.classList.add(this.theme);
  }

  trackByStreamId(_, entry: IChatStream) {
    return entry.stream_id;
  }

  sortByLastMessage() {
    return (a: IChatStream, b: IChatStream) => {
      return <number>b.last_message_id - <number>a.last_message_id
    };
  }

  getSortStreamsPredicate() {
    return (a: IChatStream, b: IChatStream) => {
      return a.title === b.title
        ? a.date_created - b.date_created
        : a.title > b.title ? 1 : -1;
    }
  }

  handleOpenConference(event: MouseEvent, streamId: IStreamId) {
    event.stopPropagation();
    this.openConference.emit(streamId);
  }

}
