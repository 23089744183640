import { Injectable } from '@angular/core';
import { IChatMessageAttachment } from '../models/chat';
import { ZulipService } from './zulip.service';
import { DownloadLocallyService } from './download-locally.service';
import { base64ToArrayBuffer } from '../helpers/helpers';

@Injectable({
  providedIn: 'root'
})
export class ChatAttachmentsService {

  constructor(
    private zulipService: ZulipService,
    private downloadLocallyService: DownloadLocallyService,
  ) { }

  downloadFileAttachment(entry: IChatMessageAttachment) {
    return this.zulipService.getFile(entry.link)
      .toPromise()
      .then(res => new Response(res).arrayBuffer())
      .then(res => {
        this.downloadLocallyService.download(entry.name, res);
      })
  }

  downloadImageAttachment(imageDataUrl: string, name: string) {
    this.downloadLocallyService.download(name, base64ToArrayBuffer(imageDataUrl.split('base64,')[1]));
  }

  /** Open base64-encoded image in new browser tab */
  previewImage(imageDataUrl: string, fileName: string) {
    let windowRef = window.open('', '_blank');
    let image = new Image();
    image.src = imageDataUrl;
    image.alt = fileName;
    windowRef.document.write(image.outerHTML);
    windowRef.document.title = fileName;
    image = windowRef = void(0);
  }
}
