import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appRemoveContentSizing]'
})
/**
 * Fixes disabled scroll in ion-content instances.
 * 
 * ion-content adds `content-sizing` class to its host if the component is used
 * within ion-popover. `content-sizing` styles are blocking scroll inside ion-content.
 * 
 * The directive removes `content-sizing` after its view-init.
 * 
 * Source: https://github.com/ionic-team/ionic-framework/blob/753fd2f910e718438e3ac918e2f129501d8e9791/core/src/components/content/content.tsx#L318
 */
export class RemoveContentSizingDirective implements AfterViewInit, OnDestroy {
  private mutationObserver: MutationObserver;

  constructor(public elemRef: ElementRef<HTMLElement>) { }

  ngAfterViewInit() {
    if (this.elemRef.nativeElement.closest('ion-popover') == null) {
      return;
    }

    this.mutationObserver = new MutationObserver(entries => {
      for (let entry of entries) {
        if (!(entry.type === 'attributes' && entry.attributeName === 'class')) {
          continue;
        }

        if ((entry.target as HTMLElement).classList.contains('content-sizing')) {
          this.mutationObserver.disconnect();
          (entry.target as HTMLElement).classList.remove('content-sizing');
        }
      }
    });

    this.mutationObserver.observe(
      this.elemRef.nativeElement,
      { attributes: true, childList: false, characterData: false, subtree: false }
    );
  }

  ngOnDestroy() {
    if (this.mutationObserver instanceof MutationObserver) {
      this.mutationObserver.disconnect();
    }
  }
}
