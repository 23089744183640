import { BaseStoreService } from './base-store.class';
import { ToastService } from '../toast.service';
import { forkJoin } from 'rxjs';
import { UserRole, EventType } from '../../models/common';
import { SubjectService } from '../subject.service';
import { map, filter } from 'rxjs/operators';
import { getBaseStoreEvent } from './helpers';
import { InviteService } from '../invite.service';
import { isSuccess } from '../../helpers/helpers';
import * as i0 from "@angular/core";
import * as i1 from "../toast.service";
import * as i2 from "../invite.service";
import * as i3 from "../subject.service";
export const INIT_STATE = { teach: [], study: [] };
export class SubjectStoreService extends BaseStoreService {
    constructor(toastService, inviteService, subjectService) {
        super(INIT_STATE, toastService, inviteService);
        this.toastService = toastService;
        this.inviteService = inviteService;
        this.subjectService = subjectService;
        this.keyExtractor = (subject) => {
            return subject.role === UserRole.TEACHER
                ? 'teach'
                : 'study';
        };
    }
    getItems$() {
        return forkJoin([
            this.subjectService.getAllTeach$(),
            this.subjectService.getAllStudy$(),
        ])
            .pipe(map(responses => {
            if (responses[0].error) {
                return responses[0];
            }
            if (responses[1].error) {
                return responses[1];
            }
            return { payload: [...responses[0].payload, ...responses[1].payload] };
        }));
    }
    getItem$(id) {
        return this.subjectService.getOne$(id);
    }
    getUpdates$() {
        return this.subjectService.notifier$
            .pipe(filter(event => isSuccess(event.response)), filter(event => [EventType.ADD, EventType.EDIT, EventType.REMOVE].includes(event.eventType)), map(event => ({
            type: getBaseStoreEvent(event.eventType),
            payload: event.response.payload ? event.response.payload : event.entityId
        })));
    }
}
SubjectStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubjectStoreService_Factory() { return new SubjectStoreService(i0.ɵɵinject(i1.ToastService), i0.ɵɵinject(i2.InviteService), i0.ɵɵinject(i3.SubjectService)); }, token: SubjectStoreService, providedIn: "root" });
