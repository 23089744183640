import { TicketParent, EntityType } from '../../models/common';
import { IFileAttach } from '../../models/file-attach';
import { IOpenMaterialAttachmentParams } from '../../services/material-tickets.service';

export const BREAKOUT_ROOM_STORE_KEY = 'estudy_breakout_room';

export enum WhiteboardFrameMessageType {
  UploadPdf = 'upload_pdf',
  UploadImage = 'upload_image',
  UploadDoc = 'upload_doc',
  DownloadFile = 'download_file',
  WhiteboardClose = 'whiteboard_close',
  WhiteboardToken = 'get_jwt_token',
  WhiteboardLoaded = 'whiteboard_loaded',
  WhiteboardLoadingError = 'whiteboard_loading_error',
  BreakoutRoomJoin = 'breakout_room_join',
  BreakoutRoomExit = 'breakout_room_exit',
  BreakoutRoomRequestReturn = 'breakout_room_request_return',
  BreakoutRoomPersistState = 'breakout_room_persist_state',
  BreakoutRoomRestoreState = 'breakout_room_restore_state',
  BreakoutRoomRestoreStateResponse = 'breakout_room_restore_state_response',
  BreakoutLifecycleMessageError = 'breakout_lifecycle_message_error',
  ToggleChat = 'toggle_chat',
  GetChatNotifications = 'get_chat_notifications',
  UpdateChatNotifications = 'update_chat_notifications',
  InitGgbState = 'init_ggb_state',
  UpdateGgbState = 'update_ggb_state',
  HydrateGgbState = 'hydrate_ggb_state',
}

export interface IWhiteboardUploadData<T extends Blob | string> {
  payload: T;
  attachmentId: string;
  assignedTaskId: string;
  shouldDownload: boolean;
  isSameDestination: boolean;
  ticketParent: TicketParent;
  parentTicketIdForMaterialTicket: string;
  saveToChat: boolean;
}

export interface IWhiteboardOpenData {
  attachment: IFileAttach;
  entityId: string;
  entityType: EntityType;
  ticketParent: TicketParent;
  component: any;
  openMaterialAttachmentParams?: IOpenMaterialAttachmentParams;
  saveToChat?: boolean;
}

export interface IWhiteboard {
  id: string
  data: string
}