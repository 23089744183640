import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { IsChatUserOnlinePipe } from "./is-chat-user-online.pipe";

@NgModule({
  imports: [ CommonModule, StoreModule ],
  declarations: [ IsChatUserOnlinePipe ],
  exports: [ IsChatUserOnlinePipe ],
})
export class IsChatUserOnlinePipeModule {}