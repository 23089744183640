import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { StudentTaskState } from '../../models/ticket';
import {
         IAssignedTaskNotification,
         IBaseNotification,
         IMeetingNotification,
         INotificationMessage,
         IStudentTaskNotification,
         ITicketStateNotification,
         NotificationType,
} from '../../models/notifications';
import { TranslateService } from '@ngx-translate/core';
import { TicketRouteService } from '../../services/ticket-route.service';
import { IMeetingFrequency } from '../../models/meeting';
import { Router } from '@angular/router';
import { getFrequencyText, backgroundAsStyle } from '../../helpers/helpers';
import { getMsgTitle } from './helpers/title.helper';
import { getMsgBody } from './helpers/body-msg.helper';
import { getParentTitle, getParentColor } from './helpers/parent-info.helper';
import { isOpenButtonVisible, openNotification } from './helpers/open.helper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessageComponent implements OnInit {
  @Input() message: INotificationMessage<IBaseNotification>;
  @Input() isOpen: boolean;
  @Output() read = new EventEmitter<INotificationMessage<any>>();
  @Output() closeSidebar = new EventEmitter();
  notificationTypes = NotificationType;

  private dateFormat = 'D MMMM YYYY';

  constructor(
    private translateService: TranslateService,
    private ticketRouteService: TicketRouteService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
  }

  get formattedDate(): string {
    return moment(this.message.added_on).format(this.dateFormat);
  }

  get msgTitle(): string {
    return getMsgTitle(this.message, this.translateService);
  }

  get msgBody(): string {
    return getMsgBody(this.message, this.translateService);
  }

  get parentTicketName(): string {
    return getParentTitle(this.message);
  }

  get parentTicketColor(): string {
    return getParentColor(this.message);
  }

  get isAssignedTaskDone(): boolean {
    if (this.message.type === NotificationType.ASSIGNED_TASK_STATE_CHANGE) {
      if ((this.message.payload as IAssignedTaskNotification).state === StudentTaskState.CHECKED_AND_FINISHED) {
        return true;
      }
    }
    return false;
  }

  get isMeetingAttendeeNotification(): boolean {
    return this.message.type === NotificationType.MEETING_ATTENDEE_START_SOON
      || this.message.type === NotificationType.MEETING_ATTENDEE_START_1_HOUR
      || this.message.type === NotificationType.MEETING_ATTENDEE_START_5_MIN
      || this.message.type === NotificationType.MEETING_ATTENDEE_START_10_MIN;
  }

  get mark(): string {
    if (this.message.type === NotificationType.ASSIGNED_TASK_STATE_CHANGE) {
      return (this.message.payload as IAssignedTaskNotification).mark;
    }

    return '';
  }

  get subjectMark(): string {
    if (this.message.type === NotificationType.ASSIGNED_TASK_STATE_CHANGE) {
      return (this.message.payload as IAssignedTaskNotification).my_mark;
    }

    return '';
  }

  get isOpenBtnVisible(): boolean {
    return isOpenButtonVisible(this.message);
  }

  get studentFullName(): string {
    if (this.message.type === NotificationType.STUDENT_COMPLETE_TASK || this.message.type === NotificationType.STUDENT_UPDATE_TASK) {
      return (this.message.payload as IStudentTaskNotification).student_full_name;
    }

    return '';
  }

  get meetingData(): IMeetingNotification | null {
    if (this.isMeetingAttendeeNotification) {
      return this.message.payload as IMeetingNotification;
    }
    return null;
  }

  get meetingFrequencies(): IMeetingFrequency[] {
    if ([
      NotificationType.MEETING_ATTENDEE_START_SOON,
       NotificationType.MEETING_ATTENDEE_START_1_HOUR,
       NotificationType.MEETING_ATTENDEE_START_5_MIN,
       NotificationType.MEETING_ATTENDEE_START_10_MIN,
    ].includes(this.message.type) || !this.meetingData) {
      return [];
    }
    return this.meetingData.meeting_frequency_dates;
  }

  getFrequencyText(meetingFrequency: IMeetingFrequency): string {
    return getFrequencyText(meetingFrequency.frequency, meetingFrequency.step, this.translateService);
  }

  getTranslatedState(): string {
    if (this.message.type === NotificationType.ASSIGNED_TASK_STATE_CHANGE) {
      return this.message.payload
        ? `assigned-task-state.${(this.message.payload as IAssignedTaskNotification).state}`
        : '';
    } else if (this.message.type === NotificationType.TICKET_TASK_STATE_CHANGED) {
      return this.message.payload
        ? `task-state.${(this.message.payload as ITicketStateNotification).state}`
        : '';
    }
    return '';
  }

  open(): void {
    openNotification(this.message, this.ticketRouteService, this.router);
    this.closeSidebar.emit();
  }

  onNotificationClick(): void {
    if (this.isOpen && !this.message.is_read) {
      this.read.emit(this.message);
    }
  }

  backgroundAsStyle(value) {
    return backgroundAsStyle(value, this.sanitizer);
  }
}
