import { of, concat, fromEvent, BehaviorSubject, TimeoutError, throwError, merge, EMPTY } from "rxjs";
import { catchError, concatMap, delay, filter, first, map, mapTo, switchMap, takeWhile, tap, timeout, timeoutWith, withLatestFrom } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Onboarding, OnboardingLinks, ONBOARDING_YOUTUBE_PLAYLIST_ID } from "../models/onboarding";
import { UserService } from "src/app/auth/services/user.service";
import { EntityType, EventType } from "../models/common";
import { EntityAdapterService } from "./entity-adapter.service";
import { InviteService } from "./invite.service";
import { SubjectStoreService } from "./stores/subject-store.service";
import { NavigationEnd, Router } from "@angular/router";
import { UserSettingsService } from "src/app/auth/services/user-settings.service";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../auth/services/user.service";
import * as i3 from "./stores/subject-store.service";
import * as i4 from "./invite.service";
import * as i5 from "./entity-adapter.service";
import * as i6 from "@angular/router";
import * as i7 from "../../auth/services/user-settings.service";
export class OnboardingService {
    constructor(translateService, userService, subjectStoreService, inviteService, entityAdapterService, router, userSettingsService) {
        this.translateService = translateService;
        this.userService = userService;
        this.subjectStoreService = subjectStoreService;
        this.inviteService = inviteService;
        this.entityAdapterService = entityAdapterService;
        this.router = router;
        this.userSettingsService = userSettingsService;
        this.active$ = new BehaviorSubject(null);
        this.startOnboardingGuide$ = new BehaviorSubject(null);
        this.onNavigateToSubjectDashboard$().pipe(takeWhile(() => !this.checkOnboardingComplete(Onboarding.SubjectTasks)))
            .subscribe(() => this.startOnboarding({ type: Onboarding.SubjectTasks }));
    }
    getDisplayHintsSettings() {
        return this.userSettingsService.getSettings().showOnboarding;
    }
    setDisplayHintsSettings(showOnboarding) {
        const current = this.userSettingsService.getSettings();
        this.userSettingsService.updateSettings(Object.assign({}, current, { showOnboarding }));
    }
    checkOnboardingComplete(onboarding) {
        const settings = this.userSettingsService.getSettings();
        return settings.showOnboarding && !settings.onboarding[onboarding];
    }
    getOnboardingLinks(onboardingSection) {
        const lang = this.translateService.currentLang === 'de' ? 'de' : 'en';
        const links = OnboardingLinks[lang][onboardingSection];
        return links.map(link => (Object.assign({}, link, { url: this.getVideoLink(link.videoId) })));
    }
    getVideoLink(videoId) {
        return `https://www.youtube.com/watch?v=${videoId}&list=${ONBOARDING_YOUTUBE_PLAYLIST_ID}`;
    }
    startOnboarding(onboarding) {
        this.startOnboardingGuide$.next(onboarding);
    }
    markOnboardingComplete({ type }) {
        this.userSettingsService.userSettings$()
            .pipe(first(), map(settings => {
            return Object.assign({}, settings, { onboarding: Object.assign({}, settings.onboarding, { [type]: false }) });
        }))
            .subscribe(settings => {
            this.userSettingsService.updateSettings(settings);
            this.active$.next(null);
        });
    }
    showOnboardingGuide$() {
        const onboardingStart$ = this.inviteService.onPendingJoin$.pipe(first(isPending => isPending), mapTo({ isStudentFlow: true }), timeout(2000), catchError(err => {
            if (err instanceof TimeoutError) {
                return of({ isStudentFlow: false });
            }
            return throwError(err);
        }), concatMap(({ isStudentFlow }) => {
            console.log(`OnboardingService.showOnboardingGuide$`, { isStudentFlow });
            if (isStudentFlow) {
                return this.subjectStoreService.getState$().pipe(first(state => state != null && state.study.length === 0), switchMap(() => this.onJoinLesson$()), first(), mapTo({ type: Onboarding.Lessons }));
            }
            return this.subjectStoreService.getState$().pipe(first(state => state != null && state.study.length === 0), switchMap(() => merge(this.setupTeacherOnboardingFlow$(), this.startOnboardingGuide$)));
        }));
        return onboardingStart$.pipe(filter(value => value != null), concatMap((onboarding) => this.active$.pipe(first((activeOnboarding) => activeOnboarding == null), mapTo(onboarding))), concatMap(onboarding => this.userSettingsService.userSettings$().pipe(first(), map((settings) => ({ onboarding, settings })))), filter(({ onboarding, settings }) => (settings.showOnboarding !== undefined && settings.showOnboarding) &&
            (settings.onboarding ? settings.onboarding[onboarding.type] : true)), map(({ onboarding }) => onboarding), tap(({ type }) => this.active$.next(type)));
    }
    setupTeacherOnboardingFlow$() {
        const { onboarding } = this.userSettingsService.getSettings();
        if (onboarding == null) {
            return EMPTY;
        }
        const teacherFlow = [
            {
                type: Onboarding.Subjects,
                guide$: of({ type: Onboarding.Subjects })
            },
            {
                type: Onboarding.FindCreatedSubjects,
                guide$: this.onCreateSubject$().pipe(first(), map((entity) => ({
                    type: Onboarding.FindCreatedSubjects,
                    data: { title: entity.title }
                }))),
            },
            {
                type: Onboarding.SubjectStudents,
                guide$: this.onCreateSubject$().pipe(first(), delay(1000), switchMap(() => this.clickOnSubjectsGroup$().pipe(first())), delay(200), map(() => ({ type: Onboarding.SubjectStudents })))
            },
            {
                type: Onboarding.TaskAttachments,
                guide$: this.onNavigateToTaskPage$().pipe(first(), delay(200), map(() => ({ type: Onboarding.TaskAttachments })))
            },
            {
                type: Onboarding.CommunityChat,
                guide$: of({ type: Onboarding.CommunityChat })
            },
            {
                type: Onboarding.Materials,
                guide$: of({ type: Onboarding.Materials })
            },
            {
                type: Onboarding.Meetings,
                guide$: of({ type: Onboarding.Meetings })
            },
            {
                type: Onboarding.SchoolsAndClasses,
                guide$: of({ type: Onboarding.SchoolsAndClasses })
            },
        ];
        return concat(...teacherFlow
            .filter(({ type }) => onboarding[type])
            .map(({ guide$ }) => guide$));
    }
    onNavigateToSubjectDashboard$() {
        return this.router.events.pipe(filter((event) => event instanceof NavigationEnd), filter(event => event.urlAfterRedirects.startsWith('/pages/subject-dashboard/')));
    }
    onNavigateToTaskPage$() {
        return this.router.events.pipe(filter((event) => event instanceof NavigationEnd), filter(event => event.urlAfterRedirects.startsWith('/pages/ticket/')));
    }
    clickOnSubjectsGroup$() {
        const target = document.querySelector('[data-app-menu-item-type="SUBJECT_GROUP"] [data-overlay-click-target]');
        return fromEvent(target, 'click');
    }
    onJoinLesson$() {
        return this.inviteService.observeStudentJoined$();
    }
    onCreateSubject$() {
        const createdSubject$ = this.subjectStoreService.getState$().pipe(first(state => state != null && state.teach.length > 0), map(state => {
            if (state.teach.length > 1) {
                const sortedSubjects = Array.from(state.teach)
                    .sort((a, b) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime());
                return sortedSubjects[0];
            }
            return state.teach[0];
        }));
        return this.entityAdapterService.onEntityUpdate$.pipe(withLatestFrom(this.userService.getUser$()), filter(([event, user]) => event.eventType === EventType.ADD &&
            event.entityType === EntityType.SUBJECT &&
            (event.response.payload && event.response.payload.owner_id === user.id)), map(([event]) => event.response.payload && event.response.payload), timeoutWith(2000, createdSubject$));
    }
}
OnboardingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnboardingService_Factory() { return new OnboardingService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.SubjectStoreService), i0.ɵɵinject(i4.InviteService), i0.ɵɵinject(i5.EntityAdapterService), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i7.UserSettingsService)); }, token: OnboardingService, providedIn: "root" });
