import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import { IonRouterOutlet } from '@ionic/angular';
import { IActivePage } from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class AppMenuService {
  private collapsed$ = new BehaviorSubject(false);
  public collapsedChanges$ = this.collapsed$.asObservable().pipe(share());
  private markAsSelectedEmitter$ = new Subject<IActivePage>();
  public markAsSelectedPage$ = this.markAsSelectedEmitter$.asObservable().pipe(share());
  private isDisabledEmitter$ = new BehaviorSubject<boolean>(false);
  public isDisabled$ = this.isDisabledEmitter$.asObservable().pipe(share());

  private routerOutlet: IonRouterOutlet;

  constructor(
    private platformService: PlatformService,
  ) {}

  setRouterOutlet(routerOutlet: IonRouterOutlet): void {
    this.routerOutlet = routerOutlet;
  }

  clearRouterOutlet(): void {
    this.routerOutlet = null;
  }

  toggleCollapsed(collapsed: boolean) {
    if (!this.platformService.isMobile) {
      this.collapsed$.next(collapsed);
    }
  }

  isCollapsed() {
    return this.collapsed$.getValue();
  }

  canGoBack(): boolean {
    if (this.routerOutlet) {
      return this.routerOutlet.canGoBack();
    }

    return false;
  }

  prevUrl(): string {
    if (this.routerOutlet) {
      const views: any[] = (this.routerOutlet as any).stackCtrl.views.slice();
      return views.length > 1
        ? views[views.length - 2].url
        : views[0].url;
    }

    return '';
  }

  markAsSelectedPage(activePage: IActivePage): void {
    this.markAsSelectedEmitter$.next(activePage);
  }

  disableMenu(shouldDisable: boolean = true): void {
    this.isDisabledEmitter$.next(shouldDisable);
  }
}
