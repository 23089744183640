/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-attachments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../styled-scrollbar/styled-scrollbar.directive";
import * as i6 from "../../services/window.service";
import * as i7 from "../../services/platform.service";
import * as i8 from "../styled-scrollbar/styled-scrollbar-content.directive";
import * as i9 from "./chat-attachments.component";
import * as i10 from "@ngrx/store";
import * as i11 from "../../services/chat-ui.service";
import * as i12 from "../../services/zulip.service";
import * as i13 from "../../services/chat-attachments.service";
var styles_ChatAttachmentsComponent = [i0.styles];
var RenderType_ChatAttachmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatAttachmentsComponent, data: {} });
export { RenderType_ChatAttachmentsComponent as RenderType_ChatAttachmentsComponent };
function View_ChatAttachmentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-img", [["src", "assets/img/image-ico.svg"]], null, [[null, "ionImgWillLoad"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionImgWillLoad" === en)) {
        var pd_0 = (_co.handleImagePreload($event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.preview($event, _v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_IonImg_0, i2.RenderType_IonImg)), i1.ɵdid(1, 49152, null, 0, i3.IonImg, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "assets/img/image-ico.svg"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChatAttachmentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "/assets/img/file-ic.svg"]], [[1, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0); }); }
function View_ChatAttachmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-item", [["class", "files-list-item"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "preview"], ["slot", "start"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatAttachmentsComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["fileIconTempl", 2]], null, 0, null, View_ChatAttachmentsComponent_3)), (_l()(), i1.ɵeld(6, 0, null, 0, 5, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "link"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "ion-text", [], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(10, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(11, 0, ["", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.isImage; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.senderFullName; _ck(_v, 11, 0, currVal_3); }); }
export function View_ChatAttachmentsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { infiniteScrollRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "ion-content", [["styledScrollbar", "shadow-root"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(3, 4341760, null, 1, i5.StyledScollbarDirective, [i1.ElementRef, i6.WindowService, i7.PlatformService], { styledScrollbar: [0, "styledScrollbar"] }, null), i1.ɵqud(603979776, 2, { styledScrollbarContent: 1 }), (_l()(), i1.ɵeld(5, 0, null, 0, 5, "ion-list", [["styledScrollbarContent", ""]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(6, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(7, 16384, [[2, 4]], 0, i8.StyledScrollbarContentDirective, [i1.ElementRef], { styledScrollbarContent: [0, "styledScrollbarContent"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_ChatAttachmentsComponent_1)), i1.ɵdid(9, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "ion-infinite-scroll", [["threshold", "140px"]], null, [[null, "ionInfinite"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionInfinite" === en)) {
        var pd_0 = (_co.loadMore($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonInfiniteScroll_0, i2.RenderType_IonInfiniteScroll)), i1.ɵdid(12, 49152, [[1, 4]], 0, i3.IonInfiniteScroll, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { threshold: [0, "threshold"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "ion-infinite-scroll-content", [], null, null, null, i2.View_IonInfiniteScrollContent_0, i2.RenderType_IonInfiniteScrollContent)), i1.ɵdid(14, 49152, null, 0, i3.IonInfiniteScrollContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "shadow-root"; _ck(_v, 3, 0, currVal_0); var currVal_1 = ""; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.attachments$)); var currVal_3 = _co.trackByLink; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = "140px"; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_ChatAttachmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-attachments", [], null, null, null, View_ChatAttachmentsComponent_0, RenderType_ChatAttachmentsComponent)), i1.ɵdid(1, 770048, null, 0, i9.ChatAttachmentsComponent, [i10.Store, i11.ChatUiService, i12.ZulipService, i13.ChatAttachmentsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatAttachmentsComponentNgFactory = i1.ɵccf("app-chat-attachments", i9.ChatAttachmentsComponent, View_ChatAttachmentsComponent_Host_0, { selectedChannelId: "selectedChannelId" }, {}, []);
export { ChatAttachmentsComponentNgFactory as ChatAttachmentsComponentNgFactory };
