import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Subscription, of, Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import 'firebase/messaging';
import * as firebase from 'firebase/app';
import { exhaustMap, filter } from 'rxjs/operators';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';
import { PlatformService } from './platform.service';
import { IResponse } from '../models/response';

const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  private pushNotificationToken: string;
  private POST_PUSH_NOTIFICATION_TOKEN = 'v1/push-notifications/save-token';
  private POST_DELETE_TOKEN_FROM_SERVER = 'v1/push-notifications/delete-token';
  private firebaseSub: Subscription;

  constructor(
    private httpService: HttpService,
    private swPush: SwPush,
    private afMessaging: AngularFireMessaging,
    private navCtrl: NavController,
    private platformService: PlatformService,
  ) {
    this.swPush.notificationClicks
      .pipe(
        filter(() => !!this.navCtrl),
        exhaustMap(msg => {
          console.log('%c [NGSW]: CLICKED', 'color: cyan', msg);
          if (msg.notification.data.click_action) {
            return of(this.performClickAction(msg.notification.data.click_action));
          }
          return of();
        }),
      )
      .subscribe();
  }

  initializeFirebaseMessaging(): Promise<void> {
    if (this.isSWEnabled()) {
      return navigator.serviceWorker.ready
        .then(sw => {
          console.log('%c [NGSW]: IS READY', 'color: cyan');
          firebase.messaging().useServiceWorker(sw);
          return Promise.resolve();
        })
        .catch(error => {
          console.log(error);
          return Promise.resolve();
        });
    }
    return Promise.resolve();
  }

  registerToken(): Promise<void> {
    if (this.platformService.isNativeiOS) {
      return this.registerTokeniOS();
    } else if (this.isSWEnabled()) {
      return this.registerTokenPWA();
    }
    return Promise.resolve();
  }

  deleteToken(): Promise<void> {
    if (this.platformService.isNativeiOS) {
      this.removeTokeniOS();
    } else {
      return this.removeTokenPWA();
    }
    return Promise.resolve();
  }

  private registerTokenReq(token: string): Promise<void> {
    return this.httpService.post$(this.POST_PUSH_NOTIFICATION_TOKEN, { token })
      .toPromise()
      .then(response => {
        if (response.error) {
          console.log(response.error);
        }
        return Promise.resolve();
      });
  }

  private isSWEnabled(): boolean {
    return navigator && navigator.serviceWorker && this.swPush.isEnabled && environment.production;
  }

  private registerTokeniOS(): Promise<void> {
    return new Promise(resolve => {
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: PushNotificationToken) => {
          console.log('%c [FCM]: Token', 'color: cyan', token.value);
          this.registerTokenReq(token.value)
            .then(() => resolve());
        }
      );
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on ios push registration: ', JSON.stringify(error));
          resolve();
        }
      );
      PushNotifications.requestPermission()
        .then(result => {
          if (result.granted) {

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
              (notification: PushNotification) => {
                console.log('%c [iOS PUSH]: FOREGROUND RECEIVED', 'color: cyan', JSON.stringify(notification));
              }
            );

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
              (notification: PushNotificationActionPerformed) => {
                console.log('%c [iOS PUSH]: CLICKED', 'color: cyan', JSON.stringify(notification));
                console.log(notification.notification.click_action, 'click_action')
                this.performClickAction(notification.notification.data.aps.category);
              }
            );

            // Register with Apple / Google to receive push via APNS/FCM
            return PushNotifications.register();
          } else {
            console.log(result);
          }
        });
    });
  }

  private registerTokenPWA(): Promise<void> {
    return this.afMessaging.getToken
      .toPromise()
      .then(token => {
        this.pushNotificationToken = token;
        console.log('%c [FCM]: Token', 'color: cyan', token);
        this.firebaseSub = this.afMessaging.messaging
          .subscribe(messaging => {
            console.log('%c [FCM]: AngularFirebaseMessaging', 'color: cyan', messaging);
          });
        return this.registerTokenReq(token);
      })
      .catch(error => {
        console.log(error);
        return Promise.resolve();
      });
  }

  private removeTokeniOS(): void {
    PushNotifications.removeAllListeners();
  }

  private removeTokenPWA(): Promise<void> {
    if (this.firebaseSub) {
      this.firebaseSub.unsubscribe();
      this.firebaseSub = null;
    }
    if (this.pushNotificationToken) {
      return Promise.all([
        this.deleteTokenReq$().toPromise(),
        firebase.messaging().deleteToken(this.pushNotificationToken),
      ])
      .then(() => Promise.resolve())
      .catch(error => {
        console.log(error);
        return Promise.resolve();
      });
    }
  }

  private getPageUrl(url: string): string {
    return url.slice(url.indexOf('pages'), url.length);
  }

  // click_action is a url provided in Firebase notification payload
  private performClickAction(click_action: string): Promise<any> {
    return this.navCtrl.navigateRoot(this.getPageUrl(click_action));
  }

  private deleteTokenReq$(): Observable<IResponse<{ message: string; }>> {
    return this.httpService.post$(this.POST_DELETE_TOKEN_FROM_SERVER, { token: this.pushNotificationToken });
  }
}
