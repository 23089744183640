export interface IFileAttach {
  id?: string;
  name: string;
  encoding?: string;
  mimetype?: string;
  buffer?: Buffer;
  size?: number;
  entity_id?: string;
  entity_type?: string;
  user_id?: string;
  is_deleted?: boolean
  created_on?: string;
  modified_on?: string
  preview_id?: string;
}

export enum FileType {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
}
