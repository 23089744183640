import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { IMeetingFrequency, MeetingFrequency } from '../../models/meeting';
import { ISelectOption } from '../select-input/models';
import { TranslateService } from '@ngx-translate/core';
import { getMeetingFrequencyOps } from '../create-menu/helpers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { TimePickerComponent } from '../time-picker/time-picker.component';
import { detectChanges, setStraightTime } from '../../helpers/helpers';
import { CalendarInputComponent } from '../calendar-input/calendar-input.component';
import { ICalendarDisplay, ICalendarType } from '../calendar-input/models';

import * as moment from 'moment';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { PopoverOptions } from '@ionic/core';

type DateKey = 'start_date' | 'end_date' | 'end_time';

@Component({
  selector: 'app-meeting-frequency-selector',
  templateUrl: './meeting-frequency-selector.component.html',
  styleUrls: ['./meeting-frequency-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingFrequencySelectorComponent implements OnInit, OnDestroy {
  @Input() isDisabled: boolean;
  @Input() disableRemove: boolean = false;
  @Input() meetingFrequency: IMeetingFrequency;
  @Input() isFrequencySelectVisible: boolean;
  @Output() changed = new EventEmitter<IMeetingFrequency>();
  @Output() remove = new EventEmitter<void>();
  @ViewChild('endDateIonItem', { static: false, read: ElementRef }) endDateIonItemEl: ElementRef;

  meetingFrequencyOps: ISelectOption[] = [];
  formGroup: FormGroup;
  isOvernightMode: boolean = false;
  isDefaultEndDate: boolean = false;
  maxValidYear: number = 2029;

  private destroy$ = new Subject();

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder,
    private popoverCtrl: PopoverController,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.listenForFormChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isEndDatePickerVisible(): boolean {
    if (this.formGroup && this.formGroup.value.frequency) {
      const frequency: MeetingFrequency = this.formGroup.value.frequency[0];
      return frequency !== MeetingFrequency.NONE;
    }
    return false;
  }

  get startDate(): string {
    return this.formGroup && this.formGroup.value.start_date
      ? this.formGroup && this.formGroup.value.start_date[0]
      : '';
  }

  get endDate(): string {
    return this.formGroup && this.formGroup.value.end_date
      ? this.formGroup && this.formGroup.value.end_date[0]
      : '';
  }

  get endTime(): string {
    return this.formGroup && this.formGroup.value.end_time
      ? this.formGroup && this.formGroup.value.end_time[0]
      : '';
  }

  get isStepInputVisible(): boolean {
    return this.formGroup && this.formGroup.value.frequency[0] !== MeetingFrequency.NONE;
  }

  openCalendarInput(event: MouseEvent, key: DateKey): void {
    let initialDate = this.formGroup.value[key][0];
    if (key === 'end_date' && this.isDefaultEndDate) {
      initialDate = moment(this.getFormControlValue('start_date')).toISOString();
    }

    this.openPopover(event, {
      component: CalendarInputComponent,
      componentProps: {
        display: ICalendarDisplay.INLINE,
        type: ICalendarType.DATE,
        initialDate: initialDate,
        label: this.getCalendarLabel(key),
        allowSelectPastDay: true,
        onValueChange: (selecteDate: string) => this.changeDate(selecteDate, key),
      },
      translucent: true,
      cssClass: 'create-menu-calendar-input',
      showBackdrop: false,
      event,
    });
  }

  openTimePicker(event: MouseEvent, key: DateKey): void {
    this.openPopover(event, {
      event,
      component: TimePickerComponent,
      showBackdrop: false,
      translucent: true,
      cssClass: 'time-picker-popover',
      mode: 'md',
      componentProps: {
        time: this.formGroup.value[key][0],
        fromDate: key === 'end_time' ? this.formGroup.value.start_date[0] : null,
        showTomorrowTime: key === 'end_time',
        onValueChanged: (newTime: string) => this.changeTime(newTime, key),
      }
    });
  }

  resetOvernightMode() {
    const endTimeDate = moment(this.getFormControlValue('end_time'));
    const startDate = moment(this.getFormControlValue('start_date'));
    const endTimeValue = startDate
      .set({
        h: startDate.hours() + 1,
        m: endTimeDate.minutes(),
      })
      .toISOString();
    
    this.changeTime(endTimeValue, 'end_time');
    this.isOvernightMode = false;
  }

  private initForm(): void {
    this.meetingFrequencyOps = getMeetingFrequencyOps();
    
    const {
      start_date,
      end_date,
      frequency,
      step,
      meeting_duration: duration,
      id,
    } = this.meetingFrequency;
    const endDate = moment(end_date);
    const startDate = moment(start_date);
    const end_time: string = Number.isFinite(duration)
      ? moment(start_date).add(duration, 's').toISOString()
      : endDate.year() >= this.maxValidYear
        ? startDate.set({ h: endDate.hours(), m: endDate.minutes() }).toISOString()
        : endDate.toISOString();
    this.isDefaultEndDate = endDate !== null && endDate.year() > this.maxValidYear;

    this.formGroup = this.fb.group({
      start_date: [ [ start_date ] ],
      end_date:   [ [ end_date ] ],
      end_time:   [ [ end_time ] ],
      frequency:  [ [ frequency ] ],
      step: [ step ],
    });
  }

  private getFormControlValue(key: DateKey) {
    return key in this.formGroup.value ? this.formGroup.value[key][0] : null;
  }

  private setFormControlValue(key: DateKey, value: string | null) {
    if (key in this.formGroup.controls) {
      this.formGroup.controls[key].setValue([value]);
    } else {
      throw new Error(`Undefined control ${key}`)
    }
  }
  
  private getMeetingFrequencyDuration(startDate: string, endDate: string): IMeetingFrequency['meeting_duration'] {
    const startDateM = moment(startDate);
    const endDateM = moment(endDate);
    return Math.abs(endDateM.diff(startDateM, 's'));
  }

  private listenForFormChanges(): void {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$),
      )
      .subscribe(value => {
        const payload: IMeetingFrequency = {
          start_date: value.start_date[0],
          end_date: value.end_date[0],
          frequency: value.frequency[0],
          step: +value.step,
          meeting_duration: undefined,
        }

        if (moment.isDate(new Date(value.end_time[0]))) {
          payload.meeting_duration = this.getMeetingFrequencyDuration(
            payload.start_date,
            value.end_time[0]
          );
        }

        if (this.meetingFrequency.id) {
          const endDateYear = moment(payload.end_date).year();
          if (
            Number.isFinite(payload.meeting_duration) &&
            // HACK: temporarily limiting editable repeat end date to Y<2030
            // because repeat end date is bumped up to 2030 year if wasn't provided
            // at a time of meeting series creation.
            // Also, we need to limit to 2029 because there are cases when end_date auto-bump
            // results in 2029 year dates.
            // https://bitbucket.org/terrainfinity/fe/src/cdfc0d2dd2caa13fff5e1c1370a545d6cf2d8a0c/src/app/shared/components/create-menu/create-menu.component.ts#lines-770
            Number.isFinite(endDateYear) && endDateYear <= this.maxValidYear
          ) {
            const endTime = moment(this.getFormControlValue('end_time'));
            payload.end_date = moment(payload.end_date)
              .set({
                h: endTime.hours(),
                m: endTime.minutes(),
              })
              .toISOString();
          }
        }

        this.changed.emit(payload);
      });
  }

  private maybeGetDateChangeError(date: string, key: DateKey): string | null {
    switch (true) {
      case key === 'start_date' && moment(date).isAfter(this.getFormControlValue('end_date')):
        return this.translateService.instant('datepicker.invalid-start-date');
      case key === 'end_date' && moment(date).isBefore(this.getFormControlValue('start_date')):
        return this.translateService.instant('datepicker.invalid-end-date');
       case key === 'end_date' && moment(date).year() > this.maxValidYear:
        return this.translateService.instant('datepicker.invalid-end-date');
      default:
        return null;
    }
  }

  private changeDate(selecteDate: string, key: DateKey): void {
    if (this.formGroup.value.frequency[0] !== MeetingFrequency.NONE) {
      const errorMessage: string = this.maybeGetDateChangeError(selecteDate, key);
      if (errorMessage) {
        this.toastService.showToast(errorMessage, TOAST_TYPE.ERROR);
        return;
      }
    }
    const selectedMoment = moment(selecteDate);
    let newDate: moment.Moment = null;

    // Updating end_date control with end_time's time if has no value
    if (key === 'end_date' || key === 'end_time') {
      const date = moment(this.getFormControlValue('end_time'));
      const hours: number = date.get('hours');
      const minutes: number = date.get('minutes');
      newDate = selectedMoment.set({ hours, minutes, s: 0, ms: 0 });
      this.isDefaultEndDate = key !== 'end_date';
    } else {
      // Updating previously selected date
      const date = moment(this.getFormControlValue(key));
      const hours: number = date.get('hours');
      const minutes: number = date.get('minutes');
      newDate = selectedMoment.set({ hours, minutes, s: 0, ms: 0 });
    }

    if (key === 'start_date' && !this.isOvernightMode) {

      const endTime = moment(this.getFormControlValue('end_time'));
      const nextEndTime = setStraightTime(
        newDate,
        endTime.hours(),
        endTime.minutes()
      );
      this.setFormControlValue('end_time', nextEndTime.toISOString());

    } else if (
      key === 'start_date' && this.isOvernightMode &&
      newDate.isSameOrAfter(this.getFormControlValue('end_time'))
    ) {

      const endTime = moment(this.getFormControlValue('end_time'));
      const daysDiff: number = endTime.diff(this.getFormControlValue('start_date'), 'd') || 1;
      const nextEndTime: string = endTime.clone().date(newDate.date() + Math.abs(daysDiff)).toISOString();
      this.setFormControlValue('end_time', nextEndTime);
    }

    if (newDate !== this.getFormControlValue(key)) {
      this.setFormControlValue(key, newDate.toISOString());
    }

    detectChanges(this.cdr);
  }

  private changeTime(newDate: string, key: DateKey): void {
    switch (key) {
      case 'start_date': {
        this.setFormControlValue('start_date', newDate)

        if (!this.isOvernightMode) {
          const newDateMoment = moment(newDate);
          const startDateHours: number = newDateMoment.get('hours');
          const endTime = setStraightTime(newDateMoment, startDateHours + 1)
          this.setFormControlValue('end_time', endTime.toISOString())
        }
        break;
      }
      case 'end_date': {
        this.setFormControlValue(key, newDate)
        break;
      }
      case 'end_time': {
        this.setFormControlValue(key, newDate)
        break;
      }
    }

    const isOvernightMode = this.checkForOvernightMode(
      key,
      key === 'start_date' ? 'end_time' : 'start_date'
    );

    if (isOvernightMode) {
      this.isOvernightMode = isOvernightMode;
      const newDateMoment = moment(newDate)
      const baseDate = key === 'end_time' ? this.getFormControlValue('start_date') : newDate;
      const shiftedDate = (
        setStraightTime(
          moment(baseDate).add(1, 'd'),
          newDateMoment.hours(),
          newDateMoment.minutes()
        ) 
        .toISOString()
      );

      this.setFormControlValue(key, shiftedDate);
    }

    detectChanges(this.cdr);
  }

  private checkForOvernightMode(startDateKey: DateKey, endDateKey: DateKey) {
    const start = moment(this.getFormControlValue(startDateKey)).set({ ms: 0, s: 0 });
    const end = moment(this.getFormControlValue(endDateKey)).set({ ms: 0, s: 0 });
    return startDateKey === 'start_date'
      ? start.isAfter(end)
      : end.isAfter(start)
  }

  private openPopover(event: MouseEvent, options: PopoverOptions): void {
    event.preventDefault();
    event.stopPropagation();

    (async () => {
      const popover = await this.popoverCtrl.create({
        ...options,
        componentProps: {
          ...options.componentProps,
          onDismiss: () => popover.dismiss(),
        },
      });
      popover.present();
    })();
  }

  private getCalendarLabel(key: DateKey): string {
    return key === 'start_date'
      ? this.translateService.instant('meeting.select-start-date')
      : this.translateService.instant('meeting.select-end-date');
  }

}
