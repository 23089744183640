import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {concatMap, filter, map, share, shareReplay, tap} from 'rxjs/operators';
import {IResponse} from '../models/response';
import {IInviteModel} from '../components/invite-modal/models';
import {
  EntityType,
  IEntityInvitationMessage,
  IUserAddInvitationMessage,
  SocketMessageType,
  UserTypes
} from '../models/common';
import {UserModel} from '../models/user/user';
import {SocketService} from './socket.service';
import {AuthService} from 'src/app/auth/services/auth.service';

export interface IInvitationMessage {
  type: SocketMessageType.USER_ADD_INVITATION | SocketMessageType.ENTITY_INVITATION;
  payload: IUserAddInvitationMessage | IEntityInvitationMessage;
}

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  public notifier$: Observable<IInvitationMessage>;

  private notifyEmitter$: Subject<IInvitationMessage> = new Subject();

  private readonly POST_JOIN_ENTITY = 'v1/join';
  private readonly GET_ALL_ACTIVE = (id: string, type: string) => `v1/invite/status?entityId=${id}&entityType=${type}`;
  private readonly GET_ALL_USERS = (search: string, userType: string, filterEntities: { entityId: string; entityType: string; }[], page: number, limit: number, shouldAddInvitedUser: boolean = false, shouldAddSchoolOwner: boolean = true, entityWithUserType?: UserTypes, shouldHideDeactivated?: boolean) => {
    let url = `v1/invite/users?name=${search}&userType=${userType}&filters=${JSON.stringify(filterEntities)}&shouldAddInvitedUser=${shouldAddInvitedUser}&shouldAddSchoolOwner=${shouldAddSchoolOwner}&entityWithUserType=${entityWithUserType}&shouldHideDeactivated=${shouldHideDeactivated}`;

    if (Number.isFinite(page)) {
      url += `&page=${page}`;
    }

    if (Number.isFinite(limit)) {
      url += `&limit=${limit}`
    }

    return url
  };
  private readonly POST_INVITE_ALL = (search: string, userType: string, entityId: string, entityType: string, filterEntities: { entityId: string; entityType: string; }[]) => `v1/invite/all?search=${search}&userType=${userType}&inviteEntityId=${entityId}&inviteEntityType=${entityType}&filters=${JSON.stringify(filterEntities)}`;
  private readonly POST_GENERATE = (id: string, type: string) => `v1/invite/generate?entityId=${id}&entityType=${type}`;
  private readonly POST_DEACTIVATE = (id: string, type: string) => `v1/invite/deactivate?entityId=${id}&entityType=${type}`;
  private readonly POST_INVITE_STUDENT = (id: string, type: string) => `v1/invite/student?entityId=${id}&entityType=${type}`;
  private readonly DELETE_INVITE = (id: string, type: string, userId: string) => `v1/invite/delete?entityId=${id}&entityType=${type}&userId=${userId}`;

  private pendingJoin$ = new BehaviorSubject(false)

  public onPendingJoin$ = this.pendingJoin$.asObservable().pipe(
    shareReplay(1)
  )

  constructor(
  private httpService: HttpService,
  private socketService: SocketService,
  private authService: AuthService
  ) {
    this.notifier$ = this.notifyEmitter$.pipe(share());
    this.socketService.messages$()
      .pipe(
        filter(() => this.authService.isAuthenticated),
        filter(message => [SocketMessageType.ENTITY_INVITATION, SocketMessageType.USER_ADD_INVITATION].includes(message.type)),
        map(message => ({ type: message.type, payload: message.payload } as IInvitationMessage))
      )
      .subscribe(invitationMessage => this.notifyEmitter$.next(invitationMessage));
  }

  joinByCode$(code: string): Observable<IResponse<any>> {
    return this.httpService.post$(this.POST_JOIN_ENTITY, { inviteCode: code })
      .pipe(tap(response => {
        const payload = response.payload
          ? { entity_id: response.payload.entityId, entity_type: response.payload.entityType } as IUserAddInvitationMessage
          : null;
        this.notify(response, { type: SocketMessageType.USER_ADD_INVITATION, payload });
      }));
  }

  getActive$(entityId: string, type: EntityType): Observable<IResponse<IInviteModel[]>> {
    return this.httpService.get$(this.GET_ALL_ACTIVE(entityId, type));
  }

  generate$(entityId: string, type: EntityType, invite: IInviteModel): Observable<IResponse<IInviteModel>> {
    return this.httpService.post$(this.POST_GENERATE(entityId, type), {
        valid_hours: +invite.valid_hours,
        vacant_places: +invite.vacant_places,
        type: invite.type[0]
      } as IInviteModel);
  }

  deactivate$(entityId: string, type: EntityType, invite: IInviteModel): Observable<IResponse<IInviteModel>> {
    return this.httpService.post$(this.POST_DEACTIVATE(entityId, type), {
        type: invite.type[0],
      });
  }

  removeInvitation$(entityId: string, type: EntityType, userId: string): Observable<IResponse<any>> {
    return this.httpService.delete$(this.DELETE_INVITE(entityId, type, userId), {});
  }

  removeInvitations$(entityId: string, type: EntityType, userIds: string[]): Observable<IResponse<any>[]> {
    return forkJoin(userIds.map(userId => this.removeInvitation$(entityId, type, userId)));
  }

  createInvitations$(entityId: string, entityType: EntityType, usersIds: string[]): Observable<IResponse<any>> {
    return this.httpService.post$(this.POST_INVITE_STUDENT(entityId, entityType), { users: usersIds });
  }

  getUsers$(
    search: string,
    userType: UserTypes,
    filterEntities: { entityId: string; entityType: string; }[],
    page: number,
    limit: number,
    shouldAddInvitedUser: boolean = false,
    shouldAddSchoolOwner: boolean = true,
    entityWithUserType: UserTypes = UserTypes.ALL,
    shouldHideDeactivated: boolean = false
  ): Observable<IResponse<UserModel[]>> {
    return this.httpService.get$(this.GET_ALL_USERS(search, userType, filterEntities, page, limit, shouldAddInvitedUser, shouldAddSchoolOwner, entityWithUserType, shouldHideDeactivated));
  }

  inviteAll$(
    search: string,
    userType: 'teacher' | 'student' | 'all',
    inviteEntityId: string,
    inviteEntityType: EntityType,
    filterEntities: { entityId: string; entityType: string; }[],
  ): Observable<IResponse<UserModel[]>> {
    return this.httpService.post$(this.POST_INVITE_ALL(search, userType, inviteEntityId, inviteEntityType, filterEntities), {});
  }

  observeStudentJoined$() {
    const pendingJoin$ = this.pendingJoin$.asObservable()
    const joined$ = this.notifier$.pipe(
      filter(event => event.type === 'USER_ADD_INVITATION'),
    )
    return pendingJoin$.pipe(
      concatMap(() => joined$),
      tap(() => this.pendingJoin$.next(false))
    )
  }

  startPendingJoin() {
    this.pendingJoin$.next(true);
  }

  private notify(response: IResponse<any>, invitationMessage: IInvitationMessage) {
    if (!response.error && invitationMessage) {
      this.notifyEmitter$.next(invitationMessage);
    }
  }
}
