<ion-card>
  <ion-card-header>
    <ion-item class="close" lines="none">
      <ion-button fill="clear" slot="end" mode="md" class="btn-xs" (click)="close()">
        <ion-label>{{ 'common.btn-close' | translate }}</ion-label>
      </ion-button>
    </ion-item>
  </ion-card-header>
  <ion-card-content [formGroup]="formGroup">
    <ion-item class="input" [disabled]="isSubmitted">
      <ion-textarea formControlName="message" placeholder="{{ 'feedback.message-field' | translate }}" rows="3" autoGrow></ion-textarea>
    </ion-item>
    <span class="error-message" *ngIf="hasError(formGroup.controls.message)">{{ 'feedback.field-required' | translate }}</span>
    <ion-item lines="none">
        <ion-button class="primary submit font-extra-bold ion-margin-vertical" expand="block" mode="md" type="submit" [ngClass]="{'btn-submitted': isSubmitted}" [disabled]="isSubmitted || !formGroup.valid" (click)="submit$.next()">
          {{ 'feedback.submit-btn' | translate }}
          <ion-spinner [hidden]="!isSubmitted" class="btn-spinner" color="light" name="dots"></ion-spinner>
        </ion-button>
    </ion-item>
  </ion-card-content>
</ion-card>
