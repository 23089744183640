<ion-list class="onboarding-guides__list">
  <div *ngFor="let entry of guides; trackBy: trackBy" class="onboarding-guides__entry" [id]="entry.id">
    <ion-item lines="none" class="ion-no-padding onboarding-guides__entry-item">
      <ion-icon src="/assets/img/help-icon-outline.svg" width="26" height="26" slot="start"></ion-icon>
      <ion-label class="onboarding-guides__entry-title">{{ entry.title | translate }}</ion-label>
      <ion-button
        (click)="expand(entry.id)"
        class="onboarding-guides__entry-toggle"
        [class.onboarding-guides__entry-toggle--expanded]="entry.id === (expanded$ | async)"
        slot="end" fill="clear">
        <img src="/assets/img/chevron-down-ic.svg" width="12" height="7" slot="icon-only" alt="Toggle Collapse" />
      </ion-button>
    </ion-item>
    <ion-list
      lines="none"
      class="ion-no-padding onboarding-guides__entry-links"
      [class.onboarding-guides__entry-links--expanded]="entry.id === (expanded$ | async)">
      <ion-item class="onboarding-guides__entry-desc">{{ entry.desc | translate }}</ion-item>
      <ion-item 
        *ngFor="let link of entry.links"
        [href]="link.url"
        detail="false"
        target="_blank" 
        class="onboarding-guides__entry-link">
        <img
          src="/assets/img/play-icon.svg"
          width="17"
          height="17"
          alt="Play"
          slot="start"
          class="icon-prefix" />
        <span class="tutorial-link">{{ link.title }} ({{ link.dur }})</span>
      </ion-item>
    </ion-list>
  </div>
  <div class="onboarding-guides__entry">
    <ion-item lines="none" class="ion-no-padding onboarding-guides__entry-item">
      <ion-icon src="/assets/img/help-icon-outline.svg" width="26" height="26" slot="start"></ion-icon>
      <ion-label class="onboarding-guides__entry-title">
        <a  
          href="https://www.youtube.com/playlist?list=PL0hrHvw81XGZhVpz9jYlZb3kKBHWFIPt8"
          target="_blank"
          class="onboarding-guides__entry-link">
          {{ 'onboarding.all_tutorials' | translate }}
        </a>
      </ion-label>
    </ion-item>
  </div>
</ion-list>
<app-checkbox [formControl]="hideHintsControl">
  {{ 'onboarding.dont_show' | translate }}
</app-checkbox>