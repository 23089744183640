import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, timer } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
export class AppUpdateService {
    constructor(swUpdate) {
        this.swUpdate = swUpdate;
        this.showUpdateMessageEmitter$ = new BehaviorSubject(false);
    }
    showUpdateMessage$() {
        return this.showUpdateMessageEmitter$
            .asObservable()
            .pipe(distinctUntilChanged());
    }
    listenSWUpdates() {
        if (this.swUpdate.isEnabled) {
            console.log('Service Worker is enabled!');
            const everyTwoMinutes$ = timer(4 * 60 * 1000, 2 * 60 * 1000);
            everyTwoMinutes$
                .subscribe(() => this.swUpdate.checkForUpdate());
            this.swUpdate.available
                .subscribe(() => {
                this.showUpdateMessageEmitter$.next(true);
            });
        }
        else {
            console.warn('Service Worker is disabled!');
        }
    }
}
AppUpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppUpdateService_Factory() { return new AppUpdateService(i0.ɵɵinject(i1.SwUpdate)); }, token: AppUpdateService, providedIn: "root" });
