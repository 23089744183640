<div class="content" [formGroup]="formGroup">
  <ion-item button (click)="openCalendarInput($event, 'start_date')" [disabled]="isDisabled" lines="none" [detail]="false" class="calendar-picker-item">
    <ion-icon slot="start" src="assets/img/calendar-ic.svg"></ion-icon>
    <ion-label>
      <span>{{ startDate | dateLocale: 'd MMMM' }}</span>
      <span>
        {{ startDate | dateLocale: '(EEEE)' }}
        <ion-text *ngIf="isOvernightMode">
          &nbsp;&ndash;&nbsp;{{ 'create-menu.meeting-start-date' | translate }}
        </ion-text>
      </span>
    </ion-label>
    <ion-button [disabled]="isDisabled || disableRemove" (click)="remove.emit()" slot="end" mode="md" class="circle-chip-btn remove">
      <ion-icon class="ico icon-remove"></ion-icon>
    </ion-button>
  </ion-item>
  <div class="time-picker-content" [class.time-picker-shrinked]="isOvernightMode">
    <ion-item button class="input" [detail]="false" [disabled]="isDisabled" (click)="openTimePicker($event, 'start_date')">
      <ion-input value="{{ startDate | dateLocale: 'HH:mm' }}" readonly></ion-input>
    </ion-item>
    <ng-container *ngIf="!isOvernightMode">
      <div>-</div>
      <ion-item button class="input" [detail]="false" [disabled]="isDisabled" (click)="openTimePicker($event, 'end_time')">
        <ion-input value="{{ endTime | dateLocale: 'HH:mm' }}" readonly></ion-input>
      </ion-item>
    </ng-container>
  </div>
  <ng-container *ngIf="isOvernightMode">
    <ion-item
      [disabled]="isDisabled"
      [detail]="false"
      (click)="openTimePicker($event, 'end_time')"
      lines="none"
      class="calendar-picker-item overnight-meeting-date-item">

      <ion-icon slot="start" src="assets/img/calendar-ic.svg"></ion-icon>
      <ion-label>
        <span>{{ endTime | dateLocale: 'd MMMM' }}</span>
        <span>
          {{ endTime | dateLocale: '(EEEE)' }}
          <ion-text *ngIf="isOvernightMode">
            &nbsp;&ndash;&nbsp;{{ 'create-menu.meeting-end-date' | translate }}
          </ion-text>
        </span>
      </ion-label>

      <ion-button [disabled]="isDisabled" (click)="$event.stopPropagation();resetOvernightMode()" slot="end" mode="md" class="reset-overnight-mode-btn" fill="clear">
        <ion-icon src="assets/img/refresh.svg" width="9" height="9" alt="Reset" class="ico"></ion-icon>
      </ion-button>

    </ion-item>

    <div class="time-picker-content" [class.time-picker-shrinked]="isOvernightMode">
      <ion-item button class="input" [detail]="false" [disabled]="isDisabled" (click)="openTimePicker($event, 'end_time')">
        <ion-input value="{{ endTime | dateLocale: 'HH:mm' }}" readonly></ion-input>
      </ion-item>
    </div>
  </ng-container>
  <div class="frequency-content">
    <app-select-input [hidden]="!isFrequencySelectVisible" formControlName="frequency" [options]="meetingFrequencyOps" [disable]="isDisabled" class="select"></app-select-input>
    <ion-item *ngIf="isStepInputVisible" class="input" [disabled]="isDisabled">
      <ion-input formControlName="step"></ion-input>
    </ion-item>
  </div>
  <ion-item #endDateIonItem [hidden]="!isEndDatePickerVisible" button (click)="openCalendarInput($event, 'end_date')" [disabled]="isDisabled" lines="none" [detail]="false" class="calendar-picker-item end-repeat">
    <ion-icon slot="start" src="assets/img/calendar-ic.svg"></ion-icon>
    <ion-label class="calendar-picker-date-label">
      <span [hidden]="endDate == null || isDefaultEndDate">{{ endDate | dateLocale: 'd MMMM' }}</span>
      <span>({{ 'meeting.end-repeat' | translate }})</span>
    </ion-label>
  </ion-item>
</div>
