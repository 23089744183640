import { Component, OnInit, ViewChild, ElementRef, forwardRef, Input, ChangeDetectorRef, EventEmitter, Output, ContentChild, AfterContentInit, OnDestroy } from '@angular/core';
import { ICalendarDate, ICalendarDisplay, ICalendarType, ICalendarDatePeriod, ICalendarDatePeriodValue } from './models';
import * as moment from 'moment';
import * as range from 'lodash.range';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { of, Subject, Observable, fromEvent } from 'rxjs';
import { delay, filter, tap, takeUntil, map, first } from 'rxjs/operators';
import { detectChanges } from '../../helpers/helpers';
import { CalendarCellDirective } from './calendar-cell.directive';
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { TimePickerComponent } from '../time-picker/time-picker.component';
import { ConfirmationService } from '../../services/confirmation.service';
import { ConfirmAction } from '../../models/common';

const cloneDeep = require('lodash.clonedeep');

@Component({
  selector: 'app-calendar-input',
  templateUrl: './calendar-input.component.html',
  styleUrls: ['./calendar-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CalendarInputComponent), multi: true }],
})
export class CalendarInputComponent implements OnInit, OnDestroy, AfterContentInit, ControlValueAccessor {
  @Input() readonly: boolean = false;
  @Input() isDetached: boolean = true;
  @Input() dateFormat: string = 'D MMMM YYYY';
  @Input() type: ICalendarType = ICalendarType.DATE;
  @Input() display: ICalendarDisplay = ICalendarDisplay.BUTTON_WITH_TEXT;
  @Input() placeholder: string = 'Set due date';
  @Input() onValueChange: Function;
  @Input() onDismiss: Function;
  @Input() onSelectedPeriodChange: Function;
  @Input() listenClear: Observable<void>;
  @Input() initialDate: string = null;
  @Input() label: string;
  @Input() allowSelectPastDay: boolean = false;
  @Output() valueChanged: EventEmitter<void> = new EventEmitter();
  @Output() clearPeriod: EventEmitter<void> = new EventEmitter();
  @Output() weeksUpdated = new EventEmitter<ICalendarDate[][]>();

  @ContentChild(CalendarCellDirective, { static: false })
  public calendarCellDirectiveRef: CalendarCellDirective;

  public top: number = 0;
  public left: number = 0;
  public currentDate: moment.Moment;
  public namesOfDays = moment.weekdaysShort();
  public fullNamesOfDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public weeks: Array<ICalendarDate[]> = [];
  public selectedDate;
  public selectedPeriod: ICalendarDatePeriod = {
    startDate: null,
    endDate: null,
    selected: false
  };
  public startDate;
  public endDate;
  public timeControl: FormControl;
  public isSubmitted: boolean = false;
  public submit$: Subject<void> = new Subject();
  public calendarCellTemplate: any = null;

  public selectTimePeriod: boolean = false;
  public isOpen: boolean = false;
  public showErrorNotEquals: boolean = false;

  private destroy$: Subject<void> = new Subject();
  private isOpened = false;

  @ViewChild('calendar', {static: true}) calendar;

  constructor(
    public cdf: ChangeDetectorRef,
    private eRef: ElementRef,
    private translateService: TranslateService,
    private popoverCtrl: PopoverController,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit() {
    if (this.isDetached) {
      this.cdf.detach();
    }

    if (moment(this.initialDate).isValid()) {
      this.currentDate = moment(this.initialDate);
      this.selectedDate = this.currentDate.toLocaleString();
    } else {
      this.currentDate = moment();
    }

    this.generateCalendar();

    if (this.display === ICalendarDisplay.INLINE) {
      this.toggleOpen();
    }

    detectChanges(this.cdf);

    this.submit$
      .pipe(
          filter(() => this.isSelectedTimeValid()),
          tap(() => this.isSubmitted = true),
          map(() => this.timeControl.value),
          takeUntil(this.destroy$),
      )
      .subscribe(value => {
          this.isSubmitted = false;
          this.showErrorNotEquals = false;
          if (value) {
            this.valueChanged.emit();
            this.selectTimePeriod = false;
            this.isOpen = false;
            this.onChange(value);

            if (this.onValueChange instanceof Function) {
              this.onValueChange(value);
            }

            if (this.onDismiss instanceof Function) {
              this.onDismiss();
            }
          } else {
            this.showErrorNotEquals = true;
          }
          detectChanges(this.cdf);
      });

    if (this.listenClear != null && this.listenClear.subscribe instanceof Function) {
      this.listenClear
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.clear());
    }

    const documentClick$ = fromEvent(document, 'click')
      .pipe(
        filter(() => this.isOpened),
        tap(event => {
          if (!this.eRef.nativeElement.contains(event.target)
            && this.display !== ICalendarDisplay.INLINE
            && !this.selectTimePeriod) {
            this.isOpen = false;
            this.selectTimePeriod = false;
            this.showErrorNotEquals = false;
            this.setOpenedState();
            if (this.onDismiss instanceof Function) {
              this.onDismiss();
            }
            detectChanges(this.cdf);
          }
        })
      );
    documentClick$
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngAfterContentInit() {
    if (this.calendarCellDirectiveRef != null) {
      this.calendarCellTemplate = this.calendarCellDirectiveRef.templateRef;
      detectChanges(this.cdf);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getSelectedDate(): string {
    if (this.type === ICalendarType.DATE_AND_TIME) {
      return moment(this.selectedDate).format('D MMM YYYY HH:mm');
    }
    return moment(this.selectedDate).format(this.dateFormat);
  }

  public isDatePeriod(): boolean {
    return this.type === ICalendarType.DATE_PERIOD;
  }

  public isSelectDatePlaceholderVisible(): boolean {
    return this.isDatePeriod();
  }

  public toggleOpen() {
    this.isOpen = !this.isOpen;
    this.setOpenedState();
    detectChanges(this.cdf);
  }

  public isSelectedMonth(date: moment.Moment): boolean {
    if (this.isPastDay(date)) {
      return true;
    }
    return moment(date).isSame(this.currentDate, 'month');
  }

  public isPastDay(date: moment.Moment): boolean {
    if (this.allowSelectPastDay || this.isDatePeriod() || this.isToday(date)) {
      return false;
    }
    return date.isBefore(moment());
  }

  public selectDate(event, date: ICalendarDate) {
    if (this.isPastDay(date.mDate)) {
      this.confirmationService.confirmAction(ConfirmAction.UNABLE_CHOOSE_PAST_DAY);
      return;
    }
    event.stopPropagation();
    this.onTouched();
    this.generateCalendar();
    of(true)
      .pipe(delay(100), first())
      .subscribe(() => {
        this.writeValue(date.mDate.toISOString());
        if (this.type === ICalendarType.DATE) {
          if (!this.isInline) {
            this.isOpen = false;
          }

          if (this.onDismiss instanceof Function) {
            this.onDismiss();
          }

          this.valueChanged.emit();
        }
        detectChanges(this.cdf);
      });
  }

  public prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();
    detectChanges(this.cdf);
  }

  public nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.generateCalendar();
    detectChanges(this.cdf);
  }

  public getCurrentMonth(): string {
    return moment(this.currentDate).format('MMMM YYYY');
  }

  public getWeekDay(): string {
    return this.fullNamesOfDays[moment(this.currentDate).weekday()];
  }

  public isSelectedWeek(week: ICalendarDate[]): boolean {
    return week.findIndex(w => w.selected) !== -1 ? true : false;
  }

  public isValidDate(date: moment.Moment): boolean {
    return date ? moment(date).isValid() : false;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: string) {
    switch (this.type) {
      case ICalendarType.DATE_PERIOD:
        this.writeValueDatePeriod(value);
        break;
      case ICalendarType.DATE_AND_TIME:
        this.writeValueDateTime(value);
        break;
      default:
        this.writeValueDate(value);
        break;
    }

  }

  public cancel() {
    of(true).pipe(delay(100)).toPromise().then(() => {
      this.selectTimePeriod = false;
      this.resetPeriod();
      this.isOpen = false;
      this.selectedDate = null;
      this.showErrorNotEquals = false;

      if (this.onDismiss instanceof Function) {
        this.onDismiss();
      }

      detectChanges(this.cdf);
    });
  }

  public back() {
    of(true).pipe(delay(100)).toPromise().then(() => {
      this.selectTimePeriod = false;
      detectChanges(this.cdf);
    });

  }

  public detectChanges() {
    detectChanges(this.cdf);
  }

  public getFormattedSelectedDate(date: moment.Moment) {
    return moment(date).format(this.dateFormat);
  }

  public clear() {
    this.resetPeriod();
    this.generateCalendar();
    this.clearPeriod.emit();
    this.onChange();

    if (this.onValueChange instanceof Function) {
      this.onValueChange({});
    }
  }

  public get isInline() {
    return this.display === ICalendarDisplay.INLINE;
  }

  public getEndTimeLabel(): string {
    if (this.type === ICalendarType.DATE_AND_TIME) {
      return this.translateService.instant('datepicker.time');
    }
    return this.translateService.instant('datepicker.end-time');
  }

  public getDayRangeForFrequencyTimeRange(): string {
    return this.getFormattedSelectedDate(moment(this.selectedDate));
  }

  public onTimeInputBlur(): void {
    this.detectChanges();
    this.showErrorNotEquals = false;
  }

  public openTimePicker(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    (async () => {
      const popover = await this.popoverCtrl.create({
        event,
        component: TimePickerComponent,
        showBackdrop: false,
        translucent: true,
        cssClass: 'time-picker-popover',
        mode: 'md',
        componentProps: {
          time: this.timeControl.value,
          minStep: 5,
          onValueChanged: (newTime: string) => {
            this.timeControl.setValue(newTime);
            this.onTimeInputBlur();
          },
          onDismiss: () => popover.dismiss(),
        },
      });
      popover.present();
    })();
  }

  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
    this.weeksUpdated.emit(cloneDeep(this.weeks));
  }

  private fillDates(currentMoment: moment.Moment) {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const lastOfMonth = moment(currentMoment).endOf('month').day();

    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
    const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'days').add(7, 'days');

    const startCalendar = firstDayOfGrid.date();

    return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')).map((date) => {
      const newDate = moment(firstDayOfGrid).date(date);

      return {
        today: this.isToday(newDate),
        selected: this.isSelected(newDate),
        period: this.isPeriod(newDate),
        mDate: newDate,
      };
    });
  }

  private isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  private isPeriod(date: moment.Moment): boolean {
    if (!this.isDatePeriod() || !this.selectedPeriod.selected) { return false; }

    return this.selectedPeriod.startDate.diff(date) < 0 && this.selectedPeriod.endDate.diff(date) > 0;
  }

  private isSelected(date: moment.Moment): boolean {
    if (this.isDatePeriod()) {
      return (
        date.isSame(this.selectedPeriod.startDate, 'd') ||
        date.isSame(this.selectedPeriod.endDate, 'd')
      );
    }

    if (!this.selectedDate) {
      return false;
    }

    return moment(this.selectedDate).isSame(date, 'd');
  }

  private writeValueDateTime(value: string): void {
    if (!value || typeof value !== 'string') {
      this.selectedDate = null;
      return;
    }

    if (moment(value).isValid()) {
        this.currentDate = moment(value);
        this.selectedDate = moment(value).toLocaleString();
        this.startDate = moment(value).format('YYYY-MM-DD');
        this.endDate = moment(value).format('YYYY-MM-DD');
        const initialTime = this.isToday(this.selectedDate)
          ? this.currentDate.clone().set('h', moment().hours() + 1).toISOString()
          : this.currentDate.clone().set('h', 10).toISOString();
        this.timeControl = new FormControl(initialTime);
        this.selectTimePeriod = true;
    } else {
      this.selectedDate = null;
    }
    this.generateCalendar();
    detectChanges(this.cdf);
  }

  private writeValueDate(value: string): void {
    if (!value || typeof value !== 'string') {
      this.selectedDate = null;
      return;
    }

    if (moment(value).isValid()) {
        this.selectedDate = moment(value).toLocaleString();
        this.onChange(value);

        if (this.onValueChange instanceof Function) {
          this.onValueChange(value);
        }
    } else {
      this.selectedDate = null;
    }
    this.generateCalendar();
    detectChanges(this.cdf);
  }

  private writeValueDatePeriod(value: string| any): void {
    if (value && value.startDate && value.endDate) {
      this.selectedPeriod.startDate = moment(value.startDate).isValid() ? moment(value.startDate) : null;
      this.selectedPeriod.endDate = moment(value.endDate).isValid() ? moment(value.endDate) : null;
      this.onChangePeriod();
      return;
    }
    if (!value || typeof value !== 'string') {
      this.resetPeriod();
      return;
    }

    if (moment(value).isValid()) {
      if (!this.selectedPeriod.startDate) {
        this.selectedPeriod.startDate = moment(value);
      } else if (this.selectedPeriod.startDate && !this.selectedPeriod.endDate) {
        if (this.selectedPeriod.startDate.diff(moment(value)) > 1) {
          this.selectedPeriod.endDate = this.selectedPeriod.startDate;
          this.selectedPeriod.startDate = moment(value);
          this.onChangePeriod();
        } else {
          this.selectedPeriod.endDate = moment(value);
          this.onChangePeriod();
        }
      } else {
        this.selectedPeriod = {
          startDate: null,
          endDate: null,
          selected: false
        };
      }
    } else {
      this.resetPeriod();
    }
    this.generateCalendar();
    detectChanges(this.cdf);
  }

  private onChangePeriod() {
    const value: ICalendarDatePeriodValue = {
      startDate: this.selectedPeriod.startDate.hours(0).toISOString(),
      endDate: this.selectedPeriod.endDate.hours(23).minutes(59).toISOString()
    };
    this.valueChanged.emit();
    this.selectedPeriod.selected = true;
    this.isOpen = false;

    if (this.onValueChange instanceof Function) {
      this.onValueChange(value);
    }

    if (this.onSelectedPeriodChange instanceof Function) {
      this.onSelectedPeriodChange(this.selectedPeriod);
    }

    if (this.onDismiss instanceof Function) {
      this.onDismiss();
    }

    this.onChange(value);
    this.generateCalendar();
  }

  private resetPeriod() {
    this.selectedPeriod = {
        startDate: null,
        endDate: null,
        selected: false
    }
    if (this.onSelectedPeriodChange instanceof Function) {
      this.onSelectedPeriodChange(this.selectedPeriod);
    }
  }

  private setOpenedState(): void {
    of(true)
      .pipe(
        delay(300),
        first(),
      )
      .subscribe(() => this.isOpened = this.isOpen);
  }

  private isSelectedTimeValid(): boolean {
    if (!this.timeControl.valid || new Date(this.timeControl.value).getTime() <= new Date(moment().toISOString()).getTime()) {
      this.showErrorNotEquals = true;
      this.detectChanges();
      return false;
    }

    return true;
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};
}
