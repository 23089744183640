import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { StyledScollbarDirective } from './styled-scrollbar.directive';
import { StyledScrollbarContentDirective } from './styled-scrollbar-content.directive';

@NgModule({
  imports: [ CommonModule, IonicModule ],
  declarations: [ StyledScollbarDirective, StyledScrollbarContentDirective ],
  exports: [ StyledScollbarDirective, StyledScrollbarContentDirective ],
})
export class StyledScrollbarModule {}
