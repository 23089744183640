import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'app-popup-blocked-notice',
  templateUrl: './popup-blocked-notice.component.html',
  styleUrls: ['./popup-blocked-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupBlockedNoticeComponent {
  @Input() doDismiss: () => void = null;

  constructor(
    public platform: PlatformService
  ) { }

  dismiss() {
    if (this.doDismiss instanceof Function) {
      this.doDismiss();
    }
  }

  get popupImage(): string {
    if (this.platform.isFireFox) {
      return '/assets/img/firefox-popup-blocked.png';
    } else if (this.platform.isSafari) {
      return '/assets/img/safari-popup-blocked.png';
    } else {
      return '/assets/img/chrome-popup-blocked.png';
    }
  }

}
