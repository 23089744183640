import * as tslib_1 from "tslib";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localePt from '@angular/common/locales/pt';
import localeZh from '@angular/common/locales/zh';
import localeHe from '@angular/common/locales/he';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var Language;
(function (Language) {
    Language["ENGLISH"] = "en";
    Language["GERMAN"] = "de";
    Language["RUSSIAN"] = "ru";
    Language["UKRAINIAN"] = "uk";
    Language["SPANISH"] = "es";
    Language["FRENCH"] = "fr";
    Language["ITALIAN"] = "it";
    Language["JAPANESE"] = "ja";
    Language["KOREAN"] = "ko";
    Language["PORTUGUESE"] = "pt";
    Language["CHINESE"] = "zh";
    Language["HEBREW"] = "he";
})(Language || (Language = {}));
export class LanguageService {
    constructor(translateService) {
        this.translateService = translateService;
        this.langChangedEmitter$ = new Subject();
        this.locales = {
            [Language.ENGLISH]: localeEn,
            [Language.GERMAN]: localeDe,
            [Language.RUSSIAN]: localeRu,
            [Language.UKRAINIAN]: localeUk,
            [Language.SPANISH]: localeEs,
            [Language.FRENCH]: localeFr,
            [Language.ITALIAN]: localeIt,
            [Language.JAPANESE]: localeJa,
            [Language.KOREAN]: localeKo,
            [Language.PORTUGUESE]: localePt,
            [Language.CHINESE]: localeZh,
            [Language.HEBREW]: localeHe,
        };
    }
    get currLang() {
        return this.translateService.currentLang;
    }
    get changes$() {
        return this.langChangedEmitter$.asObservable();
    }
    initLanguage(lang) {
        this.translateService.setDefaultLang(Language.ENGLISH);
        const appLang = lang ? lang : this.getDeviceLang();
        console.log('%c [APP LANGUAGE]: ', 'color: green', appLang);
        this.changeLanguage(appLang);
    }
    changeLanguage(lang) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.translateService.use(lang).toPromise();
                moment.locale(lang);
                this.setAngularLang(lang);
                this.langChangedEmitter$.next(lang);
            }
            catch (error) {
                console.error(error);
                return Promise.resolve();
            }
            return Promise.resolve();
        });
    }
    getDeviceLang() {
        const languages = Object.values(Language);
        for (const lang of languages) {
            if (navigator.languages[0].toLowerCase().includes(lang.toLowerCase())) {
                return lang;
            }
        }
        return Language.ENGLISH;
    }
    setAngularLang(lang) {
        if (lang in this.locales) {
            return registerLocaleData(this.locales[lang]);
        }
        return registerLocaleData(this.locales[Language.ENGLISH]);
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.TranslateService)); }, token: LanguageService, providedIn: "root" });
