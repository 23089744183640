<ion-header>
  <ion-toolbar color="primary" mode="ios">
    <ion-title>{{ 'chat.nav_page_title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="search.next()">
        <ion-icon src="/assets/img/search-thin-ic-light.svg" size="small" class="search-icon" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button (click)="expand.next()" class="expand-btn">
        <ion-icon src="/assets/img/open-new-tab-ic-light.svg" size="small" class="open-new-tab-icon" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button (click)="dismiss.next()" class="close-btn">
        <img alt="Close" slot="icon-only" src="/assets/img/chat-close-btn-light.svg">
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content styledScrollbar="shadow-root" appRemoveContentSizing>
  <div styledScrollbarContent>
    <app-chat-nav
      (showDetails)="showDetails.next($event)"
      (select)="select.next($event)"
      (create)="search.next()"
      (openConference)="openConference.next($event)">
    </app-chat-nav>
  </div>
</ion-content>
