import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OnboardingGuidedOverlayComponent } from './onboarding-guided-overlay.component';
import { OnboardingGuideComponentModule } from '../onboarding-guide/onboarding-guide.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    OnboardingGuideComponentModule,
  ],
  declarations: [OnboardingGuidedOverlayComponent],
  exports: [OnboardingGuidedOverlayComponent]
})
export class OnboardingGuidedOverlayComponentModule {}
