import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, isDevMode, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonContent, IonFooter, IonHeader } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, concat, iif, Observable, of, ReplaySubject, Subject, zip } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, first, map, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { UserService } from 'src/app/auth/services/user.service';
import { selectAllUsers, selectChatStreamList, selectChatStreamsMap, selectLoadedUserById } from 'src/app/store/selectors/chat.selectors';
import { createResizeObserver, parseHtmlText } from '../../helpers/helpers';
import { ChatMember, IChatStream, IMessage, IMessageId, IStreamId, IZulipUserId } from '../../models/chat';
import { UserModel } from '../../models/user/user';
import { ZulipService } from '../../services/zulip.service';
import * as fuzzyset from 'fuzzyset/lib/fuzzyset'
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

enum SearchEntryType {
  Stream,
  Converstaion,
  User
}

interface SearchEntry {
  type: SearchEntryType,
  id: any;
  value: string;
  content?: {
    message: string;
    senderFullName: string;
  };
  members?: UserModel[];
  avatarUrl?: string;
  streamId?: IStreamId;
}

type RecentMessage = IMessage & { senderFullName: string };

type RecentStream = IChatStream & {
  recentMessage?: RecentMessage
}

class MessageString extends String {
  constructor(private value: string, private id: IMessageId) {
    super(value);
  }

  valueOf() {
    return this.value;
  }

  toString() {
    return this.value;
  }

  toLowerCase() {
    const lowerCase = String.prototype.toLowerCase.call(this);
    return new MessageString(lowerCase, this.id) as unknown as string;
  }
}


@Component({
  selector: 'app-chat-finder',
  templateUrl: './chat-finder.component.html',
  styleUrls: ['./chat-finder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatFinderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() onCreate = new EventEmitter<IStreamId>();

  @ViewChild(IonHeader, { static: false, read: ElementRef })
  public headerRef: ElementRef<HTMLElement>;

  @ViewChild(IonFooter, { static: false, read: ElementRef })
  public footerRef: ElementRef<HTMLElement>;

  @ViewChild(IonContent, { static: false, read: ElementRef }) 
  public contentRef: ElementRef<HTMLElement>;

  searchActive$ = new ReplaySubject<boolean>(1);
  searchLoading$ = new BehaviorSubject<boolean>(false);
  searchControl = new FormControl('');
  fullTextSearchControl = new FormControl(false);
  newConversationTitleControl = new FormControl();
  newConversationMembers$ = new BehaviorSubject<SearchEntry[]>([]);
  recentStreams$: Observable<RecentStream[]>;
  searchResults$: Observable<SearchEntry[]>;
  searchEntryTypes = SearchEntryType;
  existingStream$: Observable<IChatStream>;
  loading$ = new BehaviorSubject<boolean>(false);
  query$: Observable<string>;
  searchControlPlaceholder$ = concat(of(false), this.fullTextSearchControl.valueChanges).pipe(
    map(() =>
      this.fullTextSearchControl.value
        ? 'chat.text_search_input_placeholder'
        : 'chat.search_input_placeholder'
    )
  );
  currentUser$ = this.zulipService.currentUser$;
  
  private destroy$ = new Subject();

  constructor(
    private store: Store,
    private userService: UserService,
    private zulipService: ZulipService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.recentStreams$ = this.getRecentStreams$().pipe(
      takeUntil(this.destroy$),
      shareReplay(1)
    );

    this.query$ = this.searchControl.valueChanges.pipe(
      map((value) => typeof value === 'string' ? value.trim() : ''),
      takeUntil(this.destroy$),
      shareReplay(1)
    );

    concat(of(''), this.query$)
      .pipe(
        map(value => value.trim().length !== 0),
        takeUntil(this.destroy$)
      )
      .subscribe(this.searchActive$);

    const searchPool$ = this.getSearchPool$();
    const conversationsSearch$ = this.query$.pipe(
      withLatestFrom(searchPool$),
      filter(([ query, pool ]) => query.length > 0 && pool.length > 0),
      map(([ query, pool ]) =>
        pool.filter(({ value }) => value.toLowerCase().includes(query.toLowerCase()))
      )
    );
    const fulltextSearch$ = this.query$.pipe(
      filter(query => query.trim().length > 0),
      tap(() => this.searchLoading$.next(true)),
      switchMap(query => this.fulltextSearch$(query)),
      tap(() => this.searchLoading$.next(false)),
    );
    const isFulltextSearchMode$ = concat(
      of(false),
      this.fullTextSearchControl.valueChanges
    );

    const search$ = isFulltextSearchMode$.pipe(
      switchMap((isFulltextSearchMode) => 
        isFulltextSearchMode
          ? fulltextSearch$
          : conversationsSearch$
      )
    )

    this.searchResults$ = concat(of([]), search$);

    isFulltextSearchMode$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe((isFulltextSearchMode) => {
      if (isFulltextSearchMode) {
        this.newConversationMembers$.next([]);
        this.newConversationTitleControl.setValue('');
      }
    })

    this.existingStream$ = this.zulipService.currentUser$.pipe(
      first(),
      switchMap(user => this.newConversationMembers$.pipe(
        map(members => members.map(({ id }) => id).concat([ user.user_id ])),
        withLatestFrom(this.store.select(selectChatStreamList)),
        map(([ invitees, streams ]) =>
          streams.find(stream =>
            stream.meta.type === 'pm' &&
            invitees.length === stream.subscribers.length &&
            invitees.every(invitee => stream.subscribers.includes(invitee))
          )
        )
      ))
    )
  }

  ngAfterViewInit() {
    createResizeObserver(this.headerRef.nativeElement)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const footerHeight = this.footerRef.nativeElement.offsetHeight;
        const headerHeight = this.headerRef.nativeElement.offsetHeight;
        const contentHeight = (footerHeight + headerHeight) + 'px';
        this.contentRef.nativeElement.style.setProperty(
          '--app-offset-bottom',
          contentHeight
        );
      });
  }

  ngOnDestroy() {
    this.newConversationMembers$.complete();
    this.searchActive$.complete();
    this.loading$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetSearch() {
    this.searchControl.setValue('');
  }

  isAdded(newConversationMembers: SearchEntry[], entryId: string) {
    return newConversationMembers.findIndex(({ id }) => id === entryId) > -1;
  }

  toggle(entry: SearchEntry) {
    const newConversationMembers = Array.from(this.newConversationMembers$.value);
    const existingEntryIx = newConversationMembers.findIndex(({ id }) => id === entry.id);

    if (existingEntryIx > -1) {
      newConversationMembers.splice(existingEntryIx, 1);
      this.newConversationMembers$.next(newConversationMembers)
    } else {
      this.newConversationMembers$.next(newConversationMembers.concat([ entry ]));
    }
  }

  create() {
    const invitees$ = combineLatest([
      this.zulipService.currentUser$,
      this.newConversationMembers$
    ]).pipe(
      map(([ user, members ]) => members.map(({ id }) => id).concat([ user.user_id ]))
    );

    const findOrCreateConversation$: Observable<IStreamId> = invitees$.pipe(
      first(),
      withLatestFrom(this.existingStream$),
      // Check if conversation with selected users already exists.
      // If it's true - navigate to an existing stream. Create new one otherwise
      exhaustMap(([ invitees, existingStream ]) =>
        existingStream != null
          ? of(existingStream.stream_id)
          : this.zulipService.createStream$(invitees, this.newConversationTitleControl.value)
            .pipe(
              map(res => res.stream_id),
              catchError(error => {
                console.log(error);
                return of(null);
              })
            )
      ),
    );

    this.loading$.next(true);

    findOrCreateConversation$
      .pipe(
        first(),
        tap(streamId => {
          this.loading$.next(false);
          if (!streamId) {
            this.toastService.showToast(this.translateService.instant('auth-errors.user-not-found'), TOAST_TYPE.ERROR);
          }
        }),
        filter(streamId => !!streamId),
      )
      .subscribe(this.onCreate);
  }

  handleConversationItemClick(entry: SearchEntry) {
    if (entry.type === SearchEntryType.User) {
      this.toggle(entry);
    } else {
      this.onCreate.next(entry.id);
    }
  }

  goToConversation(streamId: IStreamId) {
    this.onCreate.next(streamId);
  }

  trackByStreamId(index: number, entry: IChatStream) {
    return entry.stream_id;
  }

  private fulltextSearch$(query: string) {
    const streamsMap$ = this.store.select(selectChatStreamsMap);
    const usersMap$ = this.store.select(selectAllUsers).pipe(
      map(users =>
        users.reduce<Record<string, ChatMember>>((accum, user) => {
          if (user != null) {
            accum[user.zulip_uid] = user;
          }
          return accum;
        }, {})
      )
    );
    return this.zulipService.searchMessages$(query).pipe(
      catchError(err => {
        if (isDevMode()) {
          console.warn(err);
        }
        return of({ messages: [] });
      }),
      withLatestFrom(
        streamsMap$,
        usersMap$,
        this.zulipService.currentUser$
      ),
      map(([ res, streamsMap, usersMap, currentUser ]): SearchEntry[] => {
        if (res.messages.length === 0) {
          return [];
        }

        const contents = [];
        const messages: Record<IMessageId, IMessage> = {}
        const messagesPayload = res.messages.filter(({ stream_id }) => streamsMap[stream_id] != null);

        for (let msg of messagesPayload) {
          const content = parseHtmlText(parseHtmlText(msg.content)).replace(/\s\@webhookBot\s/, '');
          contents.push(new MessageString(content, msg.id));
          messages[msg.id] = msg;
        }

        const resultsSet = fuzzyset(contents);
        const queryResult = resultsSet.get(query);

        if (queryResult == null) {
          return [];
        }

        return Array.from(queryResult)
          .sort((a, b) => b[0] - a[0])
          .map(([ , str ]) => messages[str.id])
          .map((msg): SearchEntry => {
            const stream = streamsMap[msg.stream_id];
            const user = usersMap[msg.sender_id];
            const type = stream.meta.type === 'channel' ? SearchEntryType.Stream : SearchEntryType.Converstaion;
            const members = stream.subscribers
              .filter(uid => uid !== currentUser.user_id)
              .map(uid => usersMap[uid]);

            return {
              type,
              members,
              id: msg.id,
              content: {
                message: msg.content,
                senderFullName: `${ user.firstname } ${ user.lastname }`,
              },
              value: stream.title,
              streamId: stream.stream_id,
            }
          })
      })
    );
  }

  private getSearchPool$(): Observable<SearchEntry[]> {
    const streamsPayload$ = combineLatest([
      this.store.select(selectChatStreamList),
      this.store.select(selectAllUsers),
      this.zulipService.currentUser$
    ])
    const allStreams$: Observable<SearchEntry[]> = streamsPayload$.pipe(
      map(([ entries, users, currentZulipUser ]) => {
        const usersMap = users.reduce<Record<string, ChatMember>>((accum, user) => {
          if (user != null) {
            accum[user.zulip_uid] = user;
          }
          return accum;
        }, {});

        return entries
          .filter(stream =>
            // in case it is a conversation stream(direct messages), filter out
            // direct messages(1-o-1's) so we will not be displaying duplicates in search
            stream != null && (stream.meta.type === 'pm' && stream.subscribers.length > 2)
          )
          .map(this.mapStreamToSearchEntry(currentZulipUser, usersMap))
      })
    )
    const allUsers$: Observable<SearchEntry[]> = this.store.select(selectAllUsers).pipe(
      withLatestFrom(this.userService.getUser$()),
      map(([entries, currentUser]) =>
        entries.reduce((users, user) => {
          if (user.id !== currentUser.id) {
            users.push({
              type: SearchEntryType.User,
              id: user.zulip_uid,
              value: user.firstname + ' ' + user.lastname,
              members: [user]
            });
          }
          return users;
        }, [])
      )
    );
    return zip(allStreams$, allUsers$).pipe(
      map(([streams, users]) => [].concat(streams, users))
    )
  }

  private mapStreamToSearchEntry(currentUser: IZulipUserId, usersMap: Record<string, ChatMember>):
    (value: IChatStream) => SearchEntry {
    return entry => {
      const type = entry.meta.type === 'channel'
        ? SearchEntryType.Stream
        : SearchEntryType.Converstaion;
      const members = entry.subscribers.reduce((accum, uid) => {
        if (uid !== currentUser.user_id) {
          accum.push(usersMap[uid]);
        }
        return accum;
      }, []);
      let value = entry.title;

      if (type !== SearchEntryType.Stream && entry.title == null && members.length > 0) {
        value = members.map(({ firstname, lastname }) => firstname + ' ' + lastname).join(' ')
      }

      return {
        type,
        members,
        value,
        id: entry.stream_id,
      };
    };
  }

  private getRecentMessage$(streamId: IStreamId): Observable<RecentMessage | null> {
    return this.zulipService.getMessages$(streamId, 'newest', 1, 0).pipe(
      catchError((err) => {
        if (isDevMode()) {
          console.warn(err);
        }
        return of({ messages: [] });
      }),
      map((res): IMessage | null =>
        res.messages.length > 0 ? res.messages[0] : null
      ),
      switchMap(message => {
        if (message == null) {
          return of(null);
        }

        return this.store.pipe(
          selectLoadedUserById(message.sender_id),
          filter(user => user != null),
          map((user): RecentMessage =>
            ({
              ...message,
              senderFullName: `${ user.firstname } ${ user.lastname }`
            })
          )
        )
      })
    )
  }

  private getRecentStreams$(): Observable<RecentStream[]> {
    const recentStreams$ = this.store.select(selectChatStreamList).pipe(
      map(streams => streams.filter(stream => stream != null)),
      first()
    );
    const recentStreamsWithMessages$ = recentStreams$.pipe(
      filter(streams => streams.length > 0),
      concatMap(streams => {
        const streamsWithMessages$ = streams.map((stream): Observable<RecentStream> => {
          return this.getRecentMessage$(stream.stream_id).pipe(
            map(message =>
              ({
                ...stream,
                recentMessage: message
              })
            )
          );
        });
        return zip(...streamsWithMessages$)
      }),
      map(streams =>
        Array.from(streams).sort(
          (a, b) => a.recentMessage && b.recentMessage
            ? a.recentMessage.timestamp - b.recentMessage.timestamp
            : a.date_created - b.date_created
        )
      )
    )
    return concat(recentStreams$, recentStreamsWithMessages$);
  }
}
