import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { selectChatStreamMembers } from 'src/app/store/selectors/chat.selectors';
import { ChatMember, IStreamId } from '../../models/chat';

@Component({
  selector: 'app-chat-info',
  templateUrl: './chat-info.component.html',
  styleUrls: ['./chat-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatInfoComponent implements OnInit, OnChanges {
  @Input() selectedChannelId: string;

  selectedChannelId$ = new BehaviorSubject<IStreamId>(null);
  members$: Observable<ChatMember[]>;
  accordionState: boolean[] = [ true, true ];

  constructor(
    private store: Store
  ) {
    this.members$ = this.selectedChannelId$.pipe(
      switchMap(id =>
        id == null ? of([] as any) : this.store.pipe(selectChatStreamMembers(id))
      ),
      map((members: ChatMember[]) =>
        Array.from(members)
          .sort((a, b) => a.firstname > b.firstname ? 1 : -1)
          .sort((a, b) =>
            a.online && b.online
              ? 0
              : a.online && !b.online ? -1 : 1
      )),
      shareReplay(1)
    );
  }  

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedChannelId' in changes) {
      this.selectedChannelId$.next(changes['selectedChannelId'].currentValue);
    }
  }

  toggle(index: number) {
    const state = [...this.accordionState];
    state[index] = !state[index];
    this.accordionState = state;
  }

}
