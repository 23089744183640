<ion-card mode="md" [style.paddingBottom]="isTotalHeight ? '50px' : '0px'">
  <ion-card-content styledScrollbar [styledScrollbarSelf]="true">
      <ng-container *ngFor="let notification of notifications">
        <div class="popover-notification">
          <ng-container *ngIf="notification.type === types.MEETING_ATTENDEE">
            <app-meeting-attendee [meeting]="notification.payload" [uuid]="notification.uuid"></app-meeting-attendee>
          </ng-container>
          <ng-container *ngIf="notification.type === types.MEETING_JOIN">
            <app-meeting-join [meeting]="notification.payload" [uuid]="notification.uuid"></app-meeting-join>
          </ng-container>
        </div>
      </ng-container>
  </ion-card-content>
</ion-card>
