import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { filter, switchMap, mapTo, tap, exhaustMap, map } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';
import { IResponse } from 'src/app/shared/models/response';
import { isSuccess } from 'src/app/shared/helpers/helpers';
import { ToastService, TOAST_TYPE } from 'src/app/shared/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Onboarding } from 'src/app/shared/models/onboarding';
import { IInstantMeetLink } from 'src/app/shared/models/common';

const cloneDeep = require('lodash.clonedeep');

export interface IUserSettings {
  subjects: {
    hidden: { [key: string]: boolean },
  };
  lessons: {
    hidden: { [key: string]: boolean },
  };
  appLanguage: string;
  showOnboarding: boolean;
  showInstantMeetInfo: boolean;
  /** @type {boolean} true for incomplete tutorials */
  onboarding: Record<Onboarding, boolean>;
  instantMeetLink: null | IInstantMeetLink
}

const DEFAULT_USER_SETTINGS: IUserSettings = {
  subjects: {
    hidden: {},
  },
  lessons: {
    hidden: {},
  },
  appLanguage: null,
  showOnboarding: true,
  showInstantMeetInfo: true,
  onboarding: {
    [Onboarding.Lessons]: true,
    [Onboarding.Subjects]: true,
    [Onboarding.FindCreatedSubjects]: true,
    [Onboarding.SubjectStudents]: true,
    [Onboarding.SubjectTasks]: true,
    [Onboarding.TaskAttachments]: true,
    [Onboarding.CommunityChat]: true,
    [Onboarding.Materials]: true,
    [Onboarding.Meetings]: true,
    [Onboarding.SchoolsAndClasses]: true,
  },
  instantMeetLink: null,
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private readonly USER_SETTINGS_API: string = 'v1/user-profiles/ui-settings';
  private userSettingsState$: BehaviorSubject<IUserSettings> = new BehaviorSubject(null);
  private updateSettingsEmitter$: Subject<IUserSettings> = new Subject();

  constructor(
    private httpService: HttpService,
    private toastService: ToastService,
    private languageService: LanguageService,
  ) {
    this.listenForSettingsUpdates();
    this.listenForLangUpdates();
  }

  init$(): Observable<void> {
    return this.httpService.get$<IUserSettings>(this.USER_SETTINGS_API)
      .pipe(
        switchMap(response => {
          if (response.error || !response.payload) {
            if ((response.error as HttpErrorResponse).status === 404) {
              return this.createUserSettings$();
            }
            return of(DEFAULT_USER_SETTINGS);
          }
          return of(this.getValidUISettingsObject(response.payload));
        }),
        switchMap(settings => this.setUserLanguage$(settings)),
        tap(settings => this.userSettingsState$.next(settings)),
        mapTo(void 0),
      );
  }

  userSettings$(): Observable<IUserSettings> {
    return this.userSettingsState$
      .asObservable()
      .pipe(filter(settings => !!settings));
  }

  getSettings(): IUserSettings {
    return this.userSettingsState$.value;
  }

  updateSettings(newSettins: IUserSettings): void {
    this.updateSettingsEmitter$.next(newSettins);
  }

  handleSubjectOrLessonRemove(subjectId: string): void {
    const currSettings: IUserSettings = cloneDeep(this.userSettingsState$.value);
    if (currSettings.subjects && (currSettings.subjects.hidden[subjectId] === true || currSettings.subjects.hidden[subjectId] === false) ||
        currSettings.lessons && (currSettings.lessons.hidden[subjectId] === true || currSettings.lessons.hidden[subjectId] === false)) {
      delete currSettings.subjects.hidden[subjectId];
      delete currSettings.lessons.hidden[subjectId];
      this.updateSettings(currSettings);
    }
  }

  private createUserSettings$(): Observable<IUserSettings> {
    return this.httpService.post$<IUserSettings>(this.USER_SETTINGS_API, DEFAULT_USER_SETTINGS)
      .pipe(map(response => response.error ? DEFAULT_USER_SETTINGS : response.payload));
  }

  private updateUserSettings$(settings: IUserSettings): Observable<IResponse<IUserSettings>> {
    return this.httpService.put$(this.USER_SETTINGS_API, settings);
  }

  private listenForSettingsUpdates(): void {
    this.updateSettingsEmitter$
      .asObservable()
      .pipe(
        map(settings => this.removeVisibleSubjectsOrLessons(settings)),
        exhaustMap(settings => this.updateUserSettings$(settings)
          .pipe(tap(response => {
            if (isSuccess(response)) {
              this.userSettingsState$.next(response.payload);
            } else {
              this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
            }
          }))
        )
      )
      .subscribe();
  }

  private removeVisibleSubjectsOrLessons(settings: IUserSettings): IUserSettings {
    const cleanSubjects: IUserSettings = this.removeVisibleSubjectsOrLessonsHelper(settings, 'subjects');
    const cleanLessons: IUserSettings = this.removeVisibleSubjectsOrLessonsHelper(cleanSubjects, 'lessons');
    return cleanLessons;
  }

  private removeVisibleSubjectsOrLessonsHelper(settings: IUserSettings, key: 'subjects' | 'lessons'): IUserSettings {
    const newSettings: IUserSettings = cloneDeep(settings);

    if (newSettings[key]) {
      for (const id in newSettings[key].hidden) {
        if (newSettings[key].hidden.hasOwnProperty(id)) {
          if (newSettings[key].hidden[id] !== true) {
            delete newSettings[key].hidden[id];
          }
        }
      }
    }

    return newSettings;
  }

  private setUserLanguage$(settings: IUserSettings): Observable<IUserSettings> {
    if (settings.appLanguage) {
      this.languageService.initLanguage(settings.appLanguage);
      return of(settings);
    }
    const deviceLang: string = this.languageService.getDeviceLang();
    this.languageService.initLanguage(deviceLang);
    return this.updateUserSettings$({ ...settings, appLanguage: deviceLang })
      .pipe(map(response => response.error ? settings : response.payload))
  }

  private listenForLangUpdates(): void {
    this.languageService.changes$
      .pipe(filter(lang => this.getSettings() && this.getSettings().appLanguage !== lang))
      .subscribe(lang => this.updateSettings({ ...this.getSettings(), appLanguage: lang }));
  }

  private getValidUISettingsObject(settings: IUserSettings): IUserSettings {
    const newSettings: IUserSettings = cloneDeep(settings);
    if (!newSettings.subjects) {
      newSettings.subjects = {
        hidden: {},
      };
    }
    if (!newSettings.lessons) {
      newSettings.lessons = {
        hidden: {},
      };
    }

    return newSettings;
  }
}
