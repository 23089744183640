/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./notification-button.component";
import * as i6 from "../../services/notification-sidebar.service";
var styles_NotificationButtonComponent = [i0.styles];
var RenderType_NotificationButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationButtonComponent, data: {} });
export { RenderType_NotificationButtonComponent as RenderType_NotificationButtonComponent };
function View_NotificationButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "notification-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationButtonComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NotificationButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-button", [["fill", "clear"], ["mode", "md"], ["slot", "end"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "div", [["class", "btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["slot", "icon-only"], ["src", "assets/img/notification-bell-ic.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "clear"; var currVal_1 = "md"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.unreadCount$)); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_NotificationButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-button", [], null, null, null, View_NotificationButtonComponent_0, RenderType_NotificationButtonComponent)), i1.ɵdid(1, 114688, null, 0, i5.NotificationButtonComponent, [i6.NotificationSidebarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationButtonComponentNgFactory = i1.ɵccf("app-notification-button", i5.NotificationButtonComponent, View_NotificationButtonComponent_Host_0, {}, {}, []);
export { NotificationButtonComponentNgFactory as NotificationButtonComponentNgFactory };
