import * as tslib_1 from "tslib";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, first, shareReplay } from "rxjs/operators";
import { Onboarding, IOnboarding, OnboardingTargets } from "../models/onboarding";
import * as i0 from "@angular/core";
export class OnboardingGuideService {
    constructor() {
        this.activeGuide$ = new BehaviorSubject(null);
        this.showOverlay$ = new BehaviorSubject(null);
        this.showOverlay$
            .pipe(filter(options => options == null))
            .subscribe(() => this.activeGuide$.next(null));
    }
    onShowOverlay$() {
        return this.showOverlay$.asObservable().pipe(shareReplay(1));
    }
    showGuide(onboarding) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const activeGuide = this.activeGuide$.getValue();
            if (activeGuide != null) {
                yield this.activeGuide$
                    .pipe(first(guide => guide == null))
                    .toPromise();
            }
            try {
                this.activeGuide$.next(onboarding.type);
                const onboardingTargetsConfig = OnboardingTargets[onboarding.type];
                if (onboardingTargetsConfig != null) {
                    const { target, clickTarget } = onboardingTargetsConfig;
                    return this.showOverlay(onboarding, target, clickTarget);
                }
            }
            catch (err) {
                console.error(err);
            }
        });
    }
    showOverlay(onboarding, targetSlector, clickTargetSelector) {
        const guideComplete$ = new Subject();
        const focusElem = document.querySelector(targetSlector);
        const clickTarget = clickTargetSelector && document.querySelector(clickTargetSelector);
        if (focusElem == null) {
            throw new Error(`Can't find an element to highlight(${targetSlector})`);
        }
        if (clickTargetSelector != null && clickTarget == null) {
            console.warn(new Error(`Can't find click target to delegate to(${clickTargetSelector})`));
        }
        const focusElemRect = focusElem.getBoundingClientRect();
        const overlayHighlightPaddingX = 5;
        const overlayHighlightPaddingY = 10;
        const options = {
            top: focusElemRect.top - overlayHighlightPaddingY,
            left: focusElemRect.left - overlayHighlightPaddingX,
            width: focusElemRect.width + overlayHighlightPaddingY,
            height: focusElemRect.height + overlayHighlightPaddingY * 2,
            borderRadius: 5,
            timeout: 2000,
            delegateClick: () => {
                clickTarget && clickTarget.click();
                this.showOverlay$.next(null);
                guideComplete$.next(onboarding);
                guideComplete$.complete();
            },
            onDismiss: () => {
                this.showOverlay$.next(null);
                guideComplete$.next(onboarding);
                guideComplete$.complete();
            }
        };
        this.showOverlay$.next({ onboarding, options });
        return guideComplete$.toPromise();
    }
}
OnboardingGuideService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnboardingGuideService_Factory() { return new OnboardingGuideService(); }, token: OnboardingGuideService, providedIn: "root" });
