import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { InviteService } from '../../services/invite.service';
import { Subject } from 'rxjs';
import { takeUntil, filter, exhaustMap, tap } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
export class JoinInputComponent {
    constructor(inviteService, cdf, toastService, popoverCtrl, translateService) {
        this.inviteService = inviteService;
        this.cdf = cdf;
        this.toastService = toastService;
        this.popoverCtrl = popoverCtrl;
        this.translateService = translateService;
        this.collapsed = false;
        this.showLabel = false;
        this.onDismiss = null;
        this.join$ = new Subject();
        this.isSubmitted = false;
        this.destroy$ = new Subject();
    }
    ngOnInit() {
        this.join$
            .pipe(filter(code => code && !!code.length), tap(() => this.isSubmitted = true), exhaustMap(code => this.inviteService.joinByCode$(code)), tap(response => {
            this.code = '';
            this.isSubmitted = false;
            if (response.error && response.message) {
                this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
            }
            else {
                const message = this.translateService.instant(`join-input.join-${response.payload.entityType}-success`);
                this.toastService.showToast(message, TOAST_TYPE.SUCCESS);
            }
            if (this.onDismiss instanceof Function) {
                this.onDismiss();
            }
            this.cdf.detectChanges();
        }), takeUntil(this.destroy$))
            .subscribe();
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
    openJoinDialog(event) {
        (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                component: JoinInputComponent,
                componentProps: {
                    collapsed: false,
                    showLabel: true,
                    onDismiss: () => popover.dismiss()
                },
                cssClass: 'join-popover-host',
                showBackdrop: false,
                event
            });
            popover.present();
        }))();
    }
}
