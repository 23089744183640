import { Injectable } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { IMeeting, MeetingUserStatus } from '../models/meeting';
import { tap, map, first, filter } from 'rxjs/operators';
import { PopoverNotificationService } from './popover-notification.service';
import { PopoverNotificationType } from '../components/popover-notification/types';
import { IResponse } from '../models/response';
import { HttpService } from './http.service';
import { SocketService } from './socket.service';
import { SocketMessageType, IMeetingSerialStatusUpdates } from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class MeetingNotificationService {

  private readonly GET_PENDING_MEETINGS = 'v1/meetings/pendings';
  private readonly GET_RECENT_JOIN_MEETINGS = 'v1/meetings/join-recents';
  private readonly CHANGE_MEETING_ATTENDEE_STATUS = 'v2/meetings/change-user-status';

  private newMeetingNotification$ = new Subject<{type: PopoverNotificationType; payload: any }>();
  private meetingAttendeUpdatesEmitter$ = new Subject<string>();
  private meetingSerialStatusUpdatesEmitter$ = new Subject<IMeetingSerialStatusUpdates>();

  constructor(
    private popoverNotificationService: PopoverNotificationService,
    private httpService: HttpService,
    private socketService: SocketService,
  ) {

    this.socketService.messages$()
      .pipe(
        filter(message => message.type === SocketMessageType.MEETING_ATTENDEE_STATUS_UPDATES),
        map(message => (message.payload as IMeetingSerialStatusUpdates)),
        tap(message => this.meetingSerialStatusUpdatesEmitter$.next(message)),
      )
      .subscribe();

    const newMeetingAttendeeSocket$ = this.socketService.messages$()
      .pipe(
        filter(message => message.type === SocketMessageType.MEETING_ATTENDEE),
        map(message => ({ type: PopoverNotificationType.MEETING_ATTENDEE, payload: message.payload })),
      );

    const newMeetingAttendeeJoinSocket$ = this.socketService.messages$()
      .pipe(
        filter(message => message.type === SocketMessageType.MEETING_ATTENDEE_JOIN),
        map(message => ({ type: PopoverNotificationType.MEETING_JOIN, payload: message.payload })),
      );

    merge(newMeetingAttendeeSocket$, newMeetingAttendeeJoinSocket$)
      .subscribe(data => this.newMeetingNotification$.next(data));

    this.newMeetingNotification$
      .pipe(
        map(data => this.popoverNotificationService.getPopoverNotification(data.type, data.payload)),
        tap(popoverNotification => this.popoverNotificationService.addNotification(popoverNotification)),
      )
      .subscribe();
  }

  handleMeetingAttendeeNotification$(
    meetingId: string,
    meetingSerialId: string,
    uuid: string,
    operation: MeetingUserStatus,
  ): Observable<IResponse<boolean>> {
    return this.httpService.patch$(this.CHANGE_MEETING_ATTENDEE_STATUS, { meeting_serial_id: meetingSerialId, status: operation })
      .pipe(
        tap(response => {
          if (!response.error) {
            this.popoverNotificationService.removeNotification(uuid);
            this.meetingAttendeUpdatesEmitter$.next(meetingId);
            this.meetingSerialStatusUpdatesEmitter$.next({ meeting_serial_id: meetingSerialId, meeting_status: operation });
          }
        }),
        map(response => response.payload ? { ...response, payload: true } : { ...response, payload: false }),
      );
  }

  handleMeetingAttendeeJoinNotification(meetingId: string, uuid: string): void {
    this.popoverNotificationService.removeNotification(uuid);
    this.meetingAttendeUpdatesEmitter$.next(meetingId);
  }

  checkForPendingMeetings(): void {
    this.getPendingMeetings$()
      .pipe(
        first(),
        map(response => response.payload ? response.payload : []),
        filter(meetings => meetings.length > 0),
      )
      .subscribe(meetings => {
        meetings.forEach(meeting => {
          this.newMeetingNotification$.next({ type: PopoverNotificationType.MEETING_ATTENDEE, payload: meeting });
        });
      });
  }

  checkForRecentJoinMeetings(): void {
    this.getRecentJoinMeetings$()
      .pipe(
        first(),
        map(response => response.payload ? response.payload : []),
        filter(meetings => meetings.length > 0),
      )
      .subscribe(meetings => {
        meetings.forEach(meeting => {
          this.newMeetingNotification$.next({ type: PopoverNotificationType.MEETING_JOIN, payload: meeting });
        });
      });
  }

  getMeetingAttendeeUpdates$(): Observable<string> {
    return this.meetingAttendeUpdatesEmitter$.asObservable();
  }

  getMeetingSerialStatusUpdates$(): Observable<IMeetingSerialStatusUpdates> {
    return this.meetingSerialStatusUpdatesEmitter$.asObservable();
  }

  private getPendingMeetings$(): Observable<IResponse<IMeeting[]>> {
    return this.httpService.get$(this.GET_PENDING_MEETINGS);
  }

  private getRecentJoinMeetings$(): Observable<IResponse<IMeeting[]>> {
    return this.httpService.get$(this.GET_RECENT_JOIN_MEETINGS);
  }
}
