<div class="container" [class.inline]="isInline">
  <ion-item *ngIf="!isInline" class="calendar-btn" lines="none">
    <ion-button class="main" fill="clear" mode="md" (click)="toggleOpen(); $event.stopPropagation()" [disabled]="readonly">
      <div class="title">
        <img src="assets/img/calendar-ic.svg" alt="" slot="start">
        <div *ngIf="!isDatePeriod()" [ngClass]="{ 'select-date': !isValidDate(selectedDate) }" > {{ isValidDate(selectedDate) ? getSelectedDate() : placeholder }}</div>
        <div *ngIf="isDatePeriod()">
          <div *ngIf="!selectedPeriod.selected" class="select-date">{{ placeholder }}</div>
          <div *ngIf="selectedPeriod.selected" class="selected-period">
            <span class="select-date">
              {{ getFormattedSelectedDate(selectedPeriod.startDate) }} 
              - {{ getFormattedSelectedDate(selectedPeriod.endDate) }} 
            </span>
            <div class="clear btn-circle" (click)="clear(); $event.stopPropagation()">
              <span class="icon icon-remove"></span>
            </div>
          </div>
        </div>
      </div>
    </ion-button>
  </ion-item>
  <ion-card [class.hidden]="!isOpen" #calendar>
    <ng-container *ngIf="label">
      <div class="select-date-placeholder">
        <ion-text>{{ label }}</ion-text>
      </div>
    </ng-container>
    <ng-container *ngIf="isSelectDatePlaceholderVisible()">
      <div class="select-date-placeholder">
        <ion-text *ngIf="!selectedPeriod?.startDate && !selectedPeriod?.endDate">{{ 'meeting.select-start-date' | translate }}</ion-text>
        <ion-text *ngIf="selectedPeriod?.startDate && !selectedPeriod?.endDate">{{ 'meeting.select-end-date' | translate }}</ion-text>
      </div>
    </ng-container>
    <ion-card-header *ngIf="!selectTimePeriod">
      <h3>{{ getCurrentMonth() }}</h3>
      <div>
        <ion-button fill="clear" mode="md" size="small" (click)="prevMonth(); $event.stopPropagation()"><ion-icon class="ico icon-arrow-back"></ion-icon></ion-button>
        <ion-button fill="clear" mode="md" size="small" (click)="nextMonth(); $event.stopPropagation()"><ion-icon class="ico icon-arrow-next"></ion-icon></ion-button>
      </div>
    </ion-card-header>
    <ion-card-header *ngIf="selectTimePeriod">
      <div>
        <ion-button fill="clear" mode="md" size="small" (click)="back(); $event.stopPropagation()"><ion-icon class="ico icon-arrow-back"></ion-icon></ion-button>
      </div>
      <h3>{{ getDayRangeForFrequencyTimeRange() }}</h3>
    </ion-card-header>
    <ion-card-content *ngIf="!selectTimePeriod">
      <div class="day-names">
        <div *ngFor="let name of namesOfDays">{{ name }}</div>
      </div>
        <div class="weeks">
          <div class="week {{type}}" [ngClass]="{ 'selected-week': isSelectedWeek(week) }" *ngFor="let week of weeks; let weekIndex = index">
            <ng-container *ngFor="let day of week">
              <div
                *ngIf="!calendarCellTemplate"
                class="date"
                [ngClass]="{'today': day.today, 'selected': day.selected, 'period': day.period, 'disabled': !isSelectedMonth(day.mDate), 'past-day': isPastDay(day.mDate)}"
                (click)="selectDate($event, day); $event.stopPropagation()">
                <span class="calendar__number">{{day.mDate.date()}}</span>
              </div>
              <ng-container *ngTemplateOutlet="calendarCellTemplate; context: {$implicit: { day: day, weekIndex: weekIndex, onClick: selectDate.bind(this) }}">
              </ng-container>
            </ng-container>
          </div>
        </div>
    </ion-card-content>
    <ion-card-content *ngIf="selectTimePeriod">
      <form class="time">
        <ion-item button class="input" [detail]="false" (click)="openTimePicker($event)">
          <ion-input value="{{ timeControl?.value | date: 'HH:mm' }}" readonly></ion-input>
        </ion-item>
        <span class="error-message" *ngIf="showErrorNotEquals">
          {{ 'datepicker.invalid-time' | translate }}
        </span>
        <div class="btns">
          <ion-button mode="md" size="small" (click)="cancel(); $event.stopPropagation()" color="secondary">
            {{ 'common.btn-cancel' | translate }}
          </ion-button>
          <ion-button mode="md" size="small" color="primary" (click)="submit$.next(); $event.stopPropagation()">
            {{ 'common.btn-save' | translate }}
          </ion-button>
        </div>
      </form>
    </ion-card-content>
  </ion-card>
</div>
  