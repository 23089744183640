export const COLORS_MEETING: string[] = [
    '#DE4300',
    '#DEAD00',
    '#D6DA32',
    '#5DB069',
    '#5DABB0',
    '#5D7EB0',
    '#7B3DCB',
    '#B73DCB',
    '#E858A5',
];


export const COLORS_DEFAULT: string[] = [
    '#FF5F58',
    '#FA9917',
    '#69CE39',
    '#00C2B7',
    '#00b0ff',
    '#5A88FF',
    '#A864FF',
    '#E27DF2',
    '#FF65AF'
];


export  enum COLORS_SCHEMES  {
    DEFAULT = 'default',
    MEETING = 'meeting'
}


export  enum DISPLAY  {
    ONLY_BUTTON = 'only-button',
    BUTTON_WITH_TEXT = 'button-with-text',
}
