import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[styledScrollbarContent]'
})
export class StyledScrollbarContentDirective {
  @Input() styledScrollbarContent: any;

  constructor(public elementRef: ElementRef) {}
}
