import { Injectable } from "@angular/core"
import { of, concat, Observable, fromEvent, BehaviorSubject, TimeoutError, throwError, merge, EMPTY  } from "rxjs"
import { catchError, concatMap, delay, filter, first, map, mapTo, switchMap, takeWhile, tap, timeout, timeoutWith, withLatestFrom } from "rxjs/operators"
import { TranslateService } from "@ngx-translate/core"
import { FindCreatedSubjectsOnboardingPayload, Onboarding, OnboardingLink, OnboardingLinks, IOnboarding, ONBOARDING_YOUTUBE_PLAYLIST_ID } from "../models/onboarding"
import { UserService } from "src/app/auth/services/user.service"
import { EntityType, EventType } from "../models/common"
import { EntityAdapterService } from "./entity-adapter.service"
import { InviteService } from "./invite.service"
import { SubjectStoreService } from "./stores/subject-store.service"
import { NavigationEnd, Router } from "@angular/router"
import { UserSettingsService } from "src/app/auth/services/user-settings.service"

export interface OverlayHighlightOptions {
  top: number
  left: number
  height: number
  width: number
  borderRadius: number
  timeout: number
  delegateClick?: () => any
  onDismiss?: () => any
}

@Injectable({ providedIn: 'root' })
export class OnboardingService {
  active$ = new BehaviorSubject<Onboarding>(null)
  
  private startOnboardingGuide$ = new BehaviorSubject<IOnboarding>(null)

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private subjectStoreService: SubjectStoreService,
    private inviteService: InviteService,
    private entityAdapterService: EntityAdapterService,
    private router: Router,
    private userSettingsService: UserSettingsService,
  ) {
    this.onNavigateToSubjectDashboard$().pipe(
      takeWhile(() => !this.checkOnboardingComplete(Onboarding.SubjectTasks)),
    )
    .subscribe(() => this.startOnboarding({ type: Onboarding.SubjectTasks }))
  }

  getDisplayHintsSettings() {
    return this.userSettingsService.getSettings().showOnboarding
  }

  setDisplayHintsSettings(showOnboarding: boolean) {
    const current = this.userSettingsService.getSettings()
    this.userSettingsService.updateSettings({ ...current, showOnboarding })
  }
  
  checkOnboardingComplete(onboarding: Onboarding): boolean {
    const settings = this.userSettingsService.getSettings()
    return settings.showOnboarding && !settings.onboarding[onboarding]
  }

  getOnboardingLinks(onboardingSection: Onboarding): Array<OnboardingLink & { url: string }> {
    const lang = this.translateService.currentLang === 'de' ? 'de' : 'en'
    const links = OnboardingLinks[lang][onboardingSection]
    return links.map(
      link => ({
        ...link,
        url: this.getVideoLink(link.videoId)
      })
    )
  }

  getVideoLink(videoId: string) {
    return `https://www.youtube.com/watch?v=${videoId}&list=${ONBOARDING_YOUTUBE_PLAYLIST_ID}`
  }

  startOnboarding(onboarding: IOnboarding) {
    this.startOnboardingGuide$.next(onboarding)
  }

  markOnboardingComplete({ type }: IOnboarding): void {
    this.userSettingsService.userSettings$()
      .pipe(
        first(),
        map(settings => {
          return {
            ...settings,
            onboarding: {
              ...settings.onboarding,
              [type]: false
            }
          }
        }),
      )
      .subscribe(settings => {
        this.userSettingsService.updateSettings(settings)
        this.active$.next(null)
      })
  }

  showOnboardingGuide$(): Observable<IOnboarding> {
    const onboardingStart$: Observable<IOnboarding> = this.inviteService.onPendingJoin$.pipe(
      first(isPending => isPending),
      mapTo({ isStudentFlow: true }),
      timeout(2000),
      catchError(err => {
        if (err instanceof TimeoutError) {
          return of({ isStudentFlow: false })
        }
        return throwError(err)
      }),
      concatMap(({ isStudentFlow }) => {
        console.log(`OnboardingService.showOnboardingGuide$`, { isStudentFlow })

        if (isStudentFlow) {
          return this.subjectStoreService.getState$().pipe(
            first(state => state != null && state.study.length === 0),
            switchMap(() => this.onJoinLesson$()),
            first(),
            mapTo({ type: Onboarding.Lessons })
          )
        }

        return this.subjectStoreService.getState$().pipe(
          first(state => state != null && state.study.length === 0),
          switchMap(() => merge(
            this.setupTeacherOnboardingFlow$(),
            this.startOnboardingGuide$,
          )),
        )
      })
    )

    return onboardingStart$.pipe(
      filter(value => value != null),
      concatMap((onboarding) =>
        this.active$.pipe(
          first((activeOnboarding) => activeOnboarding == null),
          mapTo(onboarding)
        )
      ),
      concatMap(onboarding => 
        this.userSettingsService.userSettings$().pipe(
          first(),
          map((settings) => ({onboarding, settings})),
        )
      ),
      filter(({onboarding, settings}) =>
        (settings.showOnboarding !== undefined && settings.showOnboarding) &&
        (settings.onboarding ? settings.onboarding[onboarding.type] : true)
      ),
      map(({onboarding}) => onboarding),
      tap(({type}) => this.active$.next(type))
    )
  }

  private setupTeacherOnboardingFlow$(): Observable<IOnboarding> {
    const { onboarding } = this.userSettingsService.getSettings()

    if (onboarding == null) {
      return EMPTY
    }

    const teacherFlow: { type: Onboarding, guide$: Observable<IOnboarding> }[] = [
      {
        type: Onboarding.Subjects,
        guide$: of({ type: Onboarding.Subjects })
      },
      {
        type: Onboarding.FindCreatedSubjects,
        guide$: this.onCreateSubject$().pipe(
          first(),
          map((entity): FindCreatedSubjectsOnboardingPayload => ({
            type: Onboarding.FindCreatedSubjects,
            data: { title: entity.title }
          }))
        ),
      },
      {
        type: Onboarding.SubjectStudents,
        guide$: this.onCreateSubject$().pipe(
          first(),
          delay(1000),
          switchMap(() => this.clickOnSubjectsGroup$().pipe(first())),
          delay(200),
          map(() => ({ type: Onboarding.SubjectStudents }))
        )
      },
      {
        type: Onboarding.TaskAttachments,
        guide$: this.onNavigateToTaskPage$().pipe(
          first(),
          delay(200),
          map(() => ({ type: Onboarding.TaskAttachments }))
        )
      },
      {
        type: Onboarding.CommunityChat,
        guide$: of({ type: Onboarding.CommunityChat })
      },
      {
        type: Onboarding.Materials,
        guide$: of({ type: Onboarding.Materials })
      },
      {
        type: Onboarding.Meetings,
        guide$: of({ type: Onboarding.Meetings })
      },
      {
        type: Onboarding.SchoolsAndClasses,
        guide$: of({ type: Onboarding.SchoolsAndClasses })
      },
    ]

    return concat(
      ...teacherFlow
        .filter(({ type }) => onboarding[type])
        .map(({ guide$ }) => guide$)
    )
  }

  private onNavigateToSubjectDashboard$() {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      filter(event => event.urlAfterRedirects.startsWith('/pages/subject-dashboard/'))
    )
  }

  private onNavigateToTaskPage$() {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      filter(event => event.urlAfterRedirects.startsWith('/pages/ticket/'))
    )
  }

  private clickOnSubjectsGroup$() {
    const target = document.querySelector(
      '[data-app-menu-item-type="SUBJECT_GROUP"] [data-overlay-click-target]'
    )
    return fromEvent(target, 'click')
  }

  private onJoinLesson$() {
    return this.inviteService.observeStudentJoined$()
  }

  private onCreateSubject$() {
    const createdSubject$ = this.subjectStoreService.getState$().pipe(
      first(state => state != null && state.teach.length > 0),
      map(state => {
        if (state.teach.length > 1) {
          const sortedSubjects = Array.from(state.teach)
            .sort((a, b) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime())
          return sortedSubjects[0]
        }
        return state.teach[0]
      }),
    )
    return this.entityAdapterService.onEntityUpdate$.pipe(
      withLatestFrom(this.userService.getUser$()),
      filter(([event, user]) =>
        event.eventType === EventType.ADD &&
        event.entityType === EntityType.SUBJECT &&
        (event.response.payload && event.response.payload.owner_id === user.id)
      ),
      map(([event]) => event.response.payload && event.response.payload),
      timeoutWith(2000, createdSubject$)
    )
  }
}