import {
  NotificationType, ITicketAnnounceNotification, ITicketTaskDueDateChanged, INotificationMessage,
  IBaseNotification,
  ITicketBaseNotification, MessageTypes,
} from '../../../models/notifications';
import { TicketType } from '../../../models/ticket';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { EntityType } from '../../../models/common';

export function getMsgTitle(
  message: INotificationMessage<IBaseNotification>,
  translateService: TranslateService,
): string {
  if (Object.values(MessageTypes).includes(message.payload.message_type)) {
    switch (message.payload.message_type) {
      case MessageTypes.FILE:
        return translateService.instant('notifications.send-file');
      case MessageTypes.TEXT:
        return translateService.instant('notifications.new-message');
      case MessageTypes.MATERIAL:
        return translateService.instant('notifications.send-material');
      case MessageTypes.LINK:
        return translateService.instant('notifications.send-link');
    }
  }

  switch (message.type) {
    case NotificationType.TICKET_TASK_PUBLISHED:
    case NotificationType.ADD_TICKET_TASK:
      return translateService.instant('notifications.new-task');
    case NotificationType.TICKET_TASK_CHANGED:
    case NotificationType.TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED:
      return getTicketChangedTitle(message, translateService);
    case NotificationType.TICKET_TASK_STATE_CHANGED:
    case NotificationType.ASSIGNED_TASK_STATE_CHANGE:
      return translateService.instant('notifications.status');
    case NotificationType.ASSIGNED_TASK_MESSAGE:
      return translateService.instant('notifications.new-message');
    case NotificationType.STUDENT_COMPLETE_TASK:
      return translateService.instant('notifications.student-completed-task');
    case NotificationType.STUDENT_UPDATE_TASK:
      return translateService.instant('notifications.student-update-task');
    case NotificationType.TICKET_ANNOUNCE_PUBLISHED:
      const translateKey = (message.payload as ITicketAnnounceNotification).ticket_type === TicketType.GENERAL
        ? 'general'
        : 'notification';
      return translateService.instant(`notifications.new-${translateKey}`);
    case NotificationType.MEETING_ATTENDEE_START_SOON:
      return translateService.instant('notifications.meeting-start-soon');
    case NotificationType.MEETING_ATTENDEE_START_1_HOUR:
      return translateService.instant('notifications.meeting-start-1-hour');
    case NotificationType.MEETING_ATTENDEE_START_5_MIN:
      return translateService.instant('notifications.meeting-start-n-min', { value: 5 });
    case NotificationType.MEETING_ATTENDEE_START_10_MIN:
      return translateService.instant('notifications.meeting-start-n-min', { value: 10 });
    case NotificationType.TICKET_TASK_DUE_DATE:
      return translateService.instant('notifications.new-task-due-date');
    case NotificationType.TICKET_TASK_DUE_DATE_CHANGED:
      const title: string = translateService.instant('notifications.task-due-date-changed');
      const date: string = moment((message.payload as ITicketTaskDueDateChanged).due_date).format('DD.MM.YYYY');
      return  title + ' ' + date ;
    default:
      return '';
  }
}

function getTicketChangedTitle(
  message: INotificationMessage<IBaseNotification>,
  translateService: TranslateService,
): string {
  if (message.type === NotificationType.TICKET_TASK_CHANGED) {
    return translateService.instant('notifications.task-changed');
  }
  const entityType: EntityType = (message.payload as ITicketBaseNotification).entity_type as EntityType;
  return translateService.instant(`notifications.${entityType.toLowerCase().replace('_', '-')}-changed`);
}
