import { SchoolAndClassDashboardPermissions, SchoolAndClassTicketPermissions } from './base.abstract.class';
import { SchoolDashboardColumn } from 'src/app/pages/school-dashboard/helpers';
import { isPublishedAndDatePast } from '../../components/ticket-item/helpers';
export class SchoolDashboardPermissions extends SchoolAndClassDashboardPermissions {
    constructor() {
        super(...arguments);
        this.NOT_EDITED_COLUMNS = Object.keys(SchoolDashboardColumn)
            .map(title => title.toLowerCase());
        this.FORBIDDEN_ADD_TICKETS_COLUMNS = [
            SchoolDashboardColumn.TEACHERS,
            SchoolDashboardColumn.STUDENTS,
        ].map(title => title.toLowerCase());
    }
    canAddTicketsToColumn(school, column) {
        if (this.FORBIDDEN_ADD_TICKETS_COLUMNS.includes(column.title.toLowerCase())) {
            return false;
        }
        if (column.title.toLowerCase() === SchoolDashboardColumn.CLASSES.toLowerCase()) {
            return this.isOwnerOrInvitedTeacher(school);
        }
        return this.isOwner(school);
    }
}
export class SchoolTicketPermissions extends SchoolAndClassTicketPermissions {
    canEditDate(entity) {
        return !isPublishedAndDatePast(entity) && this.canEditOrRemoveEntity(entity);
    }
}
