import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { IMeeting, MeetingFrequency, MeetingUserStatus, getMeetingUserStatusIcon, IMeetingType, IMeetingFrequency } from '../../models/meeting';
import { MeetingNotificationService } from '../../services/meeting-notification.service';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { UserModel } from '../../models/user/user';
import { UserService } from 'src/app/auth/services/user.service';
import { tap, map, filter, concatMap, withLatestFrom } from 'rxjs/operators';
import { merge, Observable, BehaviorSubject, of } from 'rxjs';
import { MeetingService } from '../../services/meeting.service';
import * as moment from 'moment';
import { PopoverNotificationService } from '../../services/popover-notification.service';
import { INotificationMessage, IMeetingNotification, NotificationType, IMeetingChangedNotification, IMeetingUserStatusUpdate } from '../../models/notifications';
import { TranslateService } from '@ngx-translate/core';
import { NotificationSidebarService } from '../../services/notification-sidebar.service';
import { getFrequencyText, detectChanges, backgroundAsStyle } from '../../helpers/helpers';
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-meeting-attendee',
  templateUrl: './meeting-attendee.component.html',
  styleUrls: ['./meeting-attendee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingAttendeeComponent implements OnInit {
  @Input() meeting: IMeeting;
  @Input() uuid: string;
  @Input() notification: INotificationMessage<IMeetingNotification | IMeetingUserStatusUpdate>;
  @Output() closeSidebar = new EventEmitter();
  isSubmitted: boolean = false;
  ownerFullname$: Observable<string>;
  meetingType$: Observable<IMeetingType>;
  view: 'popover' | 'notification' = 'popover';
  isMeetingStatusLoading: boolean = true;
  isSubjectLoading: boolean = false;
  isMeetingTypeLoading: boolean = false;
  meetingStatus$: Observable<MeetingUserStatus>;
  showButtons$ = new BehaviorSubject(false);
  getMeetingUserStatusIcon = getMeetingUserStatusIcon;
  meetingAttendeeOperations = MeetingUserStatus;
  notificationTypes = NotificationType;
  meetingUserStatus = MeetingUserStatus;

  private notificationDateFormat = 'D MMMM YYYY';

  constructor(
    private meetingNotificationService: MeetingNotificationService,
    private popoverNotificationService: PopoverNotificationService,
    private notificatioSidebarService: NotificationSidebarService,
    private meetingService: MeetingService,
    private toastService: ToastService,
    private userService: UserService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    if (this.notification) {
      this.view = 'notification';
      this.meetingStatus$ = this.getMeetingStatus$();
    }

    if (this.view === 'popover') {
      this.showButtons$.next(true);
    }

    this.ownerFullname$ = this.getMeetingOwnerFullname$();
  }

  get frequencyDates(): IMeetingFrequency[] {
    if ((this.view === 'popover' && !this.meeting) ||
      this.view === 'notification' && !this.notification) {
      return [];
    }
    if (this.meeting) {
      return this.meeting.frequency_dates;
    }
    if (this.notification && this.notification.payload.meeting_frequency_dates) {
      return this.notification.payload.meeting_frequency_dates;
    }
    return [];
  }

  get startDate(): string | Date {
    if (this.view === 'popover' && this.meeting) {
      return this.meeting.start_date;
    } else if (this.view === 'notification' && this.notification && this.notification.payload) {
      return this.notification.payload.meeting_start_date;
    }
    return '';
  }


  get endDate(): string | Date {
    if (this.view === 'popover' && this.meeting) {
      return this.meeting.end_date;
    } else if (this.view === 'notification' && this.notification && this.notification.payload) {
      return this.notification.payload.meeting_end_date;
    }
    return '';
  }

  get formattedDate(): string {
    return moment(this.notification.added_on).format(this.notificationDateFormat);
  }

  get meetingId(): string {
    return this.view === 'notification' ? this.notification.payload.id : this.meeting.id;
  }

  get meetingSerialId(): string {
    return this.view === 'notification' ? this.notification.payload.meeting_serial_id : this.meeting.serial_id;
  }

  get meetingChangedTitle(): string {
    if (this.view === 'notification' && this.notification.type === NotificationType.MEETING_ATTENDEE_CHANGED) {
      const payload = this.notification.payload as IMeetingChangedNotification;
      return this.translateService.instant(`notifications.meeting-${payload.event_type.toLowerCase()}`);
    }

    return '';
  }

  get meetingType(): string {
    if (this.view === 'notification' && this.notification) {
      return this.notification.payload.meeting_type;
    }
    if (this.view === 'popover' && this.meeting && this.meeting.type) {
      return this.meeting.type.label;
    }
    return '';
  }

  get meetingName(): string {
    if (this.view === 'notification' && this.notification) {
      return this.notification.payload.message;
    }
    if (this.view === 'popover' && this.meeting) {
      return this.meeting.title;
    }
    return '';
  }

  get meetingSubjectName(): string {
    if (this.view === 'notification' && this.notification) {
      return this.notification.payload.subject_name;
    }
    if (this.view === 'popover' && this.meeting && this.meeting.subject) {
      return this.meeting.subject.title;
    }
    return '';
  }


  get meetingSubjectColor(): string {
    if (this.view === 'notification' && this.notification) {
      return this.notification.payload.subject_color;
    }
    if (this.view === 'popover' && this.meeting && this.meeting.subject) {
      return this.meeting.subject.color;
    }
    return '';
  }

  get meetingUserStatusUpdateFullname(): string {
    if (this.notification && this.notification.type === NotificationType.MEETING_ATTENDEE_STATUS_UPDATES) {
      return (this.notification.payload as IMeetingUserStatusUpdate).meeting_user_fullname
        ? (this.notification.payload as IMeetingUserStatusUpdate).meeting_user_fullname
        : '';
    }
    return '';
  }

  public getFrequencyText(frequency: MeetingFrequency, step: number): string {
    return getFrequencyText(frequency, step, this.translateService);
  }

  handleMeetingAttendee(operation: MeetingUserStatus): void {
    this.isSubmitted = true;
    this.meetingNotificationService.handleMeetingAttendeeNotification$(this.meetingId, this.meetingSerialId, this.uuid, operation)
      .toPromise()
      .then(response => {
        this.isSubmitted = false;
        if (response.error) {
          this.toastService.showToast(response.message, TOAST_TYPE.ERROR);

          type ApiValidationError = typeof response.error & { error: { error: { code: string } } }

          if ((response.error as ApiValidationError).error.error.code === 'VALIDATION_FAILED') {
            this.popoverNotificationService.removeMeetingAttendeeNotification(this.meetingId);
          }
        } else {
          if (this.view === 'notification') {
            this.popoverNotificationService.removeMeetingAttendeeNotification(this.meetingId);
          }
        }
        detectChanges(this.cdr);
      });
  }

  open() {
    this.router.navigate(['pages', 'meetings'], {queryParams: {
        meetingId: this.notification.payload.id,
        meetingDate: this.notification.payload.meeting_start_date
    }}).then(() => this.closeSidebar.emit());
  }

  backgroundAsStyle(value) {
    return backgroundAsStyle(value, this.sanitizer);
  }

  public getUserMeetingStatusTitle(status: string): string {
    return this.translateService.instant(`notifications.user-${status.toLowerCase()}-meeting`);
  }

  public getMeetingAttendeeStatusUpdateIcon(status: string): string {
    return this.getMeetingUserStatusIcon(status as MeetingUserStatus);
  }

  private getOwnerFullname(user: UserModel): string {
    return user ? UserModel.getFullname(user) : '';
  }

  private getMeetingOwnerFullname$(): Observable<string> {
    if (this.view === 'notification' && this.notification) {
      return of(this.notification.payload.meeting_owner_fullname);
    }

    return this.userService.getUserRequest$(this.meeting.owner_id)
      .pipe(
        tap(response => {
          if (response.error) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
          }
        }),
        map(response => this.getOwnerFullname(response.payload)),
      );
  }

  private getMeetingStatus$(): Observable<MeetingUserStatus> {
    if (this.view === 'notification' && this.notification) {
      if (this.notification.type === NotificationType.MEETING_ATTENDEE) {
        const currentStatus = this.notification.payload.meeting_status as MeetingUserStatus;
        if (currentStatus === MeetingUserStatus.ACCEPTED || currentStatus === MeetingUserStatus.DECLINED) {
          return of(currentStatus);
        } else {
          this.showButtons$.next(true);
        }
      } else if (this.notification.type === NotificationType.MEETING_ATTENDEE_CHANGED) {
        this.showButtons$.next(false);
      }
    }

    const initStatus$ = this.userService.getUser$()
      .pipe(
        filter(user => !!user),
        tap(() => this.isMeetingStatusLoading = true),
        concatMap(user => this.meetingService.getStatusByUser$(this.meetingId, user.id)),
        filter(response => !!response.payload),
        map(response => response.payload['status']),
      );
    const updateStatus$ = this.meetingNotificationService.getMeetingAttendeeUpdates$()
      .pipe(
        filter(id => id === this.meetingId),
        withLatestFrom(this.userService.getUser$()),
        tap(() => this.isMeetingStatusLoading = true),
        concatMap(data => this.meetingService.getStatusByUser$(data[0], data[1].id)),
        filter(response => !!response.payload),
        map(response => response.payload['status']),
        tap(() => {
          if (this.notification) {
            this.notificatioSidebarService.markAsRead(this.notification);
          }
        }),
      );

    return merge(initStatus$, updateStatus$)
      .pipe(
        tap(status => {
          if (status === MeetingUserStatus.PENDING) {
            this.showButtons$.next(true);
          } else {
            this.showButtons$.next(false);
          }
          this.isMeetingStatusLoading = false;
        }),
      );
  }
}
