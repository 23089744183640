import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarInputComponent } from './calendar-input.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarPickerComponent } from './calendar-picker/calendar-picker.component';
import { CalendarCellModule } from './calendar-cell.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [CalendarInputComponent, CalendarPickerComponent],
  entryComponents: [CalendarInputComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    CalendarCellModule,
    PipesModule,
  ],
  exports: [CalendarInputComponent, CalendarPickerComponent],
})
export class CalendarInputModule { }
