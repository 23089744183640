import { ComponentFactoryResolver, ViewContainerRef, ComponentFactory, ComponentRef } from '@angular/core';
import { CreateMenuComponent } from '../components/create-menu/create-menu.component';
import { take, filter, mapTo, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { merge } from 'rxjs';
import { SubjectService } from './subject.service';
import { Router } from '@angular/router';
import { EventType } from '../models/common';
import { NavController, ModalController } from '@ionic/angular';
import { MaterialService } from './material.service';
import { SchoolService } from './school.service';
import { ClassService } from './class.service';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/services/auth.service";
import * as i2 from "./subject.service";
import * as i3 from "./material.service";
import * as i4 from "./school.service";
import * as i5 from "./class.service";
import * as i6 from "@angular/router";
import * as i7 from "@ionic/angular";
export class EntityCrudViewService {
    constructor(authService, subjectService, materialService, schoolService, classService, router, navCtrl, resolver, modalCtrl) {
        this.subjectService = subjectService;
        this.materialService = materialService;
        this.schoolService = schoolService;
        this.classService = classService;
        this.router = router;
        this.navCtrl = navCtrl;
        this.resolver = resolver;
        this.modalCtrl = modalCtrl;
        this.MIN_WIDTH = 768;
        this.forceDestroy$ = authService.isAuthenticated$
            .pipe(filter(isAuthenticated => !isAuthenticated), mapTo(null));
        merge(this.subjectService.notifier$, this.materialService.notifier$, this.schoolService.notifier$, this.classService.notifier$)
            .pipe(filter(event => event.eventType === EventType.REMOVE), filter(event => this.router.url.includes(event.entityId)), tap(() => this.navCtrl.navigateRoot('pages')))
            .subscribe();
    }
    setCreateMenuContainer(container) {
        this.createMenuContainer = container;
    }
    openCreateMenu(entity, entityType, payload) {
        this.createMenuContainer.clear();
        if (window.innerWidth < this.MIN_WIDTH) {
            return this.displayInModal(entity, entityType, payload);
        }
        return this.displayNearSideMenu(entity, entityType, payload);
    }
    displayInModal(entity, entityType, payload) {
        this.modalCtrl.create({
            id: '1',
            component: CreateMenuComponent,
            componentProps: {
                entity,
                entityType,
                payload,
                hasModalStyle: true,
            },
        })
            .then(modal => modal.present());
    }
    displayNearSideMenu(entity, entityType, payload) {
        const factory = this.resolver.resolveComponentFactory(CreateMenuComponent);
        const componentRef = this.createMenuContainer.createComponent(factory);
        componentRef.instance.entity = entity;
        componentRef.instance.entityType = entityType;
        componentRef.instance.payload = payload;
        merge(this.forceDestroy$, componentRef.instance.destroyEmitter)
            .pipe(take(1))
            .subscribe(() => componentRef.destroy());
    }
}
EntityCrudViewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntityCrudViewService_Factory() { return new EntityCrudViewService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.SubjectService), i0.ɵɵinject(i3.MaterialService), i0.ɵɵinject(i4.SchoolService), i0.ɵɵinject(i5.ClassService), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i7.NavController), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i7.ModalController)); }, token: EntityCrudViewService, providedIn: "root" });
