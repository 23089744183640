import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@ionic/angular";
export class AuthPagesGuard {
    constructor(authService, navCtrl) {
        this.authService = authService;
        this.navCtrl = navCtrl;
    }
    canActivate() {
        const isLoggedIn = this.authService.isAuthenticated;
        if (isLoggedIn) {
            this.navCtrl.navigateRoot('pages/home');
        }
        return !isLoggedIn;
    }
}
AuthPagesGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthPagesGuard_Factory() { return new AuthPagesGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.NavController)); }, token: AuthPagesGuard, providedIn: "root" });
