import { IAppApiConfig } from "../models";

export const STAGE_API_CONFIG: IAppApiConfig = {
  apiEndpoint: 'https://api.stage.estudy.fm/api',
  websocketUrl: 'https://api.stage.estudy.fm',
  janusSignalingUrl: 'https://janus-signaling-master.stage.estudy.fm',
  groupworldEndpoint: 'https://app.stage.estudy.fm/conf',
  zulipInstanceEndpoint: 'https://chat.stage.estudy.fm',
  zulipApiEndpoint: 'https://chat.stage.estudy.fm/api/v1',
  webUrl: 'https://app.stage.estudy.fm',
}
