import { EventEmitter, Input, HostBinding } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { IStreamId } from 'src/app/shared/models/chat';
import { PlatformService } from 'src/app/shared/services/platform.service';

@Component({
  selector: 'app-chat-nav-page',
  templateUrl: './chat-nav.page.html',
  styleUrls: ['./chat-nav.page.scss'],
})
export class ChatNavPage implements OnInit {
  @Output() select = new EventEmitter<string>();
  @Output() search = new EventEmitter<void>();
  @Output() expand = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();
  @Output() showDetails = new EventEmitter<IStreamId>();
  @Output() openConference = new EventEmitter<IStreamId>();

  @HostBinding('class.desktop-view') get t() {
    return this.platformService.isDesktop;
  }

  constructor(
    private platformService: PlatformService,
  ) {}

  ngOnInit() {
  }

}
