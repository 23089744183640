import { AfterViewInit, ContentChildren, Directive, ElementRef, Input, OnDestroy, QueryList } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { concat, from, iif, merge, NEVER as OBSERVABLE_NEVER, of as observableOf, Subject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { createMutationObserver, createResizeObserver } from 'src/app/shared/helpers/helpers';
import { PlatformService } from '../../services/platform.service';
import { WindowService } from '../../services/window.service';
import { StyledScrollbarContentDirective } from './styled-scrollbar-content.directive';

@Directive({
  selector: '[styledScrollbar]'
})
/** Injects customized scrollbar styles into host's shadow root  */
export class StyledScollbarDirective implements AfterViewInit, OnDestroy {
  /* Where to inject scrollbar styles  */
  @Input() styledScrollbar: '' | 'default' | 'shadow-root' | 'hidden' = 'default';
  @Input() styledScrollbarSelf: boolean = false;

  @ContentChildren(StyledScrollbarContentDirective, { descendants: true })
  public styledScrollbarContent: QueryList<StyledScrollbarContentDirective>;

  private scrollElem: HTMLElement = null;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef<HTMLElement & IonContent>,
    private windowService: WindowService,
    private platformService: PlatformService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    if (this.platformService.isMobile) {
      return;
    }

    if (this.styledScrollbar === '') {
      this.styledScrollbar = 'default';
    }

    const host: HTMLElement & IonContent = this.elementRef.nativeElement;

    if (this.styledScrollbar === 'shadow-root' && host.getScrollElement instanceof Function) {
      host.getScrollElement().then((elem) => {
        this.scrollElem = elem;
        this.updateScrollIndicator();
      });

      try {
        const stylesheet = this.getStyles();
        const styleElem = host.shadowRoot.querySelector('style');
    
        if (styleElem) {
          styleElem.append(stylesheet);
        } else {
          const scrollbarStylesElem = document.createElement('style');
          scrollbarStylesElem.append(stylesheet);
          host.shadowRoot.appendChild(scrollbarStylesElem);
        }
      } catch(err) {
        console.error('[StyledScollbarDirective]', err);
      }
    } else {
      host.classList.add('styled-scrollbar');

      if (this.styledScrollbar === 'hidden') {
        host.classList.add('hidden-scrollbar');
      }

      this.scrollElem = host;
    }

    const childElementResize$ = concat(observableOf(this.styledScrollbarContent), this.styledScrollbarContent.changes)
      .pipe(
        first(() => this.styledScrollbarContent.first instanceof StyledScrollbarContentDirective),
        switchMap(() => {
          const target = this.styledScrollbarContent.first.elementRef.nativeElement;
          const scrollResize$ = host.getScrollElement instanceof Function
            ? from(host.getScrollElement()).pipe(switchMap(elem => createResizeObserver(elem)))
            : OBSERVABLE_NEVER;

          return merge(
            createResizeObserver(target),
            scrollResize$,
            this.windowService.resize$
          );
        })
      );
    const currentElementResize$ = merge(
      observableOf(null),
      createResizeObserver(this.scrollElem),
      createMutationObserver(this.scrollElem, { childList: true })
    );
    
    // watch for content element resize and add/remove scroll css-class
    iif(
      () => this.styledScrollbarSelf,
      currentElementResize$,
      childElementResize$
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((value) => {
      if (this.scrollElem != null) {
        this.updateScrollIndicator()
      }
    });
  }

  private getStyles() {
    const vars = this.styledScrollbar === 'hidden'
      ? `
      --styled-scrollbar-border-radius: 0px;
      --styled-scrollbar-track-color: rgba(255,255,255,0);
      --styled-scrollbar-thumb-color: rgba(255,255,255,0);
      --styled-scrollbar-thumb-width: 0px;
      --styled-scrollbar-thumb-hover-color: rgba(255,255,255,0);
      --styled-scrollbar-offset-start: 0px;
      --styled-scrollbar-offset-end: 0px;`
      : ``;
    return vars + `.inner-scroll{scrollbar-width:thin;scrollbar-color:var(--styled-scrollbar-thumb-color,#86969f) transparent}@supports not (scrollbar-width:thin){.inner-scroll::-webkit-scrollbar{width:0;height:0}.inner-scroll::-webkit-scrollbar{width:0;height:0}.inner-scroll::-webkit-scrollbar-track{background-image:linear-gradient(to bottom,var(--styled-scrollbar-track-color,#d6dfe3),var(--styled-scrollbar-track-color,#d6dfe3));background-size:0px 100%;background-repeat:no-repeat;background-position:center}.inner-scroll::-webkit-scrollbar-thumb{border-radius:var(--styled-scrollbar-border-radius,3px);background:var(--styled-scrollbar-thumb-color,#86969f);width:var(--styled-scrollbar-thumb-width,5px);cursor:pointer}.inner-scroll::-webkit-scrollbar-thumb:hover{background:var(--styled-scrollbar-thumb-hover-color,#9fb2bd)}.inner-scroll.has-scroll{margin-right:calc(var(--styled-scrollbar-offset-end,5px) + var(--styled-scrollbar-thumb-width,5px));margin-left:var(--styled-scrollbar-offset-start,0)}.inner-scroll.has-scroll:hover{margin-right:var(--styled-scrollbar-offset-end,5px)}.inner-scroll.has-scroll:hover::-webkit-scrollbar{width:var(--styled-scrollbar-thumb-width,5px);height:var(--styled-scrollbar-thumb-width,5px)}}`;
  }

  private updateScrollIndicator() {
    if (
      this.scrollElem.scrollHeight > this.scrollElem.offsetHeight ||
      this.scrollElem.scrollWidth  > this.scrollElem.offsetWidth
    ) {
      this.scrollElem.classList.add('has-scroll');
    } else {
      this.scrollElem.classList.remove('has-scroll');
    }
  }
}
