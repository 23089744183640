import { Injectable } from '@angular/core';
import { BaseStoreService, IBaseStoreAction, IBaseState } from './base-store.class';
import { ToastService } from '../toast.service';
import { Observable } from 'rxjs';
import { IResponse } from '../../models/response';
import { EventType } from '../../models/common';
import { map, filter } from 'rxjs/operators';
import { getBaseStoreEvent } from './helpers';
import { IMaterial } from '../../models/material';
import { MaterialService } from '../material.service';
import { InviteService } from '../invite.service';
import { isSuccess } from '../../helpers/helpers';

export const INIT_STATE: IBaseState<IMaterial> = { all: [] };

@Injectable({
  providedIn: 'root'
})
export class MaterialStoreService extends BaseStoreService<IMaterial> {

  constructor(
    private materialService: MaterialService,
    protected toastService: ToastService,
    protected inviteService: InviteService,
  ) {
    super(INIT_STATE, toastService, inviteService);
  }

  protected keyExtractor = (material: IMaterial) => {
    return 'all';
  }

  protected getItems$(): Observable<IResponse<IMaterial[]>> {
    return this.materialService.getAll$();
  }

  protected getItem$(id: string): Observable<IResponse<IMaterial>> {
    return this.materialService.getOne$(id);
  }

  protected getUpdates$(): Observable<IBaseStoreAction> {
    return this.materialService.notifier$
      .pipe(
        filter(event => isSuccess(event.response)),
        filter(event => [EventType.ADD, EventType.EDIT, EventType.REMOVE].includes(event.eventType)),
        map(event => ({
          type: getBaseStoreEvent(event.eventType),
          payload: event.response.payload ? event.response.payload : event.entityId
        } as IBaseStoreAction)),
      );
  }
}
