import { of as observableOf, of, throwError } from 'rxjs';
import { BaseService } from './base.service';
import { HttpService } from './http.service';
import { EntityType, EventType } from '../models/common';
import { map, share, switchMap, tap } from 'rxjs/operators';
import { SocketService } from './socket.service';
import { ToastService } from './toast.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./toast.service";
import * as i3 from "./socket.service";
export class MeetingService extends BaseService {
    constructor(httpService, toastService, socketService) {
        super(httpService, toastService);
        this.httpService = httpService;
        this.toastService = toastService;
        this.socketService = socketService;
        this.GET_MEETING_USERS_COUNT = `v1/meetings/users`;
        this.entityType = EntityType.MEETING;
        this.GET_ALL = 'v1/meetings';
        this.POST_ONE = 'v2/meetings';
        this.PUT_ONE = 'v1/meetings';
        this.PATCH_ONE = 'v1/meetings';
        this.MEETINGS_TYPES = 'v1/meeting-type';
        this.PUT_ONE_V2 = (changeAll) => `v2/meetings?changeAll=${changeAll}`;
        this.GET_ONE = (id) => `v1/meetings/${id}`;
        this.GET_ONE_MEETING_TYPE = (id) => `v1/meetings/types/${id}`;
        this.DELETE_ONE = (id) => `v1/meetings/${id}`;
        this.DELETE_ONE_MEETING_TYPE = (id) => `v1/meeting-type/${id}`;
        this.DELETE_ONE_V2 = (id, deleteSeries) => `v2/meetings/${id}?deleteSeries=${deleteSeries}`;
        this.GET_INVITED_USERS_BY_MEETING = (id, page, limit) => `v1/meetings/invited-profiles/${id}?page=${page}&limit=${limit}`;
        this.GET_ALL_BETWEEN_DATES = (from, to) => `v1/meetings/available/from/${from}/to/${to}`;
        this.GET_ALL_BETWEEN_DATES_FOR_INVITED_USER = (from, to) => `v1/meetings/available-invited-user/from/${from}/to/${to}`;
        this.GET_CALENDAR_MARKS_BETWEEN_DATES = (from, to) => `v1/meetings/marks/from/${from}/to/${to}`;
        this.GET_NOT_AVAILABLE_DATA = (userIds, from, to) => `v1/meetings/not-available/from/${from}/to/${to}?users=${JSON.stringify(userIds)}`;
        this.GET_MEETING_STATUS_BY_USER = (meetingId, userId) => `v1/meetings/status-by-user?meetingId=${meetingId}&userId=${userId}`;
        this.PUT_LEAVE_MEETING = (meetingId) => `v1/meetings/${meetingId}/leave`;
        this.socketService.getCrudMessages$([EntityType.MEETING])
            .subscribe(payload => {
            this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId });
        });
    }
    edit$(entity, method = 'patch') {
        const changeAll = entity.changeAll;
        delete entity.changeAll;
        return this.httpService.put$(this.PUT_ONE_V2(changeAll), entity)
            .pipe(tap(response => this.notifyAboutUpdate(EventType.EDIT, response, { entityId: entity['id'] })));
    }
    remove$(entity) {
        const deleteSeries = entity.changeAll;
        delete entity.changeAll;
        return this.httpService.delete$(this.DELETE_ONE_V2(entity['id'], deleteSeries))
            .pipe(tap(response => this.notifyAboutUpdate(EventType.REMOVE, response, { entityId: entity['id'] })));
    }
    getData$(from, to) {
        return this.httpService.get$(this.GET_ALL_BETWEEN_DATES(from, to))
            .pipe(map(response => response.payload ? response.payload : []), share());
    }
    getAvailableMeetingsForInvitedUser$(from, to) {
        return this.httpService.get$(this.GET_ALL_BETWEEN_DATES_FOR_INVITED_USER(from, to))
            .pipe(map(response => response.payload ? response.payload : []), share());
    }
    getCalendarCellData$(from, to) {
        return this.httpService.get$(this.GET_CALENDAR_MARKS_BETWEEN_DATES(from, to))
            .pipe(map(response => response.payload ? response.payload : []), share());
    }
    getTypeParams$() {
        return this.httpService.get$(this.MEETINGS_TYPES)
            .pipe(map(response => response.payload ? response.payload : []));
    }
    getTypeParamById$(id) {
        return this.httpService.get$(this.GET_ONE_MEETING_TYPE(id));
    }
    getPastEvents$(id) {
        return observableOf([]);
    }
    addNewMeetingType$(label, color) {
        return this.httpService.post$(this.MEETINGS_TYPES, { label, color });
    }
    updateMeetingType$(meetingTypeUpdateData) {
        return this.httpService.put$(this.MEETINGS_TYPES, meetingTypeUpdateData);
    }
    deleteMeetingType(meetingTypeId) {
        return this.httpService.delete$(this.DELETE_ONE_MEETING_TYPE(meetingTypeId));
    }
    getInvitedUsers$(meetingId, page, limit) {
        const url = this.GET_INVITED_USERS_BY_MEETING(meetingId, page, limit);
        return this.httpService.get$(url)
            .pipe(map(response => {
            if (response.payload) {
                return Object.assign({}, response, { payload: response.payload.map(value => (Object.assign({}, value.user, { status: value.status }))) });
            }
            return response;
        }));
    }
    getStatusByUser$(meetingId, userId) {
        return this.httpService.get$(this.GET_MEETING_STATUS_BY_USER(meetingId, userId));
    }
    getNotAvailableData$(userIds, from, to) {
        return this.httpService.get$(this.GET_NOT_AVAILABLE_DATA(userIds, from, to));
    }
    getInvitedUsersCount$(meetingId) {
        return this.httpService.get$(this.GET_MEETING_USERS_COUNT, { params: { meetingId } });
    }
    leaveMeeting$(entityId, fromSeries) {
        return this.httpService
            .put$(this.PUT_LEAVE_MEETING(entityId), { fromSeries })
            .pipe(switchMap(res => res.error == null ? of(res) : throwError(new Error(res.message))), tap(response => {
            this.notifyAboutUpdate(EventType.REMOVE, response, { entityId });
        }));
    }
}
MeetingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MeetingService_Factory() { return new MeetingService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.SocketService)); }, token: MeetingService, providedIn: "root" });
