import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PagesGuard } from './auth/guards/pages.guard';
import { AuthPagesGuard } from './auth/guards/auth-pages.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'pages',
        pathMatch: 'full'
    },
    {
        path: 'pages',
        canActivate: [PagesGuard],
        loadChildren: () => import('./pages/pages-routing.module').then(m => m.PagesRoutingModule)
    },
    {
        path: 'auth',
        canActivate: [AuthPagesGuard],
        loadChildren: () => import('./auth/auth-routing.module').then(m => m.AuthRoutingModule)
    },
    {
        path: 'public',
        loadChildren: () => import('./public-pages/public-pages.module').then(m => m.PublicPagesModule)
    },
    {
        path: '**',
        redirectTo: 'pages',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
