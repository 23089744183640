import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Output,
  EventEmitter,
  Input, OnChanges, SimpleChanges
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ColorsGroupComponent } from './colors-group/colors-group.component';
import { COLORS_MEETING, COLORS_DEFAULT, COLORS_SCHEMES, DISPLAY } from './colors';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('button', { static: false, read: ElementRef }) buttonEl: ElementRef;

  @Output() colorSelect = new EventEmitter<string>();
  @Input() initColor?: string;
  @Input() display: string = DISPLAY.ONLY_BUTTON;
  @Input() colorScheme: string = COLORS_SCHEMES.DEFAULT;
  @Input() hidden: boolean = false;
  @Input() title: string = '';
  @Input() disabled: boolean = false;

  colors: string[];
  displays = DISPLAY;
  currentColor: string;

  constructor(
    private popoverCtrl: PopoverController,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initColor && !changes.initColor.firstChange && changes.initColor.currentValue) {
      this.changeColor(changes.initColor.currentValue);
    }
  }

  ngAfterViewInit() {
    this.colors = this.colorScheme === 'default' ? COLORS_DEFAULT : COLORS_MEETING;

    if (this.initColor) {
      this.currentColor = this.initColor;
    } else {
      this.currentColor = this.colors[0];
    }
    this.setColor(this.currentColor);
    this.colorSelect.emit(this.currentColor);
  }

  async showColorsGroup(event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      event,
      showBackdrop: false,
      cssClass: 'color-picker-popover',
      component: ColorsGroupComponent,
      componentProps: {
        currentColor: this.currentColor,
        colors: this.colors,
        onDismiss: (color) => {
          this.changeColor(color);
          popover.dismiss();
        },
      },
    });
    popover.present();
  }

  private changeColor(color: string): void {
    this.removeColor(this.currentColor);
    this.currentColor = color;
    this.setColor(this.currentColor);
    this.colorSelect.emit(this.currentColor);
  }

  private setColor(color: string) {
    this.renderer.setProperty(this.buttonEl.nativeElement, 'style', `--background : ${color}`);
  }

  private removeColor(color: string) {
    this.renderer.setProperty(this.buttonEl.nativeElement, 'style', `--background : none`);
  }

}


