import { Component, OnInit, Input, HostListener, ElementRef, forwardRef, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISelectOption } from './models';
import { detectChanges } from '../../helpers/helpers';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectInputComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() options: ISelectOption[] = [];
  @Input() placeholder: string = 'common.select-type';
  @Input() allSelectedPlaceholder: string = 'common.all-selected';
  @Input() showPlaceholderIcon: boolean = false;
  @Input() multiSelect: boolean = false;
  @Input() disable: boolean = false;
  @Input() showNoneValue: boolean = true;
  @Input() toggleAllId?: string;

  isOpen: boolean = false;
  selectedOptions: ISelectOption[] = [];
  selectedOptionsFlag: Map<string, number> = new Map();

  private selectedValues: string[] = [];

  constructor(
    public cdr: ChangeDetectorRef,
    private eRef: ElementRef,
  ) { }

  ngOnInit() {}

  ngOnChanges() {
    if (this.options) {
      this.selectedOptions = this.getSelectedOptions(this.options, this.selectedValues);
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.isOpen) {
      this.toggleOpen();
    }
  }

  getPlaceholderIcon(): string {
    if (!this.selectedOptions || !this.selectedOptions.length) {
      return '';
    }

    return this.selectedOptions[0].icon
      ? this.selectedOptions[0].icon
      : '';
  }

  getPlaceholderValue(): string {
    const selectedOptions = this.selectedOptions.filter(opt => opt.value !== this.toggleAllId);

    if (!this.options || !this.selectedOptions || !this.selectedOptions.length) {
      return '';
    }
    if (this.multiSelect) {
      return this.selectedOptions.length === this.options.length
        ? this.allSelectedPlaceholder
        : selectedOptions[0].title;
    }

    return this.selectedOptions[0].title === 'common.none-selected'
      ? this.showNoneValue
        ? selectedOptions[0].title
        : null
      : selectedOptions[0].title;
  }

  getPlaceholderSuffix(): string {
    if (this.multiSelect && this.selectedOptions.length > 1 && this.selectedOptions.length < this.options.length) {
      return ',...';
    }
    return '';
  }

  hasOption(option: ISelectOption): boolean {
    if (!this.selectedOptions) {
      return false;
    }
    return !!this.selectedOptionsFlag.get(option.value);
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    detectChanges(this.cdr);
  }

  select(option: ISelectOption) {
    let selectedValues: string[];
    if (this.multiSelect) {
      if (option.value === this.toggleAllId) {
          if (this.hasOption(option)) {
            selectedValues = [];
            this.unselectAllOptions();
          } else {
            this.markAllAsSelected();
            selectedValues = this.options.map(opt => opt.value);
          }
      } else {
        selectedValues = this.getSelectedValuesMultiple(option);
      }
    } else if (!this.hasOption(option)) {
      selectedValues = [ option.value ];
      this.selectedOptionsFlag.clear();
      this.selectedOptionsFlag.set(option.value, 1);
    }

    if (selectedValues) {
      this.writeValue(selectedValues);
      this.onTouched();
      if (!this.multiSelect) {
        this.toggleOpen();
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(values: string[]): void {
    if (!values || (this.selectedOptions && this.isSameValues(this.selectedOptions.map(ops => ops.value), values))) {
      return;
    }
    this.selectedValues = values.slice();
    if (this.options) {
      this.selectedOptions = this.getSelectedOptions(this.options, this.selectedValues);
      this.setSelectedOptionsFlag(this.selectedValues);
      if (this.selectedOptions) {
        this.onChange(this.selectedOptions.map(option => option.value));
      }
    }
  }

  private getSelectedOptions(options: ISelectOption[], values: string[]): ISelectOption[] {
    const selectedOptions: ISelectOption[] = [];
    options
      .forEach(option => {
        const index = values.findIndex(value => option.value === value);
        if (index !== -1) {
          selectedOptions.push({ ...option });
        }
      });

    return selectedOptions.slice();
  }

  private isSameValues(valueA: string[], valueB: string[]): boolean {
    return valueA.toString() === valueB.toString();
  }

  private markAllAsSelected(): void {
    this.options.forEach((opt, index) => {
      this.selectedOptionsFlag.set(opt.value, index);
    });
  }

  private unselectAllOptions(): void {
    this.selectedOptionsFlag =  new Map();
  }

  private getSelectedValuesMultiple(option: ISelectOption): string[] {

    const selectedValues: string[] = this.selectedValues.slice();
    if (this.hasOption(option)) {
      selectedValues.splice(this.selectedOptionsFlag.get(option.value) - 1, 1);
      this.selectedOptionsFlag.delete(option.value);
    } else {
      selectedValues.push(option.value);
      this.selectedOptionsFlag.set(option.value, selectedValues.length);
    }

    if (option.value !== this.toggleAllId) {
      if (this.shouldToggleAllOption(selectedValues)) {
        selectedValues.push(this.toggleAllId);
        this.selectedOptionsFlag.set(this.toggleAllId, selectedValues.length);
      } else {
        if (selectedValues.includes(this.toggleAllId)) {
          const indexOfAll = selectedValues.findIndex(opt => opt === this.toggleAllId);
          selectedValues.splice(indexOfAll, 1);
          this.selectedOptionsFlag.delete(this.toggleAllId);
        }
      }
    }

    return selectedValues;
  }

  private shouldToggleAllOption(selectedValues: string[]): boolean {
    const optionsLength = this.options.filter(opt => opt.value !== this.toggleAllId).length;
    return optionsLength === selectedValues.filter(opt => opt !== this.toggleAllId).length;
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};

  private setSelectedOptionsFlag(values: string[]): void {
    values.forEach((value, index) => {
      this.selectedOptionsFlag.set(value, index + 1);
    });
  }

}
