import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingFrequencySelectorComponent } from './meeting-frequency-selector.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SelectInputModule } from '../select-input/select-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TimePickerModule } from '../time-picker/time-picker.module';
import { PipesModule } from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    MeetingFrequencySelectorComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SelectInputModule,
    ReactiveFormsModule,
    TimePickerModule,
    PipesModule,
  ],
  exports: [
    MeetingFrequencySelectorComponent
  ]
})
export class MeetingFrequencySelectorModule { }
