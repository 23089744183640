<div class="content">
  <ion-card class="custom-range-modal">
    <ion-card-header>
      <ion-item class="header" lines="none">
        <ion-label>{{ 'create-menu.grade-create-rande-title' | translate }}</ion-label>
      </ion-item>
    </ion-card-header>
    <ion-card-content>
      <ion-item class="input">
        <ion-input autofocus [(ngModel)]="generateFrom" placeholder="{{ 'create-menu.grade-from' | translate }}"></ion-input>
      </ion-item>
      <ion-item class="input">
        <ion-input [(ngModel)]="generateTo"  placeholder="{{ 'create-menu.grade-to' | translate }}"></ion-input>
      </ion-item>
      <span class="error-message" *ngIf="showGenerateError">{{ 'create-menu.grade-generate-invalid-data' | translate }}</span>
      <ion-item lines="none">
        <ion-button mode="md" (click)="generateGradings()">{{ 'invitation.generate-btn' | translate }}</ion-button>
      </ion-item>
    </ion-card-content>
  </ion-card>
</div>