import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File, FileEntry, DirectoryEntry } from '@ionic-native/file/ngx';
import { getFileExtension } from '../helpers/helpers';
import { ALLOWED_IMAGES_FORMATS, ALLOWED_VIDEOS_FORMATS } from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class DownloadLocallyService {

  private readonly DIR_NAME = 'attachments';

  constructor(
    private platformService: PlatformService,
    private file: File,
    private fileOpener: FileOpener,
  ) { }

  async download(name: string, arrayBuffer: ArrayBuffer): Promise<void> {
    const mimeType = this.getMimeType(name);
    if (mimeType) {
      if (this.platformService.isNativeiOS || this.platformService.isNativeiPadOS) {
        await this.downloadLocallyiOS(arrayBuffer, mimeType, name);
      } else {
        this.downloadLocallyWeb(arrayBuffer, mimeType, name);
      }
    }
  }

  private async downloadLocallyiOS(arrayBuffer: ArrayBuffer, mimeType: string, name: string): Promise<void> {
    const dirEntry: DirectoryEntry = await this.getDirectory();
    const fileEntry: FileEntry =  await this.saveFile(dirEntry, arrayBuffer, mimeType, name);
    this.openFileAs(fileEntry, mimeType);
  }

  private downloadLocallyWeb(arrayBuffer: ArrayBuffer, mimeType: string, name: string): void {
    const blob = new Blob([arrayBuffer], { type: mimeType });
    const a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.setAttribute('download', name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private getMimeType(name: string): string | null {
    const extension = getFileExtension(name.toLowerCase()).toLowerCase();
    if (extension === 'pdf') {
      return 'application/pdf';
    } else if (extension === 'txt') {
      return 'text/plain';
    } else if (extension === 'docx') {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (extension === 'doc') {
      return 'application/msword';
    } else if (this.isSameFormat(ALLOWED_VIDEOS_FORMATS, extension)) {
      return `video/${extension}`;
    } else if (this.isSameFormat(ALLOWED_IMAGES_FORMATS, extension)) {
      return `image/${extension}`;
    }
  }

  private async getDirectory(): Promise<DirectoryEntry> {
    const mainDir: string = this.file.tempDirectory;
    const isDirExists = await this.file.checkDir(mainDir, this.DIR_NAME)
      .catch(() => false);
    if (isDirExists) {
      await this.file.removeRecursively(mainDir, this.DIR_NAME);
    }
    return this.file.createDir(mainDir, this.DIR_NAME, true);
  }

  private async saveFile(dirEntry: DirectoryEntry, arrayBuffer: ArrayBuffer, mimeType: string, name: string): Promise<FileEntry> {
    const blob = new Blob([arrayBuffer], { type: mimeType });
    return this.file.writeFile(dirEntry.nativeURL, name, blob, { replace: true });
  }

  private openFileAs(fileEntry: FileEntry, mimeType: string): Promise<any> {
    return this.fileOpener.open(fileEntry.nativeURL, mimeType);
  }

  private isSameFormat(extensions: string[], ext: string): boolean {
    return extensions.map(extension => extension.toLowerCase()).indexOf(ext.toLowerCase()) !== -1;
  }
}
