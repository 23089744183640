import * as tslib_1 from "tslib";
import { EntityType, ALLOWED_IMAGES_FORMATS, EventType, ALLOWED_VIDEOS_FORMATS, ALLOWED_FILES_FORMATS } from '../models/common';
import { StudentTaskState } from '../models/ticket';
import * as moment from 'moment';
import { NodeType } from '../components/tree-menu/models/node_type';
import { NEVER as OBSERVABLE_NEVER, Observable } from 'rxjs';
import { TOAST_TYPE } from '../services/toast.service';
import { MeetingFrequency } from '../models/meeting';
import { first, filter, takeUntil } from 'rxjs/operators';
import { MOBILE_WINDOW_LANDSCAPE_WIDTH, TABLET_WINDOW_LANDSCAPE_WIDTH } from '../services/window.service';
import { FileType } from '../models/file-attach';
import { HttpErrorResponse } from '@angular/common/http';
export function isHttpErrorResponse(response) {
    return response.error instanceof HttpErrorResponse;
}
const ADD_ONE_DASHBOARD_COLUMN = (id, type) => `v1/columns?entityId=${id}&entityType=${type}`;
const ɵ0 = ADD_ONE_DASHBOARD_COLUMN;
const EDIT_ONE_DASHBOARD_COLUMN = (id, type) => `v1/columns?columnId=${id}&entityType=${type}`;
const ɵ1 = EDIT_ONE_DASHBOARD_COLUMN;
const REMOVE_ONE_DASHBOARD_COLUMN = (id, type) => `v1/columns?columnId=${id}&entityType=${type}`;
const ɵ2 = REMOVE_ONE_DASHBOARD_COLUMN;
export function hasError(control, error = 'required') {
    return (control.dirty && control.touched) && control.invalid && control.hasError(error);
}
export function detectChanges(cdf) {
    if (!cdf['destroyed']) {
        cdf.detectChanges();
    }
}
export function getAssignedTasksCount(task) {
    const total = task.assignedTasks.length;
    const waitingCount = task.assignedTasks
        .filter(t => t.state === StudentTaskState.WAITING_FOR_CHECK || t.state === StudentTaskState.CHECKED_AND_FINISHED).length;
    const checkedCount = task.assignedTasks
        .filter(t => t.state === StudentTaskState.CHECKED_AND_FINISHED).length;
    return {
        total,
        [StudentTaskState.WAITING_FOR_CHECK]: waitingCount,
        [StudentTaskState.CHECKED_AND_FINISHED]: checkedCount,
        waitingPercent: waitingCount / total,
        checkedPercent: checkedCount / total,
    };
}
export function canShowAssignedTasksScales(task) {
    return task && task.ticket && task.ticket.id && !task.ticket.should_not_verificate && task.assignedTasks && !!task.assignedTasks.length;
}
export function getItemForTicketContextMenu(item, entityType) {
    if (item && entityType) {
        if (entityType === EntityType.TICKET_TASK) {
            return item.ticket;
        }
        else if (entityType === EntityType.ASSIGNED_TASK) {
            return item.assignedTask;
        }
        return item;
    }
    return null;
}
export function isSuccess(response) {
    return !response.error && !!response.payload;
}
export function getDaysRange(startDate, endDate) {
    const dates = [];
    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');
    dates.push(currDate.clone().toISOString());
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toISOString());
    }
    dates.push(lastDate.clone().toISOString());
    return dates;
}
export function getDate(date) {
    return moment(date).format('DD.MM.YYYY');
}
export function getDayName(date) {
    return moment(date).format('dddd');
}
export function isTodayDate(date) {
    return moment(date).isSame(moment().startOf('day'), 'd');
}
export function getStartDate(dateRange) {
    return dateRange ? dateRange[0] : '';
}
export function getEndDate(dateRange) {
    return dateRange && dateRange.length > 0 ? dateRange[dateRange.length - 1] : '';
}
export function getEntityTypeFromNode(nodeType) {
    switch (nodeType) {
        case NodeType.SCHOOL:
            return EntityType.SCHOOL;
        case NodeType.CLASS:
            return EntityType.CLASS;
        case NodeType.SUBJECT:
            return EntityType.SUBJECT;
        case NodeType.MATERIAL:
            return EntityType.MATERIAL;
        default:
            return null;
    }
}
export function isImage(name) {
    if (!name) {
        return false;
    }
    const ext = /(?:\.([^.]+))?$/.exec(name)[1].toLocaleLowerCase();
    return ALLOWED_IMAGES_FORMATS.includes(ext);
}
export function isVideo(name) {
    if (!name) {
        return false;
    }
    const ext = /(?:\.([^.]+))?$/.exec(name)[1].toLocaleLowerCase();
    return ALLOWED_VIDEOS_FORMATS.includes(ext);
}
export function isFile(name) {
    if (!name) {
        return false;
    }
    const ext = /(?:\.([^.]+))?$/.exec(name)[1].toLocaleLowerCase();
    return ALLOWED_FILES_FORMATS.includes(ext);
}
export function getFileType(name) {
    let fileType;
    if (isFile(name)) {
        fileType = FileType.DOCUMENT;
    }
    else if (isImage(name)) {
        fileType = FileType.IMAGE;
    }
    else if (isVideo(name)) {
        fileType = FileType.VIDEO;
    }
    return fileType;
}
export function getImageSrc(file, getEndpoint, ticketParent) {
    return getEndpoint(`v1/download-preview/${file.id}${ticketParent ? '?parentType=' + ticketParent : ''}`);
}
export function addDashboardColumn$(column, entityId, entityType, httpService) {
    return httpService.post$(ADD_ONE_DASHBOARD_COLUMN(entityId, entityType), { title: column.title });
}
export function editDashboardColumn$(column, entityType, httpService) {
    return httpService.patch$(EDIT_ONE_DASHBOARD_COLUMN(column.id, entityType), column);
}
export function removeDashboardColumn$(columnId, entityType, httpService) {
    return httpService.delete$(REMOVE_ONE_DASHBOARD_COLUMN(columnId, entityType));
}
export function trackById(index, entry) {
    return entry.id;
}
export function isTheSameDay(dayOne, dayTwo) {
    return moment(dayOne).isSame(dayTwo, 'day');
}
export function getTicketDateForCalendarPages(task) {
    return task.ticket.due_date ? task.ticket.due_date : task.ticket.published_date;
}
export function showErrorIfExists(response, toastService) {
    if (response.error && response.message) {
        toastService.showToast(response.message, TOAST_TYPE.ERROR);
    }
}
export function createResizeObserver(elem) {
    if (!('ResizeObserver' in window)) {
        return OBSERVABLE_NEVER;
    }
    return new Observable(observer => {
        const resizeObserver = new window.ResizeObserver(values => observer.next(values));
        resizeObserver.observe(elem);
        return () => {
            resizeObserver.disconnect();
            observer.complete();
        };
    });
}
export function createMutationObserver(elem, options) {
    if (!('MutationObserver' in window)) {
        return OBSERVABLE_NEVER;
    }
    return new Observable(observer => {
        const mutationObserver = new MutationObserver(values => observer.next(values));
        mutationObserver.observe(elem, options);
        return () => {
            mutationObserver.disconnect();
            observer.complete();
        };
    });
}
export function generateId() {
    const payload = new Uint8Array(7);
    window.crypto.getRandomValues(payload);
    return payload.join('');
}
export function parseHtmlText(value) {
    return new DOMParser().parseFromString(value, 'text/html').documentElement.textContent;
}
export function getFrequencyText(frequency, step, translateService) {
    switch (frequency) {
        case MeetingFrequency.NONE: {
            return translateService.instant('meeting.frequency-none');
        }
        case MeetingFrequency.DAY:
        case MeetingFrequency.WEEK:
        case MeetingFrequency.MONTH: {
            const message = step > 1
                ? translateService.instant(`meeting.frequency-every_n_${frequency}`, { step })
                : translateService.instant(`meeting.frequency-every_${frequency}`);
            return message;
        }
        default: return '';
    }
}
export function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}
export function getFileReader() {
    const fileReader = new FileReader();
    const zoneOriginalInstance = fileReader["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
}
export function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = getFileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (err) => reject(err);
        fileReader.readAsDataURL(file);
    });
}
export function validateArrayControlLength(minLength) {
    return (control) => {
        return Array.isArray(control.value) && control.value.length < minLength
            ? { 'arrayControlLength': { minLength } }
            : null;
    };
}
export function createRecord(data, key) {
    return data.reduce((accum, curr) => {
        accum.set(curr[key], curr);
        return accum;
    }, new Map());
}
/**
 * Run given function after change detection cycle that might occur at the time of sync call
 *
 * Prevents "ExpressionChangedAfterItHasBeenCheckedError" or cases
 * when view(component bindings) deos not reflect model changes.
 */
export function runOnNextTick(zone, cb) {
    zone.onStable.pipe(first()).subscribe(cb);
}
export function entityExists(entity) {
    return entity != null;
}
export function listenForDashboardColumnUpdates(notifier$, entityId, destroy$, updateColumns) {
    notifier$
        .pipe(filter(event => isSuccess(event.response) && event.entityId === entityId &&
        [EventType.ADD_COLUMN, EventType.EDIT_COLUMN, EventType.REMOVE_COLUMN].includes(event.eventType)), takeUntil(destroy$))
        .subscribe(event => {
        updateColumns(event.eventType, event.response.payload);
    });
}
export const forbiddenNameValidator = (nameRe) => {
    return (control) => {
        const forbidden = nameRe.test(control.value);
        return forbidden ? { forbiddenName: { value: control.value } } : null;
    };
};
export function backgroundAsStyle(value, sanitizer) {
    return value ? sanitizer.bypassSecurityTrustStyle(`--background: ${value}`) : null;
}
export const isMobileLandscapeWindow = () => {
    return window.innerWidth <= MOBILE_WINDOW_LANDSCAPE_WIDTH;
};
export const isTabletLandscapeWindow = () => {
    return window.innerWidth <= TABLET_WINDOW_LANDSCAPE_WIDTH;
};
export const timeZone = () => {
    return (new Date().getTimezoneOffset() / -60) * 60;
};
export const getUserWithAvatar = (user, sanitizer) => tslib_1.__awaiter(this, void 0, void 0, function* () {
    let avatar = '';
    if (typeof user.avatarUrl === 'string') {
        avatar = user.avatarUrl;
        user.avatarUrl = sanitizer.bypassSecurityTrustUrl(avatar);
    }
    else if (user.avatarUrl !== null && user.avatarUrl.data) {
        const blob = new Blob([new Uint8Array(user.avatarUrl.data).buffer]);
        avatar = yield fileToBase64(blob);
        user.avatarUrl = sanitizer.bypassSecurityTrustUrl(avatar);
    }
    else if (!user.avatarUrl) {
        user.avatarUrl = sanitizer.bypassSecurityTrustUrl('assets/img/boy-placeholder.svg');
    }
    return user;
});
export const isRealString = (value) => {
    return typeof value === 'string' && value.trim().length > 0;
};
export function changeKeyboardEnterLogic(keyboardModule) {
    // '13' is the key of Enter button. 4 is an index of binding Enter + Shift combination.
    const handleEnter = keyboardModule.bindings['13'][4].handler;
    keyboardModule.bindings['13'][4] = {
        key: 13,
        shiftKey: true,
        handler: handleEnter,
    };
}
/** Bumps given date to `limit` date preserving hours and minutes of original date */
export function bumpToArbituaryFutureDate(date, limit = new Date(2030, 0, 1)) {
    const dateMoment = moment(date);
    return moment(limit)
        .set('hours', dateMoment.get('hours'))
        .set('minutes', dateMoment.get('minutes'))
        .toISOString();
}
export function setStraightTime(date, timeParamsOrHours = 0, minutes = 0) {
    if (typeof timeParamsOrHours === 'object') {
        const timeParams = Object.assign({ minutes: 0, hours: 0 }, timeParamsOrHours);
        return date.clone().set(Object.assign({}, timeParams, { s: 0, ms: 0 }));
    }
    return date.clone().set({ hours: timeParamsOrHours, minutes, s: 0, ms: 0 });
}
export function getTextFileBlob(content) {
    return new Blob([content], { type: 'text/plain;charset=utf-8' });
}
export function base64ToArrayBuffer(base64) {
    const binaryStr = window.atob(base64);
    const len = binaryStr.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryStr.charCodeAt(i);
    }
    return bytes.buffer;
}
export const URL_REGEXP = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
export function isValidURL(url) {
    return isRealString(url) && (url.startsWith('http') || url.startsWith('https')) && URL_REGEXP.test(url);
}
export { ɵ0, ɵ1, ɵ2 };
