import { Component, EventEmitter, Input, OnInit, Output, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import { concat, Observable, of } from 'rxjs';
import { ChatRemovedEvent, IChatStream, IStreamId } from 'src/app/shared/models/chat';
import { selectChatStreamInfo, selectChatStreamMembers } from 'src/app/store/selectors/chat.selectors';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { ChatUiService } from 'src/app/shared/services/chat-ui.service';
import { ZulipService } from 'src/app/shared/services/zulip.service';

@Component({
  selector: 'app-chat-log-page',
  templateUrl: './chat-log.page.html',
  styleUrls: ['./chat-log.page.scss'],
})
export class ChatLogPage implements OnInit {
  @Input() selectedChannelId: IStreamId;

  @Output() back = new EventEmitter<void>();
  @Output() search = new EventEmitter<void>();
  @Output() expand = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();
  
  public selectedChannel$: Observable<IChatStream>;
  public displayChatRemovedNotice$: Observable<boolean>;

  @HostBinding('class.desktop-view') get t() {
    return this.platformService.isDesktop;
  }

  constructor(
    private store: Store,
    private platformService: PlatformService,
    private chatUiService: ChatUiService,
    private zulipService: ZulipService,
  ) { }

  ngOnInit() {
    this.selectedChannel$ = this.store.select(selectChatStreamInfo, this.selectedChannelId).pipe(
      withLatestFrom(this.zulipService.currentUser$),
      switchMap(([ stream, currentUser ]) =>
        this.store.pipe(
          selectChatStreamMembers(stream.stream_id),
          map(members =>
            ({
              ...stream,
              members: members.filter(user => user.zulip_uid !== currentUser.user_id)
            })
          ),
        )
      )
    );
    
    const displayChatRemovedNotice$ = this.chatUiService.events$
      .pipe(
        filter(event => event instanceof ChatRemovedEvent && event.streamId === this.selectedChannelId),
        mapTo(true)
      );
    this.displayChatRemovedNotice$ = concat(of(false), displayChatRemovedNotice$);
  }

  openConference() {
    this.chatUiService.openConference(this.selectedChannelId);
  }

}
