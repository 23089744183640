import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatInfoComponent } from './chat-info.component';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { TranslateModule } from '@ngx-translate/core';
import { IsChatUserOnlinePipeModule } from '../../pipes/is-chat-user-online/is-chat-user-online.module';
import { ChatMembersListModule } from '../chat-members-list/chat-members-list.module';
import { ChatAttachmentsModule } from '../chat-attachments/chat-attachments.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    StyledScrollbarModule,
    IsChatUserOnlinePipeModule,
    ChatMembersListModule,
    ChatAttachmentsModule,
  ],
  declarations: [ChatInfoComponent],
  exports: [ChatInfoComponent]
})
export class ChatInfoComponentModule {}
