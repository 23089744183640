import { Injectable, isDevMode } from '@angular/core';
import { PROD_API_CONFIG } from 'src/environments/api/prod.config';
import { STAGE_API_CONFIG } from 'src/environments/api/stage.config';
import { environment } from 'src/environments/environment';
import { EnvironmentMode } from 'src/environments/models';
import { IAppApiConfig } from 'src/environments/models';
@Injectable({
    providedIn: 'root'
})
export class EnvService {
    public apiConfig: IAppApiConfig = {
        apiEndpoint: environment.apiEndpoint,
        websocketUrl: environment.websocketUrl,
        janusSignalingUrl: environment.janusSignalingUrl,
        groupworldEndpoint: environment.groupworldEndpoint,
        zulipInstanceEndpoint: environment.zulipInstanceEndpoint,
        zulipApiEndpoint: environment.zulipApiEndpoint,
    };

    private readonly defaultDevEnvironmentMode: EnvironmentMode = EnvironmentMode.Stage;

    constructor() {
        this.initEnvs();
    }

    get serverUrl(): string {
        if (this.apiConfig.groupworldEndpoint) {
            return this.apiConfig.groupworldEndpoint.slice(0, this.apiConfig.groupworldEndpoint.indexOf('/conf'));
        }
        return window.origin;
    }

    private initEnvs(): void {
        let apiConfig: IAppApiConfig;
        if (isDevMode()) {
            apiConfig = this.defaultDevEnvironmentMode === EnvironmentMode.Stage ? STAGE_API_CONFIG : PROD_API_CONFIG
            this.apiConfig = apiConfig;
        }
        const displayEnv = apiConfig
            ? { ...environment, ...apiConfig }
            : environment;
        console.log('%c [ENVIRONMENT]: ', 'color: green', displayEnv);
    }
}
