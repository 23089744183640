import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MeetingAttendeeComponent } from './meeting-attendee.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';



@NgModule({
  declarations: [
    MeetingAttendeeComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    PipesModule,
  ],
  exports: [
    MeetingAttendeeComponent,
  ],
})
export class MeetingAttendeeModule { }
