<ion-header>
  <ion-toolbar color="primary" mode="ios">
    <ion-title>#&nbsp;{{ title$ | async }}</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="back.next()">
        <ion-icon slot="icon-only" name="chevron-back-outline" size="small"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content styledScrollbar="shadow-root" appRemoveContentSizing>
  <div styledScrollbarContent>
    <app-chat-info [selectedChannelId]="selectedChannelId"></app-chat-info>
  </div>
</ion-content>
