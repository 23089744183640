import { DashboardPermissionsAbstract, TicketPermissionsAbstract } from './base.abstract.class';
import { UserRole } from '../../models/common';
import { isPublished } from '../../components/ticket-item/helpers';
import { StudentTaskState, TeacherTaskState, TicketType } from '../../models/ticket';
export class SubjectDashboardPermissions extends DashboardPermissionsAbstract {
    constructor() {
        super(...arguments);
        this.NOT_EDITED_COLUMN_TITLES = [
            'task', 'tasks', 'general', 'generals', 'announcements', 'announcement', 'notification', 'notifications',
        ];
    }
    canEditEntity(subject) {
        return this.isOwner(subject);
    }
    canRemoveEntity(subject) {
        return this.isOwnerOrInvitedTeacher(subject);
    }
    canAddColumn(subject) {
        return this.isOwnerOrInvitedTeacher(subject);
    }
    canEditOrRemoveColumn(subject, column) {
        if (this.NOT_EDITED_COLUMN_TITLES.includes(column.title.toLowerCase())) {
            return false;
        }
        return this.isOwnerOrInvitedTeacher(subject);
    }
    canAddTicketsToColumn(subject, column) {
        return this.isOwnerOrInvitedTeacher(subject);
    }
    canCreateInvitation(subject) {
        return this.isOwnerOrInvitedTeacher(subject);
    }
    canInviteStudents(subject) {
        return this.isOwnerOrInvitedTeacher(subject);
    }
    canRemoveStudentOrTeacher(subject) {
        return this.isOwner(subject);
    }
}
export class SubjectTicketPermissions extends TicketPermissionsAbstract {
    constructor() {
        super(...arguments);
        this.CAN_EDIT_DUE_DATE_STATUS = [
            'in_progress', 'to_check', 'draft'
        ];
    }
    canEditOrRemoveEntity(entity) {
        if (this.isNewTicket(entity)) {
            return true;
        }
        else if (entity.type === TicketType.TASK && entity.state === TeacherTaskState.DONE) {
            return false;
        }
        return this.isOwnerOrInvitedTeacher(entity);
    }
    canAddAttachments(entity) {
        if (this.isNewTicket(entity)) {
            return true;
        }
        else if (entity.type === TicketType.TASK && entity.state === TeacherTaskState.DONE) {
            return false;
        }
        else if (entity.type !== TicketType.TASK && this.isStudent(entity)) {
            return false;
        }
        return this.isOwnerOrInvitedTeacher(entity) || this.isStudent(entity);
    }
    canRemoveAttachments(entity) {
        return this.canEditOrRemoveEntity(entity);
    }
    canEditDate(entity) {
        return !isPublished(entity) && this.canEditOrRemoveEntity(entity);
    }
    canEditState(entity, assignedTask) {
        if (this.isStudent(entity)) {
            return assignedTask
                ? assignedTask.state !== StudentTaskState.CHECKED_AND_FINISHED
                : false;
        }
        return this.isOwnerOrInvitedTeacher(entity);
    }
    canEditSubjectOrDate(entity) {
        return this.canEditDate(entity);
    }
    canEditSubjectDueDate(entity) {
        return entity.role === UserRole.TEACHER && this.CAN_EDIT_DUE_DATE_STATUS.includes(entity.state);
    }
}
