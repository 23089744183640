import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { shareReplay, takeUntil } from "rxjs/operators";

type IsVisible = boolean;

export interface VisibilityState {
  [itemId: string]: IsVisible;
}

@Injectable()
export class ChatLogControllerService {
  private destroy$ = new Subject();
  private _visibilityState = new Subject<VisibilityState>();
  readonly visibilityStateChange$ = this._visibilityState.asObservable().pipe(
    shareReplay(1),
    takeUntil(this.destroy$),
  );

  updateVisibilityState(nextState: VisibilityState) {
    this._visibilityState.next(nextState);
  }

  complete() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}