import { Injectable } from '@angular/core';

import { Socket } from 'ngx-socket-io';
import { UserService } from 'src/app/auth/services/user.service';
import { EnvService } from './env/env.service';
import { Subject, Observable } from 'rxjs';
import { tap, share, takeUntil, filter, mergeMap, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ISocketMessage, ICrudMessage, SocketMessageType, EntityType } from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private socket: Socket;
  private messageEmitter$: Subject<ISocketMessage> = new Subject();
  private destroy$ = new Subject();
  private topic = (id: string) => `/topic/user/${id}`;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private envService: EnvService
  ) { }

  connect(): void {
    if (this.socket == null) {
      this.socket = new Socket({ url: this.envService.apiConfig.websocketUrl, options: { query: { token: this.authService.jwtToken }, transports: [ 'websocket' ] } });
      this.userService.getUser$()
        .pipe(
          filter(user => !!user && this.authService.isAuthenticated),
          mergeMap(user => this.socket.fromEvent<ISocketMessage>(this.topic(user.id))),
          tap(data => console.log('%c SOCKET MESSAGE: ', 'color: cyan', data)),
          takeUntil(this.destroy$),
        )
        .subscribe(message => this.messageEmitter$.next(message));
    }
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket.removeAllListeners();
      this.destroy$.next();
      this.socket = null;
    }
  }

  messages$(): Observable<ISocketMessage> {
    return this.messageEmitter$.pipe(share());
  }

  getCrudMessages$(entityTypes: EntityType[]): Observable<ICrudMessage> {
    return this.messages$()
      .pipe(
        filter(() => this.authService.isAuthenticated),
        filter(message =>
          message.type === SocketMessageType.CRUD &&
          entityTypes.includes((message.payload as ICrudMessage).entityType)
        ),
        map(message => message.payload as ICrudMessage),
      );
  }

  isConnected(): boolean {
    if (this.socket && this.socket.ioSocket) {
      return this.socket.ioSocket.connected;
    }
    return false;
  }
}
