import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FileUploadDialogComponent } from './file-upload-dialog.component';



@NgModule({
  declarations: [FileUploadDialogComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [FileUploadDialogComponent],
})
export class FileUploadDialogModule { }
