import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { INotificationMessage, IBaseNotification, ITicketBaseNotification, ITicketTaskNotification, NotificationType } from '../models/notifications';
import { shareReplay, take, tap, filter, pluck, map, bufferTime, concatMap, takeUntil, withLatestFrom, switchMap, exhaustMap, mapTo } from 'rxjs/operators';
import { IonInfiniteScroll } from '@ionic/angular';
import { HttpService } from './http.service';
import { IResponse } from '../models/response';
import { showErrorIfExists, isSuccess } from '../helpers/helpers';
import { ToastService } from './toast.service';
import { SocketService } from './socket.service';
import { SocketMessageType, EntityType, EventType, ICrudMessage, IStudentTask } from '../models/common';
import { getTicket } from '../components/ticket-item/helpers';
import { ITicket } from '../models/ticket';

const cloneDeep = require('lodash.clonedeep');

@Injectable({
  providedIn: 'root'
})
export class NotificationSidebarService {
  private readonly LIMIT = 20;
  private pageToLoad = 1;
  private infiniteScroll: IonInfiniteScroll;
  private notifications$ = new BehaviorSubject<INotificationMessage<IBaseNotification>[]>(null);
  private unreadedCount$ = new BehaviorSubject<number>(0);
  private markAsReadEmitter$ = new Subject<INotificationMessage<IBaseNotification>>();
  private loadedMoreEmitter$ = new Subject<INotificationMessage<IBaseNotification>[]>();
  private sidebarState$ = new BehaviorSubject<'in' | 'out'>('out');
  private destroy$ = new Subject();
  private loadUnreadedCountEmitter$: Subject<void> = new Subject();
  private subtractUnreadedCountEmitter$: Subject<number> = new Subject();
  private markAllAsReadEmitter$: Subject<void> = new Subject();
  private readAllNotifications$: Subject<void> = new Subject();

  constructor(
    private httpService: HttpService,
    private toastService: ToastService,
    private socketService: SocketService,
  ) { }

  getNotifications$(): Observable<INotificationMessage<IBaseNotification>[]> {
    return this.notifications$.asObservable()
      .pipe(shareReplay(1));
  }

  getUnreadCount$(): Observable<number> {
    return this.unreadedCount$.asObservable();
  }

  getSidebarState$(): Observable<'in' | 'out'> {
    return this.sidebarState$.asObservable();
  }

  init(infiniteScroll: IonInfiniteScroll): void {
    this.infiniteScroll = infiniteScroll;
    this.infiniteScroll.disabled = true;
    this.listenForNotificationsDataChanges();
    this.listenForUnreadedDataChanges();
  }

  destroy(): void {
    this.notifications$.next(null);
    this.unreadedCount$.next(0);
    this.destroy$.next();
  }

  loadMoreNotifications(): void {
    this.pageToLoad += 1;
    this.loadNotifications$(this.pageToLoad)
      .pipe(
        tap(response => {
          showErrorIfExists(response, this.toastService);
          if (response.error) {
            this.pageToLoad -= 1;
            this.infiniteScroll.complete();
          }
          if (response.payload.length === 0) {
            this.pageToLoad -= 1;
          }
        }),
        filter(response => isSuccess(response)),
        pluck('payload'),
        take(1),
      )
      .subscribe(notifications => this.loadedMoreEmitter$.next(notifications));
  }

  markAsRead(message: INotificationMessage<any>): void {
    this.markAsReadEmitter$.next(message);
  }

  toggle(): void {
    const currSidebarState = this.sidebarState$.value;
    this.sidebarState$.next(currSidebarState === 'in' ? 'out' : 'in');
  }

  markAllAsRead(): void {
    this.markAllAsReadEmitter$.next();
  }

  private loadNotifications$(page: number): Observable<IResponse<INotificationMessage<IBaseNotification>[]>> {
    const url = `v1/notifications?page=${page}&limit=${this.LIMIT}`;
    return this.httpService.get$(url);
  }

  private hasMoreNotifications(data: INotificationMessage<IBaseNotification>[]): boolean {
    return data.length === this.LIMIT;
  }

  private markAsRead$(messages: INotificationMessage<any>[]): Observable<INotificationMessage<IBaseNotification>[]> {
    const url = 'v1/notifications';
    const data = messages.map(message => ({ id: message.id, is_read: true }));
    return this.httpService.patch$<INotificationMessage<IBaseNotification>[]>(url, data)
      .pipe(
        map(response => response.payload ? response.payload : []),
      );
  }

  private addNewNotification(message: INotificationMessage<IBaseNotification>): INotificationMessage<IBaseNotification>[] {
    const currentMessages: INotificationMessage<IBaseNotification>[] = cloneDeep(this.notifications$.value);
    currentMessages.unshift(message);
    return currentMessages;
  }

  private addMoreNotifications(messages: INotificationMessage<IBaseNotification>[]): INotificationMessage<IBaseNotification>[] {
    const currentMessages: INotificationMessage<IBaseNotification>[] = cloneDeep(this.notifications$.value);
    currentMessages.push(...messages);
    return currentMessages;
  }

  private updateNotifications(messages: INotificationMessage<IBaseNotification>[]): INotificationMessage<IBaseNotification>[] {
    const currentMessages: INotificationMessage<IBaseNotification>[] = cloneDeep(this.notifications$.value);
    messages
      .forEach(message => {
        const index = currentMessages.findIndex(msg => msg.id === message.id);
        if (index !== -1) {
          currentMessages[index] = message;
        }
      });
    return currentMessages;
  }

  private listenForUnreadedDataChanges(): void {
    const substractCount$ = this.subtractUnreadedCountEmitter$
      .pipe(
        filter(value => value && value > 0),
        map(value => {
          const currentUnreadedCount: number = this.unreadedCount$.getValue();
          return currentUnreadedCount - value;
        })
      );

    const markAllAsRead$ = this.markAllAsReadEmitter$
        .pipe(
          exhaustMap(() => this.markAllAsReadReq$()),
          filter(response => isSuccess(response)),
          tap(() => this.readAllNotifications$.next()),
          mapTo(0),
        );

    merge(this.loadUnreadedCount$(), substractCount$, markAllAsRead$)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(count => this.unreadedCount$.next(count));
  }

  private listenForNotificationsDataChanges(): void {
    this.getNotificationsData$()
      .pipe(
        tap(data => {
          if (this.hasMoreNotifications(data)) {
            this.infiniteScroll.disabled = false;
          }
          if (data) {
            this.notifications$.next(data);
          }
          this.infiniteScroll.complete();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getNotificationsData$(): Observable<INotificationMessage<IBaseNotification>[]> {
    const init$ = this.loadNotifications$(this.pageToLoad)
      .pipe(
        tap(response => showErrorIfExists(response, this.toastService)),
        filter(response => isSuccess(response)),
        tap(() => this.loadUnreadedCountEmitter$.next()),
        pluck('payload'),
        take(1),
      );

    const newNotifications$ = this.socketService.messages$()
      .pipe(
        filter(msg => msg.type === SocketMessageType.NOTIFICATIONS),
        map(msg => msg.payload),
        map((notification: INotificationMessage<IBaseNotification>) => this.addNewNotification(notification)),
        tap(() => this.loadUnreadedCountEmitter$.next()),
      );

    const readUpdates$ = this.markAsReadEmitter$
      .pipe(
        bufferTime(2000),
        filter(values => values.length > 0),
        concatMap(notifications => this.markAsRead$(notifications)),
        tap(notifications => {
          if (notifications && notifications.length) {
            this.subtractUnreadedCountEmitter$.next(notifications.length);
          }
        }),
        map(notifications => this.updateNotifications(notifications)),
      );

    const loadedMore$ = this.loadedMoreEmitter$
      .pipe(
        map(notifications => this.addMoreNotifications(notifications)),
      );

    const removeUpdates$ = merge(this.getAssignedTaskUpdates$(), this.getTicketUpdates$(), this.getMeetingUpdates$())
      .pipe(tap(() => this.loadUnreadedCountEmitter$.next()));

    const readAllNotifications$ = this.readAllNotifications$
      .pipe(
        map(() => {
          const currentNotifications: INotificationMessage<IBaseNotification>[] = cloneDeep(this.notifications$.value);
          return currentNotifications.map(notification => {
            notification.is_read = true;
            return notification;
          });
        }),
      );

    return merge(init$, removeUpdates$, newNotifications$, loadedMore$, readUpdates$, readAllNotifications$);
  }

  private getMeetingUpdates$(): Observable<INotificationMessage<IBaseNotification>[]> {
    return this.socketService.getCrudMessages$([
      EntityType.MEETING,
    ]).pipe(
      filter(message => message.eventType === EventType.REMOVE),
      withLatestFrom(this.notifications$),
      map(values => {
        const [message, notifications] = values;
        return notifications.map(notification => {
          if (message.entityId === notification.payload.id) {
            notification.payload.is_deleted = true;
            notification.is_read = true;
          }
          return notification;
        });
      }),
    );
  }

  private getAssignedTaskUpdates$(): Observable<INotificationMessage<IBaseNotification>[]> {
    return this.socketService.getCrudMessages$([
      EntityType.ASSIGNED_TASK,
    ])
    .pipe(
      filter(message => message.eventType === EventType.REMOVE),
      withLatestFrom(this.notifications$),
      map(values => {
        const studentTask: IStudentTask = values[0].entity;
        const types: NotificationType[] = [
          NotificationType.TICKET_TASK_PUBLISHED,
          NotificationType.TICKET_TASK_CHANGED,
          NotificationType.TICKET_TASK_DUE_DATE,
          NotificationType.ASSIGNED_TASK_MESSAGE,
          NotificationType.ASSIGNED_TASK_STATE_CHANGE,
        ];
        return values[1].map(notification => {
          if (types.includes(notification.type) &&
            (notification.payload.id === studentTask.assignedTask.id ||
            (notification.payload as ITicketTaskNotification).assigned_task_id === studentTask.assignedTask.id)
          ) {
            notification.payload.is_deleted = true;
            notification.is_read = true;
          }
          return notification;
        });
      }),
    );
  }

  private getTicketUpdates$(): Observable<INotificationMessage<IBaseNotification>[]> {
    return this.socketService.getCrudMessages$([
      EntityType.TICKET_TASK,
      EntityType.TICKET_GENERAL,
      EntityType.TICKET_NOTIFICATION,
    ])
    .pipe(
      filter(message => message.eventType === EventType.REMOVE),
      withLatestFrom(this.notifications$),
      map(values => {
        const ticket: ITicket = values[0].entity;
        const types: NotificationType[] = [
          NotificationType.STUDENT_UPDATE_TASK,
          NotificationType.STUDENT_COMPLETE_TASK,
          NotificationType.TICKET_TASK_STATE_CHANGED,
          NotificationType.TICKET_ANNOUNCE_PUBLISHED,
          NotificationType.TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED,
        ];
        return values[1].map(notification => {
          if (types.includes(notification.type) && notification.payload.id === ticket.id) {
            notification.payload.is_deleted = true;
          }
          return notification;
        });
      }),
    );
  }

  private loadUnreadedCount$(): Observable<number> {
    return this.loadUnreadedCountEmitter$
      .pipe(
        switchMap(() => this.loadUnreadedCountReq$()),
        filter(response => !response.error),
        map(response => response.payload),
      );
  }

  private loadUnreadedCountReq$(): Observable<IResponse<number>> {
    return this.httpService.get$('v1/notifications/unread-count')
    .pipe(
      map((response: IResponse<{ count: number }>) => {
        if (isSuccess(response)) {
          return { payload: response.payload.count };
        }
        return { ...response, payload: null };
      }),
    );
  }

  private markAllAsReadReq$(): Observable<IResponse<any>> {
    return this.httpService.post$('v1/notifications/mark-all', {});
  }
}
