import {ITicket, StudentTaskState, TeacherTaskState, TicketType} from '../../models/ticket';
import {EntityType, IFilesList, IStudentTask, ITeacherTask, TicketParent} from '../../models/common';
import {TranslateService} from '@ngx-translate/core';
import {isFile, isImage, isVideo} from '../../helpers/helpers';
import {ICalendarType} from '../calendar-input/models';
import {SubjectTicket} from 'src/app/pages/subject-dashboard/models';
import * as moment from 'moment';
import { IFileAttach } from '../../models/file-attach';

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;

  return clonedObj;
};

export function getId(item: ITicket | ITeacherTask | IStudentTask): string {
  if (!item) {
    return '';
  }

  return (item as ITeacherTask | IStudentTask).ticket
    ? (item as ITeacherTask | IStudentTask).ticket.id
    : (item as ITicket).id;
}

export function getTitle(item: ITicket | ITeacherTask | IStudentTask): string {
  if (!item) {
    return '';
  }

  return (item as ITeacherTask | IStudentTask).ticket
    ? (item as ITeacherTask | IStudentTask).ticket.title
    : (item as ITicket).title;
}

export function getDescription(item: ITicket | ITeacherTask | IStudentTask): string {
  if (!item) {
    return '';
  }

  return (item as ITeacherTask | IStudentTask).ticket
    ? (item as ITeacherTask | IStudentTask).ticket.description
    : (item as ITicket).description;
}

export function getSubjectId(item: ITicket | ITeacherTask | IStudentTask): string {
  if (!item) {
    return '';
  }

  return (item as ITeacherTask | IStudentTask).ticket
    ? (item as ITeacherTask | IStudentTask).ticket.subject_id
    : (item as ITicket).subject_id;
}

export function getTicket(item: ITicket | ITeacherTask | IStudentTask): ITicket {
  if (!item) {
    return null;
  }

  return (item as ITeacherTask | IStudentTask).ticket
    ? (item as ITeacherTask | IStudentTask).ticket
    : (item as ITicket);
}

export function getTicketDueDate(item: SubjectTicket): string {
  const ticket = getTicket(item);
  return ticket.due_date ? ticket.due_date : getTicketPublishedDate(item);
}

export function getTicketPublishedDate(item: SubjectTicket): string {
  const ticket = getTicket(item);
  return ticket.published_date ? ticket.published_date : ticket.created_on;
}

export function getTicketNotificationDate(ticket: ITicket, isOwner: boolean): string {
  if (isOwner) {
    return getTicketPublishedDate(ticket);
  }
  return ticket.notify_at ? ticket.notify_at : ticket.created_on;
}

export function getTicketParentType(item: ITicket | ITeacherTask | IStudentTask): TicketParent {
  if (!item) {
    return null;
  }
  const ticket = getTicket(item);
  if (ticket.school_id) {
    return TicketParent.SCHOOL;
  } else if (ticket.school_class_id) {
    return TicketParent.CLASS;
  } else if (ticket.material_id) {
    return TicketParent.MATERIAL;
  }
  return TicketParent.SUBJECT;
}

export function getTicketParentId(item: ITicket | ITeacherTask | IStudentTask): string {
  if (!item) {
    return null;
  }
  const ticket = getTicket(item);
  if (ticket.school_id) {
    return ticket.school_id;
  } else if (ticket.school_class_id) {
    return ticket.school_class_id;
  } else if (ticket.material_id) {
    return ticket.material_id;
  }
  return ticket.subject_id;
}

export function getDate(item: ITicket | ITeacherTask | IStudentTask, entityType: EntityType): string {
  if (!item) {
    return '';
  }

  if (entityType === EntityType.TICKET_TASK || entityType === EntityType.ASSIGNED_TASK) {
    return (item as ITeacherTask | IStudentTask).ticket.due_date;
  } else if (entityType === EntityType.TICKET_NOTIFICATION) {
    return (item as ITicket).notify_at;
  } else if (entityType === EntityType.TICKET_GENERAL) {
    return (item as ITicket).make_visible_at;
  }
}

export function isPublished(item: ITicket | ITeacherTask | IStudentTask): boolean {
  if (!item) {
    return false;
  }

  const ticket = getTicket(item);

  return ticket.is_published;
}

export function isPublishedAndDatePast(item: ITicket | ITeacherTask | IStudentTask): boolean {
  if (!item) {
    return false;
  }

  const ticket = getTicket(item);

  if (ticket.type === TicketType.NOTIFICATION) {
    return ticket.is_published && ticket.notify_at && moment(new Date()).isAfter(moment(ticket.notify_at));
  } else  {
    return ticket.is_published && ticket.make_visible_at && moment(new Date()).isAfter(moment(ticket.make_visible_at));
  }
}

export function shouldNotVerificate(item: ITicket | ITeacherTask | IStudentTask): boolean {
  if (!item) {
    return false;
  }

  return (item as ITeacherTask | IStudentTask).ticket
    ? (item as ITeacherTask | IStudentTask).ticket.should_not_verificate
    : (item as ITicket).should_not_verificate;
}

export function getDateFieldname(entityType: EntityType): string {
  if (!entityType) {
    return null;
  }

  if (entityType === EntityType.TICKET_TASK || entityType === EntityType.ASSIGNED_TASK) {
    return 'due_date';
  } else if (entityType === EntityType.TICKET_NOTIFICATION) {
    return 'notify_at';
  } else if (entityType === EntityType.TICKET_GENERAL) {
    return 'make_visible_at';
  }
}

export function getTicketValue(fgValue: {}, entityType: EntityType): {} {
  if (fgValue['date']) {
    if (fgValue['date'] === 'clear') {
      fgValue['date'] = null;
    }
    return renameKey(fgValue, 'date', getDateFieldname(entityType));
  }
  delete fgValue['date'];
  return fgValue;
}

export function getCalendarPlaceholder(entityType: EntityType, translateService: TranslateService): string {
  if (!entityType || !translateService) {
    return 'Set due date';
  }

  if (entityType === EntityType.TICKET_GENERAL) {
    return translateService.instant('ticket.calendar-general');
  } else if (entityType === EntityType.TICKET_NOTIFICATION) {
    return translateService.instant('ticket.calendar-notification');
  }
  return translateService.instant('ticket.calendar-task');
}

export function getEntityType(item: ITicket | ITeacherTask | IStudentTask): EntityType {
  let ticket: ITicket;
  if (item && (item as ITeacherTask | IStudentTask).ticket) {
    ticket = (item as ITeacherTask | IStudentTask).ticket;
  } else if (item && (item as ITicket)) {
    ticket = (item as ITicket);
  }

  if (ticket) {
    switch (ticket.type) {
      case TicketType.GENERAL:
        return EntityType.TICKET_GENERAL;
      case TicketType.NOTIFICATION:
          return EntityType.TICKET_NOTIFICATION;
      case TicketType.TASK:
        return (item as IStudentTask).assignedTask ? EntityType.ASSIGNED_TASK : EntityType.TICKET_TASK;
    }
  }
}

export function getTicketParentFromEntityType(entityType: EntityType): TicketParent {
  switch (entityType) {
    case EntityType.SCHOOL:
      return TicketParent.SCHOOL;
    case EntityType.CLASS:
      return TicketParent.CLASS;
    case EntityType.SUBJECT:
      return TicketParent.SUBJECT;
    case EntityType.MATERIAL:
      return TicketParent.MATERIAL;
  }
}

export function getEntityTypeFromTicketParent(ticketParent: TicketParent): EntityType {
  switch (ticketParent) {
    case TicketParent.SCHOOL:
      return EntityType.SCHOOL;
    case TicketParent.CLASS:
      return EntityType.CLASS;
    case TicketParent.SUBJECT:
      return EntityType.SUBJECT;
    case TicketParent.MATERIAL:
      return EntityType.MATERIAL;
  }
}

export function canChangeState(item: ITicket | ITeacherTask | IStudentTask, newState: TeacherTaskState | StudentTaskState, ownerId: string): boolean {
  const entityType = getEntityType(item);

  if (entityType === EntityType.ASSIGNED_TASK) {
    if ((item as IStudentTask).assignedTask.state === StudentTaskState.CHECKED_AND_FINISHED || (item as IStudentTask).assignedTask.state === StudentTaskState.WAITING_FOR_CHECK) {
      return false;
    } else if ((item as IStudentTask).assignedTask.state === StudentTaskState.TODO) {
      return (item as IStudentTask).ticket.should_not_verificate
        ? newState === StudentTaskState.IN_PROGRESS
        : newState === StudentTaskState.IN_PROGRESS || newState === StudentTaskState.WAITING_FOR_CHECK;
    } else if ((item as IStudentTask).assignedTask.state === StudentTaskState.IN_PROGRESS) {
      if (newState === StudentTaskState.TODO) {
        return true;
      }
      return (item as IStudentTask).ticket.should_not_verificate
        ? newState === StudentTaskState.CHECKED_AND_FINISHED
        : newState === StudentTaskState.WAITING_FOR_CHECK;
    }
  } else if (entityType === EntityType.TICKET_TASK) {
    if (!Object.values(TeacherTaskState).includes(newState)) {
      return true;
    } else if ((item as ITeacherTask).ticket.state === TeacherTaskState.DRAFT) {
      return !!getSubjectId(item);
    } else if ((item as ITeacherTask).ticket.state === TeacherTaskState.IN_PROGRESS) {
      return (item as ITeacherTask).ticket.should_not_verificate
        ? newState === TeacherTaskState.DONE
        : newState === TeacherTaskState.TO_CHECK || newState === TeacherTaskState.DONE;
    } else if ((item as ITeacherTask).ticket.state === TeacherTaskState.TO_CHECK) {
      return newState === TeacherTaskState.IN_PROGRESS || newState === TeacherTaskState.DONE;
    } else if ((item as ITeacherTask).ticket.state === TeacherTaskState.DONE) {
      return newState === TeacherTaskState.IN_PROGRESS || newState === TeacherTaskState.TO_CHECK;
    }
  } else if (entityType === EntityType.TICKET_NOTIFICATION && (getTicket(item).school_id || getTicket(item).school_class_id)) {
    return getTicket(item).owner_id === ownerId;
  }

  return true;
}

export function getFilesList(files: IFileAttach[]): IFilesList {
  if (!files.length) {
    return {
      documents: [],
      images: [],
      videos: [],
    };
  }
  const result: IFilesList = {
    documents: files
      .filter(f => isFile(f.name))
      .sort((a, b) => a.created_on > b.created_on ? -1 : a.created_on < b.created_on ? 1 : 0),
    images: files
      .filter(f => isImage(f.name))
      .sort((a, b) => a.created_on > b.created_on ? -1 : a.created_on < b.created_on ? 1 : 0),
    videos: files
      .filter(f => isVideo(f.name))
      .sort((a, b) => a.created_on > b.created_on ? -1 : a.created_on < b.created_on ? 1 : 0),
  };

  return result;
}

export function getCalendarType(item: ITicket | ITeacherTask | IStudentTask): ICalendarType {
  if (!this.item || !this.entityType) {
    return ICalendarType.DATE;
  }

  return getTicket(item) && getTicket(item).type === TicketType.NOTIFICATION
    ? ICalendarType.DATE_AND_TIME
    : ICalendarType.DATE;
}
