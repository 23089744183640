/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-widget.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chat-nav/chat-nav.page.ngfactory";
import * as i3 from "./chat-nav/chat-nav.page";
import * as i4 from "../../shared/services/platform.service";
import * as i5 from "./chat-log/chat-log.page.ngfactory";
import * as i6 from "./chat-log/chat-log.page";
import * as i7 from "@ngrx/store";
import * as i8 from "../../shared/services/chat-ui.service";
import * as i9 from "../../shared/services/zulip.service";
import * as i10 from "./create-chat/create-chat.page.ngfactory";
import * as i11 from "./create-chat/create-chat.page";
import * as i12 from "./chat-info/chat-info.page.ngfactory";
import * as i13 from "./chat-info/chat-info.page";
import * as i14 from "@ngx-translate/core";
import * as i15 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i16 from "@ionic/angular";
import * as i17 from "@angular/common";
import * as i18 from "./chat-widget.page";
import * as i19 from "@angular/router";
var styles_ChatWidgetPage = [i0.styles];
var RenderType_ChatWidgetPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatWidgetPage, data: {} });
export { RenderType_ChatWidgetPage as RenderType_ChatWidgetPage };
function View_ChatWidgetPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-nav-page", [], [[2, "desktop-view", null]], [[null, "select"], [null, "search"], [null, "expand"], [null, "dismiss"], [null, "showDetails"], [null, "openConference"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onChannelSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("search" === en)) {
        var pd_1 = (_co.handleSearch() !== false);
        ad = (pd_1 && ad);
    } if (("expand" === en)) {
        var pd_2 = (_co.handleExpand() !== false);
        ad = (pd_2 && ad);
    } if (("dismiss" === en)) {
        var pd_3 = (_co.handleDismiss() !== false);
        ad = (pd_3 && ad);
    } if (("showDetails" === en)) {
        var pd_4 = (_co.handleShowDetails($event) !== false);
        ad = (pd_4 && ad);
    } if (("openConference" === en)) {
        var pd_5 = (_co.handleOpenConference($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i2.View_ChatNavPage_0, i2.RenderType_ChatNavPage)), i1.ɵdid(1, 114688, null, 0, i3.ChatNavPage, [i4.PlatformService], null, { select: "select", search: "search", expand: "expand", dismiss: "dismiss", showDetails: "showDetails", openConference: "openConference" })], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).t; _ck(_v, 0, 0, currVal_0); }); }
function View_ChatWidgetPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-log-page", [], [[2, "desktop-view", null]], [[null, "back"], [null, "search"], [null, "expand"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.changePage(_co.pages.Nav) !== false);
        ad = (pd_0 && ad);
    } if (("search" === en)) {
        var pd_1 = (_co.handleSearch() !== false);
        ad = (pd_1 && ad);
    } if (("expand" === en)) {
        var pd_2 = (_co.handleExpand() !== false);
        ad = (pd_2 && ad);
    } if (("dismiss" === en)) {
        var pd_3 = (_co.handleDismiss() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_ChatLogPage_0, i5.RenderType_ChatLogPage)), i1.ɵdid(1, 114688, null, 0, i6.ChatLogPage, [i7.Store, i4.PlatformService, i8.ChatUiService, i9.ZulipService], { selectedChannelId: [0, "selectedChannelId"] }, { back: "back", search: "search", expand: "expand", dismiss: "dismiss" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedChannelId; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).t; _ck(_v, 0, 0, currVal_0); }); }
function View_ChatWidgetPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-chat-page", [], [[2, "desktop-view", null]], [[null, "back"], [null, "onCreate"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.changePage(_co.pages.Nav) !== false);
        ad = (pd_0 && ad);
    } if (("onCreate" === en)) {
        var pd_1 = (_co.onChannelSelect($event) !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.handleDismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i10.View_CreateChatPage_0, i10.RenderType_CreateChatPage)), i1.ɵdid(1, 114688, null, 0, i11.CreateChatPage, [i4.PlatformService], null, { back: "back", dismiss: "dismiss", onCreate: "onCreate" })], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).t; _ck(_v, 0, 0, currVal_0); }); }
function View_ChatWidgetPage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-info-page", [], null, [[null, "back"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.changePage(_co.pages.Nav) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ChatInfoPage_0, i12.RenderType_ChatInfoPage)), i1.ɵdid(1, 638976, null, 0, i13.ChatInfoPage, [i7.Store], { selectedChannelId: [0, "selectedChannelId"] }, { back: "back" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedChannelId; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChatWidgetPage_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "disconnected-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/img/warning-ico.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 4, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "ion-button", [["expand", "block"], ["fill", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_IonButton_0, i15.RenderType_IonButton)), i1.ɵdid(7, 49152, null, 0, i16.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"], fill: [1, "fill"] }, null), (_l()(), i1.ɵted(8, 0, ["", ""])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "block"; var currVal_2 = "clear"; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("connection-errors.zulip-offline")); _ck(_v, 3, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("common.btn-close")); _ck(_v, 8, 0, currVal_3); }); }
export function View_ChatWidgetPage_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i17.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatWidgetPage_1)), i1.ɵdid(3, 278528, null, 0, i17.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i17.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatWidgetPage_2)), i1.ɵdid(5, 278528, null, 0, i17.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i17.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatWidgetPage_3)), i1.ɵdid(7, 278528, null, 0, i17.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i17.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatWidgetPage_4)), i1.ɵdid(9, 278528, null, 0, i17.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i17.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatWidgetPage_5)), i1.ɵdid(11, 278528, null, 0, i17.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i17.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activePage; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.pages.Nav; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.pages.Log; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.pages.Create; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.pages.Details; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.pages.Disconnected; _ck(_v, 11, 0, currVal_5); }, null); }
export function View_ChatWidgetPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-widget", [], [[2, "mobile-view", null]], null, null, View_ChatWidgetPage_0, RenderType_ChatWidgetPage)), i1.ɵdid(1, 245760, null, 0, i18.ChatWidgetPage, [i7.Store, i19.Router, i1.ChangeDetectorRef, i4.PlatformService, i8.ChatUiService, i9.ZulipService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).t; _ck(_v, 0, 0, currVal_0); }); }
var ChatWidgetPageNgFactory = i1.ɵccf("app-chat-widget", i18.ChatWidgetPage, View_ChatWidgetPage_Host_0, { activePage: "activePage", selectedChannelId: "selectedChannelId", doDismiss: "doDismiss" }, {}, []);
export { ChatWidgetPageNgFactory as ChatWidgetPageNgFactory };
