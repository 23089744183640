<ion-content styledScrollbar="shadow-root">
  <div styledScrollbarContent class="inner-content">
    <ion-list [class.collapsed]="!accordionState[0]" lines="none" class="members-list">
      <ion-list-header>
        <ion-label (click)="toggle(0)">
          <ion-text>{{ 'chat.member_multi' | translate }} ({{ (members$ | async)?.length }})</ion-text>
          <ion-icon class="accordion-item-toggle-icon" name="chevron-down-sharp"></ion-icon>
        </ion-label>
      </ion-list-header>
      <div class="list-body">
        <app-chat-members-list [members]="members$ | async"></app-chat-members-list>
      </div>
    </ion-list>
    <ion-list [class.collapsed]="!accordionState[1]" lines="none" class="attachments-list">
      <ion-list-header>
        <ion-label (click)="toggle(1)">
          <ion-text>{{ 'common.files_multi' | translate }}</ion-text>
          <ion-icon class="accordion-item-toggle-icon" name="chevron-down-sharp"></ion-icon>
        </ion-label>
      </ion-list-header>
      <div class="list-body">
        <app-chat-attachments [selectedChannelId]="selectedChannelId$ | async"></app-chat-attachments>
      </div>
    </ion-list>
  </div>
</ion-content>
