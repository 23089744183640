import { HttpService } from './http.service';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { of } from 'rxjs';
import { NavController } from '@ionic/angular';
import 'firebase/messaging';
import * as firebase from 'firebase/app';
import { exhaustMap, filter } from 'rxjs/operators';
import { Plugins, } from '@capacitor/core';
import { PlatformService } from './platform.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "@angular/service-worker";
import * as i3 from "@angular/fire/messaging";
import * as i4 from "@ionic/angular";
import * as i5 from "./platform.service";
const { PushNotifications } = Plugins;
export class PushNotificationService {
    constructor(httpService, swPush, afMessaging, navCtrl, platformService) {
        this.httpService = httpService;
        this.swPush = swPush;
        this.afMessaging = afMessaging;
        this.navCtrl = navCtrl;
        this.platformService = platformService;
        this.POST_PUSH_NOTIFICATION_TOKEN = 'v1/push-notifications/save-token';
        this.POST_DELETE_TOKEN_FROM_SERVER = 'v1/push-notifications/delete-token';
        this.swPush.notificationClicks
            .pipe(filter(() => !!this.navCtrl), exhaustMap(msg => {
            console.log('%c [NGSW]: CLICKED', 'color: cyan', msg);
            if (msg.notification.data.click_action) {
                return of(this.performClickAction(msg.notification.data.click_action));
            }
            return of();
        }))
            .subscribe();
    }
    initializeFirebaseMessaging() {
        if (this.isSWEnabled()) {
            return navigator.serviceWorker.ready
                .then(sw => {
                console.log('%c [NGSW]: IS READY', 'color: cyan');
                firebase.messaging().useServiceWorker(sw);
                return Promise.resolve();
            })
                .catch(error => {
                console.log(error);
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    }
    registerToken() {
        if (this.platformService.isNativeiOS) {
            return this.registerTokeniOS();
        }
        else if (this.isSWEnabled()) {
            return this.registerTokenPWA();
        }
        return Promise.resolve();
    }
    deleteToken() {
        if (this.platformService.isNativeiOS) {
            this.removeTokeniOS();
        }
        else {
            return this.removeTokenPWA();
        }
        return Promise.resolve();
    }
    registerTokenReq(token) {
        return this.httpService.post$(this.POST_PUSH_NOTIFICATION_TOKEN, { token })
            .toPromise()
            .then(response => {
            if (response.error) {
                console.log(response.error);
            }
            return Promise.resolve();
        });
    }
    isSWEnabled() {
        return navigator && navigator.serviceWorker && this.swPush.isEnabled && environment.production;
    }
    registerTokeniOS() {
        return new Promise(resolve => {
            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration', (token) => {
                console.log('%c [FCM]: Token', 'color: cyan', token.value);
                this.registerTokenReq(token.value)
                    .then(() => resolve());
            });
            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError', (error) => {
                console.log('Error on ios push registration: ', JSON.stringify(error));
                resolve();
            });
            PushNotifications.requestPermission()
                .then(result => {
                if (result.granted) {
                    // Show us the notification payload if the app is open on our device
                    PushNotifications.addListener('pushNotificationReceived', (notification) => {
                        console.log('%c [iOS PUSH]: FOREGROUND RECEIVED', 'color: cyan', JSON.stringify(notification));
                    });
                    // Method called when tapping on a notification
                    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
                        console.log('%c [iOS PUSH]: CLICKED', 'color: cyan', JSON.stringify(notification));
                        console.log(notification.notification.click_action, 'click_action');
                        this.performClickAction(notification.notification.data.aps.category);
                    });
                    // Register with Apple / Google to receive push via APNS/FCM
                    return PushNotifications.register();
                }
                else {
                    console.log(result);
                }
            });
        });
    }
    registerTokenPWA() {
        return this.afMessaging.getToken
            .toPromise()
            .then(token => {
            this.pushNotificationToken = token;
            console.log('%c [FCM]: Token', 'color: cyan', token);
            this.firebaseSub = this.afMessaging.messaging
                .subscribe(messaging => {
                console.log('%c [FCM]: AngularFirebaseMessaging', 'color: cyan', messaging);
            });
            return this.registerTokenReq(token);
        })
            .catch(error => {
            console.log(error);
            return Promise.resolve();
        });
    }
    removeTokeniOS() {
        PushNotifications.removeAllListeners();
    }
    removeTokenPWA() {
        if (this.firebaseSub) {
            this.firebaseSub.unsubscribe();
            this.firebaseSub = null;
        }
        if (this.pushNotificationToken) {
            return Promise.all([
                this.deleteTokenReq$().toPromise(),
                firebase.messaging().deleteToken(this.pushNotificationToken),
            ])
                .then(() => Promise.resolve())
                .catch(error => {
                console.log(error);
                return Promise.resolve();
            });
        }
    }
    getPageUrl(url) {
        return url.slice(url.indexOf('pages'), url.length);
    }
    // click_action is a url provided in Firebase notification payload
    performClickAction(click_action) {
        return this.navCtrl.navigateRoot(this.getPageUrl(click_action));
    }
    deleteTokenReq$() {
        return this.httpService.post$(this.POST_DELETE_TOKEN_FROM_SERVER, { token: this.pushNotificationToken });
    }
}
PushNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationService_Factory() { return new PushNotificationService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.SwPush), i0.ɵɵinject(i3.AngularFireMessaging), i0.ɵɵinject(i4.NavController), i0.ɵɵinject(i5.PlatformService)); }, token: PushNotificationService, providedIn: "root" });
