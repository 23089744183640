import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, merge, Observable, of, Subject, zip} from 'rxjs';
import {filter, first, map, skip, skipUntil, takeUntil, tap} from 'rxjs/operators';
import {INode} from './models/node';
import {ISubject} from '../../models/subject';
import {NodeType} from './models/node_type';
import {DEFAULT_TREE, TREE_CONFIG} from './config';
import {TranslateService} from '@ngx-translate/core';
import {ISchool as IClass, ISchool} from '../../models/school';
import {IMaterial} from '../../models/material';
import {SchoolStoreService} from '../../services/stores/school-store.service';
import {ClassStoreService} from '../../services/stores/class-store.service';
import {SubjectStoreService} from '../../services/stores/subject-store.service';
import {MaterialStoreService} from '../../services/stores/material-store.service';

const cloneDeep = require('lodash.clonedeep');

@Injectable()
export class TreeMenuStore implements OnDestroy {
  public tree$: BehaviorSubject<INode[]> = new BehaviorSubject(DEFAULT_TREE);
  public initializedTree$: BehaviorSubject<INode[]> = new BehaviorSubject([]);
  private destroy$: Subject<void> = new Subject();
  public primaryNodeType?: NodeType | null;

  constructor(
    private translateService: TranslateService,
    private schoolStoreService: SchoolStoreService,
    private classStoreService: ClassStoreService,
    private subjectStoreService: SubjectStoreService,
    private materialStoreService: MaterialStoreService,
  ) {}

  onInit() {
    const defTree$ = of(DEFAULT_TREE).pipe(map(defaultTree => this.getTranslatedTree(defaultTree)));

    const init$ = this.getInitTree$()
      .pipe(
          tap(data => this.initializedTree$.next(data)),
          first()
      );

    const schoolUpdates$ = this.schoolStoreService.getState$()
      .pipe(
        filter(state => !!state),
        skip(1),
        map(state => this.prepareTreeByGroup(NodeType.SCHOOL_GROUP, [ ...state.teach, ...state.study ], this.getSchoolNode, this.tree$.value)),
      );

    const classUpdates$ = this.classStoreService.getState$()
      .pipe(
        filter(state => !!state),
        skip(1),
        map(state => this.prepareTreeByGroup(NodeType.CLASS_GROUP, [ ...state.teach, ...state.study ], this.getClassNode, this.tree$.value)),
      );

    const subjectUpdates$ = this.subjectStoreService.getState$()
      .pipe(
        filter(state => !!state),
        skip(1),
        map(state => {
          const subjectTree = this.prepareTreeByGroup(NodeType.SUBJECT_GROUP, state.teach, this.getSubjectNode, this.tree$.value);
          if (this.primaryNodeType === NodeType.SUBJECT_GROUP) {
            return subjectTree;
          }
          return this.prepareTreeByGroup(NodeType.LESSON_GROUP, state.study, this.getLessonsNode, subjectTree);
        }),
      );

    const materialUpdates$ = this.materialStoreService.getState$()
      .pipe(
        filter(state => !!state),
        skip(1),
        map(state => this.prepareTreeByGroup(NodeType.MATERIAL_GROUP, state.all, this.getMaterialNode, this.tree$.value)),
      );

    const updates$ = merge(schoolUpdates$, classUpdates$, subjectUpdates$, materialUpdates$)
      .pipe(skipUntil(init$));

    merge(defTree$, init$, updates$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(tree => {
        this.tree$.next(tree);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  protected onDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public refreshTree(): void {
    this.tree$.next(this.tree$.getValue());
  }

  private prepareTreeByGroup(groupType: NodeType, data: any[], transformerFn: (item: any) => INode, tree: INode[]): INode[] {
    const newTree: INode[] = cloneDeep(tree);
    let shouldUpdateInitTree = false;

    const groupIndex: number = newTree.findIndex(groupNode => groupNode.type === groupType);
    if (groupIndex !==  -1) {
      const group: INode = cloneDeep(newTree[groupIndex]);
      if (group.children && group.children.length === 0 && data.length !== 0) {
        shouldUpdateInitTree = true;
      }
      group.children = data.map(d => transformerFn(d));
      newTree[groupIndex] = group;
    } else {
      return this.prepareChildTree(groupType, data, transformerFn, tree);
    }

    if (shouldUpdateInitTree) {
      this.initializedTree$.next(newTree);
    }

    return newTree;
  }

  prepareChildTree(groupType: NodeType, data: any[], transformerFn: (item: any) => INode, tree: INode[]):  INode[] {
    const newTree: INode[] = data.map(d => transformerFn(d));

    const initTree = this.initializedTree$.value;

    const groupIndex: number = initTree.findIndex(groupNode => groupNode.type === groupType);
    if (groupIndex !==  -1) {
      const group: INode = cloneDeep(initTree[groupIndex]);
      group.children = newTree;
      initTree[groupIndex] = group;
      this.initializedTree$.next(initTree);
    }

    return newTree;
  }

  private getSchoolNode = (school: ISchool): INode => {
    return {
      id: school.id,
      name: school.title,
      type: NodeType.SCHOOL,
      showButtons: true,
      showVideoButton: false,
      shareEnabled: true,
      editEnabled: false,
      payload: school,
    };
  }

  private getClassNode = (schoolClass: IClass): INode => {
    return {
      id: schoolClass.id,
      name: schoolClass.title,
      type: NodeType.CLASS,
      showButtons: true,
      showVideoButton: false,
      shareEnabled: true,
      editEnabled: false,
      payload: schoolClass,
    };
  }

  private getSubjectNode = (subject: ISubject): INode => {
    return {
      id: subject.id,
      name: subject.title,
      type: NodeType.SUBJECT,
      showButtons: true,
      showVideoButton: false,
      shareEnabled: true,
      editEnabled: false,
      children: [
        {
          id: `${subject.id}_[dashboard]`,
          name: 'subject.dashboard',
          type: NodeType.SUBJECT_DASHBOARD,
          payload: subject,
        },
        {
          id: `${subject.id}_[calendar]`,
          name: 'subject.calendar',
          type: NodeType.SUBJECT_CALENDAR,
          payload: subject,
        },
      ],
      payload: subject,
    };
  }

  private getLessonsNode = (subject: ISubject): INode => {
    return {
      id: subject.id,
      name: subject.title,
      type: NodeType.LESSON,
      showButtons: false,
      showVideoButton: false,
      shareEnabled: false,
      editEnabled: false,
      payload: subject,
    };
  }

  private getMaterialNode = (material: IMaterial): INode => {
    return {
      id: material.id,
      name: material.title,
      type: NodeType.MATERIAL,
      showButtons: true,
      showVideoButton: false,
      shareEnabled: null,
      editEnabled: true,
      payload: material,
    };
  }

  private getTranslatedTree(tree: INode[]): INode[] {
    return tree.map(groupNode => {
      groupNode.name = TREE_CONFIG.groupTranslateKeys[groupNode.type];
      return groupNode;
    });
  }

  private getInitTree$(): Observable<INode[]> {
    return zip(
      this.schoolStoreService.getState$()
        .pipe(filter(state => !!state)),
      this.classStoreService.getState$()
        .pipe(filter(state => !!state)),
      this.subjectStoreService.getState$()
        .pipe(filter(state => !!state)),
      this.materialStoreService.getState$()
        .pipe(filter(state => !!state)),
    )
    .pipe(
      map(values => {
        const schoolTree = this.prepareTreeByGroup(NodeType.SCHOOL_GROUP, [ ...values[0].teach, ...values[0].study ], this.getSchoolNode, this.tree$.value);
        const classTree = this.prepareTreeByGroup(NodeType.CLASS_GROUP, [ ...values[1].teach, ...values[1].study ], this.getClassNode, schoolTree);
        const subjectTree = this.prepareTreeByGroup(NodeType.SUBJECT_GROUP, values[2].teach, this.getSubjectNode, classTree);
        const lessonTree = this.prepareTreeByGroup(NodeType.LESSON_GROUP, values[2].study, this.getLessonsNode, subjectTree);
        const materialTree = this.prepareTreeByGroup(NodeType.MATERIAL_GROUP, values[3].all, this.getMaterialNode, lessonTree);
        return materialTree;
      }),
    );
  }
}
