import { Injectable, isDevMode } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import {combineLatest, concat, EMPTY, interval, merge, Observable, of, zip} from 'rxjs';
import { catchError, concatMap, debounceTime, exhaustMap, filter, first, map, mapTo, mergeMap, scan, switchMap, takeUntil, tap, timeoutWith, withLatestFrom } from 'rxjs/operators';
import { UserService } from 'src/app/auth/services/user.service';
import { createRecord, isImage } from 'src/app/shared/helpers/helpers';
import { IChatUpdateEvent, IMessage, IChatUpdateMessageEvent, NewMessageStatus, IChatUpdateMessageFlagsEvent, ISubscriptionEvent, IChatStream, ISubscriptionPeerEvent, ChatRemovedEvent, UserPresenceStatus, IUserPresenceEvent, IZulipUsersPresence, IZulipUserPresence, IChatMessageAttachment, LoadChatAttachmentsSuccessEvent, IStreamUnreads, IZulipUserId, IGetMessagesResponse } from 'src/app/shared/models/chat';
import { EventType } from 'src/app/shared/models/common';
import { ChatUiService } from 'src/app/shared/services/chat-ui.service';
import { EntityAdapterService } from 'src/app/shared/services/entity-adapter.service';
import { CLIENT_IDLE_TIMEOUT_SECONDS, WindowService } from 'src/app/shared/services/window.service';
import { ZulipService } from 'src/app/shared/services/zulip.service';
import * as ChatActions from '../actions/chat';
import { selectChatStreamByEntityId, selectChatStreamMembers, selectChatStreamMessages, selectLoadedUserStateById, selectMarkMessageRead, selectUserById, _resetAllSelectors } from '../selectors/chat.selectors';
import { UNDEFINED_CHANNEL_TITLE } from '../helpers';
import * as moment from 'moment';
import {environment} from "../../../environments/environment";

@Injectable()
export class ChatEffects {
  triggerInit$ = createEffect(() =>
    this.zulipService.init$.pipe(
        switchMap(res => this.entityAdapterService.onEntitiesLoaded$.pipe(filter(loaded => loaded), mapTo(res))),
        map(res => {
        const streams = this.parseStreamMetadata(
          res.subscriptions.filter(entry => entry.stream_id !== 1)
        );
        const unreads = res.unread_msgs != null ? res.unread_msgs.streams : [];

        if (unreads.length > 0) {
          return ChatActions.init({
            streams: this.assignStreamFirstUnread(streams, unreads)
          });
        }
        return ChatActions.init({ streams });
      }),
    )
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.init),
      map(({ streams }) => ChatActions.initChatStreams({ streams }))
    )
  );

  loadStreams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadChatStreams),
      exhaustMap((action) => this.zulipService.getStreams$().pipe(
        map(streams => ({ action, streams }))
      )),
      exhaustMap(({ action, streams }) => {
        const streamsData$ = streams
          .filter(stream => {
            const hasMetadata = stream.meta != null;
            return action.streams.length > 0
              ? action.streams.includes(stream.stream_id) && hasMetadata
              : hasMetadata;
          })
          .map(stream => {
            if (typeof stream.meta.entityType === 'string') {
              return this.entityAdapterService.getOne$(stream.name, stream.meta.entityType).pipe(
                map(res => {
                  if (res != null) {
                    return { ...stream, title: res.title } as IChatStream;
                  }
                  return null;
                })
              );
            }
            return of(stream);
          });
        return zip(...streamsData$).pipe(first());
      }),
      map((streams) =>
        ChatActions.loadChatStreamsSuccess({ streams: streams.filter(entry => entry != null) })
      )
    )
  )

  updateChatStreamTitles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.initChatStreams),
      mergeMap(({ streams }) => {
        const actions$ = streams.map(stream => {
          if (stream.meta.type === 'channel') {
            return this.updateChannelStreamTitle(stream);
          }

          if (stream.meta.type === 'pm') {
            return this.updatePmStreamTitle(stream);
          }

          if (isDevMode()) {
            console.warn('ChatEffects.batchUpdateChatStreamData$ cannot decide what to do with', stream.name);
          }

          return of(stream);
        });
        return zip(...actions$).pipe(
          map(streams => ChatActions.batchUpdateChatStreamData({ streams }))
        );
      })
    )
  )

  initLastMessageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.initChatStreams),
      exhaustMap(({ streams }) => {
        const streamMessages$ = streams.map(stream =>
          this.zulipService.getMessages$(stream.stream_id, 'newest', 1).pipe(
            catchError(err => {
              if (isDevMode()) {
                console.error(err);
              }
              return of({ messages: [] } as Partial<IGetMessagesResponse>);
            })
          )
        );
        return zip(...streamMessages$).pipe(
          map(messagesRes => messagesRes.filter(res => res.messages.length > 0)),
          filter(messagesRes => messagesRes.length > 0),
          map(messagesRes => ({ streams, messagesRes }))
        );
      }),
      map(({ streams, messagesRes }) => {
        const hiddenStreamsTreshold = moment().subtract(environment.hideZulipChatOlderThenHours, 'hours');

        const streamUpdates = messagesRes.map(res => {
          const [message] = res.messages;
          const stream = streams.find(entry => entry.stream_id === message.stream_id);
          const doesntPassHiddenStreamsTreshold = moment(message.timestamp * 1000).isBefore(hiddenStreamsTreshold);

          return {
            hide: stream.meta.type === 'pm' && doesntPassHiddenStreamsTreshold,
            last_message_id: message.id,
            stream_id: message.stream_id,
          }
        })

        return ChatActions.batchUpdateChatStreamData({ streams: streamUpdates })
      })
    )
  );

  syncChatStreamUpdates$ = createEffect(() => {
    const init$ = this.actions$.pipe(ofType(ChatActions.init));
    const reset$ = this.actions$.pipe(ofType(ChatActions.resetChatState));

    return init$.pipe(
      switchMap(() => this.entityAdapterService.onEntityUpdate$.pipe(takeUntil(reset$))),
      filter(event => event.response && event.response.payload != null),
      mergeMap(event => {
        if (event.eventType === EventType.EDIT) {
          return this.store.select(selectChatStreamByEntityId, event.response.payload.id).pipe(
            first(stream =>
              stream != null && (stream.data || { title: null }).title !== event.response.payload.title
            ),
            map(stream => ChatActions.updateChatStreamData({
              streamId: stream.id,
              data: {
                title: event.response.payload.title
              }
            }))
          )
        }
        return EMPTY;
      })
    )
  })

  loadUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.initChatStreams),
      exhaustMap(({ streams }) => {
        type Actions = Array<Observable<TypedAction<string>>>;
        const setUnreadsActions$ = streams.reduce<Actions>((actions, stream) => {
          // stream.server_first_unread might be set to -1 in case stream has no unreads.
          // This is done in ChatEffects.assignStreamFirstUnread.
          if (stream.server_first_unread != null && stream.server_first_unread > 0) {
            const setChatStreamFirstUnread$ = of(
              ChatActions.setChatStreamFirstUnread({
                streamId: stream.stream_id,
                firstUnread: stream.server_first_unread
              })
            )
            actions.push(setChatStreamFirstUnread$);
          }
          return actions;
        }, []);

        return merge(...setUnreadsActions$);
      })
    )
  )

  loadChatUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadUserDataById),
      mergeMap(action =>
        this.zulipService.getUser$(action.id).pipe(
          map(res => {
            if (res.payload) {
              const user = res.payload;
              return ChatActions.loadUserDataSuccess({
                data: user,
                uid: user.zulip_uid,
              })
            }
            return ChatActions.loadUserDataFailed({
              error: res,
              uid: action.id,
            })
          })
        )
      )
    )
  )

  addChatStreamSubscriberWhenLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.addChatStreamSubscriberWhenLoaded),
      mergeMap((action) =>
        this.store.select(selectUserById, action.uid).pipe(
          first(),
          map(userState => ({ action, userState })),
        )
      ),
      mergeMap(({ action, userState }) => {
        if (userState != null) {
          const addChatStreamSubscriberAction$ = of(
            ChatActions.addChatStreamSubscriber({
              streamId: action.streamId,
              uid: action.uid,
            })
          );
          return addChatStreamSubscriberAction$;
        }

        const loadUserData$ = of(ChatActions.loadUserDataById({ id: action.uid }));
        const addChatStreamSubscriberWhenLoadedAction$ = this.actions$.pipe(
          ofType(ChatActions.loadUserDataSuccess),
          first(userAction => userAction.uid === action.uid),
          map(userAction =>
            ChatActions.addChatStreamSubscriber({
              streamId: action.streamId,
              uid: userAction.uid,
            })
          )
        );
        return merge(loadUserData$, addChatStreamSubscriberWhenLoadedAction$)
      })
    )
  )

  loadStreamsMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.initChatStreams),
      switchMap(() => this.zulipService.getSubscriptionsMembers$()),
      map(res => {
        if (res.error) {
          return ChatActions.batchLoadChatUsersFailed({ error: res.error })
        }

        return ChatActions.batchLoadChatUsersSuccess({ payload: res.payload })
      })
    )
  )

  loadStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadSingleChatStream),
      map(({ streamId }) => ChatActions.loadRemoteMessages({ streamId }))
    )
  );

  loadRemoteMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadRemoteMessages),
      exhaustMap(action =>
        this.store.select(selectChatStreamMessages, action.streamId).pipe(
          map(messages => ({ action, messages })),
          first(),
        )
      ),
      exhaustMap(({ action, messages }) => {
        const { streamId, anchor, fetchAll = false } = action;
        const earliestMessageId = messages.length > 0 ? messages[0].id : undefined;

        const messages$ = fetchAll
          ? this.zulipService.getAllMessages$(streamId, anchor, earliestMessageId)
          : this.zulipService.getMessages$(streamId, anchor);

        return messages$.pipe(
          first(),
          map((res) =>
            ChatActions.loadRemoteMessagesSuccess({
              streamId,
              data: res.messages,
              anchor: res.anchor
            })
          ),
          catchError((err) =>
            of(
              ChatActions.loadRemoteMessagesFailure({
                streamId,
                error: err && err.code
                  ? { msg: err.msg, code: err.code }
                  : err
              })
            )
          )
        )
      })
    )
  );

  streamUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.init),
      switchMap(() => this.zulipService.currentUser$),
      switchMap((user) =>
        this.zulipService.eventQueueUpdates$.pipe(
          takeUntil(
            this.actions$.pipe(ofType(ChatActions.resetChatState))
          ),
          map((update): IChatUpdateEvent[] =>
            update.events.filter(event =>
              event.type !== 'heartbeat' && (event as ISubscriptionPeerEvent).stream_id !== 1
            ),
          ),
          filter(events => events.length > 0),
          switchMap(events => {
            const actions: TypedAction<string>[] = events.reduce(
              (actions, event) => [].concat(actions, this.mapUpdateEventToActions(event, user)),
              []
            );
            return of(...actions);
          })
        )
      ),
    )
  );

  updateCurrentUserPresence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.init),
      switchMap(() => {
        const active$ = this.windowService.active$.pipe(mapTo(true))
        const idle$ = this.windowService.idle$.pipe(mapTo(false))
        const forceActiveCheck$ = this.windowService.focus$.pipe(
          debounceTime(1000),
          mapTo(true)
        );
        const isActive = document.hasFocus && document.hasFocus()
        const isActive$ = merge(of(isActive), active$, idle$, forceActiveCheck$).pipe(
          scan((_, val) => val, true)
        )
        const scheduledPing$ = concat(of(null), merge(forceActiveCheck$, interval(60 * 1e3))).pipe(
          withLatestFrom(isActive$),
          map(([, isActive]) => isActive ? UserPresenceStatus.Active : UserPresenceStatus.Idle),
          switchMap(presence => this.zulipService.updateUserPresence$(presence, false)),
          map(res => res.presences ? this.mapPresencePingResponseToAction(res.presences) : ChatActions.noop()),
          takeUntil(this.actions$.pipe(ofType(ChatActions.resetChatState)))
        )

        return scheduledPing$;
      })
    )
  );

  maybeUpdateUsersPresence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.maybeUpdateUsersPresence),
      mergeMap((action) => {
        const awaitedUsers$ = action.payload.map(({ uid, online }) =>
          this.store.pipe(
            selectLoadedUserStateById(uid),
            first(currentState => currentState.online !== online),
            timeoutWith(3 * 60 * 1e3, EMPTY), // User fetching timeout
            map(() => ChatActions.updateUserPresence({ uid, online })),
          )
        );
        return merge(...awaitedUsers$);
      })
    )
  )

  prepareSentMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.sendMessage),
      switchMap(action => {
        const userData$ = zip(
          this.userService.getUser$(),
          this.zulipService.currentUser$
        )
        return userData$.pipe(
          first(),
          map(([ user, zulipUser ]) => ({ action, user, zulipUser }))
        )
      }),
      map(({ action, user, zulipUser }) => {
        const tempMessage: Partial<IMessage> = {
          id: action.tempMessageId,
          sender_id: zulipUser.user_id,
          sender_email: user.email,
          sender_full_name: user.firstName + ' ' + user.lastName,
          content: action.content,
          stream_id: action.streamId,
          timestamp: action.timestamp
        }
        return ChatActions.setDraftMessage({
          streamId: action.streamId,
          message: tempMessage as any
        })
      })
    )
  )

  setDraftMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.setDraftMessage),
      map(({ streamId, message }) =>
        ChatActions.setMessage({ streamId, message: message as any })
      )
    )
  )

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.setDraftMessage),
      mergeMap((action) => {
        return this.zulipService.sendMessage$(
          action.streamId,
          action.message.content,
          action.message.id.toString()
        ).pipe(
          map(() =>
            ChatActions.sendMessageSuccess({
              streamId: action.streamId,
              messageId: action.message.id
            })
          ),
          catchError(err => {
            if (isDevMode()) {
              console.warn(err);
            }

            return of(ChatActions.sendMessageFailure({
              streamId: action.streamId,
              messageId: action.message.id.toString(),
              error: err
            }))
          })
        )
      })
    )
  )

  markAllMessagesRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.markAllMessagesRead),
      exhaustMap(action => this.zulipService
        .markAllAsRead$(action.streamId)
        .pipe(
          map(() =>
            ChatActions.markAllMessagesReadSuccess({ streamId: action.streamId })
          ),
          catchError(err =>
            of(ChatActions.markAllMessagesReadFailure({ streamId: action.streamId, error: err }))
          )
        )
      ),
    )
  )

  // Filter for messages that are not yet requested
  markMessageRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.markMessagesRead),
      withLatestFrom(this.store.select(selectMarkMessageRead)),
      map(([action, markMessageReadEntries]) => {
        const inProgress = markMessageReadEntries.map(message => message.id);
        return action.messages.filter(({ id }) => !inProgress.includes(id))
      }),
      filter(messages => messages.length > 0),
      map(messages => ChatActions.addMarkMessagesRead({ messages }))
    )
  )

  markMessageReadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.addMarkMessagesRead),
      mergeMap(action => {
        const messagesPayload = action.messages.map(({ id }) => id);
        return this.zulipService.markAsRead(messagesPayload).pipe(
          catchError(() => {
            this.store.dispatch(ChatActions.markMessagesReadFailure({
              messages: action.messages
            }));
            return of(null);
          })
        )
      }),
    ),
    { dispatch: false }
  )

  updateLocalUnreadsOnMarkMessagesReadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.finalizeMarkMessagesRead),
      switchMap((action) =>
        concat(
          of(ChatActions.maybeUpdateChatStreamFirstUnread({ messageId: action.messages[0] })),
          of(ChatActions.markMessagesReadSuccess({ messages: action.messages }))
        )
      )
    )
  )

  maybeUpdateChatStreamFirstUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.maybeUpdateChatStreamFirstUnread),
      concatMap(action => this.zulipService.getFirstUnreadByMessage$(action.messageId)),
      map(res => ChatActions.setChatStreamFirstUnread(res))
    )
  )

  resetChatState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.resetChatState),
      tap(() => _resetAllSelectors())
    ),
    { dispatch: false }
  )

  loadChatStreamAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadChatStreamAttachments),
      mergeMap(action => this.zulipService.getAttachments$(action.streamId, action.anchor, action.count).pipe(
        map(res => {
          if (typeof action.anchor === 'number') {
            return {
              ...res,
              messages: res.messages.filter(msg => msg.id !== action.anchor)
            }
          }
          return res;
        }),
        map(res => {
          const payload: IChatMessageAttachment[] = this.mapMessagesToAttachments(res.messages)
          return ChatActions.loadChatStreamAttachmentsSuccess({
            payload,
            streamId: action.streamId,
            loaded: res.found_oldest,
          })
        }),
        tap(action => {
          this.chatUiService.publish(
            new LoadChatAttachmentsSuccessEvent(action.streamId, action.payload)
          );
        }),
        catchError(error => of(
          ChatActions.loadChatStreamAttachmentsFailure({ streamId: action.streamId, error })
        ))
      ))
    )
  );

  loadChatStreamAttachmentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadChatStreamAttachmentsSuccess),
      map(action =>
        ChatActions.setChatStreamAttachmentsLoaded({
          streamId: action.streamId,
          loaded: action.loaded,
        })
      )
    )
  )

  constructor(
    private actions$: Actions,
    private zulipService: ZulipService,
    private userService: UserService,
    private store: Store,
    private entityAdapterService: EntityAdapterService,
    private chatUiService: ChatUiService,
    private windowService: WindowService,
  ) {}

  private updateChannelStreamTitle(stream: IChatStream): Observable<IChatStream> {
    return this.entityAdapterService.getOne$(stream.name, stream.meta.entityType).pipe(
      map((res): IChatStream => {
        if (res != null) {
          return { ...stream, title: res.title };
        }
        return { ...stream, title: UNDEFINED_CHANNEL_TITLE };
      })
    );
  }

  private updatePmStreamTitle(stream: IChatStream): Observable<IChatStream> {
    if (stream.meta.title != null) {
      return of({
        ...stream,
        title: stream.meta.title,
      })
    }

    const streamMembers$ = this.store.pipe(selectChatStreamMembers(stream.stream_id));

    return combineLatest([ streamMembers$, this.zulipService.currentUser$ ]).pipe(
      filter(([_, currentUser]) => currentUser && currentUser !== null),
      first(),
      map(([members, currentUser]): IChatStream => ({
        ...stream,
        title: members
          .filter(({ zulip_uid }) => currentUser.user_id !== zulip_uid)
          .map(member => member.fullName)
          .join(', ')
      }))
    )
  }

  private mapUpdateEventToActions(event: IChatUpdateEvent, user: IZulipUserId): TypedAction<string>[] {
    switch (event.type) {
      case 'message': {
        return this.mapUpdateEventToMessageActions(event, user);
      }
      case 'update_message_flags': {
        return this.mapUpdateEventToMessageFlagActions(event);
      }
      case 'subscription': {
        return this.mapUpdateEventToStreamActions(event);
      }
      case 'presence': {
        return this.mapUpdateEventToPresenceActions(event);
      }
      default: return [];
    }
  }

  private mapUpdateEventToStreamActions(event: ISubscriptionEvent | ISubscriptionPeerEvent): TypedAction<string>[] {
    const actions: TypedAction<string>[] = [];

    switch (event.op) {
      case 'add': {
        actions.push(
          ChatActions.initChatStreams({
            streams: this.parseStreamMetadata(event.subscriptions)
          })
        );
        break;
      }
      case 'remove': {
        actions.push(
          ChatActions.unloadChatStream({
            streams: event.subscriptions.map(({ stream_id }) => stream_id)
          })
        )

        event.subscriptions.forEach(({ stream_id }) => {
          this.chatUiService.publish(new ChatRemovedEvent(stream_id))
        })

        break;
      }
      case 'peer_add': {
        actions.push(
          ChatActions.addChatStreamSubscriberWhenLoaded({
            streamId: event.stream_id,
            uid: event.user_id
          })
        )
        break;
      }
      case 'peer_remove': {
        actions.push(
          ChatActions.removeChatStreamSubscriber({
            streamId: event.stream_id,
            uid: event.user_id,
          })
        )
        break;
      }
    }

    return actions;
  }

  private mapUpdateEventToMessageActions(event: IChatUpdateMessageEvent, user: IZulipUserId): TypedAction<string>[] {
    const actions: TypedAction<string>[] = [];
    const payload = {
      streamId: event.message.stream_id,
      message: event.message,
    };

    if (event.message.sender_realm_str === 'zulipinternal') {
      return actions;
    }

    if (event.local_message_id) {
      payload.message.local_id = event.local_message_id;
      actions.push(
        ChatActions.updateDraftMessage({
          draftId: event.local_message_id,
          status: NewMessageStatus.Sent,
          id: event.message.id
        }),
        ChatActions.markMessagesRead({
          messages: [{ id: payload.message.id, streamId: payload.streamId }]
        })
      );
    }

    actions.push(ChatActions.updateChatStreamData({
      streamId: event.message.stream_id,
      data: {
        last_message_id: event.message.id as number,
        hide: false
      }
    }));

    actions.push(ChatActions.setMessage(payload));

    // If action targets current user's message don't proceed
    // because it's incorrect behavior:
    //    chat fab unread marker should not include own user messages
    //    even though each user message is unread for the sender by zulip api design
    if (
      event.local_message_id == null &&
      event.message.sender_id !== user.user_id &&
      !(Array.isArray(payload.message.flags) && payload.message.flags.includes('read'))
    ) {
      actions.push(ChatActions.maybeUpdateChatStreamFirstUnread({
        messageId: event.message.id
      }));
    }

    return actions;
  }

  private mapUpdateEventToMessageFlagActions(event: IChatUpdateMessageFlagsEvent): TypedAction<string>[] {
    const actions: TypedAction<string>[] = [];

    if (event.operation === 'add' && event.flag === 'read' && event.messages.length > 0) {
      if (event.all) {
        actions.push(
          ChatActions.maybeUpdateChatStreamFirstUnread({ messageId: event.messages[0] })
        )
      } else {
        actions.push(
          ChatActions.finalizeMarkMessagesRead({ messages: event.messages })
        );
      }
    }

    return actions;
  }

  private mapUpdateEventToPresenceActions(event: IUserPresenceEvent): TypedAction<string>[] {
    const actions: TypedAction<string>[] = [];

    actions.push(ChatActions.updateUserPresence({
      uid: event.user_id,
      online: this.isUserOnline(event.presence)
    }))

    return actions;
  }

  private mapPresencePingResponseToAction(presences: IZulipUsersPresence) {
    const presencesPayload: Array<{ uid: number, online: boolean }> = [];

    for (let uid in presences) {
      try {
        presencesPayload.push({ uid: Number(uid), online: this.isUserOnline(presences[uid]) })
      } catch(err) {
        console.error(err);
        presencesPayload.push({ uid: Number(uid), online: false });
      }
    }

    return ChatActions.maybeUpdateUsersPresence({ payload: presencesPayload })
  }

  private isUserOnline(presence: IZulipUserPresence): boolean {
    const timestampNow = Math.floor(Date.now() / 1000);

    if (!('active_timestamp' in presence)) {
      return false;
    }

    return (timestampNow - presence['active_timestamp']) < CLIENT_IDLE_TIMEOUT_SECONDS;
  }

  private mapMessagesToAttachments(messages: IMessage[]): IChatMessageAttachment[] {
    const parser = new DOMParser();

    return messages.reduce((accum, msg) => {
      try {
        const linksList = (parser.parseFromString(msg.content, 'text/html').querySelectorAll('a'))
        const links: IChatMessageAttachment[] = Array.from(linksList).map(elem => ({
          link: elem.href.slice(elem.href.indexOf('/user_uploads/'), elem.href.length),
          name: elem.href.split('/').pop(),
          messageId: msg.id,
          senderId: msg.sender_id,
          senderEmail: msg.sender_email,
          isImage: isImage(elem.href),
          createdAt: msg.timestamp
        }));
        return accum.concat(links);
      } catch(err) {
        if (isDevMode()) console.error(err);
        return accum;
      }
    }, [] as IChatMessageAttachment[]);
  }

  private assignStreamFirstUnread(streams: IChatStream[], unreads: IStreamUnreads[]): IChatStream[] {
    const unreadsMap = createRecord(unreads, 'stream_id');

    return streams.map(entry => {
      return {
        ...entry,
        server_first_unread: unreadsMap.has(entry.stream_id)
          ? unreadsMap.get(entry.stream_id).unread_message_ids[0]
          : null
      }
    })
  }

  private parseStreamMetadata(streams: IChatStream[]): IChatStream[] {
    return streams.map(entry => {
      try {
        return {
          ...entry,
          meta: JSON.parse(entry.description)
        }
      } catch(err) {
        if (isDevMode()) console.error(err, entry.description);
        return {
          ...entry,
          meta: {}
        }
      }
    })
  }
}
