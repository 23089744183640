import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NotificationSidebarComponent } from './notification-sidebar.component';
import { NotificationMessageModule } from '../notification-message/notification-message.module';
import { TranslateModule } from '@ngx-translate/core';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { NotificationButtonModule } from '../notification-button/notification-button.module';

@NgModule({
  declarations: [NotificationSidebarComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    NotificationMessageModule,
    StyledScrollbarModule,
    NotificationButtonModule,
  ],
  exports: [NotificationSidebarComponent]
})
export class NotificationSidebarModule { }
