<div class="foreground"></div>
<ion-icon class="ico icon-view"></ion-icon>
<ng-container *ngIf="src && src.length">
  <img [src]="src" alt="">
</ng-container>
<ion-button *ngIf="canRemove" mode="md" class="remove-img-btn circle-chip-btn remove" (click)="$event.stopPropagation(); remove.next();">
  <ion-icon class="ico icon-remove"></ion-icon>
</ion-button>
<ion-button class="download-img-btn" mode="md" fill="clear" (click)="$event.stopPropagation(); download.next();">
  <img slot="icon-only" src="assets/img/download-img.svg" alt="">
</ion-button>
