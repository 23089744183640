import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit, Renderer2, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ColorsGroupComponent } from './colors-group/colors-group.component';
import { COLORS_MEETING, COLORS_DEFAULT, COLORS_SCHEMES, DISPLAY } from './colors';
export class ColorPickerComponent {
    constructor(popoverCtrl, renderer) {
        this.popoverCtrl = popoverCtrl;
        this.renderer = renderer;
        this.colorSelect = new EventEmitter();
        this.display = DISPLAY.ONLY_BUTTON;
        this.colorScheme = COLORS_SCHEMES.DEFAULT;
        this.hidden = false;
        this.title = '';
        this.disabled = false;
        this.displays = DISPLAY;
    }
    ngOnInit() {
    }
    ngOnChanges(changes) {
        if (changes.initColor && !changes.initColor.firstChange && changes.initColor.currentValue) {
            this.changeColor(changes.initColor.currentValue);
        }
    }
    ngAfterViewInit() {
        this.colors = this.colorScheme === 'default' ? COLORS_DEFAULT : COLORS_MEETING;
        if (this.initColor) {
            this.currentColor = this.initColor;
        }
        else {
            this.currentColor = this.colors[0];
        }
        this.setColor(this.currentColor);
        this.colorSelect.emit(this.currentColor);
    }
    showColorsGroup(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                event,
                showBackdrop: false,
                cssClass: 'color-picker-popover',
                component: ColorsGroupComponent,
                componentProps: {
                    currentColor: this.currentColor,
                    colors: this.colors,
                    onDismiss: (color) => {
                        this.changeColor(color);
                        popover.dismiss();
                    },
                },
            });
            popover.present();
        });
    }
    changeColor(color) {
        this.removeColor(this.currentColor);
        this.currentColor = color;
        this.setColor(this.currentColor);
        this.colorSelect.emit(this.currentColor);
    }
    setColor(color) {
        this.renderer.setProperty(this.buttonEl.nativeElement, 'style', `--background : ${color}`);
    }
    removeColor(color) {
        this.renderer.setProperty(this.buttonEl.nativeElement, 'style', `--background : none`);
    }
}
