import { Component, OnInit, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-grades-custom-generator',
  templateUrl: './grades-custom-generator.component.html',
  styleUrls: ['./grades-custom-generator.component.scss'],
})
export class GradesCustomGeneratorComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();
  @Output() generated = new EventEmitter<string[]>();

  generateFrom: string = '';
  generateTo: string = '';
  showGenerateError: boolean = false;

  private destroy$ = new Subject();

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    fromEvent(document, 'click')
      .pipe(
        filter(event => !this.elementRef.nativeElement.contains(event.target)),
        skip(1),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.close.emit());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  generateGradings() {
    if (this.generateFrom && this.generateFrom.length && this.generateTo && this.generateTo.length) {
      const generateFromType = this.getGeneratedGradingValueType(this.generateFrom);
      const generateToType = this.getGeneratedGradingValueType(this.generateTo);
      if (generateFromType !== 'invalid' && generateToType !== 'invalid' && generateFromType === generateToType) {
        this.showGenerateError = false;
        const result = [];
        if (generateFromType === 'letter') {
          const start = this.generateFrom.charCodeAt(0);
          const last  = this.generateTo.charCodeAt(0);
          if (last <= start) {
            this.showGenerateError = true;
            return;
          }
          for (let i = start; i <= last; ++i) {
            result.push(String.fromCharCode(i));
          }
        } else if (generateFromType === 'number') {
          const start = +this.generateFrom;
          const last  = +this.generateTo;
          if (last <= start) {
            this.showGenerateError = true;
            return;
          }
          for (let i = 1; i < last - start; ++i) {
            result.push((start + i).toString());
          }
          result.unshift(start.toString());
          result.push(last.toString());
        }
        this.generated.emit(result);
        this.close.emit();
      } else {
        this.showGenerateError = true;
      }
    } else {
      this.showGenerateError = true;
    }
  }

  private getGeneratedGradingValueType(value: string): 'letter' | 'number' | 'invalid' {
    if (!value || !value.length || value.length > 3 || (value.length > 1 && value[0] === '0')) {
      return 'invalid';
    }

    if (this.isLetter(value[0])) {
      return value.length === 1 ? 'letter' : 'invalid';
    } else if (this.isNumber(value[0])) {
      for (let index = 1; index < value.length; index++) {
        if (!this.isNumber(value[index])) {
          return 'invalid';
        }
      }
      return 'number';
    }
  }

  private isLetter(char: string): boolean {
    return (char.charCodeAt(0) >= 65 && char.charCodeAt(0) <= 90) ||
      (char.charCodeAt(0) >= 97 && char.charCodeAt(0) <= 122);
  }

  private isNumber(char: string): boolean {
    return (char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57);
  }
}
