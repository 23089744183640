import { BaseService } from './base.service';
import { EntityType, EventType, UserRole, UserTypes } from '../models/common';
import { HttpService } from './http.service';
import { forkJoin } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { addDashboardColumn$, editDashboardColumn$, removeDashboardColumn$ } from '../helpers/helpers';
import { InviteService } from './invite.service';
import { SocketService } from './socket.service';
import { ToastService } from './toast.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./toast.service";
import * as i3 from "./invite.service";
import * as i4 from "./socket.service";
export class ClassService extends BaseService {
    constructor(httpService, toastService, inviteService, socketService) {
        super(httpService, toastService);
        this.httpService = httpService;
        this.toastService = toastService;
        this.inviteService = inviteService;
        this.socketService = socketService;
        this.CLASS_URL = 'v1/classes';
        this.entityType = EntityType.CLASS;
        this.GET_ALL = this.CLASS_URL;
        this.GET_ALL_TO_TEACH = `${this.CLASS_URL}/teach`;
        this.GET_ALL_TO_STUDY = `${this.CLASS_URL}/study`;
        this.POST_ONE = this.CLASS_URL;
        this.PUT_ONE = this.CLASS_URL;
        this.PATCH_ONE = this.CLASS_URL;
        this.PATCH_ONE_DASHBOARD_COLUMN = 'v1/columns';
        this.PATCH_CHANGE_SCHOOL = `v1/classes/change-school`;
        this.GET_ONE = (id) => `${this.CLASS_URL}/${id}`;
        this.GET_ALL_BY_USER = (id, schoolId, type) => `${this.CLASS_URL}/by-user?userId=${id}&type=${type}&schoolId=${schoolId}`;
        this.GET_ALL_BY_SCHOOL = (id) => `${this.CLASS_URL}/by-school/${id}`;
        this.GET_CLASS_CARD_DATA = (id) => `${this.CLASS_URL}/card-data/${id}`;
        this.DELETE_ONE = (id) => `${this.CLASS_URL}/${id}`;
        this.GET_DASHBOARD_BY_CLASS = (id) => `${this.CLASS_URL}/dashboard/${id}`;
        this.GET_DASHBOARD_DATA_BY_CLASS_INIT = (id, limitByColumn) => `${this.CLASS_URL}/dashboard-data/${id}/init?limitByColumn=${limitByColumn}`;
        this.GET_DASHBOARD_DATA_BY_CLASS_BY_PAGE = (id, page, limit, columnId, selectedDate) => `${this.CLASS_URL}/dashboard-data/${id}?page=${page}&limit=${limit}&columnId=${columnId}&selectedDate=${selectedDate}`;
        this.DELETE_ONE_DASHBOARD_COLUMN = (id) => `v1/columns/${id}`;
        this.GET_TIMELINE_DATES_BY_COLUMN = (columnId, classId) => `v1/school-class-tickets/timeline-dates?columnId=${columnId}&schoolClassId=${classId}`;
        this.GET_TIMELINE_DATES_BY_ALL_COLUMNS = (classId) => `v1/school-class-tickets/timeline-dates-by-all-columns?schoolClassId=${classId}`;
        this.GET_TICKETS_NEAR_DATE_BY_COLUMN = (classId, columnId, selectedDate) => `v1/school-class-tickets/near-selected-date?schoolClassId=${classId}&columnId=${columnId}&selectedDate=${selectedDate}`;
        this.socketService.getCrudMessages$([EntityType.CLASS])
            .subscribe(payload => this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId }));
    }
    getAllTeach$() {
        return this.httpService.get$(this.GET_ALL)
            .pipe(share());
    }
    getAllStudy$() {
        return this.httpService.get$(this.GET_ALL_TO_STUDY)
            .pipe(share());
    }
    getDashboardByClassId$(id) {
        return this.httpService.get$(this.GET_DASHBOARD_BY_CLASS(id));
    }
    addDashboardColumn$(column, classId) {
        return addDashboardColumn$(column, classId, EntityType.CLASS, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.ADD_COLUMN, response, { entityId: classId })));
    }
    editDashboardColumn$(column, classId) {
        return editDashboardColumn$(column, EntityType.CLASS, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.EDIT_COLUMN, response, { entityId: classId })));
    }
    removeDashboardColumn$(column, classId) {
        return removeDashboardColumn$(column.id, EntityType.CLASS, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.REMOVE_COLUMN, response, { entityId: classId })));
    }
    getDashboardTicketsInit$(classId, limitByColumn) {
        return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_CLASS_INIT(classId, limitByColumn));
    }
    getDashboardTicketsByPage$(classId, page, limit, columnId, selectedDate) {
        return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_CLASS_BY_PAGE(classId, page, limit, columnId, selectedDate));
    }
    getDashboardUsers$(schoolClass, page, limit) {
        return forkJoin([
            this.inviteService.getUsers$('', UserTypes.TEACHER, [{ entityId: schoolClass.id, entityType: EntityType.CLASS }], page, limit, false),
            this.inviteService.getUsers$('', UserTypes.STUDENT, [{ entityId: schoolClass.id, entityType: EntityType.CLASS }], page, limit, false),
        ])
            .pipe(map(responses => {
            for (const response of responses) {
                if (response.error) {
                    return { error: response.error, message: response.message };
                }
            }
            return {
                payload: [
                    ...responses[0].payload.map(user => (Object.assign({}, user, { role: UserRole.TEACHER }))),
                    ...responses[1].payload.map(user => (Object.assign({}, user, { role: UserRole.STUDENT }))),
                ],
            };
        }));
    }
    getAllByUserId$(userId, schoolId) {
        return this.httpService.get$(this.GET_ALL_BY_USER(userId, schoolId, 'teacher'));
    }
    getAllBySchoolId$(schoolId) {
        return this.httpService.get$(this.GET_ALL_BY_SCHOOL(schoolId));
    }
    getClassCardData$(classId) {
        return this.httpService.get$(this.GET_CLASS_CARD_DATA(classId));
    }
    changeSchool$(payload) {
        return this.httpService.patch$(this.PATCH_CHANGE_SCHOOL, payload);
    }
    getTimelineDatesByColumn$(columnId, schoolClassId) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_COLUMN(columnId, schoolClassId));
    }
    getTimelineDatesByAllColumns$(schoolClassId) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_ALL_COLUMNS(schoolClassId));
    }
    getTicketsNearDateByColumn$(schoolId, columnId, selectedDate) {
        return this.httpService.get$(this.GET_TICKETS_NEAR_DATE_BY_COLUMN(schoolId, columnId, selectedDate));
    }
    notifyAboutUpdateColumn(eventType, response, payload) {
        if (response.error || !this.socketService.isConnected()) {
            this.notifyAboutUpdate(eventType, response, payload);
        }
    }
}
ClassService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClassService_Factory() { return new ClassService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.InviteService), i0.ɵɵinject(i4.SocketService)); }, token: ClassService, providedIn: "root" });
