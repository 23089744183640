import {Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IPopoverNotification, PopoverNotificationType } from './types';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popover-notification',
  templateUrl: './popover-notification.component.html',
  styleUrls: ['./popover-notification.component.scss'],
})
export class PopoverNotificationComponent implements OnInit, OnDestroy {
  @Input() addNotification$: Observable<IPopoverNotification<any>>;
  @Input() removeNotification$: Observable<string>;
  @Input() onDismiss: () => void;

  isTotalHeight = false;

  notifications: IPopoverNotification<any>[] = [];
  types = PopoverNotificationType;

  private destroy$ = new Subject();

  constructor() { }

  ngOnInit() {
    this.addNotification$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(newNotification => {
        const currentNotifications = this.notifications.slice();
        currentNotifications.push(newNotification);
        this.notifications = currentNotifications.slice();
        this.updateContainerHeight();
      });

    this.removeNotification$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(notificationId => {
        const currentNotifications = this.notifications.slice();
        const index = currentNotifications.findIndex(n => n.uuid === notificationId);
        if (index !== -1) {
          currentNotifications.splice(index, 1);
        }
        this.notifications = currentNotifications.slice();
        if (!this.notifications.length) {
          this.onDismiss();
        }
        this.updateContainerHeight();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  updateContainerHeight() {
    const height = window.innerHeight <= 667 && this.notifications.length >= 3 || this.notifications.length >= 5 ? '100%' : 'fit-content';
    this.isTotalHeight = height === '100%';
    document.documentElement.style.setProperty('--popover-notification-height', height);
  }
}
