import { Component, OnInit, ViewChild, ViewContainerRef, AfterViewInit, ElementRef, NgZone } from '@angular/core';

import { Platform, IonMenu, PopoverController, NavController, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AuthService } from './auth/services/auth.service';
import { EntityCrudViewService } from './shared/services/entity-crud-view.service';
import { LoadingService } from './shared/services/loading.service';
import { SocketService } from './shared/services/socket.service';
import { AppMenuService } from './shared/services/menu.service';
import { PopoverNotificationService } from './shared/services/popover-notification.service';
import { MeetingNotificationService } from './shared/services/meeting-notification.service';
import { ChatWidgetPage, ChatWidgetPages, ChatWidgetInputParams } from './pages/chat-widget/chat-widget.page';
import { PushNotificationService } from './shared/services/push-notification.service';
import { AppUpdateService } from './shared/services/app-update.service';
import { SubjectStoreService } from './shared/services/stores/subject-store.service';
import { SchoolStoreService } from './shared/services/stores/school-store.service';
import { ClassStoreService } from './shared/services/stores/class-store.service';
import { MaterialStoreService } from './shared/services/stores/material-store.service';
import { ZulipService } from './shared/services/zulip.service';
import { Store } from '@ngrx/store';
import * as ChatActions from './store/actions/chat';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, withLatestFrom, delay, first, switchMap, takeUntil } from 'rxjs/operators';
import { selectAllUnreads } from './store/selectors/chat.selectors';
import { from, Observable, of } from 'rxjs';
import { NotificationSidebarService } from './shared/services/notification-sidebar.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { PlatformService } from './shared/services/platform.service';
import { LinkStorageService, LinkStorageEntity } from './shared/services/link-storage.service';
import { InviteLoadingModalComponent } from './shared/components/invite-loading/invite-loading-modal.component';
import { ChatUiService } from './shared/services/chat-ui.service';

import {
    Plugins,
    StatusBarStyle,
  } from '@capacitor/core';
import { RemoteConfigService } from './shared/services/remote-config.service';
import { ChatToggleService } from './shared/services/chat-toggle.service';
import { WhiteboardService } from './shared/services/whiteboard.service';
import { OnboardingGuideService } from './shared/services/onboarding-guide.service';
import { OnboardingService } from './shared/services/onboarding.service';
import { IOnboarding } from './shared/models/onboarding';
import { InviteService } from './shared/services/invite.service';
import { DefinedQueryParam } from './shared/models/common';
  
  const { StatusBar } = Plugins;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('createMenuContainer', { static: false, read: ViewContainerRef }) createMenuContainer: ViewContainerRef;
    @ViewChild('popoverNotificationContainer', { static: false, read: ViewContainerRef }) popoverNotificationContainer: ViewContainerRef;
    @ViewChild('ionMenu', { static: false }) ionMenuRef: IonMenu;
    @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;
    @ViewChild('chatBtn', { static: false, read: ElementRef }) chatButtonEl: ElementRef;

    public isMenuCollapsed: boolean;
    public showChatFab: boolean = false;
    public chatFabCounter$: Observable<number>;
    private isChatOpened: boolean;
    private chatInputParams: ChatWidgetInputParams;

    constructor(
        public loadingService: LoadingService,
        public authService: AuthService,
        public appUpdateService: AppUpdateService,
        public notificationSidebarService: NotificationSidebarService,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private entityCrudViewService: EntityCrudViewService,
        private socketService: SocketService,
        private appMenuService: AppMenuService,
        private popoverNotificationService: PopoverNotificationService,
        private meetingNotificationService: MeetingNotificationService,
        private popoverCtrl: PopoverController,
        private pushNotificationService: PushNotificationService,
        private schoolStoreService: SchoolStoreService,
        private classStoreService: ClassStoreService,
        private subjectStoreService: SubjectStoreService,
        private materialStoreService: MaterialStoreService,
        private zulipService: ZulipService,
        private store: Store,
        private router: Router,
        private screenOrientation: ScreenOrientation,
        private platformService: PlatformService,
        private linkStorageService: LinkStorageService,
        private navCtrl: NavController,
        private chatUiService: ChatUiService,
        private chatToggleService: ChatToggleService,
        private remoteConfigService: RemoteConfigService,
        private whiteboardService: WhiteboardService,
        private onboardingService: OnboardingService,
        private onboardignGuideService: OnboardingGuideService,
        private zone: NgZone,
        private inviteService: InviteService,
    ) {
        this.initializeApp();
        this.appMenuService.collapsedChanges$.subscribe(isCollapsed => {
            this.isMenuCollapsed = isCollapsed;
        });
        this.chatFabCounter$ = this.store.select(selectAllUnreads).pipe(
            distinctUntilChanged()
        );
    }
    public selectedIndex = 0;
    public appPages = [
        {
            title: 'Inbox',
            url: '/folder/Inbox',
            icon: 'mail'
        },
        {
            title: 'Outbox',
            url: '/folder/Outbox',
            icon: 'paper-plane'
        },
        {
            title: 'Favorites',
            url: '/folder/Favorites',
            icon: 'heart'
        },
        {
            title: 'Archived',
            url: '/folder/Archived',
            icon: 'archive'
        },
        {
            title: 'Trash',
            url: '/folder/Trash',
            icon: 'trash'
        },
        {
            title: 'Spam',
            url: '/folder/Spam',
            icon: 'warning'
        }
    ];
    isMenuVisible: boolean = false;

    initializeApp() {
        this.platform.ready().then(() => {
            this.platformService.setOS();
            if (this.platformService.isMobile) {
                StatusBar.setStyle({ style: StatusBarStyle.Light });
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY);
            }
            console.log('%c [PLATFORM]: ', 'color: green', this.platform);

            this.remoteConfigService.initialize();
        });
    }

    onMenuNavChange() {
        if (!this.platformService.isDesktop) {
            this.ionMenuRef.close(true);
        }
    }

    ngOnInit() {
        this.authService.isAuthenticated$
            .subscribe(isAuthenticated => {
                console.log('%c [AUTHENTICATED]: ', 'color: green', isAuthenticated);
                if (isAuthenticated === true) {
                    this.showChatFab = true;
                    this.isMenuVisible = true;
                    this.socketService.connect();
                    this.meetingNotificationService.checkForPendingMeetings();
                    this.meetingNotificationService.checkForRecentJoinMeetings();
                    this.schoolStoreService.init();
                    this.classStoreService.init();
                    this.subjectStoreService.init();
                    this.materialStoreService.init();
                    this.pushNotificationService.registerToken();
                    this.checkLinkSessionsAndRedirect();
                    this.setRouterOutlet();
                    this.initOnboarding();
                } else {
                    this.showChatFab = false;
                    this.isMenuVisible = false;
                    this.socketService.disconnect();
                    this.schoolStoreService.destroy();
                    this.classStoreService.destroy();
                    this.subjectStoreService.destroy();
                    this.materialStoreService.destroy();
                    this.store.dispatch(ChatActions.resetChatState());
                    this.pushNotificationService.deleteToken();
                    this.clearRouterOutlet();
                }
            });

        this.appUpdateService.listenSWUpdates();
        this.zulipService.init();

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                withLatestFrom(this.authService.isAuthenticated$)
            )
            .subscribe(([event, isAuthenticated]: [NavigationEnd, boolean]) => {
                const url = new URL(location.href)

                if (url.searchParams.has(DefinedQueryParam.POST_SHARING)) {
                    this.appMenuService.disableMenu(false)
                }

                this.isMenuVisible = this.showChatFab = (
                    isAuthenticated &&
                    !event.url.startsWith('/public') &&
                    !event.url.startsWith('/pages/chat') &&
                    !event.url.startsWith('/pages/share') && 
                    !event.url.startsWith('/pages/ticket/SHARING/TICKET_TASK/SUBJECT')
                );
            });

        this.chatUiService.openChatStream$()
            .pipe(
                filter(() => !!this.chatButtonEl),
            )
            .subscribe(streamId => {
                this.chatInputParams = { selectedChannelId: streamId, activePage: ChatWidgetPages.Log };
                (this.chatButtonEl.nativeElement as HTMLElement).click();
            });
        this.chatUiService.closeChatWithUser$.subscribe(() => this.chatInputParams = null);

        this.chatToggleService.openChatNotifier.subscribe(() => {
            this.openChatPopover(null)
        });
    }

    private initOnboarding() {
        if (this.isMobile) {
            return
        }

        const logout$ = this.authService.isAuthenticated$.pipe(
            first(isAuthenticated => !isAuthenticated)
        )
        const appLoaded$ = this.router.events.pipe(
            first(event => event instanceof NavigationEnd),
            switchMap(() => this.zone.onStable),
            first()
        )
        appLoaded$.pipe(
            switchMap(() => this.onboardingService.showOnboardingGuide$()),
            takeUntil(logout$),
            delay(1000),
            switchMap((onboarding) => 
                from(this.onboardignGuideService.showGuide(onboarding))
            ),
            filter((onboarding): onboarding is IOnboarding => onboarding != null),
        )
        .subscribe(onboarding => {
            this.onboardingService.markOnboardingComplete(onboarding);
        });
    }

    ngAfterViewInit() {
        this.entityCrudViewService.setCreateMenuContainer(this.createMenuContainer);
        this.popoverNotificationService.setViewContainer(this.popoverNotificationContainer);
        this.setRouterOutlet();
    }

    get isMobile(): boolean {
        return this.platformService.isMobile;
    }

    get isMenuDisabled$(): Observable<boolean> {
        return this.appMenuService.isDisabled$;
    }

    openChat(event: MouseEvent) {
        if (this.isChatOpened) {
            return;
        }

        this.isChatOpened = true;
        this.openChatPopover(event, this.chatInputParams);
        this.chatInputParams = null;
    }

    private async openChatPopover(event: Event, params?: ChatWidgetInputParams) {
        const cssClasses: string[] = ['chat-widget-popup'];
        if (this.platformService.isMobile) {
            cssClasses.push('mobile-view');
        }
        if (this.platformService.isMobile && this.whiteboardService.checkWhiteboardOpened()) {
            cssClasses.push('chat-widget-over-wb');
        }
        const componentParams = params ? params : {};
        const popover = await this.popoverCtrl.create({
            component: ChatWidgetPage,
            showBackdrop: false,
            cssClass: cssClasses,
            componentProps: {
                doDismiss: () => popover.dismiss(),
                ...componentParams,
            },
            event
        });
        popover.onWillDismiss().then(() => {
            this.isChatOpened = false;
        });
        await popover.present();
    }

    private checkLinkSessionsAndRedirect(): void {
        of(true)
            .pipe(delay(2000), first())
            .subscribe(() => {
                if (this.linkStorageService.getInviteLink()) {
                    this.checkStoredInviteLink();
                } else if (this.linkStorageService.getTicketLink()) {
                    this.checkStoredEntityLink('Ticket');
                } else if (this.linkStorageService.getSchoolLink()) {
                    this.checkStoredEntityLink('School');
                } else if (this.linkStorageService.getClassLink()) {
                    this.checkStoredEntityLink('SchoolClass');
                } else if (this.linkStorageService.getSubjectLink()) {
                    this.checkStoredEntityLink('Subject');
                } else if (this.linkStorageService.getShareLink()) {
                    this.checkStoredEntityLink('Share');
                }
                this.linkStorageService.clearAll();
            });
    }

    private async checkStoredInviteLink(): Promise<void> {
        const inviteSession = this.linkStorageService.getInviteLink()
            ? JSON.parse(this.linkStorageService.getInviteLink())
            : null;

        if (inviteSession && inviteSession.code) {
            this.inviteService.startPendingJoin()

            const popver = await this.popoverCtrl.create({
                component: InviteLoadingModalComponent,
                componentProps: {
                    session: inviteSession,
                    doDismiss: () => {
                        popver.dismiss();
                    }
                },
                backdropDismiss: false,
                cssClass: 'invite-modal',
            });

            return popver.present();
        }

        return Promise.resolve();
    }

    private async checkStoredEntityLink(entity: LinkStorageEntity): Promise<any> {
        let entityRouteSession;
        switch (entity) {
            case 'Ticket':
                entityRouteSession = this.linkStorageService.getTicketLink();
                break;
            case 'School':
                entityRouteSession = this.linkStorageService.getSchoolLink();
                break;
            case 'SchoolClass':
                entityRouteSession = this.linkStorageService.getClassLink();
                break;
            case 'Subject':
                entityRouteSession = this.linkStorageService.getSubjectLink();
                break;
            case 'Share':
                entityRouteSession = this.linkStorageService.getShareLink()
        }

        if (entityRouteSession) {
            if (entity === 'Share') {
                return this.navCtrl.navigateRoot(entityRouteSession);
            }

            return this.navCtrl.navigateForward(entityRouteSession);
        }

        return this.navCtrl.navigateRoot('pages/home');
    }

    private setRouterOutlet(): void {
        if (this.routerOutlet) {
            this.appMenuService.setRouterOutlet(this.routerOutlet);
        }
    }

    private clearRouterOutlet(): void {
        this.appMenuService.clearRouterOutlet();
    }
}
