
export enum EnvironmentMode {
  Stage,
  Prod,
}
export interface IAppApiConfig {
  apiEndpoint: string;
  websocketUrl: string;
  janusSignalingUrl: string;
  groupworldEndpoint: string;
  zulipInstanceEndpoint: string;
  zulipApiEndpoint: string;
  webUrl?: string;
}

export interface IAppEnvironment extends IAppApiConfig {
  production: boolean;
  showLogs: boolean;
  firebase: any;
  hideZulipChatOlderThenHours: number;
}

export const COMMON_API_CONFIG: IAppApiConfig = {
  apiEndpoint: `${process.env.API_ENDPOINT}/api`,
  websocketUrl: process.env.WS_API_ENDPOINT,
  janusSignalingUrl: process.env.JANUS_SIGNALING_URL,
  groupworldEndpoint: process.env.GROUPWORLD_ENDPOINT,
  zulipInstanceEndpoint: process.env.ZULIP_SERVER_ENDPOINT,
  zulipApiEndpoint: process.env.ZULIP_API_ENDPOINT,
}
