import { BaseService } from './base.service';
import { HttpService } from './http.service';
import { EntityType, EventType, UserRole, UserTypes } from '../models/common';
import { forkJoin } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { addDashboardColumn$, editDashboardColumn$, removeDashboardColumn$ } from '../helpers/helpers';
import { InviteService } from './invite.service';
import { SocketService } from './socket.service';
import { ToastService } from './toast.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./toast.service";
import * as i3 from "./invite.service";
import * as i4 from "./socket.service";
export class SchoolService extends BaseService {
    constructor(httpService, toastService, inviteService, socketService) {
        super(httpService, toastService);
        this.httpService = httpService;
        this.toastService = toastService;
        this.inviteService = inviteService;
        this.socketService = socketService;
        this.SCHOOL_URL = 'v1/schools';
        this.entityType = EntityType.SCHOOL;
        this.GET_ALL = this.SCHOOL_URL;
        this.GET_ALL_TO_TEACH = `${this.SCHOOL_URL}/teach`;
        this.GET_ALL_TO_STUDY = `${this.SCHOOL_URL}/study`;
        this.POST_ONE = this.SCHOOL_URL;
        this.PUT_ONE = this.SCHOOL_URL;
        this.PATCH_ONE = this.SCHOOL_URL;
        this.GET_ONE = (id) => `${this.SCHOOL_URL}/${id}`;
        this.DELETE_ONE = (id) => `${this.SCHOOL_URL}/${id}`;
        this.GET_DASHBOARD_BY_SCHOOL = (id) => `${this.SCHOOL_URL}/dashboard/${id}`;
        this.GET_DASHBOARD_DATA_BY_SCHOOL_BY_PAGE = (id, page, limit, columnId, selectedDate) => `${this.SCHOOL_URL}/dashboard-data/${id}?page=${page}&limit=${limit}&columnId=${columnId}&selectedDate=${selectedDate}`;
        this.GET_DASHBOARD_DATA_BY_SCHOOL_INIT = (id, limitByColumn) => `${this.SCHOOL_URL}/dashboard-data/${id}/init?limitByColumn=${limitByColumn}`;
        this.GET_CLASSES_BY_SCHOOL = (id) => `${this.SCHOOL_URL}/${id}/classes`;
        this.GET_TIMELINE_DATES_BY_COLUMN = (columnId, schoolId) => `v1/school-tickets/timeline-dates?columnId=${columnId}&schoolId=${schoolId}`;
        this.GET_TIMELINE_DATES_BY_ALL_COLUMNS = (schoolId) => `v1/school-tickets/timeline-dates-by-all-columns?schoolId=${schoolId}`;
        this.GET_TICKETS_NEAR_DATE_BY_COLUMN = (schoolId, columnId, selectedDate) => `v1/school-tickets/near-selected-date?schoolId=${schoolId}&columnId=${columnId}&selectedDate=${selectedDate}`;
        this.socketService.getCrudMessages$([EntityType.SCHOOL])
            .subscribe(payload => this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId }));
    }
    getAllTeach$() {
        return this.httpService.get$(this.GET_ALL)
            .pipe(share());
    }
    getAllStudy$() {
        return this.httpService.get$(this.GET_ALL_TO_STUDY)
            .pipe(share());
    }
    getDashboardBySchoolId$(id) {
        return this.httpService.get$(this.GET_DASHBOARD_BY_SCHOOL(id));
    }
    getClassesBySchoolId$(id) {
        return this.httpService.get$(this.GET_CLASSES_BY_SCHOOL(id));
    }
    addDashboardColumn$(column, schoolId) {
        return addDashboardColumn$(column, schoolId, EntityType.SCHOOL, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.ADD_COLUMN, response, { entityId: schoolId })));
    }
    editDashboardColumn$(column, schoolId) {
        return editDashboardColumn$(column, EntityType.SCHOOL, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.EDIT_COLUMN, response, { entityId: schoolId })));
    }
    removeDashboardColumn$(column, schoolId) {
        return removeDashboardColumn$(column.id, EntityType.SCHOOL, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.REMOVE_COLUMN, response, { entityId: schoolId })));
    }
    getDashboardTicketsInit$(schoolId, limitByColumn) {
        return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SCHOOL_INIT(schoolId, limitByColumn));
    }
    getDashboardTicketsByPage$(schoolId, page, limit, columnId, selectedDate) {
        return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SCHOOL_BY_PAGE(schoolId, page, limit, columnId, selectedDate));
    }
    getDashboardUsers$(school, page, limit) {
        return forkJoin([
            this.inviteService.getUsers$('', UserTypes.TEACHER, [{ entityId: school.id, entityType: EntityType.SCHOOL }], page, limit, false),
            this.inviteService.getUsers$('', UserTypes.STUDENT, [{ entityId: school.id, entityType: EntityType.SCHOOL }], page, limit, false, false),
        ])
            .pipe(map(responses => {
            for (const response of responses) {
                if (response.error) {
                    return { error: response.error, message: response.message };
                }
            }
            return {
                payload: [
                    ...responses[0].payload.map(user => (Object.assign({}, user, { role: UserRole.TEACHER }))),
                    ...responses[1].payload.map(user => (Object.assign({}, user, { role: UserRole.STUDENT }))),
                ],
            };
        }));
    }
    getTimelineDatesByColumn$(columnId, schoolId) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_COLUMN(columnId, schoolId));
    }
    getTimelineDatesByAllColumns$(schoolId) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_ALL_COLUMNS(schoolId));
    }
    getTicketsNearDateByColumn$(schoolId, columnId, selectedDate) {
        return this.httpService.get$(this.GET_TICKETS_NEAR_DATE_BY_COLUMN(schoolId, columnId, selectedDate));
    }
    notifyAboutUpdateColumn(eventType, response, payload) {
        if (response.error || !this.socketService.isConnected()) {
            this.notifyAboutUpdate(eventType, response, payload);
        }
    }
}
SchoolService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SchoolService_Factory() { return new SchoolService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.InviteService), i0.ɵɵinject(i4.SocketService)); }, token: SchoolService, providedIn: "root" });
