import { Subject, merge } from 'rxjs';
import { tap, map, first, filter } from 'rxjs/operators';
import { PopoverNotificationService } from './popover-notification.service';
import { PopoverNotificationType } from '../components/popover-notification/types';
import { HttpService } from './http.service';
import { SocketService } from './socket.service';
import { SocketMessageType } from '../models/common';
import * as i0 from "@angular/core";
import * as i1 from "./popover-notification.service";
import * as i2 from "./http.service";
import * as i3 from "./socket.service";
export class MeetingNotificationService {
    constructor(popoverNotificationService, httpService, socketService) {
        this.popoverNotificationService = popoverNotificationService;
        this.httpService = httpService;
        this.socketService = socketService;
        this.GET_PENDING_MEETINGS = 'v1/meetings/pendings';
        this.GET_RECENT_JOIN_MEETINGS = 'v1/meetings/join-recents';
        this.CHANGE_MEETING_ATTENDEE_STATUS = 'v2/meetings/change-user-status';
        this.newMeetingNotification$ = new Subject();
        this.meetingAttendeUpdatesEmitter$ = new Subject();
        this.meetingSerialStatusUpdatesEmitter$ = new Subject();
        this.socketService.messages$()
            .pipe(filter(message => message.type === SocketMessageType.MEETING_ATTENDEE_STATUS_UPDATES), map(message => message.payload), tap(message => this.meetingSerialStatusUpdatesEmitter$.next(message)))
            .subscribe();
        const newMeetingAttendeeSocket$ = this.socketService.messages$()
            .pipe(filter(message => message.type === SocketMessageType.MEETING_ATTENDEE), map(message => ({ type: PopoverNotificationType.MEETING_ATTENDEE, payload: message.payload })));
        const newMeetingAttendeeJoinSocket$ = this.socketService.messages$()
            .pipe(filter(message => message.type === SocketMessageType.MEETING_ATTENDEE_JOIN), map(message => ({ type: PopoverNotificationType.MEETING_JOIN, payload: message.payload })));
        merge(newMeetingAttendeeSocket$, newMeetingAttendeeJoinSocket$)
            .subscribe(data => this.newMeetingNotification$.next(data));
        this.newMeetingNotification$
            .pipe(map(data => this.popoverNotificationService.getPopoverNotification(data.type, data.payload)), tap(popoverNotification => this.popoverNotificationService.addNotification(popoverNotification)))
            .subscribe();
    }
    handleMeetingAttendeeNotification$(meetingId, meetingSerialId, uuid, operation) {
        return this.httpService.patch$(this.CHANGE_MEETING_ATTENDEE_STATUS, { meeting_serial_id: meetingSerialId, status: operation })
            .pipe(tap(response => {
            if (!response.error) {
                this.popoverNotificationService.removeNotification(uuid);
                this.meetingAttendeUpdatesEmitter$.next(meetingId);
                this.meetingSerialStatusUpdatesEmitter$.next({ meeting_serial_id: meetingSerialId, meeting_status: operation });
            }
        }), map(response => response.payload ? Object.assign({}, response, { payload: true }) : Object.assign({}, response, { payload: false })));
    }
    handleMeetingAttendeeJoinNotification(meetingId, uuid) {
        this.popoverNotificationService.removeNotification(uuid);
        this.meetingAttendeUpdatesEmitter$.next(meetingId);
    }
    checkForPendingMeetings() {
        this.getPendingMeetings$()
            .pipe(first(), map(response => response.payload ? response.payload : []), filter(meetings => meetings.length > 0))
            .subscribe(meetings => {
            meetings.forEach(meeting => {
                this.newMeetingNotification$.next({ type: PopoverNotificationType.MEETING_ATTENDEE, payload: meeting });
            });
        });
    }
    checkForRecentJoinMeetings() {
        this.getRecentJoinMeetings$()
            .pipe(first(), map(response => response.payload ? response.payload : []), filter(meetings => meetings.length > 0))
            .subscribe(meetings => {
            meetings.forEach(meeting => {
                this.newMeetingNotification$.next({ type: PopoverNotificationType.MEETING_JOIN, payload: meeting });
            });
        });
    }
    getMeetingAttendeeUpdates$() {
        return this.meetingAttendeUpdatesEmitter$.asObservable();
    }
    getMeetingSerialStatusUpdates$() {
        return this.meetingSerialStatusUpdatesEmitter$.asObservable();
    }
    getPendingMeetings$() {
        return this.httpService.get$(this.GET_PENDING_MEETINGS);
    }
    getRecentJoinMeetings$() {
        return this.httpService.get$(this.GET_RECENT_JOIN_MEETINGS);
    }
}
MeetingNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MeetingNotificationService_Factory() { return new MeetingNotificationService(i0.ɵɵinject(i1.PopoverNotificationService), i0.ɵɵinject(i2.HttpService), i0.ɵɵinject(i3.SocketService)); }, token: MeetingNotificationService, providedIn: "root" });
