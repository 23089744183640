import { BehaviorSubject, combineLatest, merge, of, zip } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { EntityType } from '../models/common';
import { ClassService } from './class.service';
import { SchoolService } from './school.service';
import { ClassStoreService } from './stores/class-store.service';
import { SchoolStoreService } from './stores/school-store.service';
import { SubjectStoreService } from './stores/subject-store.service';
import { SubjectService } from './subject.service';
import * as i0 from "@angular/core";
import * as i1 from "./stores/subject-store.service";
import * as i2 from "./stores/class-store.service";
import * as i3 from "./stores/school-store.service";
import * as i4 from "./subject.service";
import * as i5 from "./class.service";
import * as i6 from "./school.service";
export class EntityAdapterService {
    constructor(subjectStoreService, classStoreService, schoolStoreService, subjectService, classService, schoolService) {
        this.subjectStoreService = subjectStoreService;
        this.classStoreService = classStoreService;
        this.schoolStoreService = schoolStoreService;
        this.subjectService = subjectService;
        this.classService = classService;
        this.schoolService = schoolService;
        this.onEntitiesLoadedSubject$ = new BehaviorSubject(false);
        const allStates$ = combineLatest([
            this.subjectStoreService.getState$().pipe(map((state) => ({
                etities: this.flatMapStoreState(state),
                type: EntityType.SUBJECT
            }))),
            this.classStoreService.getState$().pipe(map((state) => ({
                etities: this.flatMapStoreState(state),
                type: EntityType.CLASS
            }))),
            this.schoolStoreService.getState$().pipe(map((state) => ({
                etities: this.flatMapStoreState(state),
                type: EntityType.SCHOOL
            })))
        ]);
        zip(this.subjectStoreService.getState$().pipe(first(entities => entities !== null)), this.classStoreService.getState$().pipe(first(entities => entities !== null)), this.schoolStoreService.getState$().pipe(first(entities => entities !== null))).pipe(first()).subscribe(() => {
            this.onEntitiesLoadedSubject$.next(true);
        });
        this.sharedState$ = allStates$.pipe(map(entries => entries.reduce((all, entry) => {
            all[entry.type] = entry.etities;
            return all;
        }, {})), shareReplay(1));
        this.onEntityUpdate$ = merge(this.subjectService.notifier$, this.classService.notifier$, this.schoolService.notifier$).pipe(shareReplay(1));
    }
    get onEntitiesLoaded$() {
        return this.onEntitiesLoadedSubject$.asObservable();
    }
    getOne$(id, type, securityLevel = 'private') {
        return this.sharedState$.pipe(first(), map(state => {
            if (Array.isArray(state[type])) {
                return state[type].find(entry => entry.id === id);
            }
            return null;
        }), switchMap(data => data != null ? of(data) : this.getRemoteOne$(id, type, securityLevel)));
    }
    getRemoteOne$(id, type, securityLevel) {
        let source$ = of(null);
        switch (type) {
            case EntityType.SUBJECT: {
                source$ = securityLevel === 'public' ? this.subjectService.getPublicData$(id) : this.subjectService.getOne$(id);
                break;
            }
            case EntityType.SCHOOL: {
                source$ = this.schoolService.getOne$(id);
                break;
            }
            case EntityType.CLASS: {
                source$ = this.classService.getOne$(id);
                break;
            }
            default: throw new Error(`Unsupported entity type ${type}`);
        }
        return source$.pipe(map(res => res.payload ? res.payload : null));
    }
    flatMapStoreState(state) {
        if (state == null)
            return [];
        return Object.values(state).reduce((all, entities) => all.concat(entities), []);
    }
}
EntityAdapterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntityAdapterService_Factory() { return new EntityAdapterService(i0.ɵɵinject(i1.SubjectStoreService), i0.ɵɵinject(i2.ClassStoreService), i0.ɵɵinject(i3.SchoolStoreService), i0.ɵɵinject(i4.SubjectService), i0.ɵɵinject(i5.ClassService), i0.ɵɵinject(i6.SchoolService)); }, token: EntityAdapterService, providedIn: "root" });
