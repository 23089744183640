import { isReccuringMeeting, IMeeting, IMeetingUpdate } from 'src/app/shared/models/meeting';
import { ConfirmAction, EntityType, EventType, IPublishEvent } from 'src/app/shared/models/common';
import { ConfirmationService } from 'src/app/shared/services/confirmation.service';
import { MeetingService } from 'src/app/shared/services/meeting.service';

export function removeMeeting(
  meeting: IMeeting,
  meetingService: MeetingService,
  confirmationService: ConfirmationService,
) {
  if (isReccuringMeeting(meeting)) {
    return removeReccuringMeetings(meeting, meetingService, confirmationService);
  }
  removeOneMeeting(meeting, meetingService, confirmationService);
}

function removeReccuringMeetings(
  meeting: IMeeting,
  meetingService: MeetingService,
  confirmationService: ConfirmationService,
) {
  confirmationService.confirmAction(ConfirmAction.REMOVE_MEETING)
    .then(resRemoveMeeting => {
      if (resRemoveMeeting.isConfirmed) {
        const deleteSeries = resRemoveMeeting.payload;
        if (deleteSeries) {
          return confirmationService.confirmAction(ConfirmAction.REMOVE_SERIES_MEETING)
            .then(resRemoveSeries => {
              if (resRemoveSeries.isConfirmed) {
                removeMeetingPublish(meetingService, meeting, true);
              }
            });
        }
        removeMeetingPublish(meetingService, meeting, false);
      }
    });
}

function removeOneMeeting(
  meeting: IMeeting,
  meetingService: MeetingService,
  confirmationService: ConfirmationService,
) {
  confirmationService.confirmAction(ConfirmAction.REMOVE_ONE_MEETING)
    .then(res => {
      if (res.isConfirmed) {
        removeMeetingPublish(meetingService, meeting, false);
      }
    });
}

function removeMeetingPublish(
  meetingService: MeetingService,
  meeting: IMeeting,
  deleteSeries: boolean,
): void {
  meetingService.publishUpdate({
    entity: {
      ...meeting,
      changeAll: deleteSeries,
    } as IMeetingUpdate,
    entityType: EntityType.MEETING,
    eventType: EventType.REMOVE,
  } as IPublishEvent<IMeeting>);
}
