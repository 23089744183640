import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatLogPage } from './chat-log.page';
import { ChatLogComponentModule } from 'src/app/shared/components/chat-log/chat-log.module';
import { RemoveContentSizingDirectiveModule } from 'src/app/shared/directives/remove-content-sizing/remove-content-sizing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChatClosedNoticeComponentModule } from 'src/app/shared/components/chat-closed-notice/chat-closed-notice.module';
import { ChatConversationItemComponentModule } from 'src/app/shared/components/chat-conversation-item/chat-conversation-item.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ChatLogComponentModule,
    RemoveContentSizingDirectiveModule,
    ChatClosedNoticeComponentModule,
    ChatConversationItemComponentModule,
  ],
  declarations: [ChatLogPage],
  exports: [ChatLogPage]
})
export class ChatLogPageModule {}
