import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerComponent } from './color-picker.component';
import { IonicModule } from '@ionic/angular';
import { ColorsGroupComponent } from './colors-group/colors-group.component';

@NgModule({
  declarations: [
    ColorPickerComponent,
    ColorsGroupComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    ColorPickerComponent,
    ColorsGroupComponent,
  ],
  entryComponents: [
    ColorsGroupComponent,
  ],
})
export class ColorPickerModule { }
