import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteModalComponent } from './invite-modal.component';
import { IonicModule } from '@ionic/angular';
import { SelectInputModule } from '../select-input/select-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InviteModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    SelectInputModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [InviteModalComponent],
  entryComponents: [InviteModalComponent],
})
export class InviteModalModule { }
