import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-update-notification',
  templateUrl: './app-update-notification.component.html',
  styleUrls: ['./app-update-notification.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(-120%)' }),
            animate('.5s ease-out',
                    style({ transform: 'translateX(0)' }))
          ]
        ),
      ]
    )
  ],
})
export class AppUpdateNotificationComponent {

  constructor() { }

  refresh() {
    window.location.reload();
  }

}
