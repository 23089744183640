import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { StyledScrollbarModule } from 'src/app/shared/components/styled-scrollbar/styled-scrollbar.module';
import { RemoveContentSizingDirectiveModule } from 'src/app/shared/directives/remove-content-sizing/remove-content-sizing.module';
import { ChatInfoComponentModule } from 'src/app/shared/components/chat-info/chat-info.module';
import { ChatInfoPage } from './chat-info.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    StyledScrollbarModule,
    RemoveContentSizingDirectiveModule,
    ChatInfoComponentModule,
  ],
  declarations: [ChatInfoPage],
  exports: [ChatInfoPage],
})
export class ChatInfoPageModule {}
