import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatFinderComponent } from './chat-finder.component';
import { ChatConversationItemComponentModule } from '../chat-conversation-item/chat-conversation-item.module';
import { RemoveContentSizingDirectiveModule } from '../../directives/remove-content-sizing/remove-content-sizing.module';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    RemoveContentSizingDirectiveModule,
    StyledScrollbarModule,
    TranslateModule,
    ChatConversationItemComponentModule,
    PipesModule,
  ],
  declarations: [ChatFinderComponent],
  exports: [ChatFinderComponent]
})
export class ChatFinderComponentModule {}
