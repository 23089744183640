import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {TaskPage} from './task.page';
import {UpdateNotificationHelperService} from '../../shared/services/update-notification-helper.service';
import {ITicket} from '../../shared/models/ticket';
import { getTicket } from 'src/app/shared/components/ticket-item/helpers';

@Injectable()
export class CanDeactivateGuardService implements CanDeactivate<TaskPage> {

    constructor(
        private updateNotificationHelperService: UpdateNotificationHelperService,
    ) {
    }

    canDeactivate(page: TaskPage): boolean {

        if (page.canShowModalToNotifyStudents()) {
            this.updateNotificationHelperService.displayCanUpdateModal(page.parentType, getTicket(page.item), page.entityType);
        }

        return true;
    }
}
