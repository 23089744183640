import { Injectable } from '@angular/core';
import { ConfirmationService } from './confirmation.service';
import { ConfirmAction, EntityType, TicketParent } from '../models/common';
import { Observable } from 'rxjs';
import { IResponse } from '../models/response';
import { HttpService } from './http.service';
import { ITicket } from '../models/ticket';
import { isPublished } from '../components/ticket-item/helpers';

@Injectable({
  providedIn: 'root'
})
export class StudentNotifierService {

  protected POST_NOTIFY_STUDENTS = (id: string, entityType: EntityType, parentType: TicketParent) => `v2/tickets/send-notification-changed?entityId=${id}&entityType=${entityType}&parentType=${parentType}`;

  constructor(
    private confirmationService: ConfirmationService,
    private httpService: HttpService,
  ) { }

  notifyAboutTicketChange(entity: ITicket, entityType: EntityType, parentType: TicketParent): void {
    if (this.canShowConfimationModal(entity, entityType)) {
      this.isNotificationConfirmed()
        .then(isConfirmed => {
          if (isConfirmed) {
            return this.notifyAboutTicketChangeReq$(entity.id, entityType, parentType).toPromise();
          }
        });
    }
  }

  canShowConfimationModal(entity: ITicket, entityType: EntityType): boolean {
    return !!entity.material_id || isPublished(entity);
  }

  isNotificationConfirmed(): Promise<boolean> {
    return this.confirmationService.confirmAction(ConfirmAction.SHOULD_NOTIFY_USERS)
      .then(isConfirmed => isConfirmed.isConfirmed);
  }

  notifyAboutTicketChangeReq$(ticketId: string, entityType: EntityType, parentType: TicketParent): Observable<IResponse<any>> {
    return this.httpService.post$(this.POST_NOTIFY_STUDENTS(ticketId, entityType, parentType), {});
  }
}
