import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeMenuComponent } from './tree-menu.component';
import { IonicModule } from '@ionic/angular';
import { TreeModule } from 'angular-tree-component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [TreeMenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    TreeModule,
    PipesModule,
  ],
  exports: [TreeMenuComponent],
})
export class TreeMenuModule { }
