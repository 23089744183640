<div class="content">
  <ion-card mode="md">
    <ion-card-content>
      <ion-item lines="none">
        <ion-label>
          <ion-text class="meeting-type">
            {{ 'meeting.title-one' | translate }} - {{ meeting?.type?.label }}
          </ion-text>
          <ion-text class="meeting-title">
            {{ meeting?.title }}
          </ion-text>
          <ion-badge *ngIf="meeting?.subject?.title" class="subject-badge">{{ meeting?.subject?.title }}</ion-badge>
          <ion-text class="meeting-dates">
              {{ meeting?.start_date | dateLocale: 'shortTime' }} - {{ meeting?.end_date | dateLocale: 'shortTime d.MM.yyyy' }}
          </ion-text>
        </ion-label>
        <ion-button class="primary font-extra-bold" mode="md" slot="end" [disabled]="isSubmitted" (click)="join()">
          {{ 'join-input.join' | translate }}
        </ion-button>
      </ion-item>
    </ion-card-content>
  </ion-card>
</div>
