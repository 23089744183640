import { SchoolAndClassDashboardPermissions , SchoolAndClassTicketPermissions} from './base.abstract.class';
import { ClassDashboardColumn } from 'src/app/pages/class-dashboard/helpers';
import {ITicket} from '../../models/ticket';
import {isPublishedAndDatePast} from '../../components/ticket-item/helpers';

export class ClassDashboardPermissions extends SchoolAndClassDashboardPermissions {
  NOT_EDITED_COLUMNS: string[] = Object.keys(ClassDashboardColumn).map(title => title.toLowerCase());
  FORBIDDEN_ADD_TICKETS_COLUMNS: string[] = [
    ClassDashboardColumn.TEACHERS,
    ClassDashboardColumn.STUDENTS,
  ].map(title => title.toLowerCase());
}

export class ClassTicketPermissions extends SchoolAndClassTicketPermissions {
  canEditDate(entity: ITicket): boolean {
    return !isPublishedAndDatePast(entity) && this.canEditOrRemoveEntity(entity);
  }
}
