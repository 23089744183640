import { Directive, ElementRef, ViewContainerRef } from "@angular/core";

@Directive({
  selector: '[renderAttachmentsOutlet]'
})
export class RenderAttachmentsOutletDirective {
  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public viewContainerRef: ViewContainerRef
  ) {}
}