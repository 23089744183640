import { EventType } from '../../models/common';
import { BaseStoreEvent } from './base-store.class';

export function getBaseStoreEvent(type: EventType): BaseStoreEvent {
    switch (type) {
        case EventType.ADD:
            return BaseStoreEvent.ADD;
        case EventType.EDIT:
            return BaseStoreEvent.UPDATE;
        case EventType.REMOVE:
            return BaseStoreEvent.REMOVE;
    }
}
