import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MeetingNotificationService } from '../../services/meeting-notification.service';
import { IMeeting } from '../../models/meeting';
import { WhiteboardService, IWhiteboardRoom } from '../../services/whiteboard.service';
import { Subject } from 'rxjs';
import { takeUntil, tap, throttleTime } from 'rxjs/operators';
import { EntityType } from '../../models/common';
import { WhiteboardComponent } from '../whiteboard/whiteboard.component';

@Component({
  selector: 'app-meeting-join',
  templateUrl: './meeting-join.component.html',
  styleUrls: ['./meeting-join.component.scss'],
})
export class MeetingJoinComponent implements OnInit, OnDestroy {
  @Input() meeting: IMeeting;
  @Input() uuid: string;
  isSubmitted: boolean;

  private joinEmitter$ = new Subject();
  private destroy$ = new Subject();

  constructor(
    private meetingNotificationService: MeetingNotificationService,
    private whiteboardService: WhiteboardService,
  ) { }

  ngOnInit() {
    this.joinEmitter$
      .pipe(
        throttleTime(5000),
        tap(async () => {
          this.meetingNotificationService.handleMeetingAttendeeJoinNotification(this.meeting.id, this.uuid);
          const room: IWhiteboardRoom = {
            id: this.meeting.date_serial_id,
            entityType: EntityType.MEETING,
            owner_id: this.meeting.owner_id,
            title: this.meeting.title,
          }
          await this.whiteboardService.openConference(room, WhiteboardComponent);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  join() {
    this.joinEmitter$.next();
  }
}
