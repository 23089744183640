<ion-card>
  <ion-card-content>
    <img src="assets/img/warning-ico-sm.png" width="91" height="90" alt="Warning" />
    <h1>{{ (isClassEntityChange ? 'confirm-message.parent-class-change-confirm-title' : 'confirm-message.parent-school-change-confirm-title') | translate: entityChange }}</h1>
    <ion-slides [options]="slidesOptions" #slidesElem>
      <ion-slide>
        <p>
          {{ 'confirm-message.parent-change-confirm-1' | translate }}<br/>
          {{ 'confirm-message.parent-change-confirm-2' | translate }}
        </p>
        <div class="overflown-list-container" styledScrollbar>
          <ion-list lines="none" class="users-list" styledScrollbarContent>
            <ion-item
              *ngFor="let user of usersList"
              [class.opaque]="!user.shouldMove"
              lines="none">
              <ion-avatar slot="start">
                <img src="assets/img/boy-placeholder.svg" alt="" />
              </ion-avatar>
              <ion-label>{{ user.data.firstName }} {{ user.data.lastName }}</ion-label>
              <ion-button
                *ngIf="!user.shouldMove"
                (click)="toggleUserShouldMove(user)"
                class="user-list-control-btn primary" slot="end" shape="round">
                <ion-icon src="assets/img/plus.svg" slot="icon-only" size="small"></ion-icon>
              </ion-button>
              <ion-button
                *ngIf="user.shouldMove"
                (click)="toggleUserShouldMove(user)"
                class="user-list-control-btn secondary" slot="end" shape="round">
                <ion-icon src="assets/img/cross-black-ic.svg" slot="icon-only" size="small"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-list>
        </div>
      </ion-slide>
      <ion-slide>
        <ion-row *ngIf="usersList">
          <ion-col>
            <p>{{ 'confirm-message.parent-change-confirm-col-1-title' | translate }}</p>
            <div class="overflown-list-container" styledScrollbar>
              <ion-list lines="none" class="users-list" styledScrollbarContent>
                <ion-item *ngFor="let user of getUsersToStay()" lines="none">
                  <ion-avatar slot="start"><img src="assets/img/boy-placeholder.svg" alt="" /></ion-avatar>
                  <ion-label>{{ user.data.firstName }} {{ user.data.lastName }}</ion-label>
                </ion-item>
              </ion-list>
            </div>
          </ion-col>
          <ion-col>
            <p>{{ 'confirm-message.parent-change-confirm-col-2-title' | translate }}</p>
            <div class="overflown-list-container" styledScrollbar>
              <ion-list lines="none" class="users-list" styledScrollbarContent>
                <ion-item *ngFor="let user of getUsersToMove()" lines="none">
                  <ion-avatar slot="start"><img src="assets/img/boy-placeholder.svg" alt="" /></ion-avatar>
                  <ion-label>{{ user.data.firstName }} {{ user.data.lastName }}</ion-label>
                </ion-item>
              </ion-list>
            </div>
          </ion-col>
        </ion-row>
      </ion-slide>
    </ion-slides>
    <footer>
      <ion-button
        *ngIf="showBackBtn$ | async"
        (click)="back()"
        class="secondary"
        type="button">
        {{ 'common.btn-back' | translate }}
      </ion-button>
      <ion-button type="button" (click)="dismiss()" class="secondary">
        {{ 'common.btn-cancel' | translate }}
      </ion-button>
      <ion-button type="button" (click)="next()" class="primary">
        {{ 'common.btn-confirm' | translate }}
      </ion-button>
    </footer>
  </ion-card-content>
</ion-card>