import { of, from, forkJoin, BehaviorSubject } from 'rxjs';
import { tap, concatMap, map, mapTo, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { Storage } from '@ionic/storage';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { LinkStorageService } from 'src/app/shared/services/link-storage.service';
import { isRealString, isSuccess } from 'src/app/shared/helpers/helpers';
import { NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "../../shared/services/http.service";
import * as i3 from "@ionic/storage";
import * as i4 from "../../shared/services/loading.service";
import * as i5 from "../../shared/services/link-storage.service";
import * as i6 from "@ionic/angular";
export class AuthService {
    constructor(userService, httpService, storage, loadingService, linkStorageService, navCtrl) {
        this.userService = userService;
        this.httpService = httpService;
        this.storage = storage;
        this.loadingService = loadingService;
        this.linkStorageService = linkStorageService;
        this.navCtrl = navCtrl;
        this.TOKEN_STORE_KEY = 'TOKENS';
        this.POST_LOGIN_API = 'v2/user-profiles/login';
        this.POST_LOGOUT_API = 'v1/user-profiles/logout';
        this.POST_REGISTER_API = 'v1/signup';
        this.POST_REFRESH_TOKEN_API = 'v1/refresh-token';
        this.POST_FORGOT_PASSWORD_API = 'v1/forgot-password';
        this.POST_CHANGE_PASSWORD_API = 'v1/set-password';
        this.POST_ZULIP_API_KEY = 'v1/zulip/fetch-api-key';
        this.GET_NEW_USER_PASSCODE = 'v1/new-user-passcode';
        this.GET_RANDOM_USERNAME = 'v1/random-username';
        this.GET_CHECK_UNIQUE_USERNAME = (userName) => `v1/unique-username?userName=${userName}`;
        this.GET_USER_PASSCODE = 'v1/user-passcode';
        this.GET_USER_PASSCODE_COUNT = 'v1/user-passcode-count';
        this.POST_LOGIN_WITH_PASSCODE = 'v1/user-profiles/login-with-passcode';
        this._isAuthenticated$ = new BehaviorSubject(false);
    }
    authenticate$(user) {
        return this.loginRequest$(user)
            .pipe(tap(loginRes => {
            if (loginRes.payload) {
                this._jwtToken = loginRes.payload.token;
            }
        }), concatMap(loginRes => loginRes.payload
            ? this.userService.getUserRequest$()
                .pipe(tap(userRes => {
                if (userRes.error) {
                    this._jwtToken = null;
                }
            }), concatMap(userRes => userRes.payload
                ? forkJoin([this.storeTokens$(loginRes.payload)])
                    .pipe(tap(() => {
                    this.userService.setUser(userRes.payload);
                    this._isAuthenticated$.next(true);
                }), mapTo({ payload: true }))
                : of({ payload: false, error: userRes.error, message: userRes.message })))
            : of({ payload: false, error: loginRes.error, message: loginRes.message })));
    }
    authorize$() {
        return this.isLoggedIn$()
            .pipe(concatMap(isLogged => isLogged
            ? this.getStoredTokens$()
                .pipe(tap(tokens => this._jwtToken = tokens.token), concatMap((tokens) => this.userService.getUserRequest$()
                .pipe(concatMap(userRes => this.userService.setUser(userRes.payload)
                .then(() => userRes)), tap((userRes) => {
                if (isSuccess(userRes)) {
                    this._isAuthenticated$.next(true);
                }
                else {
                    this.logout$()
                        .toPromise()
                        .then(() => {
                        if (this.navCtrl) {
                            this.navCtrl.navigateRoot('auth/login');
                        }
                    });
                }
            }), map(userRes => isSuccess(userRes) ? { payload: true } : { payload: false }))))
            : of({ payload: false })));
    }
    get jwtToken() {
        return this._jwtToken;
    }
    get isAuthenticated$() {
        return this._isAuthenticated$.asObservable();
    }
    get isAuthenticated() {
        return this._isAuthenticated$.value;
    }
    refreshToken$() {
        return this.getStoredTokens$()
            .pipe(switchMap(tokens => tokens && tokens.refresh_token
            ? this.httpService.post$(this.POST_REFRESH_TOKEN_API, { token: tokens.refresh_token })
            : of({ error: {} })), concatMap(tokensRes => !tokensRes.error && tokensRes.payload ? this.storeTokens$(tokensRes.payload) : of(null)), tap((tokens) => {
            if (tokens) {
                this._jwtToken = tokens.token;
            }
        }), map(tokens => tokens ? true : false));
    }
    register$(user) {
        return this.registerRequest$(user)
            .pipe(concatMap(response => response.payload
            ? this.authenticate$({
                email: isRealString(user.email) ? user.email : response.payload.email,
                password: user.password
            })
            : of({ payload: false, error: response.error, message: response.message })));
    }
    logout$() {
        return from(this.loadingService.startLoading())
            .pipe(switchMap(() => forkJoin([this.removeTokens$()])), tap(() => {
            this._isAuthenticated$.next(false);
            this.loadingService.finishLoading();
            this.linkStorageService.clearAll();
            this.userService.clearUser();
        }), switchMap(() => of(null)));
    }
    forgotPassword$(email) {
        return this.httpService.post$(this.POST_FORGOT_PASSWORD_API, { email });
    }
    changePassword$(password, token) {
        return this.httpService.post$(this.POST_CHANGE_PASSWORD_API, { password, token });
    }
    getZulipApiKey() {
        return this.httpService.post$(this.POST_ZULIP_API_KEY, {});
    }
    getNewUserPasscode() {
        return this.httpService.get$(this.GET_NEW_USER_PASSCODE);
    }
    getRandomUsername() {
        return this.httpService.get$(this.GET_RANDOM_USERNAME);
    }
    checkUniqueUsername(payload) {
        return this.httpService.get$(this.GET_CHECK_UNIQUE_USERNAME(payload));
    }
    getUserPasscode() {
        return this.httpService.get$(this.GET_USER_PASSCODE);
    }
    getUserPasscodeCount() {
        return this.httpService.get$(this.GET_USER_PASSCODE_COUNT);
    }
    loginWithPasscode(payload) {
        return this.httpService.post$(this.POST_LOGIN_WITH_PASSCODE, payload).pipe(tap(response => {
            if (response.payload) {
                this._jwtToken = response.payload.token;
                const tokens = Object.assign({}, response.payload, { set_password_token: undefined });
                this.storeTokens$(tokens);
            }
        }), map(res => {
            if (res.payload) {
                const { set_password_token } = res.payload;
                return Object.assign({}, res, { payload: { set_password_token } });
            }
            return res;
        }));
    }
    loginRequest$(user) {
        return this.httpService.post$(this.POST_LOGIN_API, user);
    }
    registerRequest$(user) {
        return this.httpService.post$(this.POST_REGISTER_API, Object.assign({}, user, { tel: '0000', dob: new Date().toISOString() }));
    }
    logoutRequest$(token) {
        return this.httpService.post$(this.POST_LOGOUT_API, token);
    }
    isLoggedIn$() {
        return this.getStoredTokens$()
            .pipe(map(tokens => tokens && tokens.token ? true : false));
    }
    storeTokens$(tokens) {
        return from(this.storage.set(this.TOKEN_STORE_KEY, JSON.stringify(tokens)))
            .pipe(concatMap(() => this.getStoredTokens$()));
    }
    getStoredTokens$() {
        return from(this.storage.get(this.TOKEN_STORE_KEY))
            .pipe(map(tokens => tokens ? JSON.parse(tokens) : null));
    }
    removeTokens$() {
        return from(this.storage.remove(this.TOKEN_STORE_KEY));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.HttpService), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.LoadingService), i0.ɵɵinject(i5.LinkStorageService), i0.ɵɵinject(i6.NavController)); }, token: AuthService, providedIn: "root" });
