import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IResponse } from 'src/app/shared/models/response';
import { HttpService } from 'src/app/shared/services/http.service';
import { tap, first, switchMap, filter } from 'rxjs/operators';
import { UserModel } from 'src/app/shared/models/user/user';
import { EntityType } from 'src/app/shared/models/common';
import { isSuccess, fileToBase64 } from 'src/app/shared/helpers/helpers';
import { DomSanitizer } from '@angular/platform-browser';
import { UserSettingsService } from './user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public readonly PLACEHOLDER_AVATAR_URL: string = '/assets/img/boy-placeholder.svg';
  private loggedUser$: BehaviorSubject<UserModel> = new BehaviorSubject(null);
  private userAvatar$: BehaviorSubject<string> = new BehaviorSubject(this.PLACEHOLDER_AVATAR_URL);

  private readonly GET_USER_API = (id: string) => 'v2/user-profiles' + (id ? `?id=${id}` : '');
  private readonly GET_ALL_BY_FILTERS = (userType: string, entityId: string, entityType: EntityType, count: number, search: string) => `v1/users?userType=${userType}&entityId=${entityId}&entityType=${entityType}&count=${count}&search=${search}`;
  private readonly GET_ALL_BY_ENTITIES_FILTERS = (search: string, entities: { entityId: string; entityType: string; }[], page: number, limit: number) => `v1/meetings/users?name=${search}&page=${page}&limit=${limit}&filters=${JSON.stringify(entities)}`;
  private readonly GET_USER_BY_EMAIL = (email: string) => `v1/user-profiles/by-email/${email}`;
  private readonly POST_DELETE_ACCOUNT_USER = `v1/user-profiles`;
  private readonly PATCH_USER = `v1/user-profiles`;
  private readonly UPLOAD_PHOTO = `v1/user-profiles/upload-photo`;
  private readonly UPDATE_PASSWORD = `v1/user-profiles/update-password`;
  private readonly GET_PHOTO = (id: string) => `v1/user-photo/${id}`;
  private readonly SET_USER_EMAIL = 'v1/user-profiles/set-email';
  private readonly POST_USER_ACTIVITY = 'v1/user-activity-log';

  constructor(
    private httpService: HttpService,
    private sanitizer: DomSanitizer,
    private userSettingsService: UserSettingsService,
  ) { }

  async setUser(user: UserModel): Promise<void> {
    if (user) {
      this.loggedUser$.next(user);
      this.updateUserAvatar(user);
      await this.userSettingsService.init$().toPromise();
    }
  }

  getUser$(): Observable<UserModel> {
    return this.loggedUser$.asObservable()
      .pipe(filter(user => !!user));
  }

  getUserState$(): Observable<UserModel> {
    return this.loggedUser$.asObservable();
  }

  clearUser(): void {
      this.loggedUser$.next(null);
      this.userAvatar$.next(this.PLACEHOLDER_AVATAR_URL);
  }

  getUserRequest$(userId?: string): Observable<IResponse<UserModel>> {
    return this.httpService.get$<UserModel>(this.GET_USER_API(userId));
  }

  getUsers$(
    userType: 'student' | 'teacher',
    count?: number,
    search?: string,
    entityId?: string,
    entityType?: EntityType,
  ): Observable<IResponse<UserModel[]>> {
    return this.httpService.get$(this.GET_ALL_BY_FILTERS(userType, entityId, entityType, count, search));
  }

  getUsersByEntitiesFilters$(search: string = null, entities: { entityId: string; entityType: string; }[] = [], page: number, limit: number): Observable<IResponse<UserModel[]>> {
    return this.httpService.get$(this.GET_ALL_BY_ENTITIES_FILTERS(search, entities, page, limit));
  }

  getByEmail(email: string): Observable<IResponse<UserModel>> {
    return this.httpService.get$(this.GET_USER_BY_EMAIL(email));
  }

  patchUser(users: UserModel) {
    return this.httpService.patch$<UserModel>(this.PATCH_USER, users)
  }

  uploadPhoto(file: Blob): Observable<IResponse<Blob>> {
    const body = new FormData()
    body.append('files', file);
    return this.httpService.post$<UserModel>(this.UPLOAD_PHOTO, body)
      .pipe(
        switchMap(() => this.getPhoto$(this.loggedUser$.value.id)),
        tap(response => this.setUserAvatar(response)),
      );
  }

  updatePassword$(passwordData): Observable<IResponse<{updated: true}>> {
    return this.httpService.post$<{updated: true}>(this.UPDATE_PASSWORD, passwordData);
  }

  deleteAccount$(): Observable<IResponse<any>> {
    return this.httpService.delete$(this.POST_DELETE_ACCOUNT_USER);
  }

  postActivity$(): Observable<IResponse<any>> {
    return this.httpService.post$(this.POST_USER_ACTIVITY, null);
  }

  getPhoto$(id: string) {
    return this.httpService.get$<Blob>(this.GET_PHOTO(id), { responseType: 'blob' });
  }

  private updateUserAvatar(user?: UserModel): void {
    const data: UserModel = user ? user : this.loggedUser$.value;
    if (data && data.avatarUrl) {
      this.getPhoto$(data.id)
        .pipe(first())
        .subscribe(response => this.setUserAvatar(response));
    }
  }

  getUserAvatar$(): Observable<string> {
    return this.userAvatar$.asObservable();
  }

  setUserEmail$(payload: { email: string }): Observable<IResponse<unknown>> {
    return this.httpService.post$(this.SET_USER_EMAIL, payload);
  }

  private setUserAvatar(response: IResponse<Blob>): void {
    if (isSuccess(response)) {
      fileToBase64(response.payload)
        .then(data => {
          this.userAvatar$.next(this.sanitizer.bypassSecurityTrustUrl(data) as string);
        });
    }
  }
}
