import { Subject } from 'rxjs';
import { HttpService } from './http.service';
import { EntityType, EventType } from '../models/common';
import { BaseService } from './base.service';
import { exhaustMap, tap, share } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from './toast.service';
import { SocketService } from './socket.service';
import { addDashboardColumn$, editDashboardColumn$, removeDashboardColumn$ } from '../helpers/helpers';
import { ExternalLinkService } from './external-link.service';
import { UserSettingsService } from 'src/app/auth/services/user-settings.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./toast.service";
import * as i3 from "./socket.service";
import * as i4 from "./external-link.service";
import * as i5 from "../../auth/services/user-settings.service";
export class SubjectService extends BaseService {
    constructor(httpService, toastService, socketService, externalLinkSevice, userSettingsService) {
        super(httpService, toastService);
        this.httpService = httpService;
        this.toastService = toastService;
        this.socketService = socketService;
        this.externalLinkSevice = externalLinkSevice;
        this.userSettingsService = userSettingsService;
        this.joinVideo$ = new Subject();
        this.SUBJECT_URL = 'v1/subjects';
        this.entityType = EntityType.SUBJECT;
        this.GET_ALL = '';
        this.GET_ALL_USERS = (id) => `${this.SUBJECT_URL}/${id}/users`;
        this.GET_ALL_TO_TEACH = `${this.SUBJECT_URL}/teach`;
        this.GET_ALL_TO_STUDY = `${this.SUBJECT_URL}/study`;
        this.POST_ONE = this.SUBJECT_URL;
        this.PUT_ONE = this.SUBJECT_URL;
        this.PATCH_ONE = this.SUBJECT_URL;
        this.GET_ONE = (id) => `${this.SUBJECT_URL}/${id}`;
        this.GET_ONE_PUBLIC_DATA = (id) => `${this.SUBJECT_URL}/public/${id}`;
        this.GET_DASHBOARD_DATA_BY_SUBJECT_INIT = (id, userRole, limitByColumn, filters) => `${this.SUBJECT_URL}/dashboard-data/${id}/init?type=${userRole}&limitByColumn=${limitByColumn}&filters=${filters}`;
        this.GET_DASHBOARD_DATA_BY_SUBJECT_BY_PAGE = (id, userRole, page, limit, columnId, selectedDate, filters) => `${this.SUBJECT_URL}/dashboard-data/${id}?type=${userRole}&page=${page}&limit=${limit}&columnId=${columnId}&selectedDate=${selectedDate}&filters=${filters}`;
        this.GET_DASHBOARD_DATA_BY_SUBJECT_NEAR_DATE = (id, userRole, limit, columnId, selectedDate, filters) => `${this.SUBJECT_URL}/dashboard-data/${id}/near-selected-date?type=${userRole}&limit=${limit}&columnId=${columnId}&selectedDate=${selectedDate}&filters=${filters}`;
        this.GET_TIMELINE_DATES_BY_COLUMN = (id, userRole, columnId, filters) => `${this.SUBJECT_URL}/dashboard-data/${id}/timeline-dates?type=${userRole}&columnId=${columnId}&filters=${filters}`;
        this.GET_TIMELINE_DATES_BY_ALL_COLUMNS = (userRole, filters) => `${this.SUBJECT_URL}/dashboard-data/timeline-dates-by-all-columns?type=${userRole}&filters=${filters}`;
        this.GET_TIMELINE_DATES_BY_STATE = (userRole, state, filters) => `v2/subjects/dashboard-data/timeline-dates-by-state/${state}?type=${userRole}&filters=${filters}`;
        this.GET_TIMELINE_DATES_ALL_STATES = (userRole, filters) => `${this.SUBJECT_URL}/dashboard-data/timeline-dates-by-all-states?type=${userRole}&filters=${filters}`;
        this.GET_ALL_BY_CLASS = (id) => `${this.SUBJECT_URL}/by-class/${id}`;
        this.GET_ALL_BY_USER = (id, schoolId, classId, type) => `${this.SUBJECT_URL}/by-user?userId=${id}&type=${type}&schoolId=${schoolId}&classId=${classId}`;
        this.GET_TEACHER_CALENDAR_DATA = (id, from, to) => `${this.SUBJECT_URL}/teacher-calendar-data/${id}/from/${from}/to/${to}`;
        this.GET_STUDENT_CALENDAR_DATA = (from, to) => `${this.SUBJECT_URL}/student-calendar-data/from/${from}/to/${to}`;
        this.DELETE_ONE = (id) => `${this.SUBJECT_URL}/${id}`;
        this.GET_DASHBOARD_BY_SUBJECT = (id) => `${this.SUBJECT_URL}/dashboard/${id}`;
        this.POST_JOIN_VIDEO = (id) => `${this.SUBJECT_URL}/${id}/join/video`;
        this.PATCH_CHANGE_CLASS = `v1/subjects/change-class`;
        this.joinVideo$
            .pipe(exhaustMap(id => this.joinVideoRequest$(id)), tap(response => {
            if (response.error && response.message) {
                this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
            }
            else {
                this.externalLinkSevice.open(response.payload.url);
            }
        }))
            .subscribe();
        this.socketService.getCrudMessages$([EntityType.SUBJECT])
            .pipe(tap(message => {
            if (message.eventType === EventType.REMOVE) {
                this.userSettingsService.handleSubjectOrLessonRemove(message.entityId);
            }
        }))
            .subscribe(payload => this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId }));
    }
    getAllTeach$() {
        return this.httpService.get$(this.GET_ALL_TO_TEACH)
            .pipe(share());
    }
    getAllStudy$() {
        return this.httpService.get$(this.GET_ALL_TO_STUDY)
            .pipe(share());
    }
    getDashboardTicketsInit$(subjectId, userRole, limitByColumn, filters) {
        return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SUBJECT_INIT(subjectId, userRole, limitByColumn, JSON.stringify(filters)));
    }
    getDashboardTicketsByPage$(subjectId, userRole, page, limit, columnId, selectedDate, filters) {
        return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SUBJECT_BY_PAGE(subjectId, userRole, page, limit, columnId, selectedDate, JSON.stringify(filters)));
    }
    getDashboardTicketsNearDate$(subjectId, userRole, limit, columnId, selectedDate, filters) {
        return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SUBJECT_NEAR_DATE(subjectId, userRole, limit, columnId, selectedDate, JSON.stringify(filters)));
    }
    getTimelineDatesByColumnId$(subjectId, userRole, columnId, filters) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_COLUMN(subjectId, userRole, columnId, JSON.stringify(filters)));
    }
    getTimelineDatesAllColumns$(userRole, filters) {
        filters.subject_ids;
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_ALL_COLUMNS(userRole, JSON.stringify(filters)));
    }
    getTimelineDatesByState$(userRole, state, filters) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_BY_STATE(userRole, state, JSON.stringify(filters)));
    }
    getTimelineDatesByAllStates$(userRole, filters) {
        return this.httpService.get$(this.GET_TIMELINE_DATES_ALL_STATES(userRole, JSON.stringify(filters)));
    }
    getAllByUserId$(userId, schoolId, classId) {
        return this.httpService.get$(this.GET_ALL_BY_USER(userId, schoolId, classId, 'teacher'));
    }
    getAllByClassrId$(classId) {
        return this.httpService.get$(this.GET_ALL_BY_CLASS(classId));
    }
    getTeacherCalendarData$(subjectId, fromDate, toDate) {
        return this.httpService.get$(this.GET_TEACHER_CALENDAR_DATA(subjectId, fromDate, toDate));
    }
    getStudentCalendarData$(fromDate, toDate) {
        return this.httpService.get$(this.GET_STUDENT_CALENDAR_DATA(fromDate, toDate));
    }
    getDashboardBySubjectId(id) {
        return this.httpService.get$(this.GET_DASHBOARD_BY_SUBJECT(id));
    }
    addDashboardColumn$(column, subjectId) {
        return addDashboardColumn$(column, subjectId, EntityType.SUBJECT, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.ADD_COLUMN, response, { entityId: subjectId })));
    }
    editDashboardColumn$(column, subjectId) {
        return editDashboardColumn$(column, EntityType.SUBJECT, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.EDIT_COLUMN, response, { entityId: subjectId })));
    }
    removeDashboardColumn$(column, subjectId) {
        return removeDashboardColumn$(column.id, EntityType.SUBJECT, this.httpService)
            .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.REMOVE_COLUMN, response, { entityId: subjectId })));
    }
    joinVideoRequest$(subjectId) {
        return this.httpService.post$(this.POST_JOIN_VIDEO(subjectId), null);
    }
    getPublicData$(subjectId) {
        return this.httpService.get$(this.GET_ONE_PUBLIC_DATA(subjectId));
    }
    changeClass$(payload) {
        return this.httpService.patch$(this.PATCH_CHANGE_CLASS, payload);
    }
    getAllSubjectUsers$(subjectId) {
        return this.httpService.get$(this.GET_ALL_USERS(subjectId));
    }
    notifyAboutUpdateColumn(eventType, response, payload) {
        if (response.error || !this.socketService.isConnected()) {
            this.notifyAboutUpdate(eventType, response, payload);
        }
    }
}
SubjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubjectService_Factory() { return new SubjectService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.SocketService), i0.ɵɵinject(i4.ExternalLinkService), i0.ɵɵinject(i5.UserSettingsService)); }, token: SubjectService, providedIn: "root" });
