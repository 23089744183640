import * as tslib_1 from "tslib";
import { OnInit, ElementRef, ChangeDetectorRef, EventEmitter, AfterContentInit, OnDestroy } from '@angular/core';
import { ICalendarDisplay, ICalendarType } from './models';
import * as moment from 'moment';
import * as range from 'lodash.range';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { of, Subject, Observable, fromEvent } from 'rxjs';
import { delay, filter, tap, takeUntil, map, first } from 'rxjs/operators';
import { detectChanges } from '../../helpers/helpers';
import { CalendarCellDirective } from './calendar-cell.directive';
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { TimePickerComponent } from '../time-picker/time-picker.component';
import { ConfirmationService } from '../../services/confirmation.service';
import { ConfirmAction } from '../../models/common';
const cloneDeep = require('lodash.clonedeep');
export class CalendarInputComponent {
    constructor(cdf, eRef, translateService, popoverCtrl, confirmationService) {
        this.cdf = cdf;
        this.eRef = eRef;
        this.translateService = translateService;
        this.popoverCtrl = popoverCtrl;
        this.confirmationService = confirmationService;
        this.readonly = false;
        this.isDetached = true;
        this.dateFormat = 'D MMMM YYYY';
        this.type = ICalendarType.DATE;
        this.display = ICalendarDisplay.BUTTON_WITH_TEXT;
        this.placeholder = 'Set due date';
        this.initialDate = null;
        this.allowSelectPastDay = false;
        this.valueChanged = new EventEmitter();
        this.clearPeriod = new EventEmitter();
        this.weeksUpdated = new EventEmitter();
        this.top = 0;
        this.left = 0;
        this.namesOfDays = moment.weekdaysShort();
        this.fullNamesOfDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        this.weeks = [];
        this.selectedPeriod = {
            startDate: null,
            endDate: null,
            selected: false
        };
        this.isSubmitted = false;
        this.submit$ = new Subject();
        this.calendarCellTemplate = null;
        this.selectTimePeriod = false;
        this.isOpen = false;
        this.showErrorNotEquals = false;
        this.destroy$ = new Subject();
        this.isOpened = false;
        this.onChange = () => { };
        this.onTouched = () => { };
    }
    ngOnInit() {
        if (this.isDetached) {
            this.cdf.detach();
        }
        if (moment(this.initialDate).isValid()) {
            this.currentDate = moment(this.initialDate);
            this.selectedDate = this.currentDate.toLocaleString();
        }
        else {
            this.currentDate = moment();
        }
        this.generateCalendar();
        if (this.display === ICalendarDisplay.INLINE) {
            this.toggleOpen();
        }
        detectChanges(this.cdf);
        this.submit$
            .pipe(filter(() => this.isSelectedTimeValid()), tap(() => this.isSubmitted = true), map(() => this.timeControl.value), takeUntil(this.destroy$))
            .subscribe(value => {
            this.isSubmitted = false;
            this.showErrorNotEquals = false;
            if (value) {
                this.valueChanged.emit();
                this.selectTimePeriod = false;
                this.isOpen = false;
                this.onChange(value);
                if (this.onValueChange instanceof Function) {
                    this.onValueChange(value);
                }
                if (this.onDismiss instanceof Function) {
                    this.onDismiss();
                }
            }
            else {
                this.showErrorNotEquals = true;
            }
            detectChanges(this.cdf);
        });
        if (this.listenClear != null && this.listenClear.subscribe instanceof Function) {
            this.listenClear
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.clear());
        }
        const documentClick$ = fromEvent(document, 'click')
            .pipe(filter(() => this.isOpened), tap(event => {
            if (!this.eRef.nativeElement.contains(event.target)
                && this.display !== ICalendarDisplay.INLINE
                && !this.selectTimePeriod) {
                this.isOpen = false;
                this.selectTimePeriod = false;
                this.showErrorNotEquals = false;
                this.setOpenedState();
                if (this.onDismiss instanceof Function) {
                    this.onDismiss();
                }
                detectChanges(this.cdf);
            }
        }));
        documentClick$
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }
    ngAfterContentInit() {
        if (this.calendarCellDirectiveRef != null) {
            this.calendarCellTemplate = this.calendarCellDirectiveRef.templateRef;
            detectChanges(this.cdf);
        }
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
    getSelectedDate() {
        if (this.type === ICalendarType.DATE_AND_TIME) {
            return moment(this.selectedDate).format('D MMM YYYY HH:mm');
        }
        return moment(this.selectedDate).format(this.dateFormat);
    }
    isDatePeriod() {
        return this.type === ICalendarType.DATE_PERIOD;
    }
    isSelectDatePlaceholderVisible() {
        return this.isDatePeriod();
    }
    toggleOpen() {
        this.isOpen = !this.isOpen;
        this.setOpenedState();
        detectChanges(this.cdf);
    }
    isSelectedMonth(date) {
        if (this.isPastDay(date)) {
            return true;
        }
        return moment(date).isSame(this.currentDate, 'month');
    }
    isPastDay(date) {
        if (this.allowSelectPastDay || this.isDatePeriod() || this.isToday(date)) {
            return false;
        }
        return date.isBefore(moment());
    }
    selectDate(event, date) {
        if (this.isPastDay(date.mDate)) {
            this.confirmationService.confirmAction(ConfirmAction.UNABLE_CHOOSE_PAST_DAY);
            return;
        }
        event.stopPropagation();
        this.onTouched();
        this.generateCalendar();
        of(true)
            .pipe(delay(100), first())
            .subscribe(() => {
            this.writeValue(date.mDate.toISOString());
            if (this.type === ICalendarType.DATE) {
                if (!this.isInline) {
                    this.isOpen = false;
                }
                if (this.onDismiss instanceof Function) {
                    this.onDismiss();
                }
                this.valueChanged.emit();
            }
            detectChanges(this.cdf);
        });
    }
    prevMonth() {
        this.currentDate = moment(this.currentDate).subtract(1, 'months');
        this.generateCalendar();
        detectChanges(this.cdf);
    }
    nextMonth() {
        this.currentDate = moment(this.currentDate).add(1, 'months');
        this.generateCalendar();
        detectChanges(this.cdf);
    }
    getCurrentMonth() {
        return moment(this.currentDate).format('MMMM YYYY');
    }
    getWeekDay() {
        return this.fullNamesOfDays[moment(this.currentDate).weekday()];
    }
    isSelectedWeek(week) {
        return week.findIndex(w => w.selected) !== -1 ? true : false;
    }
    isValidDate(date) {
        return date ? moment(date).isValid() : false;
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    writeValue(value) {
        switch (this.type) {
            case ICalendarType.DATE_PERIOD:
                this.writeValueDatePeriod(value);
                break;
            case ICalendarType.DATE_AND_TIME:
                this.writeValueDateTime(value);
                break;
            default:
                this.writeValueDate(value);
                break;
        }
    }
    cancel() {
        of(true).pipe(delay(100)).toPromise().then(() => {
            this.selectTimePeriod = false;
            this.resetPeriod();
            this.isOpen = false;
            this.selectedDate = null;
            this.showErrorNotEquals = false;
            if (this.onDismiss instanceof Function) {
                this.onDismiss();
            }
            detectChanges(this.cdf);
        });
    }
    back() {
        of(true).pipe(delay(100)).toPromise().then(() => {
            this.selectTimePeriod = false;
            detectChanges(this.cdf);
        });
    }
    detectChanges() {
        detectChanges(this.cdf);
    }
    getFormattedSelectedDate(date) {
        return moment(date).format(this.dateFormat);
    }
    clear() {
        this.resetPeriod();
        this.generateCalendar();
        this.clearPeriod.emit();
        this.onChange();
        if (this.onValueChange instanceof Function) {
            this.onValueChange({});
        }
    }
    get isInline() {
        return this.display === ICalendarDisplay.INLINE;
    }
    getEndTimeLabel() {
        if (this.type === ICalendarType.DATE_AND_TIME) {
            return this.translateService.instant('datepicker.time');
        }
        return this.translateService.instant('datepicker.end-time');
    }
    getDayRangeForFrequencyTimeRange() {
        return this.getFormattedSelectedDate(moment(this.selectedDate));
    }
    onTimeInputBlur() {
        this.detectChanges();
        this.showErrorNotEquals = false;
    }
    openTimePicker(event) {
        event.preventDefault();
        event.stopPropagation();
        (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverCtrl.create({
                event,
                component: TimePickerComponent,
                showBackdrop: false,
                translucent: true,
                cssClass: 'time-picker-popover',
                mode: 'md',
                componentProps: {
                    time: this.timeControl.value,
                    minStep: 5,
                    onValueChanged: (newTime) => {
                        this.timeControl.setValue(newTime);
                        this.onTimeInputBlur();
                    },
                    onDismiss: () => popover.dismiss(),
                },
            });
            popover.present();
        }))();
    }
    generateCalendar() {
        const dates = this.fillDates(this.currentDate);
        const weeks = [];
        while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
        }
        this.weeks = weeks;
        this.weeksUpdated.emit(cloneDeep(this.weeks));
    }
    fillDates(currentMoment) {
        const firstOfMonth = moment(currentMoment).startOf('month').day();
        const lastOfMonth = moment(currentMoment).endOf('month').day();
        const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
        const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'days').add(7, 'days');
        const startCalendar = firstDayOfGrid.date();
        return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')).map((date) => {
            const newDate = moment(firstDayOfGrid).date(date);
            return {
                today: this.isToday(newDate),
                selected: this.isSelected(newDate),
                period: this.isPeriod(newDate),
                mDate: newDate,
            };
        });
    }
    isToday(date) {
        return moment().isSame(moment(date), 'day');
    }
    isPeriod(date) {
        if (!this.isDatePeriod() || !this.selectedPeriod.selected) {
            return false;
        }
        return this.selectedPeriod.startDate.diff(date) < 0 && this.selectedPeriod.endDate.diff(date) > 0;
    }
    isSelected(date) {
        if (this.isDatePeriod()) {
            return (date.isSame(this.selectedPeriod.startDate, 'd') ||
                date.isSame(this.selectedPeriod.endDate, 'd'));
        }
        if (!this.selectedDate) {
            return false;
        }
        return moment(this.selectedDate).isSame(date, 'd');
    }
    writeValueDateTime(value) {
        if (!value || typeof value !== 'string') {
            this.selectedDate = null;
            return;
        }
        if (moment(value).isValid()) {
            this.currentDate = moment(value);
            this.selectedDate = moment(value).toLocaleString();
            this.startDate = moment(value).format('YYYY-MM-DD');
            this.endDate = moment(value).format('YYYY-MM-DD');
            const initialTime = this.isToday(this.selectedDate)
                ? this.currentDate.clone().set('h', moment().hours() + 1).toISOString()
                : this.currentDate.clone().set('h', 10).toISOString();
            this.timeControl = new FormControl(initialTime);
            this.selectTimePeriod = true;
        }
        else {
            this.selectedDate = null;
        }
        this.generateCalendar();
        detectChanges(this.cdf);
    }
    writeValueDate(value) {
        if (!value || typeof value !== 'string') {
            this.selectedDate = null;
            return;
        }
        if (moment(value).isValid()) {
            this.selectedDate = moment(value).toLocaleString();
            this.onChange(value);
            if (this.onValueChange instanceof Function) {
                this.onValueChange(value);
            }
        }
        else {
            this.selectedDate = null;
        }
        this.generateCalendar();
        detectChanges(this.cdf);
    }
    writeValueDatePeriod(value) {
        if (value && value.startDate && value.endDate) {
            this.selectedPeriod.startDate = moment(value.startDate).isValid() ? moment(value.startDate) : null;
            this.selectedPeriod.endDate = moment(value.endDate).isValid() ? moment(value.endDate) : null;
            this.onChangePeriod();
            return;
        }
        if (!value || typeof value !== 'string') {
            this.resetPeriod();
            return;
        }
        if (moment(value).isValid()) {
            if (!this.selectedPeriod.startDate) {
                this.selectedPeriod.startDate = moment(value);
            }
            else if (this.selectedPeriod.startDate && !this.selectedPeriod.endDate) {
                if (this.selectedPeriod.startDate.diff(moment(value)) > 1) {
                    this.selectedPeriod.endDate = this.selectedPeriod.startDate;
                    this.selectedPeriod.startDate = moment(value);
                    this.onChangePeriod();
                }
                else {
                    this.selectedPeriod.endDate = moment(value);
                    this.onChangePeriod();
                }
            }
            else {
                this.selectedPeriod = {
                    startDate: null,
                    endDate: null,
                    selected: false
                };
            }
        }
        else {
            this.resetPeriod();
        }
        this.generateCalendar();
        detectChanges(this.cdf);
    }
    onChangePeriod() {
        const value = {
            startDate: this.selectedPeriod.startDate.hours(0).toISOString(),
            endDate: this.selectedPeriod.endDate.hours(23).minutes(59).toISOString()
        };
        this.valueChanged.emit();
        this.selectedPeriod.selected = true;
        this.isOpen = false;
        if (this.onValueChange instanceof Function) {
            this.onValueChange(value);
        }
        if (this.onSelectedPeriodChange instanceof Function) {
            this.onSelectedPeriodChange(this.selectedPeriod);
        }
        if (this.onDismiss instanceof Function) {
            this.onDismiss();
        }
        this.onChange(value);
        this.generateCalendar();
    }
    resetPeriod() {
        this.selectedPeriod = {
            startDate: null,
            endDate: null,
            selected: false
        };
        if (this.onSelectedPeriodChange instanceof Function) {
            this.onSelectedPeriodChange(this.selectedPeriod);
        }
    }
    setOpenedState() {
        of(true)
            .pipe(delay(300), first())
            .subscribe(() => this.isOpened = this.isOpen);
    }
    isSelectedTimeValid() {
        if (!this.timeControl.valid || new Date(this.timeControl.value).getTime() <= new Date(moment().toISOString()).getTime()) {
            this.showErrorNotEquals = true;
            this.detectChanges();
            return false;
        }
        return true;
    }
}
