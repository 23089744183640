<ion-content scrollY="false" [ngStyle]="{'width': (isStudentListVisible || isGradingSystemVisible) ? '440px' : '220px'}" [class.modal-style]="hasModalStyle">
  <div class="header"></div>
  <div class="wrapper-main">
    <div class="wrapper-left">
      <div class="content" styledScrollbar>
        <div styledScrollbarContent>
          <ion-list [style.padding]="isMobile ? '40px' : '20px'"  class="ion-padding" style="contain: unset" [formGroup]="createForm" (ngSubmit)="save$.next()" (keyup.enter)="save$.next()">
            <ion-item class="title-item" lines="none">
              <ion-label class="title font-regular">{{ getTitle() }}</ion-label>
            </ion-item>
            <app-select-input class="select" [options]="createOptions" [disable]="isCreateTypeControlDisabled" [showPlaceholderIcon]="true" formControlName="createType"></app-select-input>
            <ion-item class="input" [disabled]="isControlDisabled">
              <ion-input #titleInput [class.disable-input]="!canEdit" placeholder="{{ 'create-menu.name-placeholder' | translate }}" formControlName="name" [readonly]="!canEdit"></ion-input>
            </ion-item>
            <span class="error-message" *ngIf="hasError(createForm.controls.name)">{{ 'validation-errors.name-required' | translate }}</span>

            <ion-input hidden="true" formControlName="color"></ion-input>
            <app-color-picker [disabled]="isControlDisabled || !canEdit" display="button-with-text" [hidden]="!isColorPickerVisible"  [initColor]="(entity && entity.id) ? createForm.value.color : null" title="{{'common.pick-color' | translate }}" (colorSelect)="changeColor($event)"></app-color-picker>

            <ion-item *ngIf="isLoading" class="ion-margin-vertical loading" lines="none">
              <ion-skeleton-text style="height: 80%" animated></ion-skeleton-text>
            </ion-item>
            <ion-item class="input" [hidden]="!(createMenuType === createMenuTypes.SCHOOL)" [disabled]="isControlDisabled">
              <img src="assets/img/address-pin-ic.svg" slot="start" alt="">
              <ion-input [class.disable-input]="!canEdit" placeholder="{{ 'create-menu.address-placeholder' | translate }}" formControlName="address" [readonly]="!canEdit"></ion-input>
            </ion-item>
            <span class="error-message" *ngIf="hasError(createForm.controls.address)">{{ 'validation-errors.name-required' | translate }}</span>
            <ion-item class="input" [hidden]="!(createMenuType === createMenuTypes.SCHOOL)" [disabled]="isControlDisabled">
              <ion-input [class.disable-input]="!canEdit" placeholder="{{ 'create-menu.phone-placeholder' | translate }}" formControlName="phone" [readonly]="!canEdit"></ion-input>
            </ion-item>
            <span class="error-message" *ngIf="hasError(createForm.controls.phone)">{{ 'validation-errors.name-required' | translate }}</span>
            <ion-item class="input" [hidden]="!(createMenuType === createMenuTypes.SCHOOL)" [disabled]="isControlDisabled">
              <ion-input [class.disable-input]="!canEdit" placeholder="{{ 'create-menu.website-placeholder' | translate }}" formControlName="website" [readonly]="!canEdit"></ion-input>
            </ion-item>
            <span class="error-message" *ngIf="hasError(createForm.controls.website)">{{ 'validation-errors.name-required' | translate }}</span>
            <app-select-input #schoolSelect [hidden]="!(createMenuType === createMenuTypes.SUBJECT_WITH_CLASS)" [options]="classes" [disable]="isControlDisabled || !canEdit" [showPlaceholderIcon]="true" [showNoneValue]="false" placeholder="common.select-class" formControlName="class"></app-select-input>
            <app-select-input #classSelect [hidden]="!(createMenuType === createMenuTypes.CLASS_WITH_SCHOOL)" [options]="schools" [disable]="isControlDisabled || !canEdit" [showPlaceholderIcon]="true" [showNoneValue]="false" placeholder="common.select-school" formControlName="school"></app-select-input>
            <ng-container *ngIf="createMenuType === createMenuTypes.MEETING && !isLoading">
              <app-meeting-frequency-selector *ngFor="let meetingFrequency of meetingFrequencyDates; let i = index; let last = last; trackBy:meetingFrequencyTrackByFn"
                                              [ngClass]="{ 'last-child': last }"
                                              [meetingFrequency]="meetingFrequency"
                                              [isFrequencySelectVisible]="isFrequencySelectVisible"
                                              [disableRemove]="i === 0 && meetingFrequencyDates.length === 1"
                                              [isDisabled]="isSubmitted"
                                              (changed)="changeFrequencyRange($event, i)"
                                              (remove)="removeFrequencyRange(i)">

              </app-meeting-frequency-selector>
              <ion-item *ngIf="!isAddDateAndTimeBtnVisible" (click)="addNewFrequencyRange()" lines="none" class="add-date-and-time">
                <ion-button [disabled]="isControlDisabled" mode="md" fill="clear">
                  <ion-text class="date-time-btn-label">{{ 'common.add-date-time' | translate }}</ion-text>
                </ion-button>
              </ion-item>
              <app-select-input #subjectSelect [options]="subjects" [disable]="isControlDisabled" class="select" placeholder="common.select-subject" formControlName="subject"></app-select-input>
              <ion-item lines="none" class="section-label">
                <ion-label class="title font-light">{{ 'meeting.type-section-label' | translate }}</ion-label>
              </ion-item>
              <app-select-input #meetingTypesSelect [options]="meetingTypes" [disable]="isControlDisabled" formControlName="type" class="select" placeholder="meeting.calendar"></app-select-input>
            </ng-container>
            <ion-item *ngIf="canSeeStudentList() && !isLoading" class="list" lines="none">
              <div>
                <h2 class="link" (click)="setStudentListVisible()">{{ 'create-menu.student-list-title' | translate }}</h2>
                <span>{{ 'create-menu.student-list-body' | translate }}</span>
              </div>
            </ion-item>
            <ion-item *ngIf="canSeeGradingSystem() && !isLoading" class="list" lines="none">
              <div>
                <h2 class="link" (click)="setGradingSystemVisible()">{{ 'create-menu.grading-system-title' | translate }}</h2>
                <span>{{ 'create-menu.grading-system-body' | translate }}</span>
              </div>
            </ion-item>
            <app-checkbox [hidden]="true" formControlName="is_official" [isDisabled]="schoolOfficialStatusControlDisabled">{{ 'create-menu.official-status-field' | translate }}</app-checkbox>

            <!-- HIDE TEXT TEMPORARY https://app.asana.com/0/1174045075382187/1200093129270444/f -->
            <!-- <ion-item [hidden]="!(createMenuType === createMenuTypes.SCHOOL) || isLoading" class="list" lines="none">
              <div>
                <span>{{ 'create-menu.official-status-body' | translate }}</span>
              </div>
            </ion-item> -->

            <ion-button class="primary font-extra-bold ion-margin-vertical" expand="block" mode="md" type="submit" [ngClass]="{'btn-submitted': isSubmitted}" [disabled]="isControlDisabled || !isValid" (click)="save$.next()">
                {{ submitBtnName }}
                <ion-spinner [hidden]="!isSubmitted" class="btn-spinner" color="light" name="dots"></ion-spinner>
            </ion-button>
            <ion-button class="secondary font-extra-bold" expand="block" mode="md" [disabled]="isSubmitted" (click)="close()">{{ 'common.btn-cancel' | translate }}</ion-button>
          </ion-list>
          <app-students-invited-list [hidden]="!isStudentsInvitedListVisible"
                                     [entity]="entity" [entityType]="entityType" [canRemove]="canRemoveUsers && !isControlDisabled"></app-students-invited-list>
        </div>
      </div>
      <div class="footer">
        <ion-button [hidden]="isLoading || !canRemoveEntity" fill="clear" expand="block" mode="md" class="remove-btn" (click)="removeEntity()">{{ getRemoveButtonText() }}</ion-button>
      </div>
    </div>
    <div class="wrapper-right" *ngIf="isStudentListVisible || isGradingSystemVisible">
      <div class="content" styledScrollbar>
        <ng-container *ngIf="isGradingSystemVisible">
          <app-grades-crud [entityId]="entity?.id" [entityType]="entityType" [disabled]="isControlDisabled" [canEdit]="canEdit" [defaultGrade]="entityGrade" (close)="closeRightBlock()" (updated)="onGradeUpdate($event)" styledScrollbarContent></app-grades-crud>
        </ng-container>
        <ion-list *ngIf="isStudentListVisible" [formGroup]="userFilterForm" style="contain: unset" class="student-list ion-padding" styledScrollbarContent>
          <ion-item *ngIf="canAddUsers" lines="none">
            <ion-label class="title font-light">{{ 'create-menu.add-students-title' | translate }}</ion-label>
            <ion-button  fill="clear" slot="end" mode="md" class="title-btn link" (click)="addAllStudents()" [disabled]="isAddAllButtonDisabled">{{ 'create-menu.add-all' | translate }}</ion-button>
          </ion-item>
          <ion-item class="input search-input">
            <ion-input placeholder="{{ 'create-menu.name-placeholder' | translate }}" [disabled]="isControlDisabled" (ionChange)="searchNameChanged($event.target.value)"></ion-input>
            <ion-icon slot="end" class="ico icon-search"></ion-icon>
          </ion-item>
          <app-select-input [hidden]="!isMeetingFilterSelectVisible(schoolsFilterList) || isLoading" [options]="schoolsFilterList" [disable]="isControlDisabled" [showNoneValue]="false" placeholder="common.select-school" [formControl]="userFilterForm.controls.filter_school" class="meeting-filter-select"></app-select-input>
          <ng-container *ngIf="!isMeetingClassLoading; else meetingClassesLoading">
            <app-select-input [hidden]="!isMeetingFilterSelectVisible(classesFilterList) || isLoading" [options]="classesFilterList" [disable]="isControlDisabled" [showNoneValue]="false" placeholder="common.select-class" [formControl]="userFilterForm.controls.filter_class" class="meeting-filter-select"></app-select-input>
          </ng-container>
          <ng-template #meetingClassesLoading>
            <ion-item class="ion-margin-vertical loading" lines="none">
              <ion-skeleton-text style="height: 100%" animated></ion-skeleton-text>
            </ion-item>
          </ng-template>
          <ng-container *ngIf="!isMeetingSubjectLoading; else meetingSubjectsLoading">
            <app-select-input [hidden]="!isMeetingFilterSelectVisible(subjectsFilterList) || isLoading" [options]="subjectsFilterList" [disable]="isControlDisabled" [showNoneValue]="false" placeholder="common.select-subject" [formControl]="userFilterForm.controls.filter_subject" class="meeting-filter-select"></app-select-input>
          </ng-container>
          <ng-template #meetingSubjectsLoading>
            <ion-item class="ion-margin-vertical loading" lines="none">
              <ion-skeleton-text style="height: 100%" animated></ion-skeleton-text>
            </ion-item>
          </ng-template>
          <br>
          <ion-content styledScrollbar="shadow-root" class="students" [ngStyle]="{'height': createMenuType === createMenuTypes.MEETING ? '285px': '255px'}">
            <ion-list class="students" styledScrollbarContent>
              <ion-text class="empty-list" *ngIf="!isStudentListLoading && searchedUsersEmptyText?.length">{{ searchedUsersEmptyText }}</ion-text>
              <ion-spinner class="student-list-spinner" *ngIf="isStudentListLoading || isLoading" name="crescent"></ion-spinner>
              <ion-item *ngFor="let student of searchedStudents" lines="none" [disabled]="isStudentListLoading || isControlDisabled">
                <img [src]="student.avatarUrl || 'assets/img/boy-placeholder.svg'" slot="start" alt="">
                <ion-label class="user-name" [ngClass]="{'semi-visible': student.deactivated}">{{ student.firstName }} {{ student.lastName }} <ng-container *ngIf="student.deactivated">( {{'common.deactivated' | translate}} )</ng-container></ion-label>
                <ion-spinner *ngIf="(updatingStudent && updatingStudent.id === student?.id)" name="dots" color="primary"></ion-spinner>
                <ion-button *ngIf="canAddUsers" slot="end" mode="md" class="circle-chip-btn add" [disabled]="isControlDisabled" [hidden]="isStudentInvited(student.id) || (updatingStudent && updatingStudent.id === student?.id) || student.deactivated" (click)="inviteUser(student)">
                  <ion-icon class="ico icon-plus"></ion-icon>
                </ion-button>
              </ion-item>
            </ion-list>
            <ion-infinite-scroll (ionInfinite)="loadMoreUsers($event)" [disabled]="!searchedStudents?.length || searchedStudents?.length < searchedUsersLimit - 1">
              <ion-infinite-scroll-content color="primary" loadingSpinner="crescent">
              </ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </ion-content>
          <ion-item *ngIf="isCloseRightBlockBtnVisible" class="wrapper-right-close-btn" lines="none">
            <ion-button class="primary font-extra-bold ion-margin-vertical" expand="block" mode="md" (click)="closeRightBlock()">{{ 'common.btn-close' | translate }}</ion-button>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
</ion-content>
