import { Component, Input, ViewChild, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, ElementRef, Output, EventEmitter, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonCheckbox } from '@ionic/angular';
import { detectChanges } from '../../helpers/helpers';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() label: string;
  @Input() isDisabled: boolean;
  @Input() color: string = '#3399FF';
  @Input() isChecked: boolean;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(IonCheckbox, { static: false, read: ElementRef }) ionCheckboxEl: ElementRef;

  private disabledCheckboxColor: string = '#96A4AB';
  private valueChangeEmitter$ = new Subject<boolean>();
  private destroy$ = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.valueChangeEmitter$.asObservable()
      .pipe(
        throttleTime(500),
        takeUntil(this.destroy$),
      )
      .subscribe(value => this.handleChangedValue(value));
  }

  ngAfterViewInit() {
    this.setBackgroundColor();
    detectChanges(this.cdr);
  }

  ngOnChanges() {
    if (!this.isDisabled && this.ionCheckboxEl) {
      this.setBackgroundColor();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get backgroundChecked(): string {
    if (this.isDisabled) {
      return this.disabledCheckboxColor;
    }
    return this.color;
  }

  writeValue(obj: any): void {
    if (this.isBoolean(obj)) {
      this.isChecked = obj;
      detectChanges(this.cdr);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (this.isBoolean(isDisabled)) {
      this.isDisabled = isDisabled;
    }
  }

  onIonChange(value: boolean): void {
    this.valueChangeEmitter$.next(value);
  }

  private isBoolean(value: any): boolean {
    return value === false || value === true;
  }

  private onChange = (value: boolean) => {};
  private onTouched = () => {};

  private handleChangedValue(value: boolean): void {
    this.writeValue(value);
    this.onTouched();
    this.onChange(value);
    this.change.emit(value);
    detectChanges(this.cdr);
  }

  private setBackgroundColor(): void {
    this.ionCheckboxEl.nativeElement.style.setProperty('--background-checked', this.backgroundChecked);
    this.ionCheckboxEl.nativeElement.style.setProperty('--border-color-checked', this.backgroundChecked);
  }
}
