/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./onboarding-guided-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../onboarding-guide/onboarding-guide.component.ngfactory";
import * as i3 from "../onboarding-guide/onboarding-guide.component";
import * as i4 from "../../services/onboarding.service";
import * as i5 from "@angular/common";
import * as i6 from "./onboarding-guided-overlay.component";
import * as i7 from "../../services/onboarding-guide.service";
var styles_OnboardingGuidedOverlayComponent = [i0.styles];
var RenderType_OnboardingGuidedOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OnboardingGuidedOverlayComponent, data: {} });
export { RenderType_OnboardingGuidedOverlayComponent as RenderType_OnboardingGuidedOverlayComponent };
function View_OnboardingGuidedOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-onboarding-guide", [], null, [[null, "onDismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDismiss" === en)) {
        var pd_0 = (_co.handleDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OnboardingGuideComponent_0, i2.RenderType_OnboardingGuideComponent)), i1.ɵdid(1, 245760, null, 0, i3.OnboardingGuideComponent, [i4.OnboardingService], { onboarding: [0, "onboarding"] }, { onDismiss: "onDismiss" })], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OnboardingGuidedOverlayComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "onboarding-overlay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleOverlayClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "onboarding-popover__arrow"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "onboarding-popover"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_OnboardingGuidedOverlayComponent_1)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.overlayStyles$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.guidePopoverArrowStyles$)); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.guidePopoverStyles$)); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.onboarding$)); _ck(_v, 13, 0, currVal_3); }, null); }
export function View_OnboardingGuidedOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-onboarding-guided-overlay", [], [[4, "z-index", null], [4, "opacity", null]], null, null, View_OnboardingGuidedOverlayComponent_0, RenderType_OnboardingGuidedOverlayComponent)), i1.ɵdid(1, 245760, null, 0, i6.OnboardingGuidedOverlayComponent, [i7.OnboardingGuideService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).overlayActive; var currVal_1 = i1.ɵnov(_v, 1).overlayActiveTransparency; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var OnboardingGuidedOverlayComponentNgFactory = i1.ɵccf("app-onboarding-guided-overlay", i6.OnboardingGuidedOverlayComponent, View_OnboardingGuidedOverlayComponent_Host_0, {}, {}, []);
export { OnboardingGuidedOverlayComponentNgFactory as OnboardingGuidedOverlayComponentNgFactory };
