import {Injectable} from '@angular/core';

import {BrowserOpenOptions, Plugins} from '@capacitor/core';
import {TranslateService} from '@ngx-translate/core';
import {PlatformService} from './platform.service';
import {PopoverController} from '@ionic/angular';
import {PopupBlockedNoticeComponent} from '../components/popup-blocked-notice/popup-blocked-notice.component';
import {of} from 'rxjs';
import {delay, first} from 'rxjs/operators';


export enum ExternalLinksEnum {
    paypal = 'https://www.paypal.com/donate?hosted_button_id=SX4VVQPZ9WG5Q',
    tawk = 'https://estudy.tawk.help/',
}

const {Browser} = Plugins;

@Injectable({
    providedIn: 'root'
})
export class ExternalLinkService {

    constructor(
        private translateService: TranslateService,
        private platformService: PlatformService,
        private modalPopover: PopoverController
    ) {
    }

    open(url: string, options?: BrowserOpenOptions): void {
        this.isPopUpsBlocked(url).then(isBlocked => {
            if (!isBlocked) {
                Browser.open({ ...(options || {}), url});
            }
        });
    }
    private isPopUpsBlocked(url: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.platformService.isNativeiOS || (this.platformService.isiOS && this.platformService.isSafari)) {
                return resolve(false);
            }
            let popUp = window.open(url);
            if ((!popUp )) {
                this.showNotificationPopover(url);
                resolve(true);
            } else {
                popUp.close();
                of(true)
                    .pipe(delay(10), first())
                    .subscribe(() => {
                        popUp = window.open(url);
                        if ((!popUp )) {
                            this.showNotificationPopover(url);
                            resolve(true);
                        } else {
                            popUp.close();
                            resolve(false);
                        }
                    });
            }
        });
    }

    private async showNotificationPopover(url: string): Promise<void> {
        const popover = await this.modalPopover.create({
            component: PopupBlockedNoticeComponent,
            cssClass: ['popover-blocked-notice'],
            componentProps: {
                doDismiss: () => popover.dismiss(),
            },
        });
        await popover.present();
    }
}
