import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chat-closed-notice',
  templateUrl: './chat-closed-notice.component.html',
  styleUrls: ['./chat-closed-notice.component.scss'],
})
export class ChatClosedNoticeComponent implements OnInit {
  @Output() ok = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {}

}
