import * as firebase from './firebase/firebase.options.stage.json';
import { COMMON_API_CONFIG, IAppEnvironment } from './models';

export const environment: IAppEnvironment = {
  production: true,
  showLogs: true,
  firebase: (firebase as any).default,
  hideZulipChatOlderThenHours: 336, // 2 weeks
  ...COMMON_API_CONFIG,
};
