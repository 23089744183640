<ion-header mode="ios" #headerRef>
  <ion-toolbar mode="ios">
    <ion-item class="chat-finder-toolbar-item">
      <div class="chat-finder-toolbar-content">
        <div *ngIf="(newConversationMembers$ | async).length > 0" class="new-conversation-members-list">
          <ion-chip *ngFor="let entry of newConversationMembers$ | async" class="new-member-entry">
            <ion-avatar>
              <img [src]="entry.avatarUrl || 'assets/img/boy-placeholder.svg'" alt="" />
            </ion-avatar>
            <ion-label>{{ entry.value }}</ion-label>
            <ion-button (click)="toggle(entry)" class="rm-member-btn">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-chip>
        </div>
        <ion-input
          [formControl]="searchControl"
          debounce="200"
          autofocus="true"
          type="search"
          inputmode="search"
          class="search-input"
          [placeholder]="searchControlPlaceholder$ | async | translate"
          #searchControlRef>
        </ion-input>
      </div>
      <ion-icon src="/assets/img/search-thin-ic.svg" class="search-ico" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="searchControlRef.setFocus()" class="checkbox chat-finder-toolbar-item fulltext-search-check-item" lines="none">
      <ion-label>{{ 'chat.fulltext_search_check_label' | translate }}</ion-label>
      <ion-checkbox slot="start" [formControl]="fullTextSearchControl"></ion-checkbox>
    </ion-item>
    <ion-item
      *ngIf="(newConversationMembers$ | async).length > 0 && (existingStream$ | async) == null"
      class="chat-finder-toolbar-item">
      <ion-input
        [formControl]="newConversationTitleControl"
        placeholder="{{ 'chat.new_chat_input_placeholder' | translate }}">
      </ion-input>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content [scrollX]="false" styledScrollbar="shadow-root" appRemoveContentSizing>
  <div styledScrollbarContent>
    <ng-container *ngIf="searchResults$ | async as searchResults">
      <ion-item
        *ngIf="searchLoading$ | async; else searchResultsTempl"
        class="search-loading-item"
        lines="none">
        <div class="search-loading-item-content">
          <ion-spinner></ion-spinner>
          <ion-label>Searching…</ion-label>
        </div>
      </ion-item>
      <ng-template #searchResultsTempl>
        <ion-list
          *ngIf="searchResults.length > 0; else noSearchResultsTempl"
          [hidden]="!(searchActive$ | async)"
          lines="none">
          
          <ng-container *ngIf="fullTextSearchControl.value; else conversationsSearchTempl">
            <ng-container *ngFor="let entry of searchResults">
              <app-chat-conversation-item
                (click)="goToConversation(entry.streamId)"
                [title]="entry.value"
                [isChannel]="entry.type === searchEntryTypes.Stream"
                [members]="entry.members"
                lines="none"
                class="full-text-search-entry">
                <ion-text class="last-message">
                  <strong>{{ entry!.content.senderFullName }}:</strong>&nbsp;
                  <div [innerHTML]="entry!.content.message | unescapeHtml"></div>
                </ion-text>
              </app-chat-conversation-item>
            </ng-container>
          </ng-container>
          <ng-template #conversationsSearchTempl>
            <ng-container *ngFor="let entry of searchResults">
              <app-chat-conversation-item
                (click)="handleConversationItemClick(entry)"
                [title]="entry.value"
                [isChannel]="entry.type === searchEntryTypes.Stream"
                [members]="entry.members"
                lines="none"
                [class.added]="isAdded(newConversationMembers$ | async, entry.id)">
              </app-chat-conversation-item>
            </ng-container>
          </ng-template>
          
        </ion-list>
        <ng-template #noSearchResultsTempl>
          <ion-item [hidden]="!(searchActive$ | async)" class="no-search-results-item" lines="none">
            <ion-label>No results</ion-label>
          </ion-item>
        </ng-template>
      </ng-template>
    </ng-container>
    <ion-list [hidden]="searchActive$ | async" lines="none">
      <ion-list-header>
        <ion-label class="recent-chats-header">{{ 'chat.recent_chats' | translate }}</ion-label>
      </ion-list-header>
  
      <ng-container *ngFor="let entry of recentStreams$ | async; trackBy: trackByStreamId">
        <app-chat-conversation-item
          (click)="goToConversation(entry.stream_id)"
          [title]="entry.title"
          class="recent-dm-entry"
          lines="none"
          [attr.id]="entry.stream_id">
          <ion-text *ngIf="entry.recentMessage as recentMessage" class="last-message">
            <strong>
              <span *ngIf="entry.recentMessage.sender_id !== (currentUser$ | async).user_id; else currentUserSenderTempl">
                {{ entry.recentMessage.senderFullName }}
              </span>
              <ng-template #currentUserSenderTempl>
                <span>{{ 'chat.last_message_sender_you' | translate }}</span>
              </ng-template>
            </strong>:&nbsp;
            <span class="msg-content" [innerHTML]="recentMessage.content | unescapeHtml"></span>
          </ion-text>
        </app-chat-conversation-item>
      </ng-container>
    </ion-list>
  </div>
</ion-content>
<ion-footer mode="ios" [hidden]="(newConversationMembers$ | async).length === 0">
  <ion-button (click)="create()" [disabled]="loading$ | async" size="block">
    <ion-text>{{ 'common.btn-confirm' | translate }}</ion-text>
    <ion-spinner *ngIf="loading$ | async"></ion-spinner>
  </ion-button>
</ion-footer>