import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EMPTY, Observable } from 'rxjs';
import { IResponse } from '../models/response';
import { HttpService } from './http.service';
import { ToastService, TOAST_TYPE } from './toast.service';
import { PlatformService } from './platform.service';
import { EntityType, IInstantMeetLink, TicketParent } from '../models/common';
import { ExternalLinkService } from './external-link.service';
import { IWhiteboardUploadData, IWhiteboardOpenData, BREAKOUT_ROOM_STORE_KEY, IWhiteboard } from '../components/whiteboard/models';
import { IOpenMaterialAttachmentParams } from './material-tickets.service';
import { EnvService } from './env/env.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, map, switchMap, tap, timeoutWith } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { UserService } from 'src/app/auth/services/user.service';
import { nanoid } from 'nanoid'
import { UserSettingsService } from 'src/app/auth/services/user-settings.service';

interface IGetWhiteboardFileUrlParams {
  entityId: string;
  entityType: EntityType;
  ticketParent: TicketParent;
  attachmentId: string;
  saveToChat: boolean;
  openMaterialAttachmentParams: IOpenMaterialAttachmentParams;
}
export interface IWhiteboardRoom {
  id: string;
  owner_id: string;
  title: string;
  entityType: EntityType | "chat-pm" | "instant-meet";
}
export interface IWhiteboardData {
  room: string;
  teacher: string;
  fullName: string;
  attachmentId: string;
  assignedTaskId: string;
}

export interface IBreakoutRoomJoinPayload {
  estudy_uid: string,
  obj_id?: number,
  ui?: string,
  uid?: number,
  origin_uid?: number,
  id?: string,
}

export interface IBreakoutRoomExitPayload extends IBreakoutRoomJoinPayload {}

@Injectable({
  providedIn: 'root'
})
export class WhiteboardService {
  private isWhiteboardOpened = false;

  private WHITEBOARD_URL: string = 'v1/whiteboard';

  private readonly GET_WHITEBOARD_HTML = (params: IGetWhiteboardFileUrlParams) => `/whiteboard-loader.html?type=attachment&ticketParent=${params.ticketParent}&entityType=${params.entityType}&entityId=${params.entityId}&attachmentId=${params.attachmentId}&ticketId=${params.openMaterialAttachmentParams.parentTicketId}&assignedTaskId=${params.openMaterialAttachmentParams.assignedTaskId}&saveToChat=${params.saveToChat}`;
  private readonly POST_UPLOAD_IMAGE = (ticketParent: TicketParent) => `${this.WHITEBOARD_URL}/save-image?ticketParent=${ticketParent}`;
  private readonly POST_UPLOAD_PDF =  (ticketParent: TicketParent) => `${this.WHITEBOARD_URL}/save-pdf?ticketParent=${ticketParent}`;
  private readonly POST_UPLOAD_DOC = (ticketParent: TicketParent) => `${this.WHITEBOARD_URL}/save-doc?ticketParent=${ticketParent}`;
  private readonly POST_BREAKOUT_ROOM_JOIN = `v3/whiteboard/breakout-room-join`;
  private readonly POST_BREAKOUT_ROOM_EXIT = `v3/whiteboard/breakout-room-exit`;
  private readonly POST_BREAKOUT_ROOM_REQUEST_RETURN = `v3/whiteboard/breakout-room-request-return`;
  private readonly GET_ONE_BY_ID = (id: string) => `${ this.WHITEBOARD_URL }/${ id }`;
  private readonly PATCH_ONE = this.WHITEBOARD_URL;
  private readonly POST_ONE = this.WHITEBOARD_URL;

  constructor(
    private authService: AuthService,
    private platformService: PlatformService,
    private externalLinkService: ExternalLinkService,
    private popoverCtrl: PopoverController,
    private httpService: HttpService,
    private envService: EnvService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private storage: Storage,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
  ) {
    this.authService.isAuthenticated$.pipe(
      filter(isAuthenticated => !isAuthenticated),
      switchMap(() => this.storage.remove(BREAKOUT_ROOM_STORE_KEY))
    )
    .subscribe({
      error: err => console.error(err)
    })
  }

  checkWhiteboardOpened(): boolean {
    return this.isWhiteboardOpened;
  }

  async openAttachmentInWhiteboard(data: IWhiteboardOpenData): Promise<void> {
    if (this.isWhiteboardOpened) {
      return;
    }
    this.isWhiteboardOpened = true;
    const whiteboardFileUrlParams: IGetWhiteboardFileUrlParams = this.getWhiteboardFileUrlParams(data);
    const popover = await this.popoverCtrl.create({
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'popover-whiteboard',
      component: data.component,
      componentProps: {
        url: this.getWhiteboardFileUrl(whiteboardFileUrlParams),
        ticketParent: data.ticketParent,
        parentTicketIdForMaterialTicket: whiteboardFileUrlParams.openMaterialAttachmentParams.parentTicketId,
        attachment: data.attachment,
        saveToChat: data.saveToChat,
        onDismiss: (err: Error) => {
          popover.dismiss();
          this.isWhiteboardOpened = false;

          if (err) {
            this.handleWhiteboardError(err);
          }
        },
      },
    });
    return popover.present();
  }

  async openConference(room: IWhiteboardRoom, component: any): Promise<void> {
    if (this.isWhiteboardOpened) {
      return;
    }

    // let params = `id=${encodeURIComponent(room.id)}&entity=${encodeURIComponent(room.entityType)}${room.entityType == "chat-pm" ? `&owner=${encodeURIComponent(room.owner_id)}` : ''}`;
    const prms = new URLSearchParams({
      type: 'whiteboard',
      id: room.id,
      entity: room.entityType,
    })
    const isWebRtcWork = await this.platformService.isWebRtcWork();
    const url = new URL(`${this.envService.apiConfig.groupworldEndpoint}/whiteboard-loader.html`)
    
    if (room.entityType == 'chat-pm' || room.entityType == 'instant-meet') {
      prms.set('owner', room.owner_id)
    }

    if(isWebRtcWork) {
      url.search = prms.toString()
      this.isWhiteboardOpened = true;
       const popover = await this.popoverCtrl.create({
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: 'popover-whiteboard-wide',
        component: component,
        componentProps: {

          // url: `${this.envService.apiConfig.groupworldEndpoint}/whiteboard-loader.html?type=whiteboard&${params}`,
          url: url.toString(),
          onDismiss: (err: Error) => {
            popover.dismiss();
            this.isWhiteboardOpened = false;

            if (err) {
              this.handleWhiteboardError(err);
            }
          },
        },
      });
      return popover.present();
    } else {
      // params = params + `&jwt_token=${encodeURIComponent(this.authService.jwtToken)}`;
      // this.externalLinkService.open(`${this.envService.apiConfig.groupworldEndpoint}/whiteboard-loader.html?type=whiteboard&${params}`);

      const url = new URL(`${this.envService.apiConfig.groupworldEndpoint}/whiteboard-loader.html`)
      prms.set('jwt_token', this.authService.jwtToken)
      url.search = prms.toString()
      this.externalLinkService.open(url.toString());
    }
  }

  uploadPdf$(uploadData: IWhiteboardUploadData<Blob>): Observable<IResponse<any>> {
    const url: string = this.POST_UPLOAD_PDF(uploadData.ticketParent);
    const formData = this.getFileFormData('pdf', uploadData);

    return this.httpService.post$(url, formData, { responseType: uploadData.shouldDownload ? 'arraybuffer' : 'json' });
  }

  uploadDoc$(uploadData: IWhiteboardUploadData<Blob>): Observable<IResponse<any>> {
    const url: string = this.POST_UPLOAD_DOC(uploadData.ticketParent);
    const formData = this.getFileFormData('doc', uploadData);

    return this.httpService.post$(url, formData, { responseType: uploadData.shouldDownload ? 'arraybuffer' : 'json' });
  }

  uploadImage$(uploadData: IWhiteboardUploadData<string>): Observable<IResponse<any>> {
    const url: string = this.POST_UPLOAD_IMAGE(uploadData.ticketParent);
    const data = {
      filename: uploadData.payload,
      attachmentId: uploadData.attachmentId,
      assignedTaskId: uploadData.assignedTaskId,
      shouldDownload: uploadData.shouldDownload,
      isSameDestination: uploadData.isSameDestination,
      ticketId: uploadData.parentTicketIdForMaterialTicket,
      saveToChat: uploadData.saveToChat,
    };
    return this.httpService.post$(url, data, { responseType: uploadData.shouldDownload ? 'arraybuffer' : 'json' });
  }

  getWhiteboardFileUrl(params: IGetWhiteboardFileUrlParams): string {
    return `${this.envService.apiConfig.groupworldEndpoint}${this.GET_WHITEBOARD_HTML(params)}`;
  }

  sendBreakoutRoomJoin(data: { payload: IBreakoutRoomJoinPayload, user_id: string }): Observable<IResponse<void>> {
    return this.httpService.post$<void>(this.POST_BREAKOUT_ROOM_JOIN, data);
  }

  sendBreakoutRoomExit(data: { payload: IBreakoutRoomExitPayload, user_id: string }): Observable<IResponse<void>> {
    return this.httpService.post$<void>(this.POST_BREAKOUT_ROOM_EXIT, data);
  }

  sendBreakoutRoomRequestReturn(data: { payload: any, user_id: string }): Observable<IResponse<void>> {
    return this.httpService.post$<void>(this.POST_BREAKOUT_ROOM_REQUEST_RETURN, data);
  }

  getOneById$(id: string): Observable<IResponse<IWhiteboard>> {
    return this.httpService.get$(this.GET_ONE_BY_ID(id));
  }

  create$(payload: IWhiteboard): Observable<IResponse<IWhiteboard>> {
    return this.httpService.post$(this.POST_ONE, payload)
  }

  update$(payload: IWhiteboard): Observable<IResponse<IWhiteboard>> {
    return this.httpService.patch$(this.PATCH_ONE, payload)
  }

  async createInstantLessonLink(): Promise<{ url: string, id: string, ownerId: string }> {
    const user = await this.userService
      .getUserState$()
      .pipe(first())
      .toPromise()
    const url = new URL(location.origin + '/conf/instant-lesson.html')
    const params = new URLSearchParams()
    const id = 'instant_lesson_' + nanoid(32)
    params.set('id', id)
    params.set('presenter-name', [user.firstName, user.lastName].join(' '))
    url.search = params.toString()
    const currentSettings = this.userSettingsService.getSettings();
    const link = {
      url: url.toString(),
      ownerId: user.id,
      id,
    }
    this.userSettingsService.updateSettings({
      ...currentSettings,
      instantMeetLink: link
    })
    return link
  }

  getInstantMeetLink$(): Observable<null | IInstantMeetLink> {
    return this.userSettingsService
      .userSettings$()
      .pipe(
        filter(settings => settings != null && settings.instantMeetLink != null),
        map(settings => settings.instantMeetLink)
      )
  }

  private getFileFormData(fileType: 'pdf' | 'doc', uploadData: IWhiteboardUploadData<Blob>): FormData {
    const formData = new FormData();
    formData.append(fileType, uploadData.payload);
    formData.append('attachmentId', uploadData.attachmentId);
    formData.append('assignedTaskId', uploadData.assignedTaskId);
    formData.append('shouldDownload', String(uploadData.shouldDownload));
    formData.append('isSameDestination', String(uploadData.isSameDestination));
    formData.append('ticketId', String(uploadData.parentTicketIdForMaterialTicket));
    formData.append('saveToChat', String(uploadData.saveToChat));

    return formData;
  }

  private getWhiteboardFileUrlParams(data: IWhiteboardOpenData): IGetWhiteboardFileUrlParams {
    return {
      entityId: data.entityId,
      entityType: data.entityType,
      ticketParent: data.ticketParent,
      attachmentId: data.attachment.id,
      saveToChat: data.saveToChat,
      openMaterialAttachmentParams: data.openMaterialAttachmentParams
        ? data.openMaterialAttachmentParams
        : { parentTicketId: null, assignedTaskId: null },
    };
  }

  private handleWhiteboardError(err: Error) {
    const msg = this.translateService.instant('connection-errors.something-went-wrong');
    this.toastService.showToast(msg, TOAST_TYPE.ERROR);
  }
}
