import { NodeType } from './models/node_type';
import { INode } from './models/node';

export const TREE_CONFIG = {
    alwaysOpenTypes: [
    ],
    hasPagesTypes: [
        NodeType.SCHOOL,
        NodeType.CLASS,
        NodeType.SUBJECT_GROUP,
        NodeType.SUBJECT_DASHBOARD,
        NodeType.SUBJECT_CALENDAR,
        NodeType.LESSON_GROUP,
        NodeType.LESSON_CALENDAR,
        NodeType.LESSON,
        NodeType.MATERIAL,
        NodeType.MEETING,
    ],
    groupTranslateKeys: {
        [NodeType.CREATE]: 'common.btn-create',
        [NodeType.SCHOOL_GROUP]: 'school.title-many',
        [NodeType.CLASS_GROUP]: 'class.title-many',
        [NodeType.SUBJECT_GROUP]: 'subject.title-many',
        [NodeType.LESSON_GROUP]: 'lesson.title-many',
        [NodeType.LESSON_CALENDAR]: 'lesson.calendar',
        [NodeType.MATERIAL_GROUP]: 'material.title-many',
        [NodeType.MEETING]: 'meeting.title-many',
    }
};

export const DEFAULT_TREE: INode[] = [
    {
        id: '0',
        name: TREE_CONFIG.groupTranslateKeys[NodeType.CREATE],
        type: NodeType.CREATE,
        icon: 'assets/img/add-ic.svg',
        showButtons: true,
        editEnabled: true,
    },
    {
        id: '1',
        name: TREE_CONFIG.groupTranslateKeys[NodeType.SCHOOL_GROUP],
        icon: 'assets/img/school-ic.svg',
        type: NodeType.SCHOOL_GROUP,
        children: [],
    },
    {
        id: '2',
        name: TREE_CONFIG.groupTranslateKeys[NodeType.CLASS_GROUP],
        icon: 'assets/img/class-ic.svg',
        type: NodeType.CLASS_GROUP,
        children: [],
    },
    {
        id: '3',
        name: TREE_CONFIG.groupTranslateKeys[NodeType.SUBJECT_GROUP],
        icon: 'assets/img/subject-ic.svg',
        type: NodeType.SUBJECT_GROUP,
        children: [],
    },
    {
        id: '4',
        name: TREE_CONFIG.groupTranslateKeys[NodeType.LESSON_CALENDAR],
        icon: 'assets/img/calendar-ic.svg',
        type: NodeType.LESSON_CALENDAR,
        children: [],
    },
    {
        id: '5',
        name: TREE_CONFIG.groupTranslateKeys[NodeType.LESSON_GROUP],
        icon: 'assets/img/lessons-ic.svg',
        type: NodeType.LESSON_GROUP,
        children: [],
    },
    {
        id: '6',
        name: TREE_CONFIG.groupTranslateKeys[NodeType.MATERIAL_GROUP],
        icon: 'assets/img/materials-ic.svg',
        type: NodeType.MATERIAL_GROUP,
    },
    {
        id: '7',
        name: TREE_CONFIG.groupTranslateKeys[NodeType.MEETING],
        icon: 'assets/img/meeting-ic.svg',
        type: NodeType.MEETING,
        collapseMenuWhenActive: true
    },
];
