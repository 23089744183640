import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
const { Device } = Plugins;

enum Platform {
  Android = 'Android',
  iOS = 'iOS',
  MacOS = 'Mac OS',
  Windows = 'Windows',
  Linux = 'Linux',
}

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private userAgent = window.navigator.userAgent;
  private platform = window.navigator.platform;
  private macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  private windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  private iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  os: Platform = null;

  constructor() {}

  get isMobile(): boolean {
    return [ Platform.Android, Platform.iOS ].includes(this.os);
  }

  get isiOS(): boolean {
    return this.os === Platform.iOS;
  }

  get isNativeiOS(): boolean {
    return this.os === Platform.iOS
      ? Capacitor.isNative
      : false;
  }

  get isNativeiPadOS(): boolean {
    return  [Platform.MacOS].includes(this.os)
      ? Capacitor.isNative
      : false;
  }

  get isAndroid(): boolean {
    return this.os === Platform.Android;
  }

  get isFireFox(): boolean {
    return window.navigator.userAgent.match(/Firefox\/([0-9]+)\./) !== null;
  }

  get isSafari(): boolean {
    return navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0;
  }

  get isDesktop(): boolean {
    return [ Platform.Windows, Platform.MacOS, Platform.Linux ].includes(this.os);
  }

  async isWebRtcWork(): Promise<boolean> {
      if(this.isNativeiOS) {
        const info = await Device.getInfo();
        const osVersion = parseFloat(info.osVersion);
        return osVersion > 14.4;
      }
      return true;
  }

  setOS(): void {
    if (this.macosPlatforms.indexOf(this.platform) !== -1) {
      this.os = Platform.MacOS;
    } else if (this.iosPlatforms.indexOf(this.platform) !== -1) {
      this.os = Platform.iOS;
    } else if (this.windowsPlatforms.indexOf(this.platform) !== -1) {
      this.os = Platform.Windows;
    } else if (/Android/.test(this.userAgent)) {
      this.os = Platform.Android;
    } else if (!this.os && /Linux/.test(this.platform)) {
      this.os = Platform.Linux;
    }
    this.displayConsole();
  }

  private displayConsole(): void {
    const suffix = this.isNativeiOS ? 'Native' : '';
    console.log(`%c [PLATFORM]: ${suffix}`, 'color: green', JSON.stringify(this.os));
  }
}
