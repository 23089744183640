import { BehaviorSubject, Subject, merge } from 'rxjs';
import { INotificationMessage, IBaseNotification, ITicketTaskNotification, NotificationType } from '../models/notifications';
import { shareReplay, take, tap, filter, pluck, map, bufferTime, concatMap, takeUntil, withLatestFrom, switchMap, exhaustMap, mapTo } from 'rxjs/operators';
import { HttpService } from './http.service';
import { showErrorIfExists, isSuccess } from '../helpers/helpers';
import { ToastService } from './toast.service';
import { SocketService } from './socket.service';
import { SocketMessageType, EntityType, EventType, IStudentTask } from '../models/common';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./toast.service";
import * as i3 from "./socket.service";
const cloneDeep = require('lodash.clonedeep');
export class NotificationSidebarService {
    constructor(httpService, toastService, socketService) {
        this.httpService = httpService;
        this.toastService = toastService;
        this.socketService = socketService;
        this.LIMIT = 20;
        this.pageToLoad = 1;
        this.notifications$ = new BehaviorSubject(null);
        this.unreadedCount$ = new BehaviorSubject(0);
        this.markAsReadEmitter$ = new Subject();
        this.loadedMoreEmitter$ = new Subject();
        this.sidebarState$ = new BehaviorSubject('out');
        this.destroy$ = new Subject();
        this.loadUnreadedCountEmitter$ = new Subject();
        this.subtractUnreadedCountEmitter$ = new Subject();
        this.markAllAsReadEmitter$ = new Subject();
        this.readAllNotifications$ = new Subject();
    }
    getNotifications$() {
        return this.notifications$.asObservable()
            .pipe(shareReplay(1));
    }
    getUnreadCount$() {
        return this.unreadedCount$.asObservable();
    }
    getSidebarState$() {
        return this.sidebarState$.asObservable();
    }
    init(infiniteScroll) {
        this.infiniteScroll = infiniteScroll;
        this.infiniteScroll.disabled = true;
        this.listenForNotificationsDataChanges();
        this.listenForUnreadedDataChanges();
    }
    destroy() {
        this.notifications$.next(null);
        this.unreadedCount$.next(0);
        this.destroy$.next();
    }
    loadMoreNotifications() {
        this.pageToLoad += 1;
        this.loadNotifications$(this.pageToLoad)
            .pipe(tap(response => {
            showErrorIfExists(response, this.toastService);
            if (response.error) {
                this.pageToLoad -= 1;
                this.infiniteScroll.complete();
            }
            if (response.payload.length === 0) {
                this.pageToLoad -= 1;
            }
        }), filter(response => isSuccess(response)), pluck('payload'), take(1))
            .subscribe(notifications => this.loadedMoreEmitter$.next(notifications));
    }
    markAsRead(message) {
        this.markAsReadEmitter$.next(message);
    }
    toggle() {
        const currSidebarState = this.sidebarState$.value;
        this.sidebarState$.next(currSidebarState === 'in' ? 'out' : 'in');
    }
    markAllAsRead() {
        this.markAllAsReadEmitter$.next();
    }
    loadNotifications$(page) {
        const url = `v1/notifications?page=${page}&limit=${this.LIMIT}`;
        return this.httpService.get$(url);
    }
    hasMoreNotifications(data) {
        return data.length === this.LIMIT;
    }
    markAsRead$(messages) {
        const url = 'v1/notifications';
        const data = messages.map(message => ({ id: message.id, is_read: true }));
        return this.httpService.patch$(url, data)
            .pipe(map(response => response.payload ? response.payload : []));
    }
    addNewNotification(message) {
        const currentMessages = cloneDeep(this.notifications$.value);
        currentMessages.unshift(message);
        return currentMessages;
    }
    addMoreNotifications(messages) {
        const currentMessages = cloneDeep(this.notifications$.value);
        currentMessages.push(...messages);
        return currentMessages;
    }
    updateNotifications(messages) {
        const currentMessages = cloneDeep(this.notifications$.value);
        messages
            .forEach(message => {
            const index = currentMessages.findIndex(msg => msg.id === message.id);
            if (index !== -1) {
                currentMessages[index] = message;
            }
        });
        return currentMessages;
    }
    listenForUnreadedDataChanges() {
        const substractCount$ = this.subtractUnreadedCountEmitter$
            .pipe(filter(value => value && value > 0), map(value => {
            const currentUnreadedCount = this.unreadedCount$.getValue();
            return currentUnreadedCount - value;
        }));
        const markAllAsRead$ = this.markAllAsReadEmitter$
            .pipe(exhaustMap(() => this.markAllAsReadReq$()), filter(response => isSuccess(response)), tap(() => this.readAllNotifications$.next()), mapTo(0));
        merge(this.loadUnreadedCount$(), substractCount$, markAllAsRead$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(count => this.unreadedCount$.next(count));
    }
    listenForNotificationsDataChanges() {
        this.getNotificationsData$()
            .pipe(tap(data => {
            if (this.hasMoreNotifications(data)) {
                this.infiniteScroll.disabled = false;
            }
            if (data) {
                this.notifications$.next(data);
            }
            this.infiniteScroll.complete();
        }), takeUntil(this.destroy$))
            .subscribe();
    }
    getNotificationsData$() {
        const init$ = this.loadNotifications$(this.pageToLoad)
            .pipe(tap(response => showErrorIfExists(response, this.toastService)), filter(response => isSuccess(response)), tap(() => this.loadUnreadedCountEmitter$.next()), pluck('payload'), take(1));
        const newNotifications$ = this.socketService.messages$()
            .pipe(filter(msg => msg.type === SocketMessageType.NOTIFICATIONS), map(msg => msg.payload), map((notification) => this.addNewNotification(notification)), tap(() => this.loadUnreadedCountEmitter$.next()));
        const readUpdates$ = this.markAsReadEmitter$
            .pipe(bufferTime(2000), filter(values => values.length > 0), concatMap(notifications => this.markAsRead$(notifications)), tap(notifications => {
            if (notifications && notifications.length) {
                this.subtractUnreadedCountEmitter$.next(notifications.length);
            }
        }), map(notifications => this.updateNotifications(notifications)));
        const loadedMore$ = this.loadedMoreEmitter$
            .pipe(map(notifications => this.addMoreNotifications(notifications)));
        const removeUpdates$ = merge(this.getAssignedTaskUpdates$(), this.getTicketUpdates$(), this.getMeetingUpdates$())
            .pipe(tap(() => this.loadUnreadedCountEmitter$.next()));
        const readAllNotifications$ = this.readAllNotifications$
            .pipe(map(() => {
            const currentNotifications = cloneDeep(this.notifications$.value);
            return currentNotifications.map(notification => {
                notification.is_read = true;
                return notification;
            });
        }));
        return merge(init$, removeUpdates$, newNotifications$, loadedMore$, readUpdates$, readAllNotifications$);
    }
    getMeetingUpdates$() {
        return this.socketService.getCrudMessages$([
            EntityType.MEETING,
        ]).pipe(filter(message => message.eventType === EventType.REMOVE), withLatestFrom(this.notifications$), map(values => {
            const [message, notifications] = values;
            return notifications.map(notification => {
                if (message.entityId === notification.payload.id) {
                    notification.payload.is_deleted = true;
                    notification.is_read = true;
                }
                return notification;
            });
        }));
    }
    getAssignedTaskUpdates$() {
        return this.socketService.getCrudMessages$([
            EntityType.ASSIGNED_TASK,
        ])
            .pipe(filter(message => message.eventType === EventType.REMOVE), withLatestFrom(this.notifications$), map(values => {
            const studentTask = values[0].entity;
            const types = [
                NotificationType.TICKET_TASK_PUBLISHED,
                NotificationType.TICKET_TASK_CHANGED,
                NotificationType.TICKET_TASK_DUE_DATE,
                NotificationType.ASSIGNED_TASK_MESSAGE,
                NotificationType.ASSIGNED_TASK_STATE_CHANGE,
            ];
            return values[1].map(notification => {
                if (types.includes(notification.type) &&
                    (notification.payload.id === studentTask.assignedTask.id ||
                        notification.payload.assigned_task_id === studentTask.assignedTask.id)) {
                    notification.payload.is_deleted = true;
                    notification.is_read = true;
                }
                return notification;
            });
        }));
    }
    getTicketUpdates$() {
        return this.socketService.getCrudMessages$([
            EntityType.TICKET_TASK,
            EntityType.TICKET_GENERAL,
            EntityType.TICKET_NOTIFICATION,
        ])
            .pipe(filter(message => message.eventType === EventType.REMOVE), withLatestFrom(this.notifications$), map(values => {
            const ticket = values[0].entity;
            const types = [
                NotificationType.STUDENT_UPDATE_TASK,
                NotificationType.STUDENT_COMPLETE_TASK,
                NotificationType.TICKET_TASK_STATE_CHANGED,
                NotificationType.TICKET_ANNOUNCE_PUBLISHED,
                NotificationType.TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED,
            ];
            return values[1].map(notification => {
                if (types.includes(notification.type) && notification.payload.id === ticket.id) {
                    notification.payload.is_deleted = true;
                }
                return notification;
            });
        }));
    }
    loadUnreadedCount$() {
        return this.loadUnreadedCountEmitter$
            .pipe(switchMap(() => this.loadUnreadedCountReq$()), filter(response => !response.error), map(response => response.payload));
    }
    loadUnreadedCountReq$() {
        return this.httpService.get$('v1/notifications/unread-count')
            .pipe(map((response) => {
            if (isSuccess(response)) {
                return { payload: response.payload.count };
            }
            return Object.assign({}, response, { payload: null });
        }));
    }
    markAllAsReadReq$() {
        return this.httpService.post$('v1/notifications/mark-all', {});
    }
}
NotificationSidebarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationSidebarService_Factory() { return new NotificationSidebarService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.SocketService)); }, token: NotificationSidebarService, providedIn: "root" });
