<ion-item [class.collapsed]="collapsed" lines="none">
  <div class="join-input-wrapper">
    <ion-label *ngIf="showLabel" position="fixed">Invite code<!-- TODO: add to translations file --></ion-label>
    <ion-input *ngIf="!collapsed; else collapsedTempl" class="font-regular" [(ngModel)]="code" enterkeyhint="go" (keyup.enter)="join$.next(code)" [disabled]="isSubmitted" placeholder="{{ 'join-input.enter-code' | translate }}">
      <ion-button clear class="primary ion-float-right font-extra-bold" mode="md" [ngClass]="{'btn-submitted': isSubmitted}" slot="end" [disabled]="isSubmitted" (click)="join$.next(code)">
        {{ 'join-input.join' | translate }}
        <ion-spinner [hidden]="!isSubmitted" class="btn-spinner" color="light" name="dots"></ion-spinner>
      </ion-button>
    </ion-input>
  </div>
</ion-item>

<ng-template #collapsedTempl>
  <ion-button clear class="primary font-extra-bold" mode="md" [ngClass]="{'btn-submitted': isSubmitted}" [disabled]="isSubmitted" (click)="openJoinDialog($event)">
    {{ 'join-input.join' | translate }}
    <ion-spinner [hidden]="!isSubmitted" class="btn-spinner" color="light" name="dots"></ion-spinner>
  </ion-button>
</ng-template>