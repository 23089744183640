import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core'
import { concat, Observable, of, Subject, timer } from 'rxjs'
import { switchMap, mapTo, filter, tap, takeUntil, map } from 'rxjs/operators'
import { GuidePopoverPosition, IOnboarding, Onboarding } from '../../models/onboarding'
import { OnboardingGuideService } from '../../services/onboarding-guide.service'
import { OverlayHighlightOptions } from '../../services/onboarding.service'

type StylesMap = Record<string, string | number>

@Component({
  selector: 'app-onboarding-guided-overlay',
  templateUrl: './onboarding-guided-overlay.component.html',
  styleUrls: ['./onboarding-guided-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingGuidedOverlayComponent implements OnInit, OnDestroy {
  onboarding$: Observable<IOnboarding>
  overlayStyles$: Observable<StylesMap> = of({})
  guidePopoverStyles$:Observable<StylesMap> = of({})
  guidePopoverArrowStyles$:Observable<StylesMap> = of({})
  click$ = new Subject<void>()

  private overlayZIndex = false
  private overlayTransparency = false
  private dismiss$ = new Subject()
  private destroy$ = new Subject<void>()

  @HostBinding('style.z-index')
  get overlayActive() {
    return this.overlayZIndex ? '0' : '-1'
  }

  @HostBinding('style.opacity')
  get overlayActiveTransparency() {
    return this.overlayTransparency ? '0' : '1'
  }

  constructor(
    private onboardingGuideService: OnboardingGuideService,
  ) { }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit() {
    const showOverlay$ = this.onboardingGuideService.onShowOverlay$()

    showOverlay$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((action) =>
          // add delay before hidding popover and highlight to prevent transition
          // cancel because of z-index change
          action == null
            ? timer(400).pipe(mapTo(action))
            : of(action)
        )
      )
      .subscribe(value => {
        this.overlayZIndex = value != null
      })

    showOverlay$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(value => {
        this.overlayTransparency = value == null
      })

    this.onboarding$ = showOverlay$.pipe(
      map(actionPayload => actionPayload == null ? null : actionPayload.onboarding)
    )

    this.overlayStyles$ = showOverlay$.pipe(
      switchMap(actionPayload =>
        actionPayload == null
          ? of({})
          : this.transition$(actionPayload.options)
      )
    )

    const popoverStyles$ = showOverlay$.pipe(
      filter(actionPayload => actionPayload != null),
      map(actionPayload => {
        const { options, onboarding } = actionPayload
        return this.getPopoverStyles(
          this.getPopoverPosition(onboarding.type),
          options,
        )
      })
    )

    this.guidePopoverStyles$ = popoverStyles$.pipe(
      map(({ popoverStyles }) => popoverStyles)
    )

    this.guidePopoverArrowStyles$ = popoverStyles$.pipe(
      switchMap(({ popoverArrowStyles }) => concat(
        of(popoverArrowStyles),
        this.dismiss$.pipe(
          mapTo({ top: '100%', left: '100%', opacity: 0 } as StylesMap)
        )
      )),
    )

    showOverlay$
      .pipe(
        filter(options => options != null),
        switchMap(({ options }) => this.dismiss$.pipe(mapTo(options.onDismiss))),
        takeUntil(this.destroy$),
      )
      .subscribe(onDismiss => typeof onDismiss === 'function' && onDismiss())

    showOverlay$
      .pipe(
        filter(options => options != null),
        switchMap(({ options: { delegateClick } }) => this.click$.pipe(
          tap(() => delegateClick())
        )),
        takeUntil(this.destroy$)
      )
      .subscribe()
  }

  handleOverlayClick() {
    this.click$.next()
  }

  handleDismiss() {
    this.dismiss$.next()
  }

  private transition$(options: OverlayHighlightOptions): Observable<StylesMap> {
    const baseStyles = {
      'width.px': options.width,
      'height.px': options.height,
      'border-radius.px': options.borderRadius,
      'transform': `translate3d(${options.left}px, ${options.top}px, 0)`,
      'z-index': 2,
      'opacity': 0,
      'transition': 'var(--onboarding-overlay-transtion-out)',
    }

    return concat(
      of(baseStyles),
      timer(50).pipe(
        mapTo({
          ...baseStyles,
          'opacity': 1,
        })
      ),
    )
  }

  private getPopoverStyles(
    position: GuidePopoverPosition, 
    options: OverlayHighlightOptions, 
  ) {
    const popoverStyles: StylesMap = {
      opacity: 1,
    }
    const popoverArrowStyles: StylesMap = {
      opacity: 1,
    }

    switch (position) {
      case GuidePopoverPosition.Left: {
        const top = options.top - 10
        const left = options.left + options.width + 15
        popoverStyles['top.px'] = Math.round(top)
        popoverStyles['left.px'] = Math.round(left)
        popoverArrowStyles['top.px'] = Math.round(top + 22)
        popoverArrowStyles['left.px'] = Math.round(left - 10)
        break
      }
      case GuidePopoverPosition.Right: {
        const margin = 20
        const bottom = margin / 2
        const right = options.width + margin
        popoverStyles.top = 'unset'
        popoverStyles['right.px'] = Math.round(right)
        popoverStyles['bottom.px'] = Math.round(bottom)
        popoverArrowStyles['right.px'] = Math.round(right)
        popoverArrowStyles['bottom.px'] = Math.round(bottom + options.height / 2 - margin / 2)
        break
      }
      case GuidePopoverPosition.RightBelow: {
        const margin = 20
        const top = options.top + options.height + margin
        const right = window.innerWidth - options.left - options.width - margin
        popoverStyles.left = 'unset'
        popoverStyles['top.px'] = Math.round(top)
        popoverStyles['right.px'] = Math.round(right)
        popoverArrowStyles['top.px'] = Math.round(top - margin / 2)
        popoverArrowStyles['left.px'] = Math.round(options.left + options.width / 2)
        break
      }
      default:
        throw new Error(`Unsupported guide-popover position "${position}"`)
    }

    return { popoverStyles, popoverArrowStyles }
  }

  private getPopoverPosition(onboarding: Onboarding) {
    const positionsMapping = {
      [Onboarding.Subjects]: GuidePopoverPosition.Left,
      [Onboarding.FindCreatedSubjects]: GuidePopoverPosition.Left,
      [Onboarding.Lessons]: GuidePopoverPosition.Left,
      [Onboarding.SubjectStudents]: GuidePopoverPosition.Left,
      [Onboarding.SubjectTasks]: GuidePopoverPosition.Left,
      [Onboarding.TaskAttachments]: GuidePopoverPosition.RightBelow,
      [Onboarding.CommunityChat]: GuidePopoverPosition.Right,
      [Onboarding.Materials]: GuidePopoverPosition.Left,
      [Onboarding.Meetings]: GuidePopoverPosition.Left,
      [Onboarding.SchoolsAndClasses]: GuidePopoverPosition.Left,
    }
    return positionsMapping[onboarding]
  }

}
