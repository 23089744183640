<ion-item [ngSwitch]="true" [lines]="lines" [class.no-paddings]="noPaddings">
  <ng-container *ngSwitchCase="members.length === 1">
    <ion-avatar slot="start" [class.online]="members[0] | isChatUserOnline | async">
      <img src="assets/img/boy-placeholder.svg" alt="avatar" />
    </ion-avatar>
  </ng-container>
  <ng-container *ngSwitchCase="members.length === 2">
    <div slot="start" class="avatar-list">
      <ion-avatar>
        <img src="assets/img/boy-placeholder.svg" alt="avatar" />
      </ion-avatar>
      <ion-avatar>
        <img src="assets/img/boy-placeholder.svg" alt="avatar" />
      </ion-avatar>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="members.length > 2">
    <ion-icon slot="start" src="/assets/img/conversation-ic.svg"></ion-icon>
  </ng-container>

  <div class="content">
    <ion-text class="channel-name" [class.unread]="isUnread">{{ showTitle }}</ion-text>
    <span [hidden]="!isUnread" class="unread-bullet">&bull;</span>
    <ng-content></ng-content>
  </div>
  <ng-content select="[slot=end]"></ng-content>
</ion-item>
