import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs/operators';
import { ChatMember, IChatStream, IMessageId, INewMessage, IStreamId } from 'src/app/shared/models/chat';
import * as fromChat from '../reducers/chat'
import { Observable, zip } from 'rxjs';
import { isDevMode } from '@angular/core';

export const selectChatState = createFeatureSelector<fromChat.State>(fromChat.chatFeatureKey);

export const selectChatStreamsState = createSelector(
  selectChatState,
  (state: fromChat.State) => state.streams
)

export const selectChatStreamsMap = createSelector(
  selectChatState,
  (state: fromChat.State) => Object.entries(state.streams).reduce(
    (accum, [streamId, streamState]) => {
      accum[streamId] = streamState.data;
      return accum;
    },
    {} as Record<IStreamId, IChatStream>
  )
)

export const selectChatStreams = createSelector(
  selectChatStreamsState,
  (streams: fromChat.IChatStateStreams) => Object.values(streams)
)

export const selectChatStreamList = createSelector(
  selectChatStreams,
  (streams: fromChat.IChatStreamState[]) => streams.map(stream => stream.data)
)

export const selectChatStream = createSelector(
  selectChatState,
  (state: fromChat.State, streamId: IStreamId) => state.streams[streamId]
)

export const selectChatStreamByEntityId = createSelector(
  selectChatStreams,
  (streams: fromChat.IChatStreamState[], entityId: string) => (
    streams.find(stream => stream != null && stream.data != null && stream.data.name === entityId)
  )
)

export const selectChatStreamLoaded = createSelector(
  selectChatStream,
  (state: fromChat.IChatStreamState) => state != null && state.loaded
)

export const selectAllUnreads = createSelector(
  selectChatStreams,
  (state: fromChat.IChatStreamState[]) => (
    state.reduce((total, stream) => stream.firstUnread != null ? ++total : total, 0)
  )
)

export const selectFirstUnread = createSelector(
  selectChatStream,
  (state: fromChat.IChatStreamState) => state == null ? null : state.firstUnread
)

export const selectDrafts = createSelector(
  selectChatState,
  (state: fromChat.State) => state.drafts
)

export const selectDraftByTempId = createSelector(
  selectDrafts,
  (drafts: fromChat.IChatStateDrafts, draftId: IMessageId) => drafts[draftId]
)

export const selectDraftStatus = createSelector(
  selectDraftByTempId,
  (draft: INewMessage) => draft == null ? null : draft.status
)

export const selectChatStreamMessages = createSelector(
  selectChatStream,
  (streamState: fromChat.IChatStreamState) => streamState == null ? [] : streamState.messages
)

export const selectChatStreamLoading = createSelector(
  selectChatStream,
  (streamState: fromChat.IChatStreamState) => streamState == null ? false : streamState.loading
)

export const selectChatStreamAnchor = createSelector(
  selectChatStream,
  (streamState: fromChat.IChatStreamState) => streamState == null ? null : streamState.anchor
)

export const selectLoadRemoteMessagesSuccess = createSelector(
  selectChatStream,
  (streamState: fromChat.IChatStreamState) => !streamState.loading && streamState.error == null
)

export const selectChatStreamInfo = createSelector(
  selectChatStream,
  (streamState: fromChat.IChatStreamState) => streamState == null ? null : streamState.data
)

export const selectChatStreamSubscribers = createSelector(
  selectChatStreamInfo,
  (streamState: IChatStream) => streamState != null ? streamState.subscribers : []
)

export const selectMarkMessageRead = createSelector(
  selectChatState,
  (state: fromChat.State) => state.markMessagesRead
)

export const selectUsersState = createSelector(
  selectChatState,
  (state: fromChat.State) => state.users
)

export const selectUserById = createSelector(
  selectUsersState,
  (state: fromChat.IChatStateUsers, uid: number) => state[uid],
)

export const selectLoadedUserStateById = (uid: number) => {
  return (store: Store): Observable<fromChat.IChatUserState> => {
    return store.select(selectUserById, uid).pipe(
      filter(userState => userState != null && userState.data != null && !userState.loading),
    );
  }
}

export const selectLoadedUserById = (uid: number) => {
  return (store: Store): Observable<ChatMember> => {
    return store.pipe(
      selectLoadedUserStateById(uid),
      filter(u => u != null),
      map(userState => userState.data),
    );
  }
}

export const selectAllUsers = createSelector(
  selectUsersState,
  (state: fromChat.IChatStateUsers) => Object.values(state).map(entry => entry.data)
)

export const selectChatStreamMembers = (streamId: IStreamId) => {
  return (store: Store): Observable<Array<ChatMember>> => {
    return store.select(selectChatStreamSubscribers, streamId).pipe(
      switchMap(subs => {
        const subs$ = subs.map(sub =>
          store.pipe(
            selectLoadedUserStateById(sub),
            map((userState): ChatMember => ({
              ...userState.data,
              online: userState.online,
              fullName: `${ userState.data.firstname } ${ userState.data.lastname }`
            }))
          )
        )
        return zip(...subs$)
      }),
      map(users => Array.from(users).sort((a, b) => a.fullName > b.fullName ? 1 : -1))
    )
  }
}

export const selectChatStreamAttachments = createSelector(
  selectChatStream,
  (state: fromChat.IChatStreamState) => state == null ? null : state.attachments
)

export const selectChatStreamAttachmentsLoaded = createSelector(
  selectChatStream,
  (state: fromChat.IChatStreamState) => state == null ? false : state.attachmentsLoaded
)

export const _resetAllSelectors = () => {
  const selectors = [
    selectChatStreamsState,
    selectChatStreamsMap,
    selectChatStreams,
    selectChatStreamList,
    selectChatStream,
    selectChatStreamLoaded,
    selectAllUnreads,
    selectFirstUnread,
    selectDrafts,
    selectDraftByTempId,
    selectDraftStatus,
    selectChatStreamMessages,
    selectChatStreamLoading,
    selectChatStreamAnchor,
    selectLoadRemoteMessagesSuccess,
    selectChatStreamInfo,
    selectChatStreamSubscribers,
    selectMarkMessageRead,
    selectUsersState,
    selectAllUsers,
    selectChatStreamAttachments,
    selectChatStreamAttachmentsLoaded,
    selectUserById,
  ];

  for (let selector of selectors) {
    try {
      selector.release();
    } catch(err) {
      isDevMode() && console.error(err);
    }
  }
}
