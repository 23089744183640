import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    NgZone,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {AuthService} from '../../../auth/services/auth.service';
import {Observable, of, Subject} from 'rxjs';
import {delay, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {UserService} from 'src/app/auth/services/user.service';
import {EntityCrudViewService} from '../../services/entity-crud-view.service';
import {INode} from '../tree-menu/models/node';
import {NodeType} from '../tree-menu/models/node_type';
import {MenuController, NavController} from '@ionic/angular';
import {SubjectService} from '../../services/subject.service';
import {ConfirmAction, EntityType} from '../../models/common';
import {detectChanges, getEntityTypeFromNode} from '../../helpers/helpers';
import {AppMenuService} from '../../services/menu.service';
import {PlatformService} from '../../services/platform.service';
import {InviteModalService} from '../../services/invite-modal.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { ExternalLinkService, ExternalLinksEnum } from '../../services/external-link.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, OnDestroy {
    @Output() navChange: EventEmitter<void> = new EventEmitter();

    public logout$: Subject<void> = new Subject();
    public userName$: Observable<string> = this.userService.getUser$()
        .pipe(filter(user => !!user), map(user => `${user.firstName} ${user.lastName}`));
    public isCollapsed: boolean;

    primaryNode?: INode;
    reRenderTree$: Subject<void> = new Subject();
    isChildEditingTrigger$: Subject<void> = new Subject();

    private destroy$: Subject<void> = new Subject();

    constructor(
        private auth: AuthService,
        private userService: UserService,
        private entityCrudViewService: EntityCrudViewService,
        private navCtlr: NavController,
        private subjectService: SubjectService,
        private appMenuService: AppMenuService,
        private cdr: ChangeDetectorRef,
        private navCtrl: NavController,
        private zone: NgZone,
        private platformService: PlatformService,
        private menuCtrl: MenuController,
        private inviteModalService: InviteModalService,
        private confirmationService: ConfirmationService,
        private externalLinkSevice: ExternalLinkService,
    ) {
        this.appMenuService.collapsedChanges$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isCollapsed) => {
                this.isCollapsed = isCollapsed;
                detectChanges(this.cdr);
            });
    }

    ngOnInit() {
        this.logout$
            .pipe(
                switchMap(() => this.auth.logout$()),
                tap(() => this.navCtrl.navigateRoot('auth/login')),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    get isMobile(): boolean {
        return this.platformService.isMobile;
    }

    get isNativeiOS(): boolean {
        return this.platformService.isNativeiOS;
    }

    get userAvatar$(): Observable<string> {
        return this.userService.getUserAvatar$();
    }

    openPaypal() {
        this.externalLinkSevice.open(ExternalLinksEnum.paypal);
    }

    openTawk() {
        this.externalLinkSevice.open(ExternalLinksEnum.tawk);
    }

    openCreateMenu(entity: INode): void {
        if (entity) {
            this.entityCrudViewService.openCreateMenu(entity.payload, getEntityTypeFromNode(entity.type));
        } else {
            this.entityCrudViewService.openCreateMenu();
        }

        this.navChange.emit();
    }

    openPage(data: INode): void {
        let url: string;
        switch (data.type) {
            case NodeType.SCHOOL:
                url = `pages/school-dashboard/${data.payload.id}`;
                break;
            case NodeType.CLASS:
                url = `pages/class-dashboard/${data.payload.id}`;
                break;
            case NodeType.SUBJECT_GROUP:
                url = 'pages/subjects';
                break;
            case NodeType.SUBJECT_DASHBOARD:
                url = `pages/subject-dashboard/${data.payload.id}`;
                break;
            case NodeType.SUBJECT_CALENDAR:
                url = `pages/subject-calendar/${data.payload.id}`;
                break;
            case NodeType.LESSON_GROUP:
                url = 'pages/lessons';
                break;
            case NodeType.LESSON_CALENDAR:
                url = 'pages/lessons-calendar';
                break;
            case NodeType.LESSON:
                url = `pages/subject-dashboard/${data.payload.id}`;
                break;
            case NodeType.MATERIAL:
                url = `pages/material/${data.payload.id}`;
                break;
            case NodeType.MEETING:
                url = `pages/meetings`;
                break;
        }

        if (url) {
            this.zone.run(() => this.navCtlr.navigateRoot(url)).then(() => {
                of(true)
                    .pipe(delay(250))
                    .subscribe(() => {
                        this.navChange.emit();
                        if (this.canCloseSidemenu(data)) {
                            this.menuCtrl.close();
                        }
                    });
            });
        }

    }

    openNode(node: INode) {
        this.primaryNode = node;
    }

    closeMobileMenu(): void {
        this.primaryNode = undefined;
        this.reRenderTree$.next();
    }

    toggleEditing(): void {
        this.isChildEditingTrigger$.next();
    }

    get canEditNodeChild(): boolean {
        return this.primaryNode && this.primaryNode.type !== NodeType.LESSON_GROUP;
    }

    openInvite(data: INode) {
        if (data && data.payload) {
            this.showInviteModal(data.payload.id, getEntityTypeFromNode(data.type));
        }
    }

    openVideo(data: INode) {
        this.subjectService.joinVideo$.next(data['data'].id);
    }

    openHomePage() {
        this.navCtlr.navigateRoot('pages/home');
        this.closeMobileMenu();
        this.closeMenu();
        this.appMenuService.toggleCollapsed(false);
    }

    handleTreeMenuCollapse(isCollapsed: boolean) {
        this.isCollapsed = isCollapsed;
    }


    closeMenu(): void {
        this.menuCtrl.close();
    }

    async handleLogout() {
        const { isConfirmed } = await this.confirmationService.confirmAction(ConfirmAction.LOGOUT)
        if (isConfirmed) {
            this.logout$.next();
        }
    }

    private async showInviteModal(entityId: string, entityType: EntityType): Promise<void> {
        return this.inviteModalService.open(entityId, entityType);
    }

    private canCloseSidemenu(node: INode): boolean {
        if (this.isMobile) {
          return ![
            NodeType.SCHOOL_GROUP,
            NodeType.CLASS_GROUP,
            NodeType.SUBJECT_GROUP,
            NodeType.LESSON_GROUP,
            NodeType.MATERIAL_GROUP,
          ].includes(node.type);
        }
        return false;
    }
}
