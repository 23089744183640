import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class PagesGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private navCtrl: NavController,
    ) { }

    canActivate() {
        if (!this.authService.isAuthenticated) {
            this.navCtrl.navigateRoot('auth/login');
        }

        return this.authService.isAuthenticated;
    }
}
