import * as tslib_1 from "tslib";
import { PlatformService } from './platform.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { getFileExtension } from '../helpers/helpers';
import { ALLOWED_IMAGES_FORMATS, ALLOWED_VIDEOS_FORMATS } from '../models/common';
import * as i0 from "@angular/core";
import * as i1 from "./platform.service";
import * as i2 from "@ionic-native/file/ngx/index";
import * as i3 from "@ionic-native/file-opener/ngx/index";
export class DownloadLocallyService {
    constructor(platformService, file, fileOpener) {
        this.platformService = platformService;
        this.file = file;
        this.fileOpener = fileOpener;
        this.DIR_NAME = 'attachments';
    }
    download(name, arrayBuffer) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const mimeType = this.getMimeType(name);
            if (mimeType) {
                if (this.platformService.isNativeiOS || this.platformService.isNativeiPadOS) {
                    yield this.downloadLocallyiOS(arrayBuffer, mimeType, name);
                }
                else {
                    this.downloadLocallyWeb(arrayBuffer, mimeType, name);
                }
            }
        });
    }
    downloadLocallyiOS(arrayBuffer, mimeType, name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const dirEntry = yield this.getDirectory();
            const fileEntry = yield this.saveFile(dirEntry, arrayBuffer, mimeType, name);
            this.openFileAs(fileEntry, mimeType);
        });
    }
    downloadLocallyWeb(arrayBuffer, mimeType, name) {
        const blob = new Blob([arrayBuffer], { type: mimeType });
        const a = document.createElement('a');
        a.style.display = 'none';
        a.setAttribute('href', window.URL.createObjectURL(blob));
        a.setAttribute('download', name);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    getMimeType(name) {
        const extension = getFileExtension(name.toLowerCase()).toLowerCase();
        if (extension === 'pdf') {
            return 'application/pdf';
        }
        else if (extension === 'txt') {
            return 'text/plain';
        }
        else if (extension === 'docx') {
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        else if (extension === 'doc') {
            return 'application/msword';
        }
        else if (this.isSameFormat(ALLOWED_VIDEOS_FORMATS, extension)) {
            return `video/${extension}`;
        }
        else if (this.isSameFormat(ALLOWED_IMAGES_FORMATS, extension)) {
            return `image/${extension}`;
        }
    }
    getDirectory() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const mainDir = this.file.tempDirectory;
            const isDirExists = yield this.file.checkDir(mainDir, this.DIR_NAME)
                .catch(() => false);
            if (isDirExists) {
                yield this.file.removeRecursively(mainDir, this.DIR_NAME);
            }
            return this.file.createDir(mainDir, this.DIR_NAME, true);
        });
    }
    saveFile(dirEntry, arrayBuffer, mimeType, name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blob = new Blob([arrayBuffer], { type: mimeType });
            return this.file.writeFile(dirEntry.nativeURL, name, blob, { replace: true });
        });
    }
    openFileAs(fileEntry, mimeType) {
        return this.fileOpener.open(fileEntry.nativeURL, mimeType);
    }
    isSameFormat(extensions, ext) {
        return extensions.map(extension => extension.toLowerCase()).indexOf(ext.toLowerCase()) !== -1;
    }
}
DownloadLocallyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DownloadLocallyService_Factory() { return new DownloadLocallyService(i0.ɵɵinject(i1.PlatformService), i0.ɵɵinject(i2.File), i0.ɵɵinject(i3.FileOpener)); }, token: DownloadLocallyService, providedIn: "root" });
