import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { EnvService } from './env/env.service';
import { ErrorHandlerService } from './error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./env/env.service";
import * as i3 from "./error-handler.service";
export class HttpService {
    constructor(httpClient, envService, errHandlerService) {
        this.httpClient = httpClient;
        this.envService = envService;
        this.errHandlerService = errHandlerService;
        this.getEndpoint = (endpoint) => {
            return `${this.envService.apiConfig.apiEndpoint}/${endpoint}`;
        };
    }
    get$(endpoint, options = {}) {
        return this.httpClient.get(this.getEndpoint(endpoint), options)
            .pipe(map(response => ({ payload: response })), catchError(error => of(this.getError(error))));
    }
    post$(endpoint, data, options = {}) {
        return this.httpClient.post(this.getEndpoint(endpoint), data, options)
            .pipe(map(response => ({ payload: response })), catchError(error => of(this.getError(error))));
    }
    put$(endpoint, data, options = {}) {
        return this.httpClient.put(this.getEndpoint(endpoint), data, options)
            .pipe(map(response => ({ payload: response })), catchError(error => of(this.getError(error))));
    }
    patch$(endpoint, data, options = {}) {
        return this.httpClient.patch(this.getEndpoint(endpoint), data, options)
            .pipe(map(response => ({ payload: response })), catchError(error => of(this.getError(error))));
    }
    delete$(endpoint, options = {}) {
        return this.httpClient.delete(this.getEndpoint(endpoint), options)
            .pipe(map(response => ({ payload: response })), catchError(error => of(this.getError(error))));
    }
    getError(error) {
        return { error, message: this.errHandlerService.getUserMessage(error) };
    }
}
HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvService), i0.ɵɵinject(i3.ErrorHandlerService)); }, token: HttpService, providedIn: "root" });
