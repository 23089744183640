import { EntityType, IBaseTicketList, EventType, IDashboard, TicketParent, ExtraRequests } from '../../models/common';
import { v4 as uuidv4 } from 'uuid';
import { IonInfiniteScroll } from '@ionic/angular';
import {TicketType} from '../../models/ticket';

const cloneDeep = require('lodash.clonedeep');

export interface IColumnConfig {
    // Determines if new item can be created in column
    canAdd?: boolean;
    // Determines if column can be edited or removed
    canEdit?: boolean;
    // Determines column opacity
    opacity?: number;
    entityType?: EntityType;
}

export interface IBoardConfig {
    // Determines if new column can be created in board
    canAdd: boolean;
    notAllowTicketType?: TicketType[];
}

export class ColumnItem<T> {
    id: string;
    uuid: string;
    columnId: string;
    payload: T;

    constructor(payload: T, columnIdExtractor: (payload: T) => string, itemIdExtractor: (payload: T) => string) {
        this.uuid = uuidv4();
        this.id = itemIdExtractor(payload);
        this.columnId = columnIdExtractor(payload);
        this.payload = cloneDeep(payload);
    }
}

export class Column<T> {
    public canAdd: boolean = false;
    public canEdit: boolean = false;
    public opacity: number = 1;
    public entityType?: EntityType;

    constructor(
        public id: string,
        public title: string,
        public items: ColumnItem<T>[],
        public config: IColumnConfig,
    ) {
        if (config.canAdd) {
            this.canAdd = config.canAdd;
        }
        if (config.canEdit) {
            this.canEdit = config.canEdit;
        }
        if (config.opacity) {
            this.opacity = config.opacity;
        }
        if (config.entityType) {
            this.entityType = config.entityType;
        }
    }
}

export class Board<T> {
    public canAdd: boolean = false;
    constructor(
        public columns: Column<T>[],
        public config: IBoardConfig,
    ) {
        if (config.canAdd) {
            this.canAdd = config.canAdd;
        }
    }
}

export interface INewColumnItem {
    columnKey: string;
    entityType: EntityType;
    shouldAdd: boolean;
}

export interface IBoardData<T> {
    config: IDashboard;
    ticketsList: IBaseTicketList<T>;
}

export interface IInfoBlock {
    icon: string;
    message: string;
}

export interface ILoadMoreItems {
    infiniteScroll: IonInfiniteScroll;
    columnId: string;
    direction: 'top' | 'bottom';
    date?: string;
    responseHandler(data: ILoadMoreItems, hasMoreItems: boolean): void;
}

export interface ILoadOneMoreItem {
    columnId: string;
    skip: number;
}

export interface ILoadItemsNearDate {
    columnId: string;
    date: string;
    showColumnRefreshAnimation: boolean;
    responseHandler(data: ILoadItemsNearDate, isSuccess: boolean): void;
}

export interface IItemsWithTimelineDates<T> {
    items: T[];
    timelineDates: { [columnId: string]: string[] };
}

export enum KanbanEventType {
    OPEN = 'open',
    ADD_NEW_TICKET = 'add_new_ticket',
    REMOVE_NEW_TICKET = 'remove_new_ticket',
}

export interface IKanbanEvent {
    eventType: EventType | KanbanEventType;
    entityType: EntityType;
    entity: any;
    columnKey: string;
    parent?: { type?: TicketParent; id?: string };
    patchValue?: any;
    extraRequests?: ExtraRequests[];
}

export interface ColumnRuler {
    height: any;
    items: Array<{ start: number, label: string }>
}

export function groupByColumn<T>(items: ColumnItem<T>[]): { [columnId: string]: ColumnItem<T>[] } {
    const result = {};
    items
      .forEach(item => {
        if (!result[item.columnId]) {
          result[item.columnId] = [];
        }
        result[item.columnId].push(cloneDeep(item));
      });
    return result;
}

export enum KanbanStateEventType {
    SET_ITEMS = 'SET_ITEMS',
    SET_ITEMS_IN_COLUMN = 'SET_ITEMS_IN_COLUMN',
    SET_ITEMS_IN_COLUMNS = 'SET_ITEMS_IN_COLUMNS',
    ADD_ITEMS = 'ADD_ITEMS',
    ADD_ITEM = 'ADD_ITEM',
    UPDATE_ITEM = 'UPDATE_ITEM',
    REMOVE_ITEM = 'REMOVE_ITEM',
    CHANGE_ITEM_COLUMN = 'CHANGE_ITEM_COLUMN',
    TOGGLE_NEW_ITEM = 'TOGGLE_NEW_ITEM',
}

export interface IKanbanStateEvent {
    type: KanbanStateEventType;
}

export class KanbanStateEventSetItems<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.SET_ITEMS;

    constructor(
        public items: T[],
    ) {}
}

export class KanbanStateEventSetItemsInColumn<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.SET_ITEMS_IN_COLUMN;

    constructor(
        public columnId: string,
        public items: T[],
        public data: ILoadItemsNearDate = null,
    ) {}
}

export class KanbanStateEventSetItemsInColumns<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.SET_ITEMS_IN_COLUMNS;

    constructor(
        public data: { [columnId: string]: T[] },
    ) {}
}

export class KanbanStateEventAddItems<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.ADD_ITEMS;

    constructor(
        public data: ILoadMoreItems,
        public items: T[],
        public direction: 'top' | 'bottom',
    ) {}
}

export class KanbanStateEventAddItem<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.ADD_ITEM;

    constructor(
        public item: T,
    ) {}
}

export class KanbanStateEventUpdateItem<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.UPDATE_ITEM;

    constructor(
        public item: T,
    ) {}
}

export class KanbanStateEventChangeItemColumn<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.CHANGE_ITEM_COLUMN;

    constructor(
        public item: T,
        public prevColumnId: string,
    ) {}
}

export class KanbanStateEventRemoveItem<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.REMOVE_ITEM;

    constructor(
        public itemId: string,
        public columnId?: string,
    ) {}
}

export class KanbanStateEventToggleNewItem<T> implements IKanbanStateEvent {
    type: KanbanStateEventType = KanbanStateEventType.TOGGLE_NEW_ITEM;

    constructor(
        public item: INewColumnItem,
        public getNewTicketItem: (entityType: EntityType) => any,
    ) {}
}
