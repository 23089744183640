import { Injectable, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { Subject } from 'rxjs';
import { IPopoverNotification, PopoverNotificationType } from '../components/popover-notification/types';
import { PopoverNotificationComponent } from '../components/popover-notification/popover-notification.component';
import { tap, delay, filter } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IMeeting } from '../models/meeting';

@Injectable({
  providedIn: 'root'
})
export class PopoverNotificationService {

  private addNotificationEmitter$ = new Subject<IPopoverNotification<any>>();
  private removeNotificationEmitter$ = new Subject<string>();
  private addNotification$ = new Subject<IPopoverNotification<any>>();
  private isPopoverCreated: boolean = false;
  private popoverViewContainer: ViewContainerRef;
  private popoverComponent: PopoverNotificationComponent;

  constructor(
    private resolver: ComponentFactoryResolver,
    private authService: AuthService,
  ) {
    this.listenAuthState();
    this.listenAddNotification();
  }

  setViewContainer(container: ViewContainerRef): void {
    this.popoverViewContainer = container;
  }

  addNotification(notification: IPopoverNotification<any>): void {
    this.addNotificationEmitter$.next(notification);
  }

  removeNotification(notificationId: string): void {
    this.removeNotificationEmitter$.next(notificationId);
  }

  removeMeetingAttendeeNotification(meetingId: string): void {
    if (this.popoverComponent) {
      const notification: IPopoverNotification<IMeeting> = this.popoverComponent.notifications.find(n => n.payload.id === meetingId);
      if (notification) {
        this.removeNotification(notification.uuid);
      }
    }
  }

  getPopoverNotification<T>(type: PopoverNotificationType, payload: T): IPopoverNotification<T> {
    return {
      uuid: uuidv4(),
      type,
      payload,
    };
  }

  private async displayPopover(): Promise<void> {
    if (!this.isPopoverCreated) {
      this.isPopoverCreated = true;
      this.popoverViewContainer.clear();
      const factory: ComponentFactory<PopoverNotificationComponent> = this.resolver.resolveComponentFactory(PopoverNotificationComponent);
      const componentRef: ComponentRef<PopoverNotificationComponent> = this.popoverViewContainer.createComponent(factory);
      componentRef.instance.addNotification$ = this.addNotification$.asObservable();
      componentRef.instance.removeNotification$ = this.removeNotificationEmitter$.asObservable();
      componentRef.instance.onDismiss = () => {
        componentRef.destroy();
        this.isPopoverCreated = false;
      };
      this.popoverComponent = componentRef.instance;
    }
  }

  private listenAddNotification(): void {
    this.addNotificationEmitter$
      .pipe(
        filter(() => this.authService.isAuthenticated),
        tap(() => this.displayPopover()),
        delay(100),
        tap(notification => this.addNotification$.next(notification)),
      )
      .subscribe();
  }

  private listenAuthState(): void {
    this.authService.isAuthenticated$
      .pipe(
        tap(isAuthenticated => {
          if (!isAuthenticated) {
            this.isPopoverCreated = false;
          }
        }),
        filter(isAuthenticated => !isAuthenticated && !!this.popoverViewContainer),
      )
      .subscribe(() => this.popoverViewContainer.clear());
  }

}
