/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "./checkbox.component";
var styles_CheckboxComponent = [i0.styles];
var RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
export function View_CheckboxComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { ionCheckboxEl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "ion-item", [["button", ""], ["class", "checkbox"], ["lines", "none"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.onIonChange(!i1.ɵnov(_v, 6).checked);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(2, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { button: [0, "button"], detail: [1, "detail"], disabled: [2, "disabled"], lines: [3, "lines"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "div", [["class", "checkbox-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0]], null, 3, "ion-checkbox", [], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7)._handleIonChange($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.onIonChange($event.detail.checked) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonCheckbox_0, i2.RenderType_IonCheckbox)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.BooleanValueAccessor]), i1.ɵdid(6, 49152, [["checkbox", 4]], 0, i3.IonCheckbox, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { checked: [0, "checked"] }, null), i1.ɵdid(7, 4341760, null, 0, i3.BooleanValueAccessor, [i1.Injector, i1.ElementRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/img/checkbox-mark.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(10, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = false; var currVal_2 = _co.isDisabled; var currVal_3 = "none"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.isChecked; _ck(_v, 6, 0, currVal_4); }, null); }
export function View_CheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CheckboxComponent]), i1.ɵdid(2, 4964352, null, 0, i5.CheckboxComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CheckboxComponentNgFactory = i1.ɵccf("app-checkbox", i5.CheckboxComponent, View_CheckboxComponent_Host_0, { label: "label", isDisabled: "isDisabled", color: "color", isChecked: "isChecked" }, { change: "change" }, ["*"]);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
