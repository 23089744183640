import { EntityType, EventType } from './common';
import { TicketType } from './ticket';
import { IMeetingFrequency } from './meeting';

export enum NotificationType {
    TICKET_TASK_PUBLISHED = 'TICKET_TASK_PUBLISHED',
    TICKET_ANNOUNCE_PUBLISHED = 'TICKET_ANNOUNCE_PUBLISHED',
    TICKET_TASK_CHANGED = 'TICKET_TASK_CHANGED',
    TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED = 'TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED',
    TICKET_TASK_STATE_CHANGED = 'TICKET_TASK_STATE_CHANGED',
    STUDENT_COMPLETE_TASK = 'STUDENT_COMPLETE_TASK',
    STUDENT_UPDATE_TASK = 'STUDENT_UPDATE_TASK',
    MEETING_ATTENDEE = 'MEETING_ATTENDEE',
    MEETING_ATTENDEE_CHANGED = 'MEETING_ATTENDEE_CHANGED',
    MEETING_ATTENDEE_START_SOON = 'MEETING_ATTENDEE_START_SOON',
    MEETING_ATTENDEE_START_1_HOUR = 'MEETING_ATTENDEE_START_1_HOUR',
    MEETING_ATTENDEE_START_5_MIN = 'MEETING_ATTENDEE_START_5_MIN',
    MEETING_ATTENDEE_START_10_MIN = 'MEETING_ATTENDEE_START_10_MIN',
    ASSIGNED_TASK_STATE_CHANGE = 'ASSIGNED_TASK_STATE_CHANGE',
    ASSIGNED_TASK_MESSAGE = 'ASSIGNED_TASK_MESSAGE',
    ENTITY_INVITE = 'ENTITY_INVITE',
    TICKET_TASK_DUE_DATE = 'TICKET_TASK_DUE_DATE',
    MEETING_ATTENDEE_STATUS_UPDATES = 'MEETING_ATTENDEE_STATUS_UPDATES',
    ADD_TICKET_TASK = 'ADD_TICKET_TASK',
    TICKET_TASK_DUE_DATE_CHANGED = 'TICKET_TASK_DUE_DATE_CHANGED',
}

export enum MessageTypes {
    FILE = 'FILE',
    TEXT = 'TEXT',
    LINK = 'LINK',
    MATERIAL = 'MATERIAL'
}

export interface IBaseNotification {
    id: string;
    message: string;
    is_deleted: boolean;
    message_type?: MessageTypes;
}

export interface INotificationMessage<T extends IBaseNotification> {
    id: string;
    type: NotificationType;
    is_read: boolean;
    added_on: string;
    payload: T;
}

export interface IMeetingNotification extends IBaseNotification {
    meeting_serial_id: string;
    meeting_type: string;
    meeting_status: string;
    meeting_owner_fullname: string;
    meeting_start_date: string;
    meeting_end_date: string;
    subject_name: string;
    subject_color?: string;
    meeting_frequency_dates: IMeetingFrequency[];
}

export interface IMeetingUserStatusUpdate extends IMeetingNotification {
    meeting_user_fullname: string;
}

export interface IAssignedTaskNotification extends IBaseNotification {
    mark: string;
    my_mark: string;
    state: string;
    subject_name: string;
    subject_color?: string;
    is_deleted: boolean;
}

export interface ITicketTaskNotification extends IBaseNotification {
    id: string;
    subject_name: string;
    subject_color?: string;
    assigned_task_id: string;
    is_deleted: boolean;
}

export interface ITicketTaskDueDateChanged extends ITicketTaskNotification {
    due_date: string;
}

export interface ITicketBaseNotification extends IBaseNotification {
    entity_type: string;
    parent_name: string;
    parent_type: string;
    is_deleted: boolean;
}

export interface ITicketAnnounceNotification extends IBaseNotification {
    ticket_type: TicketType;
    parent_name: string;
    parent_type: EntityType;
}

export interface ITicketStateNotification extends IBaseNotification {
    state: string;
    subject_name: string;
    subject_color?: string;
    is_deleted: boolean;
}

export interface IInviteNotification extends IBaseNotification {
    entity_type: EntityType;
}

export interface IMeetingChangedNotification extends IMeetingNotification {
    event_type: EventType;
}

export interface IStudentTaskNotification extends IBaseNotification {
    subject_name: string;
    subject_color?: string;
    student_full_name: string;
    is_deleted: boolean;
}

export interface IAssignedTaskMessageNotification extends IBaseNotification {
    subject_name: string;
    subject_color?: string;
}
