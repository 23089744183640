import { BaseStoreService } from './base-store.class';
import { ToastService } from '../toast.service';
import { EventType } from '../../models/common';
import { map, filter } from 'rxjs/operators';
import { getBaseStoreEvent } from './helpers';
import { MaterialService } from '../material.service';
import { InviteService } from '../invite.service';
import { isSuccess } from '../../helpers/helpers';
import * as i0 from "@angular/core";
import * as i1 from "../material.service";
import * as i2 from "../toast.service";
import * as i3 from "../invite.service";
export const INIT_STATE = { all: [] };
export class MaterialStoreService extends BaseStoreService {
    constructor(materialService, toastService, inviteService) {
        super(INIT_STATE, toastService, inviteService);
        this.materialService = materialService;
        this.toastService = toastService;
        this.inviteService = inviteService;
        this.keyExtractor = (material) => {
            return 'all';
        };
    }
    getItems$() {
        return this.materialService.getAll$();
    }
    getItem$(id) {
        return this.materialService.getOne$(id);
    }
    getUpdates$() {
        return this.materialService.notifier$
            .pipe(filter(event => isSuccess(event.response)), filter(event => [EventType.ADD, EventType.EDIT, EventType.REMOVE].includes(event.eventType)), map(event => ({
            type: getBaseStoreEvent(event.eventType),
            payload: event.response.payload ? event.response.payload : event.entityId
        })));
    }
}
MaterialStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MaterialStoreService_Factory() { return new MaterialStoreService(i0.ɵɵinject(i1.MaterialService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.InviteService)); }, token: MaterialStoreService, providedIn: "root" });
