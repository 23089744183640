import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AuthPagesGuard implements CanActivate {

    constructor(private authService: AuthService, private navCtrl: NavController) { }

    canActivate() {
        const isLoggedIn = this.authService.isAuthenticated;
        if (isLoggedIn) {
            this.navCtrl.navigateRoot('pages/home');
        }
        return !isLoggedIn;
    }
}
