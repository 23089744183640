import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  HostBinding,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {filter, first, takeUntil} from 'rxjs/operators';
import { IStreamId } from 'src/app/shared/models/chat';
import * as ChatActions from 'src/app/store/actions/chat'
import { selectChatStreamLoaded } from 'src/app/store/selectors/chat.selectors';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { ChatUiService } from 'src/app/shared/services/chat-ui.service';
import { ZulipService } from 'src/app/shared/services/zulip.service';
import {detectChanges} from '../../shared/helpers/helpers';

export enum ChatWidgetPages {
  Nav = 'nav',
  Log = 'log',
  Create = 'create',
  Details = 'details',
  Disconnected = 'disconnected'
}

export interface ChatWidgetInputParams {
  activePage: ChatWidgetPages;
  selectedChannelId: IStreamId;
}

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.page.html',
  styleUrls: ['./chat-widget.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWidgetPage implements OnInit, OnDestroy {
  @Input() activePage: ChatWidgetPages;
  @Input() selectedChannelId: IStreamId;
  @Input() doDismiss: Function;

  private activePage$ = new Subject<ChatWidgetPages>();

  public pages = ChatWidgetPages;

  private destroy$ = new Subject();

  @HostBinding('class.mobile-view') get t() {
    return this.platformService.isMobile;
  }

  constructor(
    private store: Store,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private platformService: PlatformService,
    private chatUiService: ChatUiService,
    private zulipService: ZulipService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.chatUiService.closeChatWithUser();
  }

  ngOnInit() {
    this.zulipService.zulipIsOnline$.pipe(takeUntil(this.destroy$)).subscribe(isOnline => {
      if (isOnline) {
        const tab = this.activePage === ChatWidgetPages.Disconnected ? ChatWidgetPages.Nav : this.activePage || ChatWidgetPages.Nav;
        this.changePage(tab);
      } else {
        this.changePage(ChatWidgetPages.Disconnected);
      }
      detectChanges(this.cdr);
    });

    this.chatUiService.openChatStream$()
        .pipe(takeUntil(this.destroy$))
        .subscribe((streamId) => this.onChannelSelect(streamId));
  }

  onChannelSelect(streamId: IStreamId) {
    this.store
      .select(selectChatStreamLoaded, streamId)
      .pipe(
        first(),
        filter(isLoaded => !isLoaded),
      )
      .subscribe(() => {
        this.store.dispatch(ChatActions.loadSingleChatStream({ streamId }));
      });

    this.selectedChannelId = streamId;
    this.changePage(ChatWidgetPages.Log);
  }

  handleSearch() {
    this.changePage(ChatWidgetPages.Create);
  }

  handleDismiss() {
    this.doDismiss instanceof Function && this.doDismiss();
  }

  handleExpand() {
    if (this.platformService.isDesktop) {
      const id = this.selectedChannelId != null ? this.selectedChannelId : '';
      const url = this.router.createUrlTree(['/pages/chat', id]);
      window.open(url.toString(), '_blank');
    }

    if (this.doDismiss instanceof Function) {
      this.doDismiss();
    }
  }

  handleShowDetails(streamId: IStreamId) {
    this.selectedChannelId = streamId;
    this.changePage(ChatWidgetPages.Details);
  }

  handleOpenConference(streamId: IStreamId) {
    this.chatUiService.openConference(streamId);
  }

  changePage(page: ChatWidgetPages) {
    this.activePage = page;
    this.activePage$.next(this.activePage);
  }

}
