import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, isDevMode } from '@angular/core';
import { WhiteboardService } from '../../services/whiteboard.service';
import { fromEvent, Subject, EMPTY, of, throwError, BehaviorSubject } from 'rxjs';
import { takeUntil, filter, delay, first, switchMap, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { DownloadLocallyService } from '../../services/download-locally.service';
import { getFileExtension } from '../../helpers/helpers';
import { SocketMessageType, TicketParent } from '../../models/common';
import { BREAKOUT_ROOM_STORE_KEY, WhiteboardFrameMessageType } from './models';
import { FileAttachService } from '../../services/file-attach.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { SocketService } from '../../services/socket.service';
import { Storage } from '@ionic/storage';
import { ChatToggleService } from '../../services/chat-toggle.service';
import { Store } from '@ngrx/store';
import { selectAllUnreads } from 'src/app/store/selectors/chat.selectors';
export class WhiteboardComponent {
    constructor(authService, whiteboard, toastService, translateService, downloadLocallyService, fileAttachService, platformService, socketService, storage, chatToggleService, store) {
        this.authService = authService;
        this.whiteboard = whiteboard;
        this.toastService = toastService;
        this.translateService = translateService;
        this.downloadLocallyService = downloadLocallyService;
        this.fileAttachService = fileAttachService;
        this.platformService = platformService;
        this.socketService = socketService;
        this.storage = storage;
        this.chatToggleService = chatToggleService;
        this.store = store;
        this.showLoading$ = new BehaviorSubject(true);
        this.whiteboardDataExist = null;
        this.destroy$ = new Subject();
    }
    ngOnInit() {
        const messages$ = fromEvent(window, 'message')
            .pipe(filter((message) => message.data && message.data.type && this.hasCorrectType(message.data.type)));
        messages$
            .pipe(map(msg => ({ message: msg.data, source: msg.source })), takeUntil(this.destroy$))
            .subscribe(({ message, source }) => {
            this.handleWhiteboardMessage(message, source);
        });
        messages$
            .pipe(filter(message => message.data.type === WhiteboardFrameMessageType.UpdateGgbState), debounceTime(1000), takeUntil(this.destroy$))
            .subscribe(message => this.handleUpdateGgbState(message.data));
        if (this.socketService.isConnected) {
            const whiteboardMessageTypes = [
                SocketMessageType.CONFERENCE_BREAKOUT_ROOM_JOIN,
                SocketMessageType.CONFERENCE_BREAKOUT_ROOM_EXIT,
                SocketMessageType.CONFERENCE_BREAKOUT_ROOM_REQUEST_RETURN
            ];
            this.socketService.messages$()
                .pipe(takeUntil(this.destroy$), filter(message => whiteboardMessageTypes.includes(message.type)))
                .subscribe(message => {
                this.iframe.contentWindow && this.iframe.contentWindow.postMessage(message, '*');
            });
        }
        this.store.select(selectAllUnreads).pipe(distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe(count => this.sendUpdateChatNotifications(count));
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    onLoad(iframe) {
        // if (iframe.contentDocument == null && !this.platformService.isNativeiOS && !this.platformService.isNativeiPadOS) {
        //   this.handleWhiteboardLoadingError();
        //   return;
        // }
        if (!this.iframe) {
            this.iframe = iframe;
        }
    }
    handleWhiteboardMessage(message, source) {
        switch (message.type) {
            case WhiteboardFrameMessageType.WhiteboardToken:
                return this.handleWhiteboardGetToken(message, source);
            case WhiteboardFrameMessageType.WhiteboardClose:
                return this.handleClose(message);
            case WhiteboardFrameMessageType.WhiteboardLoaded:
                return this.handleWhiteboardLoaded();
            case WhiteboardFrameMessageType.DownloadFile:
                return this.handleDownloadFile();
            case WhiteboardFrameMessageType.UploadDoc:
            case WhiteboardFrameMessageType.UploadPdf:
            case WhiteboardFrameMessageType.UploadImage:
                return this.handleWhiteboardUploadMessage(message);
            case WhiteboardFrameMessageType.WhiteboardLoadingError: {
                return this.handleWhiteboardLoadingError();
            }
            case WhiteboardFrameMessageType.BreakoutRoomJoin:
                return this.handleBreakoutRoomJoin(message);
            case WhiteboardFrameMessageType.BreakoutRoomExit:
                return this.handleBreakoutRoomExit(message);
            case WhiteboardFrameMessageType.BreakoutRoomRequestReturn:
                return this.handleBreakoutRoomRequestReturn(message);
            case WhiteboardFrameMessageType.BreakoutRoomRestoreState:
                return this.handleBreakoutRoomRestoreState();
            case WhiteboardFrameMessageType.BreakoutRoomPersistState:
                return this.handleBreakoutRoomPersistState(message);
            case WhiteboardFrameMessageType.ToggleChat:
                return this.chatToggleService.openChat$();
            case WhiteboardFrameMessageType.GetChatNotifications:
                return this.handleGetChatNotifications();
            case WhiteboardFrameMessageType.InitGgbState:
                return this.handleInitGgbState(message);
            case WhiteboardFrameMessageType.UpdateGgbState:
                return;
            default: {
                throw new Error(`Unsupported message type "${message.type}"`);
            }
        }
    }
    handleGetChatNotifications() {
        this.store.select(selectAllUnreads)
            .pipe(first())
            .subscribe(count => this.sendUpdateChatNotifications(count));
    }
    sendUpdateChatNotifications(count) {
        this.iframe && this.iframe.contentWindow && this.iframe.contentWindow.postMessage({
            type: WhiteboardFrameMessageType.UpdateChatNotifications,
            payload: { count }
        }, '*');
    }
    handleClose({ payload }) {
        if (payload != null && payload.lastBreakoutState != null) {
            this.storage.set(BREAKOUT_ROOM_STORE_KEY, payload.lastBreakoutState);
        }
        this.onDismiss();
    }
    handleBreakoutRoomRestoreState() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const payload = yield this.storage.get(BREAKOUT_ROOM_STORE_KEY);
            this.iframe.contentWindow.postMessage({
                type: WhiteboardFrameMessageType.BreakoutRoomRestoreStateResponse,
                payload,
            }, '*');
        });
    }
    handleBreakoutRoomPersistState({ payload }) {
        this.storage.set(BREAKOUT_ROOM_STORE_KEY, payload);
    }
    handleBreakoutRoomJoin({ payload }) {
        this.whiteboard.sendBreakoutRoomJoin(payload)
            .pipe(first())
            .subscribe(res => {
            if (res.error) {
                this.iframe.contentWindow.postMessage({
                    type: WhiteboardFrameMessageType.BreakoutLifecycleMessageError,
                }, '*');
            }
        });
    }
    handleBreakoutRoomExit({ payload }) {
        this.whiteboard.sendBreakoutRoomExit(payload)
            .pipe(first())
            .subscribe(res => {
            if (res.error) {
                this.iframe.contentWindow.postMessage({
                    type: WhiteboardFrameMessageType.BreakoutLifecycleMessageError,
                }, '*');
            }
        });
    }
    handleBreakoutRoomRequestReturn({ payload }) {
        this.whiteboard.sendBreakoutRoomRequestReturn(payload)
            .pipe(first())
            .subscribe(res => {
            if (res.error) {
                this.iframe.contentWindow.postMessage({
                    type: WhiteboardFrameMessageType.BreakoutLifecycleMessageError,
                }, '*');
            }
        });
    }
    handleWhiteboardLoadingError() {
        const err = new Error();
        err['code'] = 'whiteboard_init_error';
        this.onDismiss(err);
    }
    checkIfWindowType(type) {
        if (type.postMessage) {
            return true;
        }
        return false;
    }
    handleWhiteboardGetToken(message, source) {
        //we use this for cases when this.iframe is still undefined at the moment of receiving this message
        if (source && this.checkIfWindowType(source)) {
            source.postMessage({
                type: 'jwt_token',
                payload: this.authService.jwtToken
            }, '*');
        }
    }
    handleWhiteboardUploadMessage(message) {
        if (this.ticketParent === undefined || this.parentTicketIdForMaterialTicket === undefined || this.saveToChat === undefined) {
            return;
        }
        this.prepareWhiteboardUploadRequest(message.type, message.payload)
            .pipe(first(), delay(1000), switchMap(response => {
            if (response != null) {
                return of({ response, message });
            }
            const errMessage = message.payload.shouldDownload
                ? 'whiteboard.download-error'
                : 'whiteboard.upload-error';
            const err = new Error(this.translateService.instant(errMessage));
            return throwError(err);
        }))
            .subscribe(response => this.handleWhiteboardUploadResponse({ response, message }), (error) => this.toastService.showToast(error.message, TOAST_TYPE.ERROR));
    }
    prepareWhiteboardUploadRequest(messageType, payload) {
        if (this.ticketParent === undefined || this.parentTicketIdForMaterialTicket === undefined || this.saveToChat === undefined) {
            throw new Error('Whiteboard ticket agruments were not supplied');
        }
        const uploadData = {
            payload: null,
            attachmentId: payload.attachmentId,
            assignedTaskId: payload.assignedTaskId,
            shouldDownload: payload.shouldDownload,
            isSameDestination: payload.isSameDestination,
            ticketParent: this.ticketParent,
            parentTicketIdForMaterialTicket: this.parentTicketIdForMaterialTicket,
            saveToChat: this.saveToChat,
        };
        switch (messageType) {
            case 'upload_pdf': {
                return this.whiteboard.uploadPdf$(Object.assign({}, uploadData, { payload: payload.file }));
            }
            case 'upload_image': {
                return this.whiteboard.uploadImage$(Object.assign({}, uploadData, { payload: payload.filename }));
            }
            case 'upload_doc': {
                return this.whiteboard.uploadDoc$(Object.assign({}, uploadData, { payload: payload.file }));
            }
            default: return EMPTY;
        }
    }
    handleWhiteboardUploadResponse(res) {
        const response = res.response;
        const type = res.message.type;
        const payload = res.message.payload;
        if (response.error) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
        }
        else {
            if (!payload.shouldDownload) {
                this.showFileSavedSuccessMessage();
            }
            else {
                const filename = this.getFilename(payload.filename);
                this.downloadFileLocally(response.response.payload, filename);
            }
        }
        this.iframe.contentWindow.postMessage({
            type: type + '_response',
            payload: !res.response.error,
        }, '*');
    }
    getFilename(filename) {
        const extension = getFileExtension(filename.toLowerCase());
        return extension === 'doc' || extension === 'docx' ? `${filename}.pdf` : filename;
    }
    hasCorrectType(type) {
        return Object.values(WhiteboardFrameMessageType).includes(type);
    }
    showFileSavedSuccessMessage() {
        const message = this.translateService.instant('whiteboard.file-saved');
        this.toastService.showToast(message, TOAST_TYPE.SUCCESS);
    }
    handleDownloadFile() {
        if (this.attachment === undefined || this.ticketParent === undefined) {
            return;
        }
        this.fileAttachService
            .downloadFile$(this.attachment, this.ticketParent)
            .pipe(first())
            .toPromise()
            .then(rawData => {
            this.downloadFileLocally(rawData, this.attachment.name);
            this.iframe.contentWindow.postMessage({
                type: 'download_response',
                payload: true,
            }, '*');
        })
            .catch(err => {
            if (isDevMode()) {
                console.error(err);
            }
            this.iframe.contentWindow.postMessage({
                type: 'download_response',
                payload: false,
            }, '*');
            const message = this.translateService.instant('whiteboard.download-error');
            this.toastService.showToast(message, TOAST_TYPE.ERROR);
        });
    }
    downloadFileLocally(arrayBuffer, fileName) {
        this.downloadLocallyService.download(fileName, arrayBuffer);
    }
    handleInitGgbState({ payload }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.whiteboard
                    .getOneById$(payload.id)
                    .pipe(first())
                    .toPromise();
                this.whiteboardDataExist = res && res.payload && res.payload.data;
                if (this.whiteboardDataExist) {
                    this.iframe.contentWindow.postMessage({
                        type: WhiteboardFrameMessageType.HydrateGgbState,
                        payload: {
                            data: res.payload.data
                        }
                    }, '*');
                }
            }
            catch (error) {
                if (isDevMode()) {
                    console.error(error);
                }
            }
        });
    }
    handleUpdateGgbState({ payload }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.whiteboardDataExist) {
                    yield this.whiteboard
                        .create$(payload)
                        .pipe(first())
                        .toPromise();
                    this.whiteboardDataExist = !this.whiteboardDataExist;
                    return;
                }
                yield this.whiteboard
                    .update$(payload)
                    .pipe(first())
                    .toPromise();
            }
            catch (error) {
                if (isDevMode()) {
                    console.error(error);
                    const message = this.translateService.instant('whiteboard.upload-error');
                    this.toastService.showToast(message, TOAST_TYPE.ERROR);
                }
            }
        });
    }
    handleWhiteboardLoaded() {
        this.showLoading$.next(false);
    }
}
