<ion-header class="menu-header ion-no-border" [class.menu-header-collapsed]="isCollapsed" [class.mobile-header]="isMobile">
	<ion-toolbar class="menu-toolbar has-avatar">
		<ion-avatar slot="start" (click)="openHomePage()">
			<img [src]="userAvatar$ | async">
		</ion-avatar>
		<ion-label *ngIf="!isCollapsed">
			<p (click)="openHomePage()" class="user-name font-extra-bold">{{ userName$ | async }}</p>
			<p *ngIf="!isMobile" class="logout font-light" (click)="handleLogout()">{{ 'auth.logout' | translate }}</p>
		</ion-label>
		<ion-buttons class="close-menu-btn" [class.mobile]="isMobile" slot="end">
			<ion-button fill="clear" mode="md" (click)="closeMenu()">
				<img slot="icon-only" src="/assets/img/close-btn.svg" alt="" />
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
	<ion-toolbar *ngIf="primaryNode" class="menu-toolbar sub-toolbar" >
		<ion-label (click)="closeMobileMenu()">
			<ion-icon class="back-icon" name="chevron-back-outline"></ion-icon> <span class="font-light">{{ primaryNode.name | translate }}</span>
		</ion-label>

		<ion-button *ngIf="canEditNodeChild" (click)="toggleEditing()" slot="end" fill="clear" class="edit-button">
			<ion-icon slot="icon-only" class="ico icon-edit"></ion-icon>
		</ion-button>
	</ion-toolbar>
</ion-header>
<ion-content class="menu-content" [class.ion-content-collapsed]="isCollapsed" styledScrollbar="shadow-root">
	<ion-list lines="none" styledScrollbarContent>
		<app-instant-meet-copy-widget [collapsed]="isCollapsed"></app-instant-meet-copy-widget>
		<app-join-input *ngIf="!primaryNode" [collapsed]="isCollapsed"></app-join-input>
		<app-tree-menu
				[isChildEditing]="isChildEditingTrigger$"
				[isPrimaryNode]="!!primaryNode"
				[reRenderTree]="reRenderTree$"
				(openCreate)="openCreateMenu($event)"
				(openPage)="openPage($event)"
				(openVideo)="openVideo($event)"
				(openNode)="openNode($event)"
				(openInvite)="openInvite($event)">
		</app-tree-menu>
	</ion-list>
</ion-content>
<ion-footer class="ion-no-border" [class.mobile-footer]="isMobile" [class.mobile-footer-small]="isNativeiOS">
	<ion-toolbar>
		<ion-button fill="clear"
					*ngIf="!isMobile"
					mode="md" expand="block"
					class="footer-btn feedback-btn"
					(click)="openTawk()">
			{{ 'common.help' | translate }}
		</ion-button>
		<ion-button *ngIf="!isNativeiOS"
					mode="md"
					expand="block"
					fill="clear"
					class="button-small donate font-extra-bold"
					(click)="openPaypal()">
			{{ 'common.donate' | translate }}
		</ion-button>
		<ion-button fill="clear"
					*ngIf="isMobile"
					mode="md" expand="block"
					[class.mobile-button]="isMobile"
					class="footer-btn logout-btn"
					(click)="handleLogout()">
			{{ 'auth.logout' | translate }}
		</ion-button>
	</ion-toolbar>
</ion-footer>
