import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatConversationItemComponent } from './chat-conversation-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { IsChatUserOnlinePipeModule } from '../../pipes/is-chat-user-online/is-chat-user-online.module';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, TranslateModule, IsChatUserOnlinePipeModule ],
  declarations: [ChatConversationItemComponent],
  exports: [ChatConversationItemComponent]
})
export class ChatConversationItemComponentModule {}
