<ng-container [ngSwitch]="activePage">
  <app-chat-nav-page
    *ngSwitchCase="pages.Nav"
    (select)="onChannelSelect($event)"
    (search)="handleSearch()"
    (expand)="handleExpand()"
    (dismiss)="handleDismiss()"
    (showDetails)="handleShowDetails($event)"
    (openConference)="handleOpenConference($event)">
  </app-chat-nav-page>
  <app-chat-log-page
    *ngSwitchCase="pages.Log"
    [selectedChannelId]="selectedChannelId"
    (back)="changePage(pages.Nav)"
    (search)="handleSearch()"
    (expand)="handleExpand()"
    (dismiss)="handleDismiss()">
  </app-chat-log-page>
  <app-create-chat-page
    *ngSwitchCase="pages.Create"
    (back)="changePage(pages.Nav)"
    (onCreate)="onChannelSelect($event)"
    (dismiss)="handleDismiss()">
  </app-create-chat-page>
  <app-chat-info-page
    *ngSwitchCase="pages.Details"
    [selectedChannelId]="selectedChannelId"
    (back)="changePage(pages.Nav)">
  </app-chat-info-page>
  <section *ngSwitchCase="pages.Disconnected" class="disconnected-page">
    <img src="assets/img/warning-ico.svg" alt="">
    <p>{{'connection-errors.zulip-offline' | translate}}</p>
    <footer>
      <ion-button (click)="handleDismiss()" fill="clear" expand="block">{{ 'common.btn-close' | translate }}</ion-button>
    </footer>
  </section>
</ng-container>
