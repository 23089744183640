import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { selectUserById } from "src/app/store/selectors/chat.selectors";
import { ChatMember } from "src/app/shared/models/chat";

@Pipe({
  name: 'isChatUserOnline'
})
export class IsChatUserOnlinePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(value: ChatMember): Observable<boolean> {
    if (value != null) {
      return this.store.select(selectUserById, value.zulip_uid).pipe(
        filter(userState => userState != null && !userState.loading),
        map(userState => userState.online),
        distinctUntilChanged()
      )
    }
    return of(false);
  }
}