import { ISchool } from '../../models/school';
import { IClass } from '../../models/class';
import { ISubject } from '../../models/subject';
import { IDashboardColumn, UserRole } from '../../models/common';
import { ITicket } from '../../models/ticket';

export abstract class DashboardPermissionsAbstract<T extends ISchool | IClass | ISubject> {
  constructor(
    protected userId: string,
  ) {}

  public abstract canEditEntity(entity: T): boolean;
  public abstract canRemoveEntity(entity: T): boolean;
  public abstract canAddColumn(entity: T): boolean;
  public abstract canCreateInvitation(entity: T): boolean;
  public abstract canEditOrRemoveColumn(entity: T, column: IDashboardColumn): boolean;
  public abstract canAddTicketsToColumn(entity: T, column: IDashboardColumn): boolean;
  public abstract canRemoveStudentOrTeacher(entity: T): boolean;
  public abstract canInviteStudents(entity: T): boolean;

  public isOwner(entity: T): boolean {
    return isOwner(entity.owner_id, this.userId);
  }

  public isInvitedTeacher(entity: T): boolean {
    return isInvitedTeacher(entity.role, entity.owner_id, this.userId);
  }

  public isStudent(entity: T): boolean {
    return isStudent(entity.role);
  }

  public isOwnerOrInvitedTeacher(entity: T): boolean {
    return isOwnerOrInvitedTeacher(entity.role, entity.owner_id, this.userId);
  }
}

export abstract class TicketPermissionsAbstract {
  constructor(
    protected userId: string,
  ) {}

  abstract canEditOrRemoveEntity(entity: ITicket): boolean;
  abstract canAddAttachments(entity: ITicket): boolean;
  abstract canRemoveAttachments(entity: ITicket): boolean;
  abstract canEditDate(entity: ITicket): boolean;

  public isOwner(entity: ITicket): boolean {
    return isOwner(entity.owner_id, this.userId);
  }

  public isInvitedTeacher(entity: ITicket): boolean {
    return isInvitedTeacher(entity.role, entity.owner_id, this.userId);
  }

  public isStudent(entity: ITicket): boolean {
    return isStudent(entity.role);
  }

  public isOwnerOrInvitedTeacher(entity: ITicket): boolean {
    return isOwnerOrInvitedTeacher(entity.role, entity.owner_id, this.userId);
  }

  protected isNewTicket(entity: ITicket): boolean {
    return !entity.id;
  }
}

type SchoolOrClass = ISchool | IClass;
export abstract class SchoolAndClassDashboardPermissions extends DashboardPermissionsAbstract<SchoolOrClass> {
  protected abstract readonly NOT_EDITED_COLUMNS: string[];
  protected abstract readonly FORBIDDEN_ADD_TICKETS_COLUMNS: string[];

  canEditEntity(schoolOrClass: SchoolOrClass): boolean {
    return this.isOwner(schoolOrClass);
  }

  canRemoveEntity(schoolOrClass: SchoolOrClass): boolean {
    return this.isOwnerOrInvitedTeacher(schoolOrClass);
  }

  canAddColumn(schoolOrClass: SchoolOrClass): boolean {
    return this.isOwner(schoolOrClass);
  }

  canEditOrRemoveColumn(schoolOrClass: SchoolOrClass, column: IDashboardColumn): boolean {
    if (this.NOT_EDITED_COLUMNS.includes(column.title.toLowerCase())) {
      return false;
    }

    return this.isOwner(schoolOrClass);
  }

  canAddTicketsToColumn(schoolOrClass: SchoolOrClass, column: IDashboardColumn): boolean {
    if (this.FORBIDDEN_ADD_TICKETS_COLUMNS.includes(column.title.toLowerCase())) {
      return false;
    }

    return this.isOwner(schoolOrClass);
  }

  canRemoveStudentOrTeacher(schoolOrClass: SchoolOrClass): boolean {
    return this.isOwner(schoolOrClass);
  }

  canCreateInvitation(schoolOrClass: SchoolOrClass): boolean {
    return this.isOwner(schoolOrClass);
  }

  canInviteStudents(entity: SchoolOrClass): boolean {
    return this.isOwner(entity);
  }
}

export class SchoolAndClassTicketPermissions extends TicketPermissionsAbstract {
  canEditOrRemoveEntity(entity: ITicket): boolean {
    if (this.isNewTicket(entity)) {
      return true;
    }
    return this.isOwner(entity);
  }
  canAddAttachments(entity: ITicket): boolean {
    if (this.isNewTicket(entity)) {
      return true;
    }
    return this.isOwner(entity);
  }
  canRemoveAttachments(entity: ITicket): boolean {
    return this.isOwner(entity);
  }

  canEditDate(entity: ITicket): boolean {
    if (entity.is_published) {
      return false;
    }
    return this.isOwner(entity);
  }
}

function isOwner(ownerId: string, userId: string): boolean {
  return ownerId === userId;
}

function isInvitedTeacher(role: UserRole, ownerId: string, userId: string): boolean {
  return role === UserRole.TEACHER && ownerId !== userId;
}

function isStudent(role: UserRole): boolean {
  return role === UserRole.STUDENT;
}

function isOwnerOrInvitedTeacher(role: UserRole, ownerId: string, userId: string): boolean {
  return isOwner(ownerId, userId) || isInvitedTeacher(role, ownerId, userId);
}
