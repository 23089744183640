import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ITreeOptions, TreeModel, TreeNode} from 'angular-tree-component';
import {NodeType} from './models/node_type';
import {INode} from './models/node';
import {DEFAULT_TREE, TREE_CONFIG} from './config';
import {TreeMenuStore} from './tree-menu.store';
import {delay, filter, first, map, skip, takeUntil} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {detectChanges} from '../../helpers/helpers';
import {NavigationEnd, Router} from '@angular/router';
import {IActivePage} from '../../models/common';
import {NavController} from '@ionic/angular';
import {AppMenuService} from '../../services/menu.service';
import {PlatformService} from '../../services/platform.service';
import {PermissionService} from '../../services/permission/permission.service';
import {IUserSettings, UserSettingsService} from 'src/app/auth/services/user-settings.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss'],
  providers: [TreeMenuStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TreeModel, { static: false }) treeModel: TreeModel;
  @Output() openPage: EventEmitter<INode> = new EventEmitter();
  @Output() openVideo: EventEmitter<INode> = new EventEmitter();
  @Output() openCreate: EventEmitter<INode> = new EventEmitter();
  @Output() openInvite: EventEmitter<INode> = new EventEmitter();
  @Output() openNode: EventEmitter<INode> = new EventEmitter();
  @Input() reRenderTree: Subject<void>;
  @Input() isChildEditing: Subject<void>;
  @Input() isPrimaryNode: boolean;

  nodes: INode[] = [];

  options: ITreeOptions = {
    animateExpand: true,
    animateSpeed: this.isMobile ? 15 : 30,
  };

  selectedNodeId: string;
  isLoaded: boolean = false;
  collapsed: boolean = false;
  private isEditModeEnabled: boolean = false;
  private isShareModeEnabled: boolean = true;
  private userSettings: IUserSettings;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private treeMenuStore: TreeMenuStore,
    private cdf: ChangeDetectorRef,
    private router: Router,
    private navCtrl: NavController,
    private zone: NgZone,
    private appMenuService: AppMenuService,
    private platformService: PlatformService,
    private permissionService: PermissionService,
    private userSettingsService: UserSettingsService,
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) {
    this.userSettings = this.userSettingsService.getSettings();
  }

  ngOnInit() {
    this.cdf.detach();

    this.treeStoreSubscription();
    this.routerEventSubscription();
    this.menuCollapsedSubscription();
    this.reRenderingSubscription();
    this.primaryNodeEditingTriggerSubscription();
    this.listenForUserSettingsChanges();
    this.listenForLangChanges();

    this.treeMenuStore.onInit();

    this.appMenuService.markAsSelectedPage$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(activePage => this.markNodeAsSelected(activePage));
  }

  get isMobile(): boolean {
    return this.platformService.isMobile;
  }

  primaryNodeEditingTriggerSubscription(): void {
    this.isChildEditing
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toggleEditOrShareButtons();
          this.treeMenuStore.refreshTree();
        });
  }

  reRenderingSubscription(): void {
    if (this.reRenderTree) {
      this.reRenderTree
          .pipe(
            filter(() => this.isMobile),
            takeUntil(this.destroy$),
          )
          .subscribe(() => {
            this.isPrimaryNode = false;
            this.selectedNodeId = null;
            this.treeMenuStore.primaryNodeType = null;
            this.treeMenuStore.tree$.next(this.treeMenuStore.initializedTree$.getValue());
          });
    }
  }

  treeStoreSubscription(): void {
    this.treeMenuStore.tree$
        .pipe(
            skip(2),
            map(tree => this.transformTree(tree)),
            takeUntil(this.destroy$),
        )
        .subscribe(tree => this.onTreeStoreSuccess(tree));
  }

  isShareBtnVisible(node: INode): boolean {
    if (this.isEditModeEnabled) {
      return false;
    }
    switch (node.type) {
      case NodeType.SCHOOL:
        return this.permissionService.schoolDashboard.canCreateInvitation(node.payload);
      case NodeType.CLASS:
        return this.permissionService.classDashboard.canCreateInvitation(node.payload);
      case NodeType.SUBJECT:
        return this.permissionService.subjectDashboard.canCreateInvitation(node.payload);
      default:
        return false;
    }
  }

  isNodeHidden(node: INode): boolean {
    if (this.userSettings) {
      switch (node.type) {
        case NodeType.SUBJECT:
        case NodeType.SUBJECT_DASHBOARD:
        case NodeType.SUBJECT_CALENDAR:
          return this.userSettings.subjects && this.userSettings.subjects.hidden[node.payload.id] === true;
        case NodeType.LESSON:
          return this.userSettings.lessons && this.userSettings.lessons.hidden[node.payload.id] === true;
      }
    }
    return false;
  }

  getNodeLabel(nameLangKey: string): string {
    return this.translateService.instant(nameLangKey);
  }

  private onTreeStoreSuccess(tree: INode[]): void {
    this.nodes = tree;
    this.isLoaded = true;
    const activePage: IActivePage = this.getActivePage(location.href);
    this.setEditOrShareModeStatus();
    if (activePage) {
      if ((activePage.type === NodeType.LESSON_GROUP || activePage.type === NodeType.LESSON_CALENDAR) && !this.hasLessons(tree)) {
        return this.navigateToHomePage();
      } else if ((activePage.type === NodeType.SUBJECT_GROUP || activePage.type === NodeType.SUBJECT_DASHBOARD || activePage.type === NodeType.SUBJECT_CALENDAR) && !this.hasSubjects(tree)) {
        return this.navigateToHomePage();
      } else if (activePage.type === NodeType.CLASS && !this.hasClasses(tree)) {
        return this.navigateToHomePage();
      } else if (activePage.type === NodeType.SCHOOL && !this.hasSchools(tree)) {
        return this.navigateToHomePage();
      }

      this.maybeToggleCollapsed(activePage);
    }

    detectChanges(this.cdf);
  }

  setPrimaryNode(nodeType: NodeType): void {
    if (nodeType === NodeType.CLASS) {
      nodeType = NodeType.CLASS_GROUP;
    } else  if (nodeType === NodeType.SCHOOL) {
      nodeType = NodeType.SCHOOL_GROUP;
    } else  if (nodeType === NodeType.SUBJECT) {
      nodeType = NodeType.SUBJECT_GROUP;
    } else if (nodeType === NodeType.MATERIAL) {
      nodeType = NodeType.MATERIAL_GROUP;
    } else if (nodeType === NodeType.SUBJECT_CALENDAR || nodeType === NodeType.SUBJECT_DASHBOARD) {
      nodeType = NodeType.SUBJECT_GROUP;
    }
    const selectedNode = this.nodes.find((node: INode) => node.type === nodeType);
    if (selectedNode) {
      this.mobileSubMenuActive(selectedNode);
    }
  }

  routerEventSubscription(): void {
    this.router.events
        .pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.destroy$),
        )
        .subscribe(() => {
          const activePage: IActivePage = this.getActivePage(location.href);
          if (activePage) {
            if (this.isMobile) {
              this.setPrimaryNode(activePage.type);
            }
            this.markNodeAsSelected(activePage);
            this.maybeToggleCollapsed(activePage);
          }
        });
  }

  menuCollapsedSubscription(): void {
    this.appMenuService.collapsedChanges$
        .pipe(takeUntil(this.destroy$))
        .subscribe(isCollapsed => {
          this.collapsed = isCollapsed;
          if (isCollapsed) {
            this.collapseOpenNodes();
          } else {
            this.openNodesByDefault();
          }
          detectChanges(this.cdf);
        });
  }

  ngAfterViewInit() {
    detectChanges(this.cdf);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  treeUpdated() {
    if (this.collapsed) {
      this.collapseOpenNodes();
    } else {
      this.openNodesByDefault();
    }

    this.setEditOrShareModeStatus();
  }

  isNodeSubItem(treeNode: TreeNode): boolean {
    const node: INode = treeNode.data;
    return node.type === NodeType.SUBJECT_DASHBOARD || node.type === NodeType.SUBJECT_CALENDAR;
  }

  onNodeClicked(treeNode: TreeNode, shouldOnlyExpandNode: boolean = false): void {
    const node: INode = treeNode.data;

    if (node.type === NodeType.CREATE) {
      this.openCreateMenu(node);
    } else if (this.shouldBeAlwaysOpen(node) && this.shouldOpenPage(node)) {
      this.changePage(node);
    } else if (this.shouldOpenPage(node)) {
      if (!shouldOnlyExpandNode || (![NodeType.SUBJECT_GROUP, NodeType.LESSON_GROUP].includes(node.type) && shouldOnlyExpandNode) ) {
        this.changePage(node);
      }
      if (!this.canShowCollapseBtn(node)) {
        this.doTreeNodeExpand(treeNode);
      }
    } else {
      const childNode: INode = treeNode.getFirstChild(true).data;
      if (this.collapsed && this.shouldOpenPage(childNode)) {
        this.changePage(childNode);
      } else {
        if (node.type === NodeType.SUBJECT && !this.isMobile) {
          this.navigationSubjectDashboard(node);
        }
        this.doTreeNodeExpand(treeNode);
      }
    }

    this.mobileSubMenuActive(node);
  }

  private navigationSubjectDashboard(node: INode): void {
    const subjectDashboard = node.children.find(n => n.type === NodeType.SUBJECT_DASHBOARD);
    of(subjectDashboard)
        .pipe(
            delay(500),
            filter(dashboard => !!dashboard),
            first()
        )
        .subscribe((dashboard) => this.changePage(dashboard));
  }

  private doTreeNodeExpand(treeNode: TreeNode): void {
    if (this.shouldToggleNode) {
      treeNode.toggleExpanded();
    }
  }

  mobileSubMenuActive(node: INode) {
    if (this.isMobile && !this.isPrimaryNode && node.type !== NodeType.CREATE && node.type !== NodeType.MEETING && node.type !== NodeType.LESSON_CALENDAR) {
      this.isPrimaryNode = true;
      this.treeMenuStore.primaryNodeType = node.type;
      this.treeMenuStore.tree$.next(node.children);
      this.openNode.emit(node);
    }
  }

  private get shouldToggleNode(): boolean {
    return this.isMobile ? (this.isMobile && this.isPrimaryNode) : true;
  }

  onEditBtnClicked(treeNode: TreeNode): void {
    const node: INode = treeNode.data;
    if (node.type === NodeType.CREATE) {
      this.toggleEditOrShareButtons();
    } else {
      this.openCreateMenu(node);
    }
  }

  onShareBtnClicked(node: TreeNode): void {
    this.openInvite.emit(node.data);
  }

  isActiveNode(node: TreeNode): boolean {
    return this.selectedNodeId === node.data.id;
  }

  canShowCollapseBtn(node: INode): boolean {
    if (window.innerWidth <= 767) {
      return [
          NodeType.SCHOOL_GROUP,
          NodeType.MATERIAL_GROUP,
          NodeType.CLASS_GROUP,
          NodeType.SUBJECT_GROUP,
          NodeType.LESSON_GROUP
      ].includes(node.type);
    }
    return false;
  }

  onCollapseBtnClicked(treeNode: TreeNode): void {
    if (this.shouldToggleNode) {
      treeNode.toggleExpanded();
    }
  }

  isNodeCollapsed(treeNode: TreeNode): boolean {
    return treeNode.isExpanded;
  }

  private toggleEditOrShareButtons(): void {
    this.isEditModeEnabled = !this.isEditModeEnabled;
    this.isShareModeEnabled = !this.isShareModeEnabled;
    this.setEditOrShareModeStatus();
  }

  private openNodesByDefault(): void {
    this.treeModel.getNodeBy((node: TreeNode) => {
      TREE_CONFIG.alwaysOpenTypes
        .forEach(type => {
          if ((node.data as INode).type === type) {
            this.doNodeExpand(node);
          }
        });
    });
  }

  private doNodeExpand(node: any): void {
    if (this.shouldToggleNode) {
      node.expand();
    }
  }

  private collapseOpenNodes(): void {
    this.treeModel.doForAll((node: TreeNode) => node.collapseAll());
    detectChanges(this.cdf);
  }

  private openCreateMenu(node: INode): void {
    if (node.type === NodeType.CREATE) {
      this.openCreate.emit(null);
    } else if (node.type === NodeType.SCHOOL || node.type === NodeType.CLASS || node.type === NodeType.SUBJECT || node.type === NodeType.MATERIAL) {
      this.openCreate.emit(node);
    }
  }

  private changePage(node: INode): void {
    this.selectedNodeId = node.id;
    this.openPage.emit(node);
  }

  private setEditOrShareModeStatus(): void {
    for (const key in this.nodes) {
      if (this.nodes.hasOwnProperty(key)) {
        const node = this.nodes[key];

        if (node.children && !this.isPrimaryNode) {
          node.children.forEach(child => this.setNodeButtons(child));
        } else if (this.isPrimaryNode) {
          this.setNodeButtons(node);
        }
      }
    }
  }

  private setNodeButtons(node: any): void {
    if (node.editEnabled !== null && node.editEnabled !== undefined) {
      node.editEnabled = this.isEditModeEnabled;
    }
    if (node.shareEnabled !== null && node.shareEnabled !== undefined) {
      node.shareEnabled = this.isShareModeEnabled;
    }
  }

  private transformTree(tree: INode[]): INode[] {
    const newTree = tree.filter(group => group.type === NodeType.CREATE || (group.children && group.children.length));

    this.sortSchoolAndSchoolClassesInNode(newTree);

    if (newTree.length === 0 || (this.isMobile && this.isPrimaryNode))  {
      return tree;
    } else  {
      const lessonGroupIndex = newTree.findIndex(n => n.type === NodeType.LESSON_GROUP);

      if (lessonGroupIndex !== -1) {
        const lessonCalendarNode: INode = DEFAULT_TREE.find(n => n.type === NodeType.LESSON_CALENDAR);
        newTree.splice(lessonGroupIndex, 0, lessonCalendarNode);
      }

      const meetingsNode: INode = DEFAULT_TREE.find(n => n.type === NodeType.MEETING);
      newTree.splice(newTree.length, 0, meetingsNode);

      return newTree;
    }
  }

  private sortSchoolAndSchoolClassesInNode(tree: INode[]): void {
    for (const node of tree) {
      if (node.type === NodeType.SCHOOL_GROUP || node.type === NodeType.CLASS_GROUP) {
        if ('children' in node && node.children.length) {
          node.children.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
    }
  }

  private shouldBeAlwaysOpen(node: INode): boolean {
    return TREE_CONFIG.alwaysOpenTypes.findIndex(n => n === node.type) !== -1;
  }

  private shouldOpenPage(node: INode): boolean {
    return TREE_CONFIG.hasPagesTypes.findIndex(n => n === node.type) !== -1;
  }

  private getActivePage(url: string): IActivePage {
    if (url.includes('school-dashboard')) {
      const id = this.extractIdFromUrl(url);
      return { type: NodeType.SCHOOL, id } as IActivePage;
    } else if (url.includes('class-dashboard')) {
      const id = this.extractIdFromUrl(url);
      return { type: NodeType.CLASS, id } as IActivePage;
    } else if (url.includes('subjects')) {
      return { type: NodeType.SUBJECT_GROUP } as IActivePage;
    } else if (url.includes('subject-dashboard')) {
      const id = this.extractIdFromUrl(url);
      return { type: NodeType.SUBJECT_DASHBOARD, id } as IActivePage;
    } else if (url.includes('subject-calendar')) {
      const id = this.extractIdFromUrl(url);
      return { type: NodeType.SUBJECT_CALENDAR, id } as IActivePage;
    } else if (url.includes('lessons-calendar')) {
      return { type: NodeType.LESSON_CALENDAR } as IActivePage;
    } else if (url.includes('lessons')) {
      return { type: NodeType.LESSON_GROUP } as IActivePage;
    } else if (url.includes('material')) {
      const id = this.extractIdFromUrl(url);
      return { type: NodeType.MATERIAL, id } as IActivePage;
    } else if (url.includes('meetings')) {
      return { type: NodeType.MEETING } as IActivePage;
    }
    this.selectedNodeId = null;
    detectChanges(this.cdf);
    return null;
  }

  private extractIdFromUrl(url): string {
    const items = url.split('/');

    return items[items.length - 1];
  }

  private markNodeAsSelected(activePage: IActivePage): void {
    this.zone.onStable.pipe(first()).subscribe(() => {
      if (!this.treeModel) {
        return;
      }

      this.treeModel.getNodeBy((node: TreeNode) => {
        if (activePage.type === NodeType.SUBJECT_GROUP || activePage.type === NodeType.LESSON_GROUP || activePage.type === NodeType.LESSON_CALENDAR || activePage.type === NodeType.MEETING) {
          if ((node.data as INode).type === activePage.type) {
            this.selectedNodeId = node.id;
          }
        } else if (activePage.type === NodeType.SCHOOL || activePage.type === NodeType.CLASS || activePage.type === NodeType.MATERIAL) {
          if ((node.data as INode).id === activePage.id) {
            this.doNodeExpand(node.parent);
            this.selectedNodeId = node.id;
          }
        } else if (activePage.type === NodeType.SUBJECT_DASHBOARD) {
          if ((node.data as INode).id === activePage.id) {
            if ((node.data as INode).type === NodeType.SUBJECT) {
              this.doNodeExpand(node.parent);
              this.doNodeExpand(node);
              this.selectedNodeId = `${(node.data as INode).id}_[dashboard]`;
            } else if ((node.data as INode).type === NodeType.LESSON) {
              this.doNodeExpand(node.parent);
              this.selectedNodeId = (node.data as INode).id;
            }
          }
        } else if (activePage.type === NodeType.SUBJECT_CALENDAR) {
          if ((node.data as INode).id === activePage.id) {
            this.doNodeExpand(node.parent);
            this.doNodeExpand(node);
            this.selectedNodeId = `${(node.data as INode).id}_[calendar]`;
          }
        }
        detectChanges(this.cdf);
      });
    });
  }

  private maybeToggleCollapsed(activePage: IActivePage) {
    if (!this.treeModel || this.isMobile) {
      return;
    }

    this.zone.onStable.pipe(first()).subscribe(() => {
      this.treeModel.getNodeBy((node: TreeNode) => {
        const nodeData = node.data as INode;
        if (nodeData.type === activePage.type) {
          const collapsed = Boolean(nodeData.collapseMenuWhenActive);

          if (this.collapsed !== collapsed) {
            this.collapsed = collapsed;
            this.appMenuService.toggleCollapsed(this.collapsed);
            detectChanges(this.cdf);
          }
        }
      });
    });
  }

  private hasLessons(tree: INode[]): boolean {
    return this.hasEntities(tree, NodeType.LESSON_GROUP);
  }

  private hasSubjects(tree: INode[]): boolean {
    return this.hasEntities(tree, NodeType.SUBJECT_GROUP);
  }

  private hasClasses(tree: INode[]): boolean {
    return this.hasEntities(tree, NodeType.CLASS_GROUP);
  }

  private hasSchools(tree: INode[]): boolean {
    return this.hasEntities(tree, NodeType.SCHOOL_GROUP);
  }

  private hasEntities(tree: INode[], nodeType: NodeType): boolean {
    if (this.isMobile) {
      return true;
    }
    const node: INode = tree.find(n => n.type === nodeType);
    return node
      ? node.children && node.children.length > 0
      : null;
  }

  private navigateToHomePage(): void {
    detectChanges(this.cdf);
    this.navCtrl.navigateRoot('pages/home');
  }

  private listenForUserSettingsChanges(): void {
    this.userSettingsService.userSettings$()
      .pipe(
        skip(1),
        takeUntil(this.destroy$),
      )
      .subscribe(settings => {
        this.userSettings = settings;
        detectChanges(this.cdf);
      });
  }

  private listenForLangChanges(): void {
    this.languageService.changes$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => detectChanges(this.cdf));
  }
}
