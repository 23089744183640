import * as tslib_1 from "tslib";
import { PopoverController } from '@ionic/angular';
import { InviteModalComponent } from '../components/invite-modal/invite-modal.component';
import { InviteType } from '../components/invite-modal/models';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class InviteModalService {
    constructor(popoverCtrl) {
        this.popoverCtrl = popoverCtrl;
    }
    open(entityId, entityType, inviteType = InviteType.STUDENT) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const inviteModal = yield this.popoverCtrl.create({
                componentProps: {
                    entityId,
                    entityType,
                    preselectedUserType: inviteType
                },
                component: InviteModalComponent,
                backdropDismiss: true,
                cssClass: 'invite-modal',
            });
            return inviteModal.present();
        });
    }
}
InviteModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InviteModalService_Factory() { return new InviteModalService(i0.ɵɵinject(i1.PopoverController)); }, token: InviteModalService, providedIn: "root" });
