<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button (click)="back.next()">
        <ion-icon slot="icon-only" class="back-icon" name="chevron-back-outline" size="small"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="selectedChannel$ | async as selectedChannel">
      <ng-container *ngIf="selectedChannel.meta?.type === 'channel'; else pmChannelHeaderTempl">
        {{ selectedChannel.title }}
        <p class="members-count">
          {{ selectedChannel.subscribers?.length }}
          <ng-container *ngIf="selectedChannel?.subscribers?.length === 1; else multiMembersTempl">{{ 'chat.member_single' | translate }}</ng-container>
          <ng-template #multiMembersTempl>{{ 'chat.member_multi' | translate }}</ng-template>
        </p>
      </ng-container>
      <ng-template #pmChannelHeaderTempl>
        <app-chat-conversation-item
          [title]="selectedChannel.title"
          [members]="selectedChannel.members"
          [noPaddings]="true"
          lines="none">
        </app-chat-conversation-item>
      </ng-template>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="openConference()">
        <ion-icon src="/assets/img/camera-ic.svg" size="small" class="camera-icon" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button (click)="search.next()">
        <ion-icon src="/assets/img/search-thin-ic.svg" size="small" class="search-icon" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button (click)="expand.next()" class="expand-btn">
        <ion-icon src="/assets/img/open-new-tab-ic.svg" size="small" class="open-new-tab-icon" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button (click)="dismiss.next()" class="close-btn">
        <img alt="Close" slot="icon-only" src="/assets/img/chat-close-btn-dark.svg">
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content appRemoveContentSizing scroll-y="false">
  <app-chat-closed-notice *ngIf="displayChatRemovedNotice$ | async" (ok)="back.next()"></app-chat-closed-notice>
  <app-chat-log [selectedChannelId]="selectedChannelId"></app-chat-log>
</ion-content>
