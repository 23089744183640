import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationMessageComponent } from './notification-message.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { MeetingAttendeeModule } from '../meeting-attendee/meeting-attendee.module';



@NgModule({
  declarations: [NotificationMessageComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    PipesModule,
    MeetingAttendeeModule,
  ],
  exports: [NotificationMessageComponent],
})
export class NotificationMessageModule { }
