<div class="content">
  <ion-list class="ion-padding-vertical" lines="none">
    <ion-item class="header ion-padding-horizontal" lines="none">
      <ion-label class="header font-light">{{ 'create-menu.grades-title' | translate }}</ion-label>
    </ion-item>
    <ion-item class="grade-ranges-header" lines="none">
      <div class="container">
        <ion-button slot="start" fill="clear" mode="md" disabled [ngStyle]="{'opacity': '1'}">
          <ion-label class="font-regular">{{ 'create-menu.grades-default' | translate }}</ion-label>
        </ion-button>
        <ion-button slot="end" fill="clear" mode="md" (click)="isCustomGradeGeneratorVisible$.next(true)" [disabled]="isDisabled || !canEdit">
          <ion-label class="primary font-regular">{{ 'create-menu.grades-custom' | translate }}</ion-label>
        </ion-button>
      </div>
    </ion-item>
    <ion-item class="grade-ranges-items ion-margin-bottom">
      <ng-container *ngIf="(gradesDefault$ | async) as grades; else showDefaultGradesLoading">
        <ng-container *ngIf="isDefaultGradesLoading; else showDefaultGrades">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ng-container>
        <ng-template #showDefaultGrades>
          <div class="container">
            <ion-button *ngFor="let grade of grades" fill="clear" mode="md" (click)="selectedDefaultGrade$.next(grade)" [ngClass]="{'active': selectedDefaultGrade$.value?.id === grade?.id}" [disabled]="isDisabled || !canEdit">
              <ion-label class="primary">{{ getDefaultGradeTitle(grade) }}</ion-label>
            </ion-button>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #showDefaultGradesLoading>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ng-template>
    </ion-item>
    <app-checkbox class="grade-checkbox" [formControl]="highestGradeControl" [isDisabled]="isDisabled || !canEdit">{{ 'create-menu.grades-highes' | translate }}</app-checkbox>
    <app-checkbox class="grade-checkbox" [formControl]="hasPlusMinusControl" [isDisabled]="isDisabled || !canEdit">{{ 'create-menu.enable' | translate }}</app-checkbox>
    <div class="grade-list ion-margin-top" styledScrollbar [styledScrollbarSelf]="true">
      <ng-container *ngIf="(!gradeValues$.value || !gradeValues$.value.length) && !isNewGradeInputVisible$.value">
        <ion-text class="font-light empty-label">{{ 'common.empty-list' | translate }}</ion-text>
      </ng-container>
      <ion-reorder-group disabled="false" (ionItemReorder)="reorderGrades($event)">
        <ng-container *ngIf="gradeValues$ | async as gradings">
          <ng-container *ngIf="isGradeLoading; else showGrade">
            <ion-skeleton-text style="height: 20px; width: 80%; margin: 0 auto;" animated></ion-skeleton-text>
          </ng-container>
          <ng-template #showGrade>
            <ion-item *ngFor="let gradeValue of gradings; let i = index" class="input grade-item ion-padding-horizontal" lines="none">
              <ion-reorder [ngStyle]="{'pointer-events': (isDisabled || !canEdit) ? 'none' : 'all'}" slot="start"></ion-reorder>
              <ion-note slot="start">{{ i + 1 }}.</ion-note>
              <span [hidden]="!grade?.is_percent" class="percent">%</span>
              <ion-input [value]="gradeValue" (ionBlur)="updateGrade($event, i)" (keyup.enter)="updateGrade($event, i)" [ngStyle]="{'pointer-events': (isDisabled || !canEdit) ? 'none' : 'all'}" [readonly]="isDisabled || !canEdit"></ion-input>
              <ion-button slot="end" mode="md" class="circle-chip-btn remove" (click)="removeGrade(i)" [disabled]="isDisabled || !canEdit">
                <ion-icon class="ico icon-remove"></ion-icon>
              </ion-button>
            </ion-item>
            <ion-item [hidden]="!(isNewGradeInputVisible$ | async)" class="input grade-item ion-padding-horizontal" lines="none" [disabled]="isDisabled">
              <ion-reorder [ngStyle]="{'pointer-events': 'none', 'opacity': '0'}" slot="start"></ion-reorder>
              <ion-note slot="start">{{ gradings?.length + 1 }}.</ion-note>
              <ion-input #newGradeInput (ionBlur)="addGrade($event.target.value)" (keyup.enter)="addGrade($event.target.value)"></ion-input>
              <ion-button slot="end" mode="md" class="circle-chip-btn remove" (click)="removeGrade(null, true)" [disabled]="isDisabled">
                <ion-icon class="ico icon-remove"></ion-icon>
              </ion-button>
            </ion-item>
          </ng-template>
        </ng-container>
      </ion-reorder-group>
    </div>
    <ion-item class="ion-padding-horizontal">
      <ion-button fill="clear" mode="md" slot="end" class="new-grade-btn" (click)="showNewGradeInput()" [disabled]="isDisabled || !canEdit">
        {{ 'create-menu.add-new-grade' | translate }}
      </ion-button>
    </ion-item>
    <ion-item class="ion-padding-horizontal close-btn" lines="none">
      <ion-button class="primary font-extra-bold ion-no-margin" mode="md" expand="block" (click)="close.emit()" [disabled]="isDisabled">
        {{ 'common.btn-close' | translate }}
      </ion-button>
    </ion-item>
    <ion-item class="recent-grades ion-margin-top">
      <ion-label>{{ 'create-menu.use-recent-grades' | translate }}</ion-label>
    </ion-item>

    <ng-container *ngIf="(gradesRecent$ | async) as grades; else showRecentGradesLoading">
      <ng-container *ngIf="isRecentGradesLoading; else showRecentGrades">
        <ion-skeleton-text style="height: 15px; width: 80%; margin: 0 auto" animated></ion-skeleton-text>
      </ng-container>
      <ng-template #showRecentGrades>
        <div class="recent-grade-list" styledScrollbar [styledScrollbarSelf]="true">
          <ion-radio-group [value]="selectedRecentGrade$ | async" (ionChange)="selectedRecentGrade$.next($event.target.value)">
            <ion-item *ngFor="let grade of grades" class="grade-checkbox grade-radio" [disabled]="isDisabled || !canEdit">
              <ion-radio slot="start" [value]="grade.grade.id"></ion-radio>
              <ion-label class="font-regular">{{ getRecentGradeTitle(grade) }}</ion-label>
            </ion-item>
          </ion-radio-group>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #showRecentGradesLoading>
      <ion-skeleton-text style="height: 15px; width: 80%; margin: 0 auto" animated></ion-skeleton-text>
    </ng-template>
  </ion-list>
  <ng-container *ngIf="isCustomGradeGeneratorVisible$ | async">
    <app-grades-custom-generator [@inOutAnimation] (close)="isCustomGradeGeneratorVisible$.next(false)" (generated)="generateCustom($event)"></app-grades-custom-generator>
  </ng-container>
</div>
