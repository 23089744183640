import { SchoolAndClassDashboardPermissions, SchoolAndClassTicketPermissions } from './base.abstract.class';
import { ClassDashboardColumn } from 'src/app/pages/class-dashboard/helpers';
import { isPublishedAndDatePast } from '../../components/ticket-item/helpers';
export class ClassDashboardPermissions extends SchoolAndClassDashboardPermissions {
    constructor() {
        super(...arguments);
        this.NOT_EDITED_COLUMNS = Object.keys(ClassDashboardColumn).map(title => title.toLowerCase());
        this.FORBIDDEN_ADD_TICKETS_COLUMNS = [
            ClassDashboardColumn.TEACHERS,
            ClassDashboardColumn.STUDENTS,
        ].map(title => title.toLowerCase());
    }
}
export class ClassTicketPermissions extends SchoolAndClassTicketPermissions {
    canEditDate(entity) {
        return !isPublishedAndDatePast(entity) && this.canEditOrRemoveEntity(entity);
    }
}
