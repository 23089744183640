import 'firebase/remote-config';
import * as firebase from 'firebase/app';
import * as i0 from "@angular/core";
export class RemoteConfigService {
    constructor() { }
    initialize() {
        const defaultRemoteConfig = firebase.remoteConfig();
        defaultRemoteConfig.fetchAndActivate().then(() => {
            this.remoteConfig = defaultRemoteConfig.getAll();
        });
    }
    get showDonateBanner() {
        return this.remoteConfig && this.remoteConfig.show_donate_banner ? this.remoteConfig.show_donate_banner.asBoolean() : false;
    }
}
RemoteConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoteConfigService_Factory() { return new RemoteConfigService(); }, token: RemoteConfigService, providedIn: "root" });
