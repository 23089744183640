/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./files-image-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./files-image-preview.component";
var styles_FilesImagePreviewComponent = [i0.styles];
var RenderType_FilesImagePreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilesImagePreviewComponent, data: {} });
export { RenderType_FilesImagePreviewComponent as RenderType_FilesImagePreviewComponent };
function View_FilesImagePreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 1, 0, currVal_0); }); }
function View_FilesImagePreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-button", [["class", "remove-img-btn circle-chip-btn remove"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.remove.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["class", "ico icon-remove"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null)], function (_ck, _v) { var currVal_0 = "md"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FilesImagePreviewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "foreground"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ion-icon", [["class", "ico icon-view"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(2, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesImagePreviewComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesImagePreviewComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ion-button", [["class", "download-img-btn"], ["fill", "clear"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.download.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(8, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 0, "img", [["alt", ""], ["slot", "icon-only"], ["src", "assets/img/download-img.svg"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.src && _co.src.length); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.canRemove; _ck(_v, 6, 0, currVal_1); var currVal_2 = "clear"; var currVal_3 = "md"; _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
export function View_FilesImagePreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-files-image-preview", [], [[8, "className", 0]], null, null, View_FilesImagePreviewComponent_0, RenderType_FilesImagePreviewComponent)), i1.ɵdid(1, 114688, null, 0, i5.FilesImagePreviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).className; _ck(_v, 0, 0, currVal_0); }); }
var FilesImagePreviewComponentNgFactory = i1.ɵccf("app-files-image-preview", i5.FilesImagePreviewComponent, View_FilesImagePreviewComponent_Host_0, { src: "src", previewMode: "previewMode", canRemove: "canRemove" }, { remove: "remove", download: "download" }, []);
export { FilesImagePreviewComponentNgFactory as FilesImagePreviewComponentNgFactory };
