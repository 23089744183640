<div class="content">
  <ion-card mode="md" [ngStyle]="{ 'opacity': isSubmitted ? '0.9' : '1' }" [ngClass]="{'notification': view === 'notification'}">
    <ion-spinner [hidden]="!isSubmitted" class="btn-spinner" color="primary" name="crescent"></ion-spinner>
    <ion-card-header [ngStyle]="{ 'border-bottom': view === 'notification' ? 'none' : '1px solid #F2F2F2' }">
      <ng-container *ngIf="notification">
        <ion-label class="notification-date font-light">
          {{ formattedDate }}
          <div *ngIf="!notification.is_read" class="unread"></div>
        </ion-label>
        <ng-container *ngIf="notification.type === notificationTypes.MEETING_ATTENDEE_STATUS_UPDATES || notification.type === notificationTypes.MEETING_ATTENDEE_CHANGED">
          <div *ngIf="!notification.payload.is_deleted && notification.payload.meeting_status === meetingUserStatus.ACCEPTED" class="navigate-to-meeting">
            <ion-button fill="clear" mode="md" (click)="open()">
              <img src="assets/img/open-ic.svg" slot="icon-only" alt="">
            </ion-button>
          </div>
          <ion-icon *ngIf="notification.payload.meeting_status !== meetingUserStatus.ACCEPTED" [src]="getMeetingAttendeeStatusUpdateIcon(notification.payload.meeting_status)" class="meeting-status"></ion-icon>
        </ng-container>
        <ng-container *ngIf="notification.type === notificationTypes.MEETING_ATTENDEE">
          <ng-container *ngIf="(meetingStatus$ | async) as status">
            <ng-container *ngIf="status !== meetingAttendeeOperations.PENDING && status !== meetingAttendeeOperations.ACCEPTED">
              <ion-icon [src]="getMeetingUserStatusIcon(status)" class="meeting-status"></ion-icon>
            </ng-container>
            <div *ngIf="!notification.payload.is_deleted && status === meetingAttendeeOperations.ACCEPTED" class="navigate-to-meeting">
              <ion-button fill="clear" mode="md" (click)="open()">
                <img src="assets/img/open-ic.svg" slot="icon-only" alt="">
              </ion-button>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="notification.type === notificationTypes.MEETING_ATTENDEE_CHANGED">
          <ion-label class="meeting-changed-title">{{ meetingChangedTitle }}</ion-label>
        </ng-container>
        <ng-container *ngIf="notification.type === notificationTypes.MEETING_ATTENDEE_STATUS_UPDATES">
          <ion-text class="meeting-status-update-title font-bold">{{ getUserMeetingStatusTitle(notification.payload.meeting_status) }}</ion-text>
        </ng-container>
        <ng-container *ngIf="notification.type === notificationTypes.MEETING_ATTENDEE">
          <ng-container *ngIf="(meetingStatus$ | async) as status">
              <ion-text
                      *ngIf="status === meetingAttendeeOperations.ACCEPTED"
                      class="meeting-status-update-title font-bold meeting-status-update-title--accepted">{{'notifications.i-accept-invite' | translate }}</ion-text>
          </ng-container>
        </ng-container>
      </ng-container>
      <ion-card-subtitle>
        {{ 'meeting.title-one' | translate }} - {{ meetingType }}
      </ion-card-subtitle>
      <ion-card-title>
        {{ meetingName | truncate:[25] }}
      </ion-card-title>
      <ng-container *ngIf="notification && notification.type === notificationTypes.MEETING_ATTENDEE_STATUS_UPDATES; else loadOwner">
        <ion-item class="owner" lines="none">
          <ion-avatar slot="start">
            <img src="assets/img/boy-placeholder.svg" alt="">
          </ion-avatar>
          <ion-label>{{ meetingUserStatusUpdateFullname | truncate:[20] }}</ion-label>
        </ion-item>
      </ng-container>
      <ng-template #loadOwner>
        <ng-container *ngIf="(ownerFullname$ | async) as fullName; else ownerLoading">
          <ion-item class="owner" lines="none">
            <ion-avatar slot="start">
              <img src="assets/img/boy-placeholder.svg" alt="">
            </ion-avatar>
            <ion-label>{{ fullName | truncate:[20] }}</ion-label>
          </ion-item>
        </ng-container>
        <ng-template #ownerLoading>
          <ion-item lines="none">
            <ion-skeleton-text animated style="height: 40%"></ion-skeleton-text>
          </ion-item>
        </ng-template>
      </ng-template>
    </ion-card-header>
    <ion-card-content>
      <ng-container *ngIf="meetingSubjectName && meetingSubjectName.length">
        <ion-badge class="subject-badge" [style]="backgroundAsStyle(meetingSubjectColor)">{{ meetingSubjectName }}</ion-badge>
      </ng-container>
      <ng-container *ngFor="let frequencyDate of frequencyDates">
        <div class="content">
          <ion-text>
            {{ frequencyDate.start_date | dateLocale: 'shortTime' }}-{{ frequencyDate.end_date | dateLocale: 'shortTime' }} {{ frequencyDate.start_date | dateLocale: 'd.MM.yyyy' }}
          </ion-text>
          <ion-text>
            {{ frequencyDate.start_date | dateLocale: '(EEEE)' }}&nbsp;{{ getFrequencyText(frequencyDate.frequency, frequencyDate.step) }}
          </ion-text>
        </div>
      </ng-container>
      <ng-container *ngIf="showButtons$ | async">
        <div class="buttons">
          <ion-button class="secondary font-extra-bold" mode="md" slot="end" [disabled]="isSubmitted" (click)="handleMeetingAttendee(meetingAttendeeOperations.DECLINED)">
            {{ 'common.btn-decline' | translate }}
          </ion-button>
          <ion-button class="primary font-extra-bold ion-margin-vertical" mode="md" slot="end" [disabled]="isSubmitted" (click)="handleMeetingAttendee(meetingAttendeeOperations.ACCEPTED)">
            {{ 'common.btn-accept' | translate }}
          </ion-button>
        </div>
      </ng-container>
    </ion-card-content>
  </ion-card>
</div>
