import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { JoinInputModule } from '../join-input/join-input.module';
import { TreeMenuModule } from '../tree-menu/tree-menu.module';
import { CreateMenuModule } from '../create-menu/create-menu.module';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { InstantMeetCopyWidgetModule } from '../instant-meet-copy-widget/instant-meet-copy-widget.module';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    JoinInputModule,
    TreeMenuModule,
    CreateMenuModule,
    StyledScrollbarModule,
    InstantMeetCopyWidgetModule,
  ],
  exports: [MenuComponent],
})
export class MenuModule { }
