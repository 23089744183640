import { AfterContentInit, ChangeDetectorRef, ContentChild, Directive, ElementRef, Input, OnDestroy, TemplateRef } from "@angular/core";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { createMutationObserver } from "../../helpers/helpers";
import { IChatMessageAttachment } from "../../models/chat";
import { RenderAttachmentsOutletDirective } from "./render-attachments-outlet.directive";

interface LinksCtx { 
  images: IChatMessageAttachment[], 
  files: IChatMessageAttachment[],
  id: string,
}

@Directive({
  selector: '[renderAttachment]'
})
export class RenderAttachmentDirective implements AfterContentInit, OnDestroy {
  @Input() renderAttachment: boolean = false;
  @Input() renderAttachmentId: string = null;
  @Input() renderAttachmentTemplate: TemplateRef<any> = null;

  @ContentChild(RenderAttachmentsOutletDirective, { static: false })
  public renderAttachmentsOutlet: RenderAttachmentsOutletDirective;

  private destroy$ = new Subject();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterContentInit() {
    if (this.renderAttachment && this.renderAttachmentsOutlet) {
      const contentElem = this.elementRef.nativeElement.querySelector<HTMLElement>('.message-content');

      createMutationObserver(contentElem, { childList: true })
        .pipe(
          first(() => {
            const target = contentElem.querySelector<HTMLElement>('[attr-data-msg-attachments]');
            return target != null;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.parseAndRenderAttachments()
        })
    }
  }
  
  private parseAndRenderAttachments() {
    const imageExtensions = ['bmp', 'jpg', 'jpeg', 'png', 'gif'];
    const target = this.elementRef.nativeElement.querySelector<HTMLElement>('[attr-data-msg-attachments]');
    
    if (target != null && target.innerText.includes('/user_uploads/')) {
      const ctxPayload: LinksCtx = {
        images: [],
        files: [],
        id: this.renderAttachmentId
      };
      const linksCtx: LinksCtx = target.innerText.trim().split('§§')
        .map(link => ({
          link,
          name: link.split('/').pop(),
          isImage: imageExtensions.includes(link.split('.').pop()),
        }))
        .reduce(
          (accum, entry) => {
            accum[entry.isImage ? 'images' : 'files'].push(entry);
            return accum;
          },
          ctxPayload
        );
      
      linksCtx.files.sort((a, b) => a.name > b.name ? -1 : 1);
      linksCtx.images.sort((a, b) => a.name > b.name ? -1 : 1);

      this.cdr.detach();
      this.renderAttachmentsOutlet.viewContainerRef.clear();
      this.renderAttachmentsOutlet.viewContainerRef.createEmbeddedView(
        this.renderAttachmentTemplate,
        { $implicit: linksCtx }
      );
      this.cdr.reattach();
      this.cdr.markForCheck();
    }
  }

}