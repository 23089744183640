/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./whiteboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../pipes/safe-url.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./whiteboard.component";
import * as i8 from "../../../auth/services/auth.service";
import * as i9 from "../../services/whiteboard.service";
import * as i10 from "../../services/toast.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../services/download-locally.service";
import * as i13 from "../../services/file-attach.service";
import * as i14 from "../../services/platform.service";
import * as i15 from "../../services/socket.service";
import * as i16 from "@ionic/storage";
import * as i17 from "../../services/chat-toggle.service";
import * as i18 from "@ngrx/store";
var styles_WhiteboardComponent = [i0.styles];
var RenderType_WhiteboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WhiteboardComponent, data: {} });
export { RenderType_WhiteboardComponent as RenderType_WhiteboardComponent };
function View_WhiteboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-card", [["class", "loader-card"], ["color", "light"]], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "ion-card-content", [], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(3, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(6, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Loading"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ion-spinner", [["name", "crescent"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(9, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "light"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "crescent"; _ck(_v, 9, 0, currVal_1); }, null); }
function View_WhiteboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-button", [["class", "close-button"], ["color", "light"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["class", "ico icon-plus"], ["slot", "icon-only"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null)], function (_ck, _v) { var currVal_0 = "light"; var currVal_1 = "md"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_WhiteboardComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.SafeUrlPipe, [i5.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_WhiteboardComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_WhiteboardComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [["iframe", 1]], null, 1, "iframe", [["allow", "camera; microphone"], ["frameborder", "0"], ["sandbox", "allow-scripts allow-same-origin allow-popups allow-downloads allow-modals"]], [[8, "src", 5]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onLoad(i1.ɵnov(_v, 9)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(10, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.showLoading$)); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.showLoading$)); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.url)); _ck(_v, 9, 0, currVal_2); }); }
export function View_WhiteboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-whiteboard", [], null, null, null, View_WhiteboardComponent_0, RenderType_WhiteboardComponent)), i1.ɵdid(1, 245760, null, 0, i7.WhiteboardComponent, [i8.AuthService, i9.WhiteboardService, i10.ToastService, i11.TranslateService, i12.DownloadLocallyService, i13.FileAttachService, i14.PlatformService, i15.SocketService, i16.Storage, i17.ChatToggleService, i18.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WhiteboardComponentNgFactory = i1.ɵccf("app-whiteboard", i7.WhiteboardComponent, View_WhiteboardComponent_Host_0, { url: "url", attachment: "attachment", ticketParent: "ticketParent", parentTicketIdForMaterialTicket: "parentTicketIdForMaterialTicket", saveToChat: "saveToChat", onDismiss: "onDismiss" }, {}, []);
export { WhiteboardComponentNgFactory as WhiteboardComponentNgFactory };
