<ion-list lines="none" class="channels-list">
  <ion-list-header>
    <ion-label>{{ 'chat.nav_channels_subtitle' | translate }}</ion-label>
  </ion-list-header>
  <ion-item
    *ngFor="let entry of channels$ | async; trackBy: trackBy"
    class="channels-list-item"
    [attr.title]="entry.title">
    <ion-text
      (click)="select.next(entry.stream_id)"
      class="channel-name"
      [class.highlight-unread]="entry.isUnread">
      #&nbsp;{{ entry.title }}
    </ion-text>
    <span *ngIf="entry.isUnread" class="bullet">&nbsp;&bull;</span>
    <ion-text class="channel-counter">({{ entry.subscribers?.length }}&nbsp;
      <ng-container *ngIf="entry?.subscribers?.length === 1; else multiMembersTempl">{{ 'chat.member_single' | translate }}</ng-container>
      <ng-template #multiMembersTempl>{{ 'chat.member_multi' | translate }}</ng-template>)
    </ion-text>
    <ion-button *ngIf="displayInfoBtn" (click)="showDetails.next(entry.stream_id)" class="info-btn" fill="clear" slot="end">i</ion-button>
  </ion-item>
</ion-list>
<ion-list lines="none" class="dms-list">
  <ion-list-header>
    <ion-label>{{ 'chat.nav_dms_subtitle' | translate }}</ion-label>
    <ion-button (click)="create.next()">
      <ion-icon name="add-circle" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-list-header>
  <ng-container *ngFor="let entry of conversations$ | async; trackBy: trackByStreamId">
    <app-chat-conversation-item
      [title]="entry.title"
      [members]="entry.members"
      [isUnread]="entry.isUnread"
      *ngIf="!entry.hide || entry.isUnread"
      lines="none"
      class="pm-conversation-entry"
      (click)="select.next(entry.stream_id)">
      <ion-button
        (click)="handleOpenConference($event, entry.stream_id)"
        class="camera-btn" fill="clear" slot="end">
        <ion-icon src="/assets/img/camera-ic.svg" size="small" class="camera-icon" slot="icon-only"></ion-icon>
      </ion-button>
    </app-chat-conversation-item>
  </ng-container>
</ion-list>
