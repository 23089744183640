import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loaderComponent: HTMLIonLoadingElement = null;

  constructor(
    private loadingController: LoadingController,
    private translateService: TranslateService,
  ) { }

  public async startLoading(messageKey?: string): Promise<void> {
    if (!this.loaderComponent) {
      this.loaderComponent = await this.loadingController.create({
        message: this.translateService.instant(messageKey ? messageKey : 'common.wait'),
        cssClass: 'custom-loading',
      });
      return await this.loaderComponent.present();
    }
  }

  public async finishLoading(): Promise<void> {
    if (this.loaderComponent) {
      return await this.loaderComponent.dismiss()
        .then(() => this.loaderComponent = null);
    }
  }
}
