import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {IClass} from '../models/class';
import {
  EntityType,
  EventType,
  IChangeClassSchoolPayload,
  IDashboard,
  IDashboardColumn,
  INotifyUpdatePayload,
  UserRole,
  UserTypes
} from '../models/common';
import {HttpService} from './http.service';
import {forkJoin, Observable} from 'rxjs';
import {IResponse} from '../models/response';
import {map, share, tap} from 'rxjs/operators';
import {addDashboardColumn$, editDashboardColumn$, removeDashboardColumn$} from '../helpers/helpers';
import {UserModel} from '../models/user/user';
import {ITicket} from '../models/ticket';
import {InviteService} from './invite.service';
import {SocketService} from './socket.service';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ClassService extends BaseService<IClass> {

  private readonly CLASS_URL: string = 'v1/classes';

  protected entityType: EntityType = EntityType.CLASS;
  protected GET_ALL: string = this.CLASS_URL;
  protected GET_ALL_TO_TEACH: string = `${this.CLASS_URL}/teach`;
  protected GET_ALL_TO_STUDY: string = `${this.CLASS_URL}/study`;
  protected POST_ONE: string = this.CLASS_URL;
  protected PUT_ONE: string = this.CLASS_URL;
  protected PATCH_ONE: string = this.CLASS_URL;
  protected PATCH_ONE_DASHBOARD_COLUMN: string = 'v1/columns';
  protected PATCH_CHANGE_SCHOOL = `v1/classes/change-school`;
  protected GET_ONE: (id: string) => string = (id: string) => `${this.CLASS_URL}/${id}`;
  protected GET_ALL_BY_USER = (id: string, schoolId: string, type: 'teacher' | 'student') => `${this.CLASS_URL}/by-user?userId=${id}&type=${type}&schoolId=${schoolId}`;
  protected GET_ALL_BY_SCHOOL: (id: string) => string = (id: string) => `${this.CLASS_URL}/by-school/${id}`;
  protected GET_CLASS_CARD_DATA: (id: string) => string = (id: string) => `${this.CLASS_URL}/card-data/${id}`;
  protected DELETE_ONE: (id: string) => string = (id: string) => `${this.CLASS_URL}/${id}`;
  protected GET_DASHBOARD_BY_CLASS = (id: string) => `${this.CLASS_URL}/dashboard/${id}`;
  protected GET_DASHBOARD_DATA_BY_CLASS_INIT = (id: string, limitByColumn: number) => `${this.CLASS_URL}/dashboard-data/${id}/init?limitByColumn=${limitByColumn}`;
  protected GET_DASHBOARD_DATA_BY_CLASS_BY_PAGE = (id: string, page: number, limit: number, columnId: string, selectedDate: string) => `${this.CLASS_URL}/dashboard-data/${id}?page=${page}&limit=${limit}&columnId=${columnId}&selectedDate=${selectedDate}`;
  protected DELETE_ONE_DASHBOARD_COLUMN: (id: string) => string = (id: string) => `v1/columns/${id}`;

  protected GET_TIMELINE_DATES_BY_COLUMN = (columnId: string, classId: string) => `v1/school-class-tickets/timeline-dates?columnId=${columnId}&schoolClassId=${classId}`;
  protected GET_TIMELINE_DATES_BY_ALL_COLUMNS = (classId: string) => `v1/school-class-tickets/timeline-dates-by-all-columns?schoolClassId=${classId}`;
  protected GET_TICKETS_NEAR_DATE_BY_COLUMN = (classId: string, columnId: string, selectedDate: string) => `v1/school-class-tickets/near-selected-date?schoolClassId=${classId}&columnId=${columnId}&selectedDate=${selectedDate}`;

  constructor(
    protected httpService: HttpService,
    protected toastService: ToastService,
    private inviteService: InviteService,
    private socketService: SocketService,
  ) {
    super(httpService, toastService);

    this.socketService.getCrudMessages$([EntityType.CLASS])
      .subscribe(payload => this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId }));
  }

  getAllTeach$(): Observable<IResponse<IClass[]>> {
    return this.httpService.get$<IClass[]>(this.GET_ALL)
      .pipe(share());
  }

  getAllStudy$(): Observable<IResponse<IClass[]>> {
    return this.httpService.get$<IClass[]>(this.GET_ALL_TO_STUDY)
      .pipe(share());
  }

  getDashboardByClassId$(id: string): Observable<IResponse<IDashboard>> {
    return this.httpService.get$(this.GET_DASHBOARD_BY_CLASS(id));
  }

  addDashboardColumn$(column: IDashboardColumn, classId: string): Observable<IResponse<IDashboardColumn>> {
    return addDashboardColumn$(column, classId, EntityType.CLASS, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.ADD_COLUMN, response, { entityId: classId })));
  }

  editDashboardColumn$(column: IDashboardColumn, classId: string): Observable<IResponse<IDashboardColumn>> {
    return editDashboardColumn$(column, EntityType.CLASS, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.EDIT_COLUMN, response, { entityId: classId })));
  }

  removeDashboardColumn$(column: IDashboardColumn, classId: string): Observable<IResponse<IDashboardColumn>> {
    return removeDashboardColumn$(column.id, EntityType.CLASS, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.REMOVE_COLUMN, response, { entityId: classId })));
  }

  getDashboardTicketsInit$(classId: string, limitByColumn: number): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_CLASS_INIT(classId, limitByColumn));
  }

  getDashboardTicketsByPage$(classId: string, page: number, limit: number, columnId: string, selectedDate: string): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_CLASS_BY_PAGE(classId, page, limit, columnId, selectedDate));
  }

  getDashboardUsers$(schoolClass: IClass, page: number, limit: number): Observable<IResponse<UserModel[]>> {
    return forkJoin([
      this.inviteService.getUsers$('', UserTypes.TEACHER, [{ entityId: schoolClass.id, entityType: EntityType.CLASS }], page, limit, false),
      this.inviteService.getUsers$('', UserTypes.STUDENT, [{ entityId: schoolClass.id, entityType: EntityType.CLASS }], page, limit, false),
    ])
    .pipe(
      map(responses => {
        for (const response of responses) {
          if (response.error) {
            return { error: response.error, message: response.message };
          }
        }

        return {
          payload: [
            ...responses[0].payload.map(user => ({ ...user, role: UserRole.TEACHER })),
            ...responses[1].payload.map(user => ({ ...user, role: UserRole.STUDENT })),
          ],
        };
      }),
    );
  }

  getAllByUserId$(userId: string, schoolId?: string): Observable<IResponse<IClass[]>> {
    return this.httpService.get$(this.GET_ALL_BY_USER(userId, schoolId, 'teacher'));
  }

  getAllBySchoolId$(schoolId: string): Observable<IResponse<IClass[]>> {
    return this.httpService.get$(this.GET_ALL_BY_SCHOOL(schoolId));
  }

  getClassCardData$(classId: string): Observable<IResponse<any>> {
    return this.httpService.get$(this.GET_CLASS_CARD_DATA(classId));
  }

  changeSchool$(payload: IChangeClassSchoolPayload): Observable<IResponse<IClass>> {
    return this.httpService.patch$(this.PATCH_CHANGE_SCHOOL, payload);
  }

  getTimelineDatesByColumn$(
    columnId: string,
    schoolClassId: string,
  ): Observable<IResponse<string[]>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_COLUMN(columnId, schoolClassId));
  }

  getTimelineDatesByAllColumns$(
    schoolClassId: string,
  ): Observable<IResponse<{ [columndId: string]: string[] }>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_ALL_COLUMNS(schoolClassId));
  }

  getTicketsNearDateByColumn$(
    schoolId: string,
    columnId: string,
    selectedDate: string,
  ): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_TICKETS_NEAR_DATE_BY_COLUMN(schoolId, columnId, selectedDate));
  }

  protected notifyAboutUpdateColumn(eventType: EventType, response: IResponse<IClass>, payload: INotifyUpdatePayload) {
    if (response.error || !this.socketService.isConnected()) {
      this.notifyAboutUpdate(eventType, response, payload);
    }
  }
}
