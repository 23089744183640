import { createAction, props } from '@ngrx/store';
import { ChatMember, IChatMessageAttachment, IChatStream, IMessage, IMessageId, IStreamId, IStreamMessagesAnchor, MarkMessageReadEntry, NewMessageStatus } from 'src/app/shared/models/chat';
import { UserModel } from 'src/app/shared/models/user/user';

export const noop = createAction('no-op');

export const resetChatState = createAction(
  `[chat] reset chat state`
)

export const init = createAction(
  `[chat] init`,
  props<{ streams: IChatStream[] }>()
)

export const initChatStreams = createAction(
  `[chat] init chat streams`,
  props<{ streams: IChatStream[] }>()
)

export const loadSingleChatStream = createAction(
  `[chat] load single chat stream`,
  props<{ streamId: IStreamId }>()
)

export const loadRemoteMessages = createAction(
  `[chat] load remote messages`,
  props<{ streamId: IStreamId, anchor?: IMessageId, fetchAll?: boolean }>()
)

export const loadRemoteMessagesSuccess = createAction(
  `[chat] load remote messages success`,
  props<{ streamId: IStreamId, data: IMessage[], anchor: IStreamMessagesAnchor }>()
)

export const loadRemoteMessagesFailure = createAction(
  `[chat] load remote messages failure`,
  props<{ streamId: IStreamId, error: { msg: string; code: string } }>()
)

export const setMessage = createAction(
  `[chat] set message`,
  props<{ streamId: IStreamId, message: IMessage }>()
)

export const sendMessage = createAction(
  `[chat] send message`,
  props<{ streamId: IStreamId, tempMessageId: string, content: string, timestamp: number }>()
)

export const setDraftMessage = createAction(
  `[chat] set draft message`,
  props<{ streamId: IStreamId, message: Partial<IMessage> }>()
)

export const updateDraftMessage = createAction(
  `[chat] update draft message`,
  props<{ draftId: IMessageId, status?: NewMessageStatus, id?: IMessageId }>()
)

export const removeDraftMessage = createAction(
  `[chat] remove draft message`,
  props<{ draftId: IMessageId }>()
)

export const sendMessageSuccess = createAction(
  `[chat] send message success`,
  props<{ streamId: IStreamId, messageId: IMessageId }>()
)

export const sendMessageFailure = createAction(
  `[chat] send message failure`,
  props<{ streamId: IStreamId, messageId: IMessageId, error: { msg: string, code: string } }>()
)

export const loadChatStreams = createAction(
  `[chat] load chat streams`,
  props<{ streams: IStreamId[] }>()
)

export const loadChatStreamsSuccess = createAction(
  `[chat] load chat streams success`,
  props<{ streams: IChatStream[] }>()
)

export const loadChatStreamsFailure = createAction(
  `[chat] load chat streams failure`,
  props<{ error: { msg: string; code: string } }>()
)

export const updateChatStreamData = createAction(
  `[chat] update chat stream data`,
  props<{ streamId: IStreamId, data: Partial<IChatStream> }>()
)

export const batchUpdateChatStreamData = createAction(
  `[chat] batch update chat stream data`,
  props<{ streams: Array<Pick<IChatStream, 'stream_id'> & Partial<IChatStream>> }>()
)

export const setChatStreamFirstUnread = createAction(
  `[chat] set chat stream first unread`,
  props<{ streamId: IStreamId, firstUnread: IMessageId }>()
)

export const maybeUpdateChatStreamFirstUnread = createAction(
  `[chat] maybe update chat stream first unread`,
  props<{ messageId: IMessageId }>()
)

export const markMessagesRead = createAction(
  `[chat] mark messages read`,
  props<{ messages: MarkMessageReadEntry[] }>()
)

export const markAllMessagesRead = createAction(
  `[chat] mark all messages read`,
  props<{ streamId: IStreamId }>()
)

export const markAllMessagesReadSuccess = createAction(
  `[chat] mark all messages read success`,
  props<{ streamId: IStreamId }>()
)

export const markAllMessagesReadFailure = createAction(
  `[chat] mark all messages read failure`,
  props<{ streamId: IStreamId, error: any }>()
)

export const addMarkMessagesRead = createAction(
  `[chat] add mark messages read`,
  props<{ messages: MarkMessageReadEntry[] }>()
)

export const markMessagesReadFailure = createAction(
  `[chat] mark messages read failure`,
  props<{ messages: MarkMessageReadEntry[] }>()
)

export const finalizeMarkMessagesRead = createAction(
  `[chat] finalize mark messages read`,
  props<{ messages: IMessageId[] }>()
)

export const markMessagesReadSuccess = createAction(
  `[chat] mark messages read success`,
  props<{ messages: IMessageId[] }>()
)

export const loadUserDataById = createAction(
  '[chat] load user data by id',
  props<{ id: number }>()
)

export const loadUserDataFailed = createAction(
  '[chat] load user data failed',
  props<{ error: any, uid: number }>()
)

export const loadUserDataSuccess = createAction(
  '[chat] load user data success',
  props<{ data: ChatMember, uid: number }>()
)

export const batchLoadChatUsersSuccess = createAction(
  '[chat] batch load chat users success',
  props<{ payload: ChatMember[] }>()
)

export const batchLoadChatUsersFailed = createAction(
  '[chat] batch load chat users failed',
  props<{ error: any }>()
)

export const unloadChatStream = createAction(
  '[chat] unload chat stream',
  props<{ streams: IStreamId[] }>()
)

export const addChatStreamSubscriber = createAction(
  '[chat] add chat stream subscriber',
  props<{ streamId: IStreamId, uid: number }>()
)

export const addChatStreamSubscriberWhenLoaded = createAction(
  '[chat] add chat stream subscriber when loaded',
  props<{ streamId: IStreamId, uid: number }>()
)

export const removeChatStreamSubscriber = createAction(
  '[chat] remove chat stream subscriber',
  props<{ streamId: IStreamId, uid: number }>()
)

export const removeChatStreamSubscriberWhenLoaded = createAction(
  '[chat] remove chat stream subscriber when loaded',
  props<{ streamId: IStreamId, uid: number }>()
)

export const maybeUpdateUsersPresence = createAction(
  '[chat] maybe update users presence if changed and loaded',
  props<{ payload: Array<{ uid: number, online: boolean }> }>()
)

export const updateUserPresence = createAction(
  '[chat] update user presence',
  props<{ uid: number, online: boolean }>()
)

export const updateUsersPresence = createAction(
  '[chat] update users presence',
  props<{ payload: Array<{ uid: number, online: boolean }> }>()
)

export const loadChatStreamAttachments = createAction(
  '[chat] load chat stream attachments',
  props<{ streamId: IStreamId, anchor: 'newest' | IMessageId, count?: number }>()
)

export const loadChatStreamAttachmentsSuccess = createAction(
  '[chat] load chat stream attachments success',
  props<{ streamId: IStreamId, payload: IChatMessageAttachment[], loaded: boolean }>()
)

export const loadChatStreamAttachmentsFailure = createAction(
  '[chat] load chat stream attachments failure',
  props<{ streamId: IStreamId, error: any }>()
)

export const setChatStreamAttachmentsLoaded = createAction(
  '[chat] set chat stream attachments loaded',
  props<{ streamId: IStreamId, loaded: boolean }>()
)
