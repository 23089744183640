import { EntityType } from './common';
import { UserModel } from './user/user';

export enum ChannelType {
  Channel = 'channel',
  Direct = 'pm',
}

export enum UserPresenceStatus {
  Active = 'active',
  Idle = 'idle'
}

export interface NarrowEntry {
  operator: string,
  operand: any,
  negated?: boolean
}

type IMessageFlag
  = 'read'
  | 'starred'
  | 'collapsed'
  | 'mentioned'
  | 'wildcard_mentioned'
  | 'has_alert_word'
  | 'historical';

export interface IZulipResponse {
  msg: string;
  result: 'success' | 'error';
  code?: string;
}

export interface IZulipUserId {
  user_id: number;
}

export interface IZulipUser extends IZulipUserId {
  avatar_url: string;
  bot_type?: string;
  date_joined: string;
  email: string;
  full_name: string;
  is_active: boolean;
  is_admin: boolean;
  is_bot: boolean;
  is_guest: boolean;
  is_owner: boolean;
  profile_data?: Record<string, { value: any }>;
  timezone: string;
}

interface Profiledata {
}

export type IStreamId = number;
export type IMessageId = number | string;
export type ChatMember = UserModel & {
  fullName?: string,
  firstname?: string,
  lastname?: string,
  online?: boolean,
  zulip_uid?: number,
};

export interface IChatStream {
  name: string;
  title?: string;
  stream_id: IStreamId;
  description: string;
  rendered_description: string;
  invite_only: boolean;
  is_web_public: boolean;
  stream_post_policy: number;
  history_public_to_subscribers: boolean;
  first_message_id: number;
  message_retention_days?: any;
  date_created: number;
  last_message_id: IMessageId | null;
  hide?: boolean;
  color: string;
  is_muted: boolean;
  pin_to_top: boolean;
  audible_notifications?: any;
  desktop_notifications?: any;
  email_notifications?: any;
  push_notifications?: any;
  wildcard_mentions_notify?: any;
  role: number;
  in_home_view: boolean;
  is_announcement_only: boolean;
  stream_weekly_traffic: number;
  email_address: string;
  meta?: {
    type: 'channel' | 'pm',
    entityType?: EntityType,
    title?: string
  };
  subscribers?: any[];  // FIXME: should be number[]
  members?: ChatMember[];
  server_first_unread?: IMessageId;
}

export interface IMessage {
  avatar_url: string;
  client: string;
  content: string;
  content_type: string;
  display_recipient: IDisplayRecipient[];
  flags: IMessageFlag[];
  id: IMessageId;
  is_me_message: boolean;
  local_id?: IMessageId;
  reactions: any[];
  recipient_id: number;
  sender_email: string;
  sender_full_name: string;
  sender_id: number;
  sender_realm_str: string;
  stream_id: number;
  subject: string;
  submessages: any[];
  timestamp: number;
  topic_links: any[];
  type: string;
  hasAttachments?: boolean;
}

export interface IDisplayRecipient {
  email: string;
  full_name: string;
  id: number;
  is_mirror_dummy: boolean;
}

export type IStreamMessagesAnchor = string | number;

export type IGetMessagesResponse = IZulipResponse & {
  anchor: IStreamMessagesAnchor;
  found_anchor: boolean;
  found_oldest: boolean;
  found_newest: boolean;
  messages: IMessage[];
};

export interface IRecentPrivateConversation {
  max_message_id: number;
  user_ids: number[];
}

export interface IPresence {
  client: string;
  status: string;
  timestamp: number;
  pushable?: boolean;
}

export interface IStreamUnreads {
  stream_id: number;
  topic: string;
  unread_message_ids: number[];
  sender_ids: number[];
}

export type IRegisterEventQueueResponse = IZulipResponse & {
  queue_id: string;
  last_event_id: number;
  zulip_version: string;
  zulip_feature_level: number;
  max_message_id: number;
  presences?: Record<string, Record<string, IPresence>>;
  subscriptions?: IChatStream[];
  unsubscribed?: any[];
  never_subscribed?: any[];
  recent_private_conversations?: IRecentPrivateConversation[];
  unread_msgs?: {
    pms: any[];
    streams: IStreamUnreads[];
    huddles: any[];
    mentions: any[];
    count: number;
  }
}

export interface IChatUpdateHeartbeatEvent {
  type: 'heartbeat';
  id: number;
}

export interface IChatUpdateMessageFlagsEvent {
  type: 'update_message_flags';
  op: 'add' | 'remove';
  operation?: 'add' | 'remove';
  flag: IMessageFlag;
  messages: IMessageId[];
  all: boolean;
  id: number;
}

export interface ISubscriptionPeerEvent {
  type: 'subscription';
  id: number;
  op: 'peer_add' | 'peer_remove';
  stream_id: IStreamId;
  user_id: number;
}

export interface ISubscriptionEvent {
  type: 'subscription';
  id: number;
  op: 'add' | 'remove';
  subscriptions: IChatStream[]
}

export interface IChatUpdateMessageEvent {
  message?: IMessage;
  flags?: IMessageFlag[];
  type: 'message';
  id: number;
  local_message_id?: IMessageId
}

export type IZulipUserPresence = { 'active_timestamp': number } | { 'idle_timestamp': number };

export interface IUserPresenceEvent {
  type: 'presence';
  id: number;
  user_id: number;
  email: string;
  server_timestamp: number;
  presence: IZulipUserPresence;
}

export type IChatUpdateEvent
  = IChatUpdateHeartbeatEvent
  | ISubscriptionEvent
  | ISubscriptionPeerEvent
  | IChatUpdateMessageEvent
  | IChatUpdateMessageFlagsEvent
  | IUserPresenceEvent;

export type IEventsResponse = IZulipResponse & {
  events: IChatUpdateEvent[]
  handler_id?: number
  queue_id: string
}

export interface IZulipUsersPresence {
  [uid: number]: IZulipUserPresence
}

export type IZulipUpdatePresenceResponse = IZulipResponse & {
  presences?: IZulipUsersPresence
}

export enum NewMessageStatus {
  Draft,
  Sent,
  SendFailed,
}

export type INewMessage = {
  streamId: IStreamId;
  tempId: IMessageId;
  status: NewMessageStatus
  id: IMessageId | null;
};

export type MarkMessageReadEntry = { id: IMessageId, streamId: IStreamId };

export interface IChatMessageAttachment {
  link: string
  name: string
  createdAt?: number;
  dataUrl?: string
  messageId?: IMessageId
  senderId?: number,
  senderEmail?: string,
  isImage?: boolean,
}

export interface IChatUIEvent {

}

export class ChatRemovedEvent implements IChatUIEvent {
  constructor(public readonly streamId: IStreamId) {}
}

export class LoadChatAttachmentsSuccessEvent implements IChatUIEvent {
  constructor(
    public readonly streamId: IStreamId,
    public readonly payload: IChatMessageAttachment[],
  ) {}
}
