import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveContentSizingDirective } from './remove-content-sizing.directive';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [ RemoveContentSizingDirective ],
  exports: [ RemoveContentSizingDirective ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class RemoveContentSizingDirectiveModule { }
