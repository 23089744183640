import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatToggleService {
  private openChatNotifier$: Subject<void> = new Subject();
  public get openChatNotifier() {
    return this.openChatNotifier$.asObservable();
  }

  public openChat$(): void {
    this.openChatNotifier$.next();
  }
}