import { Injectable } from '@angular/core';
import 'firebase/remote-config';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  private remoteConfig;
  constructor(
  ) {}

  initialize() {
    const defaultRemoteConfig = firebase.remoteConfig();
    defaultRemoteConfig.fetchAndActivate().then(() => {
      this.remoteConfig = defaultRemoteConfig.getAll();
    });
  }

  get showDonateBanner(): boolean {
    return this.remoteConfig && this.remoteConfig.show_donate_banner ? this.remoteConfig.show_donate_banner.asBoolean() : false;
  }

}
