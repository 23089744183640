<div class="content" [@slideInOut]="sidebarState$ | async">
  <ion-header class="ion-no-border">
    <ion-toolbar mode="md">
      <ion-title>
        {{ 'notifications.title' | translate }}
      </ion-title>
      <ion-buttons slot="end">
        <app-notification-button></app-notification-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content style="--background: transparent;" styledScrollbar="shadow-root">
    <ng-container *ngIf="(unreadCount$ | async) as unreadCount">
      <div [hidden]="!(unreadCount > 0)" class="mark-all-container">
        <ion-button fill="clear" class="link" (click)="markAllAsRead()">{{ 'notifications.mark-all-as-read' | translate }}</ion-button>
      </div>
    </ng-container>
    <ion-list lines="none" styledScrollbarContent>
      <ng-container *ngIf="(messages$ | async) as messages; else loadingMessages">
        <app-notification-message *ngFor="let msg of messages; trackBy:trackByFn" [message]="msg" [isOpen]="isOpened" (read)="readMessage($event)" (closeSidebar)="toggle()"></app-notification-message>
      </ng-container>
      <ng-template #loadingMessages>
        <ion-item lines="none">
          <ion-spinner color="primary" style="margin: 0 auto" name="crescent"></ion-spinner>
        </ion-item>
      </ng-template>
    </ion-list>
    <ion-infinite-scroll threshold="200px" (ionInfinite)="loadMoreData($event)">
      <ion-infinite-scroll-content color="primary" loadingSpinner="crescent">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-content>
</div>
