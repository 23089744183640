export interface ICalendarDate {
    mDate: moment.Moment;
    selected?: boolean;
    today?: boolean;
}

export interface ICalendarDatePeriodValue {
    startDate: string;
    endDate: string;
}

export interface ICalendarDatePeriod {
    startDate: moment.Moment;
    endDate: moment.Moment;
    selected?: boolean;
}

export enum ICalendarDisplay {
    BUTTON_WITH_TEXT = 'button-with-text',
    INLINE = 'inline',
}
export enum ICalendarType {
    DATE = 'date',
    DATE_AND_TIME = 'date-and-time',
    DATE_PERIOD = 'date-period'
}
