import { INotificationMessage, IBaseNotification, IAssignedTaskNotification,
  ITicketTaskNotification, NotificationType, ITicketTaskDueDateChanged, ITicketBaseNotification, ITicketAnnounceNotification,
} from 'src/app/shared/models/notifications';
import { TicketRouteService } from 'src/app/shared/services/ticket-route.service';
import { EntityType, TicketParent } from 'src/app/shared/models/common';
import { getTicketParentFromEntityType } from '../../ticket-item/helpers';
import { TicketType } from 'src/app/shared/models/ticket';
import { Router } from '@angular/router';

export function isOpenButtonVisible(message: INotificationMessage<IBaseNotification>): boolean {
  if ((message.payload as IAssignedTaskNotification | ITicketTaskNotification).is_deleted) {
    return false;
  }

  switch (message.type) {
    case NotificationType.ASSIGNED_TASK_MESSAGE:
    case NotificationType.ASSIGNED_TASK_STATE_CHANGE:
    case NotificationType.TICKET_TASK_CHANGED:
    case NotificationType.TICKET_TASK_PUBLISHED:
    case NotificationType.TICKET_TASK_STATE_CHANGED:
    case NotificationType.TICKET_TASK_DUE_DATE_CHANGED:
    case NotificationType.STUDENT_COMPLETE_TASK:
    case NotificationType.STUDENT_UPDATE_TASK:
    case NotificationType.TICKET_ANNOUNCE_PUBLISHED:
    case NotificationType.TICKET_TASK_DUE_DATE:
    case NotificationType.TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED:
    case NotificationType.MEETING_ATTENDEE_START_1_HOUR:
    case NotificationType.MEETING_ATTENDEE_START_5_MIN:
    case NotificationType.MEETING_ATTENDEE_START_10_MIN:
    case NotificationType.ADD_TICKET_TASK:
      return true;
    default:
      return false;
  }
}

export function openNotification(
  message: INotificationMessage<IBaseNotification>,
  ticketRouteService: TicketRouteService,
  router: Router,
): void {
  switch (message.type) {
    case NotificationType.ASSIGNED_TASK_STATE_CHANGE:
    case NotificationType.ASSIGNED_TASK_MESSAGE:
      openAssignedTask(message.payload.id, ticketRouteService);
      break;
    case NotificationType.TICKET_TASK_PUBLISHED:
    case NotificationType.TICKET_TASK_CHANGED:
    case NotificationType.TICKET_TASK_DUE_DATE:
      if ((message.payload as ITicketTaskNotification).assigned_task_id) {
        openAssignedTask((message.payload as ITicketTaskNotification).assigned_task_id, ticketRouteService);
      }
      break;
    case NotificationType.TICKET_TASK_DUE_DATE_CHANGED:
      if ((message.payload as ITicketTaskDueDateChanged).assigned_task_id) {
        openAssignedTask((message.payload as ITicketTaskDueDateChanged).assigned_task_id, ticketRouteService);
      } else {
        openTicket(message.payload.id, EntityType.TICKET_TASK, TicketParent.SUBJECT, ticketRouteService);
      }
      break;
    case NotificationType.TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED:
      openTicket(
        message.payload.id,
        (message.payload as ITicketBaseNotification).entity_type as EntityType,
        (message.payload as ITicketBaseNotification).parent_type as TicketParent,
        ticketRouteService,
      );
      break;
    case NotificationType.TICKET_ANNOUNCE_PUBLISHED:
      const ticketParent: TicketParent = getTicketParentFromEntityType((message.payload as ITicketAnnounceNotification).parent_type);
      const entityType: EntityType = (message.payload as ITicketAnnounceNotification).ticket_type === TicketType.GENERAL
        ? EntityType.TICKET_GENERAL
        : EntityType.TICKET_NOTIFICATION;
      openTicket(
        message.payload.id,
        entityType,
        ticketParent,
        ticketRouteService,
      );
      break;
    case NotificationType.TICKET_TASK_STATE_CHANGED:
    case NotificationType.STUDENT_COMPLETE_TASK:
    case NotificationType.STUDENT_UPDATE_TASK:
    case NotificationType.ADD_TICKET_TASK:
      openTicket(message.payload.id, EntityType.TICKET_TASK, TicketParent.SUBJECT, ticketRouteService);
      break;
    case NotificationType.MEETING_ATTENDEE_START_5_MIN:
    case NotificationType.MEETING_ATTENDEE_START_10_MIN:
    case NotificationType.MEETING_ATTENDEE_START_1_HOUR:
      openMeeting(message, router);
      break;
  }
}

function openAssignedTask(
  assignedTaskId: string,
  ticketRouteService: TicketRouteService,
): void {
  ticketRouteService.openTicket({
    ticketId: assignedTaskId,
    entityType: EntityType.ASSIGNED_TASK,
  });
}

function openTicket(
  ticketId: string,
  entityType: EntityType,
  ticketParent: TicketParent,
  ticketRouteService: TicketRouteService,
): void {
  ticketRouteService.openTicket({
    ticketId,
    entityType,
    ticketParent,
  });
}

function openMeeting(
  message: INotificationMessage<IBaseNotification>,
  router: Router,
): void {
  router.navigate(['pages', 'meetings'], {queryParams: {
    meetingId: message.payload.id,
    meetingDate: message.payload['meeting_start_date']
  }});
}
