import { HttpService } from './http.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { tap, map, delay, filter, exhaustMap, distinctUntilChanged, concatMap } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from './toast.service';
import { EntityType, SocketMessageType, EventType, ConfirmAction } from '../models/common';
import { SocketService } from './socket.service';
import { ConfirmationService } from './confirmation.service';
import { isSuccess, getFileReader } from '../helpers/helpers';
import { getTicketParentFromEntityType } from '../components/ticket-item/helpers';
import { UpdateNotificationHelperService } from './update-notification-helper.service';
import { FileType } from '../models/file-attach';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./toast.service";
import * as i3 from "./socket.service";
import * as i4 from "./confirmation.service";
import * as i5 from "./update-notification-helper.service";
export class FileAttachService {
    constructor(httpService, toastService, socketService, confirmationService, updateNotificationHelperService) {
        this.httpService = httpService;
        this.toastService = toastService;
        this.socketService = socketService;
        this.confirmationService = confirmationService;
        this.updateNotificationHelperService = updateNotificationHelperService;
        this.uploadEmitter$ = new Subject();
        this.queueEmitter$ = new Subject();
        this.removeEmitter$ = new Subject();
        this.notifierEmitter$ = new Subject();
        this.showFileUploadDialogEmitter$ = new BehaviorSubject(false);
        this.filesInProgress = [];
        this.POST_FILE = (shouldAddToChat) => `v1/upload-files?shouldAddToChat=${shouldAddToChat}`;
        this.GET_FILES = (id, type, parentType) => `v1/get-files?entityId=${id}&entityType=${type}&parentType=${parentType}`;
        this.REMOVE_FILE = (id, parentType) => `v1/attachments/${id}?parentType=${parentType}`;
        this.DOWNLOAD_FILE = (id, parentType) => `v1/download-file/${id}?parentType=${parentType}`;
        this.DOWNLOAD_PREVIEW = (id, parentType) => `v1/download-preview/${id}?parentType=${parentType}`;
        this.uploadEmitter$
            .pipe(filter(fileUpload => fileUpload && fileUpload.entityId && fileUpload.entityType && !!fileUpload.file), tap(fileUpload => {
            this.showFileUploadDialogEmitter$.next(true);
            this.addFileToInProgress(fileUpload.file);
        }), concatMap(fileUpload => this.uploadReq$(fileUpload).pipe(map(response => ({ response, fileUpload })))), tap(data => {
            const response = data.response;
            if (!isSuccess(response)) {
                this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
            }
            else {
                this.notifyAboutUpdate(data.fileUpload.entityId, EventType.ADD, response);
                const parentType = getTicketParentFromEntityType(data.fileUpload.parentType);
                this.updateNotificationHelperService.displayCanUpdateModal(parentType, data.fileUpload.entity, data.fileUpload.entityType);
            }
            this.removeFileFromInProgress(data.fileUpload.file);
        }), delay(1000), tap(() => {
            if (this.isFilesInProgressEmpty()) {
                this.showFileUploadDialogEmitter$.next(false);
            }
        }))
            .subscribe();
        this.removeEmitter$
            .pipe(exhaustMap(event => this.removeReq$(event).pipe(map(response => ({ response, event })))), tap(data => {
            const response = data.response;
            const event = data.event;
            if (response.error) {
                this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
            }
            else {
                this.notifyAboutUpdate(response.payload.ticket_id, EventType.REMOVE, response);
                const parentType = getTicketParentFromEntityType(event.parentType);
                this.updateNotificationHelperService.displayCanUpdateModal(parentType, event.entity, event.entityType);
            }
        }))
            .subscribe();
        this.socketService.messages$()
            .pipe(filter(message => message.type === SocketMessageType.CRUD), map(message => message.payload), filter(message => message.entityType === EntityType.FILE_ATTACH))
            .subscribe(message => this.notifyAboutUpdate(message.entityId, message.eventType, { payload: message.entity }, true));
    }
    notifier$() {
        return this.notifierEmitter$.asObservable();
    }
    uploadQueue$() {
        return this.queueEmitter$.asObservable();
    }
    showFileUploadDialog$() {
        return this.showFileUploadDialogEmitter$.asObservable()
            .pipe(distinctUntilChanged());
    }
    getFileAttaches$(entityId, entityType, parentType) {
        return this.httpService.get$(this.GET_FILES(entityId, entityType, parentType));
    }
    downloadFile$(file, parentType) {
        return this.httpService.get$(this.DOWNLOAD_FILE(file.id, parentType), { responseType: 'arraybuffer' })
            .pipe(map(response => response.payload ? response.payload : null));
    }
    downloadPreview$(file, parentType) {
        return this.httpService.get$(this.DOWNLOAD_PREVIEW(file.id, parentType), { responseType: 'blob' })
            .pipe(map(response => response.payload ? response.payload : null));
    }
    uploadAttachment(fileUpload, type) {
        if (fileUpload.entityId) {
            this.uploadEmitter$.next(fileUpload);
        }
        else {
            this.queueAttachment(fileUpload, type);
        }
    }
    removeAttachment(attachmentId, entity, entityType, parentType) {
        this.confirmationService.confirmAction(ConfirmAction.REMOVE_FILE)
            .then(res => {
            if (res.isConfirmed) {
                this.removeEmitter$.next({
                    attachmentId,
                    entity,
                    entityType,
                    parentType,
                });
            }
        });
    }
    uploadReq$(fileUpload) {
        const formData = new FormData();
        formData.append('files', fileUpload.file);
        formData.append('entity_id', fileUpload.entityId);
        formData.append('entity_type', fileUpload.entityType);
        formData.append('parent_type', fileUpload.parentType);
        return this.httpService.post$(this.POST_FILE(fileUpload.shouldAddToChat), formData);
    }
    removeReq$(fileRemove) {
        return this.httpService.delete$(this.REMOVE_FILE(fileRemove.attachmentId, fileRemove.parentType));
    }
    notifyAboutUpdate(id, eventType, response, isSocketMsg = false) {
        if (isSocketMsg || !isSuccess(response) || !this.socketService.isConnected()) {
            const event = {
                entityId: id,
                entityType: EntityType.FILE_ATTACH,
                eventType,
                response,
            };
            console.log(`%c notifyAboutUpdate`, 'color:coral', event);
            this.notifierEmitter$.next(event);
        }
    }
    addFileToInProgress(file) {
        this.filesInProgress.push(file);
    }
    removeFileFromInProgress(file) {
        const index = this.filesInProgress.findIndex(f => f.name === file.name);
        if (index !== -1) {
            this.filesInProgress.splice(index, 1);
        }
    }
    isFilesInProgressEmpty() {
        return this.filesInProgress.length === 0;
    }
    queueAttachment(fileUpload, type) {
        if (type === FileType.VIDEO) {
            const queueFileUpload = this.prepareFileUploadQueueObject(fileUpload, type, URL.createObjectURL(fileUpload.file));
            this.queueEmitter$.next(queueFileUpload);
            return;
        }
        const reader = getFileReader();
        reader.onload = (event) => {
            const queueFileUpload = this.prepareFileUploadQueueObject(fileUpload, type, event.target.result);
            this.queueEmitter$.next(queueFileUpload);
        };
        reader.readAsDataURL(fileUpload.file);
    }
    prepareFileUploadQueueObject(fileUpload, type, url) {
        return {
            name: fileUpload.file.name,
            url,
            type,
            request$: (id) => this.uploadReq$(Object.assign({}, fileUpload, { entityId: id })),
            created_on: new Date().toISOString(),
        };
    }
}
FileAttachService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileAttachService_Factory() { return new FileAttachService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.SocketService), i0.ɵɵinject(i4.ConfirmationService), i0.ɵɵinject(i5.UpdateNotificationHelperService)); }, token: FileAttachService, providedIn: "root" });
