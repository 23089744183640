import {
  INotificationMessage, IBaseNotification, NotificationType, ITicketTaskNotification,
  IStudentTaskNotification, IMeetingNotification, ITicketStateNotification,
  IAssignedTaskNotification, IAssignedTaskMessageNotification, ITicketAnnounceNotification,
  ITicketBaseNotification,
} from 'src/app/shared/models/notifications';
import { TicketParent } from 'src/app/shared/models/common';

export function getParentTitle(message: INotificationMessage<IBaseNotification>): string {
  switch (message.type) {
    case NotificationType.TICKET_TASK_STATE_CHANGED:
    case NotificationType.TICKET_TASK_PUBLISHED:
    case NotificationType.TICKET_TASK_DUE_DATE:
    case NotificationType.TICKET_TASK_CHANGED:
    case NotificationType.TICKET_TASK_DUE_DATE_CHANGED:
    case NotificationType.ASSIGNED_TASK_STATE_CHANGE:
    case NotificationType.STUDENT_COMPLETE_TASK:
    case NotificationType.STUDENT_UPDATE_TASK:
    case NotificationType.MEETING_ATTENDEE_START_SOON:
    case NotificationType.ASSIGNED_TASK_MESSAGE:
    case NotificationType.ADD_TICKET_TASK:
      type messageType = ITicketTaskNotification | ITicketStateNotification | IAssignedTaskNotification |
        IStudentTaskNotification | IMeetingNotification | IAssignedTaskMessageNotification;
      return (message.payload as messageType).subject_name;
    case NotificationType.TICKET_ANNOUNCE_PUBLISHED:
      return (message.payload as ITicketAnnounceNotification).parent_name;
    case NotificationType.TICKET_GENERAL_NOTIFICATION_MATERIAL_CHANGED:
      const msg: ITicketBaseNotification = message.payload as ITicketBaseNotification;
      if (msg.parent_type !== TicketParent.MATERIAL) {
        return (message.payload as ITicketBaseNotification).parent_name;
      }
      break;
    default:
      return '';
  }
  return '';
}

export function getParentColor(message: INotificationMessage<IBaseNotification>): string {
  switch (message.type) {
    case NotificationType.TICKET_TASK_STATE_CHANGED:
    case NotificationType.TICKET_TASK_PUBLISHED:
    case NotificationType.TICKET_TASK_DUE_DATE:
    case NotificationType.TICKET_TASK_CHANGED:
    case NotificationType.TICKET_TASK_DUE_DATE_CHANGED:
    case NotificationType.ASSIGNED_TASK_STATE_CHANGE:
    case NotificationType.STUDENT_COMPLETE_TASK:
    case NotificationType.STUDENT_UPDATE_TASK:
    case NotificationType.MEETING_ATTENDEE_START_SOON:
    case NotificationType.ASSIGNED_TASK_MESSAGE:
    case NotificationType.ADD_TICKET_TASK:
      type messageType = ITicketTaskNotification | ITicketStateNotification | IAssignedTaskNotification |
        IStudentTaskNotification | IMeetingNotification | IAssignedTaskMessageNotification;
      return (message.payload as messageType).subject_color;
    default:
      return '';
  }
  return '';
}
