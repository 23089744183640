import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { concat, Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { fileToBase64 } from 'src/app/shared/helpers/helpers';
import { IChatMessageAttachment } from 'src/app/shared/models/chat';
import { ChatAttachmentsService } from 'src/app/shared/services/chat-attachments.service';
import { ToastService, TOAST_TYPE } from 'src/app/shared/services/toast.service';
import { ZulipService } from 'src/app/shared/services/zulip.service';
import { ChatLogControllerService } from '../chat-log-controller.service';

type ImageAttachment = IChatMessageAttachment & { asyncDataUrl?: Observable<string> }

@Component({
  selector: 'app-message-attachments',
  templateUrl: './message-attachments.component.html',
  styleUrls: ['./message-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageAttachmentsComponent implements OnInit {
  @Input() messageId: string = null;
  @Input() files: IChatMessageAttachment[] = [];
  @Input() images: ImageAttachment[] = [];

  imageDataUrls: Record<string, string> = {};

  constructor(
    private zulipService: ZulipService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private chatLogController: ChatLogControllerService,
    private chatAttachmentsService: ChatAttachmentsService,
  ) { }

  ngOnInit() {
    const messageVisible$ = this.chatLogController.visibilityStateChange$.pipe(
      first(visibilityState => visibilityState[this.messageId])
    );
    const defaultSrc = 'assets/img/image-ico.svg';

    if (Array.isArray(this.images) && this.images.length > 0) {
      for (let image of this.images) {
        const remoteSrc$ = messageVisible$.pipe(
          switchMap(() => this.getFileDataUrl(image.link)),
          catchError(() => of(defaultSrc)),
        );

        image.asyncDataUrl = concat(of(defaultSrc), remoteSrc$);
      }
    }
  }

  downloadAttachment(event: Event, entry: IChatMessageAttachment) {
    event.stopPropagation();
    event.preventDefault();

    this.chatAttachmentsService.downloadFileAttachment(entry).catch(() => {
      const msg = this.translateService.instant('chat.attachment_download_error');
      this.toastService.showToast(msg, TOAST_TYPE.ERROR, 'bottom');
    });
  }

  downloadImageAttachment(imageDataUrl: string, name: string) {
    this.chatAttachmentsService.downloadImageAttachment(imageDataUrl, name);
  }

  previewImage(imageDataUrl: string, fileName: string) {
    if (this.isBase64Img(imageDataUrl)) {
      this.chatAttachmentsService.previewImage(imageDataUrl, fileName);
    }
  }

  private getFileDataUrl(link: string) {
    return this.zulipService.getFile(link)
      .toPromise()
      .then(res => fileToBase64(res));
  }

  private isBase64Img(imageDataUrl: string): boolean {
    return imageDataUrl.indexOf('base64') !== -1;
  }
}
