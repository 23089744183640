import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PopupBlockedNoticeComponent } from './popup-blocked-notice.component';
import { IonicModule } from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule],
  declarations: [PopupBlockedNoticeComponent],
  exports: [PopupBlockedNoticeComponent],
  entryComponents: [PopupBlockedNoticeComponent]
})
export class PopupBlockedNoticeModule {}
