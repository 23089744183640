import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { ParentChangeConfirmComponent } from './parent-change-confirm.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    StyledScrollbarModule,
  ],
  declarations: [ParentChangeConfirmComponent],
  entryComponents: [ParentChangeConfirmComponent],
  exports: [ParentChangeConfirmComponent],
})
export class ParentChangeConfirmModule {}
