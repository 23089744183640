<div class="content">
    <ion-content class="time-picker" styledScrollbar="shadow-root">
        <div>
            <ion-list lines="none" mode="md">
                <ion-item *ngFor="let timeInterval of timeIntervals$ | async" lines="none" mode="md" [class.selected]="isSelectedTimeInerval(timeInterval)" (click)="selectTime(timeInterval)">
                    <ion-label [class.time-diff]="canShowTimeDiff">{{ timeInterval | dateLocale: 'HH:mm' }} <span *ngIf="showTimeDiff(timeInterval)">({{ showTimeDiff(timeInterval) }})</span></ion-label>
                </ion-item>
            </ion-list>
        </div>
    </ion-content>
</div>