/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-members-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../pipes/is-chat-user-online/is-chat-user-online.pipe";
import * as i6 from "@ngrx/store";
import * as i7 from "./chat-members-list.component";
import * as i8 from "../../services/chat-ui.service";
var styles_ChatMembersListComponent = [i0.styles];
var RenderType_ChatMembersListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatMembersListComponent, data: {} });
export { RenderType_ChatMembersListComponent as RenderType_ChatMembersListComponent };
function View_ChatMembersListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-item", [["class", "member-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChat(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "ion-avatar", [["slot", "start"]], [[2, "online", null]], null, null, i2.View_IonAvatar_0, i2.RenderType_IonAvatar)), i1.ɵdid(3, 49152, null, 0, i3.IonAvatar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵppd(4, 1), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "img", [["alt", "avatar"]], [[1, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "ion-text", [["class", "name"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(8, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(9, 0, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit)))); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.avatar_url || "assets/img/boy-placeholder.svg"); _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.fullName; _ck(_v, 9, 0, currVal_2); }); }
export function View_ChatMembersListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.IsChatUserOnlinePipe, [i6.Store]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatMembersListComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.members; var currVal_1 = _co.trackById; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ChatMembersListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-members-list", [], null, null, null, View_ChatMembersListComponent_0, RenderType_ChatMembersListComponent)), i1.ɵdid(1, 49152, null, 0, i7.ChatMembersListComponent, [i8.ChatUiService], null, null)], null, null); }
var ChatMembersListComponentNgFactory = i1.ɵccf("app-chat-members-list", i7.ChatMembersListComponent, View_ChatMembersListComponent_Host_0, { members: "members" }, {}, []);
export { ChatMembersListComponentNgFactory as ChatMembersListComponentNgFactory };
