import { Injectable } from '@angular/core';
import { of as observableOf, Observable, of, throwError } from 'rxjs'
import {
  IMeetingType,
  IMeeting,
  IPastMeeting,
  MeetingUserModel,
  MeetingUserStatus,
  IMeetingCellMark,
  IMeetingUpdate,
  IMeetingUserCount
} from '../models/meeting';
import { BaseService } from './base.service';
import { HttpService } from './http.service';
import { EntityType, EventType, SocketMessageType, ICrudMessage } from '../models/common';
import { map, share, switchMap, tap } from 'rxjs/operators';
import { IResponse } from '../models/response';
import { UserModel } from '../models/user/user';
import { SocketService } from './socket.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingService extends BaseService<IMeeting> {
  private GET_MEETING_USERS_COUNT = `v1/meetings/users`;
  protected entityType: EntityType = EntityType.MEETING;
  protected GET_ALL: string = 'v1/meetings';
  protected POST_ONE: string = 'v2/meetings';
  protected PUT_ONE: string = 'v1/meetings';
  protected PATCH_ONE: string = 'v1/meetings';
  protected MEETINGS_TYPES: string = 'v1/meeting-type';
  protected PUT_ONE_V2 = (changeAll: boolean) => `v2/meetings?changeAll=${changeAll}`;
  protected GET_ONE: (id: string) => string = (id: string) => `v1/meetings/${id}`;
  protected GET_ONE_MEETING_TYPE: (id: string) => string = (id: string) => `v1/meetings/types/${id}`;
  protected DELETE_ONE: (id: string) => string = (id: string) => `v1/meetings/${id}`;
  protected DELETE_ONE_MEETING_TYPE: (id: string) => string = (id: string) => `v1/meeting-type/${id}`;
  protected DELETE_ONE_V2 = (id: string, deleteSeries: boolean) => `v2/meetings/${id}?deleteSeries=${deleteSeries}`;
  protected GET_INVITED_USERS_BY_MEETING = (id: string, page: number, limit: number) => `v1/meetings/invited-profiles/${id}?page=${page}&limit=${limit}`;
  protected GET_ALL_BETWEEN_DATES = (from: string, to: string) => `v1/meetings/available/from/${from}/to/${to}`;
  protected GET_ALL_BETWEEN_DATES_FOR_INVITED_USER = (from: string, to: string) => `v1/meetings/available-invited-user/from/${from}/to/${to}`;
  protected GET_CALENDAR_MARKS_BETWEEN_DATES = (from: string, to: string) => `v1/meetings/marks/from/${from}/to/${to}`;
  protected GET_NOT_AVAILABLE_DATA = (userIds: string[], from: string, to: string) => `v1/meetings/not-available/from/${from}/to/${to}?users=${JSON.stringify(userIds)}`;
  protected GET_MEETING_STATUS_BY_USER = (meetingId: string, userId: string) => `v1/meetings/status-by-user?meetingId=${meetingId}&userId=${userId}`;
  protected PUT_LEAVE_MEETING = (meetingId: string) => `v1/meetings/${meetingId}/leave`;

  constructor(
    protected httpService: HttpService,
    protected toastService: ToastService,
    private socketService: SocketService,
  ) {
    super(httpService, toastService);

    this.socketService.getCrudMessages$([EntityType.MEETING])
      .subscribe(payload => {
        this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId });
      });
  }

  protected edit$(entity: IMeetingUpdate, method: 'put' | 'patch' = 'patch'): Observable<IResponse<IMeeting>> {
    const changeAll = entity.changeAll;
    delete entity.changeAll;
    return this.httpService.put$<IMeeting>(this.PUT_ONE_V2(changeAll), entity as IMeeting)
        .pipe(tap(response => this.notifyAboutUpdate(EventType.EDIT, response, { entityId: entity['id'] })));
  }

  protected remove$(entity: IMeetingUpdate): Observable<IResponse<IMeeting>> {
    const deleteSeries = entity.changeAll;
    delete entity.changeAll;
    return this.httpService.delete$<IMeeting>(this.DELETE_ONE_V2(entity['id'], deleteSeries))
        .pipe(tap(response => this.notifyAboutUpdate(EventType.REMOVE, response, { entityId: entity['id'] })));
  }

  getData$(from: string, to: string): Observable<IMeeting[]> {
    return this.httpService.get$<IMeeting[]>(this.GET_ALL_BETWEEN_DATES(from, to))
      .pipe(
        map(response => response.payload ? response.payload : [] ),
        share(),
      );
  }

  getAvailableMeetingsForInvitedUser$(from: string, to: string): Observable<IMeeting[]> {
    return this.httpService.get$<IMeeting[]>(this.GET_ALL_BETWEEN_DATES_FOR_INVITED_USER(from, to))
      .pipe(
        map(response => response.payload ? response.payload : [] ),
        share(),
      );
  }

  getCalendarCellData$(from: string, to: string): Observable<IMeetingCellMark[]> {
    return this.httpService.get$<IMeetingCellMark[]>(this.GET_CALENDAR_MARKS_BETWEEN_DATES(from, to))
      .pipe(
        map(response => response.payload ? response.payload : [] ),
        share(),
      );
  }

  getTypeParams$(): Observable<IMeetingType[]> {
    return this.httpService.get$<IMeetingType[]>(this.MEETINGS_TYPES)
      .pipe(map(response => response.payload ? response.payload : [] ));
  }

  getTypeParamById$(id: string): Observable<IResponse<IMeetingType>> {
    return this.httpService.get$<IMeetingType>(this.GET_ONE_MEETING_TYPE(id));
  }

  getPastEvents$(id: string): Observable<IPastMeeting[]> {
    return observableOf(
      []
    );
  }

  addNewMeetingType$(label: string, color: string): Observable<IResponse<IMeetingType>> {
    return this.httpService.post$(this.MEETINGS_TYPES, {label, color});
  }
  updateMeetingType$(meetingTypeUpdateData: IMeetingType): Observable<IResponse<IMeetingType>> {
    return this.httpService.put$(this.MEETINGS_TYPES, meetingTypeUpdateData);
  }

  deleteMeetingType(meetingTypeId: string): Observable<IResponse<{ id: string }>> {
    return this.httpService.delete$(this.DELETE_ONE_MEETING_TYPE(meetingTypeId));
  }

  getInvitedUsers$(meetingId: string, page: number, limit: number): Observable<IResponse<MeetingUserModel[]>> {
    const url = this.GET_INVITED_USERS_BY_MEETING(meetingId, page, limit);
    return this.httpService.get$<{ status: MeetingUserStatus; user: UserModel }[]>(url)
      .pipe(
        map(response => {
          if (response.payload) {
            return {
              ...response,
              payload: response.payload.map(value => ({ ...value.user, status: value.status } as MeetingUserModel))
            };
          }
          return response as IResponse<any>;
        }),
      );
  }

  getStatusByUser$(meetingId: string, userId: string): Observable<IResponse<MeetingUserStatus>> {
    return this.httpService.get$(this.GET_MEETING_STATUS_BY_USER(meetingId, userId));
  }

  getNotAvailableData$(userIds: string[], from: string, to: string): Observable<IResponse<any>> {
    return this.httpService.get$(this.GET_NOT_AVAILABLE_DATA(userIds, from, to));
  }

  getInvitedUsersCount$(meetingId: string): Observable<IResponse<IMeetingUserCount>> {
    return this.httpService.get$(this.GET_MEETING_USERS_COUNT, {params: {meetingId}});
  }

  leaveMeeting$(entityId: string, fromSeries: boolean) {
    return this.httpService
      .put$<any>(this.PUT_LEAVE_MEETING(entityId), { fromSeries })
      .pipe(
        switchMap(res => res.error == null ? of(res) : throwError(new Error(res.message))),
        tap(response => {
          this.notifyAboutUpdate(EventType.REMOVE, response, { entityId })
        })
      )
  }
}
