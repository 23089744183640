import { Injectable } from '@angular/core';
import { BaseStoreService, IBaseStoreAction, IBaseState } from './base-store.class';
import { ISubject } from '../../models/subject';
import { ToastService } from '../toast.service';
import { Observable, forkJoin } from 'rxjs';
import { IResponse } from '../../models/response';
import { UserRole, EventType } from '../../models/common';
import { SubjectService } from '../subject.service';
import { map, filter } from 'rxjs/operators';
import { getBaseStoreEvent } from './helpers';
import { InviteService } from '../invite.service';
import { isSuccess } from '../../helpers/helpers';

export const INIT_STATE: IBaseState<ISubject> = { teach: [], study: [] };

@Injectable({
  providedIn: 'root'
})
export class SubjectStoreService extends BaseStoreService<ISubject> {

  constructor(
    protected toastService: ToastService,
    protected inviteService: InviteService,
    private subjectService: SubjectService,
  ) {
    super(INIT_STATE, toastService, inviteService);
  }

  protected keyExtractor = (subject: ISubject) => {
    return subject.role === UserRole.TEACHER
      ? 'teach'
      : 'study';
  }

  protected getItems$(): Observable<IResponse<ISubject[]>> {
    return forkJoin([
      this.subjectService.getAllTeach$(),
      this.subjectService.getAllStudy$(),
    ])
    .pipe(
      map(responses => {
        if (responses[0].error) {
          return responses[0];
        }
        if (responses[1].error) {
          return responses[1];
        }
        return { payload: [ ...responses[0].payload, ...responses[1].payload ] };
      })
    );
  }

  protected getItem$(id: string): Observable<IResponse<ISubject>> {
    return this.subjectService.getOne$(id);
  }

  protected getUpdates$(): Observable<IBaseStoreAction> {
    return this.subjectService.notifier$
      .pipe(
        filter(event => isSuccess(event.response)),
        filter(event => [EventType.ADD, EventType.EDIT, EventType.REMOVE].includes(event.eventType)),
        map(event => ({
          type: getBaseStoreEvent(event.eventType),
          payload: event.response.payload ? event.response.payload : event.entityId
        } as IBaseStoreAction)),
      );
  }
}
