import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatClosedNoticeComponent } from './chat-closed-notice.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [ CommonModule, IonicModule, TranslateModule ],
  declarations: [ChatClosedNoticeComponent],
  exports: [ChatClosedNoticeComponent]
})
export class ChatClosedNoticeComponentModule {}
