import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateMenuComponent } from './create-menu.component';
import { IonicModule } from '@ionic/angular';
import { SelectInputModule } from '../select-input/select-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StudentsInvitedListModule } from '../students-invited-list/students-invited-list.module';
import { CalendarInputModule } from '../calendar-input/calendar-input.module';
import { GradesCrudModule } from '../grades-crud/grades-crud.module';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { MeetingFrequencySelectorModule } from '../meeting-frequency-selector/meeting-frequency-selector.module';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { ParentChangeConfirmModule } from '../parent-change-confirm/parent-change-confirm.module';

@NgModule({
  declarations: [CreateMenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    SelectInputModule,
    ReactiveFormsModule,
    TranslateModule,
    StudentsInvitedListModule,
    CalendarInputModule,
    GradesCrudModule,
    StyledScrollbarModule,
    CheckboxModule,
    MeetingFrequencySelectorModule,
    ColorPickerModule,
    ParentChangeConfirmModule,
  ],
  exports: [CreateMenuComponent],
  entryComponents: [CreateMenuComponent],
})
export class CreateMenuModule { }
