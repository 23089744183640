import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  private showUpdateMessageEmitter$ = new BehaviorSubject(false);

  constructor(
    private swUpdate: SwUpdate,
  ) {}

  showUpdateMessage$(): Observable<boolean> {
    return this.showUpdateMessageEmitter$
      .asObservable()
      .pipe(
        distinctUntilChanged(),
      );
  }

  listenSWUpdates() {
    if (this.swUpdate.isEnabled) {
      console.log('Service Worker is enabled!');
      const everyTwoMinutes$ = timer(4 * 60 * 1000, 2 * 60 * 1000);
      everyTwoMinutes$
        .subscribe(() => this.swUpdate.checkForUpdate());
      this.swUpdate.available
        .subscribe(() => {
          this.showUpdateMessageEmitter$.next(true);
        });
    } else {
      console.warn('Service Worker is disabled!');
    }
  }
}
