import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ngx-translate/core";
export class LoadingService {
    constructor(loadingController, translateService) {
        this.loadingController = loadingController;
        this.translateService = translateService;
        this.loaderComponent = null;
    }
    startLoading(messageKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.loaderComponent) {
                this.loaderComponent = yield this.loadingController.create({
                    message: this.translateService.instant(messageKey ? messageKey : 'common.wait'),
                    cssClass: 'custom-loading',
                });
                return yield this.loaderComponent.present();
            }
        });
    }
    finishLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.loaderComponent) {
                return yield this.loaderComponent.dismiss()
                    .then(() => this.loaderComponent = null);
            }
        });
    }
}
LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i2.TranslateService)); }, token: LoadingService, providedIn: "root" });
