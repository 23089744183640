<ion-card>
  <ion-card-header>
    <ion-item lines="none">
      <img
        src="/assets/img/help-icon.svg"
        width="20"
        height="20"
        alt="Help"
        slot="start"
        class="icon-prefix" />
      <h2 class="title">{{ title | translate }}</h2>
      <ion-button (click)="dismiss()" fill="clear" slot="end">
        <img
          src="/assets/img/close-btn.svg"
          width="10"
          height="10"
          alt="Close"
          slot="icon-only" />
      </ion-button>
    </ion-item>
  </ion-card-header>
  <ion-list lines="none">
    <ion-item class="description">{{ description | translate: onboarding.data }}</ion-item>
    <ion-item 
      *ngFor="let link of links"
      [href]="link.url"
      target="_blank" 
      class="tutorial-link-item">
      <img
        src="/assets/img/play-icon.svg"
        width="17"
        height="17"
        alt="Play"
        slot="start"
        class="icon-prefix" />
      <span class="tutorial-link">{{ link.title }} ({{ link.dur }})</span>
    </ion-item>
    <app-checkbox [formControl]="hideHintsControl">
      {{ 'onboarding.dont_show' | translate }}
    </app-checkbox>
  </ion-list>
</ion-card>