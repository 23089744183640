import { Component, OnInit } from '@angular/core';
import { NotificationSidebarService } from '../../services/notification-sidebar.service';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent implements OnInit {
  unreadCount$ = this.notificationSidebarService.getUnreadCount$();

  constructor(
    private notificationSidebarService: NotificationSidebarService,
  ) { }

  ngOnInit() {}

  toggle() {
    this.notificationSidebarService.toggle();
  }

}
