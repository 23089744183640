// tslint:disable:variable-name
export class UserModel {
    id?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    dob?: string;
    avatarUrl?: string | { data: any, type: string };
    avatar_url?: string;
    verification_token_created_at?;
    email?: string;
    emailVerified?: boolean = false;
    role?: string;
    showContacts?: any;
    gender?: any;
    deactivated?: boolean;
    type?: string;

    public static getFullname(user): string {
        return `${user.firstName} ${user.lastName}`;
    }
}

export interface PasswordUpdate {
    oldPassword: string;
    newPassword: string;
}

export interface EntityUsers {
    students: UserModel[];
    teachers: UserModel[];
}

