/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-closed-notice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./chat-closed-notice.component";
var styles_ChatClosedNoticeComponent = [i0.styles];
var RenderType_ChatClosedNoticeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatClosedNoticeComponent, data: {} });
export { RenderType_ChatClosedNoticeComponent as RenderType_ChatClosedNoticeComponent };
export function View_ChatClosedNoticeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-backdrop", [], null, null, null, i2.View_IonBackdrop_0, i2.RenderType_IonBackdrop)), i1.ɵdid(1, 49152, null, 0, i3.IonBackdrop, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 11, "ion-card", [], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(3, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 9, "ion-card-content", [], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(5, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "img", [["alt", ""], ["src", "assets/img/warning-ico.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "p", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(12, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("chat.chat_removed_notice")); _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("common.btn-ok")); _ck(_v, 12, 0, currVal_1); }); }
export function View_ChatClosedNoticeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-closed-notice", [], null, null, null, View_ChatClosedNoticeComponent_0, RenderType_ChatClosedNoticeComponent)), i1.ɵdid(1, 114688, null, 0, i5.ChatClosedNoticeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatClosedNoticeComponentNgFactory = i1.ɵccf("app-chat-closed-notice", i5.ChatClosedNoticeComponent, View_ChatClosedNoticeComponent_Host_0, {}, { ok: "ok" }, []);
export { ChatClosedNoticeComponentNgFactory as ChatClosedNoticeComponentNgFactory };
