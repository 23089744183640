/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./color-picker.component";
var styles_ColorPickerComponent = [i0.styles];
var RenderType_ColorPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColorPickerComponent, data: {} });
export { RenderType_ColorPickerComponent as RenderType_ColorPickerComponent };
function View_ColorPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-item", [["button", "true"], ["lines", "none"], ["mode", "md"], ["type", "button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showColorsGroup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { button: [0, "button"], disabled: [1, "disabled"], lines: [2, "lines"], mode: [3, "mode"], type: [4, "type"] }, null), (_l()(), i1.ɵeld(2, 0, [[1, 0]], 0, 1, "ion-button", [["class", "main"], ["mode", "md"]], null, null, null, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(3, 49152, [["button", 4]], 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-label", [["class", "color-picker-label"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(5, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "true"; var currVal_2 = _co.disabled; var currVal_3 = "none"; var currVal_4 = "md"; var currVal_5 = "button"; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "md"; _ck(_v, 3, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hidden; _ck(_v, 0, 0, currVal_0); var currVal_7 = _co.title; _ck(_v, 6, 0, currVal_7); }); }
function View_ColorPickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0]], null, 1, "ion-button", [["class", "main"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showColorsGroup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, [["button", 4]], 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_0 = "md"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ColorPickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { buttonEl: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPickerComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPickerComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.display === _co.displays.BUTTON_WITH_TEXT); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.display === _co.displays.ONLY_BUTTON); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ColorPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-color-picker", [], null, null, null, View_ColorPickerComponent_0, RenderType_ColorPickerComponent)), i1.ɵdid(1, 4833280, null, 0, i5.ColorPickerComponent, [i3.PopoverController, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorPickerComponentNgFactory = i1.ɵccf("app-color-picker", i5.ColorPickerComponent, View_ColorPickerComponent_Host_0, { initColor: "initColor", display: "display", colorScheme: "colorScheme", hidden: "hidden", title: "title", disabled: "disabled" }, { colorSelect: "colorSelect" }, []);
export { ColorPickerComponentNgFactory as ColorPickerComponentNgFactory };
