/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../pipes/date-locale.pipe";
import * as i5 from "../../services/language.service";
import * as i6 from "@angular/common";
import * as i7 from "../styled-scrollbar/styled-scrollbar.directive";
import * as i8 from "../../services/window.service";
import * as i9 from "../../services/platform.service";
import * as i10 from "./time-picker.component";
import * as i11 from "@ngx-translate/core";
var styles_TimePickerComponent = [i0.styles];
var RenderType_TimePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimePickerComponent, data: {} });
export { RenderType_TimePickerComponent as RenderType_TimePickerComponent };
function View_TimePickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTimeDiff(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_TimePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-item", [["lines", "none"], ["mode", "md"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTime(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "ion-label", [], [[2, "time-diff", null]], null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(4, 0, ["", " "])), i1.ɵpid(0, i4.DateLocalePipe, [i5.LanguageService]), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TimePickerComponent_2)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "none"; var currVal_2 = "md"; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_5 = _co.showTimeDiff(_v.context.$implicit); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSelectedTimeInerval(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.canShowTimeDiff; _ck(_v, 2, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.context.$implicit, "HH:mm")); _ck(_v, 4, 0, currVal_4); }); }
export function View_TimePickerComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { ionListEl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "ion-content", [["class", "time-picker"], ["styledScrollbar", "shadow-root"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(3, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(4, 4341760, null, 1, i7.StyledScollbarDirective, [i1.ElementRef, i8.WindowService, i9.PlatformService], { styledScrollbar: [0, "styledScrollbar"] }, null), i1.ɵqud(603979776, 2, { styledScrollbarContent: 1 }), (_l()(), i1.ɵeld(6, 0, null, 0, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0]], null, 4, "ion-list", [["lines", "none"], ["mode", "md"]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(8, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"], mode: [1, "mode"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_TimePickerComponent_1)), i1.ɵdid(10, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "shadow-root"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "none"; var currVal_2 = "md"; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.timeIntervals$)); _ck(_v, 10, 0, currVal_3); }, null); }
export function View_TimePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-time-picker", [], null, null, null, View_TimePickerComponent_0, RenderType_TimePickerComponent)), i1.ɵdid(1, 8634368, null, 0, i10.TimePickerComponent, [i11.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimePickerComponentNgFactory = i1.ɵccf("app-time-picker", i10.TimePickerComponent, View_TimePickerComponent_Host_0, { time: "time", fromDate: "fromDate", minStep: "minStep", showTomorrowTime: "showTomorrowTime", onValueChanged: "onValueChanged", onDismiss: "onDismiss" }, {}, []);
export { TimePickerComponentNgFactory as TimePickerComponentNgFactory };
