import { Injectable } from '@angular/core';
import { of, Observable, from, forkJoin, BehaviorSubject } from 'rxjs';
import { tap, concatMap, map, mapTo, switchMap } from 'rxjs/operators';
import { IAnonymousUserPasscodeResponse, INewUserPasscodeResponse, SetPasswordToken, Tokens } from '../models/tokens';
import { UserModel } from '../../shared/models/user/user';
import { UserService } from './user.service';
import { IResponse } from 'src/app/shared/models/response';
import { HttpService } from 'src/app/shared/services/http.service';
import { Storage } from '@ionic/storage';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { RegisterData, LoginData } from '../models/credentials';
import { LinkStorageService } from 'src/app/shared/services/link-storage.service';
import { isRealString, isSuccess } from 'src/app/shared/helpers/helpers';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly TOKEN_STORE_KEY = 'TOKENS';
    private readonly POST_LOGIN_API: string = 'v2/user-profiles/login';
    private readonly POST_LOGOUT_API: string = 'v1/user-profiles/logout';
    private readonly POST_REGISTER_API: string = 'v1/signup';
    private readonly POST_REFRESH_TOKEN_API: string = 'v1/refresh-token';
    private readonly POST_FORGOT_PASSWORD_API: string = 'v1/forgot-password';
    private readonly POST_CHANGE_PASSWORD_API: string = 'v1/set-password';
    private readonly POST_ZULIP_API_KEY: string = 'v1/zulip/fetch-api-key';
    private readonly GET_NEW_USER_PASSCODE: string = 'v1/new-user-passcode';
    private readonly GET_RANDOM_USERNAME: string = 'v1/random-username';
    private readonly GET_CHECK_UNIQUE_USERNAME = (userName) => `v1/unique-username?userName=${ userName }`;
    private readonly GET_USER_PASSCODE: string = 'v1/user-passcode';
    private readonly GET_USER_PASSCODE_COUNT: string = 'v1/user-passcode-count';
    private readonly POST_LOGIN_WITH_PASSCODE: string = 'v1/user-profiles/login-with-passcode';


    private _jwtToken: string;
    private _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private userService: UserService,
        private httpService: HttpService,
        private storage: Storage,
        private loadingService: LoadingService,
        private linkStorageService: LinkStorageService,
        private navCtrl: NavController,
    ) {}

    authenticate$(user: { email: string, password: string }): Observable<IResponse<boolean>> {
        return this.loginRequest$(user)
            .pipe(
                tap(loginRes => {
                    if (loginRes.payload) {
                        this._jwtToken = loginRes.payload.token;
                    }
                }),
                concatMap(
                    loginRes => loginRes.payload
                        ? this.userService.getUserRequest$()
                            .pipe(
                                tap(userRes => {
                                    if (userRes.error) {
                                        this._jwtToken = null;
                                    }
                                }),
                                concatMap(
                                    userRes => userRes.payload
                                        ? forkJoin([ this.storeTokens$(loginRes.payload) ])
                                            .pipe(
                                                tap(() => {
                                                    this.userService.setUser(userRes.payload);
                                                    this._isAuthenticated$.next(true);
                                                }),
                                                mapTo({ payload: true }),
                                            )
                                        : of({ payload: false, error: userRes.error, message: userRes.message })
                                )
                            )
                        : of({ payload: false, error: loginRes.error, message: loginRes.message })
                ),
            );
    }

    authorize$(): Observable<IResponse<boolean>> {
        return this.isLoggedIn$()
            .pipe(
                concatMap(
                    isLogged => isLogged
                        ? this.getStoredTokens$()
                            .pipe(
                                tap(tokens => this._jwtToken = tokens.token),
                                concatMap(
                                    (tokens) => this.userService.getUserRequest$()
                                        .pipe(
                                            concatMap(userRes => this.userService.setUser(userRes.payload)
                                                .then(() => userRes)
                                            ),
                                            tap((userRes) => {
                                                if (isSuccess(userRes)) {
                                                    this._isAuthenticated$.next(true);
                                                } else {
                                                    this.logout$()
                                                        .toPromise()
                                                        .then(() => {
                                                            if (this.navCtrl) {
                                                                this.navCtrl.navigateRoot('auth/login');
                                                            }
                                                        });
                                                }
                                            }),
                                            map(userRes => isSuccess(userRes) ? { payload: true } : { payload: false }),
                                        )
                                )
                            )
                        : of({ payload: false })
                ),
            );
    }

    get jwtToken(): string {
        return this._jwtToken;
    }

    get isAuthenticated$(): Observable<boolean> {
        return this._isAuthenticated$.asObservable();
    }

    get isAuthenticated(): boolean {
        return this._isAuthenticated$.value;
    }

    refreshToken$(): Observable<boolean> {
        return this.getStoredTokens$()
            .pipe(
                switchMap(tokens => tokens && tokens.refresh_token
                    ? this.httpService.post$<Tokens>(this.POST_REFRESH_TOKEN_API, { token: tokens.refresh_token })
                    : of({ error: {} } as IResponse<Tokens>)
                ),
                concatMap(tokensRes => !tokensRes.error && tokensRes.payload ? this.storeTokens$(tokensRes.payload) : of(null)),
                tap((tokens: Tokens | null) => {
                    if (tokens) {
                        this._jwtToken = tokens.token;
                    }
                }),
                map(tokens => tokens ? true : false),
            );
    }

    register$(user: RegisterData): Observable<IResponse<boolean>> {
        return this.registerRequest$(user)
            .pipe(
                concatMap(
                    response => response.payload
                        ? this.authenticate$({
                            email: isRealString(user.email) ? user.email : response.payload.email,
                            password: user.password
                        })
                        : of({ payload: false, error: response.error, message: response.message })
                ),
            );
    }

    logout$(): Observable<void> {
        return from(this.loadingService.startLoading())
            .pipe(
                switchMap(() => forkJoin([ this.removeTokens$() ])),
                tap(() => {
                    this._isAuthenticated$.next(false);
                    this.loadingService.finishLoading();
                    this.linkStorageService.clearAll();
                    this.userService.clearUser()
                }),
                switchMap(() => of(null)),
            );
    }

    forgotPassword$(email: string): Observable<IResponse<any>> {
        return this.httpService.post$(this.POST_FORGOT_PASSWORD_API, {email});
    }

    changePassword$(password: string, token: string): Observable<IResponse<any>> {
        return this.httpService.post$(this.POST_CHANGE_PASSWORD_API, { password, token });
    }

    getZulipApiKey(): Observable<IResponse<{ api_key: string, email: string }>> {
        return this.httpService.post$(this.POST_ZULIP_API_KEY, {});
    }
    
    getNewUserPasscode(): Observable<IResponse<INewUserPasscodeResponse>> {
        return this.httpService.get$(this.GET_NEW_USER_PASSCODE)
    }

    getRandomUsername(): Observable<IResponse<{ userName: string }>> {
        return this.httpService.get$(this.GET_RANDOM_USERNAME)
    }

    checkUniqueUsername(payload: string): Observable<IResponse<{ unique: boolean }>> {
        return this.httpService.get$(this.GET_CHECK_UNIQUE_USERNAME(payload))
    }

    getUserPasscode(): Observable<IResponse<IAnonymousUserPasscodeResponse>> {
        return this.httpService.get$(this.GET_USER_PASSCODE)
    }

    getUserPasscodeCount(): Observable<IResponse<{ count: number }>> {
        return this.httpService.get$(this.GET_USER_PASSCODE_COUNT)
    }

    loginWithPasscode(
        payload: { userName: string, passCode: string }
    ): Observable<IResponse<SetPasswordToken>> {
        type Response = Tokens & SetPasswordToken

        return this.httpService.post$<Response>(this.POST_LOGIN_WITH_PASSCODE, payload).pipe(
            tap(response => {
                if (response.payload) {
                    this._jwtToken = response.payload.token;
                    const tokens = { ...response.payload, set_password_token: undefined }
                    this.storeTokens$(tokens)
                }
            }),
            map(res => {
                if (res.payload) {
                    const { set_password_token } = res.payload;
                    return { ...res, payload: { set_password_token } };
                }
                return res;
            }),
        );
    }

    private loginRequest$(user: LoginData): Observable<IResponse<Tokens>> {
        return this.httpService.post$<Tokens>(this.POST_LOGIN_API, user);
    }

    private registerRequest$(user: RegisterData): Observable<IResponse<UserModel>> {
        return this.httpService.post$<any>(this.POST_REGISTER_API, { ...user, tel: '0000', dob: new Date().toISOString()});
    }

    private logoutRequest$(token: { refreshToken: string }): Observable<IResponse<Tokens>> {
        return this.httpService.post$<any>(this.POST_LOGOUT_API, token);
    }

    private isLoggedIn$(): Observable<boolean> {
        return this.getStoredTokens$()
            .pipe(map(tokens => tokens && tokens.token ? true : false));
    }

    private storeTokens$(tokens: Tokens): Observable<Tokens | null> {
        return from(this.storage.set(this.TOKEN_STORE_KEY, JSON.stringify(tokens)))
            .pipe(concatMap(() => this.getStoredTokens$()));
    }

    private getStoredTokens$(): Observable<Tokens | null> {
        return from(this.storage.get(this.TOKEN_STORE_KEY))
            .pipe(map(tokens => tokens ? JSON.parse(tokens) : null));
    }

    private removeTokens$(): Observable<void> {
        return from(this.storage.remove(this.TOKEN_STORE_KEY));
    }
}
