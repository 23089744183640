import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { IResponse } from '../models/response';
import { tap, map, delay, filter, exhaustMap, distinctUntilChanged, concatMap } from 'rxjs/operators';
import { ToastService, TOAST_TYPE } from './toast.service';
import { EntityType, SocketMessageType, ICrudMessage, EventType, ConfirmAction, IReceivedEvent, TicketParent } from '../models/common';
import { SocketService } from './socket.service';
import { ConfirmationService } from './confirmation.service';
import { IAssignedTask } from '../models/assigned-task';
import { ITicket } from '../models/ticket';
import { isSuccess, getFileReader } from '../helpers/helpers';
import { getTicketParentFromEntityType } from '../components/ticket-item/helpers';
import {UpdateNotificationHelperService} from './update-notification-helper.service';
import { FileType, IFileAttach } from '../models/file-attach';
export interface IFileUploadData {
  file: File;
  entityId: string;
  entityType: EntityType;
  parentType: EntityType;
  entity: ITicket | IAssignedTask;
  shouldAddToChat?: boolean;
}

export interface IFileRemoveData {
  attachmentId: string;
  entity: ITicket | IAssignedTask;
  entityType: EntityType;
  parentType: EntityType;
}

export interface IFileUploadQueue {
  name: string;
  type: FileType;
  url: string;
  request$: (id: string) => Observable<IResponse<any>>;
  created_on: string;
}



@Injectable({
  providedIn: 'root'
})
export class FileAttachService {

  private uploadEmitter$ = new Subject<IFileUploadData>();
  private queueEmitter$ = new Subject<IFileUploadQueue>();
  private removeEmitter$ = new Subject<IFileRemoveData>();
  private notifierEmitter$ = new Subject<IReceivedEvent<IFileAttach>>();
  private showFileUploadDialogEmitter$ = new BehaviorSubject(false);
  private filesInProgress: File[] = [];

  private readonly POST_FILE = (shouldAddToChat: boolean) => `v1/upload-files?shouldAddToChat=${shouldAddToChat}`;
  private readonly GET_FILES = (id: string, type: string, parentType: TicketParent) => `v1/get-files?entityId=${id}&entityType=${type}&parentType=${parentType}`;
  private readonly REMOVE_FILE = (id: string, parentType: string) => `v1/attachments/${id}?parentType=${parentType}`;
  private readonly DOWNLOAD_FILE = (id: string, parentType: string) => `v1/download-file/${id}?parentType=${parentType}`;
  private readonly DOWNLOAD_PREVIEW = (id: string, parentType: string) => `v1/download-preview/${id}?parentType=${parentType}`;

  constructor(
    private httpService: HttpService,
    private toastService: ToastService,
    private socketService: SocketService,
    private confirmationService: ConfirmationService,
    private updateNotificationHelperService: UpdateNotificationHelperService,
  ) {
    this.uploadEmitter$
      .pipe(
        filter(fileUpload => fileUpload && fileUpload.entityId && fileUpload.entityType && !!fileUpload.file),
        tap(fileUpload => {
          this.showFileUploadDialogEmitter$.next(true);
          this.addFileToInProgress(fileUpload.file);
        }),
        concatMap(fileUpload => this.uploadReq$(fileUpload).pipe(map(response => ({ response, fileUpload })))),
        tap(data => {
          const response = data.response;
          if (!isSuccess(response)) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
          } else {
            this.notifyAboutUpdate(data.fileUpload.entityId, EventType.ADD, response);
            const parentType: TicketParent = getTicketParentFromEntityType(data.fileUpload.parentType);
            this.updateNotificationHelperService.displayCanUpdateModal(parentType, data.fileUpload.entity as ITicket, data.fileUpload.entityType);
          }
          this.removeFileFromInProgress(data.fileUpload.file);
        }),
        delay(1000),
        tap(() => {
          if (this.isFilesInProgressEmpty()) {
            this.showFileUploadDialogEmitter$.next(false);
          }
        }),
      )
      .subscribe();

    this.removeEmitter$
      .pipe(
        exhaustMap(event => this.removeReq$(event).pipe(map(response => ({ response, event })))),
        tap(data => {
          const response = data.response;
          const event = data.event;
          if (response.error) {
            this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
          } else {
            this.notifyAboutUpdate(response.payload.ticket_id, EventType.REMOVE, response);
            const parentType: TicketParent = getTicketParentFromEntityType(event.parentType);
            this.updateNotificationHelperService.displayCanUpdateModal(parentType, event.entity as ITicket, event.entityType);
          }
        }),
      )
      .subscribe();

    this.socketService.messages$()
      .pipe(
        filter(message => message.type === SocketMessageType.CRUD),
        map(message => message.payload as ICrudMessage),
        filter(message => message.entityType === EntityType.FILE_ATTACH),
      )
      .subscribe(message => this.notifyAboutUpdate(message.entityId, message.eventType, { payload: message.entity }, true));
  }

  notifier$(): Observable<IReceivedEvent<IFileAttach>> {
    return this.notifierEmitter$.asObservable();
  }

  uploadQueue$(): Observable<IFileUploadQueue> {
    return this.queueEmitter$.asObservable();
  }

  showFileUploadDialog$(): Observable<boolean> {
    return this.showFileUploadDialogEmitter$.asObservable()
      .pipe(distinctUntilChanged());
  }

  getFileAttaches$(entityId: string, entityType: EntityType, parentType: TicketParent): Observable<IResponse<IFileAttach[]>> {
    return this.httpService.get$(this.GET_FILES(entityId, entityType, parentType));
  }

  downloadFile$(file: IFileAttach, parentType: TicketParent): Observable<any> {
    return this.httpService.get$<string>(this.DOWNLOAD_FILE(file.id, parentType), { responseType: 'arraybuffer' })
      .pipe(map(response => response.payload ? response.payload : null));
  }

  downloadPreview$(file: IFileAttach, parentType: TicketParent): Observable<any> {
    return this.httpService.get$<string>(this.DOWNLOAD_PREVIEW(file.id, parentType), { responseType: 'blob' })
      .pipe(map(response => response.payload ? response.payload : null));
  }

  uploadAttachment(fileUpload: IFileUploadData, type: FileType): void {
    if (fileUpload.entityId) {
      this.uploadEmitter$.next(fileUpload);
    } else {
      this.queueAttachment(fileUpload, type);
    }
  }

  removeAttachment(
    attachmentId: string,
    entity: ITicket | IAssignedTask,
    entityType: EntityType,
    parentType: EntityType,
  ): void {
    this.confirmationService.confirmAction(ConfirmAction.REMOVE_FILE)
      .then(res => {
        if (res.isConfirmed) {
          this.removeEmitter$.next({
            attachmentId,
            entity,
            entityType,
            parentType,
           } as IFileRemoveData);
        }
      });
  }

  private uploadReq$(fileUpload: IFileUploadData): Observable<IResponse<any>> {
    const formData = new FormData();
    formData.append('files', fileUpload.file);
    formData.append('entity_id', fileUpload.entityId);
    formData.append('entity_type', fileUpload.entityType);
    formData.append('parent_type', fileUpload.parentType);

    return this.httpService.post$(this.POST_FILE(fileUpload.shouldAddToChat), formData);
  }

  private removeReq$(fileRemove: IFileRemoveData): Observable<IResponse<any>> {
    return this.httpService.delete$(this.REMOVE_FILE(fileRemove.attachmentId, fileRemove.parentType));
  }

  private notifyAboutUpdate(id: string, eventType: EventType, response: IResponse<IFileAttach>, isSocketMsg: boolean = false): void {
    if (isSocketMsg || !isSuccess(response) || !this.socketService.isConnected()) {
      const event: IReceivedEvent<IFileAttach> = {
        entityId: id,
        entityType: EntityType.FILE_ATTACH,
        eventType,
        response,
      };
      console.log(`%c notifyAboutUpdate`, 'color:coral', event);
      this.notifierEmitter$.next(event);
    }
  }

  private addFileToInProgress(file: File): void {
    this.filesInProgress.push(file);
  }

  private removeFileFromInProgress(file: File): void {
    const index = this.filesInProgress.findIndex(f => f.name === file.name);
    if (index !== -1) {
      this.filesInProgress.splice(index, 1);
    }
  }

  private isFilesInProgressEmpty(): boolean {
    return this.filesInProgress.length === 0;
  }

  private queueAttachment(fileUpload: IFileUploadData, type: FileType): void {
    if (type === FileType.VIDEO) {
      const queueFileUpload: IFileUploadQueue = this.prepareFileUploadQueueObject(fileUpload, type, URL.createObjectURL(fileUpload.file));
      this.queueEmitter$.next(queueFileUpload);
      return;
    }

    const reader = getFileReader();
    reader.onload = (event: ProgressEvent) => {
      const queueFileUpload: IFileUploadQueue = this.prepareFileUploadQueueObject(fileUpload, type, (event.target as any).result);
      this.queueEmitter$.next(queueFileUpload);
    };
    reader.readAsDataURL(fileUpload.file);
  }

  private prepareFileUploadQueueObject(fileUpload: IFileUploadData, type: FileType, url: string): IFileUploadQueue {
    return {
      name: fileUpload.file.name,
      url,
      type,
      request$: (id: string) => this.uploadReq$({ ...fileUpload, entityId: id }),
      created_on: new Date().toISOString(),
    }
  }
}
