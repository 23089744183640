import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';

import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localePt from '@angular/common/locales/pt';
import localeZh from '@angular/common/locales/zh';
import localeHe from '@angular/common/locales/he';

export enum Language {
  ENGLISH = 'en',
  GERMAN = 'de',
  RUSSIAN = 'ru',
  UKRAINIAN = 'uk',
  SPANISH = 'es',
  FRENCH = 'fr',
  ITALIAN = 'it',
  JAPANESE = 'ja',
  KOREAN = 'ko',
  PORTUGUESE = 'pt',
  CHINESE = 'zh',
  HEBREW = 'he',
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private langChangedEmitter$ = new Subject<Language>();
  private locales = {
    [Language.ENGLISH]: localeEn,
    [Language.GERMAN]: localeDe,
    [Language.RUSSIAN]: localeRu,
    [Language.UKRAINIAN]: localeUk,
    [Language.SPANISH]: localeEs,
    [Language.FRENCH]: localeFr,
    [Language.ITALIAN]: localeIt,
    [Language.JAPANESE]: localeJa,
    [Language.KOREAN]: localeKo,
    [Language.PORTUGUESE]: localePt,
    [Language.CHINESE]: localeZh,
    [Language.HEBREW]: localeHe,
  }

  constructor(
    private translateService: TranslateService,
  ) { }

  get currLang(): Language {
    return this.translateService.currentLang as Language;
  }

  get changes$(): Observable<Language> {
    return this.langChangedEmitter$.asObservable();
  }

  initLanguage(lang?: string): void {
    this.translateService.setDefaultLang(Language.ENGLISH);
    const appLang: string = lang ? lang : this.getDeviceLang();

    console.log('%c [APP LANGUAGE]: ', 'color: green', appLang);
    this.changeLanguage(appLang as Language);
  }

  async changeLanguage(lang: Language): Promise<void> {
    try {
      await this.translateService.use(lang).toPromise();
      moment.locale(lang);
      this.setAngularLang(lang);
      this.langChangedEmitter$.next(lang);
    } catch (error) {
      console.error(error);
      return Promise.resolve();
    }

    return Promise.resolve();
  }

  getDeviceLang(): string {
    const languages: string[] = Object.values(Language);

    for (const lang of languages) {
      if (navigator.languages[0].toLowerCase().includes(lang.toLowerCase())) {
        return lang;
      }
    }

    return Language.ENGLISH;
  }

  private setAngularLang(lang: string): void {
    if (lang in this.locales) {
      return registerLocaleData(this.locales[lang]);
    }

    return registerLocaleData(this.locales[Language.ENGLISH]);
  }
}
