import { ITicket } from 'src/app/shared/models/ticket';
import { IClass } from 'src/app/shared/models/class';
import { UserModel } from 'src/app/shared/models/user/user';
import { EntityType, UserRole } from 'src/app/shared/models/common';
import { Board, Column, ColumnItem, IKanbanEvent, KanbanEventType } from 'src/app/shared/components/kanban-board/helpers';
import * as cloneDeep from 'lodash.clonedeep';
import { InviteType } from 'src/app/shared/components/invite-modal/models';

export enum SchoolDashboardColumn {
    STUDENTS = 'Students',
    TEACHERS = 'Teachers',
    CLASSES = 'Classes',
    NOTIFICATIONS = 'Notifications',
}

export enum SchoolDashboardTranslateKeyColumn {
    STUDENTS = 'common.students',
    TEACHERS = 'common.teachers',
    CLASSES = 'common.classes',
    NOTIFICATIONS = 'common.notifications',
}

export type SchoolDashboardTicket = ITicket | IClass | UserModel;

export function isTeacherType(item: SchoolDashboardTicket): boolean {
    return (item as UserModel).firstName && (item as UserModel).role === UserRole.TEACHER;
}

export function isStudentType(item: SchoolDashboardTicket): boolean {
    return (item as UserModel).firstName && (item as UserModel).role === UserRole.STUDENT;
}

export function isTicketType(item: SchoolDashboardTicket): boolean {
    return !!(item as ITicket).type;
}

export function sortTeacherColumn<T>(board: Board<T>, owner_id: string): Board<T> {
    const newBoard: Board<T> = cloneDeep(board);
    const teacherColumnIndex: number = newBoard.columns.findIndex(column => column.id === SchoolDashboardColumn.TEACHERS);

    if (teacherColumnIndex !== -1) {
        const teacherColumn: Column<UserModel> = cloneDeep(newBoard.columns[teacherColumnIndex]);
        const ownerIndex: number = teacherColumn.items.findIndex(user => user.id === owner_id);

        if (ownerIndex !== -1) {
          const owner: ColumnItem<UserModel> = cloneDeep(teacherColumn.items[ownerIndex]);
          teacherColumn.items.splice(ownerIndex, 1);
          teacherColumn.items.unshift(owner);
          newBoard.columns[teacherColumnIndex] = teacherColumn as Column<T>;
        }
    }

    return newBoard;
}

export function shouldInviteUser(event: IKanbanEvent): boolean {
    return event.eventType === KanbanEventType.ADD_NEW_TICKET &&
        [EntityType.USER_TEACHER, EntityType.USER_STUDENT].includes(event.entityType)
}

export function getInviteTypeFromEntityType(entityType: EntityType): InviteType {
    return entityType === EntityType.USER_TEACHER
        ? InviteType.TEACHER
        : InviteType.STUDENT;
}
