/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instant-meet-copy-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./instant-meet-copy-widget.component";
import * as i7 from "../../services/whiteboard.service";
import * as i8 from "../../services/clipboard.service";
var styles_InstantMeetCopyWidgetComponent = [i0.styles];
var RenderType_InstantMeetCopyWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstantMeetCopyWidgetComponent, data: {} });
export { RenderType_InstantMeetCopyWidgetComponent as RenderType_InstantMeetCopyWidgetComponent };
function View_InstantMeetCopyWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-item", [["lines", "none"]], [[2, "collapsed", null]], null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-button", [["class", "start-conf-btn"], ["fill", "clear"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startConf(_v.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(3, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "ion-button", [["class", "font-extra-bold copy-btn"], ["slot", "end"]], [[2, "copy-btn--collapsed", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyConfLink(_v.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(7, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(8, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "none"; _ck(_v, 1, 0, currVal_1); var currVal_3 = "clear"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collapsed; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.collapsed; _ck(_v, 2, 0, currVal_2); var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("instant-meet.title")); _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.collapsed; _ck(_v, 6, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("instant-meet.copy-btn")); _ck(_v, 8, 0, currVal_6); }); }
export function View_InstantMeetCopyWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_InstantMeetCopyWidgetComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.link$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InstantMeetCopyWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instant-meet-copy-widget", [], null, null, null, View_InstantMeetCopyWidgetComponent_0, RenderType_InstantMeetCopyWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i6.InstantMeetCopyWidgetComponent, [i7.WhiteboardService, i8.ClipboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstantMeetCopyWidgetComponentNgFactory = i1.ɵccf("app-instant-meet-copy-widget", i6.InstantMeetCopyWidgetComponent, View_InstantMeetCopyWidgetComponent_Host_0, { collapsed: "collapsed" }, {}, []);
export { InstantMeetCopyWidgetComponentNgFactory as InstantMeetCopyWidgetComponentNgFactory };
