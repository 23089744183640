import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { map, share, switchMap, take, withLatestFrom, filter, tap } from 'rxjs/operators';
import { UserService } from 'src/app/auth/services/user.service';
import { selectChatStreamInfo, selectAllUsers, selectChatStreamList } from 'src/app/store/selectors/chat.selectors';
import { IChatUIEvent, IStreamId, ChatMember, IChatStream, IZulipUserId } from '../models/chat';
import { EntityAdapterService } from './entity-adapter.service';
import { WhiteboardService } from './whiteboard.service';
import { UserModel } from '../models/user/user';
import { ZulipService } from './zulip.service';
import { ToastService, TOAST_TYPE } from './toast.service';
import { WhiteboardComponent } from '../components/whiteboard/whiteboard.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ChatUiService {
  public readonly events$: Observable<IChatUIEvent>;

  private events = new Subject<IChatUIEvent>();
  private openChatWithUserEmitter$: Subject<UserModel> = new Subject();
  private closeChatWithUserEmitter$: Subject<void> = new Subject();
  private isChatOpenInProgress: boolean = false;

  constructor(
    private store: Store,
    private userService: UserService,
    private whiteboardService: WhiteboardService,
    private entityAdapterService: EntityAdapterService,
    private zulipService: ZulipService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {
    this.events$ = this.events.asObservable().pipe(share())
  }

  public publish(event: IChatUIEvent) {
    this.events.next(event);
  }

  public openConference(id: IStreamId) {
    this.store.select(selectChatStreamInfo, id).pipe(
      switchMap(stream => {
        if (stream.meta.type === 'channel') {
          return this.entityAdapterService.getOne$(stream.name, stream.meta.entityType).pipe(
            map((entity) => ({ stream, ownerId: entity.owner_id, entityId: entity.id }))
          );
        }
        return this.userService.getUser$().pipe(
          map(user => ({ stream, ownerId: user.id, entityId: null }))
        );
      }),
      take(1),
    )
    .subscribe(async ({ stream, ownerId, entityId }) => {
      await this.whiteboardService.openConference({
        id: entityId ? entityId : stream.name,
        title: stream.meta.type === 'channel' ? stream.title : stream.stream_id + '',
        owner_id: ownerId,
        entityType: entityId ? stream.meta.entityType : "chat-pm"
      }, WhiteboardComponent)
    })
  }

  public openChatWithUser(user: UserModel): void {
    this.openChatWithUserEmitter$.next(user);
  }

  public closeChatWithUser(): void {
      this.closeChatWithUserEmitter$.next();
  }

  public get closeChatWithUser$(): Observable<void> {
      return this.closeChatWithUserEmitter$.asObservable();
  }

  public openChatStream$(): Observable<number> {
    return this.openChatWithUserEmitter$
      .asObservable()
      .pipe(
        filter(() => !this.isChatOpenInProgress),
        tap(() => this.isChatOpenInProgress = true),
        switchMap(user => this.getPmStreamId$(user)),
      );
  }

  private getPmStreamId$(user: UserModel): Observable<number> {
    return this.zulipService.currentUser$
      .pipe(
        withLatestFrom(this.store.select(selectAllUsers)),
        map(([currUser, users]) => this.getZulipUserIds(currUser, user, users)),
        filter(([a, b]) => a !== b),
        tap(userIds => {
          if (!this.isUserIdsValid(userIds)) {
            this.toastService.showToast(this.translateService.instant('connection-errors.something-went-wrong'), TOAST_TYPE.ERROR);
            this.isChatOpenInProgress = false;
          }
        }),
        filter(userIds => this.isUserIdsValid(userIds)),
        withLatestFrom(this.store.select(selectChatStreamList)),
        switchMap(([userIds, streams]) => this.getStreamId$(userIds, streams)
          .pipe(tap(() => this.isChatOpenInProgress = false))
        )
      );
  }

  private getZulipUserIds(currUser: IZulipUserId, findUser: UserModel, chatUsers: ChatMember[]): number[] {
    const chatUser: ChatMember = chatUsers.find(user => user.id === findUser.id);
    const chatUserId = chatUser ? chatUser.zulip_uid : null;
    return [ currUser.user_id, chatUserId ];
  }

  private isUserIdsValid(ids: number[]): boolean {
    return ids && ids.length === 2 && !!ids[0] && !!ids[1];
  }

  private getStreamId$(userIds: number[], streams: IChatStream[]): Observable<number> {
    const stream: IChatStream = streams.find(str =>
      str.meta.type === 'pm' &&
      userIds.length === str.subscribers.length &&
      userIds.every(invitee => str.subscribers.includes(invitee))
    );
    if (stream) {
        return of(stream.stream_id);
    }
    return this.zulipService.createStream$(userIds as any[])
      .pipe(
        tap(response => {
          if (response.result === 'error') {
            this.toastService.showToast(this.translateService.instant('chat.cannot-create-chat'), TOAST_TYPE.ERROR);
          }
        }),
        filter(response => response.result === 'success'),
        map(response => response.stream_id),
      );
  }
}
