/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-update-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./app-update-notification.component";
var styles_AppUpdateNotificationComponent = [i0.styles];
var RenderType_AppUpdateNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppUpdateNotificationComponent, data: { "animation": [{ type: 7, name: "inOutAnimation", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(-120%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0)" }, offset: null }, timings: ".5s ease-out" }], options: null }], options: {} }] } });
export { RenderType_AppUpdateNotificationComponent as RenderType_AppUpdateNotificationComponent };
export function View_AppUpdateNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "ion-card", [["color", "primary"]], [[24, "@inOutAnimation", 0]], null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 13, "ion-card-content", [], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(3, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "ion-item", [["color", "primary"], ["lines", "none"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(5, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], lines: [1, "lines"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "ion-label", [["class", "font-bold"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(7, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(8, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, 0, 5, "ion-item", [["color", "primary"], ["lines", "none"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(11, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], lines: [1, "lines"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 3, "ion-button", [["class", "font-medium"], ["color", "light"], ["mode", "md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(13, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], mode: [1, "mode"] }, null), (_l()(), i1.ɵted(14, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "primary"; _ck(_v, 1, 0, currVal_1); var currVal_2 = "primary"; var currVal_3 = "none"; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = "primary"; var currVal_6 = "none"; _ck(_v, 11, 0, currVal_5, currVal_6); var currVal_7 = "light"; var currVal_8 = "md"; _ck(_v, 13, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("notifications.new-app-version-available")); _ck(_v, 8, 0, currVal_4); var currVal_9 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("common.refresh-btn")); _ck(_v, 14, 0, currVal_9); }); }
export function View_AppUpdateNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-notification", [], null, null, null, View_AppUpdateNotificationComponent_0, RenderType_AppUpdateNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i5.AppUpdateNotificationComponent, [], null, null)], null, null); }
var AppUpdateNotificationComponentNgFactory = i1.ɵccf("app-update-notification", i5.AppUpdateNotificationComponent, View_AppUpdateNotificationComponent_Host_0, {}, {}, []);
export { AppUpdateNotificationComponentNgFactory as AppUpdateNotificationComponentNgFactory };
