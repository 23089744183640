import { isDevMode, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'unescapeHtml',
  pure: true
})
export class UnescapeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): any {
    if (!this.isEscaped(value)) {
      return value;
    }
    try {
      const parsed = new DOMParser().parseFromString(value, 'text/html');
      return this.sanitizer.bypassSecurityTrustHtml(parsed.documentElement.textContent);
    } catch(err) {
      if (isDevMode()) {
        console.warn(err);
      }
      return value;
    }
  }

  private isEscaped(value: string) {
    return [
      '&amp;',
      '&lt;',
      '&gt;',
      '&quot;',
      '&#039;',
    ].some(char => value.includes(char))
  }

}
