<div class="container">
  <img class="warning-icon" width="144" height="144" src="/assets/img/warning-ico.png" alt="" />
  <h1 *ngIf="platform.isSafari">{{'warnings.popup-blocked' | translate}}</h1>
  <h3>
    <ng-container *ngIf="platform.isSafari; else genericTItle">{{'blocked-popup.more-info' | translate}}</ng-container>
    <ng-template #genericTItle>{{'blocked-popup.title' | translate}}</ng-template>
    <p class="support-text" *ngIf="!platform.isSafari">{{ 'blocked-popup.content' | translate }}</p>
    <a class="support-text" href="https://support.apple.com/it-it/guide/safari/sfri40696/mac" target="_blank" *ngIf="platform.isSafari">{{ 'blocked-popup.here' | translate }}</a>
  </h3>
  <img class="popup-image" width="290" height="204" [src]="popupImage" alt="">

  <ion-button (click)="dismiss()" class="dismiss-btn">{{'common.btn-close' | translate}}</ion-button>
</div>