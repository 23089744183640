/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./file-upload-dialog.component";
import * as i6 from "../../services/file-attach.service";
var styles_FileUploadDialogComponent = [i0.styles];
var RenderType_FileUploadDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadDialogComponent, data: { "animation": [{ type: 7, name: "enterAnimation", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)", opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0)", opacity: 1 }, offset: null }, timings: "500ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0)", opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(100%)", opacity: 0 }, offset: null }, timings: "500ms" }], options: null }], options: {} }] } });
export { RenderType_FileUploadDialogComponent as RenderType_FileUploadDialogComponent };
function View_FileUploadDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-card", [], [[24, "@enterAnimation", 0]], null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "ion-card-content", [["class", "ion-no-padding"]], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(3, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 8, "ion-item", [["color", "primary"], ["lines", "none"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(5, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], lines: [1, "lines"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 4, "ion-label", [["class", "ion-no-margin"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(7, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "ion-text", [["class", "font-medium"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(9, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Uploading files..."])), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "ion-spinner", [["class", "ion-margin-start"], ["color", "light"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(12, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null)], function (_ck, _v) { var currVal_1 = "primary"; var currVal_2 = "none"; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = "light"; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_FileUploadDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_FileUploadDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.uploadService.showFileUploadDialog$())); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FileUploadDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-upload-dialog", [], null, null, null, View_FileUploadDialogComponent_0, RenderType_FileUploadDialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.FileUploadDialogComponent, [i6.FileAttachService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploadDialogComponentNgFactory = i1.ɵccf("app-file-upload-dialog", i5.FileUploadDialogComponent, View_FileUploadDialogComponent_Host_0, {}, {}, []);
export { FileUploadDialogComponentNgFactory as FileUploadDialogComponentNgFactory };
