import * as tslib_1 from "tslib";
import { ToastController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export var TOAST_TYPE;
(function (TOAST_TYPE) {
    TOAST_TYPE["SUCCESS"] = "success";
    TOAST_TYPE["ERROR"] = "danger";
    TOAST_TYPE["WARNING"] = "warning";
})(TOAST_TYPE || (TOAST_TYPE = {}));
export const TOAST_CONFIG = {
    durations: {
        success: 2000,
        error: 5000,
    }
};
export class ToastService {
    constructor(toastController) {
        this.toastController = toastController;
    }
    showToast(message, type, position = 'bottom') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message,
                color: type,
                duration: type === TOAST_TYPE.SUCCESS ? TOAST_CONFIG.durations.success : TOAST_CONFIG.durations.error,
                position,
                cssClass: 'custom-toast',
            });
            return toast.present();
        });
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.ɵɵinject(i1.ToastController)); }, token: ToastService, providedIn: "root" });
