import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingJoinComponent } from './meeting-join.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    MeetingJoinComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    PipesModule,
  ],
  exports: [
    MeetingJoinComponent,
  ],
})
export class MeetingJoinModule { }
