import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IInstantMeetLink } from '../../models/common';
import { ClipboardService } from '../../services/clipboard.service';
import { WhiteboardService, IWhiteboardRoom } from '../../services/whiteboard.service';
import { WhiteboardComponent } from '../whiteboard/whiteboard.component';

@Component({
  selector: 'app-instant-meet-copy-widget',
  templateUrl: './instant-meet-copy-widget.component.html',
  styleUrls: ['./instant-meet-copy-widget.component.scss'],
})
export class InstantMeetCopyWidgetComponent implements OnInit {
  @Input() collapsed = false

  link$: Observable<IInstantMeetLink | null>;

  constructor(
    private whiteboardService: WhiteboardService,
    private clipboardService: ClipboardService,
  ) { }

  ngOnInit(): void {
    this.link$ = this.whiteboardService.getInstantMeetLink$()
  }

  startConf(link: IInstantMeetLink) {
    const room: IWhiteboardRoom = {
      id: link.id,
      owner_id: link.ownerId,
      title: link.id,
      entityType: "instant-meet",
    }
    this.whiteboardService.openConference(room, WhiteboardComponent)
  }

  copyConfLink(link: IInstantMeetLink) {
    this.clipboardService.copy(link.url)
  }
}
