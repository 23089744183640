import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {from, merge, Observable, of, Subject} from 'rxjs';
import {UserModel} from '../../models/user/user';
import {ISubject} from '../../models/subject';
import {concatMap, exhaustMap, filter, first, map, pluck, switchMap, takeUntil, tap} from 'rxjs/operators';
import {IResponse} from '../../models/response';
import {ConfirmationService} from '../../services/confirmation.service';
import {InviteService} from '../../services/invite.service';
import {ToastService} from '../../services/toast.service';
import {ConfirmAction, EntityType, UserTypes} from '../../models/common';
import {ISchool} from '../../models/school';
import {IClass} from '../../models/class';
import {getMeetingUserStatusIcon, IMeeting, MeetingUserModel, MeetingUserStatus} from '../../models/meeting';
import {getUserWithAvatar, showErrorIfExists} from '../../helpers/helpers';
import {MeetingService} from '../../services/meeting.service';
import {InvitedUsersListViewService} from '../../services/invited-users-list-view.service';
import {MeetingNotificationService} from '../../services/meeting-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../auth/services/user.service';
import {InvitedUserModel} from '../create-menu/create-menu.component';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-students-invited-list',
  templateUrl: './students-invited-list.component.html',
  styleUrls: ['./students-invited-list.component.scss'],
})
export class StudentsInvitedListComponent implements OnInit, OnDestroy {
  @Input() entity: ISchool | IClass | ISubject | IMeeting;
  @Input() entityType: EntityType;
  @Input() canRemove: boolean;

  users$: Observable<UserModel[]>;
  forceUsersUpdate$: Subject<UserModel[]> = new Subject<UserModel[]>();
  isNew: boolean;
  isSubmitted: boolean = true;
  currentUsers: UserModel[] = [];
  userStatuses: { [key: string]: MeetingUserStatus } = {};
  invitedUsersPageToLoad = 1;
  invitedUsersLimit = 10;
  ownerId: string;
  isUserListChanged: boolean = false;
  usersToAdd: InvitedUserModel[] = [];
  usersToRemove: UserModel[] = [];

  private removeInviteEmitter$ = new Subject<UserModel>();
  private addInviteEmitter$ = new Subject<UserModel>();
  private updatesEmitter$: Subject<void> = new Subject();
  private addAllEmitter$ = new Subject<UserModel[]>();
  private loadMoreUsers$ = new Subject<UserModel[]>();

  private destroy$ = new Subject();

  constructor(
    private inviteService: InviteService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private meetingService: MeetingService,
    private inviteListViewService: InvitedUsersListViewService,
    private meetingNotificationService: MeetingNotificationService,
    private translateService: TranslateService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.isNew = !(this.entity && !!this.entity.id);

    const loadMoreUsers$ = this.loadMoreUsers$.asObservable()
      .pipe(
        map(users => [...this.currentUsers, ...users]),
      );

    this.users$ = merge(
      this.getOtherUserUpdates$(),
      this.getAddInviteUpdates$(),
      this.getRemoveInviteUpdates$(),
      this.getAddAllUpdates$(),
      loadMoreUsers$,
      this.forceUsersUpdate$,
    )
    .pipe(
        switchMap(payload =>
            from(Promise.all(payload.map(user => getUserWithAvatar(user, this.sanitizer))))
        ),
        tap(users => this.currentUsers = this.getUniqueUsers(users)));

    this.initOwnerId();
  }

  private initOwnerId(): void {
    this.userService.getUser$()
        .pipe(takeUntil(this.destroy$))
        .subscribe(user => {
          if (user) {
            this.ownerId = user.id;
          }
        });
  }

  ngOnDestroy() {
    this.inviteListViewService.emitDestroyEvent();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initUsers(): void {
    this.updatesEmitter$.next();
  }

  removeInvitedFlag(): void {
    this.forceUsersUpdate$.next(
        this.currentUsers.map(user => {
          delete (user as InvitedUserModel).isNew;
          return user;
        })
    );
  }

  removeInvite(user: InvitedUserModel): void {
    this.isUserListChanged = true;
    this.removeInviteEmitter$.next(user);
    if (!this.isNew) {
      if (!user.isNew) {
        this.usersToRemove.push(user);
      }
      const index = this.usersToAdd.findIndex(u => u.id === user.id);
      if (index !== -1) {
        this.usersToAdd.splice(index, 1);
      }
    }
  }

  addInvite(user: InvitedUserModel): void {
    this.isUserListChanged = true;
    this.addInviteEmitter$.next(user);
    if (!this.isNew) {
      this.usersToAdd.push(user);
    }
  }

  canRemoveUser(user: InvitedUserModel): boolean {
    return user.isNew ? true : this.ownerId !== user.id ? this.canRemove : false;
  }

  addAll(users: UserModel[]): void {
    this.isUserListChanged = true;
    users = users.filter(user => user.id !== this.ownerId);
    if (!this.isNew) {
      this.usersToAdd.push(
        ...users.filter(user => this.currentUsers.findIndex(u => u.id === user.id) === -1)
      );
    }
    this.addAllEmitter$.next(users);
  }

  get canShowMeetingInviteStatus(): boolean {
    return this.isNew ? false : this.entityType === EntityType.MEETING;
  }

  getMeetingInviteIcon(user: UserModel): string {
    return this.isNew ? getMeetingUserStatusIcon(MeetingUserStatus.PENDING) : getMeetingUserStatusIcon(this.userStatuses[user.id]);
  }

  loadMoreUsers(event?: any) {
    if (!this.isNew) {
      this.invitedUsersPageToLoad += 1;
      const request = this.isMeeting()
        ? this.getMeetingUsers$()
        : this.getStudents$();
      request
        .pipe(
          tap(response => showErrorIfExists(response, this.toastService)),
          first(),
        )
        .subscribe(response => {
          if (response.payload && response.payload.length) {
            this.loadMoreUsers$.next(response.payload);
          } else {
            this.invitedUsersPageToLoad -= 1;
          }
          if (event) {
            event.target.complete();
          }
        });
    }
  }

  private addToArray(user: UserModel): UserModel[] {
    const currentUsers = this.currentUsers.slice();
    currentUsers.unshift(user);
    this.inviteListViewService.emitAddEvent([user.id]);

    if (!this.isNew && this.isMeeting()) {
      this.meetingService.getStatusByUser$(this.entity.id, user.id)
        .toPromise()
        .then(response => {
          if (response.payload) {
            this.userStatuses[user.id] = response.payload['status'];
          }
        });
    }

    return currentUsers;
  }

  private removeFromArray(user: UserModel): UserModel[] {
    const currentUsers = this.currentUsers.slice();
    const index = currentUsers.findIndex(u => u.id === user.id);
    if (index !== -1) {
      currentUsers.splice(index, 1);
    }

    if (this.isMeeting) {
      delete this.userStatuses[user.id];
      this.inviteListViewService.emitRemoveEvent(user.id);
    }

    if (!this.isNew && currentUsers.length < this.invitedUsersLimit) {
      this.loadMoreUsers();
    }

    return currentUsers;
  }

  private isMeeting(): boolean {
    return this.entityType === EntityType.MEETING;
  }

  private getMeetingUsers$(): Observable<IResponse<MeetingUserModel[]>> {
    return this.meetingService.getInvitedUsers$(this.entity.id, this.invitedUsersPageToLoad, this.invitedUsersLimit)
      .pipe(
        tap(response => {
          if (response.payload) {
            response.payload.forEach(user => {
              this.userStatuses[user.id] = user.status;
            });
          }
        }),
      );
  }

  private getStudents$(): Observable<IResponse<UserModel[]>> {
    return this.inviteService.getUsers$(
      '',
      UserTypes.STUDENT,
      [{ entityId: this.entity.id, entityType: this.entityType }],
      this.invitedUsersPageToLoad,
      this.invitedUsersLimit,
    );
  }

  private getUniqueUsers(users: UserModel[]): UserModel[] {
    return Array.from(new Set(users.map(user => user.id))).map(id => users.find(user => user.id === id));
  }

  private getAddAllUpdates$(): Observable<UserModel[]> {
    return this.addAllEmitter$
      .pipe(
        map(users => [ ...this.currentUsers, ...users ]),
        map(users => this.getUniqueUsers(users)),
      );
  }

  private getAddInviteUpdates$(): Observable<UserModel[]> {
    return this.addInviteEmitter$
      .pipe(
        map(user => this.addToArray(user)),
      );
  }

  private getRemoveInviteUpdates$(): Observable<UserModel[]> {
    return this.removeInviteEmitter$
      .pipe(
        exhaustMap(user => this.isNew
          ? of({ isConfirmed: { isConfirmed: true }, payload: user})
          : from(this.confirmationService.confirmAction(this.isMeeting() ? ConfirmAction.REMOVE_STUDENT_INVITE_FOR_MEETING : ConfirmAction.REMOVE_STUDENT_INVITE)
              .then(res => ({isConfirmed: res, payload: user})))),
        filter(data => data.isConfirmed.isConfirmed),
        pluck('payload'),
        map(user => this.removeFromArray(user)),
      );
  }

  private getOtherUserUpdates$(): Observable<UserModel[]> {
    const socketUpdates$ = this.meetingNotificationService.getMeetingSerialStatusUpdates$()
      .pipe(
        filter(() => this.entityType === EntityType.MEETING),
        filter(data => this.entity && (this.entity as IMeeting).serial_id === data.meeting_serial_id),
      );

    return merge(this.updatesEmitter$, socketUpdates$)
      .pipe(
        filter(() => !this.isNew),
        tap(() => {
          this.isSubmitted = true;
          this.invitedUsersPageToLoad = 1;
        }),
        concatMap(() => this.isMeeting() ? this.getMeetingUsers$() : this.getStudents$()),
        tap(response => {
          showErrorIfExists(response, this.toastService);
          this.isSubmitted = false;
        }),
        map(response => response.payload ? response.payload : []),
        tap(data => {
          if (data && data.length) {
            this.inviteListViewService.emitInitEvent(data.map(u => u.id));
          }
        }),
      );
  }
}
