import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ConfirmAction, IConfirmResponse} from '../../models/common';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() action: ConfirmAction;
  @Input() entityName?: string;
  title: string;
  message: string;
  showConfirm: boolean = true;

  confirmActions = ConfirmAction;

  isSelected: boolean = false;
  
  get showCheckbox() {
    return [
      ConfirmAction.REMOVE_MEETING,
      ConfirmAction.SHOULD_EDIT_RECCURING_MEETINGS,
      ConfirmAction.LEAVE_MEETING_SERIES,
    ].includes(this.action)
  }

  constructor(
    private modalCtrl: ModalController,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.title = this.getTitle(this.action);
    this.message = this.getMessage(this.action);

    const notConfirmedActions: ConfirmAction[] = [
      ConfirmAction.REMOVE_NOT_EMPTY_COLUMN,
      ConfirmAction.NOTIFY_ABOUT_REMOVED_TICKET,
      ConfirmAction.UNABLE_CHOOSE_PAST_DAY,
      ConfirmAction.FILE_SIZE_EXCEED,
    ];

    if (notConfirmedActions.includes(this.action)) {
      this.showConfirm = false;
    }
  }

  close(isConfirmed: boolean) {
    this.modalCtrl.dismiss({
      isConfirmed: this.getIsConfirmedValueForConfirmResponse(isConfirmed),
      payload: this.getPayloadForConfirmResponse(isConfirmed),
    } as IConfirmResponse);
  }

  getCancelBtnText(): string {
    switch (this.action) {
      case ConfirmAction.SHOULD_TRANSFER_USERS_TO_PARENT_ENTITY:
      case ConfirmAction.SHOULD_NOTIFY_USERS:
        return this.translateService.instant('common.btn-no');
      default:
        return this.translateService.instant('common.btn-cancel');
    }
  }

  getConfirmBtnText(): string {
    switch (this.action) {
      case ConfirmAction.SHOULD_TRANSFER_USERS_TO_PARENT_ENTITY:
      case ConfirmAction.SHOULD_NOTIFY_USERS:
        return this.translateService.instant('common.btn-yes');
      default:
        return this.translateService.instant('common.btn-confirm');
    }
  }

  getToggleText(): string {
    if (this.action === ConfirmAction.REMOVE_MEETING) {
      return this.translateService.instant('meeting.remove-reccurent-meeting');
    } else if (this.action === ConfirmAction.SHOULD_EDIT_RECCURING_MEETINGS) {
      return this.translateService.instant('meeting.edit-reccurent-meeting');
    } else if (this.action === ConfirmAction.LEAVE_MEETING_SERIES) {
      return this.translateService.instant('meeting.leave-reccurent-meeting');
    }
    return '';
  }

  private getTitle(action: ConfirmAction): string {
    switch (action) {
      case ConfirmAction.COMMMON:
      case ConfirmAction.REMOVE_TICKET:
      case ConfirmAction.REMOVE_SUBJECT:
      case ConfirmAction.REMOVE_STUDENT_INVITE:
      case ConfirmAction.REMOVE_ONE_MEETING:
      case ConfirmAction.REMOVE_ONE_MEETING_TYPE:
      case ConfirmAction.REMOVE_SERIES_MEETING:
        return this.translateService.instant('confirm-message.are-you-sure');
      case ConfirmAction.LOGOUT:
        return this.translateService.instant('confirm-message.logout');
      case ConfirmAction.SHOULD_NOTIFY_USERS:
        return this.translateService.instant('confirm-message.should-notify-users-title');
      case ConfirmAction.SHOULD_TRANSFER_USERS_TO_PARENT_ENTITY:
        return this.translateService.instant('confirm-message.should-transfer-users-to-parent');
      case ConfirmAction.DELETE_ACCOUNT:
        return this.translateService.instant('confirm-message.delete-account');
      case ConfirmAction.NOTIFY_ABOUT_REMOVED_TICKET:
        return this.translateService.instant('confirm-message.notify-about-removed-ticket');
      case ConfirmAction.LEAVE_SCHOOL:
      case ConfirmAction.LEAVE_CLASS:
      case ConfirmAction.LEAVE_SUBJECT:
        return this.translateService.instant('confirm-message.should-leave-entity').replace('{entity}', this.entityName);
      case ConfirmAction.UNABLE_CHOOSE_PAST_DAY:
        return this.translateService.instant('common.unable-choose-past-day');
      case ConfirmAction.FILE_SIZE_EXCEED:
        return this.translateService.instant('validation-errors.file-size-exceed');
      case ConfirmAction.SHARED_TO_ESTUDY_SUBJECT:
        return this.translateService.instant('confirm-message.shared-to-estudy-subject-title')
      case ConfirmAction.SHARED_TO_ESTUDY_MATERIAL:
        // TODO: update with correct key
        return this.translateService.instant('confirm-message.shared-to-estudy-material-title')
      case ConfirmAction.CREATE_INSTANT_MEET_LINK:
        return this.translateService.instant('confirm-message.create-instant-meet-link')
      case ConfirmAction.LEAVE_MEETING:
        return this.translateService.instant('confirm-message.leave-meeting-title', { title: this.entityName })
      case ConfirmAction.LEAVE_MEETING_SERIES:
        return this.translateService.instant('confirm-message.leave-meeting-series-title', { title: this.entityName })
      default:
        return null;
    }
  }

  private getMessage(action: ConfirmAction): string {
    if (action === ConfirmAction.REMOVE_NOT_EMPTY_COLUMN) {
      return this.translateService.instant('confirm-message.remove-column-not-empty');
    } else if (action === ConfirmAction.REMOVE_EMPTY_COLUMN) {
      return this.translateService.instant('confirm-message.remove-column-empty');
    } else if (action === ConfirmAction.REMOVE_TICKET) {
      return this.translateService.instant('confirm-message.remove-ticket');
    } else if (action === ConfirmAction.REMOVE_SCHOOL) {
      return this.translateService.instant('confirm-message.remove-school');
    } else if (action === ConfirmAction.REMOVE_CLASS) {
      return this.translateService.instant('confirm-message.remove-class');
    } else if (action === ConfirmAction.REMOVE_SUBJECT) {
      return this.translateService.instant('confirm-message.remove-subject');
    } else if (action === ConfirmAction.REMOVE_STUDENT_INVITE) {
      return this.translateService.instant('confirm-message.remove-student-invite');
    } else if (action === ConfirmAction.REMOVE_STUDENT_INVITE_FOR_MEETING) {
      return this.translateService.instant('confirm-message.remove-student-invite-for-meeting');
    } else if (action === ConfirmAction.REMOVE_TEACHER_INVITE) {
      return this.translateService.instant('confirm-message.remove-teacher-invite');
    } else if (action === ConfirmAction.REMOVE_FILE) {
      return this.translateService.instant('confirm-message.remove-file');
    } else if (action === ConfirmAction.REMOVE_MATERIAL) {
      return this.translateService.instant('confirm-message.remove-material');
    } else if (action === ConfirmAction.REMOVE_ONE_MEETING) {
      return this.translateService.instant('confirm-message.remove-one-meeting');
    } else if (action === ConfirmAction.REMOVE_SERIES_MEETING) {
      return this.translateService.instant('confirm-message.remove-series-meeting');
    } else if (action === ConfirmAction.REMOVE_MEETING) {
      return this.translateService.instant('confirm-message.remove-meeting');
    } else if (action === ConfirmAction.SHOULD_NOTIFY_USERS) {
      return this.translateService.instant('confirm-message.should-notify-users-body');
    } else if (action === ConfirmAction.SHOULD_EDIT_RECCURING_MEETINGS) {
      return this.translateService.instant('confirm-message.should-edit-reccuring-meeting');
    } else if (action === ConfirmAction.REMOVE_ONE_MEETING_TYPE) {
      return this.translateService.instant('confirm-message.should-delete-meeting-type');
    } else if (action === ConfirmAction.SHARED_TO_ESTUDY_SUBJECT) {
      return this.translateService.instant('confirm-message.shared-to-estudy-subject');
    } else if (action === ConfirmAction.SHARED_TO_ESTUDY_MATERIAL) {
      // TODO: update with correct key
      return this.translateService.instant('confirm-message.shared-to-estudy-material');
    } else if (action === ConfirmAction.CREATE_INSTANT_MEET_LINK) {
      return this.translateService.instant('confirm-message.create-instant-meet-link-msg');
    } else if (action === ConfirmAction.LEAVE_MEETING_SERIES) {
      return this.translateService.instant('confirm-message.leave-meeting-series-msg');
    }
    return null;
  }

  private getIsConfirmedValueForConfirmResponse(isConfirmed: boolean): boolean {
    switch (this.action) {
      case ConfirmAction.SHOULD_TRANSFER_USERS_TO_PARENT_ENTITY:
        return true;
      default:
        return isConfirmed;
    }
  }

  private getPayloadForConfirmResponse(isConfirmed: boolean): any {
    switch (this.action) {
      case ConfirmAction.REMOVE_MEETING:
      case ConfirmAction.SHOULD_EDIT_RECCURING_MEETINGS:
      case ConfirmAction.LEAVE_MEETING_SERIES:
        return this.isSelected;
      case ConfirmAction.SHOULD_TRANSFER_USERS_TO_PARENT_ENTITY:
        return isConfirmed;
      default:
        return null;
    }
  }

}
