import { Component, EventEmitter, Input, OnInit, Output, HostBinding } from '@angular/core';
import { first } from 'rxjs/operators';
import { IStreamId } from 'src/app/shared/models/chat';
import { PlatformService } from 'src/app/shared/services/platform.service';

@Component({
  selector: 'app-create-chat-page',
  templateUrl: './create-chat.page.html',
  styleUrls: ['./create-chat.page.scss'],
})
export class CreateChatPage implements OnInit {
  @Input() handleCreate: (streamId: IStreamId) => any;
  @Input() doDismiss: () => void;

  isModalHeader = false;

  @Output() back = new EventEmitter<void>();
  @Output() expand = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<IStreamId>();

  @HostBinding('class.desktop-view') get t() {
    return this.platformService.isDesktop;
  }

  constructor(
    private platformService: PlatformService,
  ) { }

  ngOnInit() {
    if (typeof this.doDismiss === 'function') {
      this.isModalHeader = true;
    }
    if (typeof this.handleCreate === 'function') {
      this.onCreate.pipe(first()).subscribe((streamId) => this.handleCreate(streamId));
    }
  }

}
