/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-nav.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/components/styled-scrollbar/styled-scrollbar.directive";
import * as i6 from "../../../shared/services/window.service";
import * as i7 from "../../../shared/services/platform.service";
import * as i8 from "../../../shared/directives/remove-content-sizing/remove-content-sizing.directive";
import * as i9 from "../../../shared/components/styled-scrollbar/styled-scrollbar-content.directive";
import * as i10 from "../../../shared/components/chat-nav/chat-nav.component.ngfactory";
import * as i11 from "../../../shared/components/chat-nav/chat-nav.component";
import * as i12 from "@ngrx/store";
import * as i13 from "../../../shared/services/zulip.service";
import * as i14 from "./chat-nav.page";
var styles_ChatNavPage = [i0.styles];
var RenderType_ChatNavPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatNavPage, data: {} });
export { RenderType_ChatNavPage as RenderType_ChatNavPage };
export function View_ChatNavPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 18, "ion-toolbar", [["color", "primary"], ["mode", "ios"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, 0, 12, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(9, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.search.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "ion-icon", [["class", "search-icon"], ["size", "small"], ["slot", "icon-only"], ["src", "/assets/img/search-thin-ic-light.svg"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(13, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"], src: [1, "src"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 3, "ion-button", [["class", "expand-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expand.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(15, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "ion-icon", [["class", "open-new-tab-icon"], ["size", "small"], ["slot", "icon-only"], ["src", "/assets/img/open-new-tab-ic-light.svg"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(17, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"], src: [1, "src"] }, null), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "ion-button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(19, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(20, 0, null, 0, 0, "img", [["alt", "Close"], ["slot", "icon-only"], ["src", "/assets/img/chat-close-btn-light.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 8, "ion-content", [["appRemoveContentSizing", ""], ["styledScrollbar", "shadow-root"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(22, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(23, 4341760, null, 1, i5.StyledScollbarDirective, [i1.ElementRef, i6.WindowService, i7.PlatformService], { styledScrollbar: [0, "styledScrollbar"] }, null), i1.ɵqud(603979776, 1, { styledScrollbarContent: 1 }), i1.ɵdid(25, 4341760, null, 0, i8.RemoveContentSizingDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(26, 0, null, 0, 3, "div", [["styledScrollbarContent", ""]], null, null, null, null, null)), i1.ɵdid(27, 16384, [[1, 4]], 0, i9.StyledScrollbarContentDirective, [i1.ElementRef], { styledScrollbarContent: [0, "styledScrollbarContent"] }, null), (_l()(), i1.ɵeld(28, 0, null, null, 1, "app-chat-nav", [], null, [[null, "showDetails"], [null, "select"], [null, "create"], [null, "openConference"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showDetails" === en)) {
        var pd_0 = (_co.showDetails.next($event) !== false);
        ad = (pd_0 && ad);
    } if (("select" === en)) {
        var pd_1 = (_co.select.next($event) !== false);
        ad = (pd_1 && ad);
    } if (("create" === en)) {
        var pd_2 = (_co.search.next() !== false);
        ad = (pd_2 && ad);
    } if (("openConference" === en)) {
        var pd_3 = (_co.openConference.next($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i10.View_ChatNavComponent_0, i10.RenderType_ChatNavComponent)), i1.ɵdid(29, 4308992, null, 0, i11.ChatNavComponent, [i1.ElementRef, i12.Store, i13.ZulipService], null, { select: "select", create: "create", showDetails: "showDetails", openConference: "openConference" })], function (_ck, _v) { var currVal_0 = "primary"; var currVal_1 = "ios"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = "small"; var currVal_4 = "/assets/img/search-thin-ic-light.svg"; _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_5 = "small"; var currVal_6 = "/assets/img/open-new-tab-ic-light.svg"; _ck(_v, 17, 0, currVal_5, currVal_6); var currVal_7 = "shadow-root"; _ck(_v, 23, 0, currVal_7); var currVal_8 = ""; _ck(_v, 27, 0, currVal_8); _ck(_v, 29, 0); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("chat.nav_page_title")); _ck(_v, 6, 0, currVal_2); }); }
export function View_ChatNavPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-nav-page", [], [[2, "desktop-view", null]], null, null, View_ChatNavPage_0, RenderType_ChatNavPage)), i1.ɵdid(1, 114688, null, 0, i14.ChatNavPage, [i7.PlatformService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).t; _ck(_v, 0, 0, currVal_0); }); }
var ChatNavPageNgFactory = i1.ɵccf("app-chat-nav-page", i14.ChatNavPage, View_ChatNavPage_Host_0, {}, { select: "select", search: "search", expand: "expand", dismiss: "dismiss", showDetails: "showDetails", openConference: "openConference" }, []);
export { ChatNavPageNgFactory as ChatNavPageNgFactory };
