import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OnboardingGuideMenuComponent } from './onboarding-guide-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from '../checkbox/checkbox.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    CheckboxModule,
  ],
  declarations: [OnboardingGuideMenuComponent],
  entryComponents: [OnboardingGuideMenuComponent],
  exports: [OnboardingGuideMenuComponent]
})
export class OnboardingGuideMenuComponentModule {}
