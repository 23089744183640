import { Injectable } from '@angular/core';

export type LinkStorageEntity = 'Ticket' | 'School' | 'SchoolClass' | 'Subject' | 'Share';

@Injectable({
  providedIn: 'root'
})
export class LinkStorageService {

  private readonly INVITE_LINK_STORAGE_KEY = 'invitation_session';
  private readonly TICKET_LINK_STORAGE_KEY = 'ticket_route_session';
  private readonly SCHOOL_LINK_STORAGE_KEY = 'school_route_session';
  private readonly CLASS_LINK_STORAGE_KEY = 'class_route_session';
  private readonly SUBJECT_LINK_STORAGE_KEY = 'subject_route_session';
  private readonly SHARE_LINK_STORAGE_KEY = 'share_link_session';

  constructor() { }

  public saveInviteLink(value: string): void {
    this.saveLink(value, this.INVITE_LINK_STORAGE_KEY);
  }

  public saveTicketLink(value: string): void {
    this.saveLink(value, this.TICKET_LINK_STORAGE_KEY);
  }

  public saveSchoolLink(value: string): void {
    this.saveLink(value, this.SCHOOL_LINK_STORAGE_KEY);
  }

  public saveClassLink(value: string): void {
    this.saveLink(value, this.CLASS_LINK_STORAGE_KEY);
  }

  public saveSubjectLink(value: string): void {
    this.saveLink(value, this.SUBJECT_LINK_STORAGE_KEY);
  }

  public saveShareLink(value: string): void {
    this.saveLink(value, this.SHARE_LINK_STORAGE_KEY);
  }

  public getInviteLink(): string | null {
    return this.get(this.INVITE_LINK_STORAGE_KEY);
  }

  public getTicketLink(): string | null {
    return this.get(this.TICKET_LINK_STORAGE_KEY);
  }

  public getSchoolLink(): string | null {
    return this.get(this.SCHOOL_LINK_STORAGE_KEY);
  }

  public getClassLink(): string | null {
    return this.get(this.CLASS_LINK_STORAGE_KEY);
  }

  public getSubjectLink(): string | null {
    return this.get(this.SUBJECT_LINK_STORAGE_KEY);
  }

  public getShareLink(): string | null {
    return this.get(this.SHARE_LINK_STORAGE_KEY);
  }

  public clearAll(): void {
    this.removeInviteLink();
    this.removeTicketLink();
    this.removeSchoolLink();
    this.removeClassLink();
    this.removeSubjectLink();
    this.removeShareLink();
  }

  private removeInviteLink(): void {
    this.remove(this.INVITE_LINK_STORAGE_KEY);
  }

  private removeTicketLink(): void {
    this.remove(this.TICKET_LINK_STORAGE_KEY);
  }

  private removeSchoolLink(): void {
    this.remove(this.SCHOOL_LINK_STORAGE_KEY);
  }

  private removeClassLink(): void {
    this.remove(this.CLASS_LINK_STORAGE_KEY);
  }

  private removeSubjectLink(): void {
    this.remove(this.SUBJECT_LINK_STORAGE_KEY);
  }

  private removeShareLink(): void {
    this.remove(this.SHARE_LINK_STORAGE_KEY);
  }

  private saveLink(value: string, key: string): void {
    this.clearAll();
    this.save(key, value);
  }

  private save(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  private get(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  private remove(key: string): void {
    sessionStorage.removeItem(key);
  }

}
