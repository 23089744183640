import { isDevMode } from "@angular/core";
import { Storage } from '@ionic/storage';
import { ajax } from 'rxjs/ajax';
import { BehaviorSubject, from, NEVER, of, ReplaySubject, Subject, throwError, zip } from 'rxjs';
import { concatMap, exhaustMap, shareReplay, switchMap, takeWhile, tap, catchError, withLatestFrom, first, filter, map, scan } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { generateId } from '../helpers/helpers';
import produce from 'immer';
import { HttpService } from "./http.service";
import { EnvService } from "./env/env.service";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "../../auth/services/auth.service";
import * as i3 from "./http.service";
import * as i4 from "./env/env.service";
export class ZulipService {
    constructor(storage, authService, httpService, envService) {
        this.storage = storage;
        this.authService = authService;
        this.httpService = httpService;
        this.envService = envService;
        this.USER_TOKEN_STORAGE_KEY = 'token_c';
        this.USER_DATA_STORAGE_KEY = 'zuid';
        this.ZULIP_API_ENDPOINT = this.envService.apiConfig.zulipApiEndpoint;
        this.renewEventQueueId$ = new Subject();
        this.eventQueueId$ = new ReplaySubject(1);
        this.eventQueue$ = new Subject();
        this.userToken$ = new ReplaySubject(1);
        this.eventQueueUpdates$ = this.eventQueue$.asObservable().pipe(shareReplay(1));
        this.currentUser$ = new ReplaySubject(1);
        this.init$ = new Subject();
        this.zulipIsOnline$ = new BehaviorSubject(false);
        this.eventQueueId$
            .pipe(tap((queueId) => isDevMode() && console.log('eventQueueId$ next:', queueId)), switchMap(queueId => this.listenEvents$(queueId).pipe(takeWhile(res => res.result !== 'error'), catchError((err) => {
            if (isDevMode) {
                console.warn(err);
            }
            if (err.code === 'BAD_EVENT_QUEUE_ID') {
                this.renewEventQueueId$.next();
            }
            return NEVER;
        }))))
            .subscribe(events => {
            if (isDevMode()) {
                console.log(events);
            }
            this.eventQueue$.next(events);
        });
    }
    get userCredentials$() {
        return this.userToken$.pipe(first(token => token != null));
    }
    init() {
        this.renewEventQueueId$
            .pipe(exhaustMap(() => this.registerEventQueue$()))
            .subscribe(res => {
            this.zulipIsOnline$.next(true);
            this.eventQueueId$.next(res.queue_id);
            this.init$.next(res);
            if (isDevMode()) {
                console.info('[quque_id]', res.queue_id);
            }
        }, () => this.zulipIsOnline$.next(false));
        if (this.authService.isAuthenticated) {
            this.renewEventQueueId$.next();
            Promise.all([
                this.storage.get(this.USER_TOKEN_STORAGE_KEY),
                this.storage.get(this.USER_DATA_STORAGE_KEY)
            ])
                .then(([token, user]) => {
                if (token == null || user == null) {
                    this.requestNewApiKey();
                    return;
                }
                try {
                    this.currentUser$.next(JSON.parse(window.atob(user)));
                }
                catch (err) {
                    console.error(err);
                    return;
                }
                this.userToken$.next(token);
            });
        }
        else {
            this.logout();
        }
        this.authService.isAuthenticated$
            .pipe(filter(isAuthenticated => !isAuthenticated), tap(() => this.logout()), withLatestFrom(this.eventQueueId$), filter(([_, queueId]) => queueId != null), switchMap(([_, queueId]) => this.unregisterEventQueue(queueId).pipe(catchError(() => of(null)))))
            .subscribe(() => {
            this.eventQueueId$.next(null);
        });
        this.authService.isAuthenticated$
            .pipe(filter(isAuthenticated => isAuthenticated))
            .subscribe(() => {
            this.renewEventQueueId$.next();
        });
    }
    requestNewApiKey() {
        this.authService.getZulipApiKey().subscribe((res) => {
            if (res.payload) {
                this.setApiKey(res.payload.email, res.payload.api_key);
            }
        });
    }
    setApiKey(email, apiKey) {
        const token = window.btoa(`${email}:${apiKey}`);
        this.userToken$.next(token);
        return this.storage.set(this.USER_TOKEN_STORAGE_KEY, token)
            .then(() => this.getOwnUser())
            .then(({ user_id }) => {
            this.currentUser$.next({ user_id });
            try {
                const encodedUserData = window.btoa(JSON.stringify({ user_id }));
                return this.storage.set(this.USER_DATA_STORAGE_KEY, encodedUserData);
            }
            catch (err) {
                console.error(err);
            }
        });
    }
    logout() {
        return Promise
            .all([
            this.storage.remove(this.USER_TOKEN_STORAGE_KEY),
            this.storage.remove(this.USER_DATA_STORAGE_KEY)
        ])
            .then(() => {
            this.currentUser$.next(null);
            this.userToken$.next(null);
        });
    }
    getOwnUser() {
        return this.getUserCreds()
            .then(token => {
            const req = {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Basic ' + token
                }),
            };
            return fetch(`${this.ZULIP_API_ENDPOINT}/users/me`, req);
        })
            .then(res => res.json());
    }
    getUserCreds() {
        return this.storage.get(this.USER_TOKEN_STORAGE_KEY);
    }
    getStreams$() {
        const url = 'users/me/subscriptions?include_subscribers=true';
        return this.userRequest$('get', url).pipe(map(res => {
            if (res.result === 'error') {
                throw new Error(res.msg);
            }
            return res.subscriptions.reduce((accum, entry) => {
                try {
                    accum.push(Object.assign({}, entry, { meta: JSON.parse(entry.description) }));
                }
                finally {
                    return accum;
                }
            }, []);
        }));
    }
    getUser$(uid) {
        return this.httpService.get$(`v1/zulip/users/${uid}`);
    }
    getMessage$(id) {
        const narrow = [
            { operator: 'id', operand: String(id) },
        ];
        const queryParams = new URLSearchParams({
            'anchor': 'newest',
            'num_before': 1,
            'num_after': 0,
            'narrow': JSON.stringify(narrow),
        }).toString();
        return this.userRequest$('get', `messages?${queryParams}`).pipe(map(res => res.messages[0]));
    }
    getMessages$(streamId, anchor = 'newest', numBefore = 200, numAfter = 0) {
        const narrow = [
            { operator: 'sender', operand: 'notification-bot@zulip.com', negated: true },
            { operator: 'sender', operand: 'welcome-bot@zulip.com', negated: true },
            { operator: 'stream', operand: streamId },
        ];
        const queryParams = new URLSearchParams({
            'anchor': anchor,
            'num_before': numBefore,
            'num_after': numAfter,
            'narrow': JSON.stringify(narrow),
        }).toString();
        return this.userRequest$('get', `messages?${queryParams}`);
    }
    getAllMessages$(streamId, anchor, limitAnchor = -1, bulkSize = 200) {
        const anchor$ = new BehaviorSubject(anchor);
        return anchor$.pipe(concatMap(_anchor => this.getMessages$(streamId, _anchor, 0, bulkSize).pipe(map(res => {
            if (limitAnchor !== -1) {
                const limitAnchorMessageIx = res.messages.findIndex(msg => msg.id === limitAnchor);
                if (limitAnchorMessageIx > -1) {
                    return Object.assign({}, res, { messages: res.messages.slice(0, limitAnchorMessageIx) });
                }
            }
            return res;
        }), tap(res => {
            if (res.messages.length - 1 === bulkSize) {
                // continue fetch-chain
                anchor$.next(res.messages[res.messages.length - 1].id);
            }
            else {
                // Cancel fetch chain
                anchor$.next(null);
                anchor$.complete();
            }
        }), map(res => {
            if (res.anchor === anchor) {
                return res;
            }
            // For subsequent responses, remove first message to prevent duplicates
            // Last message of previous response is used as anchor and response
            // of the next page request will contain the same message at position 0
            return produce(res, _res => {
                _res.messages.shift();
            });
        }))), scan((accumRes, res) => {
            return Object.assign({}, accumRes, res, { messages: accumRes.messages.concat(res.messages) });
        }, { messages: [] }), first(() => anchor$.getValue() == null));
    }
    markAllAsRead$(streamId) {
        const body = new FormData();
        body.append('stream_id', streamId + '');
        return this.userRequest$('post', 'mark_stream_as_read', body);
    }
    searchMessages$(search) {
        const narrow = [
            { operator: 'search', operand: search },
            { operator: 'sender', operand: 'notification-bot@zulip.com', negated: true },
            { operator: 'sender', operand: 'welcome-bot@zulip.com', negated: true },
        ];
        const queryParams = new URLSearchParams({
            'anchor': 'newest',
            'num_before': 100,
            'num_after': 100,
            'narrow': JSON.stringify(narrow),
        }).toString();
        return this.userRequest$('get', `messages?${queryParams}`);
    }
    getUnreadsCount(streamId) {
        const narrow = [
            { operator: 'stream', operand: streamId },
            { operator: 'is', operand: 'unread' },
            { operator: 'sender', operand: 'notification-bot@zulip.com', negated: true },
            { operator: 'sender', operand: 'welcome-bot@zulip.com', negated: true }
        ];
        const query = new URLSearchParams({
            'anchor': 'newest',
            'num_before': 100,
            'num_after': 0,
            'narrow': JSON.stringify(narrow),
        }).toString();
        return this.userRequest$('get', `messages?${query}`).pipe(map(res => ({ streamId, count: res.messages.length })));
    }
    getFirstUnread$(streamId) {
        const narrow = [
            { operator: 'stream', operand: streamId },
            { operator: 'sender', operand: 'notification-bot@zulip.com', negated: true },
            { operator: 'sender', operand: 'welcome-bot@zulip.com', negated: true }
        ];
        const query = new URLSearchParams({
            'anchor': 'first_unread',
            'num_before': 0,
            'num_after': 1,
            'narrow': JSON.stringify(narrow),
        }).toString();
        return this.userRequest$('get', `messages?${query}`).pipe(map(res => res.messages.length > 0 ? res.anchor : null));
    }
    getFirstUnreadByMessage$(messageId) {
        return this.getMessage$(messageId).pipe(switchMap(res => this.getFirstUnread$(res.stream_id).pipe(map(firstUnread => ({ streamId: res.stream_id, firstUnread })))));
    }
    sendMessage$(streamId, content, localId) {
        return zip(this.eventQueueId$.pipe(first()), this.getUserCreds()).pipe(switchMap(([queueId, token]) => {
            const body = new FormData();
            body.append('type', 'stream');
            body.append('to', streamId.toString());
            body.append('content', content);
            body.append('topic', 'general');
            if (localId) {
                body.append('local_id', localId);
                body.append('queue_id', queueId);
            }
            const req = {
                headers: new Headers({
                    'Authorization': 'Basic ' + token
                }),
                method: 'POST',
                body,
            };
            return fetch(`${this.ZULIP_API_ENDPOINT}/messages`, req)
                .then(res => res.json());
        }));
    }
    createStream$(students, title = '') {
        const streamName = 'pm_' + generateId();
        const hasTitle = typeof title === 'string' && title.trim() !== '';
        const subscription = {
            name: streamName,
            description: JSON.stringify({
                type: 'pm',
                title: hasTitle ? title.trim() : null
            })
        };
        const body = new FormData();
        body.append('subscriptions', JSON.stringify([subscription]));
        body.append('principals', JSON.stringify(students));
        body.append('announce', 'false');
        body.append('invite_only', 'true');
        return this.userRequest$('post', 'users/me/subscriptions', body).pipe(switchMap(res => {
            if (res.result === 'success') {
                return this.getStreamId$(streamName);
            }
            return throwError(res);
        }));
    }
    getStreamId$(streamName) {
        return this.userRequest$('get', `get_stream_id?stream=${streamName}`);
    }
    getUser(id) {
        return this.getUserCreds().then(token => {
            const query = new URLSearchParams({
                include_custom_profile_fields: 'true'
            }).toString();
            const req = {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Basic ' + token
                })
            };
            return fetch(`${this.ZULIP_API_ENDPOINT}/users/${id}?${query}`, req)
                .then(res => res.json())
                .then(res => {
                if (res.result === 'error') {
                    throw new Error(res.msg);
                }
                return res.user;
            });
        });
    }
    markAsRead(ids) {
        const body = new FormData();
        body.append('messages', JSON.stringify(ids));
        body.append('op', 'add');
        body.append('flag', 'read');
        return this.userRequest$('post', 'messages/flags', body);
    }
    /**
     * @param status presence value. Can be 'idle' or 'active'
     * @param isPingOnly can be set to `false` for retrieving other users presence status.
     */
    updateUserPresence$(status, isPingOnly = true) {
        const body = new FormData();
        body.append('status', status);
        body.append('ping_only', isPingOnly + '');
        body.append('slim_presence', 'true');
        body.append('new_user_input', 'true');
        return this.userCredentials$.pipe(switchMap((token) => {
            const req = {
                headers: new Headers({ 'Authorization': 'Basic ' + token }),
                method: 'POST',
                body,
                keepalive: true
            };
            return fetch(`${this.ZULIP_API_ENDPOINT}/users/me/presence`, req)
                .then(res => res.json());
        }));
    }
    uploadFile(file) {
        const body = new FormData();
        body.append('files', file);
        return this.userRequest$('post', 'user_uploads', body);
    }
    getFile(link) {
        return this.userCredentials$.pipe(switchMap((token) => {
            const req = {
                headers: new Headers({ 'Authorization': 'Basic ' + token }),
                method: 'GET',
            };
            return fetch(this.envService.apiConfig.zulipInstanceEndpoint + link, req).then(res => res.blob());
        }));
    }
    getAttachments$(streamId, anchor = 'newest', numBefore = 200, numAfter = 0) {
        const narrow = [
            { operator: 'stream', operand: streamId },
            { operator: 'has', operand: 'attachment' },
        ];
        const queryParams = new URLSearchParams({
            'anchor': anchor,
            'num_before': numBefore,
            'num_after': numAfter,
            'narrow': JSON.stringify(narrow),
        }).toString();
        return this.userRequest$('get', `messages?${queryParams}`);
    }
    getSubscriptionsMembers$() {
        return this.httpService.get$('v1/zulip/subscriptions_members');
    }
    userRequest$(method, url, body = null) {
        return this.userCredentials$.pipe(switchMap(token => {
            return ajax({
                headers: {
                    'Authorization': `Basic ${token}`,
                },
                url: `${this.ZULIP_API_ENDPOINT}/${url}`,
                method,
                body,
            }).pipe(map(res => {
                if (res.response.result === 'error') {
                    throw res.response;
                }
                return res.response;
            }));
        }));
    }
    getEvents$(queueId, lastEventId = -1) {
        const req = this.getUserCreds()
            .then(token => {
            const req = {
                headers: new Headers({
                    'Authorization': 'Basic ' + token
                }),
                method: 'GET',
            };
            const queryParams = new URLSearchParams({
                queue_id: queueId,
                last_event_id: lastEventId.toString(),
                dont_block: 'false',
            }).toString();
            return fetch(`${this.ZULIP_API_ENDPOINT}/events?${queryParams}`, req);
        })
            .then(res => res.json());
        return from(req);
    }
    listenEvents$(queueId) {
        let lastEventId = -1;
        const poll$ = new BehaviorSubject(null);
        return poll$.pipe(filter(() => this.authService.isAuthenticated), concatMap(_ => {
            const events$ = this.getEvents$(queueId, lastEventId).pipe(switchMap(res => {
                if (res.result === 'error') {
                    // if ('retry-after' in res) {
                    //   return of(res);
                    // }
                    return throwError(res);
                }
                return of(res);
            }), tap(res => {
                const actualLastEventId = Math.max(...res.events.map(({ id }) => id));
                if (actualLastEventId !== lastEventId) {
                    lastEventId = actualLastEventId;
                }
            }));
            return events$;
        }), tap(() => poll$.next()));
    }
    registerEventQueue$() {
        const body = new FormData();
        body.append('event_types', `["message", "subscription", "presence", "update_message_flags"]`);
        body.append('fetch_event_types', `["message", "subscription", "update_message_flags"]`);
        body.append('all_public_streams', `false`);
        body.append('include_subscribers', `true`);
        body.append('slim_presence', `true`);
        // TODO: test new messages events both from sender and receiver perspective
        body.append('apply_markdown', `true`);
        return this.userRequest$('post', 'register', body);
    }
    unregisterEventQueue(queue_id) {
        const query = new URLSearchParams({ queue_id }).toString();
        return this.userRequest$('delete', `events?${query}`);
    }
}
ZulipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ZulipService_Factory() { return new ZulipService(i0.ɵɵinject(i1.Storage), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.HttpService), i0.ɵɵinject(i4.EnvService)); }, token: ZulipService, providedIn: "root" });
