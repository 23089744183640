export enum PopoverNotificationType {
    MEETING_ATTENDEE = 'MEETING_ATTENDEE',
    MEETING_JOIN = 'MEETING_JOIN',
}

export interface IPopoverNotification<T> {
    uuid: string;
    type: PopoverNotificationType;
    payload: T;
}
