import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePickerComponent } from './time-picker.component';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';

@NgModule({
  declarations: [
    TimePickerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    StyledScrollbarModule,
  ],
  exports: [
    TimePickerComponent,
  ],
  entryComponents: [
    TimePickerComponent,
  ]
})
export class TimePickerModule { }
