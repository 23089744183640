import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { InviteLoadingModalComponent } from './invite-loading-modal.component';

@NgModule({
  declarations: [InviteLoadingModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  exports: [InviteLoadingModalComponent],
  entryComponents: [InviteLoadingModalComponent],
})
export class InviteLoadingModalModule { }
