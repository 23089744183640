import { Component, Input, OnInit, EventEmitter, Output, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { FilePreviewMode } from '../files-preview/types';

@Component({
  selector: 'app-files-image-preview',
  templateUrl: './files-image-preview.component.html',
  styleUrls: ['./files-image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesImagePreviewComponent implements OnInit {
  @Input() src: string = '';
  @Input() previewMode: FilePreviewMode;
  @Input() canRemove: boolean = false;
  @Output() remove = new EventEmitter();
  @Output() download = new EventEmitter();

  @HostBinding('class')
  public get className() {
    return this.previewMode === FilePreviewMode.BIG ? 'big' : 'small';
  }

  constructor() { }

  ngOnInit() {}

}
