<div
  (click)="handleOverlayClick()"
  [ngStyle]="overlayStyles$ | async" class="onboarding-overlay">
</div>
<div class="onboarding-popover__arrow" [ngStyle]="guidePopoverArrowStyles$ | async"></div>
<div class="onboarding-popover" [ngStyle]="guidePopoverStyles$ | async">
  <app-onboarding-guide
    *ngIf="onboarding$ | async as onboarding"
    [onboarding]="onboarding"
    (onDismiss)="handleDismiss()">
  </app-onboarding-guide>
</div>