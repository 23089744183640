import { Injectable } from '@angular/core';
import {BehaviorSubject, combineLatest, merge, Observable, of, zip} from 'rxjs';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';
import { IClass } from '../models/class';
import { EntityType, IBaseEntity, IReceivedEvent } from '../models/common';
import { IResponse } from '../models/response';
import { ISchool } from '../models/school';
import { ISubject } from '../models/subject';
import { ClassService } from './class.service';
import { SchoolService } from './school.service';
import { IBaseState } from './stores/base-store.class';
import { ClassStoreService } from './stores/class-store.service';
import { SchoolStoreService } from './stores/school-store.service';
import { SubjectStoreService } from './stores/subject-store.service';
import { SubjectService } from './subject.service';

type TypedEntities = Record<EntityType, (ISubject | IClass | ISchool)[]>;

@Injectable({
  providedIn: 'root'
})
export class EntityAdapterService {
  private sharedState$: Observable<TypedEntities>;
  public readonly onEntityUpdate$: Observable<IReceivedEvent<ISubject | IClass | ISchool>>;
  private readonly onEntitiesLoadedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private subjectStoreService: SubjectStoreService,
    private classStoreService: ClassStoreService,
    private schoolStoreService: SchoolStoreService,
    private subjectService: SubjectService,
    private classService: ClassService,
    private schoolService: SchoolService,
  ) {
    const allStates$ = combineLatest([
      this.subjectStoreService.getState$().pipe(
        map((state) => ({
          etities: this.flatMapStoreState(state),
          type: EntityType.SUBJECT
        }))
      ),
      this.classStoreService.getState$().pipe(
        map((state) => ({
          etities: this.flatMapStoreState(state),
          type: EntityType.CLASS
        }))
      ),
      this.schoolStoreService.getState$().pipe(
        map((state) => ({
          etities: this.flatMapStoreState(state),
          type: EntityType.SCHOOL
        }))
      )
    ]);

    zip(
        this.subjectStoreService.getState$().pipe(first(entities => entities !== null)),
        this.classStoreService.getState$().pipe(first(entities => entities !== null)),
        this.schoolStoreService.getState$().pipe(first(entities => entities !== null)),
    ).pipe(first()).subscribe(() => {
      this.onEntitiesLoadedSubject$.next(true);
    });

    this.sharedState$ = allStates$.pipe(
      map(entries => entries.reduce<TypedEntities>(
        (all, entry) => {
          all[entry.type] = entry.etities
          return all
        },
        {} as TypedEntities)
      ),
      shareReplay(1)
    );

    this.onEntityUpdate$ = merge(
      this.subjectService.notifier$,
      this.classService.notifier$,
      this.schoolService.notifier$
    ).pipe(
      shareReplay(1)
    );
  }

  public get onEntitiesLoaded$(): Observable<boolean> {
    return this.onEntitiesLoadedSubject$.asObservable();
  }

  getOne$(id: string, type: EntityType, securityLevel: 'public' | 'private' = 'private'): Observable<ISubject | IClass | ISchool> {
    return this.sharedState$.pipe(
      first(),
      map(state => {
        if (Array.isArray(state[type])) {
          return state[type].find(entry => entry.id === id);
        }
        return null;
      }),
      switchMap(data => data != null ? of(data) : this.getRemoteOne$(id, type, securityLevel))
    )
  }

  getRemoteOne$(id: string, type: EntityType, securityLevel: string): Observable<ISubject | IClass | ISchool> {
    let source$: Observable<IResponse<ISubject | IClass | ISchool>> = of(null);

    switch (type) {
      case EntityType.SUBJECT: {
        source$ = securityLevel === 'public' ? this.subjectService.getPublicData$(id) : this.subjectService.getOne$(id);
        break;
      }
      case EntityType.SCHOOL: {
        source$ = this.schoolService.getOne$(id);
        break;
      }
      case EntityType.CLASS: {
        source$ = this.classService.getOne$(id);
        break;
      }
      default: throw new Error(`Unsupported entity type ${type}`);
    }

    return source$.pipe(
      map(res => res.payload ? res.payload : null)
    )
  }

  private flatMapStoreState<T extends IBaseState<E>, E extends IBaseEntity>(state: T) {
    if (state == null) return [];

    return Object.values(state).reduce(
      (all, entities) => all.concat(entities),
      []
    )
  }
}
