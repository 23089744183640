import { SchoolAndClassDashboardPermissions , SchoolAndClassTicketPermissions} from './base.abstract.class';
import { ISchool } from '../../models/school';
import { SchoolDashboardColumn } from 'src/app/pages/school-dashboard/helpers';
import { IDashboardColumn } from '../../models/common';
import {ITicket} from '../../models/ticket';
import {isPublishedAndDatePast} from '../../components/ticket-item/helpers';

export class SchoolDashboardPermissions extends SchoolAndClassDashboardPermissions {
  NOT_EDITED_COLUMNS: string[] = Object.keys(SchoolDashboardColumn)
    .map(title => title.toLowerCase());
  FORBIDDEN_ADD_TICKETS_COLUMNS: string[] = [
    SchoolDashboardColumn.TEACHERS,
    SchoolDashboardColumn.STUDENTS,
  ].map(title => title.toLowerCase());

  canAddTicketsToColumn(school: ISchool, column: IDashboardColumn): boolean {
    if (this.FORBIDDEN_ADD_TICKETS_COLUMNS.includes(column.title.toLowerCase())) {
      return false;
    }

    if (column.title.toLowerCase() === SchoolDashboardColumn.CLASSES.toLowerCase()) {
      return this.isOwnerOrInvitedTeacher(school);
    }

    return this.isOwner(school);
  }
}

export class SchoolTicketPermissions extends SchoolAndClassTicketPermissions {

  canEditDate(entity: ITicket): boolean {
    return !isPublishedAndDatePast(entity) && this.canEditOrRemoveEntity(entity);
  }
}
