import { Injectable } from '@angular/core';
import { BaseStoreService, IBaseStoreAction, IBaseState } from './base-store.class';
import { ToastService } from '../toast.service';
import { Observable, forkJoin } from 'rxjs';
import { IResponse } from '../../models/response';
import { EventType, UserRole } from '../../models/common';
import { map, filter } from 'rxjs/operators';
import { getBaseStoreEvent } from './helpers';
import { SchoolService } from '../school.service';
import { ISchool } from '../../models/school';
import { InviteService } from '../invite.service';
import { isSuccess } from '../../helpers/helpers';

export const INIT_STATE: IBaseState<ISchool> = { teach: [], study: [] };

@Injectable({
  providedIn: 'root'
})
export class SchoolStoreService extends BaseStoreService<ISchool> {

  constructor(
    private schoolService: SchoolService,
    protected toastService: ToastService,
    protected inviteService: InviteService,
  ) {
    super(INIT_STATE, toastService, inviteService);
  }

  protected keyExtractor = (school: ISchool) => {
    return school.role === UserRole.TEACHER
      ? 'teach'
      : 'study';
  }

  protected getItems$(): Observable<IResponse<ISchool[]>> {
    return forkJoin([
      this.schoolService.getAllTeach$(),
      this.schoolService.getAllStudy$(),
    ])
    .pipe(
      map(responses => {
        if (responses[0].error) {
          return responses[0];
        }
        if (responses[1].error) {
          return responses[1];
        }
        return { payload: [ ...responses[0].payload, ...responses[1].payload ] };
      })
    );
  }

  protected getItem$(id: string): Observable<IResponse<ISchool>> {
    return this.schoolService.getOne$(id);
  }

  protected getUpdates$(): Observable<IBaseStoreAction> {
    return this.schoolService.notifier$
      .pipe(
        filter(event => isSuccess(event.response)),
        filter(event => [EventType.ADD, EventType.EDIT, EventType.REMOVE].includes(event.eventType)),
        map(event => ({
          type: getBaseStoreEvent(event.eventType),
          payload: event.response.payload ? event.response.payload : event.entityId
        } as IBaseStoreAction)),
      );
  }
}
