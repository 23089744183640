import { BaseStoreService } from './base-store.class';
import { ToastService } from '../toast.service';
import { forkJoin } from 'rxjs';
import { EventType, UserRole } from '../../models/common';
import { map, filter } from 'rxjs/operators';
import { getBaseStoreEvent } from './helpers';
import { SchoolService } from '../school.service';
import { InviteService } from '../invite.service';
import { isSuccess } from '../../helpers/helpers';
import * as i0 from "@angular/core";
import * as i1 from "../school.service";
import * as i2 from "../toast.service";
import * as i3 from "../invite.service";
export const INIT_STATE = { teach: [], study: [] };
export class SchoolStoreService extends BaseStoreService {
    constructor(schoolService, toastService, inviteService) {
        super(INIT_STATE, toastService, inviteService);
        this.schoolService = schoolService;
        this.toastService = toastService;
        this.inviteService = inviteService;
        this.keyExtractor = (school) => {
            return school.role === UserRole.TEACHER
                ? 'teach'
                : 'study';
        };
    }
    getItems$() {
        return forkJoin([
            this.schoolService.getAllTeach$(),
            this.schoolService.getAllStudy$(),
        ])
            .pipe(map(responses => {
            if (responses[0].error) {
                return responses[0];
            }
            if (responses[1].error) {
                return responses[1];
            }
            return { payload: [...responses[0].payload, ...responses[1].payload] };
        }));
    }
    getItem$(id) {
        return this.schoolService.getOne$(id);
    }
    getUpdates$() {
        return this.schoolService.notifier$
            .pipe(filter(event => isSuccess(event.response)), filter(event => [EventType.ADD, EventType.EDIT, EventType.REMOVE].includes(event.eventType)), map(event => ({
            type: getBaseStoreEvent(event.eventType),
            payload: event.response.payload ? event.response.payload : event.entityId
        })));
    }
}
SchoolStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SchoolStoreService_Factory() { return new SchoolStoreService(i0.ɵɵinject(i1.SchoolService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.InviteService)); }, token: SchoolStoreService, providedIn: "root" });
