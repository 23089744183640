import { Subject } from "rxjs";
import * as i0 from "@angular/core";
export class ChatToggleService {
    constructor() {
        this.openChatNotifier$ = new Subject();
    }
    get openChatNotifier() {
        return this.openChatNotifier$.asObservable();
    }
    openChat$() {
        this.openChatNotifier$.next();
    }
}
ChatToggleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatToggleService_Factory() { return new ChatToggleService(); }, token: ChatToggleService, providedIn: "root" });
