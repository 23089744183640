import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradesCrudComponent } from './grades-crud.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GradesCustomGeneratorComponent } from './grades-custom-generator/grades-custom-generator.component';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { CheckboxModule } from '../checkbox/checkbox.module';



@NgModule({
  declarations: [
    GradesCrudComponent,
    GradesCustomGeneratorComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    StyledScrollbarModule,
    CheckboxModule,
  ],
  exports: [
    GradesCrudComponent,
  ],
})
export class GradesCrudModule { }
