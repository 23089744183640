import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { InstantMeetCopyWidgetComponent } from './instant-meet-copy-widget.component';

@NgModule({
  imports: [ CommonModule, TranslateModule, IonicModule,],
  declarations: [InstantMeetCopyWidgetComponent],
  entryComponents: [InstantMeetCopyWidgetComponent],
  exports: [InstantMeetCopyWidgetComponent]
})
export class InstantMeetCopyWidgetModule {}
