import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {IonSlides, ModalController} from '@ionic/angular';
import {concat, forkJoin, Observable, of} from 'rxjs';
import {first, map, switchMap} from 'rxjs/operators';
import {EntityType, IChangeParentPayload, IConfirmResponse, UserTypes} from '../../models/common';
import {UserModel} from '../../models/user/user';
import {InviteService} from '../../services/invite.service';

interface UserVM {
  data: UserModel;
  shouldMove: boolean;
}

interface EntityChangeInput {
  srcTitle: string;
  destTitle: string;
}

@Component({
  selector: 'app-parent-change-confirm',
  templateUrl: './parent-change-confirm.component.html',
  styleUrls: ['./parent-change-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParentChangeConfirmComponent implements OnInit, AfterViewInit {
  @Input() entityId: string;
  @Input() nextParentEntityId: string;
  @Input() nextParentEntity: EntityType;
  @Input() entityType: EntityType;
  @Input() entityChange: EntityChangeInput;

  @ViewChild(IonSlides, { static: false }) slidesRef: IonSlides;

  usersList: UserVM[];
  slidesOptions = {
    allowTouchMove: false,
  }
  showBackBtn$: Observable<boolean>;

  constructor(
    private inviteService: InviteService,
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef,
  ) { }

  public get isClassEntityChange() {
    return this.entityType === EntityType.SUBJECT;
  }

  ngOnInit() {
    const filterCurrentEntities = [{ entityId: this.entityId, entityType: this.entityType }];
    const filterNewEntities = [{ entityId: this.nextParentEntityId, entityType: this.nextParentEntity }];

    const currentEntityUsers$ = this.inviteService
        .getUsers$('', UserTypes.ALL, filterCurrentEntities, null, null)
        .pipe(
            first(),
            map(res => res.payload.filter(user => !user.deactivated)),
        );

    const newEntityUsers$ = this.inviteService
        .getUsers$('', UserTypes.ALL, filterNewEntities, null, null)
        .pipe(
            first(),
            map(res => res.payload.filter(user => !user.deactivated)),
        );

    forkJoin(currentEntityUsers$, newEntityUsers$)
        .pipe(
            first(),
            map(([users, nextEntityUsers]) => {
              return users
                  .filter(user => nextEntityUsers.findIndex(nextUser => nextUser.id === user.id) === -1)
                  .map((user): UserVM => {
                    return {
                      data: user,
                      shouldMove: true,
                    };
                  });
            })
        )
      .subscribe(users => {
        this.usersList = users;
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit() {
    const slideChange$ = this.slidesRef.ionSlideWillChange.pipe(
      switchMap(() => this.slidesRef.isEnd())
    );
    this.showBackBtn$ = concat(of(false), slideChange$);
  }

  toggleUserShouldMove(user) {
    user.shouldMove = !user.shouldMove;
  }

  dismiss() {
    this.modalCtrl.dismiss({
      isConfirmed: false,
      payload: false,
    } as IConfirmResponse);
  }

  async next() {
    const activeIndex = await this.slidesRef.getActiveIndex();

    if (activeIndex === 0) {
      this.slidesRef.slideNext();
    } else {
      this.confirm();
    }
  }

  async back() {
    this.slidesRef.slidePrev();
  }

  confirm() {
    const usersToMove = this.getUsersToMove();
    // Omiting "user_ids_for_migration" in payload
    // for `SubjectService.changeClass$` or `ClassService.changeSchool$`
    // will tell api to move all students/teachers along
    const payload: IChangeParentPayload = usersToMove.length === this.usersList.length
      ? {}
      : { user_ids_for_migration: usersToMove.map(entry => entry.data.id) }

    this.modalCtrl.dismiss({
      isConfirmed: true,
      payload,
    } as IConfirmResponse<IChangeParentPayload>);
  }

  getUsersToMove() {
    return this.usersList.filter(user => user.shouldMove);
  }

  getUsersToStay() {
    return this.usersList.filter(user => !user.shouldMove);
  }

}
