import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatLogComponent } from './chat-log.component';
import { QuillModule } from 'ngx-quill';
import { TranslateModule } from '@ngx-translate/core';
import { RemoveContentSizingDirectiveModule } from '../../directives/remove-content-sizing/remove-content-sizing.module';
import { PipesModule } from '../../pipes/pipes.module';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';
import { RenderAttachmentDirective } from './render-attachment.directive';
import { RenderAttachmentsOutletDirective } from './render-attachments-outlet.directive';
import { FilesImagePreviewComponentModule } from '../files-image-preview/files-image-preview.module';
import { MessageAttachmentsComponent } from './message-attachments/message-attachments.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    QuillModule,
    TranslateModule,
    RemoveContentSizingDirectiveModule,
    PipesModule,
    FilesImagePreviewComponentModule,
    StyledScrollbarModule
  ],
  declarations: [
    ChatLogComponent,
    MessageAttachmentsComponent,
    RenderAttachmentDirective,
    RenderAttachmentsOutletDirective,
  ],
  exports: [ChatLogComponent]
})
export class ChatLogComponentModule {}
