import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatAttachmentsComponent } from './chat-attachments.component';
import { StyledScrollbarModule } from '../styled-scrollbar/styled-scrollbar.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    StyledScrollbarModule,
  ],
  declarations: [ChatAttachmentsComponent],
  exports: [ChatAttachmentsComponent]
})
export class ChatAttachmentsModule {}
