import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {ISchool} from '../models/school';
import {HttpService} from './http.service';
import {
  EntityType,
  EventType,
  IDashboard,
  IDashboardColumn,
  INotifyUpdatePayload,
  UserRole,
  UserTypes
} from '../models/common';
import {forkJoin, Observable} from 'rxjs';
import {IResponse} from '../models/response';
import {map, share, tap} from 'rxjs/operators';
import {IClass} from '../models/class';
import {addDashboardColumn$, editDashboardColumn$, removeDashboardColumn$} from '../helpers/helpers';
import {UserModel} from '../models/user/user';
import {ITicket} from '../models/ticket';
import {InviteService} from './invite.service';
import {SocketService} from './socket.service';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class SchoolService extends BaseService<ISchool> {

  private readonly SCHOOL_URL: string = 'v1/schools';

  protected entityType: EntityType = EntityType.SCHOOL;
  protected GET_ALL: string = this.SCHOOL_URL;
  protected GET_ALL_TO_TEACH: string = `${this.SCHOOL_URL}/teach`;
  protected GET_ALL_TO_STUDY: string = `${this.SCHOOL_URL}/study`;
  protected POST_ONE: string = this.SCHOOL_URL;
  protected PUT_ONE: string = this.SCHOOL_URL;
  protected PATCH_ONE: string = this.SCHOOL_URL;
  protected GET_ONE: (id: string) => string = (id: string) => `${this.SCHOOL_URL}/${id}`;
  protected DELETE_ONE: (id: string) => string = (id: string) => `${this.SCHOOL_URL}/${id}`;
  protected GET_DASHBOARD_BY_SCHOOL = (id: string) => `${this.SCHOOL_URL}/dashboard/${id}`;
  protected GET_DASHBOARD_DATA_BY_SCHOOL_BY_PAGE = (id: string, page: number, limit: number, columnId: string, selectedDate: string) => `${this.SCHOOL_URL}/dashboard-data/${id}?page=${page}&limit=${limit}&columnId=${columnId}&selectedDate=${selectedDate}`;
  protected GET_DASHBOARD_DATA_BY_SCHOOL_INIT = (id: string, limitByColumn: number) => `${this.SCHOOL_URL}/dashboard-data/${id}/init?limitByColumn=${limitByColumn}`;
  protected GET_CLASSES_BY_SCHOOL = (id: string) => `${this.SCHOOL_URL}/${id}/classes`;

  protected GET_TIMELINE_DATES_BY_COLUMN = (columnId: string, schoolId: string) => `v1/school-tickets/timeline-dates?columnId=${columnId}&schoolId=${schoolId}`;
  protected GET_TIMELINE_DATES_BY_ALL_COLUMNS = (schoolId: string) => `v1/school-tickets/timeline-dates-by-all-columns?schoolId=${schoolId}`;
  protected GET_TICKETS_NEAR_DATE_BY_COLUMN = (schoolId: string, columnId: string, selectedDate: string) => `v1/school-tickets/near-selected-date?schoolId=${schoolId}&columnId=${columnId}&selectedDate=${selectedDate}`;

  constructor(
    protected httpService: HttpService,
    protected toastService: ToastService,
    private inviteService: InviteService,
    private socketService: SocketService,
  ) {
    super(httpService, toastService);

    this.socketService.getCrudMessages$([EntityType.SCHOOL])
      .subscribe(payload => this.notifyAboutUpdate(payload.eventType, { payload: payload.entity }, { entityId: payload.entityId }));
  }

  getAllTeach$(): Observable<IResponse<ISchool[]>> {
    return this.httpService.get$<ISchool[]>(this.GET_ALL)
      .pipe(share());
  }

  getAllStudy$(): Observable<IResponse<ISchool[]>> {
    return this.httpService.get$<ISchool[]>(this.GET_ALL_TO_STUDY)
      .pipe(share());
  }

  getDashboardBySchoolId$(id: string): Observable<IResponse<IDashboard>> {
    return this.httpService.get$(this.GET_DASHBOARD_BY_SCHOOL(id));
  }

  getClassesBySchoolId$(id: string): Observable<IResponse<IClass[]>> {
    return this.httpService.get$(this.GET_CLASSES_BY_SCHOOL(id));
  }

  addDashboardColumn$(column: IDashboardColumn, schoolId: string): Observable<IResponse<IDashboardColumn>> {
    return addDashboardColumn$(column, schoolId, EntityType.SCHOOL, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.ADD_COLUMN, response, { entityId: schoolId })));
  }

  editDashboardColumn$(column: IDashboardColumn, schoolId: string): Observable<IResponse<IDashboardColumn>> {
    return editDashboardColumn$(column, EntityType.SCHOOL, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.EDIT_COLUMN, response, { entityId: schoolId })));
  }

  removeDashboardColumn$(column: IDashboardColumn, schoolId: string): Observable<IResponse<IDashboardColumn>> {
    return removeDashboardColumn$(column.id, EntityType.SCHOOL, this.httpService)
      .pipe(tap(response => this.notifyAboutUpdateColumn(EventType.REMOVE_COLUMN, response, { entityId: schoolId })));
  }

  getDashboardTicketsInit$(schoolId: string, limitByColumn: number): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SCHOOL_INIT(schoolId, limitByColumn));
  }

  getDashboardTicketsByPage$(schoolId: string, page: number, limit: number, columnId: string, selectedDate: string): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_DASHBOARD_DATA_BY_SCHOOL_BY_PAGE(schoolId, page, limit, columnId, selectedDate));
  }

  getDashboardUsers$(school: ISchool, page: number, limit: number): Observable<IResponse<UserModel[]>> {
    return forkJoin([
      this.inviteService.getUsers$('', UserTypes.TEACHER, [{ entityId: school.id, entityType: EntityType.SCHOOL }], page, limit, false),
      this.inviteService.getUsers$('', UserTypes.STUDENT, [{ entityId: school.id, entityType: EntityType.SCHOOL }], page, limit, false, false),
    ])
    .pipe(
      map(responses => {
        for (const response of responses) {
          if (response.error) {
            return { error: response.error, message: response.message };
          }
        }

        return {
          payload: [
            ...responses[0].payload.map(user => ({ ...user, role: UserRole.TEACHER })),
            ...responses[1].payload.map(user => ({ ...user, role: UserRole.STUDENT })),
          ],
        };
      }),
    );
  }

  getTimelineDatesByColumn$(
    columnId: string,
    schoolId: string,
  ): Observable<IResponse<string[]>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_COLUMN(columnId, schoolId));
  }

  getTimelineDatesByAllColumns$(
    schoolId: string,
  ): Observable<IResponse<{ [columnId: string]: string[] }>> {
    return this.httpService.get$(this.GET_TIMELINE_DATES_BY_ALL_COLUMNS(schoolId));
  }

  getTicketsNearDateByColumn$(
    schoolId: string,
    columnId: string,
    selectedDate: string,
  ): Observable<IResponse<ITicket[]>> {
    return this.httpService.get$(this.GET_TICKETS_NEAR_DATE_BY_COLUMN(schoolId, columnId, selectedDate));
  }

  protected notifyAboutUpdateColumn(eventType: EventType, response: IResponse<ISchool>, payload: INotifyUpdatePayload) {
    if (response.error || !this.socketService.isConnected()) {
      this.notifyAboutUpdate(eventType, response, payload);
    }
  }
}
