import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate/truncate.pipe';
import { UnescapeHtmlPipe } from './unescape-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SecureImagePipe } from './secure-image/secure-image.pipe';
import { MeetingTypeLocalePipe } from './meeting-type-locale.pipe';
import { DateLocalePipe } from './date-locale.pipe';

@NgModule({
  declarations: [
    TruncatePipe,
    UnescapeHtmlPipe,
    SafeUrlPipe,
    SecureImagePipe,
    MeetingTypeLocalePipe,
    DateLocalePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TruncatePipe,
    UnescapeHtmlPipe,
    SafeUrlPipe,
    SecureImagePipe,
    MeetingTypeLocalePipe,
    DateLocalePipe,
  ],
})
export class PipesModule { }
