import { Injectable, isDevMode } from "@angular/core";
import { fromEvent, merge, timer } from 'rxjs';
import { debounceTime, map, share, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

export const CLIENT_IDLE_TIMEOUT_SECONDS = 5 * 60;
export const MOBILE_WINDOW_LANDSCAPE_WIDTH = 576;
export const TABLET_WINDOW_LANDSCAPE_WIDTH = 767;

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  public readonly resize$ = fromEvent(window, 'resize').pipe(shareReplay(1));
  public readonly active$ = this.onActive$().pipe(debounceTime(1000));
  public readonly idle$ = this.onIdle$(CLIENT_IDLE_TIMEOUT_SECONDS).pipe(shareReplay(1));
  public readonly focus$ = fromEvent(window, 'focus').pipe(shareReplay(1));

  private onActive$() {
    const activationEvents: [any, string][] = [
      [document, "click"],
      [document, "wheel"],
      [document, "scroll"],
      [document, "mousemove"],
      [document, "keyup"],
      [window, "resize"],
      [window, "scroll"],
      [window, "mousemove"],
      [window, "focus"],
    ];
    const activationEvents$ = activationEvents.map(([source, ev]) =>
      fromEvent(source, ev)
    );
    return merge(...activationEvents$);
  }

  public onIdle$(timeoutSeconds: number) {
    const idleTimeout$ = timer(timeoutSeconds * 1e3);
    const active$ = this.onActive$().pipe(
      debounceTime(1000),
      share(),
    );
    return active$.pipe(
      switchMap(() => idleTimeout$.pipe(takeUntil(active$))),
      map(() => true)
    )
  }
}
