import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { ChatWidgetPage } from './chat-widget.page';
import { ChatLogPageModule } from './chat-log/chat-log.module';
import { ChatNavPageModule } from './chat-nav/chat-nav.module';
import { CreateChatPageModule } from './create-chat/create-chat.module';
import { ChatInfoPageModule } from './chat-info/chat-info.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ChatLogPageModule,
    ChatNavPageModule,
    CreateChatPageModule,
    ChatInfoPageModule,
    TranslateModule,
  ],
  declarations: [ChatWidgetPage],
  entryComponents: [ChatWidgetPage],
  exports: [ChatWidgetPage]
})
export class ChatWidgetModule {}
