import * as i0 from "@angular/core";
export class LinkStorageService {
    constructor() {
        this.INVITE_LINK_STORAGE_KEY = 'invitation_session';
        this.TICKET_LINK_STORAGE_KEY = 'ticket_route_session';
        this.SCHOOL_LINK_STORAGE_KEY = 'school_route_session';
        this.CLASS_LINK_STORAGE_KEY = 'class_route_session';
        this.SUBJECT_LINK_STORAGE_KEY = 'subject_route_session';
        this.SHARE_LINK_STORAGE_KEY = 'share_link_session';
    }
    saveInviteLink(value) {
        this.saveLink(value, this.INVITE_LINK_STORAGE_KEY);
    }
    saveTicketLink(value) {
        this.saveLink(value, this.TICKET_LINK_STORAGE_KEY);
    }
    saveSchoolLink(value) {
        this.saveLink(value, this.SCHOOL_LINK_STORAGE_KEY);
    }
    saveClassLink(value) {
        this.saveLink(value, this.CLASS_LINK_STORAGE_KEY);
    }
    saveSubjectLink(value) {
        this.saveLink(value, this.SUBJECT_LINK_STORAGE_KEY);
    }
    saveShareLink(value) {
        this.saveLink(value, this.SHARE_LINK_STORAGE_KEY);
    }
    getInviteLink() {
        return this.get(this.INVITE_LINK_STORAGE_KEY);
    }
    getTicketLink() {
        return this.get(this.TICKET_LINK_STORAGE_KEY);
    }
    getSchoolLink() {
        return this.get(this.SCHOOL_LINK_STORAGE_KEY);
    }
    getClassLink() {
        return this.get(this.CLASS_LINK_STORAGE_KEY);
    }
    getSubjectLink() {
        return this.get(this.SUBJECT_LINK_STORAGE_KEY);
    }
    getShareLink() {
        return this.get(this.SHARE_LINK_STORAGE_KEY);
    }
    clearAll() {
        this.removeInviteLink();
        this.removeTicketLink();
        this.removeSchoolLink();
        this.removeClassLink();
        this.removeSubjectLink();
        this.removeShareLink();
    }
    removeInviteLink() {
        this.remove(this.INVITE_LINK_STORAGE_KEY);
    }
    removeTicketLink() {
        this.remove(this.TICKET_LINK_STORAGE_KEY);
    }
    removeSchoolLink() {
        this.remove(this.SCHOOL_LINK_STORAGE_KEY);
    }
    removeClassLink() {
        this.remove(this.CLASS_LINK_STORAGE_KEY);
    }
    removeSubjectLink() {
        this.remove(this.SUBJECT_LINK_STORAGE_KEY);
    }
    removeShareLink() {
        this.remove(this.SHARE_LINK_STORAGE_KEY);
    }
    saveLink(value, key) {
        this.clearAll();
        this.save(key, value);
    }
    save(key, value) {
        sessionStorage.setItem(key, value);
    }
    get(key) {
        return sessionStorage.getItem(key);
    }
    remove(key) {
        sessionStorage.removeItem(key);
    }
}
LinkStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkStorageService_Factory() { return new LinkStorageService(); }, token: LinkStorageService, providedIn: "root" });
