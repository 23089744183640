import {Component, Input} from '@angular/core';
import {trackById} from '../../helpers/helpers';
import {ChatMember} from '../../models/chat';
import {ChatUiService} from '../../services/chat-ui.service';
import {UserModel} from '../../models/user/user';

@Component({
    selector: 'app-chat-members-list',
    templateUrl: './chat-members-list.component.html',
    styleUrls: ['./chat-members-list.component.scss'],
})
export class ChatMembersListComponent {
    @Input() members: ChatMember[];

    trackById = trackById;

    constructor(
        private chatUiService: ChatUiService,
    ) {
    }

    openChat(chatMember: ChatMember): void {
        this.chatUiService.openChatWithUser(chatMember as UserModel);
    }
}
