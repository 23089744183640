import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreateChatPage } from './create-chat.page';
import { ChatFinderComponentModule } from 'src/app/shared/components/chat-finder/chat-finder.module';
import { RemoveContentSizingDirectiveModule } from 'src/app/shared/directives/remove-content-sizing/remove-content-sizing.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    RemoveContentSizingDirectiveModule,
    ChatFinderComponentModule
  ],
  declarations: [CreateChatPage],
  entryComponents: [CreateChatPage],
  exports: [CreateChatPage]
})
export class CreateChatPageModule {}
