import { Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LanguageService } from '../services/language.service';

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
    name: 'dateLocale',
    pure: false,
})
export class DateLocalePipe extends DatePipe {

    constructor(private languageService: LanguageService) {
        super(languageService.currLang);
    }

    transform(value: string, format?: string, timezone?: string, locale?: string): string | null {
        return super.transform(value, format, timezone, this.languageService.currLang);
    }
}
