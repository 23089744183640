import * as tslib_1 from "tslib";
import { ModalController } from '@ionic/angular';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class ConfirmationService {
    constructor(modalCtrl) {
        this.modalCtrl = modalCtrl;
    }
    confirmAction(action, entityName, modalOptions = {}) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const defaultCssClass = 'confirm-modal';
            const modal = yield this.modalCtrl.create(Object.assign({ component: ConfirmationModalComponent, componentProps: {
                    action,
                    entityName
                }, backdropDismiss: true }, modalOptions, { cssClass: 'cssClass' in modalOptions
                    ? [defaultCssClass, modalOptions.cssClass].join(' ')
                    : defaultCssClass }));
            modal.present();
            const { data } = yield modal.onWillDismiss();
            return data ? data : { isConfirmed: false };
        });
    }
}
ConfirmationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfirmationService_Factory() { return new ConfirmationService(i0.ɵɵinject(i1.ModalController)); }, token: ConfirmationService, providedIn: "root" });
