import { NgModule, APP_INITIALIZER, ErrorHandler, FactoryProvider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { TokenInterceptorService } from './auth/services/token-interceptor.service';
import { IonicStorageModule } from '@ionic/storage';
import { AuthService } from './auth/services/auth.service';
import { MenuModule } from './shared/components/menu/menu.module';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { TreeModule } from 'angular-tree-component';
import { ConfirmationModalModule } from './shared/components/confirmation-modal/confirmation-modal.module';
import { InviteModalModule } from './shared/components/invite-modal/invite-modal.module';
import { FeedbackModule } from './shared/components/feedback/feedback.module';
import { QuillModule } from 'ngx-quill';
import Quill, { StringMap } from 'quill';
import AutoLinks from 'quill-auto-links';
import { FileUploadDialogModule } from './shared/components/file-upload-dialog/file-upload-dialog.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SocketIoModule } from 'ngx-socket-io';
import { PopoverNotificationModule } from './shared/components/popover-notification/popover-notification.module';
import { NotificationSidebarModule } from './shared/components/notification-sidebar/notification-sidebar.module';
import { ChatWidgetModule } from './pages/chat-widget/chat-widget.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppUpdateNotificationModule } from './shared/components/app-update-notification/app-update-notification.module';
import { WhiteboardModule } from './shared/components/whiteboard/whiteboard.module';
import { CanDeactivateGuardService } from './pages/task/can-deactivate.guard';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from 'src/app/store/reducers';
import * as fromChat from './store/reducers/chat';
import { AppEffects } from 'src/app/store/effects/app.effects';
import { ChatEffects } from './store/effects/chat.effects';
import { PushNotificationService } from './shared/services/push-notification.service';
import { InviteLoadingModalModule } from './shared/components/invite-loading/invite-loading-modal.module';
import { Location } from '@angular/common';
import { LinkStorageService } from './shared/services/link-storage.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { TicketRouteErrorModalModule } from './shared/components/ticket-route-error-modal/ticket-route-error-modal.module';
import {PopupBlockedNoticeModule} from './shared/components/popup-blocked-notice/popup-blocked-notice.module';
import { AppMenuService } from './shared/services/menu.service';
import { LanguageService } from './shared/services/language.service';

import * as firebase from 'firebase/app';
import { LOCALE_ID } from '@angular/core';
import { OnboardingGuideComponentModule } from './shared/components/onboarding-guide/onboarding-guide.module';
import { OnboardingGuidedOverlayComponentModule } from './shared/components/onboarding-guided-overlay/onboarding-guided-overlay.module';
import { OnboardingGuideMenuComponentModule } from './shared/components/onboarding-guide-menu/onboarding-guide-menu.module';

const { parse } = require('query-string');

export function initApp(
    authService: AuthService,
    languageSevice: LanguageService,
    pushNotification: PushNotificationService,
    location: Location,
    linkStorageService: LinkStorageService,
    appMenuService: AppMenuService,
) {
    return () => {
        languageSevice.initLanguage();

        initializeFirebase();
        registerQuillEditor();
        maybeSetupRedirect(location, linkStorageService, appMenuService);

        return Promise.all([
            authService.authorize$().toPromise(),
            pushNotification.initializeFirebaseMessaging(),
        ]);
    };
}

export function maybeSetupRedirect(
    location: Location,
    linkStorageService: LinkStorageService,
    appMenuService: AppMenuService,
): void {
    const path = location.path();
    if (path.includes('pages/home/invitation-by-link?code=')) {
        const queryParams = parse(path.split('?')[1]);
        linkStorageService.saveInviteLink(JSON.stringify(queryParams));
    }
    if (path.includes('pages/ticket/')) {
        linkStorageService.saveTicketLink(path);
    }
    if (path.includes('pages/school-dashboard/')) {
        linkStorageService.saveSchoolLink(path);
    }
    if (path.includes('pages/class-dashboard/')) {
        linkStorageService.saveClassLink(path);
    }
    if (path.includes('pages/subject-dashboard/')) {
        linkStorageService.saveSubjectLink(path);
    }
    if (path.includes('pages/share')) {
        appMenuService.disableMenu();
        linkStorageService.saveShareLink(
            path.replace('pages/share', 'pages/ticket/SHARING/TICKET_TASK/SUBJECT')
        );
    }
    if (path.includes('pages/chat')) {
        appMenuService.disableMenu();
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


function initializeFirebase(): void {
    if (!firebase.apps.length) {
        firebase.initializeApp(environment.firebase);
    }
}

function registerQuillEditor(): void {
    const icons: StringMap = Quill.import('ui/icons');
    icons.bold = '<img src="/assets/img/editor-toolbar-bold.svg" />';
    icons.italic = '<img src="/assets/img/editor-toolbar-italic.svg" />';
    icons.underline = '<img src="/assets/img/editor-toolbar-underline.svg" />';
    icons.list.bullet = '<img src="/assets/img/editor-toolbar-bullet-list.svg" />';
    icons.list.ordered = '<img src="/assets/img/editor-toolbar-ordered.svg" />';
    icons.link = '<img src="assets/img/add-link.svg" />';
    Quill.register(icons, true);
    Quill.register('modules/autoLinks', AutoLinks);
}

const CORE_MODULES = [
    IonicModule.forRoot({ swipeBackEnabled: false }),
    IonicStorageModule.forRoot({
        name: 'estudy_db',
        storeName: 'estudy_app',
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
        },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MenuModule,
    TreeModule.forRoot(),
    ConfirmationModalModule,
    TicketRouteErrorModalModule,
    InviteModalModule,
    FeedbackModule,
    QuillModule.forRoot({ modules: { autoLinks: true } }),
    SocketIoModule,
    ChatWidgetModule,
    AppUpdateNotificationModule,
    WhiteboardModule,
  ];

const INITIALIZERS: [FactoryProvider] = [
    { 
        provide: APP_INITIALIZER, 
        useFactory: initApp, 
        deps: [ AuthService, LanguageService, PushNotificationService, Location, LinkStorageService, AppMenuService ],
        multi: true,
    }
];

const INTERCEPTORS = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
];

export class DynamicLocaleId extends String {
    constructor(protected service: LanguageService) {
        super();
    }

    toString() {
        return this.service.currLang;
    }
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        ...CORE_MODULES,
        FileUploadDialogModule,
        PopoverNotificationModule,
        NotificationSidebarModule,
        InviteLoadingModalModule,
        PopupBlockedNoticeModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreModule.forFeature(fromChat.chatFeatureKey, fromChat.reducer),
        EffectsModule.forRoot([AppEffects, ChatEffects]),
        OnboardingGuideComponentModule,
        OnboardingGuidedOverlayComponentModule,
        OnboardingGuideMenuComponentModule,
    ],
    providers: [
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        {
            provide: LOCALE_ID,
            deps: [LanguageService],
            useClass: DynamicLocaleId,
        },
        ...INITIALIZERS,
        ...INTERCEPTORS,
        CanDeactivateGuardService,
        PushNotificationService,
        ScreenOrientation,
        File,
        FileOpener,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {}
}
