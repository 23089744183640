import { IAppApiConfig } from "../models";

export const PROD_API_CONFIG: IAppApiConfig = {
  apiEndpoint: 'https://api.estudy.fm/api',
  websocketUrl: 'https://api.estudy.fm',
  janusSignalingUrl: 'https://janus-signaling-master.estudy.fm',
  groupworldEndpoint: 'https://app.estudy.fm/conf',
  zulipInstanceEndpoint: 'https://chat.estudy.fm',
  zulipApiEndpoint: 'https://chat.estudy.fm/api/v1',
  webUrl: 'https://app.estudy.fm',
}
