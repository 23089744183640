import * as tslib_1 from "tslib";
import { Plugins } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from './platform.service';
import { PopoverController } from '@ionic/angular';
import { PopupBlockedNoticeComponent } from '../components/popup-blocked-notice/popup-blocked-notice.component';
import { of } from 'rxjs';
import { delay, first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./platform.service";
import * as i3 from "@ionic/angular";
export var ExternalLinksEnum;
(function (ExternalLinksEnum) {
    ExternalLinksEnum["paypal"] = "https://www.paypal.com/donate?hosted_button_id=SX4VVQPZ9WG5Q";
    ExternalLinksEnum["tawk"] = "https://estudy.tawk.help/";
})(ExternalLinksEnum || (ExternalLinksEnum = {}));
const { Browser } = Plugins;
export class ExternalLinkService {
    constructor(translateService, platformService, modalPopover) {
        this.translateService = translateService;
        this.platformService = platformService;
        this.modalPopover = modalPopover;
    }
    open(url, options) {
        this.isPopUpsBlocked(url).then(isBlocked => {
            if (!isBlocked) {
                Browser.open(Object.assign({}, (options || {}), { url }));
            }
        });
    }
    isPopUpsBlocked(url) {
        return new Promise((resolve, reject) => {
            if (this.platformService.isNativeiOS || (this.platformService.isiOS && this.platformService.isSafari)) {
                return resolve(false);
            }
            let popUp = window.open(url);
            if ((!popUp)) {
                this.showNotificationPopover(url);
                resolve(true);
            }
            else {
                popUp.close();
                of(true)
                    .pipe(delay(10), first())
                    .subscribe(() => {
                    popUp = window.open(url);
                    if ((!popUp)) {
                        this.showNotificationPopover(url);
                        resolve(true);
                    }
                    else {
                        popUp.close();
                        resolve(false);
                    }
                });
            }
        });
    }
    showNotificationPopover(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.modalPopover.create({
                component: PopupBlockedNoticeComponent,
                cssClass: ['popover-blocked-notice'],
                componentProps: {
                    doDismiss: () => popover.dismiss(),
                },
            });
            yield popover.present();
        });
    }
}
ExternalLinkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExternalLinkService_Factory() { return new ExternalLinkService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.PlatformService), i0.ɵɵinject(i3.PopoverController)); }, token: ExternalLinkService, providedIn: "root" });
