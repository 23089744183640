import { ISelectOption } from '../select-input/models';
import { ISchool } from '../../models/school';
import { IClass } from '../../models/class';
import { EntityType } from '../../models/common';
import { ISubject } from '../../models/subject';
import { MeetingFrequency } from '../../models/meeting';

export const ALL_SUBJECTS = 'all-subjects';

export function getSchoolsOps(schools: ISchool[]): ISelectOption[] {
    return schools.map(s => ({
        icon: '../../../../../assets/img/school-ic.svg',
        title: s.title,
        value: s.id,
    } as ISelectOption));
}

export function getClassesOps(classes: IClass[]): ISelectOption[] {
    return classes.map(c => ({
        icon: '../../../../../assets/img/class-ic.svg',
        title: c.title,
        value: c.id,
    } as ISelectOption));
}

export function getSubjectsOps(subjects: ISubject[]): ISelectOption[] {
    return subjects.map(s => ({
        icon: '../../../../../assets/img/subject-ic.svg',
        title: s.title,
        value: s.id,
    } as ISelectOption));
}

export function getCreateTypesOps(): ISelectOption[] {
    return [
        {
            icon: '../../../../../assets/img/school-ic.svg',
            title: 'school.title-one',
            value: EntityType.SCHOOL,
        },
        {
            icon: '../../../../../assets/img/class-ic.svg',
            title: 'class.title-one',
            value: EntityType.CLASS,
        },
        {
            icon: '../../../../../assets/img/subject-ic.svg',
            title: 'subject.title-one',
            value: EntityType.SUBJECT,
        },
        {
            icon: '../../../../../assets/img/meeting-ic.svg',
            title: 'meeting.title-one',
            value: EntityType.MEETING
        },
        {
            icon: '../../../../../assets/img/materials-ic.svg',
            title: 'material.title-one',
            value: EntityType.MATERIAL
        }
    ];
}

export function getMeetingFrequencyOps(): ISelectOption[] {
    return [
        {
            title: `meeting.frequency-${MeetingFrequency.NONE}`,
            value: MeetingFrequency.NONE,
          },
          {
            title: `meeting.frequency-${MeetingFrequency.DAY}`,
            value: MeetingFrequency.DAY,
          },
          {
            title: `meeting.frequency-${MeetingFrequency.WEEK}`,
            value: MeetingFrequency.WEEK,
          },
          {
            title: `meeting.frequency-${MeetingFrequency.MONTH}`,
            value: MeetingFrequency.MONTH,
          },
    ];
}
