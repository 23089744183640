import { BehaviorSubject, of, Subject } from 'rxjs';
import { filter, switchMap, mapTo, tap, exhaustMap, map } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';
import { isSuccess } from 'src/app/shared/helpers/helpers';
import { ToastService, TOAST_TYPE } from 'src/app/shared/services/toast.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Onboarding } from 'src/app/shared/models/onboarding';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/http.service";
import * as i2 from "../../shared/services/toast.service";
import * as i3 from "../../shared/services/language.service";
const cloneDeep = require('lodash.clonedeep');
const DEFAULT_USER_SETTINGS = {
    subjects: {
        hidden: {},
    },
    lessons: {
        hidden: {},
    },
    appLanguage: null,
    showOnboarding: true,
    showInstantMeetInfo: true,
    onboarding: {
        [Onboarding.Lessons]: true,
        [Onboarding.Subjects]: true,
        [Onboarding.FindCreatedSubjects]: true,
        [Onboarding.SubjectStudents]: true,
        [Onboarding.SubjectTasks]: true,
        [Onboarding.TaskAttachments]: true,
        [Onboarding.CommunityChat]: true,
        [Onboarding.Materials]: true,
        [Onboarding.Meetings]: true,
        [Onboarding.SchoolsAndClasses]: true,
    },
    instantMeetLink: null,
};
export class UserSettingsService {
    constructor(httpService, toastService, languageService) {
        this.httpService = httpService;
        this.toastService = toastService;
        this.languageService = languageService;
        this.USER_SETTINGS_API = 'v1/user-profiles/ui-settings';
        this.userSettingsState$ = new BehaviorSubject(null);
        this.updateSettingsEmitter$ = new Subject();
        this.listenForSettingsUpdates();
        this.listenForLangUpdates();
    }
    init$() {
        return this.httpService.get$(this.USER_SETTINGS_API)
            .pipe(switchMap(response => {
            if (response.error || !response.payload) {
                if (response.error.status === 404) {
                    return this.createUserSettings$();
                }
                return of(DEFAULT_USER_SETTINGS);
            }
            return of(this.getValidUISettingsObject(response.payload));
        }), switchMap(settings => this.setUserLanguage$(settings)), tap(settings => this.userSettingsState$.next(settings)), mapTo(void 0));
    }
    userSettings$() {
        return this.userSettingsState$
            .asObservable()
            .pipe(filter(settings => !!settings));
    }
    getSettings() {
        return this.userSettingsState$.value;
    }
    updateSettings(newSettins) {
        this.updateSettingsEmitter$.next(newSettins);
    }
    handleSubjectOrLessonRemove(subjectId) {
        const currSettings = cloneDeep(this.userSettingsState$.value);
        if (currSettings.subjects && (currSettings.subjects.hidden[subjectId] === true || currSettings.subjects.hidden[subjectId] === false) ||
            currSettings.lessons && (currSettings.lessons.hidden[subjectId] === true || currSettings.lessons.hidden[subjectId] === false)) {
            delete currSettings.subjects.hidden[subjectId];
            delete currSettings.lessons.hidden[subjectId];
            this.updateSettings(currSettings);
        }
    }
    createUserSettings$() {
        return this.httpService.post$(this.USER_SETTINGS_API, DEFAULT_USER_SETTINGS)
            .pipe(map(response => response.error ? DEFAULT_USER_SETTINGS : response.payload));
    }
    updateUserSettings$(settings) {
        return this.httpService.put$(this.USER_SETTINGS_API, settings);
    }
    listenForSettingsUpdates() {
        this.updateSettingsEmitter$
            .asObservable()
            .pipe(map(settings => this.removeVisibleSubjectsOrLessons(settings)), exhaustMap(settings => this.updateUserSettings$(settings)
            .pipe(tap(response => {
            if (isSuccess(response)) {
                this.userSettingsState$.next(response.payload);
            }
            else {
                this.toastService.showToast(response.message, TOAST_TYPE.ERROR);
            }
        }))))
            .subscribe();
    }
    removeVisibleSubjectsOrLessons(settings) {
        const cleanSubjects = this.removeVisibleSubjectsOrLessonsHelper(settings, 'subjects');
        const cleanLessons = this.removeVisibleSubjectsOrLessonsHelper(cleanSubjects, 'lessons');
        return cleanLessons;
    }
    removeVisibleSubjectsOrLessonsHelper(settings, key) {
        const newSettings = cloneDeep(settings);
        if (newSettings[key]) {
            for (const id in newSettings[key].hidden) {
                if (newSettings[key].hidden.hasOwnProperty(id)) {
                    if (newSettings[key].hidden[id] !== true) {
                        delete newSettings[key].hidden[id];
                    }
                }
            }
        }
        return newSettings;
    }
    setUserLanguage$(settings) {
        if (settings.appLanguage) {
            this.languageService.initLanguage(settings.appLanguage);
            return of(settings);
        }
        const deviceLang = this.languageService.getDeviceLang();
        this.languageService.initLanguage(deviceLang);
        return this.updateUserSettings$(Object.assign({}, settings, { appLanguage: deviceLang }))
            .pipe(map(response => response.error ? settings : response.payload));
    }
    listenForLangUpdates() {
        this.languageService.changes$
            .pipe(filter(lang => this.getSettings() && this.getSettings().appLanguage !== lang))
            .subscribe(lang => this.updateSettings(Object.assign({}, this.getSettings(), { appLanguage: lang })));
    }
    getValidUISettingsObject(settings) {
        const newSettings = cloneDeep(settings);
        if (!newSettings.subjects) {
            newSettings.subjects = {
                hidden: {},
            };
        }
        if (!newSettings.lessons) {
            newSettings.lessons = {
                hidden: {},
            };
        }
        return newSettings;
    }
}
UserSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserSettingsService_Factory() { return new UserSettingsService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.LanguageService)); }, token: UserSettingsService, providedIn: "root" });
