import { Plugins } from '@capacitor/core';
import { ToastService, TOAST_TYPE } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "./toast.service";
import * as i2 from "@ngx-translate/core";
const { Clipboard } = Plugins;
export class ClipboardService {
    constructor(toastService, translateService) {
        this.toastService = toastService;
        this.translateService = translateService;
    }
    copy(text) {
        Clipboard.write({
            string: text,
        });
        this.toastService.showToast(this.translateService.instant('common.copied'), TOAST_TYPE.WARNING);
    }
}
ClipboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClipboardService_Factory() { return new ClipboardService(i0.ɵɵinject(i1.ToastService), i0.ɵɵinject(i2.TranslateService)); }, token: ClipboardService, providedIn: "root" });
