import { HttpService } from './http.service';
import { Subject, Observable, TimeoutError, of, forkJoin } from 'rxjs';
import { IReceivedEvent, EventType, EntityType, IPublishEvent, ExtraRequests, INotifyUpdatePayload } from '../models/common';
import { IResponse } from '../models/response';
import { tap, takeUntil, repeatWhen, concatMap, share, switchMap, map } from 'rxjs/operators';
import { isSuccess, showErrorIfExists } from '../helpers/helpers';
import { ToastService } from './toast.service';

export abstract class BaseService<T> {
  public notifier$: Observable<IReceivedEvent<T>>;

  protected abstract entityType: EntityType;
  protected abstract GET_ALL: string;
  protected abstract POST_ONE: string;
  protected abstract PUT_ONE: string;
  protected abstract PATCH_ONE: string;
  protected abstract GET_ONE: (id: string) => string;
  protected abstract DELETE_ONE: (id: string) => string;

  protected notifyEmitter$: Subject<IReceivedEvent<T>> = new Subject();

  private publishUpdateEmitter$: Subject<IPublishEvent<T>> = new Subject<IPublishEvent<T>>();

  constructor(protected httpService: HttpService, protected toastService: ToastService) {
    this.notifier$ = this.notifyEmitter$.pipe(share());
    const open$ = new Subject();
    const close$ = new Subject();
    this.publishUpdateEmitter$
      .pipe(
        concatMap(event => {
          switch (event.eventType) {
            case EventType.ADD:
              return this.add$(event.entity, event.extraRequests);
            case EventType.EDIT:
              return this.edit$(
                  event.patchValue ? event.patchValue : event.entity,
                  event.patchValue ? 'patch' : 'put',
                  event.timeOffset);
            case EventType.REMOVE:
              return this.remove$(event.entity);
            default:
              return this.customOperation$(event);
          }
        }),
        tap(response => {
          if (!this.isConnectionActive(response)) {
            console.log(`%c CONNECTION IS NOT ALIVE: ${this.entityType}`, 'color:red');
            close$.next();
            open$.next();
          }
        }),
        takeUntil(close$),
        repeatWhen(() => open$),
      )
      .subscribe();
  }

  getAll$(): Observable<IResponse<T[]>> {
    return this.httpService.get$(this.GET_ALL);
  }

  getOne$(id: string): Observable<IResponse<T>> {
    return this.httpService.get$(this.GET_ONE(id));
  }

  getCount$(): Observable<IResponse<number>> {
    return of({ payload: 0 });
  }

  publishUpdate(event: IPublishEvent<T>): void {
    this.publishUpdateEmitter$.next(event);
  }

  protected add$(entity: T, extraReq$?: ExtraRequests[]): Observable<IResponse<T>> {
    return this.httpService.post$<T>(this.POST_ONE, entity)
      .pipe(
        switchMap(response => {
          if (!isSuccess(response)) {
            return of(response);
          }

          if (extraReq$ && extraReq$.length) {
            return forkJoin(extraReq$.map(req => req(response.payload['id'])))
              .pipe(
                map(extraResponses => {
                  for (const extraResponse of extraResponses) {
                    showErrorIfExists(extraResponse, this.toastService);
                  }
                  return response;
                }),
              );
          }
          return of(response);
        }),
        tap(response => this.notifyAboutUpdate(EventType.ADD, response)),
      );
  }

  protected edit$(entity: T, method: 'put' | 'patch' = 'patch', timeOffset?: number): Observable<IResponse<T>> {
    const url = `${method === 'put' ? this.PUT_ONE : this.PATCH_ONE}${timeOffset !== undefined ? '?timeOffset=' + timeOffset : ''}`;
    return this.httpService[`${method}$`]<T>(url, entity)
        .pipe(tap(response => this.notifyAboutUpdate(EventType.EDIT, response, entity['id'])));
  }

  protected remove$(entity: T): Observable<IResponse<T>> {
    return this.httpService.delete$<T>(this.DELETE_ONE(entity['id']))
        .pipe(tap(response => this.notifyAboutUpdate(EventType.REMOVE, response, entity['id'])));
  }

  protected customOperation$(event: IPublishEvent<T>): Observable<IResponse<any>> {
    console.log(`%c ${this.entityType} has not defined method customOperation$`, 'color:red', event);
    return of({});
  }

  protected notifyAboutUpdate(eventType: EventType, response: IResponse<T>, payload?: INotifyUpdatePayload) {
    const event: IReceivedEvent<T> = {
      entityId: payload && payload.entityId ? payload.entityId : response.payload ? response.payload['id'] : null,
      entityType: this.entityType,
      eventType,
      response,
      prevState: payload ? payload.prevState : null,
      prevColumnId: payload ? payload.prevColumnId : null,
    };
    console.log(`%c notifyAboutUpdate`, 'color:coral', event);
    this.notifyEmitter$.next(event);
  }

  private isConnectionActive(response: IResponse<T>): boolean {
    if (response.error) {
      if (response.error instanceof TimeoutError) {
        return false;
      }
      return !(response.error.status && (response.error.status <= 0 || response.error.status === 401));
    }
    return true;
  }
}
