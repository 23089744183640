import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IonImg, IonInfiniteScroll } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { selectChatStreamAttachments, selectChatStreamAttachmentsLoaded, selectChatStreamMembers } from 'src/app/store/selectors/chat.selectors';
import { fileToBase64 } from '../../helpers/helpers';
import { IChatMessageAttachment, IStreamId, LoadChatAttachmentsSuccessEvent } from '../../models/chat';
import { ChatUiService } from '../../services/chat-ui.service';
import * as ChatActions from 'src/app/store/actions/chat'
import { ZulipService } from '../../services/zulip.service';
import { ChatAttachmentsService } from '../../services/chat-attachments.service';

type AttachmentVM = IChatMessageAttachment & { senderFullName?: string };

const ATTACHMENTS_BATCH_SIZE = 20;

@Component({
  selector: 'app-chat-attachments',
  templateUrl: './chat-attachments.component.html',
  styleUrls: ['./chat-attachments.component.scss'],
})
export class ChatAttachmentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedChannelId: string;  
  @ViewChild(IonInfiniteScroll, { static: true }) infiniteScrollRef: IonInfiniteScroll;

  private imagesLoading: Record<string, boolean> = {};
  private _selectedChannelId$ = new BehaviorSubject<IStreamId>(null);
  private destroy$ = new Subject<void>();

  selectedChannelId$ = this._selectedChannelId$.pipe(
    filter(id => id != null),
    shareReplay(1)
  );
  attachments$: Observable<Array<AttachmentVM>>;

  constructor(
    private store: Store,
    private chatUiService: ChatUiService,
    private zulipService: ZulipService,
    private chatAttachmentsService: ChatAttachmentsService
  ) {
    this.attachments$ = this.selectedChannelId$.pipe(
      switchMap(streamId => this.store.pipe(selectChatStreamMembers(streamId)).pipe(
        map(members => ({
          streamId,
          membersMap: members.reduce((accum, entry) => {
            accum[entry.zulip_uid] = `${ entry.firstname } ${ entry.lastname }`;
            return accum;
          }, { })
        }))
      )),
      switchMap(({ streamId, membersMap }) =>
        this.store.select(selectChatStreamAttachments, streamId).pipe(
          tap(entries => {
            if (entries == null) {
              this.initChatStreamAttachments(streamId);
            }
          }),
          filter(entries => Array.isArray(entries)),
          map(entries => entries.filter((entry, index) => entries.findIndex(en => en.link === entry.link) === index)),
          map((entries): AttachmentVM[] =>
            entries.map(entry => ({
              ...entry,
              senderFullName: membersMap[entry.senderId]
            }))
          )
        )
      ),
    );

    this.chatUiService.events$.pipe(
      filter((event) => event instanceof LoadChatAttachmentsSuccessEvent),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.infiniteScrollRef.complete();
    })

    this.selectedChannelId$.pipe(
      switchMap(streamId => this.store.select(selectChatStreamAttachmentsLoaded, streamId)),
      first(isLoaded => isLoaded),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      console.log('infiniteScrollRef.disabled')
      this.infiniteScrollRef.disabled = true;
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedChannelId' in changes) {
      this._selectedChannelId$.next(changes['selectedChannelId'].currentValue);
    }
  }

  loadMore(event: Event) {
    of(null).pipe(
      withLatestFrom(this.attachments$),
      map(([, entries]) => entries),
      filter(entries => entries.length > 0),
      map(entries => {
        const uniqueMessageIds = new Set(
          entries.map(({ messageId }) => messageId as unknown as number)
        );
        const messageIds = Array.from(uniqueMessageIds.values()).sort((a, b) => a - b);
        return messageIds[0];
      }),
      withLatestFrom(this.selectedChannelId$),
    ).subscribe(([lastMessageId, streamId]) => {
      this.store.dispatch(
        ChatActions.loadChatStreamAttachments({
          streamId,
          anchor: lastMessageId,
          count: ATTACHMENTS_BATCH_SIZE
        })
      );
    })
  }

  handleImagePreload(event: Event, { link }: IChatMessageAttachment) {
    const target = event.target as HTMLElement & IonImg;

    if (target.classList.contains('loaded') || this.imagesLoading[link]) {
      event.preventDefault();
      return;
    }

    this.imagesLoading[link] = true;
    this.getFileDataUrl(link).then(dataUrl => {
      target.setAttribute('src', dataUrl);
      target.classList.add('loaded');
      delete this.imagesLoading[link];
    });
  }

  download(event: Event, entry: IChatMessageAttachment) {
    event.preventDefault();
    event.stopPropagation();
    this.chatAttachmentsService.downloadFileAttachment(entry);
  }

  preview(event: Event, entry: IChatMessageAttachment) {
    const target = event.target as HTMLElement & IonImg;

    if (target.classList.contains('loaded')) {
      this.chatAttachmentsService.previewImage(target.src, entry.name);
    }
  }

  trackByLink(index: number, entry: IChatMessageAttachment) {
    return entry.link;
  }

  private initChatStreamAttachments(streamId: number) {
    const payload = {
      streamId,
      anchor: 'newest',
      count: ATTACHMENTS_BATCH_SIZE
    };
    this.store.dispatch(
      ChatActions.loadChatStreamAttachments(payload)
    );
  }

  private getFileDataUrl(link: string) {
    return this.zulipService.getFile(link)
      .toPromise()
      .then(res => fileToBase64(res));
  }

}
