import { Injectable, isDevMode } from "@angular/core";
import { Storage } from '@ionic/storage';
import { ajax } from 'rxjs/ajax'
import { BehaviorSubject, from, NEVER, Observable, of, ReplaySubject, Subject, throwError, zip } from 'rxjs';
import { concatMap, exhaustMap, shareReplay, switchMap, takeWhile, tap, catchError, withLatestFrom, first, filter, map, scan } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IRegisterEventQueueResponse, IChatStream, IEventsResponse, IGetMessagesResponse, IZulipUser, IMessageId, IStreamId, NarrowEntry, UserPresenceStatus, IZulipUpdatePresenceResponse, IZulipResponse, ChatMember, IZulipUserId } from '../models/chat';
import { generateId } from '../helpers/helpers';
import produce from 'immer';
import { HttpService } from "./http.service";
import { UserModel } from "../models/user/user";
import { EnvService } from "./env/env.service";

@Injectable({
  providedIn: 'root'
})
export class ZulipService {
  private USER_TOKEN_STORAGE_KEY = 'token_c';
  private USER_DATA_STORAGE_KEY = 'zuid';
  private ZULIP_API_ENDPOINT = this.envService.apiConfig.zulipApiEndpoint;
  private renewEventQueueId$ = new Subject<void>();
  private eventQueueId$ = new ReplaySubject<string | null>(1);
  private eventQueue$ = new Subject<IEventsResponse>();
  private userToken$ = new ReplaySubject<string | null>(1);

  public eventQueueUpdates$ = this.eventQueue$.asObservable().pipe(shareReplay(1));
  public currentUser$ = new ReplaySubject<IZulipUserId | null>(1);
  public init$ = new Subject<IRegisterEventQueueResponse>();
  public zulipIsOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private get userCredentials$() {
    return this.userToken$.pipe(first(token => token != null))
  }

  constructor(
    private storage: Storage,
    private authService: AuthService,
    private httpService: HttpService,
    private envService: EnvService,
  ) {
    this.eventQueueId$
      .pipe(
        tap((queueId) => isDevMode() && console.log('eventQueueId$ next:', queueId)),
        switchMap(queueId => this.listenEvents$(queueId).pipe(
          takeWhile(res => res.result !== 'error'),
          catchError((err) => {
            if (isDevMode) {
              console.warn(err);
            }

            if (err.code === 'BAD_EVENT_QUEUE_ID') {
              this.renewEventQueueId$.next();
            }

            return NEVER;
          })
        ))
      )
      .subscribe(events => {
        if (isDevMode()) {
          console.log(events);
        }
        this.eventQueue$.next(events)
      });
  }

  init() {
    this.renewEventQueueId$
      .pipe(
        exhaustMap(() => this.registerEventQueue$())
      )
      .subscribe(res => {
        this.zulipIsOnline$.next(true);
        this.eventQueueId$.next(res.queue_id);
        this.init$.next(res);

        if (isDevMode()) {
          console.info('[quque_id]', res.queue_id);
        }
      }, () => this.zulipIsOnline$.next(false));

    if (this.authService.isAuthenticated) {
      this.renewEventQueueId$.next();

      Promise.all([
        this.storage.get(this.USER_TOKEN_STORAGE_KEY),
        this.storage.get(this.USER_DATA_STORAGE_KEY)
      ])
      .then(([ token, user ]) => {
        if (token == null || user == null) {
         this.requestNewApiKey();
         return;
        }

        try {
          this.currentUser$.next(JSON.parse(window.atob(user)));
        } catch(err) {
          console.error(err);
          return;
        }

        this.userToken$.next(token);
      });
    } else {
      this.logout();
    }

    this.authService.isAuthenticated$
      .pipe(
        filter(isAuthenticated => !isAuthenticated),
        tap(() => this.logout()),
        withLatestFrom(this.eventQueueId$),
        filter(([_, queueId]) => queueId != null),
        switchMap(([_, queueId]) => this.unregisterEventQueue(queueId).pipe(
          catchError(() => of(null))
        )),
      )
      .subscribe(() => {
        this.eventQueueId$.next(null);
      });

    this.authService.isAuthenticated$
      .pipe(
        filter(isAuthenticated => isAuthenticated)
      )
      .subscribe(() => {
        this.renewEventQueueId$.next();
      });
  }

  requestNewApiKey() {
    this.authService.getZulipApiKey().subscribe((res) => {
      if (res.payload) {
        this.setApiKey(res.payload.email, res.payload.api_key);
      }
    });
  }

  setApiKey(email: string, apiKey: string) {
    const token = window.btoa(`${ email }:${ apiKey }`);
    this.userToken$.next(token);

    return this.storage.set(this.USER_TOKEN_STORAGE_KEY, token)
      .then(() => this.getOwnUser())
      .then(({ user_id }) => {
        this.currentUser$.next({ user_id });
        try {
          const encodedUserData = window.btoa(JSON.stringify({ user_id }));
          return this.storage.set(this.USER_DATA_STORAGE_KEY, encodedUserData);
        } catch(err) {
          console.error(err);
        }
      });
  }

  logout() {
    return Promise
      .all([
        this.storage.remove(this.USER_TOKEN_STORAGE_KEY),
        this.storage.remove(this.USER_DATA_STORAGE_KEY)
      ])
      .then(() => {
        this.currentUser$.next(null);
        this.userToken$.next(null);
      });
  }

  getOwnUser(): Promise<IZulipUser> {
    return this.getUserCreds()
      .then(token => {
        const req: RequestInit = {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Basic ' + token
          }),
        }
        return fetch(`${ this.ZULIP_API_ENDPOINT }/users/me`, req)
      })
      .then(res => res.json());
  }

  getUserCreds(): Promise<string> {
    return this.storage.get(this.USER_TOKEN_STORAGE_KEY);
  }

  getStreams$(): Observable<IChatStream[]> {
    type Response = {
      result: string,
      msg?: string
      subscriptions: IChatStream[]
    };
    const url = 'users/me/subscriptions?include_subscribers=true';
    return this.userRequest$<Response>('get', url).pipe(
      map(res => {
        if (res.result === 'error') {
          throw new Error(res.msg);
        }
        return res.subscriptions.reduce<IChatStream[]>((accum, entry) => {
          try {
            accum.push({ ...entry, meta: JSON.parse(entry.description) })
          } finally {
            return accum;
          }
        }, []);
      })
    )
  }

  getUser$(uid: number) {
    type Response = {
      result: 'success' | 'error',
      msg: string,
      user: IZulipUser | null
    }
    return this.httpService.get$<ChatMember>(`v1/zulip/users/${ uid }`);
  }

  getMessage$(id: IMessageId) {
    const narrow: NarrowEntry[] = [
      { operator: 'id', operand: String(id) },
    ]
    const queryParams = new URLSearchParams({
      'anchor': 'newest',
      'num_before': 1,
      'num_after': 0,
      'narrow': JSON.stringify(narrow),
    } as Record<string, any>).toString();

    return this.userRequest$<IGetMessagesResponse>('get', `messages?${ queryParams }`).pipe(
      map(res => res.messages[0])
    );
  }

  getMessages$(streamId: number, anchor: IMessageId = 'newest', numBefore = 200, numAfter = 0) {
    const narrow: NarrowEntry[] = [
      { operator: 'sender', operand: 'notification-bot@zulip.com', negated: true },
      { operator: 'sender', operand: 'welcome-bot@zulip.com', negated: true },
      { operator: 'stream', operand: streamId },
    ]

    const queryParams = new URLSearchParams({
      'anchor': anchor,
      'num_before': numBefore,
      'num_after': numAfter,
      'narrow': JSON.stringify(narrow),
    } as Record<string, any>).toString();

    return this.userRequest$<IGetMessagesResponse>('get', `messages?${ queryParams }`);
  }

  getAllMessages$(streamId: IStreamId, anchor: IMessageId, limitAnchor: IMessageId = -1, bulkSize = 200): Observable<IGetMessagesResponse> {
    const anchor$ = new BehaviorSubject<IMessageId>(anchor);

    return anchor$.pipe(
      concatMap(_anchor => this.getMessages$(streamId, _anchor, 0, bulkSize).pipe(
        map(res => {
          if (limitAnchor !== -1) {
            const limitAnchorMessageIx = res.messages.findIndex(msg => msg.id === limitAnchor);

            if (limitAnchorMessageIx > -1) {
              return {
                ...res,
                messages: res.messages.slice(0, limitAnchorMessageIx)
              }
            }
          }

          return res;
        }),
        tap(res => {
          if (res.messages.length - 1 === bulkSize) {
            // continue fetch-chain
            anchor$.next(
              res.messages[res.messages.length - 1].id
            )
          } else {
            // Cancel fetch chain
            anchor$.next(null);
            anchor$.complete();
          }
        }),
        map(res => {
          if (res.anchor === anchor) {
            return res;
          }
          // For subsequent responses, remove first message to prevent duplicates
          // Last message of previous response is used as anchor and response
          // of the next page request will contain the same message at position 0
          return produce(res, _res => {
            _res.messages.shift();
          });
        })
      )),
      scan<IGetMessagesResponse, IGetMessagesResponse>((accumRes, res) => {
        return {
          ...accumRes,
          ...res,
          messages: accumRes.messages.concat(res.messages)
        }
      }, { messages: [] } as IGetMessagesResponse),
      first(() => anchor$.getValue() == null),
    )
  }

  markAllAsRead$(streamId: IStreamId) {
    const body = new FormData();
    body.append('stream_id', streamId + '');
    return this.userRequest$('post', 'mark_stream_as_read', body);
  }

  searchMessages$(search: string) {
    const narrow: NarrowEntry[] = [
      { operator: 'search', operand: search },
      { operator: 'sender', operand: 'notification-bot@zulip.com', negated: true },
      { operator: 'sender', operand: 'welcome-bot@zulip.com', negated: true },
    ]
    const queryParams = new URLSearchParams({
      'anchor': 'newest',
      'num_before': 100,
      'num_after': 100,
      'narrow': JSON.stringify(narrow),
    } as Record<string, any>).toString();

    return this.userRequest$<IGetMessagesResponse>('get', `messages?${ queryParams }`);
  }

  getUnreadsCount(streamId: IStreamId) {
    const narrow = [
      { operator: 'stream', operand: streamId },
      { operator: 'is', operand: 'unread'},
      { operator: 'sender', operand: 'notification-bot@zulip.com', negated: true },
      { operator: 'sender', operand: 'welcome-bot@zulip.com', negated: true }
    ];
    const query = new URLSearchParams({
      'anchor': 'newest',
      'num_before': 100,
      'num_after': 0,
      'narrow': JSON.stringify(narrow),
    } as Record<string, any>).toString();
    return this.userRequest$<IGetMessagesResponse>('get', `messages?${ query }`).pipe(
      map(res => ({ streamId, count: res.messages.length }))
    );
  }

  getFirstUnread$(streamId: IStreamId): Observable<IMessageId> {
    const narrow = [
      { operator: 'stream', operand: streamId },
      { operator: 'sender', operand: 'notification-bot@zulip.com', negated: true },
      { operator: 'sender', operand: 'welcome-bot@zulip.com', negated: true }
    ];
    const query = new URLSearchParams({
      'anchor': 'first_unread',
      'num_before': 0,
      'num_after': 1,
      'narrow': JSON.stringify(narrow),
    } as Record<string, any>).toString();

    return this.userRequest$<IGetMessagesResponse>('get', `messages?${ query }`).pipe(
      map(res => res.messages.length > 0 ? res.anchor : null)
    );
  }

  getFirstUnreadByMessage$(messageId: IMessageId) {
    return this.getMessage$(messageId).pipe(
      switchMap(res => this.getFirstUnread$(res.stream_id).pipe(
        map(firstUnread => ({ streamId: res.stream_id, firstUnread }))
      ))
    )
  }

  sendMessage$(streamId: number, content: string, localId?: string) {
    return zip(this.eventQueueId$.pipe(first()), this.getUserCreds()).pipe(
      switchMap(([ queueId, token ]) => {
        const body = new FormData();
        body.append('type', 'stream');
        body.append('to', streamId.toString());
        body.append('content', content);
        body.append('topic', 'general');

        if (localId) {
          body.append('local_id', localId);
          body.append('queue_id', queueId);
        }

        const req: RequestInit = {
          headers: new Headers({
            'Authorization': 'Basic ' + token
          }),
          method: 'POST',
          body,
        }

        return fetch(`${ this.ZULIP_API_ENDPOINT }/messages`, req)
          .then(res => res.json()) as Promise<IGetMessagesResponse>
      })
    )
  }

  createStream$(students?: string[], title = '') {
    type Response = {
      already_subscribed: Record<string, string[]>,
      msg: string,
      result: 'success' | 'error',
      subscribed: Record<string, string[]>
    }
    const streamName = 'pm_' + generateId();
    const hasTitle = typeof title === 'string' && title.trim() !== '';
    const subscription = {
      name: streamName,
      description: JSON.stringify({
        type: 'pm',
        title: hasTitle ? title.trim() : null
      })
    };
    const body = new FormData();
    body.append('subscriptions', JSON.stringify([subscription]));
    body.append('principals', JSON.stringify(students));
    body.append('announce', 'false');
    body.append('invite_only', 'true');
    return this.userRequest$<Response>('post', 'users/me/subscriptions', body).pipe(
      switchMap(res => {
        if (res.result === 'success') {
          return this.getStreamId$(streamName)
        }
        return throwError(res);
      })
    );
  }

  getStreamId$(streamName: string) {
    type Response = {
      msg: string,
      result: 'success' | 'error',
      stream_id: IStreamId
    }
    return this.userRequest$<Response>('get', `get_stream_id?stream=${ streamName }`);
  }

  getUser(id: number): Promise<IZulipUser> {
    type Response = {
      result: string;
      msg: string;
      user
    }
    return this.getUserCreds().then(token => {
      const query = new URLSearchParams({
        include_custom_profile_fields: 'true'
      }).toString();
      const req: RequestInit = {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Basic ' + token
        })
      }
      return fetch(`${ this.ZULIP_API_ENDPOINT }/users/${ id }?${ query }`, req)
        .then(res => res.json() as Promise<Response>)
        .then(res => {
          if (res.result === 'error') {
            throw new Error(res.msg);
          }
          return res.user
        })
    })
  }

  markAsRead(ids: IMessageId[]) {
    type Response = {
      messages: IMessageId[],
    }
    const body = new FormData();
    body.append('messages', JSON.stringify(ids));
    body.append('op', 'add');
    body.append('flag', 'read');
    return this.userRequest$<Response>('post', 'messages/flags', body);
  }

  /**
   * @param status presence value. Can be 'idle' or 'active'
   * @param isPingOnly can be set to `false` for retrieving other users presence status.
   */
  updateUserPresence$(status: UserPresenceStatus, isPingOnly: boolean = true) {
    const body = new FormData();
    body.append('status', status);
    body.append('ping_only', isPingOnly + '');
    body.append('slim_presence', 'true');
    body.append('new_user_input', 'true');

    return this.userCredentials$.pipe(switchMap((token) => {
      const req: RequestInit = {
        headers: new Headers({ 'Authorization': 'Basic ' + token }),
        method: 'POST',
        body,
        keepalive: true
      }

      return fetch(`${ this.ZULIP_API_ENDPOINT }/users/me/presence`, req)
        .then(res => res.json() as Promise<IZulipUpdatePresenceResponse>)
    }))
  }

  uploadFile(file: File) {
    type Response = IZulipResponse & { uri: string };
    const body = new FormData();
    body.append('files', file);
    return this.userRequest$<Response>('post', 'user_uploads', body);
  }

  getFile(link: string) {
    return this.userCredentials$.pipe(switchMap((token) => {
      const req: RequestInit = {
        headers: new Headers({ 'Authorization': 'Basic ' + token }),
        method: 'GET',
      }

      return fetch(this.envService.apiConfig.zulipInstanceEndpoint + link, req).then(res => res.blob())
    }))
  }

  getAttachments$(streamId: number, anchor: IMessageId = 'newest', numBefore = 200, numAfter = 0) {
    const narrow: NarrowEntry[] = [
      { operator: 'stream', operand: streamId  },
      { operator: 'has', operand: 'attachment' },
    ]

    const queryParams = new URLSearchParams({
      'anchor': anchor,
      'num_before': numBefore,
      'num_after': numAfter,
      'narrow': JSON.stringify(narrow),
    } as Record<string, any>).toString();

    return this.userRequest$<IGetMessagesResponse>('get', `messages?${ queryParams }`);
  }

  getSubscriptionsMembers$() {
    return this.httpService.get$<UserModel[]>('v1/zulip/subscriptions_members')
  }

  private userRequest$<Res = any>(method: string, url: string, body: any = null): Observable<Res> {
    return this.userCredentials$.pipe(switchMap(token => {
      return ajax({
        headers: {
          'Authorization': `Basic ${ token }`,
        },
        url: `${ this.ZULIP_API_ENDPOINT }/${ url }`,
        method,
        body,
      }).pipe(
        map(res => {
          if (res.response.result === 'error') {
            throw res.response;
          }
          return res.response;
        })
      )
    }))
  }

  private getEvents$(queueId: string, lastEventId: number = -1): Observable<IEventsResponse> {
    const req = this.getUserCreds()
      .then(token => {
        const req: RequestInit = {
          headers: new Headers({
            'Authorization': 'Basic ' + token
          }),
          method: 'GET',
        }
        const queryParams = new URLSearchParams({
          queue_id: queueId,
          last_event_id: lastEventId.toString(),
          dont_block: 'false',
        }).toString();

        return fetch(`${ this.ZULIP_API_ENDPOINT }/events?${ queryParams }`, req)
      })
      .then(res => res.json())

    return from(req);
  }

  private listenEvents$(queueId: string): Observable<IEventsResponse> {
    let lastEventId = -1;
    const poll$ = new BehaviorSubject<void>(null);

    return poll$.pipe(
      filter(() => this.authService.isAuthenticated),
      concatMap(_ => {
        const events$ = this.getEvents$(queueId, lastEventId).pipe(
          switchMap(res => {
            if (res.result === 'error') {
              // if ('retry-after' in res) {
              //   return of(res);
              // }
              return throwError(res);
            }

            return of(res);
          }),
          tap(res => {
            const actualLastEventId = Math.max(...res.events.map(({ id }) => id));

            if (actualLastEventId !== lastEventId) {
              lastEventId = actualLastEventId;
            }
          })
        )
        return events$;
      }),
      tap(() => poll$.next())
    );
  }

  private registerEventQueue$(): Observable<IRegisterEventQueueResponse> {
    const body = new FormData();
    body.append('event_types', `["message", "subscription", "presence", "update_message_flags"]`);
    body.append('fetch_event_types', `["message", "subscription", "update_message_flags"]`);
    body.append('all_public_streams', `false`);
    body.append('include_subscribers', `true`);
    body.append('slim_presence', `true`);
    // TODO: test new messages events both from sender and receiver perspective
    body.append('apply_markdown', `true`);

    return this.userRequest$('post', 'register', body);
  }

  private unregisterEventQueue(queue_id): Observable<{ result: string }> {
    const query = new URLSearchParams({ queue_id }).toString();
    return this.userRequest$('delete', `events?${ query }`);
  }
}
