import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { IResponse } from '../../models/response';
import { shareReplay, first, tap, filter, pluck, concatMap, takeUntil, mapTo } from 'rxjs/operators';
import { showErrorIfExists, isSuccess } from '../../helpers/helpers';
import { ToastService } from '../toast.service';
import { IBaseEntity, SocketMessageType } from '../../models/common';
import { InviteService } from '../invite.service';

const cloneDeep = require('lodash.clonedeep');

export interface IBaseState<T extends IBaseEntity> {
  [key: string]: T[];
}

export enum BaseStoreEvent {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}

export interface IBaseStoreAction {
  type: BaseStoreEvent;
  payload: string | IBaseEntity;
}

export abstract class BaseStoreService<T extends IBaseEntity> {

  private state$: BehaviorSubject<IBaseState<T>> = new BehaviorSubject<IBaseState<T>>(null);
  private destroy$ = new Subject();
  private initState: IBaseState<T>;

  protected abstract keyExtractor: (item: T) => string;
  protected abstract getItems$(): Observable<IResponse<T[]>>;
  protected abstract getItem$(id: string): Observable<IResponse<T>>;
  protected abstract getUpdates$(): Observable<IBaseStoreAction>;

  constructor(
    protected state: IBaseState<T>,
    protected toastService: ToastService,
    protected inviteService: InviteService,
  ) {
    this.initState = state;
  }

  getState$(): Observable<IBaseState<T>> {
    return this.state$.asObservable()
      .pipe(shareReplay(1));
  }

  getState(): IBaseState<T> {
    return this.state$.getValue();
  }

  init(): void {
    this.getItems$()
      .pipe(
        tap(response => showErrorIfExists(response, this.toastService)),
        filter(response => isSuccess(response)),
        pluck('payload'),
        first(),
      )
      .subscribe(items => this.setItems(items));

    this.getUpdates$()
      .pipe(
        concatMap(event => this.handleUpdates$(event)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.inviteService.notifier$
      .pipe(
        filter(message => message.type === SocketMessageType.USER_ADD_INVITATION),
        concatMap(() => this.getItems$()),
        tap(response => showErrorIfExists(response, this.toastService)),
        filter(response => isSuccess(response)),
        pluck('payload'),
        takeUntil(this.destroy$),
      )
      .subscribe(items => this.setItems(items));
  }

  destroy(): void {
    this.state$.next(null);
    this.destroy$.next();
  }

  private handleUpdates$(action: IBaseStoreAction): Observable<void> {
    switch (action.type) {
      case BaseStoreEvent.ADD:
      case BaseStoreEvent.UPDATE:
        const request$: Observable<IResponse<T>> = (action.payload as IBaseEntity).id
          // TODO: change after SCHOOL-417 will be done: ? of({ payload: action.payload } as IResponse<T>)
          ? this.getItem$((action.payload as IBaseEntity).id)
          : this.getItem$(action.payload as string);
        return request$
          .pipe(
            tap(response => showErrorIfExists(response, this.toastService)),
            filter(response => isSuccess(response)),
            pluck('payload'),
            tap(item => action.type === BaseStoreEvent.ADD ? this.addItem(item) : this.updateItem(item)),
            mapTo(null),
          );
      case BaseStoreEvent.REMOVE:
        this.removeItem((action.payload as IBaseEntity).id);
        return of(null);
    }
  }

  private setItems(items: T[]): void {
    this.state$.next(this.getGroupedByKey(items));
  }

  private addItem(item: T): void {
    if (item) {
      const key = this.keyExtractor(item);
      const currState: IBaseState<T> = cloneDeep(this.state$.value);
      if (!currState[key]) {
        currState[key] = [];
      }
      currState[key].unshift(item);
      this.state$.next(currState);
    }
  }

  private updateItem(item: T): void {
    const key = this.keyExtractor(item);
    const currState: IBaseState<T> = cloneDeep(this.state$.value);
    if (currState[key]) {
      const index = currState[key].findIndex(i => i.id === item.id);
      if (index !== -1) {
        currState[key][index] = item;
        this.state$.next(currState);
      }
    }
  }

  private removeItem(id: string): void {
    const currState: IBaseState<T> = cloneDeep(this.state$.value);
    for (const key in currState) {
      if (currState.hasOwnProperty(key)) {
        const index = currState[key].findIndex(i => i.id === id);
        if (index !== -1) {
          currState[key].splice(index, 1);
          this.state$.next(currState);
          return;
        }
      }
    }
  }

  private getGroupedByKey(items: T[]): IBaseState<T> {
    const state: IBaseState<T> = cloneDeep(this.initState);

    items
      .forEach(item => {
        const key = this.keyExtractor(item);
        if (!state[key]) {
          state[key] = [];
        }
        state[key].push(item);
      });

    return state;
  }
}
