import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError, BehaviorSubject, EMPTY, iif } from 'rxjs';
import { catchError, filter, take, switchMap, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(public authService: AuthService, private navCtrl: NavController) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('api/')) {
            return next.handle(request);
        }

        if (this.authService.jwtToken) {
            request = this.addToken(request, this.authService.jwtToken);
        }

        return next.handle(request)
            .pipe(catchError(error => {
                console.log(error);
                if (this.shouldRefreshToken(error, request)) {
                    return this.handle401Error(request, next);
                } else {
                    return throwError(error);
                }
            }));
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return request;
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken$()
                .pipe(
                    tap(isRefreshed => {
                        this.isRefreshing = false;
                        if (!isRefreshed) {
                            this.authService.logout$()
                                .toPromise()
                                .then(() => this.navCtrl.navigateRoot('auth/login'));
                        } else {
                            this.refreshTokenSubject.next(this.authService.jwtToken);
                        }
                    }),
                    switchMap(isRefreshed => iif(() => isRefreshed, next.handle(this.addToken(request, this.authService.jwtToken)), EMPTY))
                );

        } else {
            return this.refreshTokenSubject
                .pipe(
                    filter(token => token != null),
                    take(1),
                    switchMap(jwt => next.handle(this.addToken(request, jwt))),
                );
        }
    }

    private shouldRefreshToken(error: any, request: HttpRequest<any>): boolean {
        const isInvalidCreds = error && error.error && error.error.error && error.error.error.message === 'Invalid credentials';
        return error instanceof HttpErrorResponse && error.status === 401 && !isInvalidCreds && !request.url.includes('logout');
    }
}
