import { Routes } from '@angular/router';
import { PagesGuard } from './auth/guards/pages.guard';
import { AuthPagesGuard } from './auth/guards/auth-pages.guard';
const ɵ0 = () => import("./pages/pages-routing.module.ngfactory").then(m => m.PagesRoutingModuleNgFactory), ɵ1 = () => import("./auth/auth-routing.module.ngfactory").then(m => m.AuthRoutingModuleNgFactory), ɵ2 = () => import("./public-pages/public-pages.module.ngfactory").then(m => m.PublicPagesModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'pages',
        pathMatch: 'full'
    },
    {
        path: 'pages',
        canActivate: [PagesGuard],
        loadChildren: ɵ0
    },
    {
        path: 'auth',
        canActivate: [AuthPagesGuard],
        loadChildren: ɵ1
    },
    {
        path: 'public',
        loadChildren: ɵ2
    },
    {
        path: '**',
        redirectTo: 'pages',
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
