import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, ElementRef, AfterViewChecked } from '@angular/core';
import { IonList } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() time: string;
  @Input() fromDate: string;
  @Input() minStep: number = 15;
  @Input() showTomorrowTime: boolean = false;
  @Input() onValueChanged: (value: string) => void;
  @Input() onDismiss: () => void;

  @ViewChild(IonList, { static: false, read: ElementRef }) ionListEl: ElementRef;

  public timeIntervals$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  private destroy$ = new Subject();
  private firstCheckHasPassed: boolean = false;

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    if (!this.time) {
      this.time = this.fromDate ? this.fromDate : moment().add(1, 'hour').toISOString();
    }

    const startDate: string = this.fromDate ? this.fromDate : moment(this.time).clone().startOf('day').toISOString();
    this.timeIntervals$.next(this.generateTimeIntervals(startDate, this.minStep));
  }

  ngAfterViewChecked(): void {
    if (!this.firstCheckHasPassed) {
      const timeIndex: number = this.timeIntervals$.value.findIndex(time => this.isSelectedTimeInerval(time));
      if (timeIndex !== -1) {
        const scrollEl: HTMLElement = this.ionListEl.nativeElement;
        scrollEl.children.item(timeIndex).scrollIntoView({ behavior: 'auto', block: 'center' });
      }
      this.setFirstCheckHasPassed();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get canShowTimeDiff(): boolean {
    return this.showTomorrowTime && !!this.fromDate;
  }

  isSelectedTimeInerval(time: string): boolean {
    return moment(time).format('HH:mm') === moment(this.time).format('HH:mm');
  }

  selectTime(time: string): void {
    if (this.time !== time) {
      this.onValueChanged(time);
      this.onDismiss();
    }
  }

  showTimeDiff(time: string): string {
    if (this.canShowTimeDiff) {
      const hoursDiff: number =  moment(time).diff(this.fromDate, 'hours', true);
      const translatedHourShort: string = this.translateService.instant('common.hour-short');
      const translatedMinuteShort: string = this.translateService.instant('common.minute-short');

      if (Math.abs(hoursDiff) < 1)  {
        return `${60 * hoursDiff} ${translatedMinuteShort}`;
      }
      const hoursDiffFloor: number = Math.floor(hoursDiff);
      const hoursDiffPrecise: number = Math.abs(hoursDiff - hoursDiffFloor);
      return `${hoursDiffFloor}${translatedHourShort}`.replace('.', ',') + ' ' + `${60 * hoursDiffPrecise}${translatedMinuteShort}`;
    }

    return null;
  }

  private generateTimeIntervals(fromDate: string, minuteStep: number): string[] {
    const result: string[] = []
    const endDate = this.showTomorrowTime
      ? moment(fromDate).clone().add(1, 'days').toISOString()
      : moment(fromDate).clone().endOf('day').toISOString();
    const minuteStepInMilliseconds: number = minuteStep * 60 * 1000;
    const startDateTime: number = new Date(fromDate).getTime();
    const endDateTime: number = new Date(endDate).getTime();

    for (let index = startDateTime; index < endDateTime; index+=minuteStepInMilliseconds) {
      result.push(new Date(index).toISOString());
    }

    return result;
  }

  private setFirstCheckHasPassed(): void {
    of(true)
      .pipe(delay(1000))
      .subscribe(() => this.firstCheckHasPassed = true);
  }
}
