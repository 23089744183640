<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons *ngIf="!isModalHeader" slot="start">
      <ion-button (click)="back.next()">
        <ion-icon slot="icon-only" class="back-icon" name="chevron-back-outline" size="small"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'chat.direct_page_title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="isModalHeader; else defaultButtons">
        <ion-button (click)="doDismiss()">
          <ion-icon name="close-outline" size="small" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-button>
      <ng-template #defaultButtons>
        <ion-button (click)="expand.next()" class="expand-btn">
          <ion-icon src="/assets/img/open-new-tab-ic.svg" size="small" class="open-new-tab-icon" slot="icon-only"></ion-icon>
        </ion-button>
        <ion-button (click)="dismiss.next()" class="close-btn">
          <img alt="Close" slot="icon-only" src="/assets/img/chat-close-btn-dark.svg">
        </ion-button>
      </ng-template>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content appRemoveContentSizing>
  <app-chat-finder (onCreate)="onCreate.next($event)"></app-chat-finder>
</ion-content>
