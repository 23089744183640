/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invite-loading-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./invite-loading-modal.component";
import * as i6 from "../../services/invite.service";
import * as i7 from "../../services/link-storage.service";
import * as i8 from "../../services/toast.service";
import * as i9 from "@ngx-translate/core";
var styles_InviteLoadingModalComponent = [i0.styles];
var RenderType_InviteLoadingModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InviteLoadingModalComponent, data: {} });
export { RenderType_InviteLoadingModalComponent as RenderType_InviteLoadingModalComponent };
function View_InviteLoadingModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ion-spinner", [["name", "crescent"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(2, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "crescent"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InviteLoadingModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-card", [], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "ion-card-content", [], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(3, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(5, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["You are joining..."])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InviteLoadingModalComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_InviteLoadingModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-invite-loading-modal", [], null, null, null, View_InviteLoadingModalComponent_0, RenderType_InviteLoadingModalComponent)), i1.ɵdid(1, 245760, null, 0, i5.InviteLoadingModalComponent, [i6.InviteService, i7.LinkStorageService, i8.ToastService, i9.TranslateService, i3.NavController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InviteLoadingModalComponentNgFactory = i1.ɵccf("app-invite-loading-modal", i5.InviteLoadingModalComponent, View_InviteLoadingModalComponent_Host_0, { session: "session", doDismiss: "doDismiss" }, {}, []);
export { InviteLoadingModalComponentNgFactory as InviteLoadingModalComponentNgFactory };
