import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { TicketParent, EntityType } from '../models/common';
import { HttpService } from './http.service';
import { LinkStorageService } from './link-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
import * as i3 from "./http.service";
import * as i4 from "./link-storage.service";
export class TicketRouteService {
    constructor(navCtrl, route, httpService, linkStorageService) {
        this.navCtrl = navCtrl;
        this.route = route;
        this.httpService = httpService;
        this.linkStorageService = linkStorageService;
        this.inAppNavigationFlag = false;
        this.COPY_PASTE_LINK_URL = 'v1/tickets/copy-paste-link';
    }
    openTicket(params) {
        if (params.entityType === EntityType.ASSIGNED_TASK) {
            return this.openAssignedTask(params.ticketId);
        }
        const taskUrl = this.prepareUrl(params.ticketId, params.entityType, params.ticketParent);
        const queryParams = {};
        if (params.entityType === EntityType.TICKET_TASK && params.chatId) {
            queryParams['chatId'] = params.chatId;
        }
        this.navCtrl.navigateForward(taskUrl, { relativeTo: this.route, queryParams });
    }
    retrieveTicketRoute$(ticketRouteParams) {
        return this.httpService.post$(this.COPY_PASTE_LINK_URL, ticketRouteParams);
    }
    prepareUrl(ticketId, entityType, ticketParent) {
        return `pages/ticket/${ticketId}/${entityType}/${ticketParent}`;
    }
    openAssignedTask(assignedTaskId) {
        const taskUrl = `pages/ticket/${assignedTaskId}/${EntityType.ASSIGNED_TASK}/${TicketParent.SUBJECT}`;
        this.linkStorageService.saveTicketLink(taskUrl);
        this.navCtrl.navigateForward(taskUrl, { relativeTo: this.route });
    }
}
TicketRouteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketRouteService_Factory() { return new TicketRouteService(i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i3.HttpService), i0.ɵɵinject(i4.LinkStorageService)); }, token: TicketRouteService, providedIn: "root" });
