import { Component, OnInit, ViewChildren, AfterViewInit, QueryList, ElementRef, Renderer2, Input } from '@angular/core';
import { IonButton } from '@ionic/angular';

@Component({
  selector: 'app-colors-group',
  templateUrl: './colors-group.component.html',
  styleUrls: ['./colors-group.component.scss'],
})
export class ColorsGroupComponent implements OnInit, AfterViewInit {
  @Input() currentColor: string;
  @Input() colors: string;
  @Input() onDismiss: (color: string) => void;
  @ViewChildren('buttons', { read: ElementRef }) buttons: QueryList<ElementRef>;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.buttons.forEach((buttonEl, index) => {
      this.renderer.setProperty(buttonEl.nativeElement, 'style', `--background : ${this.colors[index]}`);
    });
  }

  isChecked(color: string): boolean {
    return color === this.currentColor;
  }
  chooseColor(color: string): void {
    this.onDismiss(color);
  }
}
